import styled from 'styled-components/macro';

export namespace Styled {
	export const Head = styled.section`
		display: flex;
		justify-content: space-between;
	`;

	export const Title = styled.h2``;

	export const Content = styled.section`
		position: relative;

		.ant-tabs .ant-tabs-nav {
			text-transform: uppercase;

			&:before {
				display: none;
			}
		}
	`;
}
