import { memo } from 'react';

import { IconType } from '../SVG.types';

function AddIcon({ fill = 'white', width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M20.485 10.85C21.1201 10.85 21.635 11.3649 21.635 12C21.6349 12.6352 21.1201 13.15 20.4849 13.15L13.1497 13.15L13.1498 20.4854C13.1498 21.1206 12.6349 21.6354 11.9998 21.6354C11.3647 21.6354 10.8498 21.1206 10.8498 20.4854L10.8497 13.15L3.51425 13.1499C2.87912 13.1499 2.36425 12.635 2.36426 11.9999C2.36426 11.3648 2.87914 10.8499 3.51427 10.8499L10.8497 10.85L10.8497 3.51475C10.8497 2.87963 11.3645 2.36475 11.9997 2.36475C12.6348 2.36474 13.1497 2.87961 13.1497 3.51474L13.1497 10.85L20.485 10.85Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(AddIcon);
