import React, { useCallback, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'antd';
import { debounce } from 'lodash';

import { DEFAULT_PAGE_SIZE } from 'modules/Common/constants';
import { IUserAccessDataPaginated } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { GenericType, Routes } from 'types';

import SearchField from '../../../components/SearchField';
import { ClntContext } from '../../../containers/ClientContextContainer/ClientContextContainer';
import UserAccessContainer from '../../../containers/UserAccessContainer';

import { Styled } from './ClientContextUsersPage.styled';

export const ClientContextUsersPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { clientId } = useParams();
	const searchValueRef = useRef<string>('');

	const clientStore = useContext(ClntContext)[clientId];
	const loading: GenericType = useSelector(clientStore?.backOfficeSelectors?.backOfficeLoading);
	const users: IUserAccessDataPaginated = useSelector(
		clientStore?.backOfficeSelectors?.getBackOfficeUsersList,
	);
	const getUsersRequested: (
		params: { active: boolean; page?: number; size?: number; client: number },
		isClientContext: boolean,
	) => void = clientStore?.backOfficeActions?.getBackOfficeUsersRequested;

	const deActivateUserRequested: (
		id: number,
		isActive: boolean,
		isClientContext?: boolean,
		cb?: () => void,
	) => void = clientStore?.backOfficeActions?.activationUserRequested;

	const { pageIndex, pageSize } = users || {};

	const debounceSearchUsers = debounce((value) => {
		dispatch(
			getUsersRequested(
				{ page: 0, size: DEFAULT_PAGE_SIZE, client: +clientId, searchKeyword: value },
				true,
			),
		);
	}, 1500);

	const handleGetUsers = useCallback(
		(params) => {
			dispatch(getUsersRequested({ ...params, client: +clientId }, true));
		},
		[clientId],
	);

	const handleDeActivateUser = useCallback(
		(id, isActive) => {
			dispatch(
				deActivateUserRequested(id, isActive, true, () =>
					dispatch(
						getUsersRequested(
							{
								page: pageIndex,
								size: pageSize,
								isActive: !isActive,
								client: +clientId,
							},
							true,
						),
					),
				),
			);
		},
		[pageIndex, pageSize, searchValueRef, clientId],
	);

	const handleAddUser = () => {
		navigate(`${Routes.BOClientContext}/${clientId}${Routes.Users}${Routes.Create}`);
	};

	const handleSearch = (e: Event) => {
		e?.preventDefault();

		debounceSearchUsers(e?.target?.value);
	};

	const handleImpersonate = (userId: number) =>
		dispatch(
			unregisteredDucks.unregisteredActions.impersonateUserRequested({
				userId,
				callback: () => navigate(0),
			}),
		);

	const customAction = (id) => (
		<>
			<Button onClick={() => handleImpersonate(id)}>impersonate</Button>
		</>
	);

	return (
		<UserAccessContainer
			users={users}
			loading={loading}
			getUsersRequested={handleGetUsers}
			deActivateUserRequested={handleDeActivateUser}
			isClientContext
			clientId={clientId}
			customAction={customAction}
			headerComponent={
				<>
					<Styled.Head>
						<h2>Users</h2>
						<Button type='primary' htmlType='button' onClick={handleAddUser}>
							Add User
						</Button>
					</Styled.Head>
					<p>You can manage all user accounts below.</p>

					<SearchField ref={searchValueRef} onSearch={handleSearch} />
				</>
			}
			editUserLink={`${Routes.BOClientContext}/${clientId}${Routes.Users}${Routes.Edit}`}
		/>
	);
};

export default ClientContextUsersPage;
