import styled from 'styled-components/macro';

import { size } from 'theme';

export namespace Styled {
	export const ModalHead = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;
	`;

	export const ModalContent = styled.div`
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 60vh;

		canvas {
			width: auto !important;
			height: 100% !important;
			max-height: calc(100vh - 140px) !important;

			@media (min-height: ${size.large}) {
				max-height: 1010px !important;
			}
		}
	`;
}
