import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function DotIcon({ fill = COLORS.black, width = '5', height = '6' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 5 6'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M2.5 5.86364C2.04167 5.86364 1.62311 5.75189 1.24432 5.52841C0.86553 5.30114 0.5625 4.99811 0.335227 4.61932C0.111742 4.24053 0 3.82197 0 3.36364C0 2.90152 0.111742 2.48295 0.335227 2.10795C0.5625 1.72917 0.86553 1.42803 1.24432 1.20455C1.62311 0.977272 2.04167 0.863636 2.5 0.863636C2.96212 0.863636 3.38068 0.977272 3.75568 1.20455C4.13447 1.42803 4.43561 1.72917 4.65909 2.10795C4.88636 2.48295 5 2.90152 5 3.36364C5 3.82197 4.88636 4.24053 4.65909 4.61932C4.43561 4.99811 4.13447 5.30114 3.75568 5.52841C3.38068 5.75189 2.96212 5.86364 2.5 5.86364Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(DotIcon);
