import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.section`
		position: relative;
	`;
	export const Search = styled.div`
		position: absolute;
		top: 8px;
		right: 160px;
		z-index: 2;
	`;
}
