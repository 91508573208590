import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { ClntContext } from 'modules/BackOffice/containers/ClientContextContainer/ClientContextContainer';
import JobChangesFormContainer from 'modules/BackOffice/containers/JobChangesFormContainer/JobChangesFormContainer';
import { JobFormProps } from 'modules/BackOffice/containers/JobChangesFormContainer/JobChangesFormContainer.types';
import { Routes } from 'types';

import { Styled } from './ClientContextJobChangeFormPage.styled';

export const ClientContextJobsPage = () => {
	const dispatch = useDispatch();
	const { clientId, jobId } = useParams();
	const location = useLocation();

	const clientStore = useContext(ClntContext)[clientId];

	const userRoute = `${Routes.BOClientContext}/${clientId}${Routes.EditJob}`;

	const isEditMode = location?.pathname?.includes(userRoute);

	const state: Partial<JobFormProps> = {
		clientsData: useSelector(clientStore?.backOfficeSelectors?.getClientsShortData),
		jobData: useSelector(clientStore?.backOfficeSelectors?.getBackOfficeJob),
		defaultThankYouMessage: useSelector(
			clientStore?.backOfficeSelectors?.getDefaultThankYouMessage,
		),
		loadingBackoffice: useSelector(clientStore?.backOfficeSelectors?.backOfficeLoading),
	};

	const actions: Partial<JobFormProps> = {
		getClientsListRequested: () =>
			dispatch(clientStore?.backOfficeActions?.getClientsShortRequested()),
		getBackOfficeJobById: (...values) =>
			dispatch(clientStore?.backOfficeActions?.getBackOfficeJobByIdRequested(...values)),
		getDefaultMessageByClientId: (...values) =>
			dispatch(
				clientStore?.backOfficeActions?.getBoApplicationMessagesByClientIdRequested(...values),
			),
		resetBackOfficeJobChangesForm: () =>
			dispatch(clientStore?.backOfficeActions?.resetBackOfficeJobChangesFormRequested()),
	};

	return (
		<Styled.Root>
			<JobChangesFormContainer
				jobId={jobId}
				clientId={clientId}
				isEditMode={isEditMode}
				isClientContext
				{...state}
				{...actions}
			/>
		</Styled.Root>
	);
};

export default ClientContextJobsPage;
