import styled from 'styled-components/macro';

interface INavigationProps {
	isImpersonating?: boolean;
}

export namespace Styled {
	export const Root = styled.div<INavigationProps>`
		display: flex;
		flex-direction: column;
		width: 100%;
		overflow-y: auto;
		position: relative;
		height: ${({ isImpersonating }) => (isImpersonating ? 'calc(100vh - 72px)' : '100vh')};
		min-height: ${({ isImpersonating }) => (isImpersonating ? 'calc(100vh - 72px)' : '100vh')};
	`;

	export const Main = styled.main`
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		width: 100%;
		padding-left: 32px;
		padding-right: 32px;

		section > h2 {
			margin-bottom: 24px;
		}
	`;
}
