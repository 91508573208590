import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function Icon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18.2722 18.219C19.6975 16.51 20.5 14.1111 20.5 11.5002C20.5 8.53758 18.8135 5.96775 16.5875 4.37766C15.4826 3.58841 14.3215 3.10018 13.3008 2.92421C12.2698 2.74644 11.5258 2.90511 11.0601 3.19623C10.704 3.41887 10.6384 3.58286 10.5997 3.71403C10.5282 3.95635 10.5099 4.32155 10.5624 5.05143C10.5716 5.17932 10.5836 5.31816 10.5965 5.46682C10.7111 6.79024 10.8931 8.89126 9.75214 10.9642C9.21993 11.9311 8.31462 12.5528 7.65654 12.9515C7.25065 13.1974 6.71413 13.4813 6.24675 13.7287C5.99343 13.8627 5.76043 13.9861 5.57953 14.0867C4.11935 14.8995 4 15.2865 4 15.5C4 17.0108 4.72722 18.3204 6.10319 19.3184C7.51075 20.3393 9.57874 21 12 21C14.8197 21 16.8812 19.8868 18.2722 18.219ZM12 23C18.8217 23 22.5 17.4999 22.5 11.5002C22.5 4.00048 14 -1.00011 10 1.50032C8.35141 2.53086 8.46848 3.98216 8.602 5.63743C8.71145 6.99431 8.83196 8.48824 8 9.99982C7.57887 10.765 6.52793 11.3188 5.42111 11.9021C3.77254 12.7709 2 13.7051 2 15.5C2 19.9996 6.47715 23 12 23Z'
				fill={fill}
			/>
			<circle cx='8.5' cy='16.5' r='1.5' fill={fill} />
			<circle cx='14.5' cy='16.5' r='1.5' fill={fill} />
			<circle cx='16.5' cy='11.5' r='1.5' fill={fill} />
			<circle cx='14.5' cy='6.5' r='1.5' fill={fill} />
		</svg>
	);
}

export default memo(Icon);
