import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from 'modules/Common/constants';

export const initialState = {
	contractsState: {
		clientContracts: [],
		contracts: {
			data: [],
			pageIndex: DEFAULT_CURRENT_PAGE,
			pageSize: DEFAULT_PAGE_SIZE,
			totalCount: 0,
		},
		contractDetails: null,
		chargeVat: 0,
		outstandingCount: 0,
		contractStatuses: null,
		receiptData: null,
	},
	clientsState: {
		clientData: null,
		clientUsers: [],
	},
	creditsState: {
		credits: [],
	},
	jobsState: {
		jobData: null,
		jobTypes: [],
		currentClientJobs: [],
		currentClientCopyJobs: {
			data: [],
			pageIndex: DEFAULT_CURRENT_PAGE,
			pageSize: DEFAULT_PAGE_SIZE,
			totalCount: 0,
		},
	},
	jobIndustries: [],
	educationState: {
		educationTypes: [],
	},
	employmentState: {
		employmentTypes: [],
	},
	currentClientBranding: {},
	jobApply: {
		assessment: null,
	},
	interviewState: {
		interviewData: {},
	},
	smsCountState: null,
	questionnairesState: {
		durations: [],
		questionnairesData: {
			data: [],
			pageIndex: DEFAULT_CURRENT_PAGE,
			pageSize: DEFAULT_PAGE_SIZE,
			totalCount: 0,
		},
		atsQuestionnairesData: [],
		copyQuestionnairesData: {
			data: [],
			pageIndex: DEFAULT_CURRENT_PAGE,
			pageSize: DEFAULT_PAGE_SIZE,
			totalCount: 0,
		},
		sendQuestionnairesData: {
			data: [],
			pageIndex: DEFAULT_CURRENT_PAGE,
			pageSize: DEFAULT_PAGE_SIZE,
			totalCount: 0,
		},
		candidateVideoQuestionnaireData: null,
		currentQuestionnaire: null,
		questionnaireUsers: [],
		questionnaireResult: null,
	},
	ticketTypes: [],
	orderPrices: {
		smsPrice: {},
		videoInterviewPrice: {},
	},
	emailTemplatesState: {
		emailTemplatesData: [],
	},
	clientPrices: {
		clientId: null,
		prices: [],
	},
	queryParams: {
		search: null,
		sort: '',
	},
};
