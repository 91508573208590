import { Button as ButtonComponent } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		width: 100%;
	`;

	export const Label = styled.label`
		display: block;
		margin-bottom: 8px;
		line-height: 24px;
	`;

	export const UploadWrapper = styled.div`
		.ant-upload-wrapper {
			.ant-upload {
				width: 100% !important;
			}
		}
	`;

	export const Button = styled(ButtonComponent)`
		width: 100%;

		.ant-btn-icon svg path {
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		}

		&:hover {
			.ant-btn-icon svg path {
				fill: ${COLORS.inputColor};
			}
		}

		&:disabled {
			.ant-btn-icon svg path {
				fill: ${COLORS.neutral5};
			}
		}
	`;
}
