import styled from 'styled-components/macro';

import { COLORS } from 'theme';

type TitleProps = {
	textColour?: string;
};

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;
	`;

	export const HeaderContainerControls = styled.div`
		z-index: 4;
	`;

	export const Info = styled.div`
		position: relative;
		display: flex;
		width: 100%;
		margin-bottom: 32px;

		@media screen and (max-width: 450px) {
			margin-bottom: 0;
		}
	`;

	export const Logo = styled.div`
		position: absolute;
		top: 0;
		display: flex;
		justify-content: flex-end;
		width: 100%;
		max-width: 240px;
		height: 120px;
		max-height: 120px;
		margin-left: 32px;
		padding: 8px;
		border-radius: 4px;
		background-color: ${COLORS.white};
		transform: translateY(-50%);
		box-shadow: ${COLORS.elevationShadow};
		z-index: 3;

		img {
			width: 100%;
			padding: 4px;
			object-fit: contain;
		}

		@media screen and (max-width: 450px) {
			max-width: 140px;
			margin-left: 16px;
			height: 77px;
			max-height: 77px;
		}
	`;

	export const Heading = styled.div<TitleProps>`
		margin-top: 12px;
		margin-left: 300px;
		font-weight: 800;
		font-size: 32px;
		line-height: 40px;
		color: ${({ textColour }) => textColour};
		font-family: 'Raleway';

		@media screen and (max-width: 450px) {
			font-weight: 800;
			font-size: 24px;
			margin-left: 172px;
			margin-top: 0;
		}
	`;

	export const ButtonBox = styled.div`
		position: absolute;
		right: 8px;
		bottom: 8px;
	`;

	export const DescriptionContainer = styled.div`
		padding: 68px 32px;
		position: relative;

		@media screen and (max-width: 450px) {
			padding: 32px 16px;
		}
	`;

	export const DescriptionControl = styled.div`
		position: absolute;
		top: 65px;
		right: 30px;
	`;

	export const DescriptionLabel = styled.div`
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 2.3px;
		text-transform: uppercase;
		color: ${COLORS.darkGray5};
		padding-bottom: 16px;
	`;

	export const Description = styled.pre`
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		line-height: 42px;
		color: ${COLORS.black};
		white-space: pre-wrap;

		@media screen and (max-width: 450px) {
			font-size: 16px;
			line-height: 150%;
		}
	`;

	export const DescriptionForm = styled.div``;

	export const DescriptionFormArea = styled.div`
		padding-bottom: 16px;

		textarea {
			height: 80px;
		}
	`;

	export const DescriptionFormSubmit = styled.div`
		width: 100%;
		display: flex;
		justify-content: flex-end;
	`;
}
