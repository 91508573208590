import { memo } from 'react';

import { IconType } from '../SVG.types';

function JobBriefcase({ width = '172', height = '144' }: IconType) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 200 146'
			fill='none'
		>
			<g clipPath='url(#clip0_525_4039)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M63 33C63 38.5228 67.4772 43 73 43L118 43C123.523 43 128 38.5228 128 33V12C128 6.47715 123.523 2 118 2L73 2C67.4772 2 63 6.47716 63 12V33ZM75 28C75 31.3137 77.6863 34 81 34L110 34C113.314 34 116 31.3137 116 28V17C116 13.6863 113.314 11 110 11L81 11C77.6863 11 75 13.6863 75 17L75 28Z'
					fill='#F0F4FF'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M118 3.5L73 3.5C68.3056 3.5 64.5 7.30558 64.5 12V33C64.5 37.6944 68.3056 41.5 73 41.5L118 41.5C122.694 41.5 126.5 37.6944 126.5 33V12C126.5 7.30558 122.694 3.5 118 3.5ZM73 0.500003L118 0.5C124.351 0.5 129.5 5.64873 129.5 12V33C129.5 39.3513 124.351 44.5 118 44.5L73 44.5C66.6487 44.5 61.5 39.3513 61.5 33V12C61.5 5.64873 66.6487 0.500004 73 0.500003ZM110 12.5L81 12.5C78.5147 12.5 76.5 14.5147 76.5 17L76.5 28C76.5 30.4853 78.5147 32.5 81 32.5L110 32.5C112.485 32.5 114.5 30.4853 114.5 28V17C114.5 14.5147 112.485 12.5 110 12.5ZM81 9.5L110 9.5C114.142 9.5 117.5 12.8579 117.5 17V28C117.5 32.1421 114.142 35.5 110 35.5H81C76.8579 35.5 73.5 32.1421 73.5 28L73.5 17C73.5 12.8579 76.8579 9.5 81 9.5Z'
					fill='#C8D6FF'
				/>
				<path
					d='M24 146C18.4772 146 14 141.523 14 136L14 33C14 27.4771 18.4771 23 24 23L167 23C172.523 23 177 27.4771 177 33L177 136C177 141.523 172.523 146 167 146L24 146Z'
					fill='#F0F4FF'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M17 33L17 136C17 139.866 20.134 143 24 143L167 143C170.866 143 174 139.866 174 136L174 33C174 29.134 170.866 26 167 26L24 26C20.134 26 17 29.134 17 33ZM14 136C14 141.523 18.4772 146 24 146L167 146C172.523 146 177 141.523 177 136L177 33C177 27.4771 172.523 23 167 23L24 23C18.4771 23 14 27.4771 14 33L14 136Z'
					fill='#C8D6FF'
				/>
				<circle cx='65' cy='46' r='8' fill='#7D9CFC' />
				<circle cx='67' cy='42' r='2' fill='#F0F4FF' />
				<circle cx='70' cy='44' r='1' fill='#F0F4FF' />
				<circle cx='128' cy='46' r='8' fill='#7D9CFC' />
				<circle cx='130' cy='42' r='2' fill='#F0F4FF' />
				<circle cx='133' cy='44' r='1' fill='#F0F4FF' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M80.9309 56.8238C80.9337 56.8259 80.9412 56.8317 80.9536 56.841C80.9785 56.8594 81.0228 56.8916 81.0868 56.9356C81.215 57.0237 81.4224 57.1591 81.7119 57.3266C82.2908 57.6613 83.1992 58.1248 84.4607 58.5942C86.9803 59.5318 90.9282 60.5001 96.5001 60.5001C102.072 60.5001 106.02 59.5318 108.539 58.5942C109.801 58.1248 110.709 57.6613 111.288 57.3266C111.578 57.1591 111.785 57.0237 111.913 56.9356C111.977 56.8916 112.022 56.8594 112.046 56.841C112.059 56.8317 112.066 56.8259 112.069 56.8238L112.067 56.8254L112.065 56.8269L112.065 56.827C112.067 56.8258 112.068 56.8247 112.07 56.8235C112.716 56.312 113.656 56.4183 114.171 57.063C114.689 57.7099 114.584 58.6538 113.937 59.1714L113 58.0001C113.937 59.1714 113.936 59.1719 113.936 59.1724L113.934 59.1736L113.931 59.1762L113.923 59.1825L113.902 59.1992C113.885 59.2123 113.863 59.2289 113.836 59.249C113.782 59.2891 113.707 59.3429 113.612 59.4082C113.422 59.5389 113.149 59.716 112.79 59.9236C112.072 60.3388 111.012 60.8753 109.586 61.4059C106.73 62.4683 102.428 63.5001 96.5001 63.5001C90.5719 63.5001 86.2698 62.4683 83.4145 61.4059C81.9884 60.8753 80.928 60.3388 80.2101 59.9236C79.8512 59.716 79.5781 59.5389 79.3879 59.4082C79.2928 59.3429 79.2184 59.2891 79.1644 59.249C79.1374 59.2289 79.1155 59.2123 79.0986 59.1992L79.077 59.1825L79.0691 59.1762L79.0658 59.1736L79.0644 59.1724C79.0637 59.1719 79.063 59.1714 80.0001 58.0001L79.063 59.1714C78.4161 58.6538 78.3112 57.7099 78.8288 57.063C79.3445 56.4183 80.2839 56.3119 80.9306 56.8236C80.9307 56.8237 80.9308 56.8237 80.9309 56.8238Z'
					fill='#7D9CFC'
				/>
				<ellipse cx='65' cy='61.5' rx='7' ry='2.5' fill='#D6E1FF' />
				<ellipse cx='128' cy='61.5' rx='7' ry='2.5' fill='#D6E1FF' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M176.447 65.3945C176.45 65.3958 176.459 65.3995 176.473 65.4056C176.501 65.4179 176.551 65.4401 176.621 65.4736C176.762 65.5406 176.983 65.6525 177.272 65.8195C177.852 66.1534 178.707 66.7084 179.745 67.5661C181.816 69.2795 184.629 72.2142 187.415 77.0396C190.201 81.8651 191.336 85.7681 191.784 88.419C192.008 89.7462 192.061 90.7646 192.061 91.4333C192.06 91.7678 192.047 92.0151 192.035 92.1701C192.029 92.2476 192.023 92.3021 192.019 92.3328C192.017 92.3482 192.016 92.3576 192.016 92.361L192.016 92.3586L192.016 92.3562L192.016 92.356C192.016 92.3578 192.016 92.3597 192.016 92.3615C191.896 93.1773 192.458 93.9377 193.274 94.062C194.093 94.1867 194.858 93.6239 194.983 92.8049L193.5 92.5791C194.983 92.8049 194.983 92.8041 194.983 92.8032L194.983 92.8014L194.984 92.7972L194.985 92.7872L194.989 92.7602C194.992 92.7391 194.995 92.7117 194.999 92.6783C195.007 92.6114 195.016 92.5202 195.025 92.4051C195.043 92.1751 195.06 91.85 195.061 91.4355C195.061 90.606 194.996 89.4195 194.742 87.9192C194.235 84.9152 192.977 80.6735 190.013 75.5396C187.049 70.4058 184.004 67.1958 181.657 65.2543C180.484 64.2846 179.489 63.6345 178.771 63.2203C178.411 63.0133 178.122 62.8654 177.913 62.766C177.809 62.7163 177.725 62.6788 177.664 62.6521C177.633 62.6387 177.607 62.628 177.588 62.6199L177.562 62.6097L177.553 62.6059L177.549 62.6044L177.547 62.6037C177.546 62.6034 177.546 62.6031 177 64.0002L177.546 62.6031C176.774 62.3016 175.904 62.6827 175.603 63.4544C175.302 64.2234 175.68 65.0901 176.446 65.3943C176.446 65.3944 176.446 65.3944 176.447 65.3945Z'
					fill='#9BB3FD'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M7.24069 95.3161C7.23936 95.3129 7.23572 95.3041 7.23002 95.2898C7.21862 95.261 7.19898 95.2099 7.17304 95.1366C7.12115 94.99 7.04403 94.7547 6.95723 94.4316C6.78369 93.7858 6.57108 92.7885 6.44418 91.4485C6.19072 88.772 6.2772 84.7081 7.71932 79.326C9.16143 73.944 11.1185 70.3813 12.6762 68.1901C13.4561 67.0931 14.1389 66.3357 14.6121 65.8632C14.8488 65.6268 15.0333 65.4615 15.1515 65.3605C15.2106 65.31 15.2532 65.2756 15.2774 65.2564C15.2896 65.2468 15.2971 65.241 15.2999 65.2389L15.2979 65.2404L15.2959 65.2418L15.2958 65.2419C15.2973 65.2408 15.2988 65.2397 15.3002 65.2386C15.9618 64.7463 16.1022 63.8115 15.6129 63.1464C15.122 62.4791 14.1831 62.3361 13.5158 62.827L14.4046 64.0353C13.5158 62.827 13.5151 62.8275 13.5144 62.8281L13.5129 62.8292L13.5095 62.8317L13.5014 62.8377L13.4797 62.8542C13.4627 62.8671 13.4409 62.884 13.4146 62.9049C13.3618 62.9467 13.2906 63.0046 13.2029 63.0795C13.0274 63.2294 12.7857 63.4474 12.4923 63.7403C11.9054 64.3264 11.1128 65.2118 10.2312 66.4519C8.4659 68.9349 6.35585 72.8235 4.82154 78.5496C3.28723 84.2757 3.17032 88.6983 3.45755 91.7313C3.601 93.2461 3.84477 94.4092 4.06001 95.2102C4.1676 95.6106 4.26799 95.9202 4.345 96.1377C4.3835 96.2465 4.41616 96.3322 4.44095 96.3948C4.45335 96.4261 4.46378 96.4516 4.47198 96.4713L4.4826 96.4964L4.4866 96.5057L4.48827 96.5095L4.48901 96.5112C4.48936 96.512 4.4897 96.5128 5.86362 95.9109L4.4897 96.5128C4.82215 97.2716 5.70678 97.6172 6.46558 97.2848C7.22184 96.9534 7.56767 96.0736 7.24084 95.3165C7.24079 95.3164 7.24074 95.3163 7.24069 95.3161Z'
					fill='#9BB3FD'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M16 83.5C16 82.6716 16.6716 82 17.5 82H173.5C174.328 82 175 82.6716 175 83.5C175 84.3284 174.328 85 173.5 85H17.5C16.6716 85 16 84.3284 16 83.5Z'
					fill='#C8D6FF'
				/>
				<path
					d='M76 81C76 77.6863 78.6863 75 82 75H109C112.314 75 115 77.6863 115 81V85C115 88.3137 112.314 91 109 91H82C78.6863 91 76 88.3137 76 85V81Z'
					fill='#F0F4FF'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M109 78H82C80.3431 78 79 79.3431 79 81V85C79 86.6569 80.3431 88 82 88H109C110.657 88 112 86.6569 112 85V81C112 79.3431 110.657 78 109 78ZM82 75C78.6863 75 76 77.6863 76 81V85C76 88.3137 78.6863 91 82 91H109C112.314 91 115 88.3137 115 85V81C115 77.6863 112.314 75 109 75H82Z'
					fill='#C8D6FF'
				/>
			</g>
			<defs>
				<clipPath id='clip0_525_4039'>
					<rect width='198.85' height='145.5' fill='white' transform='translate(0.162842 0.5)' />
				</clipPath>
			</defs>
		</svg>
	);
}

export default memo(JobBriefcase);
