import React from 'react';

import { AtsProfileMenuList } from 'modules/Common/types';

import ATSContracts from '../../containers/FinanceTables';

import { Styled } from './ATSFinancePage.styled';

export const ATSFinancePage = () => {
	return (
		<Styled.Root>
			<h2>{AtsProfileMenuList.Finance}</h2>
			<ATSContracts />
		</Styled.Root>
	);
};
