import React, { FC } from 'react';
import { Field } from 'react-final-form';

import FormSwitch from 'components/FormSwitch';
import * as Styled from 'modules/BackOffice/containers/ClientChangesFormBranding/ClientChangesFormBranding.styled';
import FormBlock from 'modules/Common/components/FormBlock';
import { ClientChangeFormDataType } from 'modules/Common/types';

type AdditionalTypeProps = {
	clientChangesFormData: ClientChangeFormDataType;
};

const Additional: FC<AdditionalTypeProps> = ({ clientChangesFormData }) => (
	<FormBlock title='Additional'>
		<Field name='interviewReminder' initialValue={clientChangesFormData.interviewReminder}>
			{({ input }) => <FormSwitch {...input} defaultChecked title='Send SMS Interview Reminders' />}
		</Field>
		<Styled.Space height={12} />
		<Field
			name='sendSMSQuestionnaireReminder'
			initialValue={clientChangesFormData.sendSMSQuestionnaireReminder}
		>
			{({ input }) => (
				<FormSwitch {...input} defaultChecked title='Send SMS Questionnaire Reminders' />
			)}
		</Field>
		<Styled.Space height={12} />
		<Field name='sendRejectionLetter' initialValue={clientChangesFormData.sendRejectionLetter}>
			{({ input }) => <FormSwitch {...input} defaultChecked title='Send rejection letter' />}
		</Field>
		<Styled.Space height={12} />
		<Field name='sendThankyouLetter' initialValue={clientChangesFormData.sendThankyouLetter}>
			{({ input }) => <FormSwitch {...input} defaultChecked title='Send thank-you letter' />}
		</Field>
		<Styled.Space height={12} />
		<Field name='enable2FA' initialValue={clientChangesFormData.enable2FA}>
			{({ input }) => <FormSwitch {...input} title='Enable 2FA for all users' />}
		</Field>
	</FormBlock>
);

export default Additional;
