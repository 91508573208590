import { CandidateInformation } from './SendSmsTypes';

export const extractIdsFromMessage = (message: string) => {
	if (typeof message !== 'string') return [];
	const regex = /ids=\[(.*?)\]/;
	const matches = message.match(regex);
	if (matches && matches[1]) {
		const idStrings = matches[1].split(/,\s*/);

		return idStrings.map((id) => parseInt(id, 10));
	}

	return [];
};

export const updateCandidatesWithIssues = (
	candidatesList: CandidateInformation[],
	candidateIdsWithIssues: number[],
	updateStateCallback: (list: CandidateInformation[]) => void,
) => {
	const filteredCandidatesList = candidatesList.filter((candidate) =>
		candidateIdsWithIssues.includes(candidate.id),
	);

	const updatedCandidatesList = filteredCandidatesList.map((candidate) => ({
		...candidate,
		smsFailedToBeSent: true,
	}));

	updateStateCallback(updatedCandidatesList);
};

type ClassifyAndHandleErrorProps = {
	message: string;
	candidatesList: CandidateInformation[];
	getSMSCount: () => void;
	setCandidatesList: (list: CandidateInformation[]) => void;
	setErrorMessage: (message: string) => void;
};

export const classifyAndHandleErrorMessage = async ({
	message,
	candidatesList,
	getSMSCount,
	setCandidatesList,
	setErrorMessage,
}: ClassifyAndHandleErrorProps) => {
	if (!message || typeof message !== 'string') return;
	const candidateIdsWithIssues = extractIdsFromMessage(message);

	if (message.includes('Failed to send sms to candidate applications with')) {
		getSMSCount(); // update sms count because some sms were sent in this case, and some failed
		updateCandidatesWithIssues(candidatesList, candidateIdsWithIssues, setCandidatesList);
		setErrorMessage(
			'Failed to send sms to candidate applications, please update the phone numbers and try again.',
		);
	}

	if (message.includes('Phone numbers are in wrong format for candidate applications with')) {
		updateCandidatesWithIssues(candidatesList, candidateIdsWithIssues, setCandidatesList);
		setErrorMessage(
			'Phone numbers are in wrong format for candidate applications, please update the phone numbers and try again.',
		);
	}

	if (message.includes('Candidate applications with ids')) {
		updateCandidatesWithIssues(candidatesList, candidateIdsWithIssues, setCandidatesList);
		setErrorMessage('Candidate applications were not found, please try again.');
	}

	if (message.includes('Phone numbers were not found for candidate applications with')) {
		updateCandidatesWithIssues(candidatesList, candidateIdsWithIssues, setCandidatesList);
		setErrorMessage(
			'Phone numbers were not found for candidate applications, please update the phone numbers and try again.',
		);
	}
};
