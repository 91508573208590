import { Select } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		position: relative;
		width: 100%;
	`;

	export const Head = styled.div`
		display: flex;
		justify-content: space-between;
		margin-bottom: 24px;
	`;

	export const FieldDescription = styled.p`
		font-size: 11px;
		color: ${COLORS.darkGray1};
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		margin-top: 10px;
	`;

	export const SelectAntd = styled(Select)`
		.ant-select-selection-item {
			min-height: 24px;
			height: auto;
		}

		.ant-select-selection-item-content {
			white-space: normal;
		}
	`;

	export const Content = styled.div``;

	export const Notifications = styled.div``;

	export const NotificationsHeader = styled.h3`
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
		letter-spacing: -0.264px;
		margin-bottom: 24px;
	`;

	export const NotificationsList = styled.div`
		> div {
			margin-bottom: 24px;
		}
	`;

	export const FormBlockLine = styled.div<{ columns: number }>`
		display: flex;
		margin-bottom: 32px;
		flex-wrap: wrap;

		& > div {
			width: ${({ columns }) => `calc(${100 / columns}% - 16px)`};
			height: 100%;
			padding: 0;
		}
		& > div:nth-child(odd) {
			margin-right: 16px;
		}
		& > div:nth-child(even) {
			margin-left: 16px;
		}
		&:last-of-type {
			margin-bottom: 16px;
		}
	`;
}
