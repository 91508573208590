import React, { FC, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';

import { EditorState, convertToRaw } from 'draft-js';

import { useMount } from 'hooks';
import { convertContentFromHtml } from 'modules/Common/utils/editorHelper';

import ErrorBoundary from '../../ErrorBoundary';
import { Styled } from '../FieldEditor.styled';
import { FieldEditorProps } from '../FieldEditor.types';
import { toolbarBuilder } from '../FiledEditor.constants';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const FieldHtmlEditor: FC<FieldEditorProps> = ({
	onChange,
	minRaws = 1,
	placeholder,
	defaultValue,
	toolbarValues,
	editorClassName = '',
	disabled = false,
}) => {
	const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

	useMount(() => {
		try {
			if (defaultValue) {
				const initialEditorState =
					typeof defaultValue === 'string'
						? convertContentFromHtml(defaultValue) || EditorState.createEmpty()
						: (defaultValue as EditorState); // Cast to EditorState if not a string
				setEditorState(initialEditorState);
			}
		} catch (error) {
			console.error('Error converting default value to editor state:', error);
		}
	});

	const handleChangeState = (state: EditorState) => {
		if (state) {
			setEditorState(state); // Update state for visual changes

			const content = state.getCurrentContent();
			const rawContent = JSON.stringify(convertToRaw(content));
			onChange(rawContent); // Optional: serialize and pass content
		}
	};

	const toolbarOptions = toolbarValues ? Object.keys(toolbarValues).map((i) => i) : [];
	const toolbar = { ...toolbarBuilder(toolbarOptions), ...toolbarValues };

	return (
		<Styled.Root minRaws={minRaws}>
			<Editor
				editorState={editorState}
				wrapperClassName='wrapper-class'
				editorClassName={`editor-class ant-input ${editorClassName}`}
				toolbarClassName='toolbar-class'
				onEditorStateChange={handleChangeState}
				handlePastedText={() => false}
				toolbar={toolbar}
				placeholder={placeholder}
				readOnly={disabled}
			/>
		</Styled.Root>
	);
};

const FieldHtmlEditorWrapper = (props) => (
	<ErrorBoundary errorMessage="Sorry, we've faced with an editor problem.">
		<FieldHtmlEditor {...props} />
	</ErrorBoundary>
);

export default FieldHtmlEditorWrapper;
