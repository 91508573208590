export const tableDataTemplate = [
	{
		id: 1,
		fieldName: 'name',
		label: 'Product / Service Type',
		width: '201px',
	},
	{
		id: 2,
		fieldName: 'amount',
		label: 'Quantity',
		width: '66px',
	},
	{
		id: 3,
		fieldName: 'unitPrice',
		label: 'Unit Price',
		with: '100px',
	},
	{
		id: 4,
		fieldName: 'totalPrice',
		label: 'TOTAL',
		width: '100px',
	},
];

export const smallTableDataTemplate = [
	{
		id: 1,
		fieldName: 'name',
		label: 'PRODUCT / SERVICE TYPE',
		width: '201px',
	},
	{
		id: 2,
		fieldName: 'unitPrice',
		label: 'Unit Price',
		width: '100px',
	},
	{
		id: 3,
		fieldName: 'totalPrice',
		label: 'TOTAL',
		width: '100px',
	},
];
