import styled from 'styled-components/macro';

import { device } from 'theme';

interface IMenuItemProps {
	highlightColor?: boolean;
	isHrModule?: boolean;
};

export namespace S {
	export const Root = styled.nav`
		overflow: hidden;
		white-space: nowrap;
	`;

	export const MainMenu = styled.ul`
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		list-style: none;

		@media ${device.tablet} {
			gap: 10px;
		}
	`;

	export const MenuItem = styled.li<IMenuItemProps>`
		a {
			display: block;
			line-height: 24px;
			text-decoration: none;
			font-size: 1rem;
			font-weight: 500;
			color: ${({ theme }) => theme.colors.white};
			transition: color ease 0.2s;

			&.active {
				color: ${({ theme, isHrModule, highlightColor }) => isHrModule ? highlightColor : theme.colors.lightBlue1};
			}

			&:hover,
			&:focus {
				color: ${({ theme, isHrModule, highlightColor }) => isHrModule ? highlightColor : theme.colors.lightBlue1};
			}
		}
	`;
}
