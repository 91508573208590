import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const PlanSwitch = styled.div`
		width: 100%;
		display: flex;
		justify-content: flex-start;
		padding-bottom: 24px;

		.ant-switch[aria-checked='false'] {
			background-color: ${COLORS.blue};
		}

		.ant-switch[aria-checked='true'] {
			background-color: ${COLORS.blue} !important;
		}
	`;
	export const PlanSwitchPeriod = styled.div`
		padding: 0 4px;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
	`;
	export const AnnualHint = styled.div`
		background-color: ${COLORS.blue};
		color: ${COLORS.white};
		text-align: center;
		vertical-align: center;
		padding: 4px 8px;
		border-radius: 4px;
		font-size: 12px;
	`;
}
