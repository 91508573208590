import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		min-height: 100%;

		h2 {
			margin-bottom: 24px;
		}

		p {
			margin-bottom: 24px;
		}

		form {
			display: flex;
			flex-direction: column;
			height: 100%;
		}

		section {
			height: 100%;
		}
	`;

	export const ButtonWrapper = styled.div`
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		padding-top: 36px;
	`;

	export const InfoBlock = styled.div`
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		gap: 4px;
	`;

	export const FieldsBox = styled.div`
		padding: 24px;
		background-color: ${COLORS.lightGray3};
		border-radius: 10px;
	`;
}
