import { darken, lighten } from 'polished';
import styled from 'styled-components/macro';

import { COLORS, device } from 'theme';

export namespace Styled {
	export const Root = styled.main`
        width: 409px;

        @media ${device.tablet} {
			width: 100%;
		}
    `;

	export const ModalHead = styled.div`
        width: 100%;
    `;
	export const ModalTitle = styled.div`
        text-align: center;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    `;

	export const ModalContent = styled.div`
        width: 100%;
        padding: 24px 0;
    `;
	export const ModalDescLine = styled.div`
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    `;
	export const ModalButtons = styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;

         & :nth-child(1) {
            margin-bottom: 16px;
            background-color: ${COLORS.green};

            & :focus, :hover {
                background-color: ${darken(0.2, COLORS.green)};
            }
         }

         & :nth-child(2) {
            color: ${COLORS.red};

            & :focus, :hover {
                background-color: ${lighten(0.3, COLORS.red)};
            }
         }
        
    `;
}
