import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function Icon({ fill = COLORS.red, width = '16', height = '16' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<circle cx='8.00016' cy='8.00065' r='6.66667' fill={fill} />
			<path
				d='M5.58413 11.3588C5.35632 11.5866 4.96059 11.5603 4.70025 11.2999C4.4399 11.0396 4.41351 10.6438 4.64132 10.416L7.05727 8.00008L4.64132 5.58413C4.41351 5.35632 4.4399 4.96059 4.70024 4.70025C4.96059 4.4399 5.35632 4.41351 5.58413 4.64132L8.00008 7.05727L10.416 4.64132C10.6438 4.41351 11.0396 4.4399 11.2999 4.70025C11.5603 4.96059 11.5866 5.35632 11.3588 5.58413L8.94289 8.00008L11.3588 10.416C11.5866 10.6438 11.5603 11.0396 11.2999 11.2999C11.0396 11.5603 10.6438 11.5866 10.416 11.3588L8.00008 8.94289L5.58413 11.3588Z'
				fill='white'
			/>
		</svg>
	);
}

export default memo(Icon);
