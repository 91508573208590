import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function MoneyIcon({ fill = COLORS.lightGray1, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M12.2971 10.9814L12.5603 11.0889C12.72 11.1519 13.0456 11.2567 13.2176 11.3172C13.6224 11.4597 13.9157 11.6043 14.3368 11.8418L14.2998 11.8188C14.9863 12.2364 15.6061 13.0817 15.6061 14.1309C15.6061 15.0835 15.1631 15.9427 14.481 16.4945C14.0663 16.8301 13.5785 17.0632 13.0486 17.1921V17.5409C13.0486 18.12 12.5791 18.5896 12 18.5896C11.4207 18.5896 10.9514 18.12 10.9514 17.5409V17.1617C9.85348 16.8694 9.00925 16.1565 8.54168 15.3772C8.2559 14.9007 8.4254 14.2218 8.90204 13.936C9.37845 13.6501 10.0575 13.8199 10.3433 14.2963C10.5915 14.7101 11.2051 15.2135 12 15.2135C12.5155 15.2135 12.9137 15.053 13.1554 14.8576C13.2809 14.756 13.3664 14.6568 13.422 14.5469C13.4771 14.4378 13.5088 14.3064 13.5089 14.1316C13.5068 13.8748 13.2997 13.6645 12.8971 13.4648C12.704 13.369 12.4848 13.2852 12.2599 13.2044C12.1968 13.1817 12.1327 13.1591 12.0687 13.1366C11.9095 13.0805 11.7507 13.0245 11.6083 12.9679L11.7951 13.0388C11.7307 13.0153 11.6682 12.9917 11.6083 12.9679C11.4611 12.9098 11.3018 12.8537 11.1347 12.7949C10.6849 12.6365 10.1787 12.4582 9.70026 12.1673C9.01484 11.7502 8.39385 10.9188 8.39385 9.86841C8.39385 8.91678 8.8359 8.04395 9.519 7.49151C9.93438 7.15552 10.4231 6.93044 10.9514 6.80622V6.45853C10.9514 5.94628 11.3185 5.51999 11.8039 5.42818V5.4097H12C12.5793 5.4097 13.0486 5.87927 13.0486 6.45834V6.83706C14.1457 7.12541 14.9901 7.82862 15.4583 8.60863C15.7442 9.08504 15.5744 9.76409 15.098 10.0499C14.6214 10.3357 13.9425 10.166 13.6567 9.68958C13.4097 9.27804 12.7971 8.78574 12 8.78574C11.4803 8.78574 11.0835 8.93514 10.8446 9.12831C10.7208 9.22845 10.6349 9.33043 10.5788 9.44408C10.5229 9.55723 10.4912 9.69277 10.4911 9.86776C10.4931 10.1155 10.6988 10.323 11.1044 10.5225C11.2981 10.6178 11.5177 10.7015 11.7424 10.7821C11.8032 10.8039 11.8648 10.8256 11.9263 10.8473C12.0876 10.9042 12.2483 10.9609 12.3919 11.0182L12.2971 10.9814Z'
				fill={fill}
			/>
			<path
				d='M12 2C6.48715 2 2 6.48725 2 12C2 17.5128 6.48724 22 12 22C17.5128 22 22 17.5128 22 12C22 6.48724 17.5128 2 12 2ZM12 4.0971C16.3746 4.0971 19.9025 7.62525 19.9025 11.9996C19.9025 16.3743 16.3744 19.9022 12 19.9022C7.62535 19.9022 4.09746 16.374 4.09746 11.9996C4.09746 7.62499 7.62562 4.0971 12 4.0971Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(MoneyIcon);
