import React, { type FC, memo, useState, useEffect } from 'react';
import { Field, useFormState } from 'react-final-form';

import { Input } from 'antd';

import FieldWrapper from 'components/FieldWrapper';
import { CreditsType } from 'modules/Common/types';
import { CurrencyType, Fields } from 'types';
import { numberValidator } from 'utils/validators';

import FormBlockLine from '../../../../Common/components/FormBlockLine';

import { Styled } from './Credits.styled';

type ContractDetailsProps = {
	credits: CreditsType[];
	vat: number;
	priceLoading: boolean;
	clientContextId: string;
};

const Credits: FC<ContractDetailsProps> = ({ credits, vat, priceLoading, clientContextId }) => {
	const { values } = useFormState();
	const [total, setTotal] = useState<number>(0);

	useEffect(() => {
		const totalCredits = credits.reduce((acc, credit) => {
			const quantity = parseFloat(values[credit.creditPrefix]) || 0;
			const price = parseFloat(values[credit.creditPricePrefix]) || 0;

			return acc + quantity * price;
		}, 0);

		setTotal(totalCredits);
	}, [values, credits, vat]);

	return credits?.length ? (
		<Styled.FormBlockWrap title='Credits' clientContextId={clientContextId}>
			<>
				{credits.map((credit, index) => {
					const price = +values[credit.creditPrefix] * +values[credit.creditPricePrefix] || 0;

					return (
						<FormBlockLine key={credit.id}>
							<Field name={credit.creditPrefix} validate={numberValidator}>
								{({ input, meta }) => (
									<FieldWrapper
										name={credit.creditPrefix}
										label={credit?.name}
										errorMessage={meta.dirty && meta.error}
										isFixed
									>
										<Input
											{...input}
											type={Fields.NUMBER}
											placeholder='e.g. 10'
											minLength={0}
											disabled={priceLoading}
											min={0}
										/>
									</FieldWrapper>
								)}
							</Field>
							<Field name={credit.creditPricePrefix} validate={numberValidator}>
								{({ input, meta }) => (
									<FieldWrapper
										name={credit.creditPricePrefix}
										label='Price'
										errorMessage={meta.dirty && meta.error}
										isFixed
									>
										<Input {...input} placeholder='e.g. 10' disabled={priceLoading} min={0} />
									</FieldWrapper>
								)}
							</Field>
							<Styled.Price>
								{index === 0 && <span>Total:</span>}
								{CurrencyType.PoundSterling}
								{price?.toFixed(2)}
							</Styled.Price>
						</FormBlockLine>
					);
				})}
			</>
			<Styled.Line />
			<Styled.TotalPriceSmallSize>
				<span>Total Ex VAT:</span>
				<span>
					{CurrencyType.PoundSterling}
					{total?.toFixed(2)}
				</span>
			</Styled.TotalPriceSmallSize>
			<Styled.TotalPriceSmallSize>
				<span>VAT:</span>
				<span>
					{CurrencyType.PoundSterling}
					{(values.chargeVat ? total * +vat : 0)?.toFixed(2)}
				</span>
			</Styled.TotalPriceSmallSize>
			<Styled.Line />
			<Styled.TotalPrice>
				<span>Total:</span>
				<span>
					{CurrencyType.PoundSterling}
					{(values.chargeVat ? total * +vat + total : total)?.toFixed(2)}
				</span>
			</Styled.TotalPrice>
		</Styled.FormBlockWrap>
	) : null;
};

export default memo(Credits);
