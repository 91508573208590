import Cookies from 'js-cookie';

import axiosInstance from 'api/axiosInstance';
import { CookiesType } from 'types';

const baseUrl = process.env.REACT_APP_API_URL;

export const createAtsBranding = async (data) => {
	const response = await axiosInstance({
		url: `${baseUrl}/branding/ats`,
		data,
		method: 'POST',
		headers: {
			'X-Authorization': `Bearer ${Cookies.get(CookiesType.JWT)}`,
		},
	});

	return response?.data;
};

export const updateAtsBranding = async (data) => {
	const response = await axiosInstance({
		url: `${baseUrl}/branding/ats`,
		data,
		method: 'PUT',
		headers: {
			'X-Authorization': `Bearer ${Cookies.get(CookiesType.JWT)}`,
		},
	});

	return response?.data;
};

export const createCareerBranding = async (data) => {
	const response = await axiosInstance({
		url: `${baseUrl}/branding/career`,
		data,
		method: 'POST',
		headers: {
			'X-Authorization': `Bearer ${Cookies.get(CookiesType.JWT)}`,
		},
	});

	return response?.data;
};

export const updateCareerBranding = async (data) => {
	const response = await axiosInstance({
		url: `${baseUrl}/branding/career`,
		data,
		method: 'PUT',
		headers: {
			'X-Authorization': `Bearer ${Cookies.get(CookiesType.JWT)}`,
		},
	});

	return response?.data;
};

export const createClientBenefits = async (data) => {
	const response = await axiosInstance({
		url: `${baseUrl}/branding/benefit`,
		data,
		method: 'POST',
		headers: {
			'X-Authorization': `Bearer ${Cookies.get(CookiesType.JWT)}`,
		},
	});

	return response?.data;
};

export const updateClientBenefits = async (data) => {
	const response = await axiosInstance({
		url: `${baseUrl}/branding/benefit`,
		data,
		method: 'PUT',
		headers: {
			'X-Authorization': `Bearer ${Cookies.get(CookiesType.JWT)}`,
		},
	});

	return response?.data;
};

export const removeBenefit = async (benefitId) => {
	const response = await axiosInstance({
		url: `${baseUrl}/branding/benefit/${benefitId}`,
		method: 'DELETE',
		headers: {
			'X-Authorization': `Bearer ${Cookies.get(CookiesType.JWT)}`,
		},
	});

	return response?.data;
};

export const getAtsBrandingByClientId = async (clientId) => {
	const response = await axiosInstance({
		url: `${baseUrl}/branding/ats/client/${clientId}`,
		method: 'GET',
		headers: {
			'X-Authorization': `Bearer ${Cookies.get(CookiesType.JWT)}`,
		},
	});

	return response?.data;
};

export const getCareerBrandingByClient = async (clientId) => {
	const response = await axiosInstance({
		url: `${baseUrl}/branding/career/client/${clientId}`,
		method: 'GET',
		headers: {
			'X-Authorization': `Bearer ${Cookies.get(CookiesType.JWT)}`,
		},
	});

	return response?.data;
};

const brandingService = {
	getAtsBrandingByClientId,
	getCareerBrandingByClient,
	createAtsBranding,
	createClientBenefits,
	updateClientBenefits,
	removeBenefit,
	createCareerBranding,
	updateAtsBranding,
	updateCareerBranding,
};

export default brandingService;
