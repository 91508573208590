import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		h2 {
			margin-bottom: 24px;
		}
	`;

	export const FieldWrapper = styled.div`
		.ant-select {
			width: 100%;
		}
	`;

	export const FieldLabel = styled.div`
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: ${COLORS.black};
		padding-bottom: 8px;
	`;

	export const ButtonWrapper = styled.div`
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		height: auto !important;
		padding-top: 36px;
	`;
}
