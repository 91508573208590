import { type FC, useState, useEffect } from 'react';

import Vimeo from '@u-wave/react-vimeo';
import { Input, Button } from 'antd';

import { BrandingMode } from 'modules/Common/components/BrandingContainer/BrandingContainer.types';
import ControlButtons from 'modules/Common/components/BrandingContainer/ControlButtons';

import { createVideoLink, isVimeoLink } from './CareerPageVideo.helper';
import { Styled } from './CareerPageVideo.styled';

type CareerPageVideoProps = {
	mode?: string;
	videoLink?: string;
	changevideoLink: (url: string) => void;
};

const CareerPageVideo: FC<CareerPageVideoProps> = ({ videoLink, changevideoLink, mode }) => {
	const [embedUrl, setEmbedUrl] = useState(videoLink || '');
	const [isEditingActive, setEditingActive] = useState(false);
	const [linkError, setLinkError] = useState<string>('');
	const [inputValue, setInputValue] = useState('');
	const saveNewVideoLink = () => {
		const preparedUrl: string = createVideoLink(inputValue);

		const resetState = (url = '') => {
			setEmbedUrl(url);
			setEditingActive(false);
			changevideoLink(url);
			setLinkError('');
			setInputValue('');
		};

		if (preparedUrl) {
			resetState(preparedUrl);
		} else if (inputValue.trim() === preparedUrl) {
			resetState();
		} else {
			setLinkError('Please provide a valid youtube or vimeo video url');
		}
	};

	useEffect(() => {
		if (videoLink) {
			setEmbedUrl(videoLink);
		}
	}, [videoLink, setEmbedUrl]);

	const showVideoBlock = videoLink || mode === BrandingMode.Edit;

	return (
		<Styled.Root id='about-us'>
			{showVideoBlock && (
				<Styled.Inner>
					{mode === BrandingMode.Edit && embedUrl && (
						<Styled.VideoUloaderControl>
							<ControlButtons onEdit={() => setEmbedUrl('')} />
						</Styled.VideoUloaderControl>
					)}
					{embedUrl && !isEditingActive && (
						<>
							{isVimeoLink(embedUrl) ? (
								<Vimeo video={embedUrl} width={1340} height={600} />
							) : (
								<iframe
									title='careerPageVideo'
									id='ytplayer'
									width='100%'
									height='100%'
									src={embedUrl}
									frameBorder='0'
								/>
							)}
						</>
					)}
					{(!embedUrl || isEditingActive) && mode === BrandingMode.Edit && (
						<Styled.VideoUploader>
							<Styled.VideoUloaderInner>
								<Styled.VideoUloaderTitle>Youtube/Vimeo link</Styled.VideoUloaderTitle>
								<Styled.VideoUloaderField>
									<Input onChange={(e) => setInputValue(e.target.value)} />
								</Styled.VideoUloaderField>
								{linkError && <Styled.VideoErrorMessage>{linkError}</Styled.VideoErrorMessage>}
								<Styled.VideoUloaderButton>
									<Button type='primary' onClick={saveNewVideoLink}>
										Save Changes
									</Button>
								</Styled.VideoUloaderButton>
							</Styled.VideoUloaderInner>
						</Styled.VideoUploader>
					)}
				</Styled.Inner>
			)}
		</Styled.Root>
	);
};

export default CareerPageVideo;
