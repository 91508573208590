import React from 'react';

import VideoInterview from 'modules/Common/containers/VideoInterview';

import { Styled } from './InterviewPage.styled';

const InterviewPage = () => {
	return (
		<Styled.Root>
			<Styled.ContentWrapper>
				<Styled.ContentInner>
					<VideoInterview />
				</Styled.ContentInner>
			</Styled.ContentWrapper>
		</Styled.Root>
	);
};

export default InterviewPage;
