import styled from 'styled-components/macro';

import { COLORS, device } from 'theme';

export namespace Styled {
	export const Toolbar = styled.div`
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: 16px;
		padding: 4px 0;

		@media ${device.tablet} {
			justify-content: space-between;
		}
	`;

	export const TodayWrapper = styled.div``;

	export const DateRangeWrapper = styled.div`
		flex: 0 0 auto;
		display: flex;
		align-items: center;

		@media ${device.tablet} {
			margin: 0;
			transform: none;
		}

		button.css-dev-only-do-not-override-1m62vyb.ant-btn.ant-btn-sm.ant-btn-icon-only {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			border-color: ${COLORS.blue3};
		}
	`;

	export const DateRangeLabel = styled.span`
		display: inline-block;
		min-width: 162px;
		padding: 0 16px;
		font-size: 16px;
		line-height: 16px;
		font-weight: 600;
		text-align: center;

		&.day {
			min-width: 240px
		}

		&.month {
			min-width: 152px;
		}
	`;

	export const ViewsWrapper = styled.div`
		position: absolute;
		right: 0;

		@media ${device.tablet} {
			position: relative;
		}
	`;

	export const ViewButton = styled.button`
		font-size: 16px;
		line-height: 11px;
		font-weight: 600;
		color: ${COLORS.blue2};
		margin-right: 8px;
		padding: 8px 12px;
		background-color: transparent;
		border: none;
		text-transform: capitalize;
		transition: background-color 0.2s ease;
		cursor: pointer;

		&:hover,
		&.rbc-active {
			background-color: ${COLORS.lightBlue5};
		}
	`;
}
