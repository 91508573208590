import React, { Key } from 'react';
import { Link } from 'react-router-dom';

import { Popconfirm, Space, Tooltip } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import ArchiveIcon from 'components/SVG/ArchiveIcon';
import DeleteIcon from 'components/SVG/DeleteIcon';
import EditIcon from 'components/SVG/EditIcon';
import UnarchiveIcon from 'components/SVG/UarchiveIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
import { IQuestionnaires, TableColumnsType } from 'modules/Common/types';
import { COLORS, TStyled } from 'theme';
import { Routes } from 'types';
import { getDateFormat } from 'utils/helpers';

type DataTableType = IQuestionnaires & {
	key?: Key;
};

export const columns = (
	isActive: boolean,
	handleDeleteQuestionnaire: (id: number, isActive: boolean) => void,
	onChangeArchive: (id: number, isActive: boolean) => void,
): ColumnsDataType & TableColumnsType => [
	{
		title: 'Questionnaire',
		dataIndex: 'title',
		key: 'title',
		render: (_: string, { title }: Partial<DataTableType>) => {
			return (
				<TStyled.PageLink to={`${Routes.ATS}${Routes.Questionnaires}`} state={{ title }}>
					<span>{title}</span>
				</TStyled.PageLink>
			);
		},
	},
	{
		title: 'Number of questions',
		dataIndex: 'numberOfQuestions',
		key: 'numberOfQuestions',
		align: 'center',
		render: (_: string, { numberOfQuestions }: Partial<DataTableType>) => (
			<span>{numberOfQuestions ? numberOfQuestions : 0}</span>
		),
	},
	{
		title: 'Created date',
		dataIndex: 'created',
		key: 'created',
		align: 'center',
		render: (_: string, { created }: Partial<DataTableType>) => {
			const formattedData = getDateFormat(created) ?? '-';

			return <>{formattedData}</>;
		},
	},
	{
		title: 'Actions',
		key: 'action',
		align: 'center',
		width: '176px',
		render: (_: string, { id, title }: Partial<DataTableType>) => (
			<Space size='middle'>
				<Popconfirm
					placement='top'
					title='Are you sure?'
					onConfirm={() => id && onChangeArchive(id, isActive)}
					okText='Yes'
					cancelText='Cancel'
					className='questionnaire-popconfirm'
				>
					<Tooltip placement='top' title={isActive ? 'Archive' : 'Un-archive'}>
						<TStyled.Button
							buttonType={ButtonTypes.default}
							icon={
								isActive ? (
									<ArchiveIcon width='16' height='16' />
								) : (
									<UnarchiveIcon width='16' height='16' />
								)
							}
						/>
					</Tooltip>
				</Popconfirm>
				<Link to={`${Routes.ATS}${Routes.QuestionnairesEdit}/${id}`} state={{ title }}>
					<Tooltip placement='top' title='Edit'>
						<TStyled.Button
							buttonType={ButtonTypes.default}
							icon={<EditIcon fill={COLORS.black} />}
						/>
					</Tooltip>
				</Link>
				<Popconfirm
					placement='bottomRight'
					title='Are you sure? Incomplete questionnaires will still be accessible by candidates. In order to delete it from candidate recall it.'
					onConfirm={() => id && handleDeleteQuestionnaire(id, isActive)}
					okText='Yes'
					cancelText='Cancel'
					className='questionnaire-popconfirm'
				>
					<Tooltip placement='top' title='Delete'>
						<TStyled.Button
							buttonType={ButtonTypes.default}
							icon={<DeleteIcon fill={COLORS.black} width='16' height='16' />}
						/>
					</Tooltip>
				</Popconfirm>
			</Space>
		),
	},
];
