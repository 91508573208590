import { memo } from 'react';

function StripeLock() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
			<path fill='#9D9DA1' d='M10 16a2 2 0 114 0 2 2 0 01-4 0z'></path>
			<path
				fill='#9D9DA1'
				fillRule='evenodd'
				d='M7 10V7a5 5 0 1110 0v3a3 3 0 013 3v6a3 3 0 01-3 3H7a3 3 0 01-3-3v-6a3 3 0 013-3zm2.879-5.121A3 3 0 0115 7v3H9V7a3 3 0 01.879-2.121zM17 12a1 1 0 011 1v6a1 1 0 01-1 1H7a1 1 0 01-1-1v-6a1 1 0 011-1h10z'
				clipRule='evenodd'
			></path>
		</svg>
	);
}

export default memo(StripeLock);
