import React, { type FC } from 'react';

import { TimeOptions } from 'modules/Common/constants';
import { IContractValues } from 'modules/Common/types';

import { Styled } from './ContractDetails.styled';

type ContractDetailsProps = {
	contractFields: IContractValues;
	isBackOfficeUser: boolean;
	clientContextId: string;
};

const ContractDetails: FC<ContractDetailsProps> = ({
	contractFields,
	isBackOfficeUser = false,
	clientContextId,
}) => {
	const durationDaysTimeOption = TimeOptions.find(
		(item) => item.value === contractFields?.durationDaysTimeUnit,
	)?.label.slice(0, -3);
	const paymentTermOption = TimeOptions.find(
		(item) => item.value === contractFields?.paymentTermTimeUnit,
	)?.label.slice(0, -3);

	return (
		<Styled.BoxWrap isBoxComponent={!clientContextId}>
			<h2>Contract Details</h2>
			<>
				{isBackOfficeUser && (
					<Styled.FieldRow>
						<Styled.Field>
							<h4>Client name</h4>
							<span>{contractFields?.clientName}</span>
						</Styled.Field>
						<Styled.Field />
					</Styled.FieldRow>
				)}
			</>
			<Styled.FieldRow>
				<Styled.Field>
					<h4>PO Number</h4>
					<span>{contractFields?.poNumber ?? '-'}</span>
				</Styled.Field>
				<Styled.Field>
					<h4>Agreement Reference</h4>
					<span>{contractFields?.btoReference ?? '-'}</span>
				</Styled.Field>
			</Styled.FieldRow>
			<Styled.FieldRow>
				<Styled.Field>
					<h4>Contract Duration</h4>
					<span style={{ maxWidth: '160px' }}>
						{durationDaysTimeOption &&
							`${contractFields?.durationDays} ${
								parseInt(contractFields?.durationDays) === 1
									? durationDaysTimeOption
									: durationDaysTimeOption + 's'
							}`}
					</span>
				</Styled.Field>
				<Styled.Field>
					<h4>Payment Terms</h4>
					<span style={{ maxWidth: '160px' }}>
						{paymentTermOption &&
							`${contractFields?.paymentTerm} ${
								parseInt(contractFields?.paymentTerm) === 1
									? paymentTermOption
									: paymentTermOption + 's'
							}`}
					</span>
				</Styled.Field>
			</Styled.FieldRow>
			<Styled.FieldRow>
				<Styled.Field>
					<h4>Bluetown Details</h4>
					<span style={{ minHeight: '150px' }}>{contractFields?.bluetownDetails}</span>
				</Styled.Field>
				<Styled.Field>
					<h4>Customer Details</h4>
					<span style={{ minHeight: '150px' }}>{contractFields?.clientOfficeAddress}</span>
				</Styled.Field>
			</Styled.FieldRow>
		</Styled.BoxWrap>
	);
};

export default ContractDetails;
