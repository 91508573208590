import React from 'react';

import FormBlock from 'modules/Common/components/FormBlock';

import { Styled } from './JobBoards.styled';

const brandedWithYourLogos = [
	{ key: '10', height: '28px' },
	{ key: '11', height: '16px' },
	{ key: '12', height: '35px' },
	{ key: '13', height: '35px' },
	{ key: '15', height: '36px' },
	{ key: '16', height: '15px' },
	{ key: '1', height: '27px' },
	{ key: '3', height: '26px' },
	{ key: '4', height: '21px' },
];
const brandedWithOurLogos = [
	{ key: '9', height: '26px' },
	{ key: '17', height: '25px' },
	{ key: '5', height: '18px' },
	{ key: '27', height: '19px' },
	{ key: '18', height: '23px' },
	{ key: '2', height: '23px' },
	{ key: '28', height: '20px' },
	{ key: '19', height: '24px' },
	{ key: '29', height: '16px' },
	{ key: '21', height: '36px' },
	{ key: '20', height: '20px' },
	{ key: '22', height: '66px' },
	{ key: '24', height: '30px' },
	{ key: '25', height: '26px' },
	{ key: '26', height: '26px' },
	{ key: '7', height: '21px' },
];

const JobBoards = () => {
	return (
		<FormBlock title='Job Boards'>
			<Styled.TextHint>
				Jobs listed are posted to the following premium fee-charging job boards (*free posting on
				Linkedin)
			</Styled.TextHint>
			<Styled.JBSection>
				<Styled.JBSectionTitle>
					Branded with <Styled.JBTitleHighlight>your</Styled.JBTitleHighlight> logo
				</Styled.JBSectionTitle>
				<Styled.JBLogos>
					{brandedWithYourLogos.map((i) => (
						<Styled.JBLogo key={i.key}>
							<Styled.JBLogoImage height={i.height} logoUrl={`/images/jobBoards/${i.key}.png`} />
						</Styled.JBLogo>
					))}
				</Styled.JBLogos>
			</Styled.JBSection>
			<Styled.JBSection>
				<Styled.JBSectionTitle>
					Branded with <Styled.JBTitleHighlight>our</Styled.JBTitleHighlight> logo
				</Styled.JBSectionTitle>
				<Styled.JBLogos>
					{brandedWithOurLogos.map((i) => (
						<Styled.JBLogo key={i.key}>
							<Styled.JBLogoImage height={i.height} logoUrl={`/images/jobBoards/${i.key}.png`} />
						</Styled.JBLogo>
					))}
				</Styled.JBLogos>
			</Styled.JBSection>
			<Styled.JBNote>
				Note: Each credit goes across all relevant boards. If the client has previously had an
				agreement with a job board we may not be able to advertise your brand with your logo.
				Generally if you are using the portal the same branding will be applicable as the original
				purchase. Please validate this before signing and paying if you are uncertain. Postings last
				28 days (except GAAPweb 7 days). Postings will include only be on Indeed (paid for) and the
				Guardian if upgraded above.
			</Styled.JBNote>
		</FormBlock>
	);
};

export default JobBoards;
