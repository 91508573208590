import styled from 'styled-components/macro';

import { device } from 'theme';

interface HeroStyledProps {
	width: string;
	height: string;
	minHeight: string;
	maxHeight: string;
	backgroundImageUrl: string;
	headerBackground?: string;
}

export namespace Styled {
	export const HeroImage = styled.div<HeroStyledProps>`
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: ${({ minHeight }) => minHeight};
		max-height: ${({ maxHeight }) => maxHeight};
		width: ${({ width }) => width};
		height: ${({ height }) => height};
		${({ backgroundImageUrl }) =>
		backgroundImageUrl &&
			`background: url(${backgroundImageUrl}) no-repeat; background-size: cover; background-position: center;`};
		${({ headerBackground }) => headerBackground && `background-color: ${headerBackground};`};

		@media ${device.tablet} {
			min-height: ${({ minHeight }) => `calc(${minHeight} * 0.75)`};
			height: ${({ height }) => `calc(${height} * 0.75)`};
		}
	`;
}
