import { FC } from 'react';

import { Pagination } from 'antd';

import { Styled } from './Pagination.styled';

type PaginationTypeProps = {
	pageSize: number;
	current: number;
	total: number;
	pageSizeOptions?: string[] | number[];
	onChange: (page: number, size: number) => void;
	onShowSizeChange?: (page: number, size: number) => void;
};

const PaginationComponent: FC<PaginationTypeProps> = ({
	current,
	pageSize,
	total,
	pageSizeOptions,
	onChange,
	onShowSizeChange,
}) => {
	const paginationProps = {
		current: current + 1,
		defaultPageSize: pageSize,
		pageSize,
		total,
		pageSizeOptions,
		showQuickJumper: true,
		showSizeChanger: true,
	};

	return (
		<Styled.Root>
			<Pagination
				{...paginationProps}
				onChange={(page, size) => onChange && onChange(page, size)}
				onShowSizeChange={onShowSizeChange}
				showTotal={(totalCount) => `Total ${totalCount} items`}
			/>
		</Styled.Root>
	);
};

export default PaginationComponent;
