import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function RocketIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M10.8968 5.5695C13.5144 2.95186 17.18 1.66594 20.8593 2.07454C21.0009 2.09027 21.1127 2.20208 21.1284 2.34371C21.5373 6.02321 20.2514 9.68904 17.6335 12.3069L9.97791 19.9625C9.79601 20.1444 9.50108 20.1444 9.31918 19.9625L3.24085 13.8842C3.05895 13.7023 3.05895 13.4073 3.24085 13.2254L10.8968 5.5695Z'
				fill={fill}
			/>
			<path
				d='M14.1041 11.5695C13.422 12.2517 12.316 12.2517 11.6339 11.5695C10.9517 10.8874 10.9517 9.7814 11.6339 9.09926C12.316 8.41712 13.422 8.41712 14.1041 9.09926C14.7863 9.7814 14.7863 10.8874 14.1041 11.5695Z'
				fill='white'
			/>
			<path
				d='M2.20302 9.88639L5.50642 12.1438C5.69768 12.2745 5.95593 12.2448 6.11251 12.0741L9.06839 8.85116C9.31027 8.58743 9.17676 8.16077 8.82768 8.08196L5.96326 7.43528C5.86154 7.41231 5.75506 7.42414 5.66087 7.46888L2.26601 9.08105C1.94129 9.23526 1.90623 9.68357 2.20302 9.88639Z'
				fill={fill}
			/>
			<path
				d='M13.3169 21.0003L11.0595 17.6969C10.9288 17.5056 10.9585 17.2474 11.1292 17.0908L14.3521 14.1349C14.6159 13.893 15.0425 14.0265 15.1213 14.3756L15.768 17.24C15.791 17.3418 15.7792 17.4482 15.7344 17.5424L14.1222 20.9373C13.968 21.262 13.5197 21.2971 13.3169 21.0003Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(RocketIcon);
