import React, { FC, useState } from 'react';
import { Field, useFormState } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';

import { InboxOutlined } from '@ant-design/icons';
import { Button, Input, Upload } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';

import FieldWrapper from 'components/FieldWrapper';
import * as Styled from 'modules/BackOffice/containers/ClientChangesFormBranding/ClientChangesFormBranding.styled';
import FormBlock from 'modules/Common/components/FormBlock';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { acceptedImageFileFormat } from 'modules/Common/constants';
import { ClientChangeFormDataType } from 'modules/Common/types';
import { getSingleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import { Routes } from 'types';
import { requiredFieldValidator } from 'utils/validators';

const { TextArea } = Input;

const { Dragger } = Upload;

const baseUrl = `${process.env.REACT_APP_API_URL}/open/public/file/logo/`;

type CustomBrandingProps = {
	saveClientChangesFormProcess: (values: Partial<ClientChangeFormDataType>) => void;
	clientChangesFormData: ClientChangeFormDataType;
};

const CustomBranding: FC<CustomBrandingProps> = ({
	saveClientChangesFormProcess,
	clientChangesFormData,
}) => {
	const navigate = useNavigate();
	const { clientId } = useParams();
	const navigateUrl = clientId
		? `${Routes.BackOffice}${Routes.EditClientBranding}/${clientId}`
		: `${Routes.BackOffice}${Routes.CreateClientBranding}`;
	const navigateToBranding = () => {
		navigate(navigateUrl, { state: { clientId, clientName: clientChangesFormData.clientName } });
	};

	const { values } = useFormState();

	const handleManageBrandingClick = () => {
		saveClientChangesFormProcess(values);
		navigateToBranding();
	};

	const logo = clientChangesFormData?.logo;

	const logoItem = logo && [
		{
			uid: logo,
			name: logo?.split('/')?.slice(-1),
			status: 'done',
			url: logo?.split('/')?.includes('images') ? logo : logo && `${baseUrl}${logo}`,
		},
	];

	const [fileList, setFileList] = useState<UploadFile[] | unknown>(logoItem ? logoItem : []);

	const draggerProps = (fieldOnchange: (fileName: string | null) => null) =>
		getSingleFileUploaderProps(
			(url, responseData) => {
				saveClientChangesFormProcess({
					atsBranding: {
						...clientChangesFormData.atsBranding,
						logo: responseData,
					},
					careerBranding: {
						...clientChangesFormData.careerBranding,
						logo: responseData,
					},
				});

				fieldOnchange(responseData);
			},
			// @ts-ignore
			{ fileList, accept: acceptedImageFileFormat, onRemove: () => fieldOnchange(null) },
			setFileList,
		);

	return (
		<FormBlock title='Custom Branding'>
			<FormBlockLine>
				<Styled.FieldWrapper>
					<Field
						name='logo'
						validate={requiredFieldValidator}
						initialValue={clientChangesFormData.logo}
					>
						{({ input, meta }) => (
							<FieldWrapper errorMessage={meta.submitFailed && meta.touched && meta.error}>
								<Styled.FieldLabel>Logo</Styled.FieldLabel>
								<Dragger {...draggerProps(input.onChange)}>
									<p className='ant-upload-drag-icon'>
										<InboxOutlined />
									</p>
									<p className='ant-upload-text'>Upload a files or drag and drop it</p>
									<p className='ant-upload-hint'> PNG, JPEG, SVG up to 15MB </p>
								</Dragger>
								<Styled.FieldHint>
									Logo should be on a white or transparent background.
								</Styled.FieldHint>
							</FieldWrapper>
						)}
					</Field>
				</Styled.FieldWrapper>
				<Styled.FieldWrapper>
					<Field
						name='companyAddress'
						validate={requiredFieldValidator}
						initialValue={clientChangesFormData.companyAddress}
					>
						{({ input, meta }) => (
							<FieldWrapper
								name='companyAddress'
								label='Address'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<TextArea
									{...input}
									placeholder={'Building, \nRoad, \nCountry, \nPostcode'}
									autoSize={{ minRows: 7 }}
								/>
							</FieldWrapper>
						)}
					</Field>
				</Styled.FieldWrapper>
			</FormBlockLine>
			<Styled.CreditCheckFooter>
				<Button disabled={!values.clientName} type='primary' onClick={handleManageBrandingClick}>
					Manage Branding
				</Button>
			</Styled.CreditCheckFooter>
		</FormBlock>
	);
};

export default CustomBranding;
