import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	`;
	export const CreditsWrapper = styled.section`
		justify-content: space-between;
		width: 100%;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		column-gap: 24px;
	`;

	export const ClientCreditItem = styled.div`
		border-radius: 4px;
		background: ${COLORS.white};
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		padding: 8px 16px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	`;

	export const ClientCreditCount = styled.div`
		color: ${COLORS.darkGray3};
		font-family: Inter;
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;
		margin-right: 8px;
		text-align: center;
	`;

	export const ClientCreditName = styled.div`
		color: ${COLORS.black};
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-align: center;
	`;
}
