import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;
	`;

	export const Button = styled(ButtonComponent)`
		background: none;
		border: none;
	`;
}
