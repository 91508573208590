import { memo } from 'react';

import { IconType } from '../SVG.types';

function TwitterIcon({ width = '22', height = '22' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fill='#39393B'
				d='M23.142 5.098a9.16 9.16 0 01-2.63.707 4.557 4.557 0 002.009-2.518 9.018 9.018 0 01-2.898 1.104 4.556 4.556 0 00-3.338-1.444 4.566 4.566 0 00-4.567 4.57c0 .353.043.707.113 1.047a12.98 12.98 0 01-9.418-4.781c-.41.7-.624 1.496-.621 2.306a4.57 4.57 0 002.035 3.806 4.607 4.607 0 01-2.065-.58v.055c0 2.221 1.57 4.061 3.662 4.484a4.842 4.842 0 01-1.203.156c-.297 0-.579-.03-.863-.07a4.576 4.576 0 004.27 3.169 9.155 9.155 0 01-5.67 1.953 9.33 9.33 0 01-1.104-.057 12.934 12.934 0 007.015 2.05c8.4 0 12.997-6.96 12.997-13 0-.198 0-.396-.014-.594a9.837 9.837 0 002.29-2.363z'
			></path>
		</svg>
	);
}

export default memo(TwitterIcon);
