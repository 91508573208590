export const INTERVIEW_CANDIDATE_FIELDS = [
	'id',
	'candidate',
	'rank',
	'candidateId',
	'startDate',
	'time',
	'status',
	'type',
	'guests',
];
