import { Button as ButtonComponent } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		width: 100%;
	`;

	export const Head = styled.div`
		display: flex;
		justify-content: space-between;
		margin-bottom: 24px;
	`;

	export const Fields = styled.div`
		display: flex;
		flex-direction: column;
		margin: 16px 0;
		gap: 16px;
	`;

	export const FieldSpace = styled.div`
		margin: 24px 0;
	`;

	export const DeleteButton = styled(ButtonComponent)`
		border: 1px solid ${COLORS.red};
		color: ${COLORS.red};
	`;
}
