import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.main`
		width: 100%;
		max-width: 1368px;
		margin: 0 auto;
		padding: 0 32px;
	`;
	export const Title = styled.div`
		padding: 42px 0 18px;
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%;
		text-transform: capitalize;
	`;
	export const Content = styled.div`
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 138px 0;
		background: ${COLORS.lightGray3};
		border: 1px solid ${COLORS.lightGray3};
		box-shadow: ${COLORS.elevationShadow};
		border-radius: 4px;
	`;
	export const ContentInner = styled.div`
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		flex-direction: column;
	`;
	export const ContentTitle = styled.div`
		padding: 48px 0;
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
	`;
	export const ContentDescription = styled.div`
		margin-bottom: 48px;
		width: 100%;
	`;
	export const ContentDescriptionLine = styled.div`
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		width: 100%;
		text-align: center;
	`;
}
