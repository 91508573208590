import { type FC, type ReactNode, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import BlueTownLogo from 'components/SVG/BlueTownLogo';
import { useSubdomain } from 'hooks/useSubdomain';
import Image from 'modules/Common/components/Image';
import { Routes } from 'types';

import { unregisteredDucks } from '../../ducks';

import * as Styled from './UnregisteredUser.styled';

type UnregisteredProps = {
	clientInfo: {
		subdomain: string;
		logo: Blob | MediaSource | string;
	};
	checkClientInfoRequested: (subdomain: string, cb: () => void) => void;
	pageContent?: JSX.Element;
};

const UnregisteredUser: FC<UnregisteredProps> = ({
	clientInfo,
	checkClientInfoRequested,
	pageContent,
}) => {
	const [logo, setLogo] = useState<ReactNode | null>(null);
	const [error, setError] = useState<string>('');

	const navigate = useNavigate();
	const subdomain = useSubdomain(process.env?.REACT_APP_ENV === 'prod');

	const handleError = useCallback((value: string) => setError(value), []);

	useEffect(() => {
		if (subdomain) {
			if (!clientInfo?.subdomain) {
				checkClientInfoRequested(subdomain, () => navigate(Routes.Login));
			}

			const logoComponent = clientInfo?.subdomain && (
				<Image path={clientInfo?.subdomain} setError={handleError} />
			);
			setLogo(!error ? logoComponent : <BlueTownLogo />);
		} else {
			setLogo(<BlueTownLogo />);
		}
	}, [clientInfo?.subdomain, error]);

	return (
		<Styled.PageWrapper>
			<Styled.PageContent>
				<Styled.LogoWrapper>{logo}</Styled.LogoWrapper>
				{pageContent}
			</Styled.PageContent>
		</Styled.PageWrapper>
	);
};

export default connect(
	(state) => ({
		clientInfo: unregisteredDucks.unregisteredSelectors.getUser(state)?.client,
	}),
	{
		checkClientInfoRequested: unregisteredDucks.unregisteredActions.checkClientInfo,
	},
)(UnregisteredUser);
