import React, { FC, useCallback, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import CheckCircleIcon from 'components/SVG/CheckCircleIcon';
import UnregisteredInput from 'modules/Unregistered/components/UnregisteredInput';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { TStyled } from 'theme';
import { Fields, GenericType, IUserFormValues, Routes } from 'types';
import { emailValidator, composeValidators, requiredValidator } from 'utils/validators';

import { Styled } from './ForgotPasswordForm.styled';

type LoginFormProps = {
	forgotPasswordRequested: (value: IUserFormValues, cb: (route: string) => void) => void;
	errorMessage: string;
	loading: GenericType;
};

const ForgotPasswordForm: FC<LoginFormProps> = ({
	forgotPasswordRequested,
	errorMessage,
	loading,
}) => {
	const [successMessage, setSuccessMessage] = useState('');

	const [showCredentialsError, setCredErrorVisualization] = useState(false);

	const handleSetCredError = useCallback((value: boolean) => setCredErrorVisualization(value), []);

	const onSubmit = (values: IUserFormValues) => {
		forgotPasswordRequested(values, () =>
			setSuccessMessage(`A new password has been sent to ${values?.email}`),
		);
		handleSetCredError(true);
	};

	return (
		<Styled.Form>
			{successMessage ? (
				<Styled.MessageInfo>
					<CheckCircleIcon />
					<p>{successMessage}</p>
				</Styled.MessageInfo>
			) : (
				<>
					<p>
						Please enter the email associated with your <br />
						Connect account
					</p>
					<Form
						onSubmit={onSubmit}
						render={({ handleSubmit, valid, dirtySinceLastSubmit }) => {
							if (dirtySinceLastSubmit) {
								handleSetCredError(false);
							}

							return (
								<form onSubmit={handleSubmit}>
									<Field
										name='email'
										validate={composeValidators(requiredValidator, emailValidator)}
									>
										{({ input, meta }) => (
											<UnregisteredInput
												{...input}
												type={Fields.TEXT}
												placeholder='Email address'
												isFullWidth
												errorMessage={meta.error && meta.dirty && meta.error}
											/>
										)}
									</Field>

									{showCredentialsError && errorMessage && typeof errorMessage === 'string' && (
										<TStyled.ErrorMessage>{errorMessage}</TStyled.ErrorMessage>
									)}

									<Button
										isFullWidth
										buttonType={ButtonTypes.primary}
										type='submit'
										disabled={!valid || !!loading?.forgotPasswordLoad}
										loading={!!loading?.forgotPasswordLoad}
									>
										Reset Password
									</Button>
									<TStyled.UnregisteredFormLink to={Routes.ForgotPass}>
										Login
									</TStyled.UnregisteredFormLink>
								</form>
							);
						}}
					/>
				</>
			)}
		</Styled.Form>
	);
};

export default connect(
	(state) => ({
		errorMessage: unregisteredDucks.unregisteredSelectors.getErrorMessage(state),
		loading: unregisteredDucks.unregisteredSelectors.getLoading(state),
	}),
	{
		forgotPasswordRequested: unregisteredDucks.unregisteredActions.forgotPasswordRequested,
	},
)(ForgotPasswordForm);
