import { Link } from 'react-router-dom';

import { Checkbox as CheckboxComponent } from 'antd';
import styled from 'styled-components/macro';

import ButtonComponent from '../components/Button';

import { COLORS } from './index';

export namespace TStyled {
	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
		margin-bottom: 32px;
		gap: 16px;
	`;

	export const ErrorMessage = styled.span`
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		color: ${COLORS.red};
		padding-top: 3px;
	`;

	export const ButtonLink = styled(Link)``;

	export const CheckboxOption = styled(CheckboxComponent)`
		display: flex;
		width: 100%;
		font-size: 16px;
	`;

	export const PageLink = styled((props) => <Link {...props} />)`
		display: flex;
		line-height: 28px;
		min-height: 28px;
		min-width: 28px;
		font-weight: 500;
		color: ${COLORS.black};
		gap: 4px;
	`;

	export const Button = styled(ButtonComponent)`
		line-height: 28px;
		height: 28px;
		min-width: 28px;
		padding: 4px 8px;
		font-size: 14px;
		gap: 8px;
	`;

	export const ButtonCancel = styled(ButtonComponent)`
		display: flex;
		border: 1px solid ${COLORS.red};
		color: ${COLORS.red};
		white-space: nowrap;
	`;

	export const UnregisteredFormWrapper = styled.div`
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 40px;
		text-align: center;
		border-radius: 6px;
		background-color: ${COLORS.white};
		box-shadow: ${COLORS.elevationShadow1};
	`;

	export const UnregisteredFormTitle = styled.h3`
		padding-bottom: 24px;
		line-height: 32px;
		font-style: normal;
		color: ${COLORS.black};
	`;

	export const SearchResultInfo = styled.p`
		margin: 24px 0 16px;
		font-weight: 500;
		font-size: 16px;
		color: ${COLORS.darkGray2};
	`;

	export const UnregisteredFormLink = styled(Link)`
		margin-top: 24px;
		font-weight: 400;
		color: ${COLORS.blue};
		cursor: pointer;
		transition: color ease 0.2s;

		&:hover {
			color: ${COLORS.blue1};
		}
	`;
}
