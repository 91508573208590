import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function ArchiveIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M3 5.38277C3 4.77927 3.27252 4.20803 3.74158 3.8283L5.44358 2.45045C5.80335 2.1592 6.25301 2.00176 6.71588 2.00497L12.16 2.04273L17.2902 2.01206C17.7517 2.0093 18.2001 2.16628 18.5591 2.45638L20.257 3.82827C20.7269 4.20798 21 4.77976 21 5.38392V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V5.38277Z'
				fill={fill}
			/>
			<path d='M6.40025 3H17.5989L19.0113 4.21191H4.9824L6.40025 3Z' fill='white' />
			<path
				d='M6.70979 11.9665C6.53396 11.9665 6.4437 12.1771 6.56495 12.3044L11.8755 17.8813C11.9544 17.9641 12.0865 17.964 12.1653 17.8812L17.4689 12.3044C17.59 12.177 17.4997 11.9665 17.324 11.9665H14.2037C14.0932 11.9665 14.0037 11.877 14.0037 11.7665V10.1397C14.0037 10.0293 13.9141 9.93975 13.8037 9.93975H10.1899C10.0794 9.93975 9.98988 10.0293 9.98988 10.1397V11.7665C9.98988 11.877 9.90034 11.9665 9.78988 11.9665H6.70979Z'
				fill='white'
			/>
		</svg>
	);
}

export default memo(ArchiveIcon);
