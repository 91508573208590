import styled from 'styled-components/macro';

interface IStyled {
	isDisabled: boolean;
}

export namespace Styled {
	export const Row = styled.tr<IStyled>`
		cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    background-color: ${({ isDisabled }) => (isDisabled ? 'rgba(0, 0, 0, 0.04)' : 'inherit')};
  }
	`;
}
