import styled from 'styled-components/macro';

import { COLORS } from 'theme';

type FieldProps = {
	isFullWidth: boolean;
};

type FieldHintProps = {
	isError?: boolean;
	isInline?: boolean;
};

type FieldContentProps = {
	isInline?: boolean;
};

type FieldLabelProps = {
	isInline?: boolean;
};

type ErrorMessageProps = {
	isFixed: boolean;
	isInline?: boolean;
};

export namespace Styled {
	export const Field = styled.div<FieldProps>`
		font-size: 16px;
		color: ${({ theme }) => theme.colors.BLACK_LIGHT};
		width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'auto')};
	`;

	export const FieldContent = styled.div<FieldContentProps>`
		position: relative;
		display: flex;
		flex-direction: ${({ isInline }) => (isInline ? 'row' : 'column')};
		${({ isInline }) =>
		isInline &&
			`
			width: 100%;
		`}
	`;

	export const FieldHelpers = styled.div`
		position: relative;
		display: flex;
		flex-direction: column;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		white-space: break-spaces;
	`;

	export const Label = styled.label<FieldLabelProps>`
		margin-bottom: 8px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;

		span {
			display: inline-block;
			color: ${COLORS.red};
			padding: 0 4px;
		}

		${({ isInline }) =>
		isInline &&
			`
			width: 156px;
			margin-right: 16px;
			text-align: right;
			flex-shrink: 0;
		`}
	`;

	export const FieldHint = styled.div<FieldHintProps>`
		margin-top: 8px;
		color: ${COLORS.darkGray2};
		visibility: ${({ isError }) => (isError ? 'hidden' : 'visible')};
		${({ isInline }) =>
		isInline &&
			`
			margin-left: 172px;
		`}
	`;

	export const ErrorMessage = styled.span<ErrorMessageProps>`
		position: ${({ isFixed }) => (isFixed ? 'relative' : 'absolute')};
		top: 4px;
		left: 0;
		color: ${COLORS.red};
		${({ isInline }) =>
		isInline &&
			`
			margin-left: 172px;
		`}
	`;
}
