import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import OpenPageLayout from '../../components/OpenPageLayout';
import DeclineInterview from '../../containers/DeclineInterview';

const DeclineInterviewPage = () => {
	const { search } = useLocation();

	const queryParams = new URLSearchParams(search);
	const pwdId = queryParams.get('pwd');
	const { interviewId } = useParams();

	return (
		interviewId &&
		pwdId && (
			<OpenPageLayout>
				<DeclineInterview interviewId={interviewId} pwdId={pwdId} />
			</OpenPageLayout>
		)
	);
};

export default DeclineInterviewPage;
