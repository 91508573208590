import { get, post, patch } from 'api/axiosInstance';
import { RequestsEnum } from 'types';
import { getTableParams } from 'utils/helpers';

export const getEmployeeRoles = async () => {
	return get(RequestsEnum.HREmployeeRoles);
};

export const getEmployeeStatuses = async () => {
	return get(RequestsEnum.HREmployeeStatus);
};

export const getEmployeesListSuperUser = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.HREmployeeSuperUser}${queryParams}`);
};

export const getEmployeesListNonSuperUser = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.HREmployeeNonSuperUser}${queryParams}`);
};

export const archiveEmployee = async ({ employeeId, leaveDate }) =>
	patch(`${RequestsEnum.HREmployee}/${employeeId}${RequestsEnum.Archive}`, { leaveDate });

export const unArchiveEmployee = async (employeeId) =>
	patch(`${RequestsEnum.HREmployee}/${employeeId}${RequestsEnum.Unarchive}`);

export const changeEmployeeStatus = async ({ employeeId, ...props }) =>
	patch(`${RequestsEnum.HREmployee}/${employeeId}${RequestsEnum.Status}`, props);

export const addEmployee = async (data) => post(RequestsEnum.HREmployee, data);

export const getEmployeeByIdSuperUser = async (employeeId) =>
	get(`${RequestsEnum.HREmployee}/${employeeId}${RequestsEnum.SuperUser}`);

export const getEmployeeByIdNonSuperUser = async (employeeId) =>
	get(`${RequestsEnum.HREmployee}/${employeeId}${RequestsEnum.NonSuperUser}`);

export const getEmployeePhotoByUUID = async (uuid) => {
	const blob = await get(`${RequestsEnum.HREmployeePhoto}/${uuid}`, { responseType: 'blob' });
	const imageUrl = URL.createObjectURL(blob);

	return imageUrl;
};

const employeeService = {
	getEmployeeRoles,
	getEmployeeStatuses,
	addEmployee,
	getEmployeesListSuperUser,
	getEmployeesListNonSuperUser,
	archiveEmployee,
	unArchiveEmployee,
	changeEmployeeStatus,
	getEmployeeByIdSuperUser,
	getEmployeeByIdNonSuperUser,
};

export default employeeService;
