import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const FieldBox = styled.div`
		max-width: 615px;
		margin-bottom: 32px;
	`;

	export const Title = styled.h4`
		margin-bottom: 8px;
	`;

	export const Text = styled.p`
		width: 100%;
		padding: 4px 12px;
		min-height: 32px;
		flex: 1;
		font-size: 14px;
		line-height: 22px;
		background: ${COLORS.lightGray3};
		border: 1px solid ${COLORS.lightGray1};
		border-radius: 4px;
		color: ${COLORS.darkGray2};
		white-space: break-spaces;
	`;

	export const TextArea = styled(Text)`
		min-height: 142px;
	`;

	export const LogoPreview = styled.div`
		max-width: 260px;
		min-height: 146px;
		font-size: 14px;

		img {
			width: 100%;
		}
	`;
}
