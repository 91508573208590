import React from 'react';

function BlueTownLogo() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1097.995'
			height='257.917'
			version='1.1'
			viewBox='0 0 1097.995 257.917'
		>
			<defs>
				<clipPath id='clipPath16' clipPathUnits='userSpaceOnUse'>
					<path d='M0 193.438h823.496V0H0z'></path>
				</clipPath>
			</defs>
			<g transform='matrix(1.33333 0 0 -1.33333 0 257.917)'>
				<g>
					<g clipPath='url(#clipPath16)'>
						<g transform='translate(355.175 78.228)'>
							<path
								fill='#314eff'
								fillOpacity='1'
								fillRule='nonzero'
								stroke='none'
								d='M0 0c-.371.679-1.2.964-1.908.657l-9.665-4.159c-1.409-.602-2.093-.778-3.906-.778h-13.717c-1.111 0-1.459.169-1.864.572L-35.106.34c-.52.519-.573.711-.573 1.301v32.828c0 .59.053.781.573 1.301l4.046 4.046c.39.389.701.573 1.864.573h12.703c1.817 0 2.501-.175 3.88-.766l9.443-4.272a1.5 1.5 0 011.924.628l5.396 9.557a1.499 1.499 0 01-.567 2.043l-8.548 4.836c-3.808 2.143-6.005 2.666-11.19 2.666h-14.953c-4.084 0-7.529-.725-11.067-4.262l-4.271-4.272c-3.561-3.56-4.825-5.903-4.825-11.966V1.527c0-6.061 1.264-8.404 4.825-11.965l4.271-4.273c3.538-3.537 6.983-4.262 11.067-4.262h15.964c5.111 0 7.326.37 11.196 2.668l8.543 4.834c.714.405.971 1.307.577 2.027z'
							></path>
						</g>
						<g transform='translate(423.074 112.585)'>
							<path
								fill='#314eff'
								fillOpacity='1'
								fillRule='nonzero'
								stroke='none'
								d='M0 0v-32.604c0-.589-.053-.781-.572-1.3l-4.046-4.047c-.405-.404-.754-.573-1.864-.573h-17.539c-1.11 0-1.458.169-1.862.572l-4.047 4.048c-.521.521-.574.712-.574 1.3V0c0 .589.053.779.574 1.3l4.047 4.048c.39.388.699.572 1.862.572h17.539c1.163 0 1.473-.184 1.863-.572l4.048-4.049C-.053.78 0 .59 0 0m6.496 16.463C2.958 20-.486 20.725-4.57 20.725h-21.362c-4.084 0-7.528-.725-11.066-4.262l-4.273-4.273c-3.561-3.559-4.825-5.903-4.825-11.965v-33.054c0-6.062 1.264-8.404 4.825-11.966l4.273-4.272c3.538-3.537 6.982-4.262 11.066-4.262H-4.57c4.084 0 7.528.725 11.066 4.262l4.272 4.272c3.56 3.562 4.824 5.904 4.824 11.966V.225c0 6.062-1.264 8.406-4.824 11.965z'
							></path>
						</g>
						<g transform='translate(518.898 132.635)'>
							<path
								fill='#314eff'
								fillOpacity='1'
								fillRule='nonzero'
								stroke='none'
								d='M0 0h-11.691a1.5 1.5 0 01-1.5-1.5v-45.19L-45.222-2.637C-46.565-.804-47.405 0-49.805 0H-57c-2.004 0-3.299-1.295-3.299-3.299v-67.905a1.5 1.5 0 011.5-1.5h11.694a1.5 1.5 0 011.5 1.5v44.963l31.926-43.952c1.131-1.507 2.303-2.511 4.349-2.511h7.531c2.004 0 3.299 1.295 3.299 3.299V-1.5A1.5 1.5 0 010 0'
							></path>
						</g>
						<g transform='translate(602.543 132.635)'>
							<path
								fill='#314eff'
								fillOpacity='1'
								fillRule='nonzero'
								stroke='none'
								d='M0 0h-11.691a1.5 1.5 0 01-1.5-1.5v-45.19L-45.222-2.637C-46.565-.804-47.405 0-49.805 0H-57c-2.004 0-3.299-1.295-3.299-3.299v-67.905a1.5 1.5 0 011.5-1.5h11.694a1.5 1.5 0 011.5 1.5v44.963l31.926-43.952c1.131-1.507 2.303-2.511 4.349-2.511h7.531c2.004 0 3.299 1.295 3.299 3.299V-1.5A1.5 1.5 0 010 0'
							></path>
						</g>
						<g transform='translate(675.283 73.948)'>
							<path
								fill='#314eff'
								fillOpacity='1'
								fillRule='nonzero'
								stroke='none'
								d='M0 0h-33.803v15.438h27.168a1.5 1.5 0 011.5 1.5v11.018a1.5 1.5 0 01-1.5 1.5h-27.168v15.213H-.787a1.5 1.5 0 011.5 1.5v11.018a1.5 1.5 0 01-1.5 1.5H-44.41c-3.308 0-4.985-1.677-4.985-4.985V-9.032c0-3.308 1.677-4.986 4.985-4.986H0a1.5 1.5 0 011.5 1.5V-1.5A1.5 1.5 0 010 0'
							></path>
						</g>
						<g transform='translate(746.751 78.228)'>
							<path
								fill='#314eff'
								fillOpacity='1'
								fillRule='nonzero'
								stroke='none'
								d='M0 0a1.497 1.497 0 01-1.908.657l-9.665-4.159c-1.409-.602-2.093-.778-3.908-.778h-13.717c-1.11 0-1.457.169-1.862.572L-35.106.34c-.522.521-.575.712-.575 1.301v32.828c0 .589.053.779.575 1.301l4.046 4.046c.39.389.7.573 1.862.573h12.705c1.817 0 2.5-.175 3.878-.766l9.445-4.272a1.5 1.5 0 011.924.628l5.396 9.557a1.499 1.499 0 01-.567 2.043l-8.548 4.836c-3.808 2.143-6.005 2.666-11.19 2.666h-14.953c-4.084 0-7.529-.725-11.067-4.262l-4.273-4.272c-3.561-3.56-4.824-5.903-4.824-11.966V1.527c0-6.061 1.263-8.404 4.824-11.965l4.273-4.273c3.538-3.537 6.983-4.262 11.067-4.262h15.964c5.11 0 7.324.37 11.194 2.668l8.545 4.834c.714.405.971 1.307.577 2.027z'
							></path>
						</g>
						<g transform='translate(821.996 132.635)'>
							<path
								fill='#314eff'
								fillOpacity='1'
								fillRule='nonzero'
								stroke='none'
								d='M0 0h-55.314a1.5 1.5 0 01-1.5-1.5v-11.018a1.5 1.5 0 011.5-1.5h19.861v-57.186a1.5 1.5 0 011.5-1.5h12.592a1.5 1.5 0 011.5 1.5v57.186H0a1.5 1.5 0 011.5 1.5V-1.5A1.5 1.5 0 010 0'
							></path>
						</g>
						<g transform='translate(230.499 123.519)'>
							<path
								fill='#314eff'
								fillOpacity='1'
								fillRule='nonzero'
								stroke='none'
								d='M0 0l-30.417-17.562L-60.833 0v35.121l30.416 17.562L0 35.121zm0-88.72l-30.417-17.561-30.416 17.561v35.121l30.416 17.562L0-53.599zm-153.666 0l-30.417-17.561-30.416 17.561v35.121l30.416 17.562 30.417-17.562zm-60.833 123.841l30.416 17.562 30.417-17.562V0l-30.417-17.562L-214.499 0zm153.666-53.597l14.417-8.324-14.417-8.324zm-16-44.36v-16.646l-14.416 8.323zm-60.833.001l14.417-8.324-14.417-8.324zm-16 27.711l-14.418 8.324 14.418 8.325zm16 44.361v16.647l14.417-8.323zm60.833 0L-91.25 17.56l14.417 8.323zm-60.833-18.475l30.416 17.561 30.417-17.561V-44.36l-30.417-17.562-30.416 17.562zM16-4.619V39.74a7.997 7.997 0 01-4 6.928l-38.417 22.18a8 8 0 01-8 0l-38.401-22.171-.015-.008-34.417-19.871-34.416 19.87-38.417 22.18a8 8 0 01-8 0l-38.416-22.18a7.997 7.997 0 01-4-6.928V-4.619a7.997 7.997 0 014-6.928l26.417-15.253-26.417-15.252a7.995 7.995 0 01-4-6.927v-44.36a7.998 7.998 0 014-6.928l38.416-22.179a7.988 7.988 0 018 0l38.417 22.179 34.416 19.871 34.417-19.872.011-.005 38.405-22.173a7.988 7.988 0 018 0L12-100.267a7.998 7.998 0 014 6.928v44.36a7.995 7.995 0 01-4 6.927L-14.418-26.8 12-11.547a7.997 7.997 0 014 6.928'
							></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default React.memo(BlueTownLogo);
