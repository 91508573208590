import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import JobChangeForm from 'modules/BackOffice/containers/JobChangesFormContainer';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { Routes } from 'types';

import { Styled } from './EditJobPage.styled';

export const EditJobPage = () => {
	const { jobId } = useParams();
	const { pathname } = useLocation();
	const isEditMode = pathname.includes(`${Routes.BackOffice}${Routes.EditJob}`);

	return (
		<>
			<Styled.Head>
				<PageNavTitle title='Job Details' navigationLink={-1} />
			</Styled.Head>
			<Styled.Content>
				<JobChangeForm jobId={jobId} isEditMode={isEditMode} />
			</Styled.Content>
		</>
	);
};
