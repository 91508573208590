import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		.ant-menu-root {
			position: relative;
			margin-left: 16px;
			background-color: ${COLORS.white};
			border: none !important;
			border-inline: none !important;
			z-index: 99;

			.ant-menu-item.ant-menu-item-only-child {
				padding: 0 16px !important;
			}

			.action-menu {
				.ant-menu.ant-menu-sub.ant-menu-inline {
					position: absolute;
					width: 100%;
					background-color: ${COLORS.white};
					border-radius: 4px;
					z-index: 99;
				}

				.ant-menu-title-content {
					font-size: 14px;
					font-weight: 700;
					text-align: center;
					color: ${COLORS.blue};
				}

				& > .ant-menu-submenu-title {
					border: 1px solid ${COLORS.blue};
					border-radius: 4px;
				}

				.ant-menu-sub {
					box-shadow: ${COLORS.elevationShadow};
				}

				li.ant-menu-item {
					font-size: 14px;
					font-weight: 700;
					text-align: center;
					border-bottom: 1px solid ${COLORS.lightGray2};
					border-radius: 0;

					.ant-menu-title-content {
						color: ${COLORS.darkGray2};
					}

					&:last-child {
						border-bottom: none;
					}
				}
			}

			.sub-action-menu {
				position: relative;
				border-radius: 0;

				.ant-menu.ant-menu-sub.ant-menu-inline {
					top: -4px;
					left: -100%;
					border-radius: 4px 0 4px 4px;
					//background-color: ${COLORS.lightGray1};
				}

				.ant-menu-title-content {
					color: ${COLORS.darkGray2};
				}

				& > .ant-menu-submenu-title {
					border-bottom: 1px solid ${COLORS.lightGray2};
					margin: 0;
				}
			}
		}
	`;
}
