import { type FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { message } from 'antd';
import _ from 'lodash';

import EmailIcon from 'components/SVG/EmailIcon';
import MapMarkerIcon from 'components/SVG/MapMarkerIcon';
import PhoneIcon from 'components/SVG/PhoneIcon';
import { useMount } from 'hooks';
import { backOfficeDucks } from 'modules/BackOffice/ducks';
import { BrandingMode } from 'modules/Common/components/BrandingContainer/BrandingContainer.types';
import ControlButtons from 'modules/Common/components/BrandingContainer/ControlButtons';
import { ClientAddressType } from 'modules/Common/types';
import { COLORS } from 'theme';
import { IOption } from 'types';

import { Styled } from './CareerPageAddress.styled';
import CareerPageAddressForm from './CareerPageAddressForm';

const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

type CareerPageAddressProps = {
	mode?: string;
	clientAddress?: ClientAddressType;
	changeClientAddress: (values: ClientAddressType) => void;
	getClientRegionRequested: () => void;
	clientRegions: IOption[];
};

const CareerPageAddress: FC<CareerPageAddressProps> = ({
	clientAddress,
	getClientRegionRequested,
	clientRegions,
	changeClientAddress,
	mode,
}) => {
	const [scriptLoaded, setScriptLoaded] = useState(false);
	const [addressEditingActive, setAddressEditingActive] = useState(false);

	useMount(() => {
		!clientRegions?.length && mode === BrandingMode.Edit && getClientRegionRequested();
	});

	useEffect(() => {
		if (scriptLoaded && clientAddress?.latitude && clientAddress?.longitude) {
			const map = new google.maps.Map(document.getElementById('map'), {
				center: { lat: +clientAddress.latitude, lng: +clientAddress.longitude },
				zoom: 15,
				gestureHandling: 'none',
				zoomControl: true,
				streetViewControl: false,
				scaleControl: false,
				mapTypeControl: false,
				fullscreenControl: false,
			});

			new google.maps.Marker({
				position: { lat: +clientAddress?.latitude, lng: +clientAddress?.longitude },
				map,
			});
		}
	}, [clientAddress, scriptLoaded]);

	useEffect(() => {
		const script = document.createElement('script');

		script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`;
		script.async = true;
		script.id = 'google-map-api';
		script.crossOrigin = 'anonymous';

		document.body.appendChild(script);

		script.addEventListener('load', function () {
			setScriptLoaded(true);
		});

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	if (!scriptLoaded) {
		return null;
	}

	const geoCoder = new window.google.maps.Geocoder();

	const handleChangeClientAddress = (addr: ClientAddressType) => {
		const address = Object.values(addr).join(', ');

		geoCoder.geocode({ address }, (results, status) => {
			if (status === 'OK' && results) {
				const { lat, lng } = results[0].geometry.location;
				const latitude = lat();
				const longitude = lng();

				const res = {
					...addr,
					email: addr?.email || '',
					phoneNumber: addr?.phoneNumber || '',
					latitude: `${latitude}`,
					longitude: `${longitude}`,
				};

				changeClientAddress(res);
				setAddressEditingActive(false);
			} else {
				message.error(`Geocode was not successful for the following reason: ${status}`);
			}
		});
	};
	const onRemoveAddress = () =>
		changeClientAddress({
			city: '',
			zipCode: '',
			phoneNumber: '',
			email: '',
			address: '',
			country: '',
			longitude: '',
			latitude: '',
		});
	const makeAddressEditingActive = () => setAddressEditingActive(true);

	return (
		<Styled.Root id='contacts'>
			{mode === BrandingMode.Edit && addressEditingActive && (
				<CareerPageAddressForm
					changeClientAddress={handleChangeClientAddress}
					clientRegions={clientRegions}
					clientAddress={clientAddress}
				/>
			)}
			{mode === BrandingMode.Edit && !addressEditingActive && (
				<Styled.ContainerControls>
					<ControlButtons onEdit={makeAddressEditingActive} onRemove={onRemoveAddress} />
				</Styled.ContainerControls>
			)}
			<Styled.GoogleMapInner
				hidden={!clientAddress?.latitude && !clientAddress?.longitude}
				id='map'
			/>
			{!_.isEmpty(clientAddress) && !addressEditingActive && (
				<Styled.AddressInfo>
					<Styled.AddressInfoTitle>Contacts:</Styled.AddressInfoTitle>
					{(clientAddress?.city || clientAddress?.address || clientAddress?.zipCode) && (
						<Styled.AddressInfoSection>
							<Styled.AddressInfoSectionIcon>
								<MapMarkerIcon width='16' height='16' fill={COLORS.darkGray1} />
							</Styled.AddressInfoSectionIcon>
							<Styled.AddressInfoSectionValue>
								{`${clientAddress.city}, ${clientAddress.address}, ${clientAddress?.zipCode}`}
							</Styled.AddressInfoSectionValue>
						</Styled.AddressInfoSection>
					)}
					{clientAddress?.phoneNumber && (
						<Styled.AddressInfoSection>
							<Styled.AddressInfoSectionIcon>
								<PhoneIcon width='16' height='16' fill={COLORS.darkGray1} />
							</Styled.AddressInfoSectionIcon>
							<Styled.AddressInfoSectionValue>
								{clientAddress.phoneNumber}
							</Styled.AddressInfoSectionValue>
						</Styled.AddressInfoSection>
					)}
					{clientAddress?.email && (
						<Styled.AddressInfoSection>
							<Styled.AddressInfoSectionIcon>
								<EmailIcon width='16' height='16' fill={COLORS.darkGray1} />
							</Styled.AddressInfoSectionIcon>
							<Styled.AddressInfoSectionValue>{clientAddress.email}</Styled.AddressInfoSectionValue>
						</Styled.AddressInfoSection>
					)}
				</Styled.AddressInfo>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		clientRegions: backOfficeDucks.backOfficeSelectors.getClientsRegionsPublicOptions(state),
	}),
	{
		getClientRegionRequested: backOfficeDucks.backOfficeActions.getClientRegionsRequested,
	},
)(CareerPageAddress);
