export const InterviewGuestType = [
	{ id: 1, name: 'Candidate', value: 'CANDIDATE' },
	{ id: 2, name: 'Interviewer', value: 'INTERVIEWER' },
	{ id: 3, name: 'Other', value: 'OTHER' },
];

export const DEFAULT_SHOWING_TIME_RESEND_BUTTON = 30;

export const DEFAULT_ALLOW_EDIT_BUTTON = 10;

export const DEFAULT_TIME_RESEND_BUTTON = 2;

export const DEFAULT_INTERVIEW_DURATION = 60;
