import React from 'react';

import { ColumnsDataType } from 'components/Table/Table.types';
import { CurrencyType } from 'types';

import { ITableFilteredData } from '../ContractView.types';

import { Styled } from './OrderCredits.styled';

export const columns = (currency: CurrencyType): ColumnsDataType => [
	{
		title: 'Product / Service Type',
		dataIndex: 'name',
		key: 'name',
		render: (name: string) => <div dangerouslySetInnerHTML={{ __html: name }} />,
	},
	{
		title: 'Quantity',
		dataIndex: 'amount',
		key: 'amount',
		align: 'center',
		render: (amount: string) => amount ?? 0,
	},
	{
		title: 'Unit price',
		dataIndex: 'unitPrice',
		key: 'unitPrice',
		align: 'center',
		render: (name: string) => `${currency}${(Number(name) ?? 0).toFixed(2)}`,
	},
	{
		title: 'Total',
		dataIndex: 'totalPrice',
		key: 'totalPrice',
		align: 'center',
		render: (_: string, { totalPrice }: Partial<ITableFilteredData>) => {
			return (
				<Styled.TableTotalPrice>
					{currency}
					{(totalPrice ?? 0).toFixed(2)}
				</Styled.TableTotalPrice>
			);
		},
	},
];
