import React, { FC, useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { useMount } from 'hooks';
import QuestionnaireListContainer from 'modules/Common/containers/QuestionnaireListContainer';
import { commonDucks } from 'modules/Common/ducks';
import { IQuestionnaireValues, TabsTitleEnum } from 'modules/Common/types';
import { GenericType, SubscriptionPlansType } from 'types';
import { propsFilter } from 'utils/helpers';

import { atsDucks } from '../../ducks';

import { TableFields } from './ATSQuestionnaireList.constants';
import { columns } from './ATSQuestionnaireList.entities';

type QuestionnaireListProps = {
	getQuestionnaires: (params?: { active: boolean }) => void;
	questionnaires: IQuestionnaireValues[];
	deleteQuestionnaires: (id: number, cb: () => void) => void;
	archiveUnarchiveQuestionnaireById: (
		id: number,
		params: { active: boolean },
		cb: () => void,
	) => void;
	subscriptionPlans: SubscriptionPlansType;
	loading: GenericType;
};

export const ATSQuestionnaireList: FC<QuestionnaireListProps> = ({
	getQuestionnaires,
	questionnaires,
	deleteQuestionnaires,
	archiveUnarchiveQuestionnaireById,
	subscriptionPlans,
	loading,
}) => {
	const [tab, setTab] = useState<TabsTitleEnum | string>(TabsTitleEnum.ActiveTab);

	const { isPremiumPlan } = subscriptionPlans || {};
	const handleDeleteQuestionnaire = useCallback(
		(id: number, isActive: boolean) => {
			id && deleteQuestionnaires(id, () => getQuestionnaires({ active: isActive }));
		},
		[getQuestionnaires],
	);

	const handleArchiveChange = useCallback(
		(id: number, isActive: boolean) => {
			archiveUnarchiveQuestionnaireById(id, { active: !isActive }, () =>
				getQuestionnaires({ active: isActive }),
			);
		},
		[getQuestionnaires],
	);

	const handleChangeTabs = useCallback(
		(id: TabsTitleEnum | string) => {
			getQuestionnaires({
				active: id === TabsTitleEnum.ActiveTab,
			});

			setTab(id);
		},
		[tab],
	);

	const filteredTableData = questionnaires?.length && propsFilter(questionnaires, TableFields);

	useMount(() => {
		isPremiumPlan && getQuestionnaires({ active: true });
	});

	return (
		<QuestionnaireListContainer
			handleChangeTabs={handleChangeTabs}
			showDefaultPagination
			tableData={filteredTableData}
			columns={(isActive) => columns(isActive, handleDeleteQuestionnaire, handleArchiveChange)}
			currentTab={tab}
			loading={!!loading?.getAllAtsQuestionnairesLoad}
		/>
	);
};

export default connect(
	(state) => ({
		questionnaires: commonDucks.commonSelectors.getAtsQuestionnaires(state),
		subscriptionPlans: atsDucks.atsSelectors.getSubscriptionPlans(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		getQuestionnaires: commonDucks.commonActions.getAllAtsQuestionnairesRequested,
		deleteQuestionnaires: commonDucks.commonActions.deleteQuestionnaireByIdRequested,
		archiveUnarchiveQuestionnaireById:
			commonDucks.commonActions.archiveUnarchiveQuestionnaireByIdRequested,
	},
)(ATSQuestionnaireList);
