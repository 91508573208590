import React, { useState } from 'react';

import BrandingPageSwitcher from 'components/BrandingPageSwitcher';
import FormBlock from 'modules/Common/components/FormBlock';

import AtsClientBrandingContainer from '../AtsBrandingContainer';
import CareerPageContainer from '../CareerPageContainer';

const SWITCHER_ITEMS = [
	{
		id: 0,
		value: 'ATS',
	},
	{
		id: 1,
		value: 'Career Page',
	},
];

const Branding = () => {
	const [activeItem, onSwitch] = useState(SWITCHER_ITEMS[0].id);

	return (
		<FormBlock title='Branding Page'>
			<BrandingPageSwitcher onSwitch={onSwitch} activeItem={activeItem} items={SWITCHER_ITEMS} />
			{activeItem === SWITCHER_ITEMS[0].id && <AtsClientBrandingContainer />}
			{activeItem === SWITCHER_ITEMS[1].id && <CareerPageContainer />}
		</FormBlock>
	);
};

export default Branding;
