import { IOption } from 'types';

import { IOptionArrange } from '../../ATS/containers/ArrangeInterview/ArrangeInterview.types';

import { TagColoursType } from './common';

export enum InterviewStatusEnum {
	Accepted = 'Accepted',
	Declined = 'Declined',
}

export const InterviewStatusColors = {
	[InterviewStatusEnum.Accepted]: TagColoursType.Blue,
	[InterviewStatusEnum.Declined]: TagColoursType.Orange,
};

export type InterviewGuestType = {
	id: string | number;
	email: string;
	fullName: string;
	userId: number;
	isInterviewAccepted: boolean;
	type?: IOption;
};

export type InterviewListType = {
	id: number;
	candidateApplicationId: number;
	type: IOption;
	startDate: string;
	endDate: string;
	jobId: string;
	jobTitle: string;
	information: string;
	guests: IOptionArrange[] | InterviewGuestType[];
};

export type RecordedInterviewType = {
	candidate: string;
	id: number;
	jobTitle: string;
	startDate: string;
	videos: {
		videoLink: string;
		recordingStartDate: string;
	}[];
};

export enum InterviewGuestTypeEnum {
	Candidate = 'CANDIDATE',
	Interviewer = 'INTERVIEWER',
	Other = 'OTHER',
}

export enum InterviewTypesFormEnum {
	VideoOnline = 1,
	FaceToFace = 2,
	Phone = 3,
}
