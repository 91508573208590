import { useState, type FC } from 'react';

import { Modal } from 'antd';

import CompanyApply from 'modules/Common/containers/CompanyApply';

import { Styled } from './CareerPageHeader.styled';

type CareerPageHeaderProps = {
	mode?: string;
	clientId?: number;
};

const NAVIGATION_ITEMS = [
	{
		id: 0,
		label: 'Benefits',
		link: '#benefits',
	},
	{
		id: 1,
		label: 'Jobs',
		link: '#jobs',
	},
	{
		id: 2,
		label: 'About Us',
		link: '#about-us',
	},
	{
		id: 3,
		label: 'Contacts',
		link: '#contacts',
	},
];

const CareerPageHeader: FC<CareerPageHeaderProps> = ({ clientId, mode }) => {
	const [applyModalOpen, setApplyModalOpen] = useState(false);
	const handleCancel = () => setApplyModalOpen(false);
	const handleGetInTouchClick = () => setApplyModalOpen(true);

	return (
		<>
			<Styled.HeaderContainerWrapper>
				<Styled.HeaderContainer>
					<Styled.HeaderActions>
						{NAVIGATION_ITEMS.map((i) => (
							<Styled.HeaderActionsItem href={i.link} key={i.id}>
								{i.label}
							</Styled.HeaderActionsItem>
						))}
					</Styled.HeaderActions>
					<Styled.HeaderNavSection>
						<Styled.GetInTouch ghost onClick={handleGetInTouchClick}>
							Get in Touch
						</Styled.GetInTouch>
					</Styled.HeaderNavSection>
				</Styled.HeaderContainer>
			</Styled.HeaderContainerWrapper>
			<Modal open={applyModalOpen} footer={null} centered onCancel={handleCancel} width={751}>
				<CompanyApply
					closeModal={() => setApplyModalOpen(false)}
					clientId={clientId}
					includeToDatabaseFieldShown={false}
					requiredFields={['fullName', 'email', 'phoneNumber', 'message']}
					justSendQuestion={true}
				/>
			</Modal>
		</>
	);
};

export default CareerPageHeader;
