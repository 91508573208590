import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section``;

	export const CheckoutContainer = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-top: 32px;
	`;
	export const CheckoutInfo = styled.div`
		color: ${COLORS.black};
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	`;
	export const CheckoutInfoLine = styled.div``;
	export const CreditsForm = styled.div`
		padding-top: 24px;
	`;
}
