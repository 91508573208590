import React from 'react';

import ForgotPassword from 'modules/Unregistered/containers/ForgotPassword';

import UnregisteredLayout from '../../components/UnregisteredLayout';
import UnregisteredUser from '../../containers/UnregisteredUser';

export const ForgotPasswordPage = () => (
	<UnregisteredLayout>
		<UnregisteredUser pageContent={<ForgotPassword />} />
	</UnregisteredLayout>
);
