import styled from 'styled-components/macro';

import { IInputStyled, SizeTypes, FieldSizes } from 'types';

export namespace Styled {
	export const Input = styled.input<IInputStyled>`
		position: relative;
		${({ isFullWidth, isError, sizeField, theme: { colors } }) => `
      width: ${isFullWidth ? '100%' : 'auto'};
      max-width: ${isFullWidth ? '100%' : '396px'};
      min-width: ${FieldSizes[sizeField ?? SizeTypes.medium]};
      height: 40px;
      min-height: 40px;
      padding: 8px 12px;
      font-weight: 500;
      font-size: 16px;
      color: ${colors.black};
      background: ${colors.white};
      border-radius: 4px;
      border: 1px solid ${isError ? colors.red : colors.gray};
    `}

		&:hover {
			border: 1px solid ${({ theme: { colors } }) => colors.BLUE_DARK2};
		}

		&:focus {
			border: 1px solid ${({ theme: { colors } }) => colors.BLUE_MEDIUM2};
		}
	`;

	export const Button = styled.span`
		position: absolute;
		top: 8px;
		right: 14px;
		user-select: none;
		cursor: pointer;
	`;
}
