import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function SearchIcon({ fill = COLORS.black, width = '16', height = '16' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.0784 12.0211C10.052 12.8421 8.75009 13.333 7.3335 13.333C4.01979 13.333 1.3335 10.6467 1.3335 7.33301C1.3335 4.0193 4.01979 1.33301 7.3335 1.33301C10.6472 1.33301 13.3335 4.0193 13.3335 7.33301C13.3335 8.74977 12.8425 10.0518 12.0213 11.0783L14.4715 13.5285C14.7319 13.7889 14.7319 14.211 14.4715 14.4713C14.2112 14.7317 13.789 14.7317 13.5287 14.4713L11.0784 12.0211ZM2.66683 7.33301C2.66683 4.75568 4.75617 2.66634 7.3335 2.66634C9.91083 2.66634 12.0002 4.75568 12.0002 7.33301C12.0002 8.59071 11.5026 9.73221 10.6936 10.5714C10.6711 10.5888 10.6494 10.6079 10.6287 10.6285C10.608 10.6492 10.589 10.6709 10.5716 10.6934C9.73244 11.5023 8.59106 11.9997 7.3335 11.9997C4.75617 11.9997 2.66683 9.91034 2.66683 7.33301Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(SearchIcon);
