import type { ReactElement, ReactNode } from 'react';

export type ReactParent = {
	children?: ReactElement | ReactElement[] | string;
};

export type GenericType<T = unknown> = {
	[key: string]: T;
};

export interface IGenericComponent {
	[key: string]: ReactNode;
}

export interface OptionsType {
	value: string | boolean;
	label: string;
}

export interface IOption {
	id: string | number;
	name?: string;
	value?: string;
	title?: string;
	region?: string;
	fullName?: string;
}

export enum DataFormatEnum {
	UK = 'UK',
	US = 'US',
	Full = 'Full',
	FullMonth = 'FullMonth',
	FullHoursMinutes = 'FullHoursMinutes',
}

export enum TokenPurposesEnum {
	AUTH = 'AUTH',
	TWO_FA = 'TWO_FA',
}

export const enum ErrorCodes {
	Unauthorised = 401,
	Forbidden = 403,
	NotFound = 404,
	ServerError = 500,
}

export type DataFormatType = {
	year: string | number;
	month: string | number;
	day: string | number;
	hours: string | number;
	minutes: string | number;
};

export interface IRequestParams {
	active: boolean;
	page?: number;
	size?: number;
}

export interface IRequestParamsExtended extends IRequestParams {
	client?: number | string;
}
