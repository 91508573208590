import React, { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ATS_DEFAULT_COLORS } from 'common/colors';
import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import Spinner from 'components/Spinner';
import JobBriefcase from 'components/SVG/JobBriefcase';
import { DEFAULT_PAGE_SIZE } from 'modules/Common/constants';
import JobsListStatistic from 'modules/Common/containers/JobsListStatistic';
import { ClientBrandingDataType, JobsDataType } from 'modules/Common/types';
import { CountriesType, SubscriptionPlansType } from 'types';
import { sleep } from 'utils/helpers';

import { Styled } from './DashboardActiveJobs.styled';

type DashboardActiveJobsProps = {
	jobsData: JobsDataType;
	getAllJobs: (params: { active: boolean; size?: number; page?: number }) => void;
	archiveAndUnarchiveJob: (jobId: number, active: boolean) => void;
	clientBrandingData: ClientBrandingDataType;
	countries: CountriesType;
	subscriptionPlans: SubscriptionPlansType;
	hideArchiveAndBoostButtons?: boolean;
	handleEditJob?: (jobId: number) => void;
	handleSeeAllJobs: () => void;
	handlePostJob: () => void;
	loading: boolean;
};

const NUMBER_OF_JOBS_TO_DISPLAY = 4;

const DashboardActiveJobs: FC<DashboardActiveJobsProps> = ({
	jobsData,
	getAllJobs,
	archiveAndUnarchiveJob,
	clientBrandingData,
	countries,
	subscriptionPlans,
	hideArchiveAndBoostButtons,
	handleEditJob,
	handleSeeAllJobs,
	handlePostJob,
	loading,
}) => {
	const { clientId = '' } = useParams();
	const { isPremiumPlan } = subscriptionPlans;
	const handleChangeActiveJob = useCallback(
		async (id: number, active: boolean) => {
			await archiveAndUnarchiveJob(id, active);
			await sleep(1000);
			await getAllJobs({ active: true, size: DEFAULT_PAGE_SIZE });
		},
		[archiveAndUnarchiveJob],
	);

	useEffect(() => {
		getAllJobs({
			active: true,
			page: jobsData?.pageIndex,
			size: DEFAULT_PAGE_SIZE,
		});
	}, [clientId]);

	if (loading) {
		return <Spinner fixed />;
	}

	const preparedData = {
		...jobsData,
		data: jobsData.data.slice(0, NUMBER_OF_JOBS_TO_DISPLAY),
	};

	const numberOfActiveJobs = jobsData.totalCount || 0;

	return (
		<Styled.Root>
			<Styled.Head>
				<Styled.Title>Active Jobs ({numberOfActiveJobs})</Styled.Title>
				{numberOfActiveJobs !== 0 && (
					<Styled.ButtonBox>
						<Button
							// buttonColor={isPremiumPlan ? clientBrandingData?.highlightColour : COLORS.blue}
							onClick={handleSeeAllJobs}
						>
							See all
						</Button>
						<Button
							buttonColor={
								isPremiumPlan
									? clientBrandingData?.highlightColour
									: ATS_DEFAULT_COLORS.highlightColour
							}
							buttonType={ButtonTypes.primary}
							onClick={handlePostJob}
						>
							Post a job
						</Button>
					</Styled.ButtonBox>
				)}
			</Styled.Head>
			{numberOfActiveJobs === 0 ? (
				<Styled.NoActiveJobs>
					<JobBriefcase width='120px' />
					<p>There are no active jobs yet.</p>
					<p>You can post your first job right now.</p>
					<Button
						buttonColor={
							isPremiumPlan
								? clientBrandingData?.highlightColour
								: ATS_DEFAULT_COLORS.highlightColour
						}
						buttonType={ButtonTypes.primary}
						onClick={handlePostJob}
					>
						Post a job
					</Button>
				</Styled.NoActiveJobs>
			) : (
				<JobsListStatistic
					jobsData={preparedData}
					onChangeActiveJob={handleChangeActiveJob}
					textColour={
						isPremiumPlan ? clientBrandingData?.textColour : ATS_DEFAULT_COLORS.textColour
					}
					onChangePage={() => undefined}
					countries={countries}
					isActive
					isPremium={isPremiumPlan}
					highlightColour={clientBrandingData?.highlightColour}
					showPagination={false}
					dashboardStyles={true}
					hideArchiveAndBoostButtons={hideArchiveAndBoostButtons}
					handleEditJob={handleEditJob}
				/>
			)}
		</Styled.Root>
	);
};

export default DashboardActiveJobs;
