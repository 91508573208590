import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import { DEFAULT_PAGE_SIZE } from 'modules/Common/constants';
import QuestionnaireListContainer from 'modules/Common/containers/QuestionnaireListContainer';
import { commonDucks } from 'modules/Common/ducks';
import { IQuestionnairesPaginated, TabsTitleEnum } from 'modules/Common/types';
import { GenericType, IRequestParamsExtended } from 'types';
import { propsFilter } from 'utils/helpers';

import { TableFields } from './QuestionnaireList.constants';
import { columns } from './QuestionnaireList.entities';

type QuestionnaireListProps = {
	getQuestionnaires: (params?: IRequestParamsExtended) => void;
	questionnaires: IQuestionnairesPaginated;
	loading: GenericType;
};

export const QuestionnaireList: FC<QuestionnaireListProps> = ({
	getQuestionnaires,
	questionnaires,
	loading,
}) => {
	const { clientId } = useParams();
	const [tab, setTab] = useState<TabsTitleEnum | string>(TabsTitleEnum.ActiveTab);

	const { data, pageIndex, pageSize, totalCount } = questionnaires;

	const filteredTableData = data?.length && propsFilter(data, TableFields);

	const handleTableChange = useCallback(
		(page: number, size: number) => {
			const isActiveTab = tab === TabsTitleEnum.ActiveTab;

			getQuestionnaires({ active: isActiveTab, page, size, client: clientId });
		},
		[tab, questionnaires],
	);

	const handleChangeTabs = useCallback(
		(id: TabsTitleEnum | string) => {
			getQuestionnaires({
				active: id === TabsTitleEnum.ActiveTab,
				page: 0,
				size: pageSize,
				client: clientId,
			});

			setTab(id);
		},
		[tab, pageIndex],
	);

	useMount(() => {
		!data?.length &&
			getQuestionnaires({
				active: true,
				page: 0,
				size: DEFAULT_PAGE_SIZE,
				client: clientId,
			});
	});

	useEffect(() => {
		getQuestionnaires({
			active: true,
			page: 0,
			size: DEFAULT_PAGE_SIZE,
			client: clientId,
		});
	}, [clientId]);

	if (loading?.getAllBoQuestionnairesLoad) {
		return <Spinner fixed />;
	}

	return (
		<QuestionnaireListContainer
			handleTableChange={handleTableChange}
			handleChangeTabs={handleChangeTabs}
			tableData={filteredTableData}
			columns={columns}
			pageIndex={pageIndex}
			pageSize={pageSize}
			totalCount={totalCount}
			currentTab={tab}
			loading={!!loading?.getAllBoQuestionnairesLoad}
			clientId={clientId}
		/>
	);
};

export default connect(
	(state) => ({
		questionnaires: commonDucks.commonSelectors.getBOQuestionnaires(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		getQuestionnaires: commonDucks.commonActions.getAllBOQuestionnairesRequested,
	},
)(QuestionnaireList);
