import React, { useState, type FC, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import { Tabs } from 'antd';

import Table from 'components/Table';
import { backOfficeDucks } from 'modules/BackOffice/ducks';
import { TabsTitleEnum } from 'modules/Common/types';
import { GenericType } from 'types';

import { columns } from './SupportTickets.entities';

type SupportTicketsProps = {
	loading?: GenericType;
	tickets: {
		data: object[];
		pageIndex: number;
		pageSize: number;
		totalCount: number;
	};
	getTicketsRequested: (params: { active: boolean; page?: number; size?: number }) => void;
};

const SupportTickets: FC<SupportTicketsProps> = ({ getTicketsRequested, loading, tickets }) => {
	const [tab, setTab] = useState<TabsTitleEnum | string>(TabsTitleEnum.ActiveTab);

	const { pageIndex, pageSize, totalCount, data } = tickets || {};

	const isActiveTab = tab === TabsTitleEnum.ActiveTab;

	const columnsData = columns(isActiveTab);

	const handleChangeTabs = (id: string) => {
		getTicketsRequested({ active: isActiveTab });
		setTab(id);
	};

	const handleTableChange = useCallback((page: number, size: number) => {
		getTicketsRequested({ active: isActiveTab, page, size });
	}, [isActiveTab]);

	useEffect(() => {
		getTicketsRequested({ active: isActiveTab, page: pageIndex, size: pageSize });
	}, [tab]);

	return (
		<Tabs
			defaultActiveKey={TabsTitleEnum.ActiveTab}
			tabBarStyle={{ textTransform: 'uppercase' }}
			onChange={handleChangeTabs}
			items={[
				{
					label: 'Active',
					key: TabsTitleEnum.ActiveTab,
					active: true,
					children: (
						<Table
							pageSize={pageSize}
							current={pageIndex}
							loading={loading?.getTicketsLoad}
							total={totalCount}
							data={data}
							columns={columnsData}
							onChange={handleTableChange}
						/>
					),
				},
				{
					label: 'Resolved',
					key: TabsTitleEnum.ArchiveTab,
					children: (
						<Table
							pageSize={pageSize}
							current={pageIndex}
							loading={loading?.getTicketsLoad}
							total={totalCount}
							data={data}
							columns={columnsData}
							onChange={handleTableChange}
						/>
					),
				},
			]}
		/>
	);
};

export default connect(
	(state) => ({
		tickets: backOfficeDucks.backOfficeSelectors.getBOTicketsState(state),
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
	}),
	{
		getTicketsRequested: backOfficeDucks.backOfficeActions.geTicketsRequested,
	},
)(SupportTickets);
