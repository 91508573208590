import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function CreditsIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M17 11C16.4477 11 16 11.4477 16 12C16 12.5523 16.4477 13 17 13C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21 8.17071V7C21 5.34315 19.6569 4 18 4H4C2.34315 4 1 5.34315 1 7V17C1 18.6569 2.34315 20 4 20H18C19.6569 20 21 18.6569 21 17V15.8293C22.1652 15.4175 23 14.3062 23 13V11C23 9.69378 22.1652 8.58254 21 8.17071ZM18 6H7V18H18C18.5523 18 19 17.5523 19 17V16H16C14.3431 16 13 14.6569 13 13V11C13 9.34315 14.3431 8 16 8H19V7C19 6.44771 18.5523 6 18 6ZM4 6H5V18H4C3.44772 18 3 17.5523 3 17V7C3 6.44772 3.44772 6 4 6ZM15 11C15 10.4477 15.4477 10 16 10H20C20.5523 10 21 10.4477 21 11V13C21 13.5523 20.5523 14 20 14H16C15.4477 14 15 13.5523 15 13V11Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(CreditsIcon);
