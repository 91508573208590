import { darken } from 'polished';
import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';
import { COLORS } from 'theme';

type ClientNameProps = {
	textColor?: string;
};

type ButtonProps = {
	buttonColor: string;
};

type InfoProps = {
	isClientContextDashboard?: boolean;
};

export namespace Styled {
	export const Root = styled.section`
		margin-bottom: 24px;
	`;

	export const Info = styled.div<InfoProps>`
		position: relative;
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 35px;
		${({ isClientContextDashboard }) => `
			background-color: ${isClientContextDashboard ? `${COLORS.lightGray3}` : 'none'};
    	`}
	`;

	export const Logo = styled.div`
		position: absolute;
		top: 0;
		width: 100%;
		max-width: 240px;
		height: 120px;
		max-height: 120px;
		margin-left: 32px;
		padding: 8px;
		border-radius: 4px;
		background-color: ${COLORS.white};
		transform: translateY(-50%);
		box-shadow: ${COLORS.elevationShadow};
		z-index: 3;

		img {
			width: 100%;
			padding: 4px;
			object-fit: contain;
		}
	`;

	export const ImageWrapper = styled.div`
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		gap: 4px;
		img {
			height: 100%;
		}
	`;

	export const ClientName = styled.div<ClientNameProps>`
		margin-top: 12px;
		margin-left: 300px;
		font-weight: 700;
		font-size: 32px;
		line-height: 40px;
		color: ${({ textColor }) => textColor};
	`;

	export const Button = styled(ButtonComponent)<ButtonProps>`
		margin-right: 32px;
		margin-top: 24px;
		background: ${({ buttonColor }) => buttonColor};
		white-space: pre;

		:hover {
			background: ${({ buttonColor }) => (buttonColor ? darken(0.1, buttonColor) : 'none')};
		}
	`;
}
