import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';

import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import { DashboardUnreadEmailsType } from 'modules/Common/types';
import { assignColorsToIds, getFirstLettersUppercase } from 'modules/Common/utils/commonHelpers';

import { Styled } from './DashboardEmails.styled';

type DashboardEmailsProps = {
	emailList: DashboardUnreadEmailsType;
	hideTotalEmails?: boolean;
	clientId?: string;
	linkToSingleMessage?: string;
	loading: boolean;
	getEmails: (client?: string) => void;
	handleSeeAllMessages?: () => void;
};

const DashboardEmails: FC<DashboardEmailsProps> = ({
	emailList,
	hideTotalEmails = false,
	linkToSingleMessage,
	loading,
	getEmails,
	handleSeeAllMessages,
}) => {
	useMount(() => {
		getEmails();
	});

	const senderColors = useMemo(
		() => assignColorsToIds(emailList?.data, ['candidateApplication', 'id']),
		[emailList],
	);

	if (loading) {
		return <Spinner fixed />;
	}

	const numberOfEmails = emailList?.unreadMessagesCount || 0;

	return (
		<Styled.Root>
			<Styled.Head>
				<Styled.Column>
					<Styled.Title>New Messages {!hideTotalEmails && `(${numberOfEmails})`}</Styled.Title>
				</Styled.Column>
				<Styled.Column>
					{handleSeeAllMessages && (
						<Styled.SeeMoreButton type='link' htmlType='button' onClick={handleSeeAllMessages}>
							See more
						</Styled.SeeMoreButton>
					)}
				</Styled.Column>
			</Styled.Head>
			{emailList?.data?.length === 0 ? (
				<Styled.NoMessages>
					<p>There are no unread emails</p>
				</Styled.NoMessages>
			) : (
				emailList?.data?.map((message) => {
					return (
						<Link
							to={linkToSingleMessage || '#'}
							key={message.id}
							state={{
								threadId: message.threadId,
								candidateApplicationId: message.candidateApplication.id,
							}}
						>
							<Styled.InterviewWrapper>
								<Styled.UserIcon bgColor={senderColors[message.candidateApplication.id]}>
									{getFirstLettersUppercase(message.candidateApplication.fullName)}
								</Styled.UserIcon>
								<Styled.InterviewLeftCol>
									<Styled.InterviewCandidate>
										{message.candidateApplication.fullName}
									</Styled.InterviewCandidate>
									<Styled.InterviewInfo>{message.topic}</Styled.InterviewInfo>
								</Styled.InterviewLeftCol>
								<Styled.InterviewRightCol>
									<Styled.InterviewInfo>
										{dayjs(message.date).format('MMM DD, YYYY')}
									</Styled.InterviewInfo>
									<Styled.InterviewInfo>{dayjs(message.date).format('HH:mm')}</Styled.InterviewInfo>
								</Styled.InterviewRightCol>
							</Styled.InterviewWrapper>
						</Link>
					);
				})
			)}
		</Styled.Root>
	);
};

export default DashboardEmails;
