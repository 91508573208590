import { COLORS } from 'theme';

export const options = {
	responsive: true,
	maintainAspectRatio: false,
	// barPercentage: 0.6,
	// categoryPercentage: 0.42,
	barThickness: 16,
	elements: {
		line: {
			tension: 0.2,
			borderWidth: 2,
		},
		point: {
			borderWidth: 0,
		},
	},
	plugins: {
		legendBarStatisticDetails: {
			containerID: 'time-to-hire-container',
		},
		legend: {
			display: false,
		},
		tooltip: {
			callbacks: {
				label: context => `total day(s) ${context.raw}`, 
			},
		},
		title: {
			display: false,
		},
	},
	scales: {
		x: {
			grid: {
				display: false,
			},
			ticks: {
				color: COLORS.darkGray1,
				padding: 0,
				backdropPadding: 0,
				callback: () => '',
			},
		},
		y: {
			position: 'right',
			ticks: {
				color: COLORS.darkGray1,
			},
		},
	},
};
