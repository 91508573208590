import { FC, useCallback } from 'react';
import { connect } from 'react-redux';

import Spinner from 'components/Spinner';
import Table from 'components/Table';
import { useMount } from 'hooks';
import { atsDucks } from 'modules/ATS/ducks';
import { DEFAULT_PAGE_SIZE, DEFAULT_CURRENT_PAGE } from 'modules/Common/constants/table';
import { GenericType } from 'types';

import { columns } from './GravityJobsTable.entities';
import { GravityJobsListType } from './GravityJobsTable.types';

type GravityJobsProps = {
	gravityJobsList: GravityJobsListType;
	loading: GenericType;
	getGravityJobsRequested: ({ page, size }: { page: number; size: number }) => void;
};

export const GravityJobsTable: FC<GravityJobsProps> = ({
	gravityJobsList,
	loading,
	getGravityJobsRequested,
}) => {
	useMount(() => {
		!gravityJobsList?.data?.length &&
			getGravityJobsRequested({ page: DEFAULT_CURRENT_PAGE, size: DEFAULT_PAGE_SIZE });
	});

	const handlePaginationChange = useCallback((page: number, size: number) => {
		getGravityJobsRequested({ page, size });
	}, []);

	const columnsData = columns();

	const gravityJobsLoading = !!loading?.getGravityJobsLoad;

	if (!gravityJobsList?.data || !columnsData?.length || gravityJobsLoading) {
		return <Spinner fixed />;
	}

	return (
		<>
			{gravityJobsList && (
				<Table
					pageSize={gravityJobsList.pageSize}
					current={gravityJobsList.pageIndex}
					total={gravityJobsList.totalCount}
					data={gravityJobsList.data}
					columns={columnsData}
					onChange={handlePaginationChange}
					rowKey='candidateApplicationId'
				/>
			)}
		</>
	);
};

export default connect(
	(state) => ({
		gravityJobsList: atsDucks.atsSelectors.getAllGravityJobsListData(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getGravityJobsRequested: atsDucks.atsActions.getGravityJobsRequested,
	},
)(GravityJobsTable);
