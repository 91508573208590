import React, { type FC, useEffect, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { Spin } from 'antd';
import { omit } from 'lodash';

import Box from 'components/Box';
import { ButtonTypes } from 'components/Button/Button.types';
import FormSwitch from 'components/FormSwitch';
import Spinner from 'components/Spinner';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import { atsDucks } from 'modules/ATS/ducks';
import FormBlockGrid from 'modules/Common/components/FormBlockGrid';
import { GenericType } from 'types';

import { Styled } from '../ATSSettings.styled';

interface INotificationsData {
	notificationNewJob: boolean;
	notificationNewApplication: boolean;
	notificationNewMessage: boolean;
	notificationCandidateReassigned: boolean;
	notificationCandidateRejected: boolean;
	notificationSupportTicket: boolean;
}

export type SocialNetworkProps = {
	emailNotifications: INotificationsData;
	getUserEmailNotifications: () => void;
	updateUserEmailNotifications: (values: INotificationsData) => void;
	loading: GenericType;
};

const ATSEmailNotifications: FC<SocialNetworkProps> = ({
	emailNotifications,
	getUserEmailNotifications,
	updateUserEmailNotifications,
	loading,
}) => {
	const onSubmit = (values: INotificationsData) => {
		updateUserEmailNotifications(values);
	};

	useEffect(() => {
		getUserEmailNotifications();
	}, []);

	const initialValues = useMemo(
		() => ({
			...omit(emailNotifications, ['user']),
		}),
		[emailNotifications],
	);

	const isUpdating = !!loading?.updateUserEmailNotificationsLoad;

	return (
		<Styled.Root>
			{loading.getUserEmailNotificationsLoad ? (
				<Spinner fullWidth />
			) : (
				<Form
					onSubmit={onSubmit}
					initialValues={initialValues}
					render={({ handleSubmit, dirty }) => (
						<form onSubmit={handleSubmit}>
							<>
								<Box noSpaces fullWidth style={{ flex: '1 1 auto' }}>
									<h2>Email Notifications</h2>
									<p>Receive an email from Connect when:</p>
									<FormBlockGrid columns={1}>
										<Field name={'notificationNewJob'}>
											{({ input }) => <FormSwitch {...input} title='New jobs are listed' />}
										</Field>
										<Field name={'notificationNewApplication'}>
											{({ input }) => (
												<FormSwitch {...input} title='New applications are received' />
											)}
										</Field>
										<Field name={'notificationNewMessage'}>
											{({ input }) => (
												<FormSwitch {...input} title='An email is received from a candidate' />
											)}
										</Field>
										<Field name={'notificationCandidateReassigned'}>
											{({ input }) => <FormSwitch {...input} title='A candidate is re-assigned' />}
										</Field>

										<Field name={'notificationCandidateRejected'}>
											{({ input }) => <FormSwitch {...input} title='A candidate is rejected' />}
										</Field>
										<Field name={'notificationSupportTicket'}>
											{({ input }) => <FormSwitch {...input} title='A support ticket is updated' />}
										</Field>
									</FormBlockGrid>
								</Box>

								<Styled.ButtonWrapper>
									<ATSThemedButton
										buttonType={ButtonTypes.primary}
										type='submit'
										disabled={!dirty || isUpdating}
									>
										Save
										{isUpdating ? <Spin style={{ marginLeft: '12px' }} /> : ''}
									</ATSThemedButton>
								</Styled.ButtonWrapper>
							</>
						</form>
					)}
				/>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		emailNotifications: atsDucks.atsSelectors.getUserEmailNotifications(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getUserEmailNotifications: atsDucks.atsActions.getUserEmailNotificationsRequested,
		updateUserEmailNotifications: atsDucks.atsActions.updateUserEmailNotificationsRequested,
	},
)(ATSEmailNotifications);
