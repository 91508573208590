import { Pagination as PaginationAnt } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

interface IMenuListItemProps {
	isActive: boolean;
}

export namespace Styled {
	export const Root = styled.ul`
		width: 100%;
		background-color: ${COLORS.white};
		border-radius: 10px;
		width: 250px;
		font-family: 'Inter';
	`;

	export const MenuList = styled.ul`
		width: 100%;
		display: flex;
		flex-direction: column;
		height: 100%;
	`;

	export const MenuListItem = styled.li<IMenuListItemProps>`
		position: relative;
		flex-direction: column;
		justify-content: start;
		align-items: start;
		width: 100%;
		height: auto;
		padding: 16px;
		margin: 0;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: left;
		border-radius: 4px 0 0 4px;
		cursor: pointer;
		transition: background-color 0.2s ease;
		gap: 4px;
		background: ${({ isActive }) => (isActive ? COLORS.settingsItemColor : 'none')};

		&:hover {
			background-color: ${COLORS.settingsItemColor};
		}
	`;

	export const MenuListItemSpan = styled.span`
		display: flex;
		justify-content: space-between;
		align-items: center;
	`;

	export const MenuCandidate = styled.span`
		max-width: 90%;
		span.highlight {
			color: ${COLORS.blue};
		}
	`;

	export const Badge = styled.span`
		top: 0;
		right: 0;
		width: 12px;
		height: 12px;
		background-color: ${COLORS.blue};
		display: block;
		border-radius: 50%;
	`;

	export const Pagination = styled(PaginationAnt)`
		text-align: center;
		box-shadow: 0px -2px 4px 0px rgba(99, 111, 122, 0.12);
		padding-top: 12px;
		padding-bottom: 12px;
		margin-top: auto;
	`;
}
