export const createActions = (TYPES) => {
	const checkClientInfo = (data, cb) => ({
		type: TYPES.CHECK_CLIENT_INFO.REQUESTED,
		payload: data,
		cb,
	});

	const changePasswordRequested = (data, callback) => ({
		type: TYPES.CHANGE_PASSWORD.REQUESTED,
		payload: data,
		callback,
	});

	const forgotPasswordRequested = (value, callback) => ({
		type: TYPES.FORGOT_PASSWORD.REQUESTED,
		payload: value,
		callback,
	});

	const resetPasswordRequested = (data, callback) => ({
		type: TYPES.RESET_PASSWORD.REQUESTED,
		payload: data,
		callback,
	});

	const getUserById = (data) => ({
		type: TYPES.GET_USER.REQUESTED,
		payload: data,
	});

	const getQuestionnaireWelcome = (qstId, pwd) => ({
		type: TYPES.GET_QUESTIONNAIRE_WELCOME.REQUESTED,
		payload: { qstId, pwd },
	});

	const getQuestionnaireDetails = (pwd) => ({
		type: TYPES.GET_QUESTIONNAIRE_DETAILS.REQUESTED,
		payload: { pwd },
	});

	const impersonateUserRequested = ({ userId, callback }) => ({
		type: TYPES.IMPERSONATE.REQUESTED,
		payload: { userId, callback },
	});

	const twoFALoginRequested = (values, jwt2faToken, callback) => ({
		type: TYPES.TWOFA_LOGIN.REQUESTED,
		payload: { values, jwt2faToken, callback },
	});

	const resendTwoFACodeRequested = (jwt2faToken, callback) => ({
		type: TYPES.RESEND_TWOFA_CODE.REQUESTED,
		payload: { jwt2faToken, callback },
	});

	const unImpersonateUserRequested = (callback) => ({
		type: TYPES.UN_IMPERSONATE.REQUESTED,
		payload: callback,
	});

	const getCurrentQuestion = (questionId, pwd) => ({
		type: TYPES.GET_CURRENT_QUESTION.REQUESTED,
		payload: { questionId, pwd },
	});

	const getEmailThreadRequested = (threadId, pwd, cb) => ({
		type: TYPES.GET_EMAIL_THREAD.REQUESTED,
		payload: { threadId, pwd },
		cb,
	});

	const replyToEmailThreadRequested = (threadId, pwd, data, cb) => ({
		type: TYPES.REPLY_TO_EMAIL_THREAD.REQUESTED,
		payload: { threadId, pwd, data },
		cb,
	});

	const login = (data, callback) => ({
		type: TYPES.LOGIN.REQUESTED,
		payload: data,
		callback,
	});

	const logOut = (callback) => ({ type: TYPES.LOGOUT.REQUESTED, payload: { callback } });

	return {
		checkClientInfo,
		changePasswordRequested,
		forgotPasswordRequested,
		resetPasswordRequested,
		getQuestionnaireWelcome,
		getCurrentQuestion,
		getUserById,
		getQuestionnaireDetails,
		getEmailThreadRequested,
		replyToEmailThreadRequested,
		login,
		logOut,
		impersonateUserRequested,
		unImpersonateUserRequested,
		twoFALoginRequested,
		resendTwoFACodeRequested,
	};
};
