import React, { type FC, useEffect, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Input, Popconfirm, Select, Spin } from 'antd';
import { pick, trim } from 'lodash';

import FieldPassword from 'components/FieldPassword';
import FieldWrapper from 'components/FieldWrapper';
import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import { FormBlockGrid } from 'modules/Common/components/FormBlockGrid/FormBlockGrid';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { BackOfficeSettingsRoutes, IOption, UserRolesEnum } from 'types';
import { trimSpacesFormValues } from 'utils/helpers';
import {
	composeValidators,
	emailValidator,
	passwordValidator,
	repeatPasswordValidator,
	requiredFieldValidator,
	requiredValidator,
} from 'utils/validators';

import FormSwitch from '../../../../components/FormSwitch';

import { Styled } from './UserAccessFormContainer.styled';
import {
	UserAccessFormContainerTypeProps,
	ICreateUserValues,
} from './UserAccessFormContainer.types';

const { Option } = Select;

const AllowedUserFields = [
	'firstName',
	'lastName',
	'email',
	'role',
	'backofficeNotificationTicket',
];

export const UserAccessFormContainer: FC<UserAccessFormContainerTypeProps> = ({
	isClientContext = false,
	navigationLink,
	createUser,
	getUserRoles,
	getUserManagers,
	getUserById,
	deActivateUser,
	updateUser,
	currentUser,
	userRoles,
	userManagers,
	isEditMode,
	loading,
}) => {
	const navigate = useNavigate();
	const { userId } = useParams();

	const onSubmit = (values: ICreateUserValues) => {
		const preparedValues = trimSpacesFormValues({
			...pick(values, AllowedUserFields),
			...(values?.role === UserRolesEnum.BACK_SALES && { manager: values.manager }),
			...(values?.password && { password: values.password }),
			...(values?.email && { email: trim(values.email) }),
			...(values?.veritoneUsername && { veritoneUsername: trim(values.veritoneUsername) }),
			...(isEditMode && { id: userId }),
		});

		if (isEditMode) {
			updateUser(preparedValues, isClientContext, () =>
				navigate(BackOfficeSettingsRoutes.UserAccess),
			);
		} else {
			createUser(preparedValues, isClientContext, () =>
				navigate(BackOfficeSettingsRoutes.UserAccess),
			);
		}
	};

	useMount(() => {
		!userRoles?.length && getUserRoles(isClientContext);
		!isClientContext && !userManagers?.length && getUserManagers();
	});

	useEffect(() => {
		if (isEditMode) {
			userId && getUserById(userId);
		}
	}, [isEditMode, userId]);

	const handleDeActivateUser = () => {
		userId &&
			deActivateUser(+userId, false, isClientContext, () =>
				navigate(BackOfficeSettingsRoutes.UserAccess),
			);
	};

	const initialValues = useMemo(
		() => ({
			firstName: isEditMode ? currentUser?.firstName : '',
			lastName: isEditMode ? currentUser?.lastName : '',
			email: isEditMode ? currentUser?.email : '',
			veritoneUsername: isEditMode ? currentUser?.veritoneUsername : '',
			role: isEditMode ? currentUser?.role?.id : null,
			...(!isClientContext && {
				manager: isEditMode ? currentUser?.manager?.id : null,
				backofficeNotificationTicket: isEditMode
					? currentUser?.backofficeNotificationTicket
					: false,
			}),
		}),
		[currentUser, userRoles, userManagers, isEditMode],
	);

	if (loading?.getBoUserRolesLoad || loading?.getBoUserByIdLoad) {
		return <Spinner />;
	}

	return (
		<>
			<Styled.Root>
				<Styled.Head>
					<PageNavTitle
						title={
							isEditMode ? `${currentUser?.firstName} ${currentUser?.lastName}` : 'Create User'
						}
						navigationLink={navigationLink}
						noSpace
					/>
					{isEditMode && (
						<Popconfirm
							placement='bottom'
							title='Are you sure?'
							onConfirm={handleDeActivateUser}
							okText='Yes'
							cancelText='Cancel'
						>
							<Styled.DeleteButton type='ghost' htmlType='button'>
								De-activate
							</Styled.DeleteButton>
						</Popconfirm>
					)}
				</Styled.Head>
				<Form
					onSubmit={onSubmit}
					initialValues={initialValues}
					render={({ handleSubmit, form, values, dirty }) => {
						const isSubmitting = !!loading?.createBoUserLoad || !!loading?.updateBoUserLoad;

						const showPassword = isEditMode || isClientContext;

						return (
							<form onSubmit={handleSubmit}>
								<FormBlockGrid columns={2}>
									<Field name='firstName' validate={requiredValidator}>
										{({ input, meta }) => (
											<FieldWrapper
												name='firstName'
												label='First name'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												required
											>
												<Input {...input} placeholder='' />
											</FieldWrapper>
										)}
									</Field>
									<Field name='lastName' validate={requiredValidator}>
										{({ input, meta }) => (
											<FieldWrapper
												name='lastName'
												label='Last name'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												required
											>
												<Input {...input} placeholder='' />
											</FieldWrapper>
										)}
									</Field>

									<Field
										name='email'
										validate={composeValidators(requiredValidator, emailValidator)}
									>
										{({ input, meta }) => (
											<FieldWrapper
												name='email'
												label='Email'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												required
											>
												<Input {...input} placeholder='' />
											</FieldWrapper>
										)}
									</Field>
									<Field name='role' validate={requiredFieldValidator}>
										{({ input, meta }) => (
											<FieldWrapper
												name='role'
												label='Role'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												required
											>
												<Select {...input} value={input.value || null} placeholder=''>
													{userRoles?.map((option: IOption) => (
														<Option key={option.name} value={option.id}>
															{option.value}
														</Option>
													))}
												</Select>
											</FieldWrapper>
										)}
									</Field>
									<>
										{!isClientContext && values?.role === UserRolesEnum.BACK_SALES && (
											<>
												<Field name='manager' validate={requiredFieldValidator}>
													{({ input, meta }) => (
														<FieldWrapper
															name='manager'
															label='Manager'
															errorMessage={meta.submitFailed && meta.touched && meta.error}
															required={values?.role === UserRolesEnum.BACK_SALES}
														>
															<Select
																{...input}
																value={input.value || null}
																disabled={!userManagers?.length}
																placeholder=''
															>
																{userManagers?.map((option: IOption) => (
																	<Option key={option.id} value={option.id}>
																		{option.fullName}
																	</Option>
																))}
															</Select>
														</FieldWrapper>
													)}
												</Field>
												<div />
											</>
										)}
									</>

									<>
										{showPassword && (
											<>
												<Field name='password' validate={passwordValidator}>
													{({ input, meta }) => (
														<FieldWrapper
															name='password'
															label='Password'
															errorMessage={meta.submitFailed && meta.touched && meta.error}
															isFixed
														>
															<FieldPassword {...input} placeholder='' />
														</FieldWrapper>
													)}
												</Field>

												<Field
													name='repeatPassword'
													validate={(value: string) =>
														repeatPasswordValidator(form.getState().values.password, value)
													}
												>
													{({ input, meta }) => (
														<FieldWrapper
															name='repeatPassword'
															label='Repeat password'
															errorMessage={meta.submitFailed && meta.touched && meta.error}
															isFixed
														>
															<FieldPassword {...input} placeholder='' />
														</FieldWrapper>
													)}
												</Field>
											</>
										)}
									</>
									<>
										<Styled.FieldSpace>
											{!isClientContext && (
												<Field name='backofficeNotificationTicket'>
													{({ input }) => (
														<FieldWrapper name='backofficeNotificationTicket' isFixed>
															<FormSwitch {...input} title='Support Ticket Notification' />
														</FieldWrapper>
													)}
												</Field>
											)}
										</Styled.FieldSpace>
										{!isClientContext && (
											<Field name='veritoneUsername'>
												{({ input, meta }) => (
													<FieldWrapper
														name='veritoneUsername'
														label='Broadbean username'
														errorMessage={meta.submitFailed && meta.touched && meta.error}
													>
														<Input {...input} placeholder='' />
													</FieldWrapper>
												)}
											</Field>
										)}
									</>
								</FormBlockGrid>

								<Button type='primary' htmlType='submit' disabled={!dirty || isSubmitting}>
									Save {isSubmitting && <Spin style={{ marginLeft: '16px' }} />}
								</Button>
							</form>
						);
					}}
				/>
			</Styled.Root>
		</>
	);
};
