import React from 'react';
import { Link } from 'react-router-dom';

import { Tooltip } from 'antd';
import { isBoolean } from 'lodash';

import ButtonComponent from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import CVViewIcon from 'components/SVG/CVViewIcon';
import DownloadIcon from 'components/SVG/DownloadIcon';
import EmailIcon from 'components/SVG/EmailIcon';
import EyeCloseIcon from 'components/SVG/EyeCloseIcon';
import EyeOpenIcon from 'components/SVG/EyeOpenIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
import CandidateDatabaseJobsSelect from 'modules/ATS/components/CandidateDatabaseJobsSelect';
import { COLORS } from 'theme';
import { IOption, Routes } from 'types';

import { DataTableType } from '../CandidatesDatabase.types';

import { Styled } from './CandidatesDatabaseTable.styled';

export const columns = (
	activeJobsList: IOption[],
	handleViewCandidate: (candidateApplicationId: number) => void,
	handleCVDownload: (cv: { originalFileName: string; referenceUuid: string }) => void,
	handleAssignCandidateToJobs: (
		applicationId: number,
		prevValues: number[],
		allValues: number[],
	) => void,
	handleVisibilityOfCandidate: (applicationId: number, hidden: boolean) => void,
): ColumnsDataType => [
	{
		title: 'Candidate name',
		dataIndex: 'candidateName',
		key: 'candidateName',
		render: (_: string, { candidate }: Partial<DataTableType>) => (
			<Styled.CandidateName>{candidate}</Styled.CandidateName>
		),
	},
	{
		title: 'Applied for',
		dataIndex: 'appliedFor',
		key: 'appliedFor',
		render: (_: string, { jobTitle }: Partial<DataTableType>) => <span>{jobTitle}</span>,
	},
	{
		title: 'Location',
		dataIndex: 'location',
		key: 'location',
		align: 'center',
		render: (_: string, { jobLocation }: Partial<DataTableType>) => <span>{jobLocation}</span>,
	},
	{
		title: 'Job industry',
		dataIndex: 'jobIndustry',
		key: 'jobIndustry',
		align: 'center',
		render: (_: string, { jobIndustry }: Partial<DataTableType>) => <span>{jobIndustry}</span>,
	},
	{
		title: 'Assign to job',
		dataIndex: 'assignToJob',
		key: 'assignToJob',
		align: 'center',
		render: (_: string, { candidateApplicationId, assignedToJobs }: Partial<DataTableType>) => {
			const prevSelected = assignedToJobs ? assignedToJobs.map(({ id }) => id) : [];

			return (
				<Styled.SelectWrapper>
					<CandidateDatabaseJobsSelect
						activeJobsList={activeJobsList}
						selectedJobs={prevSelected}
						handleSelectJobs={(allValues) =>
							candidateApplicationId &&
							handleAssignCandidateToJobs(candidateApplicationId, prevSelected, allValues)
						}
					/>
				</Styled.SelectWrapper>
			);
		},
	},
	{
		title: 'Visibility',
		dataIndex: 'visibility',
		key: 'visibility',
		align: 'center',
		render: (_: string, { candidateApplicationId, hidden }: Partial<DataTableType>) => {
			const icon = hidden ? (
				<EyeCloseIcon fill={COLORS.black} width='16' height='16' />
			) : (
				<EyeOpenIcon fill={COLORS.black} width='16' height='16' />
			);

			return (
				<Styled.ActionButtons>
					<Tooltip placement='top' title={hidden ? 'Show Candidate' : 'Hide Candidate'}>
						<ButtonComponent
							buttonType={ButtonTypes.default}
							icon={icon}
							onClick={() =>
								candidateApplicationId &&
								isBoolean(hidden) &&
								handleVisibilityOfCandidate(candidateApplicationId, hidden)
							}
						/>
					</Tooltip>
				</Styled.ActionButtons>
			);
		},
	},
	{
		title: 'Actions',
		key: 'actions',
		align: 'center',
		width: '54px',
		render: (_, { cv, candidateApplicationId, candidate }: Partial<DataTableType>) => {
			const isDisabledCVButton = !cv || !cv?.referenceUuid || cv?.referenceUuid === '?';

			return (
				<Styled.ActionButtons>
					<Tooltip placement='top' title='Send Message'>
						<Link
							to={`${Routes.ATS}${Routes.CreateMessage}`}
							state={{ candidateList: [{ id: candidateApplicationId, fullName: candidate }] }}
						>
							<ButtonComponent
								buttonType={ButtonTypes.default}
								icon={<EmailIcon fill={COLORS.black} width='16' height='16' />}
							/>
						</Link>
					</Tooltip>
					<Tooltip placement='top' title='Download CV'>
						<ButtonComponent
							buttonType={ButtonTypes.default}
							icon={<DownloadIcon fill={COLORS.black} width='16' height='16' />}
							onClick={() => cv && handleCVDownload(cv)}
							disabled={isDisabledCVButton}
						/>
					</Tooltip>
					<Tooltip placement='top' title='View Profile'>
						<ButtonComponent
							buttonType={ButtonTypes.default}
							icon={<CVViewIcon fill={COLORS.black} width='16' height='16' />}
							onClick={() => candidateApplicationId && handleViewCandidate(candidateApplicationId)}
						/>
					</Tooltip>
				</Styled.ActionButtons>
			);
		},
	},
];
