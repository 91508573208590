import styled from 'styled-components/macro';

import { COLORS, device } from 'theme';

interface PriceCountProps {
	inputWidth: string;
}

interface PriceUnitProps {
	inputWidth: string;
}

export namespace Styled {

	export const Price = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;
		align-self: center;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		height: 100%;

		span {
			font-size: 16px;
			line-height: 24px;
		}
	`;

	export const AmountFieldWrapper = styled.div`
		max-width: 240px;
	`;

	export const TotalPrice = styled.div`
		display: flex;
		justify-content: space-between;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;
	`;

	export const TotalPriceSmallSize = styled.div`
		display: flex;
		justify-content: space-between;
		margin-bottom: 32px;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
	`;

	export const Line = styled.div`
		width: 100%;
		height: 1px;
		margin: 32px 0;
		border-bottom: 1px dashed ${COLORS.lightBlue1};
	`;
	export const BlockDesc = styled.div`
		color: ${COLORS.black};
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		padding-bottom: 16px;
	`;

	export const InputWrapper = styled.div`
		input {
			height: 40px;
			width: 240px;

			@media ${device.laptop} {
				width: 145px;
			}
		}
	`;

	export const FiedlsBlockWrapper = styled.div`
		display: flex;
		justify-content: space-between;
		margin-bottom: 32px;
	`;

	export const PricesWrapper = styled.div`
		margin-left: 32px;
		width: 100%;
	`;

	export const Prices = styled.div`
		display: flex;
		flex-wrap: wrap;
		width: 100%;

		@media ${device.laptop} {
			flex-wrap: nowrap;
			width: 100%;
		}

		@media ${device.tablet} {
			flex-wrap: wrap;
		}
	`;
	export const PriceUnit = styled.div<PriceUnitProps>`
		margin-right: 16px;
		display: flex;
		align-items: flex-start;

		@media ${device.laptop} {
			flex: 0 1 ${({ inputWidth }) => inputWidth};
		}
	`;
	export const PriceLabel = styled.span`
		margin-right: 8px;
		color: ${COLORS.darkGray2};
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		text-wrap: nowrap;
		padding-top: 5px;
	`;
	export const PriceCountWrapper = styled.div<PriceCountProps>`
		width: ${({ inputWidth }) => inputWidth};

		@media ${device.laptop} {
			width: 100%;
			max-width: ${({ inputWidth }) => inputWidth};
		}
	`;
	export const PriceCount = styled.div`
		padding: 8px 12px;
		border-radius: 4px;
		border: 1px solid ${COLORS.lightGray1};
		background: ${COLORS.lightGray3};
	`;
	export const PriceDesc = styled.div`
		color: ${COLORS.darkGray2};
		font-style: normal;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;	
		padding-top: 8px;

		@media ${device.laptop} {
			font-size: 10px;
		}
	`;
	export const CreditFieldsInfo = styled.div`
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		color: ${COLORS.darkGray3};
		width: 100%;
		text-align: right;

		@media ${device.laptop} {
			font-size: 10px;
		}
	`;
}
