import { Checkbox as CheckboxComponent, Radio } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';
const { Group: RadioGroup } = Radio;

interface IOptionProps {
	color?: string;
}

export namespace Styled {
	export const Root = styled.section`
		position: relative;
		width: 100%;
		font-family: 'Inter';
	`;

	export const Title = styled.h2``;

	export const Main = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 24px;
		gap: 32px;
	`;

	export const Box = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		max-width: 1128px;
		padding: 24px 32px;
		background: ${COLORS.lightGray3};
		border: 1px solid ${COLORS.lightGray3};
		border-radius: 10px;
		gap: 32px;

		h3 {
			width: 100%;
			word-break: break-word;
		}
	`;

	export const Field = styled.div`
		width: 100%;
		max-width: 545px;
	`;

	export const RadioField = styled(RadioGroup)`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 16px;
	`;

	export const RadioOption = styled(Radio)<IOptionProps>`
		height: 48px;
		width: 100%;
		padding: 0 16px;
		font-size: 16px;
		line-height: 48px;
		background: ${COLORS.white};
		border-radius: 10px;
		font-family: 'Inter';

		${({ color }) => `
			color: ${color};
			border: 1px solid ${color};
    `}

		&.ant-radio-wrapper {
			.ant-radio {
				&.ant-radio-checked {
					span.ant-radio-inner {
						background-color: ${({ color }) => color};
						border-color: ${({ color }) => color};
					}
				}
			}

			&:hover,
			&:focus,
			&:active {
				.ant-radio {
					&.ant-radio-checked {
						span.ant-radio-inner {
							background-color: ${({ color }) => color};
							border-color: ${({ color }) => color};
						}

						&:after {
							border-color: ${({ color }) => color};
						}
					}
				}
			}
		}
	`;

	export const CheckboxField = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		gap: 16px;
	`;

	export const CheckboxOptionWrapper = styled.div`
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	`;

	export const CheckboxOption = styled(CheckboxComponent)<IOptionProps>`
		width: 100%;
		min-height: 48px;
		padding: 12px 16px;
		font-size: 16px;
		line-height: 24px;
		background: ${COLORS.white};
		border-radius: 10px;
		font-family: 'Inter';

		${({ color }) => `
			color: ${color};
			border: 1px solid ${color};
    `}

		&.ant-checkbox-wrapper {
			.ant-checkbox {
				&.ant-checkbox-checked {
					span.ant-checkbox-inner {
						background-color: ${({ color }) => color};
						border-color: ${({ color }) => color};
					}
				}
			}

			&:hover,
			&:focus,
			&:active {
				.ant-checkbox {
					&.ant-checkbox-checked {
						span.ant-checkbox-inner {
							background-color: ${({ color }) => color};
							border-color: ${({ color }) => color};
						}

						&:after {
							border-color: ${({ color }) => color};
						}
					}
				}
			}
		}
	`;

	export const TextField = styled.div`
		display: flex;
	`;

	export const TextFieldOption = styled.div`
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 8px 12px;
		width: 100%;
		min-height: 96px;
		background: ${COLORS.white};
		border: 1px solid ${COLORS.lightGray1};
		border-radius: 4px;
		white-space: break-spaces;
		gap: 10px;
	`;
}
