import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		background: ${COLORS.white};
		padding: 24px;
		border-radius: 6px;
		margin-bottom: 24px;
	`;

	export const Head = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
	`;

	export const Title = styled.h4`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.22px;
	`;

	export const ButtonBox = styled.div`
		display: flex;
	`;

	export const NoActiveJobs = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 40px 0;
		img {
			width: 115px;
			margin-bottom: 24px;
		}
		p {
			color: ${COLORS.darkGray1};
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			margin-bottom: 4px;
		}
		button {
			margin-top: 24px;
		}
	`;
}
