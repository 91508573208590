import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function Icon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M9.91682 12.0304C9.33015 11.0771 9.03786 9.9765 9.07559 8.8626C9.06854 8.09964 9.21423 7.34278 9.50434 6.63525C9.79446 5.92772 10.2233 5.28337 10.7664 4.73899C11.3095 4.19462 11.9562 3.76088 12.6696 3.46254C13.383 3.1642 14.1491 3.00711 14.9242 3.00024H15.0443C15.8194 2.9933 16.5883 3.13672 17.307 3.4223C18.0258 3.70788 18.6804 4.13003 19.2334 4.66464C19.7864 5.19925 20.227 5.83585 20.5301 6.5381C20.8332 7.24035 20.9928 7.99448 20.9998 8.75744V8.8626C21.0068 9.62556 20.8611 10.3824 20.571 11.0899C20.2809 11.7975 19.852 12.4418 19.3089 12.9862C18.7658 13.5306 18.1191 13.9643 17.4057 14.2627C16.6923 14.561 15.9262 14.7181 15.1512 14.725H15.0443C13.8107 14.75 12.6004 14.3912 11.5859 13.6997L8.83523 16.3286L10.1705 17.643L8.48806 19.3123L7.15276 17.9979L6.32488 18.826L7.66017 20.1404L5.9777 21.7703L3 18.8391L9.91682 12.0304ZM15.0443 5.33992C14.5724 5.33283 14.1038 5.4191 13.6664 5.59362C13.229 5.76814 12.8316 6.02735 12.4978 6.35589C12.1641 6.68442 11.9008 7.07559 11.7235 7.50618C11.5462 7.93678 11.4586 8.39803 11.4658 8.8626C11.4359 9.34242 11.5062 9.82317 11.6722 10.2753C11.8383 10.7273 12.0965 11.1412 12.4311 11.4913C12.7657 11.8415 13.1696 12.1205 13.6179 12.3112C14.0661 12.502 14.5493 12.6003 15.0377 12.6003C15.526 12.6003 16.0092 12.502 16.4575 12.3112C16.9057 12.1205 17.3096 11.8415 17.6442 11.4913C17.9788 11.1412 18.2371 10.7273 18.4031 10.2753C18.5691 9.82317 18.6394 9.34242 18.6096 8.8626C18.6096 7.9306 18.2344 7.03658 17.5662 6.37633C16.8979 5.71607 15.9911 5.3434 15.0443 5.33992Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(Icon);
