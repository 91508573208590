import React, { type FC, ReactElement, useMemo } from 'react';

import { MenuList } from 'modules/Common/types';
import { UserRoleNamesEnum, UserRoleType } from 'types';

import { ClientContextList } from '../ClientContextContainer.constants';
import SubContextMenu from '../SubContextMenu';

import { Styled } from './ClientWrapper.styled';

type ClientWrapperProps = {
	children?: ReactElement | ReactElement[] | string;
	clientId: string;
	role: UserRoleType;
};

// The list should be equal the list of BOClientContextRouteRoleMap
const AllowedSuperUserItems = {
	[UserRoleNamesEnum.BACK_MANAGER]: [
		MenuList.Dashboard,
		MenuList.Vacancies,
		MenuList.Questionnaires,
		MenuList.Candidates,
		MenuList.Contracts,
		MenuList.Invoices,
		MenuList.SupportTickets,
		MenuList.Users,
	],
	[UserRoleNamesEnum.BACK_CUSTOMER_SUPPORT]: [
		MenuList.Dashboard,
		MenuList.Vacancies,
		MenuList.Questionnaires,
		MenuList.Candidates,
		MenuList.Contracts,
		MenuList.Invoices,
		MenuList.SupportTickets,
		MenuList.Users,
	],
	[UserRoleNamesEnum.BACK_SALES]: [
		MenuList.Dashboard,
		MenuList.Vacancies,
		MenuList.Questionnaires,
		MenuList.Candidates,
		MenuList.Contracts,
		MenuList.Invoices,
		MenuList.SupportTickets,
		MenuList.Users,
	],
};

const ClientWrapper: FC<ClientWrapperProps> = ({ children, clientId, role }) => {
	const isSuperUser = role?.name === UserRoleNamesEnum.BACK_SUPER_USER;

	const filteredMenuList = useMemo(
		() =>
			isSuperUser
				? ClientContextList
				: ClientContextList.filter(
					(i) => role?.name && AllowedSuperUserItems[role?.name]?.includes(i.name),
				  ),
		[role],
	);

	return (
		<Styled.Root>
			<Styled.BoxWrapper>
				<SubContextMenu clientId={clientId} items={filteredMenuList} isFullWidth={isSuperUser} />
				{children}
			</Styled.BoxWrapper>
		</Styled.Root>
	);
};

export default ClientWrapper;
