import { useEffect, useRef, useState, useCallback } from 'react';

import { Participant } from '@zoom/videosdk';

import { useMount } from 'hooks';
import { GenericType } from 'types';

export const useParticipantsChange = (
	zoomClient: GenericType,
	callback: (participants: Participant[], updatedParticipants?: Participant[]) => void,
	listOfSpeakingParticipants: Participant[],
) => {
	const fnRef = useRef(callback);
	fnRef.current = callback;

	const handler = useCallback(
		(updatedParticipants?: Participant[]) => {
			const participants = zoomClient.getAllUser();
			fnRef.current?.(participants, updatedParticipants);
		},
		[zoomClient],
	);

	useEffect(() => {
		const participants = zoomClient.getAllUser();
		fnRef.current?.(participants, participants);
	}, [listOfSpeakingParticipants]);

	useEffect(() => {
		zoomClient.on('user-added', handler);
		zoomClient.on('user-removed', handler);
		zoomClient.on('user-updated', handler);

		return () => {
			zoomClient.off('user-added', handler);
			zoomClient.off('user-removed', handler);
			zoomClient.off('user-updated', handler);
		};
	}, [zoomClient, handler]);

	useMount(() => {
		handler();
	});
};

export function useVideoAspect(zmClient: GenericType) {
	const [aspectRatioRes, setAspectRatio] = useState<Record<string, number>>({});
	const onVideoAspectRatioChange = useCallback((payload) => {
		const { userId, aspectRatio } = payload;
		setAspectRatio((s) => {
			return { ...s, [`${userId}`]: aspectRatio };
		});
	}, []);

	useEffect(() => {
		zmClient.on('video-aspect-ratio-change', onVideoAspectRatioChange);

		return () => {
			zmClient.off('video-aspect-ratio-change', onVideoAspectRatioChange);
		};
	});

	return aspectRatioRes;
}
