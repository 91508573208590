import React, { FC } from 'react';

import CameraSmallIcon from 'components/SVG/CameraSmallIcon';
import MuteSmallIcon from 'components/SVG/MuteSmallIcon';

import { Styled } from './MembersInfo.styled';

type MembersInfoProps = {
	joined: string;
	meetingHistory: {
		message: string;
		time: string;
	}[];
};

const MembersInfo: FC<MembersInfoProps> = ({ joined, meetingHistory }) => {
	return (
		<Styled.Root>
			<Styled.InfoSection>
				<Styled.InfoSectionTitle>Joined ({joined.length})</Styled.InfoSectionTitle>
				{joined.map((j) => (
					<Styled.InfoSectionItem key={j.userGuid}>
						<Styled.InfoSectionItemText>
							{j.displayName} {j.isCurrentUser ? '(You)' : ''}
						</Styled.InfoSectionItemText>
						<Styled.InfoSectionItemIcons>
							<MuteSmallIcon active={j.muted} />
							<CameraSmallIcon active={j.bVideoOn} />
						</Styled.InfoSectionItemIcons>
					</Styled.InfoSectionItem>
				))}
			</Styled.InfoSection>
			<Styled.InfoSection>
				<Styled.InfoSectionTitle>History</Styled.InfoSectionTitle>
				{meetingHistory.map((h) => (
					<Styled.InfoSectionItem key={h.time}>
						<Styled.InfoSectionItemText>{h.message}</Styled.InfoSectionItemText>
						<Styled.InfoSectionItemIcons>{h.time.slice(0, 5)}</Styled.InfoSectionItemIcons>
					</Styled.InfoSectionItem>
				))}
			</Styled.InfoSection>
		</Styled.Root>
	);
};

export default MembersInfo;
