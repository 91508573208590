import React, { type FC, useCallback, useState } from 'react';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { Button, Modal } from 'antd';
import { isEmpty } from 'lodash';

import PDFViewer from 'modules/Common/components/PDFViewer';
import { ReceiptPdf } from 'modules/Common/containers/FinancePdf';
import { IContractValues, InvoicesTypesValuesEnum, ReceiptDataType } from 'modules/Common/types';
import { CountriesType, GenericType } from 'types';

import { Styled } from './ReceiptModal.styled';

type ReceiptModalProps = {
	open: boolean;
	onCancel: () => void;
	content: IContractValues | ReceiptDataType;
	creditsFields: GenericType[];
	countries: CountriesType;
	rootClassName?: string;
	className?: string;
};

export const ReceiptModal: FC<ReceiptModalProps> = ({
	open,
	onCancel,
	content,
	creditsFields,
	countries,
	rootClassName = '',
	className = '',
}) => {
	const [fileUrl, setFileUrl] = useState('');

	const handleSetFileUrl = useCallback((url: string) => setFileUrl(url), []);

	const isForContract =
		content &&
		'invoiceType' in content &&
		content?.invoiceType?.value === InvoicesTypesValuesEnum.ContractAccept;

	return (
		<Modal
			open={open}
			footer={null}
			centered
			onCancel={onCancel}
			width='auto'
			rootClassName={rootClassName}
			className={className}
		>
			<Styled.ModalHead>
				<h3>{`Receipt for ${isForContract ? 'Contract' : 'Invoice'} ${
					content?.btoReference ?? ''
				}`}</h3>
				{!isEmpty(content) && (
					<PDFDownloadLink
						document={
							<ReceiptPdf contract={content} creditsFields={creditsFields} countries={countries} />
						}
						fileName={`${content?.btoReference}-receipt.pdf`}
					>
						{({ loading, error, url }) => {
							!error && url && handleSetFileUrl(url);

							return (
								<Button type='primary' htmlType='button' disabled={loading}>
									Download PDF
								</Button>
							);
						}}
					</PDFDownloadLink>
				)}
			</Styled.ModalHead>
			<Styled.ModalContent>
				{content && <PDFViewer pdfUrl={fileUrl} isBorder />}
			</Styled.ModalContent>
		</Modal>
	);
};
