import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { rootReducerObj } from '../reducers';
import rootSaga from '../sagas';

function createReducer(asyncReducers) {
	return combineReducers({
		...rootReducerObj,
		...asyncReducers,
	});
}

const composeEnhancers =
	(typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const sagaMiddleware = createSagaMiddleware();

const store = {
	...createStore(createReducer(), composeEnhancers(applyMiddleware(sagaMiddleware))),
	runSaga: sagaMiddleware.run,
};

store.asyncReducers = {};

store.injectReducer = (key, asyncReducer) => {
	store.asyncReducers[key] = asyncReducer;
	store.replaceReducer(createReducer(store.asyncReducers));
};

sagaMiddleware.run(rootSaga);

export { store };
