import { Table } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace S {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;
	`;

	export const TableContainer = styled((props) => <Table {...props} />)`
		border-collapse: collapse;

		.ant-table {
			table {
				border-collapse: collapse;
			}

			.ant-table-cell {
				font-family: 'Inter';
				color: ${COLORS.black};
			}
				
			.ant-table-thead {
				font-weight: 500;
				font-size: 12px;
				line-height: 20px;
				text-transform: uppercase;
				border: 1px solid ${COLORS.lightGray2};

				tr {
					&:first-child > *:first-child,
					&:last-child > *:last-child {
						border-radius: 0;
					}

					th {
						padding: 8px 16px;
						color: ${COLORS.darkGray2};
						background-color: ${COLORS.lightGray3};
						border: 0;
						border-radius: 0;
						border-collapse: collapse;

						&:first-of-type,
						&:last-of-type {
							border-radius: 0;
						}

						&::before,
						&::after {
							display: none;
						}
					}
				}
			}

			.ant-table-tbody .ant-table-row,
			.ant-table-summary tr {
				border: 1px solid ${COLORS.lightGray2};

				td {
					padding: 12px 16px;
					font-weight: 400;
					font-size: 16px;
					line-height: 24px;
					border: 0;
				}
			}

			.ant-table-summary tr {
				background-color: ${COLORS.lightGray3};
			}
		}
	`;
}
