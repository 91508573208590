import React, { FC, useState, useEffect, useMemo } from 'react';

import Spinner from 'components/Spinner';

import { Styled } from './DashboardAllCandidates.styled';
import DoughnutChart from './DoughnutChart/DoughnutChart';

type CandidateStatisticsType = {
	numOfCandidates: number;
	state: { id: number; name: string; value: string };
};

type DashboardAllCandidatesProps = {
	loading: boolean;
	candidateStatistics: CandidateStatisticsType[];
	getCandidateStatistics: (timeFrameInDays?: number) => void;
	clientId?: string;
};

type ChartDataType = { name: string; value: number }[];

const defaultEmptyChartList = [
	{ name: 'New candidates', value: 0 },
	{ name: 'Potential', value: 0 },
	{ name: 'Interviews', value: 0 },
	{ name: 'Offers', value: 0 },
	{ name: 'Hired', value: 0 },
	{ name: 'Rejected', value: 0 },
];

const filterOptions = [
	{ value: 1, label: 'Last week', days: 7 },
	{ value: 2, label: 'Last month', days: 30 },
	{ value: 3, label: 'Last year', days: 365 },
];

const DashboardAllCandidates: FC<DashboardAllCandidatesProps> = ({
	loading,
	candidateStatistics,
	getCandidateStatistics,
	clientId,
}) => {
	const [chartData, setChartData] = useState<ChartDataType>(defaultEmptyChartList);
	const [selectedFilter, setSelectedFilter] = useState<number>(2);

	const filterDays = useMemo(
		() => filterOptions.find((item) => item.value === selectedFilter)?.days,
		[selectedFilter],
	);

	const sortFunction = (a: CandidateStatisticsType, b: CandidateStatisticsType) =>
		a.state.id - b.state.id;

	useEffect(() => {
		getCandidateStatistics(filterDays);
	}, [clientId, selectedFilter]);

	useEffect(() => {
		if (candidateStatistics?.length) {
			const sortedStatistics = [...candidateStatistics]
				.sort(sortFunction)
				.filter((item) => item.state.name !== 'Rejected');
			const transformedData = sortedStatistics.map((item) => ({
				name: item.state.name,
				value: item.numOfCandidates,
			}));

			setChartData(transformedData.length ? transformedData : defaultEmptyChartList);
		} else {
			setChartData(defaultEmptyChartList);
		}
	}, [candidateStatistics]);

	if (loading) {
		return <Spinner fixed />;
	}

	const handleFilterChange = (value: number) => {
		setSelectedFilter(value);
	};

	return (
		<Styled.Root>
			<Styled.Head>
				<Styled.Title>All candidates</Styled.Title>
				<Styled.Select
					options={filterOptions}
					value={selectedFilter}
					onChange={(e) => handleFilterChange(e as number)}
					placeholder='Choose period'
				></Styled.Select>
			</Styled.Head>
			<Styled.Content>
				<DoughnutChart data={chartData} />
			</Styled.Content>
		</Styled.Root>
	);
};

export default DashboardAllCandidates;
