import { FC } from 'react';
import { Field, useFormState } from 'react-final-form';

import { Select, Input } from 'antd';

import FieldWrapper from 'components/FieldWrapper';
import * as Styled from 'modules/BackOffice/containers/ClientChangesFormBranding/ClientChangesFormBranding.styled';
import FormBlock from 'modules/Common/components/FormBlock';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { HINTS } from 'modules/Common/constants';
import { ClientChangeFormDataType } from 'modules/Common/types';
import { OptionsType } from 'types';
import {
	composeValidators,
	requiredFieldValidator,
	requiredValidator,
	emailValidator,
	lettersValidator,
	lengthValidator,
} from 'utils/validators';

type MainDetailsProps = {
	clientRegions: OptionsType[];
	clientChangesFormData: ClientChangeFormDataType;
	isEdit: boolean;
};

const MainDetails: FC<MainDetailsProps> = ({ clientRegions, clientChangesFormData, isEdit }) => {
	const { values } = useFormState();

	return (
		<FormBlock title='Main Details'>
			<FormBlockLine>
				<Styled.FieldWrapper>
					<Field
						name='clientName'
						validate={requiredFieldValidator}
						initialValue={clientChangesFormData.clientName}
					>
						{({ input, meta }) => (
							<FieldWrapper
								name='clientName'
								label='Client / Company Name'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Input {...input} placeholder='Enter the name' />
							</FieldWrapper>
						)}
					</Field>
				</Styled.FieldWrapper>
				<Styled.FieldWrapper>
					<Field
						name='clientCode'
						validate={composeValidators(lengthValidator(4, 10), lettersValidator)}
						initialValue={clientChangesFormData.clientCode}
					>
						{({ input, meta }) => (
							<FieldWrapper
								name='clientCode'
								label='Client Code'
								hint='Used to match jobs from Broadbean'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Input {...input} placeholder='Enter the code' />
							</FieldWrapper>
						)}
					</Field>
				</Styled.FieldWrapper>
			</FormBlockLine>
			<Styled.Space height={32} />
			<FormBlockLine>
				<Styled.FieldWrapper>
					<Field
						name='subdomain'
						validate={requiredFieldValidator}
						initialValue={clientChangesFormData.subdomain}
					>
						{({ input, meta }) => (
							<FieldWrapper
								name='subdomain'
								label='Custom Subdomain'
								hint='Branded portal URL sample: https://yourdomain.connectats.com'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Input
									{...input}
									addonBefore='https://'
									addonAfter='.connectats.com'
									placeholder='yourdomain'
								/>
							</FieldWrapper>
						)}
					</Field>
				</Styled.FieldWrapper>
				<Styled.FieldWrapper>
					<Field
						name='email'
						initialValue={clientChangesFormData.email}
						validate={composeValidators(requiredValidator, emailValidator)}
					>
						{({ input, meta }) => (
							<FieldWrapper
								name='email'
								label='Primary Email Address'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Input {...input} placeholder='applecorpprimary@mail.co.uk' />
							</FieldWrapper>
						)}
					</Field>
				</Styled.FieldWrapper>
			</FormBlockLine>
			<Styled.Space height={32} />
			<FormBlockLine>
				<Styled.FieldWrapper>
					<Field
						name='region'
						initialValue={clientChangesFormData?.region}
						validate={requiredFieldValidator}
					>
						{({ input, meta }) => (
							<FieldWrapper
								name='region'
								label='Country'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
								hint={isEdit ? HINTS.ParentCompanyCountry : ''}
							>
								<Select
									{...input}
									allowClear
									disabled={!!values.parent || isEdit}
									value={input.value || null}
									placeholder="Select the client's country"
									options={clientRegions}
								/>
							</FieldWrapper>
						)}
					</Field>
				</Styled.FieldWrapper>
			</FormBlockLine>
		</FormBlock>
	);
};

export default MainDetails;
