import { type FC, useEffect, useState } from 'react';
import { Field } from 'react-final-form';

import { Space, Checkbox } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

import FieldWrapper from 'components/FieldWrapper';
import { requiredFieldValidator } from 'utils/validators';

import {
	AssessmentType,
	ApplicationQuestion,
} from '../../AssessmentContainer/AssessmentContainer.types';

type AssessmentFormMultiselectProps = {
	assessment: AssessmentType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cb: any;
};

const AssessmentFormMultiselect: FC<AssessmentFormMultiselectProps> = ({ assessment, cb }) => {
	const { assessmentAnswers, autoreject } = assessment;
	const [value, setValue] = useState<CheckboxValueType[]>([]);
	const [preparedValue, setPreparedValue] = useState({
		question: assessment.id,
		applicationAnswers: assessmentAnswers.map((aa) => ({
			answer: aa.id,
			text: null,
			checked: false,
		})),
	});

	const onChange = (e: CheckboxValueType[]) => {
		setValue(e);
	};

	useEffect(() => {
		const newApplicationAnswers = preparedValue.applicationAnswers.map((aa) => ({
			answer: aa.answer,
			text: null,
			checked: value.includes(aa.answer),
		}));

		setPreparedValue({
			question: assessment.id,
			applicationAnswers: newApplicationAnswers,
		});
	}, [value]);

	useEffect(() => {
		cb((init: ApplicationQuestion[]) => {
			return [preparedValue, ...init.filter((aa) => aa.question !== preparedValue.question)];
		});
	}, [preparedValue]);

	return (
		<div>
			<Field
				name={`assessment-${assessment.id}`}
				validate={autoreject ? requiredFieldValidator : undefined}
			>
				{({ input, meta }) => {
					return (
						<FieldWrapper
							name={`assessment-${assessment.id}`}
							errorMessage={meta.submitFailed && meta.touched && meta.error}
							isFixed
						>
							<Checkbox.Group
								{...input}
								onChange={(e) => {
									onChange(e);

									return input.onChange(e);
								}}
							>
								<Space direction='vertical'>
									{assessmentAnswers.map((aa) => (
										<Checkbox key={aa.id} value={aa.id}>
											{aa.answer}
										</Checkbox>
									))}
								</Space>
							</Checkbox.Group>
						</FieldWrapper>
					);
				}}
			</Field>
		</div>
	);
};

export default AssessmentFormMultiselect;
