import styled from 'styled-components/macro';

export namespace Styled {
	export const Head = styled.section`
		display: flex;
		justify-content: space-between;
		margin-bottom: 24px;
	`;

	export const Title = styled.h2``;

	export const Content = styled.section`
		position: relative;
	`;

	export const Buttons = styled.section`
		display: flex;
		align-items: center;
		gap: 16px;
	`;
}
