import { CountriesEnumIds, UserRolesEnum } from 'types';
import { handlersDefaultCase } from 'utils/reduxHelpers';

export const initialState = {
	user: { clientId: null, client: { subdomain: '', logo: null } },
	roles: {
		isSuperUser: false,
		isManagerUser: false,
		isSalesUser: false,
		isServiceUser: false,
		isAtsSuperUser: false,
		isAtsStandardUser: false,
		isAtsFinanceUser: false,
	},
	countries: {
		isUK: false,
		isUS: false,
	},
	questionnaire: {
		welcome: null,
		details: null,
		currentQuestion: null,
	},
	emails: {
		currentEmailThread: null,
	},
	errorMessage: null,
};

export const unregisteredReducer = (TYPES) => {
	return (state = initialState, action = {}) => {
		const handlers = {
			[TYPES.CHECK_CLIENT_INFO.SUCCESS]: () => {
				const { subdomain, logo, ...rest } = action.payload;

				return {
					...state,
					user: {
						...rest,
						client: { subdomain, logo },
					},
				};
			},
			[TYPES.CHECK_CLIENT_INFO.FAILED]: () => ({
				...state,
				user: initialState.user,
				errorMessage: action.payload,
			}),
			[TYPES.GET_USER.SUCCESS]: () => {
				// Save user "id" into "userId" prop
				const { id: userId, client, roles } = action.payload;

				return {
					...state,
					user: {
						...action.payload,
						...(!!userId && { userId }),
						clientId: client.id,
					},
					roles: {
						isSuperUser: roles[0]?.id === UserRolesEnum.BACK_SUPER_USER,
						isManagerUser: roles[0]?.id === UserRolesEnum.BACK_MANAGER,
						isSalesUser: roles[0]?.id === UserRolesEnum.BACK_SALES,
						isServiceUser: roles[0]?.id === UserRolesEnum.BACK_CUSTOMER_SUPPORT,
						isAtsSuperUser: roles[0]?.id === UserRolesEnum.JAT_SUPER_USER,
						isAtsStandardUser: roles[0]?.id === UserRolesEnum.JAT_USER,
						isAtsFinanceUser: roles[0]?.id === UserRolesEnum.JAT_FINANCE_USER,
					},
					countries: {
						isUK: client?.region === CountriesEnumIds.UK,
						isUS: client?.region === CountriesEnumIds.US,
					},
				};
			},
			[TYPES.GET_USER.FAILED]: () => ({
				...state,
				user: initialState.user,
				roles: initialState.roles,
			}),
			[TYPES.CHANGE_PASSWORD.SUCCESS]: () => ({
				...state,
				user: { ...action.payload, client: { subdomain: action.payload.subdomain } },
				errorMessage: null,
			}),
			[TYPES.CHANGE_PASSWORD.FAILED]: () => ({
				...state,
				user: initialState.user,
				errorMessage: action.payload,
			}),
			[TYPES.LOGIN.REQUESTED]: () => ({
				...state,
				user: initialState.user,
				roles: initialState.roles,
				errorMessage: initialState.errorMessage,
			}),
			[TYPES.LOGIN.SUCCESS]: () => {
				const { subdomain } = action.payload;

				return {
					...state,
					user: { ...action.payload, client: { subdomain } },
					errorMessage: null,
				};
			},
			[TYPES.IMPERSONATE.SUCCESS]: () => {
				const { subdomain } = action.payload;

				return {
					...state,
					user: { ...action.payload, client: { subdomain } },
					errorMessage: null,
				};
			},
			[TYPES.GET_QUESTIONNAIRE_WELCOME.REQUESTED]: () => ({
				...state,
				questionnaire: {
					...state.questionnaire,
					welcome: initialState.questionnaire.welcome,
				},
			}),
			[TYPES.GET_QUESTIONNAIRE_WELCOME.SUCCESS]: () => ({
				...state,
				questionnaire: {
					...state.questionnaire,
					welcome: action.payload,
				},
			}),
			[TYPES.GET_QUESTIONNAIRE_WELCOME.FAILED]: () => ({
				...state,
				questionnaire: {
					...state.questionnaire,
					welcome: {
						expiredOrCanceled: true,
					},
				},
			}),
			[TYPES.GET_CURRENT_QUESTION.REQUESTED]: () => ({
				...state,
				questionnaire: {
					...state.questionnaire,
					currentQuestion: initialState.questionnaire.currentQuestion,
				},
			}),
			[TYPES.GET_CURRENT_QUESTION.SUCCESS]: () => ({
				...state,
				questionnaire: {
					...state.questionnaire,
					currentQuestion: action.payload,
				},
			}),
			[TYPES.GET_QUESTIONNAIRE_DETAILS.REQUESTED]: () => ({
				...state,
				questionnaire: {
					...state.questionnaire,
					details: initialState.questionnaire.details,
				},
			}),
			[TYPES.GET_QUESTIONNAIRE_DETAILS.SUCCESS]: () => ({
				...state,
				questionnaire: {
					...state.questionnaire,
					details: action.payload,
				},
			}),
			[TYPES.GET_EMAIL_THREAD.SUCCESS]: () => ({
				...state,
				emails: {
					...state.emails,
					currentEmailThread: action.payload,
				},
			}),
			[TYPES.REPLY_TO_EMAIL_THREAD.SUCCESS]: () => ({
				...state,
				emails: {
					...state.emails,
					currentEmailThread: action.payload,
				},
			}),
			[TYPES.LOGIN.FAILED]: () => ({
				...state,
				user: initialState.user,
				roles: initialState.roles,
				errorMessage: action.payload,
			}),
			[TYPES.LOGOUT.SUCCESS]: () => ({
				...initialState,
			}),
		};

		return handlersDefaultCase(handlers, action, state);
	};
};
