import React, { FC, ReactElement } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import ClientContextContainer from '../../containers/ClientContextContainer';

import { Styled } from './ClientContextPage.styled';

type ClientContextPageProps = {
	children?: ReactElement | ReactElement[] | string;
};

export const ClientContextPage: FC<ClientContextPageProps> = ({ children }) => {
	const { clientId } = useParams();

	return (
		<Styled.Content>
			<ClientContextContainer clientId={clientId}>
				<Outlet />
			</ClientContextContainer>
		</Styled.Content>
	);
};
