import { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Collapse, Input, Select } from 'antd';
import { debounce } from 'lodash';

import Box from 'components/Box';
import Overlay from 'components/Overlay';
import { useMount, useUnmount } from 'hooks';
import { atsDucks } from 'modules/ATS/ducks';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { DEFAULT_PAGE_SIZE, DEFAULT_CURRENT_PAGE } from 'modules/Common/constants/table';
import { commonDucks } from 'modules/Common/ducks';
import { AssignCandidateToVacancyDatabaseResponseType } from 'modules/Common/types';
import { handleJobAssignNotificationMessages } from 'modules/Common/utils/commonHelpers';
import { GenericType, IOption, SubscriptionPlansType } from 'types';
import { filterSelectOptions } from 'utils/helpers';

import { Styled } from './CandidatesDatabase.styled';
import { CandidatesDatabaseType } from './CandidatesDatabase.types';
import CandidatesDatabaseTable from './CandidatesDatabaseTable/CandidatesDatabaseTable';

type CandidatesDatabaseProps = {
	subscriptionPlans: SubscriptionPlansType;
	candidatesDatabaseList: CandidatesDatabaseType;
	jobIndustries: IOption[];
	loading: GenericType;
	getCandidatesDatabaseRequested: ({
		page,
		size,
		name,
		showOption,
		title,
		industry,
		location,
	}: {
		page: number;
		size: number;
		name?: string;
		showOption?: string;
		title?: string;
		industry?: number;
		location?: string;
	}) => void;
	assignCandidateToVacancyDatabase: (
		candidateApplicationId: number,
		jobs: number[],
		callback: (response: AssignCandidateToVacancyDatabaseResponseType) => void,
	) => void;
	showHideCandidateInDatabaseRequested: (
		applicationId: number,
		data: { showAction: string },
		callback?: () => void,
	) => void;
	getJobIndustriesRequested: () => void;
	resetCandidateDatabase: () => void;
};

const visibilityFilterOptions = [
	{ label: 'All', value: 'ALL' },
	{ label: 'Exclude hidden candidates', value: 'SHOWN' },
	{ label: 'Shown hidden only', value: 'HIDDEN' },
];

const { Panel } = Collapse;

export const CandidatesDatabase: FC<CandidatesDatabaseProps> = ({
	subscriptionPlans,
	candidatesDatabaseList,
	jobIndustries,
	loading,
	getCandidatesDatabaseRequested,
	assignCandidateToVacancyDatabase,
	showHideCandidateInDatabaseRequested,
	getJobIndustriesRequested,
	resetCandidateDatabase,
}) => {
	const [searchByName, setSearchByName] = useState<string>('');
	const [searchByTitle, setSearchByTitle] = useState<string>('');
	const [searchByIndustry, setSearchByIndustry] = useState<number | undefined>();
	const [searchByLocation, setSearchByLocation] = useState<string>('');
	const [visibilityFilter, setVisibilityFilter] = useState<string>(
		visibilityFilterOptions[1].value,
	);

	useMount(() => {
		!jobIndustries.length && getJobIndustriesRequested();
		!candidatesDatabaseList &&
			getCandidatesDatabaseRequested({
				page: DEFAULT_CURRENT_PAGE,
				size: DEFAULT_PAGE_SIZE,
				showOption: visibilityFilter,
			});
	});

	useUnmount(() => {
		resetCandidateDatabase();
	});

	const searchParams = {
		page: DEFAULT_CURRENT_PAGE,
		size: DEFAULT_PAGE_SIZE,
		name: searchByName,
		title: searchByTitle,
		industry: searchByIndustry,
		location: searchByLocation,
		showOption: visibilityFilter,
	};

	const handlePaginationChange = useCallback(
		(page: number, size: number) => {
			getCandidatesDatabaseRequested({
				...searchParams,
				page,
				size,
			});
		},
		[searchByName, visibilityFilter, searchByTitle, searchByIndustry, searchByLocation],
	);

	const debouncedSearch = useCallback(
		debounce((searchTerm) => {
			getCandidatesDatabaseRequested({
				...searchParams,
				name: searchTerm,
			});
		}, 500),
		[visibilityFilter, searchByTitle, searchByIndustry, searchByLocation],
	);

	const handleSearch = (val: string) => {
		setSearchByName(val);
		if (val.length >= 3 || val.length === 0) {
			debouncedSearch(val);
		}
	};

	const handleAdvancedSearch = () => {
		getCandidatesDatabaseRequested({
			...searchParams,
		});
	};

	const handleVisibilityFilterChange = useCallback(
		(value: string) => {
			setVisibilityFilter(value);
			getCandidatesDatabaseRequested({
				...searchParams,
				showOption: value,
			});
		},
		[
			getCandidatesDatabaseRequested,
			searchByName,
			searchByTitle,
			searchByIndustry,
			searchByLocation,
		],
	);

	const handleVisibilityOfCandidate = (applicationId: number, hidden: boolean) => {
		showHideCandidateInDatabaseRequested(
			applicationId,
			{
				showAction: hidden ? 'SHOW' : 'HIDE',
			},
			() =>
				getCandidatesDatabaseRequested({
					...searchParams,
				}),
		);
	};

	const handleAssignCandidateToJobs = (
		applicationId: number,
		prevValues: number[],
		allValues: number[],
	) => {
		const prevValuesSet = new Set(prevValues);
		const newValues = allValues.filter((value) => !prevValuesSet.has(value));

		assignCandidateToVacancyDatabase(applicationId, newValues, (res) => {
			handleJobAssignNotificationMessages(res);
			getCandidatesDatabaseRequested({
				...searchParams,
			});
		});
	};

	useEffect(() => {
		return () => {
			debouncedSearch.cancel();
		};
	}, [debouncedSearch]);

	const candidatesDatabaseLoading = !!loading?.getCandidatesDatabaseLoad;

	return (
		<>
			{subscriptionPlans.isPremiumPlan ? (
				<Box>
					<Styled.Header>
						<FormBlockLine>
							<Input
								value={searchByName}
								placeholder='Search by name'
								autoComplete='off'
								prefix={<SearchOutlined style={{ color: 'gray' }} />}
								onChange={(e) => handleSearch(e.target.value)}
								allowClear
							/>
							<Select
								value={visibilityFilter}
								onChange={(value) => handleVisibilityFilterChange(value)}
								options={visibilityFilterOptions}
								placeholder='-Select Vacancy-'
								disabled={candidatesDatabaseLoading}
								autoClearSearchValue
							/>
						</FormBlockLine>
						<Collapse>
							<Panel header='Advanced Search' key='1'>
								<FormBlockLine>
									<Styled.InputWrap>
										<Input
											value={searchByTitle}
											placeholder='Search by title'
											autoComplete='off'
											prefix={<SearchOutlined style={{ color: 'gray' }} />}
											onChange={(e) => setSearchByTitle(e.target.value)}
											disabled={candidatesDatabaseLoading}
											allowClear
										/>
									</Styled.InputWrap>
									<Styled.SelectWrap>
										<Select
											value={searchByIndustry}
											options={jobIndustries}
											onChange={(value) => setSearchByIndustry(value)}
											filterOption={(inputValue, option) =>
												filterSelectOptions(inputValue, option, 'name')
											}
											fieldNames={{ label: 'name', value: 'id' }}
											disabled={candidatesDatabaseLoading}
											placeholder='Search by industry'
											showSearch
											allowClear
										/>
									</Styled.SelectWrap>
									<Styled.InputWrap>
										<Input
											value={searchByLocation}
											placeholder='Search by location'
											autoComplete='off'
											prefix={<SearchOutlined style={{ color: 'gray' }} />}
											onChange={(e) => setSearchByLocation(e.target.value)}
											disabled={candidatesDatabaseLoading}
											allowClear
										/>
									</Styled.InputWrap>
									<Button
										type='primary'
										onClick={handleAdvancedSearch}
										disabled={candidatesDatabaseLoading}
									>
										Search
									</Button>
								</FormBlockLine>
							</Panel>
						</Collapse>
					</Styled.Header>
					<CandidatesDatabaseTable
						candidates={candidatesDatabaseList}
						loading={candidatesDatabaseLoading}
						handlePaginationChange={handlePaginationChange}
						handleVisibilityOfCandidate={handleVisibilityOfCandidate}
						handleAssignCandidateToJobs={handleAssignCandidateToJobs}
					/>
				</Box>
			) : (
				<Overlay
					title='This feature requires a pro membership, upgrade or try free here'
					buttonTitle='Upgrade Plan'
				/>
			)}
		</>
	);
};

export default connect(
	(state) => ({
		subscriptionPlans: atsDucks.atsSelectors.getSubscriptionPlans(state),
		candidatesDatabaseList: atsDucks.atsSelectors.getAtsCandidatesDatabaseState(state),
		jobIndustries: commonDucks.commonSelectors.getJobIndustries(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getCandidatesDatabaseRequested: atsDucks.atsActions.getCandidatesDatabaseRequested,
		assignCandidateToVacancyDatabase: atsDucks.atsActions.assignCandidateToVacancyDatabaseRequested,
		showHideCandidateInDatabaseRequested: atsDucks.atsActions.showHideCandidateInDatabaseRequested,
		getJobIndustriesRequested: commonDucks.commonActions.getJobIndustriesRequested,
		resetCandidateDatabase: atsDucks.atsActions.resetCandidateDatabase,
	},
)(CandidatesDatabase);
