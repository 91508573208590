import { Link as LinkComponent } from 'react-router-dom';

import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';
import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		margin-top: 24px;
		margin-bottom: 24px;
	`;

	export const ClientPageLink = styled(LinkComponent)`
		line-height: 28px;
		height: 28px;
		min-width: 28px;
		font-weight: 500;
		color: ${COLORS.black};
	`;

	export const Button = styled(ButtonComponent)`
		line-height: 28px;
		height: 28px;
		min-width: 28px;
		padding: 4px 8px;
		gap: 8px;
	`;

	export const ButtonLink = styled(LinkComponent)``;

	export const Title = styled.h2`
		margin: 24px 0 0;
	`;
}
