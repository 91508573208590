import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;
	`;
	export const CountryPricing = styled.div`
		display: flex;
		width: 100%;
		padding-bottom: 16px;
	`;
	export const CountryPricingLabel = styled.div`
		width: 32px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		margin-right: 48px;
		padding-top: 8px;
	`;
	export const CountryPricingContent = styled.div`
		width: 100%;
	`;
	export const SubmitButtonWrapper = styled.div`
		width: 100%;
		display: flex;
		justify-content: flex-end;
	`;
	export const Bundles = styled.div`
		margin-bottom: 32px;
	`;
	export const BundlesLabel = styled.div`
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%;
		color: ${COLORS.black};
		margin-bottom: 8px;
	`;
	export const BundlesWrapper = styled.div`
		display: flex;
		flex-wrap: wrap;
	`;
}
