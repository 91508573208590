import type { MenuProps } from 'antd';

export type MenuItem = Required<MenuProps>['items'][number];

export const getAntdDropdownMenu = (
	items: MenuItem[],
	onClick: (payload: { key: string }) => void,
): MenuProps => {
	return {
		items,
		onClick,
		theme: 'dark',
	};
};
