import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.section``;

	export const Header = styled.div`
		margin-bottom: 20px;
		
		.ant-collapse-header,
		.ant-select-selection-item,
		.ant-select-item,
		input,
		button {
			font-family: 'Inter';
		}
	`;

	export const InputWrap = styled.div``;

	export const SelectWrap = styled.div`
		.ant-select {
			width: 100%;
		}
	`;
}
