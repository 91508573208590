import React, { Key } from 'react';
import { Link } from 'react-router-dom';

import { Button, Popover } from 'antd';

import { ColumnsDataType } from 'components/Table/Table.types';
import Badge from 'modules/Common/components/Badge';
import Tag from 'modules/Common/components/Tag';
import {
	ContractStatusEnum,
	ContractStatusValuesEnum,
	IContractValues,
	TableColumnsType,
	TagColoursType,
} from 'modules/Common/types';
import { Routes } from 'types';
import { getCurrencySymbolByName, getDateFormat, handleBtoReference } from 'utils/helpers';

import { Styled } from './FinanceTables.styled';

type DataTableType = IContractValues & {
	key?: Key;
};

export const columns = (): ColumnsDataType & TableColumnsType => [
	{
		title: 'Contract',
		dataIndex: 'btoReference',
		key: 'btoReference',
		render: (_: string, { btoReference, contractState, contractId }: Partial<DataTableType>) => {
			const isOutstanding = contractState?.value === ContractStatusValuesEnum.Outstanding;

			return (
				<Styled.PageLink
					to={`${Routes.ATS}${Routes.ContractsView}/${contractId}`}
					state={{ contractName: btoReference }}
				>
					<span>{btoReference}</span>
					{isOutstanding && <Badge dot />}
				</Styled.PageLink>
			);
		},
	},
	{
		title: 'RELATED INVOICE(S)',
		dataIndex: 'relatedInvoice',
		key: 'relatedInvoice',
		align: 'center',
		render: (_: string, { invoices }: Partial<DataTableType>) => {
			if (!invoices?.length) {
				return <span>-</span>;
			}

			if (invoices.length === 1) {
				const currentInvoice = invoices[0];
				const invoiceId = currentInvoice.id || currentInvoice?.invoiceId;

				return (
					<Link to={`${Routes.ATS}${Routes.InvoicesView}/${invoiceId}`}>
						<Button type='default'>View</Button>
					</Link>
				);
			}

			const popoverContent = (
				<Styled.Popover>
					{invoices.map((currentInvoice) => {
						const { preparedBtoReference } = handleBtoReference(currentInvoice.btoReference);

						return (
							<Styled.PopoverInvoice key={currentInvoice.invoiceId}>
								<Link
									to={`${Routes.ATS}${Routes.InvoicesView}/${
										currentInvoice.invoiceId || currentInvoice.id
									}`}
								>
									<Button type='default'>{preparedBtoReference}</Button>
								</Link>
							</Styled.PopoverInvoice>
						);
					})}
				</Styled.Popover>
			);

			return (
				<Popover content={popoverContent} title='List of related invoices'>
					<Button type='default'>View</Button>
				</Popover>
			);
		},
	},
	{
		title: 'Value',
		dataIndex: 'totalPrice',
		key: 'totalPrice',
		align: 'center',
		render: (_: string, { currency, totalPrice }: Partial<DataTableType>) =>
			`${getCurrencySymbolByName(currency)}${Number(totalPrice)?.toFixed(2)}` ?? 0,
	},
	{
		title: 'Date Sent',
		dataIndex: 'startDate',
		key: 'startDate',
		align: 'center',
		render: (_: string, { startDate }: Partial<DataTableType>) => {
			const formattedData = getDateFormat(startDate) ?? '-';

			return <>{formattedData}</>;
		},
	},
	{
		title: 'Status',
		dataIndex: 'accepted',
		key: 'accepted',
		align: 'center',
		render: (_, { contractState }: Partial<DataTableType>) => {
			const isAccepted = contractState?.value === ContractStatusValuesEnum.Accepted;
			const isFulfilled = contractState?.value === ContractStatusValuesEnum.Fulfilled;

			const color: TagColoursType = !isFulfilled
				? isAccepted
					? TagColoursType.Green
					: TagColoursType.Blue
				: TagColoursType.Orange;
			const statusName: ContractStatusEnum = !isFulfilled
				? isAccepted
					? ContractStatusEnum.ACCEPTED
					: ContractStatusEnum.OUTSTANDING
				: ContractStatusEnum.FULFILLED;

			return <Tag color={color}>{statusName}</Tag>;
		},
	},
];
