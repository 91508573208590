import React from 'react';

import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { Routes } from 'types';

import AppliedJobs from '../../containers/AppliedJobs';

import { Styled } from './ATSAppliedJobsPage.styled';

const ATSAppliedJobsPage = () => {
	return (
		<>
			<Styled.Head>
				<PageNavTitle
					title='Back to all candidates'
					navigationLink={`${Routes.ATS}${Routes.Candidates}`}
				/>
			</Styled.Head>
			<Styled.Content>
				<AppliedJobs />
			</Styled.Content>
		</>
	);
};

export default ATSAppliedJobsPage;
