import React, { FC, useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { getJobCandidatesCsvAts } from 'api/endpoints/jobs';
import Button, { ButtonTypes } from 'components/Button';
import { useUnmount } from 'hooks';
import SearchFilterSortWrapper from 'modules/Common/components/SearchFilterSortWrapper';
import { statusActiveColors } from 'modules/Common/containers/JobsListStatistic/JobsListStatistic.constants';
import {
	CandidateStatusType,
	JobDataType,
	QueryParamsSearchType,
	QueryParamsType,
} from 'modules/Common/types';
import { searchCandidates } from 'modules/Common/utils/commonHelpers';
import { TStyled } from 'theme';
import { downloadCsv } from 'utils/helpers';

import { statusOptions } from '../CandidatesApplication/CandidatesApplication.constants';

import { Styled } from './KanbanCandidatesView.styled';
import KanbanColumn from './KanbanColumn';
import KanbanItem from './KanbanItem';

type KanbanCandidatesViewProps = {
	item: JobDataType;
	updateCandidateStatus: CandidateStatusType;
	handleSelectSpecificCandidate: (id: number) => void;
	saveSearchQueryParams: (params: QueryParamsSearchType | null) => void;
	queryParams: QueryParamsType;
};

interface IKanbanColumnView {
	color: string;
	id: number;
	candidatesAmount: number;
	name: string;
	value: string;
}

type IKanbanColumnViewType = IKanbanColumnView[];

const KanbanCandidatesView: FC<KanbanCandidatesViewProps> = ({
	item,
	queryParams,
	saveSearchQueryParams,
	handleSelectSpecificCandidate,
	updateCandidateStatus,
}) => {
	const [canditatesViewModel, setCandidatesModel] = useState<IKanbanColumnViewType | []>([]);
	const [filteredJobData, setFilteredJobData] = useState<Partial<JobDataType> | null>(item);
	const { searchKey, searchResult } = queryParams?.search || {};

	useEffect(() => {
		const { candidates } = item;
		const preparedValues = statusOptions.map((so, idx) => ({
			...so,
			candidatesAmount: candidates.filter((c) => so.value === c?.candidateState?.value).length,
			color: statusActiveColors[idx] ?? statusActiveColors[0],
		}));

		setCandidatesModel(preparedValues);
	}, [item]);

	useUnmount(() => saveSearchQueryParams && saveSearchQueryParams(null));

	const changeTaskStatus = useCallback(
		(id: string, status: string) => {
			updateCandidateStatus([+id], status, item.id);
		},
		[item],
	);

	const handleDownloadCandidates = downloadCsv(
		() => getJobCandidatesCsvAts(item.id),
		`job-${item.id}-candidates`,
	);

	const handleSearchCandidate = useCallback(
		(key: string) => {
			const filteredJobs = searchCandidates(key, [item]);
			const searchResultLength = key?.length
				? filteredJobs?.reduce((acc, cur) => (acc += cur?.candidates?.length), 0)
				: 0;

			saveSearchQueryParams({ searchKey: key, searchResult: searchResultLength });

			setFilteredJobData(() => filteredJobs[0] || item);
		},
		[item],
	);

	const preparedCandidates =
		item?.candidates?.length && searchKey?.length ? filteredJobData?.candidates : item?.candidates;

	return (
		<Styled.Root>
			<Styled.ActionPanel>
				<SearchFilterSortWrapper
					search={{
						onSearch: (v) => handleSearchCandidate(v),
						defaultValue: '',
						placeholder: 'Search a candidate',
					}}
				/>

				<Button buttonType={ButtonTypes.primary} onClick={handleDownloadCandidates}>
					Download CSV
				</Button>
			</Styled.ActionPanel>
			<>
				{!!searchKey?.length && (
					<TStyled.SearchResultInfo>Search Result {searchResult}</TStyled.SearchResultInfo>
				)}
			</>
			<Styled.KanbanRoot>
				<DndProvider backend={HTML5Backend}>
					{canditatesViewModel.map((tab: IKanbanColumnView) => (
						<KanbanColumn key={tab.id} updateCandidateStatus={changeTaskStatus} status={tab.value}>
							<Styled.CandidatesSection>
								<Styled.CandidatesSectionTitleBlock color={tab.color}>
									<Styled.CandidatesSectionTitle>{tab.name}</Styled.CandidatesSectionTitle>
									<Styled.CandidatesSectionCounter>
										{tab.candidatesAmount}
									</Styled.CandidatesSectionCounter>
								</Styled.CandidatesSectionTitleBlock>
								<Styled.CandidatesList>
									{preparedCandidates
										?.filter((c) => tab.value === c?.candidateState?.value)
										.map((c) => (
											<KanbanItem key={c.id} id={c.id}>
												<Styled.CandidateItem>
													<Styled.CandidateItemHeader>
														<Styled.CandidateItemName
															type='ghost'
															onClick={() => c.id && handleSelectSpecificCandidate(c.id)}
														>
															{c.name}
														</Styled.CandidateItemName>
														{c.isNew && <Styled.CandidateItemBadge>New</Styled.CandidateItemBadge>}
													</Styled.CandidateItemHeader>
												</Styled.CandidateItem>
											</KanbanItem>
										))}
								</Styled.CandidatesList>
							</Styled.CandidatesSection>
						</KanbanColumn>
					))}
				</DndProvider>
			</Styled.KanbanRoot>
		</Styled.Root>
	);
};

export default KanbanCandidatesView;
