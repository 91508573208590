import dayjs from 'dayjs';

import { EmailMessageType } from 'modules/Common/types';

type GroupedMessagesType = {
	[key: string]: Array<{
		id: number;
		content: string;
		creationDate: string;
		attachments: Array<{
			originalFileName: string;
			referenceUuid: string;
		}>;
		candidateApplicationId?: number;
		userId?: number;
		time: string;
		sender?: string;
		senderType: 'user' | 'candidate';
	}>;
};

export const groupMessagesByDate = (
	messages: EmailMessageType[],
	candidateApplication?: { fullName: string },
	user?: { fullName: string },
): GroupedMessagesType => {
	return messages.reduce((acc: GroupedMessagesType, message: EmailMessageType) => {
		const date = dayjs(message.creationDate).format('YYYY-MM-DD');
		const time = dayjs(message.creationDate).format('HH:mm');
		const sender = message.candidateApplicationId ? candidateApplication?.fullName : user?.fullName;
		const senderType = message.candidateApplicationId ? 'candidate' : 'user';

		if (!acc[date]) {
			acc[date] = [];
		}

		acc[date].push({
			...message,
			time,
			sender,
			senderType,
		});

		return acc;
	}, {});
};

export const isEmptyParagraph = (content: string) => {
	return /^<p>\s*<\/p>\n?$/.test(content.trim());
};
