import React, { FC, useEffect, useState } from 'react';

import Spinner from 'components/Spinner';
import { CandidateStatisticsType } from 'modules/Common/types';

import { Styled } from './ApplicantFunnel.styled';

type ApplicantFunnelProps = {
	candidateStatistics: CandidateStatisticsType[];
	loading: boolean;
	yearMonth?: string;
	getCandidateStatistics: (params: {
		periodType: string;
		timeFrameInDays?: number;
		month?: string;
	}) => void;
};

type ApplicantStepType = {
	id: number;
	name: string;
	value: number;
};

const defaultValues = [
	{ id: 1, name: 'New Candidate', value: 0 },
	{ id: 2, name: 'Potential', value: 0 },
	{ id: 3, name: 'Interviews', value: 0 },
	{ id: 4, name: 'Offers', value: 0 },
	{ id: 5, name: 'Hired', value: 0 },
];

const ApplicantFunnel: FC<ApplicantFunnelProps> = ({
	candidateStatistics,
	loading,
	yearMonth,
	getCandidateStatistics,
}) => {
	const [chartData, setChartData] = useState(defaultValues);
	useEffect(() => {
		if (yearMonth) {
			getCandidateStatistics({
				periodType: 'month',
				month: yearMonth,
			});
		} else {
			getCandidateStatistics({
				periodType: 'days',
				timeFrameInDays: 365,
			});
		}
	}, [yearMonth]);

	const sortFunction = (a: CandidateStatisticsType, b: CandidateStatisticsType) =>
		a.state.id - b.state.id;

	useEffect(() => {
		if (candidateStatistics) {
			const sortedStatistics = [...candidateStatistics]
				.sort(sortFunction)
				.filter((item) => item.state.name !== 'Rejected');
			const transformedData = sortedStatistics.map((item) => {
				return {
					id: item.state.id,
					name: item.state.name,
					value: item.numOfCandidates,
				};
			});
			setChartData(transformedData);
		}
	}, [candidateStatistics]);

	if (loading) {
		return (
			<Styled.Root>
				<Spinner />
			</Styled.Root>
		);
	}

	return (
		<Styled.Root>
			<Styled.Title>Applicant Funnel</Styled.Title>
			<Styled.Content>
				<Styled.FunnelStatistic>
					{chartData?.map((status: ApplicantStepType) => {
						return (
							<Styled.FunnelStatisticItemWrap key={status.id} statusId={status.id}>
								<Styled.FunnelStatisticItem statusId={status.id}>
									<h3>{status.value}</h3>
								</Styled.FunnelStatisticItem>
								<h4>{status.name}</h4>
							</Styled.FunnelStatisticItemWrap>
						);
					})}
				</Styled.FunnelStatistic>
			</Styled.Content>
		</Styled.Root>
	);
};

export default ApplicantFunnel;
