import { darken } from 'polished';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

interface IProps {
	isOpen?: boolean;
	backgroundColor?: string;
	isHrModule?: boolean;
}

export namespace S {
	export const UserMenu = styled.button`
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		min-height: 48px;
		min-width: 100px;
		padding: 12px;
		cursor: pointer;
		border-radius: 4px;
		background: none;
		border: none;
		gap: 10px;
		z-index: 2;
	`;

	export const Acronyms = styled.div`
		width: 22px;
		height: 22px;
		min-width: 22px;
		line-height: 22px;
		font-size: 0.7rem;
		font-weight: bold;
		text-align: center;
		border-radius: 50%;
		background-color: ${({ theme }) => theme.colors.darkGray1};
		color: ${({ theme }) => theme.colors.darkBlue};
		text-transform: uppercase;
	`;

	export const Name = styled.span`
		display: block;
		line-height: 24px;
		font-size: 1rem;
		font-weight: 500;
		color: ${({ theme }) => theme.colors.white};
		transition: color ease 0.2s;
		overflow: hidden;
		white-space: nowrap;

		&.active {
			color: ${({ theme }) => theme.colors.lightBlue1};
		}
	`;

	export const ArrowButton = styled.span``;

	export const MenuList = styled.ul<IProps>`
		position: absolute;
		top: 100%;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		margin-top: 4px;
		padding: 0 8px;
		list-style: none;
		border-radius: 4px;
		${({ isOpen, isHrModule, backgroundColor, theme: { colors } }) => `
			display: ${isOpen ? 'flex' : 'none'};
			box-shadow: ${colors.elevationShadow};
			background-color: ${isHrModule ? backgroundColor : colors.darkBlue};
    	`}
	`;

	export const Item = styled.li`
		width: 100%;
		font-size: 1rem;
		font-weight: 400;
		line-height: 50px;
		text-align: center;
		color: ${COLORS.white};
		border-bottom: 1px solid ${COLORS.darkGray2};
		cursor: pointer;

		&:last-child {
			color: ${COLORS.red};
			border: none;

			&:hover {
				color: ${darken(0.2, COLORS.red)};
			}
		}

		&:hover {
			color: ${darken(0.2, COLORS.white)};
		}
		
	`;

	export const Link = styled.a`
		display: block;
	`;

	export const MenuOverlay = styled.div<IProps>`
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
		cursor: initial;
	`;
}
