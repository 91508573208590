import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { message } from 'antd';
import moment from 'moment';

import Spinner from 'components/Spinner';
import { useAudioPermission, useVideoPermission } from 'hooks/useMediaPermission';
import useNetwork from 'hooks/useNetwork';
import QuestionnaireCheck from 'modules/Common/components/QuestionnareSystemCheck';
import { InterviewGuestTypeEnum, MediaPermissionType } from 'modules/Common/types';

import RecordingRequestModal from './RecordingRequestModal';
import { Styled } from './VideoInterview.styled';
import VideoInterviewComplete from './VideoInterviewComplete';
import VideoInterviewProccess from './VideoInterviewProcess/VideoInterviewProcess';

const ERROR_CODES = [400, 401, 403, 404];

const VideoInterview = () => {
	const { search } = useLocation();
	const [currentStep, setStep] = useState(0);
	const [recording, setRecording] = useState<boolean>(false);
	const [recordingRequest, setRecordingRequest] = useState<boolean>(false);

	const queryParams = new URLSearchParams(search);
	const jwt = queryParams.get('jwt');
	const pwd = queryParams.get('pwd');
	const { interviewId } = useParams();

	const [guests, setGuests] = useState(null);
	const [socketConnection, setSocketConnection] = useState(false);
	const [username, setUsername] = useState('');
	const [isInterviewer, setIsInterviewer] = useState(false);
	const [isCandidate, setIsCandidate] = useState(false);
	const [backgroundImage, setBackgroundImage] = useState<string>('');
	const [highlightColor, setHighlightColor] = useState<string>('');
	const [roomName, setRoomName] = useState('');
	const [token, setToken] = useState('');
	const [candidate, setCandidate] = useState(null);
	const [jobTitle, setJobtitle] = useState(null);
	const [meetingHistory, setMeetingHistory] = useState([]);
	const [socket, setSocket] = useState(null);
	const [isNotInterviewTime, setIsNotInterviewTime] = useState<boolean>(false);
	const [interviewErrorMessage, setInterviewErrorMessage] = useState<string>('');
	const [interviewStartTime, setInterviewStartTime] = useState<string>('');
	const [recordingRequestAccepted, setRecordingRequestAccepted] = useState<boolean>(false);

	const handleLeave = useCallback(() => {
		setStep((step) => step + 1);
		if (socket) {
			socket.close();
		}
	}, [socket]);

	useEffect(() => {
		const apiUrl = `${process.env.REACT_APP_API_URL}`;
		const startSliseApiUrlIdx = apiUrl.indexOf('//') + 2;
		const endSliseApiUrlIdx = apiUrl.indexOf('/api');
		const baseUrl = apiUrl.slice(startSliseApiUrlIdx, endSliseApiUrlIdx);

		const tk = jwt ? `jwt=${jwt}` : `pwd=${pwd}`;
		const sk = new WebSocket(`wss://${baseUrl}/websocket/interview/${interviewId}?${tk}`);

		setSocket(sk);

		return () => {
			sk.close();
		};
	}, []);

	useEffect(() => {
		if (socket) {
			socket.onopen = function () {
				setIsNotInterviewTime(false);
			};

			socket.onmessage = function (event) {
				const data = JSON.parse(event?.data);

				if ('action' in data) {
					if (data.action === 'JOIN_INTERVIEW') {
						const currentGuestId = data.body.currentGuestId;
						const currentGuest = data.body.guests.find((g) => g.id === currentGuestId);
						console.info('join interview', data.body);

						setIsInterviewer(currentGuest.type.value === InterviewGuestTypeEnum.Interviewer);
						setIsCandidate(currentGuest.type.value === InterviewGuestTypeEnum.Candidate);
						setBackgroundImage(data.body?.backgroundImage);
						setHighlightColor(data.body?.highlightColour);
						setJobtitle(data?.body?.jobTitle);
						setCandidate(data.body?.guests.find((g) => g.type.value === 'CANDIDATE'));
						setSocketConnection(true);
						setUsername(currentGuest.fullName || currentGuest.email);
						setToken(data.body?.externalInterviewInfo?.roomAccessToken);
						setRoomName(data.body?.externalInterviewInfo?.roomName);
						setRecording(data.body.recordingStarted);
						setGuests(data.body?.guests);

						if (
							currentGuest.type.value === 'CANDIDATE' &&
							data.body.recordInterview &&
							!data.body.recordingStarted
						) {
							setRecordingRequest(true);
						}
					}

					if (data.action === 'HISTORY') {
						setMeetingHistory((ps) => [
							...ps,
							{ message: data?.body?.message, time: moment().format('hh:mm:ss:ms') },
						]);
					}
					if (data.action === 'INTERVIEW_RECORDING') {
						setMeetingHistory((ps) => [
							...ps,
							{ message: 'Recording', time: moment().format('hh:mm:ss:ms') },
						]);
						setRecording(true);
					}
					if (data.action === 'INTERVIEW_RECORDING_REFUSED') {
						setMeetingHistory((ps) => [
							...ps,
							{ message: 'Recording refused', time: moment().format('hh:mm:ss:ms') },
						]);
						setRecording(false);
					}
					if (data.action === 'INTERVIEW_RECORDING_ERROR') {
						setMeetingHistory((ps) => [
							...ps,
							{
								message: 'Your interview will not be recorded or saved ',
								time: moment().format('hh:mm:ss:ms'),
							},
						]);
						setRecording(false);
					}
				} else {
					if (ERROR_CODES.includes(data?.statusCode)) {
						setIsNotInterviewTime(true);
						setInterviewErrorMessage(data.errorMessage);
						data?.interviewTime && setInterviewStartTime(data.interviewTime);
					}
				}
			};

			socket.onclose = function (event, ...all) {
				console.info('connection finished', event, all);
			};

			socket.onerror = function (error) {
				setIsNotInterviewTime(true);

				console.error('Error', error);
			};
		}
	}, [socket]);

	const online: boolean = useNetwork();
	const isVideoAvailable: MediaPermissionType = useVideoPermission();
	const isAudioAvailable: MediaPermissionType = useAudioPermission();
	const notProvidedAllPermissions = !isAudioAvailable.value || !isVideoAvailable.value || !online;

	const onZoomRoomCreated = () => {
		socket.send(
			JSON.stringify({
				action: 'INTERVIEW_RECORDING_ACCEPTED',
			}),
		);
		setRecordingRequestAccepted(false);
	};

	const onRecordingAccept = useCallback(() => {
		setRecordingRequest(false);
		setRecording(true);
		setRecordingRequestAccepted(true);
		message.success('Your interview will be recorded and kept securely on our servers');
	}, [socket]);

	const onRecordingDecline = useCallback(() => {
		socket.send(
			JSON.stringify({
				action: 'INTERVIEW_RECORDING_REFUSED',
				body: {
					reason: '<Error message>',
				},
			}),
		);
		setRecording(false);
		setRecordingRequest(false);
	}, [socket]);

	if (isNotInterviewTime) {
		return (
			<Styled.NotInTimeRoot>
				<Styled.NotInTimeContent>
					{interviewErrorMessage ||
						'You’re joined not in time, please come back later or contact us'}
					{interviewStartTime && (
						<Styled.InterviewStartTime>
							{'Interview will start at: ' +
								moment(interviewStartTime).format('MMM DD, YYYY, hh:mm')}
						</Styled.InterviewStartTime>
					)}
				</Styled.NotInTimeContent>
			</Styled.NotInTimeRoot>
		);
	}

	if (!socketConnection || !token) {
		return <Spinner fullWidth />;
	}

	const meetingDescription = `Video Interview with ${candidate?.fullName} for the ${jobTitle} role`;

	return (
		<Styled.Root>
			<RecordingRequestModal
				open={recordingRequest}
				onAccept={onRecordingAccept}
				onCancel={onRecordingDecline}
			/>
			{notProvidedAllPermissions || currentStep === 0 ? (
				<QuestionnaireCheck
					title='Video Interview'
					backgroundImage={backgroundImage}
					highlightColor={highlightColor}
					description={meetingDescription}
					online={online}
					isVideoAvailable={isVideoAvailable}
					isAudioAvailable={isAudioAvailable}
					handleStart={() => setStep(1)}
					buttonText={'Join Interview'}
				/>
			) : currentStep === 1 ? (
				<VideoInterviewProccess
					guests={guests}
					recording={recording}
					onZoomRoomCreated={onZoomRoomCreated}
					recordingRequestAccepted={recordingRequestAccepted}
					meetingDescription={meetingDescription}
					token={token}
					username={username}
					isCandidate={isCandidate}
					isInterviewer={isInterviewer}
					roomName={roomName}
					handleLeave={handleLeave}
					meetingHistory={meetingHistory}
				/>
			) : (
				<VideoInterviewComplete />
			)}
		</Styled.Root>
	);
};

export default VideoInterview;
