import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function Icon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4 4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4H4ZM19.4496 7.67625C19.7789 7.42493 19.8421 6.95428 19.5907 6.62502C19.3394 6.29576 18.8688 6.23258 18.5395 6.4839L12.0509 11.4366L5.74069 6.49029C5.41469 6.23475 4.94327 6.29187 4.68773 6.61787C4.4322 6.94387 4.48932 7.41529 4.81532 7.67083L11.5821 12.975C11.851 13.1858 12.2283 13.1882 12.4999 12.9809L19.4496 7.67625Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(Icon);
