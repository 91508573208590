import React, { type FC } from 'react';

import Table from 'components/Table';
import { DEFAULT_CURRENT_PAGE } from 'modules/Common/constants/table';
import { JobDataType } from 'modules/Common/types';
import { propsFilter } from 'utils/helpers';

import { JobsListFields } from './JobsListTable.constants';
import { columns } from './JobsListTable.entities';

type JobsProps = {
	jobsData: {
		data: JobDataType[];
		pageIndex: number;
		pageSize: number;
		totalCount: number;
	};
	handleCopyJob: (id: number) => void;
	handleTableChange: (page: number, size: number) => void;
	loading: boolean;
};

const JobsListTable: FC<JobsProps> = ({ jobsData, handleCopyJob, handleTableChange, loading }) => {
	const { pageIndex = DEFAULT_CURRENT_PAGE, pageSize, totalCount, data } = jobsData;
	const activeData = propsFilter(data, JobsListFields);

	return (
		<Table
			data={activeData}
			columns={columns(handleCopyJob)}
			loading={loading}
			pageSize={pageSize}
			current={pageIndex}
			total={totalCount}
			onChange={handleTableChange}
		/>
	);
};

export default JobsListTable;
