import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';

import Container from 'components/Container';
import { Direction } from 'components/Container/Container.types';
import SettingsPanelMenu from 'modules/Common/components/SettingsPanelMenu';
import { AtsProfileMenuList } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { UserRolesType } from 'types';

import { SettingsFullAccessList, SettingsShortAccessList } from './BackofficeSettingsPage.entities';
import { Styled } from './BackofficeSettingsPage.styled';

type BackofficeSettingsPageProps = {
	roles: UserRolesType;
};

const BackofficeSettingsPage: FC<BackofficeSettingsPageProps> = ({ roles }) => {
	const { isSuperUser } = roles || {};

	const filteredMenuItems = isSuperUser ? SettingsFullAccessList : SettingsShortAccessList;

	return (
		<Styled.Root>
			<Styled.PageTitle>{AtsProfileMenuList.Settings}</Styled.PageTitle>
			<Container direction={Direction.Row} noSpaces fullWidth>
				<SettingsPanelMenu items={filteredMenuItems} />
				<Outlet />
			</Container>
		</Styled.Root>
	);
};

export default connect((state) => ({
	roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
}))(BackofficeSettingsPage);
