import React, { FC, useRef } from 'react';
import { Bar } from 'react-chartjs-2';

import moment from 'moment';
import { darken } from 'polished';

import { ShowMoreWrapperWrapper } from 'components/ShowMoreWrapper/ShowMoreWrapper';
import {
	IApplicationYear,
	StatisticPeriodEnum,
	JobApplicatonsPerMonth,
} from 'modules/Common/types';
import { getHtmlLegendPlugin } from 'modules/Common/utils/chartHelpers';
import { STATISTICS_COLORS } from 'modules/Common/utils/StatisticsUtils';

import { yearOptions } from './ApplicationChart.options';
import { Styled } from './ApplicationChart.styled';

type ApplicationChartYearProps = {
	setCurrentPeriodType: (period: StatisticPeriodEnum) => null;
	setCurrentPeriod: (period: string) => null;
	applicatonsYear: IApplicationYear;
};

const getAverageApplicationsPerMonth = (
	applicatonsYear: IApplicationYear,
	hiddenJobs: JobApplicatonsPerMonth[],
) =>
	applicatonsYear.months.map((month) => {
		const filteredJobs = month.jobs.filter((j) => !hiddenJobs.some((hj) => hj.id === j.id));

		return filteredJobs.reduce((acc, j, idx) => {
			const filteredMonthJobsLength = filteredJobs.length;
			if (!filteredMonthJobsLength) {
				return acc;
			}

			if (filteredMonthJobsLength === idx + 1) {
				return (acc + j.count) / filteredMonthJobsLength;
			}

			return acc + j.count;
		}, 0);
	});

const getTrendLinePlugin = (data: IApplicationYear) => ({
	id: 'trendLine',
	afterDatasetsDraw(chart) {
		const hiddenDatasets = chart.data.datasets.filter(
			(dataset, datasetIndex) => chart.getDatasetMeta(datasetIndex).hidden,
		);

		const averageApplicationsPerMonth = getAverageApplicationsPerMonth(data, hiddenDatasets);

		const {
			ctx,
			chartArea: { left, right },
			scales: { x, y },
		} = chart;

		ctx.save();
		ctx.beginPath();
		ctx.lineWidth = 1;
		ctx.strokeStyle = '#656E8D';
		ctx.setLineDash([5, 5]);
		ctx.moveTo(left, y.getPixelForValue(averageApplicationsPerMonth[0]));

		averageApplicationsPerMonth.forEach((dataPoint, index) => {
			ctx.lineTo(x.getPixelForValue(index), y.getPixelForValue(dataPoint));
		});

		ctx.lineTo(
			right,
			y.getPixelForValue(averageApplicationsPerMonth[averageApplicationsPerMonth.length - 1]),
		);

		ctx.stroke();
	},
});

type AllYearJobsType = {
	title: string;
	id: number;
	color: string;
	archivingDate?: string;
};

interface AllYearJobs {
	[key: string]: AllYearJobsType;
}

const ApplicationChartYear: FC<ApplicationChartYearProps> = ({
	applicatonsYear,
	setCurrentPeriod,
	setCurrentPeriodType,
}) => {
	const chartRef = useRef();
	const legendRef = useRef();
	const allYearJobs = applicatonsYear.months.reduce<AllYearJobs>((acc, month, idx) => {
		let currentColorIdx = 0;
		const getJobColor = (colorId: number): string =>
			STATISTICS_COLORS[colorId] ??
			darken(0.1, STATISTICS_COLORS[colorId % STATISTICS_COLORS.length]);

		month.jobs.forEach((mj) => {
			if (!acc[mj.id]) {
				acc[mj.id] = {
					id: mj.id,
					title: mj.title,
					archivingDate: mj.archivingDate,
					color: getJobColor(currentColorIdx),
				};
				currentColorIdx += 1;
			}
		});

		return acc;
	}, {});

	const trendLinePlugin = getTrendLinePlugin(applicatonsYear);

	const datasets = Object.values(allYearJobs).map((ayj) => ({
		label: ayj.title,
		borderColor: ayj.color,
		backgroundColor: ayj.color,
		borderRadius: 4,
		skipNull: true,
		id: ayj.id,
		data: applicatonsYear.months.map((month) => {
			const currentMonthJobApplies = month.jobs.find((j) => j.id === ayj.id);
			if (currentMonthJobApplies) {
				return currentMonthJobApplies.count;
			}

			return null;
		}),
	}));

	const data = {
		labels: applicatonsYear.months.map((m) => moment(`${m.date}-01`).format('MMM')),
		datasets,
	};

	const htmlLegendPlugin = getHtmlLegendPlugin('yearApplicationLegend');

	const onChartNativeClick = (event) => {
		const { x, chart } = event;
		const { width } = chart.chartArea;

		const clickedPeriod = Math.floor((x / width) * 12);
		const defaultMonths = applicatonsYear.months.map((m) => m.date);
		const newPeriod = defaultMonths[clickedPeriod];

		setCurrentPeriod(newPeriod);
		setCurrentPeriodType(StatisticPeriodEnum.Month);
	};

	const preparedYearOptions = yearOptions(onChartNativeClick);

	return (
		<Styled.Root>
			<Styled.Header>
				<Styled.Title>Applications</Styled.Title>
			</Styled.Header>
			<Styled.ChartWrapper>
				<Bar
					ref={chartRef}
					options={preparedYearOptions}
					data={data}
					plugins={[htmlLegendPlugin, trendLinePlugin]}
				/>
			</Styled.ChartWrapper>
			<Styled.LegendWrapper>
				<ShowMoreWrapperWrapper maxHeight={100}>
					<div ref={legendRef} id='application-chart-container-2' />
				</ShowMoreWrapperWrapper>
			</Styled.LegendWrapper>
		</Styled.Root>
	);
};

export default ApplicationChartYear;
