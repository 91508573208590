import React, { useRef } from 'react';

import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';
import ClientChangesForm from 'modules/BackOffice/containers/ClientChangesForm';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { Routes } from 'types';

import { Styled } from './CreateClient.styled';

const CreateClientPage = () => {
	const anchorRef = useRef<HTMLDivElement>(null);
	useScrollToTopOnMount(anchorRef);

	return (
		<Styled.Root ref={anchorRef}>
			<PageNavTitle
				title='Create a New Client'
				navigationLink={`${Routes.BackOffice}${Routes.Clients}`}
			/>
			<ClientChangesForm />
		</Styled.Root>
	);
};

export default CreateClientPage;
