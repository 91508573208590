import { isEmpty } from 'lodash';

import { get, del, post, put } from 'api/axiosInstance';
import { RequestsEnum } from 'types';
import { qs } from 'utils/helpers';

export const declineInterview = async (id, params) => {
	const queryParams = !isEmpty(params) ? qs(params) : '';

	return post(`${RequestsEnum.DeclineInterviewOpen}/${id}${queryParams}`);
};

export const deleteAtsInterviewById = async (id) => del(`${RequestsEnum.AtsInterview}/${id}`);

export const getAtsInterviewType = async () => get(RequestsEnum.AtsInterviewType);

export const getAtsUserInterview = async () => get(RequestsEnum.AtsUserInterview);

export const getAtsInterviewJobsList = async (params) => {
	const queryParams = qs(params);

	return get(`${RequestsEnum.AtsInterviewList}${queryParams}`);
};

export const getAtsEditInterviewJobsList = async (candidateId) =>
	get(`${RequestsEnum.AtsEditInterviewList}${candidateId}`);

export const getAtsInterviewById = async (id) => get(`${RequestsEnum.AtsInterview}/${id}`);

export const getAtsInterviewRecordingById = async (id) =>
	get(`${RequestsEnum.AtsInterview}/recording/${id}`, { 'X-Hide-Alert-Error': true });

export const getOpenInterview = async (id, params) => {
	const queryParams = !isEmpty(params) ? qs(params) : '';

	return get(`${RequestsEnum.InterviewOpen}/${id}${queryParams}`);
};

export const getAtsInterviewCalendar = async (params) => {
	const queryParams = !isEmpty(params) ? qs(params) : '';

	return get(`${RequestsEnum.AtsInterviewCalendar}${queryParams}`);
};

export const saveAtsInterview = async (data) => post(RequestsEnum.AtsInterview, data);

export const updateAtsInterview = async (data) => put(RequestsEnum.AtsInterview, data);

export const resendAtsInterviewLink = async (id) =>
	post(`${RequestsEnum.AtsInterviewResend}/${id}`);

export const getClientContextInterviewList = async (params) => {
	const queryParams = qs(params);

	return get(`${RequestsEnum.BOClientContextInterview}${queryParams}`);
};

const interviewService = {
	declineInterview,
	deleteAtsInterviewById,
	getAtsInterviewType,
	getAtsUserInterview,
	getAtsInterviewJobsList,
	getAtsEditInterviewJobsList,
	getAtsInterviewById,
	getAtsInterviewRecordingById,
	getAtsInterviewCalendar,
	getOpenInterview,
	saveAtsInterview,
	updateAtsInterview,
	resendAtsInterviewLink,
	getClientContextInterviewList,
};

export default interviewService;
