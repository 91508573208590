import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function ArrowUp({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g id='State=Chevron Up'>
				<path
					id='icon'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M3.03898 16.8577C2.6094 16.4282 2.6094 15.7317 3.03898 15.3021L11.203 7.13807C11.6319 6.70921 12.327 6.7084 12.7569 7.13627L20.959 15.3003C21.3896 15.7289 21.3912 16.4254 20.9627 16.8559C20.5341 17.2865 19.8376 17.2881 19.407 16.8596L11.9827 9.46972L4.59461 16.8577C4.16504 17.2873 3.46855 17.2873 3.03898 16.8577Z'
					fill={fill}
				/>
			</g>
		</svg>
	);
}

export default memo(ArrowUp);
