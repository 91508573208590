import styled from 'styled-components/macro';


interface IStyledProps {
    animated: boolean;
}

export namespace Styled {
	export const Root = styled.section<IStyledProps>`

        svg {
            path {
                stroke-dasharray: 100;
                stroke-dashoffset: 100;
                animation: dash 5s linear infinite;
            }

            @keyframes dash {
                to {
                    stroke-dasharray: 30;
                }
            }
        }
	`;
}
