import React from 'react';

import { RowProps } from '../ClientContextContainer.types';

import { Styled } from './TableRow.styled';

type TableRowProps = RowProps & {
	selectedList: number[];
};

export const TableRow = ({ onClick, selectedList, children, ...props }: TableRowProps) => {
	const id = children?.[0]?.props?.record?.id;
	const selectedItems = selectedList?.map((i) => i.id);
	const isDisabled = selectedItems?.includes(id);

	const handleRowClick = () => {
		const client = {
			id,
			clientName: children?.[0]?.props?.record?.clientName,
		};
		!isDisabled && onClick && onClick(client);
	};

	return (
		<Styled.Row {...props} isDisabled={isDisabled} onClick={handleRowClick}>
			{children}
		</Styled.Row>
	);
};
