import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.main`
		position: relative;
		height: 400px;
		max-width: 100%;
		overflow: hidden;
	`;
	export const ContainerControls = styled.div`
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 4;
	`;
	export const AddressInfo = styled.main`
		position: absolute;
		bottom: 0;
		left: 56px;
		padding: 24px 32px 40px;
		background: rgba(255, 255, 255, 0.4);
		border: 1px solid rgba(255, 255, 255, 0.3);
		backdrop-filter: blur(5px);
		border-radius: 12px 12px 0px 0px;
	`;
	export const AddressInfoTitle = styled.h3`
		font-family: 'Raleway';
		font-weight: 600;
		font-size: 18px;
		line-height: 20px;
		color: ${COLORS.black};
	`;
	export const AddressInfoSection = styled.div`
		padding-top: 16px;
		display: flex;
	`;
	export const AddressInfoSectionIcon = styled.div`
		margin-right: 8px;
	`;
	export const AddressInfoSectionValue = styled.div`
		font-family: 'Raleway';
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: ${COLORS.black};
	`;
	export const GoogleMapInner = styled.div`
		width: 1440px;
		height: 400px;
	`;
}
