import React, { type FC, useEffect } from 'react';

import Table from 'components/Table';
import { propsFilter } from 'utils/helpers';

import { CandidatesApplicationList } from './CandidatesApplicationContainer.constants';
import { columns } from './CandidatesApplicationContainer.entities';
import { CandidateApplicationContainerProps } from './CandidatesApplicationContainer.types';

export const CandidatesApplicationContainer: FC<CandidateApplicationContainerProps> = ({
	candidatesData,
	getBackOfficeCandidates,
	deleteBackofficeCandidate,
	pageLink,
	clientId,
	loading,
}) => {
	const { data, pageSize, pageIndex, totalCount } = candidatesData || {};

	const filteredData = propsFilter(data, CandidatesApplicationList);
	const columnsData = columns(pageLink, deleteBackofficeCandidate);

	const handleTableChange = (page: number, size: number) => {
		getBackOfficeCandidates({ page, size });
	};

	useEffect(() => {
		getBackOfficeCandidates({ size: pageSize, page: pageIndex });
	}, [clientId]);

	return (
		<>
			<Table
				data={filteredData}
				columns={columnsData}
				pageSize={pageSize}
				current={pageIndex}
				total={totalCount}
				onChange={handleTableChange}
				onPageSizeChange={handleTableChange}
				loading={loading?.getBackofficeCandidatesLoad}
			/>
		</>
	);
};
