import styled from 'styled-components/macro';

import { COLORS } from 'theme';

interface RootProps {
	isBorder: boolean;
}

export namespace Styled {
	export const Root = styled.section<RootProps>`
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		min-height: 100px;
		${({ isBorder }) => isBorder && `border: 1px solid ${COLORS.lightGray1}`};

		.pdf-canvas-document {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;

			.pdf-canvas {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
			}
		}
	`;

	export const Navigation = styled.nav`
		display: flex;
		align-items: center;
		width: 100%;
		padding: 16px;
		gap: 16px;

		p {
			margin: 0;
		}
	`;
}
