import { Link } from 'react-router-dom';

import { darken, lighten } from 'polished';
import styled from 'styled-components/macro';

import { COLORS, device } from 'theme';

import { ButtonTypes, IButton } from './Button.types';

type ButtonType = keyof typeof ButtonTypes;

const buttonColors = {
	primary: COLORS.white,
	secondary: COLORS.blue,
	tertiary: COLORS.blue,
	default: COLORS.black,
	danger: COLORS.white,
};

const buttonBgColors = {
	primary: COLORS.blue,
	secondary: COLORS.white,
	tertiary: COLORS.white,
	default: COLORS.white,
	danger: COLORS.red,
};

const buttonBgHoverColors = {
	primary: COLORS.blue1,
	secondary: COLORS.lightBlue,
	tertiary: COLORS.lightBlue,
	default: COLORS.lightBlue,
	danger: COLORS.lightRed,
};

const buttonBgFocusColors = {
	primary: COLORS.blue,
	secondary: COLORS.lightBlue1,
	tertiary: COLORS.lightBlue1,
	default: COLORS.lightBlue1,
	danger: COLORS.lightRed,
};

const buttonBorderColors = {
	primary: 'none',
	secondary: `1px solid ${COLORS.blue}`,
	tertiary: 'none',
	default: `1px solid ${COLORS.lightGray1}`,
	danger: 'none',
};

export const ButtonLink = styled(Link)`
	display: block;
	line-height: 28px;
	height: 28px;
	max-width: 90px;
	margin: 0 auto;
	font-weight: 500;
	border: 1px solid ${COLORS.lightGray1};
	border-radius: 4px;
	color: ${COLORS.black};
	transition: background-color 0.2s ease;
	gap: 4px;

	&:hover,
	&:focus,
	&:active {
		color: ${COLORS.black};
		background-color: ${COLORS.lightGray2};
	}
`;

export namespace S {
	export const Button = styled.button<IButton>`
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
		${({ buttonType, isFullWidth, onlyIcon }) => `
			width: ${isFullWidth ? '100%' : 'auto'};
			height: ${onlyIcon ? '24px' : '40px'};
			min-width: ${onlyIcon ? '24px' : '99px'};
			max-width: ${isFullWidth ? '100%' : 'none'};
			padding: ${onlyIcon ? '0' : '0 24px'};
			line-height: ${onlyIcon ? '24px' : '40px'};
			background: ${buttonBgColors[buttonType as ButtonType] || 'none'};
			color: ${buttonColors[buttonType as ButtonType]};
			border: ${buttonBorderColors[buttonType as ButtonType] || 'none'};
		`}
		font-weight: 500;
		font-size: 16px;
		border-radius: 4px;
		overflow: hidden;
		transition: background-color ease 0.2s;
		user-select: none;
		cursor: pointer;

		&:hover,
		&:active {
			background: ${({ buttonType }) => buttonBgHoverColors[buttonType as ButtonType] || 'none'};
		}

		// TODO implement animation on pressing the button and correct focus
		// &:focus {
		// 	background: ${({ buttonType }) => buttonBgFocusColors[buttonType as ButtonType] || 'none'};
		// }

		span {
			margin-right: 10px;
		}

		&:disabled {
			${({ buttonType }) => `
				background: ${buttonType === ButtonTypes.primary ? COLORS.lightGray2 : COLORS.white};
				color: ${COLORS.darkGray1};
				box-shadow: none;
				cursor: initial;
    	`}
			&:hover {
				${({ buttonType }) => `
					background: ${buttonType === ButtonTypes.primary ? COLORS.lightGray2 : COLORS.white};
    			`}
				cursor: not-allowed;
			}

			svg, path {
				color: ${COLORS.gray};
				fill: ${COLORS.gray};
			}
		}

		.ant-spin-dot {
			margin: 0;
		}

		${({ buttonColor, buttonType }) =>
		buttonColor &&
			buttonType === ButtonTypes.primary &&
			`				
			background: ${buttonColor ? buttonColor : COLORS.blue};
			:hover {
				background: ${darken(0.1, buttonColor)};
			}
			:active {
				background: ${darken(0.1, buttonColor)};
			}
    	`}

		${({ buttonColor, buttonType }) =>
				buttonColor &&
			buttonType === ButtonTypes.secondary &&
			`				
			color: ${buttonColor};
			border: 1px solid ${buttonColor};
			:hover {
				background: ${lighten(0.3, buttonColor)};
			}
    	`}

		@media ${device.tablet} {
			font-size: 14px;
		}
	`;
}
