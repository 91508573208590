import {
	JobsStatisticType,
	CandidateStatisticsType,
	InterviewListType,
	DashboardUnreadEmailsType,
} from 'modules/Common/types';

export const jobMonthStatistic:JobsStatisticType = {
	'jobs': [
		{
			'id': 16,
			'title': 'Sound Designer',
			'archivingDate': '2024-04-10T13:02:03Z',
			'applicationsData': [
				{
					'date': '2024-03-27',
					'count': 4
				},
				{
					'date': '2024-04-01',
					'count': 6
				},
				{
					'date': '2024-04-08',
					'count': 9
				}
			]
		},
		{
			'id': 49,
			'title': 'Recording Studio Technician',
			'archivingDate': '2024-04-21T13:02:03Z',
			'applicationsData': [
				{
					'date': '2024-03-27',
					'count': 0
				},
				{
					'date': '2024-03-29',
					'count': 1
				},
				{
					'date': '2024-04-02',
					'count': 3
				},
				{
					'date': '2024-04-09',
					'count': 7
				},
				{
					'date': '2024-04-17',
					'count': 9
				},
			]
		},
		{
			'id': 84,
			'title': 'Recording Engineer',
			'archivingDate': null,
			'applicationsData': [
				{
					'date': '2024-03-27',
					'count': 2
				},
				{
					'date': '2024-04-01',
					'count': 3
				},
				{
					'date': '2024-04-02',
					'count': 4
				},
				{
					'date': '2024-04-03',
					'count': 5
				},
				{
					'date': '2024-04-04',
					'count': 7
				},
				{
					'date': '2024-04-08',
					'count': 8
				},
			]
		},
		{
			'id': 235,
			'title': 'Accountant',
			'archivingDate': null,
			'applicationsData': [
				{
					'date': '2024-03-27',
					'count': 1
				},
				{
					'date': '2024-04-01',
					'count': 2
				},
				{
					'date': '2024-04-07',
					'count': 3
				},
				{
					'date': '2024-04-14',
					'count': 4
				},
				{
					'date': '2024-04-20',
					'count': 5
				},
				{
					'date': '2024-04-21',
					'count': 6
				}
			]
		},
		{
			'id': 527,
			'title': 'Recruiter',
			'archivingDate': null,
			'applicationsData': [
				{
					'date': '2024-04-01',
					'count': 0
				},
				{
					'date': '2024-04-02',
					'count': 3
				},
				{
					'date': '2024-04-03',
					'count': 4
				},
				{
					'date': '2024-04-04',
					'count': 5
				},
				{
					'date': '2024-04-20',
					'count': 7
				}
			]
		},
		{
			'id': 510,
			'title': 'Producer',
			'archivingDate': null,
			'applicationsData': [
				{
					'date': '2024-04-10',
					'count': 0
				},
				{
					'date': '2024-04-12',
					'count': 1
				},
				{
					'date': '2024-04-15',
					'count': 5
				},
				{
					'date': '2024-04-15',
					'count': 9
				}, 
			]
		},
	]
};

export const candidateStatistics:CandidateStatisticsType[] = [
	{
		'state': {
			'id': 3,
			'name': 'Interviews',
			'value': 'INTERVIEWS'
		},
		'numOfCandidates': 3
	},
	{
		'state': {
			'id': 1,
			'name': 'New Candidate',
			'value': 'NEW'
		},
		'numOfCandidates': 14
	},
	{
		'state': {
			'id': 4,
			'name': 'Offers',
			'value': 'OFFERS'
		},
		'numOfCandidates': 2
	},
	{
		'state': {
			'id': 2,
			'name': 'Potential',
			'value': 'POTENTIAL'
		},
		'numOfCandidates': 3
	},
	{
		'state': {
			'id': 6,
			'name': 'Rejected',
			'value': 'REJECTED'
		},
		'numOfCandidates': 4
	},
	{
		'state': {
			'id': 5,
			'name': 'Hired',
			'value': 'HIRED'
		},
		'numOfCandidates': 9
	}
];

export const interviewList:InterviewListType[] = [
	{
		'id': 380,
		'candidateApplicationId': 81,
		'type': {
			'id': 1,
			'name': 'Video/Online',
			'value': 'VIDEO_ONLINE'
		},
		'startDate': '2024-04-26T10:01:00Z',
		'endDate': '2024-04-26T11:01:00Z',
		'jobTitle': 'Sound Engineer',
		'jobId': 292,
		'guests': [
			{
				'id': 1107,
				'email': 'sdfdsfsd@dsfsdfsdf.com',
				'fullName': 'Vikram Shein',
				'userId': null,
				'isInterviewAccepted': true,
				'type': {
					'id': 1,
					'name': 'Candidate',
					'value': 'CANDIDATE'
				}
			},
			{
				'id': 1108,
				'email': 'pavlo.kadysh+atssu@dataart.com',
				'fullName': 'Pavlo Kadysh',
				'userId': 35,
				'isInterviewAccepted': true,
				'type': {
					'id': 2,
					'name': 'Interviewer',
					'value': 'INTERVIEWER'
				}
			}
		]
	},
	{
		'id': 379,
		'candidateApplicationId': 81,
		'type': {
			'id': 1,
			'name': 'Video/Online',
			'value': 'VIDEO_ONLINE'
		},
		'startDate': '2024-04-26T11:00:00Z',
		'endDate': '2024-04-26T12:00:00Z',
		'jobTitle': 'Recording Studio Technician',
		'jobId': 292,
		'guests': [
			{
				'id': 1106,
				'email': 'pavlo.kadysh+atssu@dataart.com',
				'fullName': 'Pavlo Kadysh',
				'userId': 35,
				'isInterviewAccepted': true,
				'type': {
					'id': 2,
					'name': 'Interviewer',
					'value': 'INTERVIEWER'
				}
			},
			{
				'id': 1105,
				'email': 'sdfdsfsd@dsfsdfsdf.com',
				'fullName': 'Alex Williams',
				'userId': null,
				'isInterviewAccepted': true,
				'type': {
					'id': 1,
					'name': 'Candidate',
					'value': 'CANDIDATE'
				}
			}
		]
	},
	{
		'id': 292,
		'candidateApplicationId': 81,
		'type': {
			'id': 1,
			'name': 'Video/Online',
			'value': 'VIDEO_ONLINE'
		},
		'startDate': '2024-04-27T09:20:00Z',
		'endDate': '2024-04-27T10:20:00Z',
		'jobTitle': 'Sound Designer',
		'jobId': 292,
		'guests': [
			{
				'id': 848,
				'email': 'sdfdsfsd@dsfsdfsdf.com',
				'fullName': 'Olivia Keith',
				'userId': 32,
				'isInterviewAccepted': true,
				'type': {
					'id': 1,
					'name': 'Candidate',
					'value': 'CANDIDATE'
				}
			},
			{
				'id': 849,
				'email': 'tatsadmin@dataart.com',
				'fullName': 'Tatiana Bazanova',
				'userId': 21,
				'isInterviewAccepted': true,
				'type': {
					'id': 2,
					'name': 'Interviewer',
					'value': 'INTERVIEWER'
				}
			}
		]
	},
	{
		'id': 206,
		'candidateApplicationId': 2,
		'type': {
			'id': 1,
			'name': 'Video/Online',
			'value': 'VIDEO_ONLINE'
		},
		'startDate': '2024-05-01T12:30:00Z',
		'endDate': '2024-05-01T13:30:00Z',
		'jobTitle': 'Accountant',
		'jobId': 17,
		'guests': [
			{
				'id': 559,
				'email': 'tatsadmin@dataart.com',
				'fullName': 'Tatiana Bazanova',
				'userId': 21,
				'isInterviewAccepted': true,
				'type': {
					'id': 2,
					'name': 'Interviewer',
					'value': 'INTERVIEWER'
				}
			},
			{
				'id': 558,
				'email': 'mariaantoaneta@gmail.com',
				'fullName': 'Erica Lima',
				'userId': 21,
				'isInterviewAccepted': true,
				'type': {
					'id': 1,
					'name': 'Candidate',
					'value': 'CANDIDATE'
				}
			}
		]
	}
];

export const emailList:DashboardUnreadEmailsType = {
	numberOfUnreadEmailCount: 4,
	data: [
		{
			id: 1,
			candidateApplication: {
				id: 62,
				fullName: 'Michael Scott',
			},
			topic: 'Dont hire Pam please',
			date: '2024-04-27T09:20:00Z',
		},
		{
			id: 2,
			candidateApplication: {
				id: 67,
				fullName: 'Pam Beesly',
			},
			topic: 'New position designer',
			date: '2024-04-26T09:20:00Z',
		},
		{
			id: 3,
			candidateApplication: {
				id: 5,
				fullName: 'Stanley Hudson',
			},
			topic: 'How much does this cost...',
			date: '2024-04-26T09:20:00Z',
		},
	],
};