import React, { useCallback, type FC } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Box from 'components/Box';
import { ButtonTypes } from 'components/Button/Button.types';
import { useMount } from 'hooks';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import AtsSupportTickets from 'modules/ATS/containers/AtsSupportTickets';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from 'modules/Common/constants/table';
import { Routes, GenericType } from 'types';

import { atsDucks } from '../../ducks';

import { Styled } from './AtsTickets.styled';

type TicketsProps = {
	ticketsData: {
		data: object[];
		pageIndex: number;
		pageSize: number;
		totalCount: number;
	};
	loading?: GenericType;
	getTicketsRequested: (params: {active: boolean, page: number, size: number }) => void;
};

const Clients: FC<TicketsProps> = ({ ticketsData, loading, getTicketsRequested }) => {
	const navigate = useNavigate();

	useMount(() => {
		getTicketsRequested({active: true, page: DEFAULT_CURRENT_PAGE, size: DEFAULT_PAGE_SIZE});
	});

	const handlePaginationChange = useCallback((page: number, size: number) => {
		getTicketsRequested({active: true, page, size});
	}, []);

	return (
		<Styled.Root>
			<Styled.Head>
				<Styled.Title>Support Tickets</Styled.Title>
				<ATSThemedButton
					buttonType={ButtonTypes.primary}
					onClick={() => navigate(`${Routes.ATS}${Routes.SupportCenterTicketCreate}`)}
				>
					Create New
				</ATSThemedButton>
			</Styled.Head>

			<Box>
				<AtsSupportTickets loading={loading} tickets={ticketsData} handlePaginationChange={handlePaginationChange} />
			</Box>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		ticketsData: atsDucks.atsSelectors.getAtsTicketsState(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getTicketsRequested: atsDucks.atsActions.geAtsTicketsRequested,
	},
)(Clients);
