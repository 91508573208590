import { memo } from 'react';

import { IconType } from '../SVG.types';

function MeetingCompleteIcon({ width = '200', height = '146' }: IconType) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			fill='none'
			viewBox='0 0 200 146'
		>
			<g clipPath='url(#clip0_185_2137)'>
				<path
					fill='#F0F4FF'
					fillRule='evenodd'
					d='M63.412 32.75c0 5.523 4.477 10 10 10h45c5.523 0 10-4.477 10-10v-21c0-5.523-4.477-10-10-10h-45c-5.523 0-10 4.477-10 10v21zm12-5a6 6 0 006 6h29a6 6 0 006-6v-11a6 6 0 00-6-6h-29a6 6 0 00-6 6v11z'
					clipRule='evenodd'
				></path>
				<path
					fill='#C8D6FF'
					fillRule='evenodd'
					d='M118.412 3.25h-45a8.5 8.5 0 00-8.5 8.5v21a8.5 8.5 0 008.5 8.5h45a8.5 8.5 0 008.5-8.5v-21a8.5 8.5 0 00-8.5-8.5zm-45-3h45c6.351 0 11.5 5.149 11.5 11.5v21c0 6.351-5.149 11.5-11.5 11.5h-45c-6.351 0-11.5-5.149-11.5-11.5v-21c0-6.351 5.149-11.5 11.5-11.5zm37 12h-29a4.5 4.5 0 00-4.5 4.5v11a4.5 4.5 0 004.5 4.5h29a4.5 4.5 0 004.5-4.5v-11a4.5 4.5 0 00-4.5-4.5zm-29-3h29a7.5 7.5 0 017.5 7.5v11a7.5 7.5 0 01-7.5 7.5h-29a7.5 7.5 0 01-7.5-7.5v-11a7.5 7.5 0 017.5-7.5z'
					clipRule='evenodd'
				></path>
				<path
					fill='#F0F4FF'
					d='M24.412 145.75c-5.523 0-10-4.477-10-10v-103c0-5.523 4.477-10 10-10h143c5.523 0 10 4.477 10 10v103c0 5.523-4.477 10-10 10h-143z'
				></path>
				<path
					fill='#C8D6FF'
					fillRule='evenodd'
					d='M17.412 32.75v103a7 7 0 007 7h143a7 7 0 007-7v-103a7 7 0 00-7-7h-143a7 7 0 00-7 7zm-3 103c0 5.523 4.477 10 10 10h143c5.523 0 10-4.477 10-10v-103c0-5.523-4.477-10-10-10h-143c-5.523 0-10 4.477-10 10v103z'
					clipRule='evenodd'
				></path>
				<circle cx='65.412' cy='45.75' r='8' fill='#7D9CFC'></circle>
				<circle cx='67.412' cy='41.75' r='2' fill='#F0F4FF'></circle>
				<circle cx='70.412' cy='43.75' r='1' fill='#F0F4FF'></circle>
				<circle cx='128.412' cy='45.75' r='8' fill='#7D9CFC'></circle>
				<circle cx='130.412' cy='41.75' r='2' fill='#F0F4FF'></circle>
				<circle cx='133.412' cy='43.75' r='1' fill='#F0F4FF'></circle>
				<path
					fill='#7D9CFC'
					fillRule='evenodd'
					d='M81.343 56.574l.023.017.133.095c.128.088.336.223.625.39.579.335 1.487.799 2.749 1.268 2.52.938 6.467 1.906 12.04 1.906 5.571 0 9.519-.968 12.039-1.906 1.261-.47 2.169-.933 2.748-1.267a9.416 9.416 0 00.759-.486l.022-.017-.002.001-.002.002.005-.003a1.5 1.5 0 011.867 2.347l-.937-1.17c.937 1.17.937 1.17.936 1.171l-.002.002-.003.002-.008.006-.021.017a5.17 5.17 0 01-.29.21c-.19.13-.463.307-.822.515-.718.415-1.778.951-3.204 1.482-2.856 1.062-7.158 2.094-13.086 2.094s-10.23-1.032-13.085-2.094c-1.426-.53-2.487-1.067-3.205-1.482a12.586 12.586 0 01-.822-.516 7.43 7.43 0 01-.29-.209l-.02-.017-.009-.006-.003-.002-.001-.002c-.001 0-.002 0 .935-1.172l-.937 1.171a1.5 1.5 0 011.868-2.347z'
					clipRule='evenodd'
				></path>
				<ellipse cx='65.412' cy='61.25' fill='#D6E1FF' rx='7' ry='2.5'></ellipse>
				<ellipse cx='128.412' cy='61.25' fill='#D6E1FF' rx='7' ry='2.5'></ellipse>
				<path
					fill='#9BB3FD'
					fillRule='evenodd'
					d='M176.859 65.144l.026.012a9.492 9.492 0 01.8.414c.579.333 1.435.888 2.472 1.746 2.072 1.713 4.884 4.648 7.67 9.474 2.786 4.825 3.921 8.728 4.369 11.379.224 1.327.277 2.346.277 3.014-.001.335-.014.582-.026.737a3.282 3.282 0 01-.016.163l-.003.028v-.002l.001-.003-.001.006a1.5 1.5 0 002.967.443l-1.483-.226c1.483.226 1.483.225 1.483.224v-.002l.001-.004.001-.01.004-.027.01-.082a12.387 12.387 0 00.062-1.243c0-.829-.065-2.016-.319-3.516-.507-3.004-1.765-7.245-4.729-12.38-2.964-5.133-6.009-8.343-8.356-10.285a21.219 21.219 0 00-2.886-2.034 12.37 12.37 0 00-1.107-.568L178 62.37l-.026-.01-.009-.004-.004-.002h-.002l-.547 1.396.546-1.397a1.5 1.5 0 00-1.1 2.791h.001zM7.653 95.066a9.587 9.587 0 01-.283-.885c-.174-.645-.387-1.643-.514-2.983-.253-2.676-.167-6.74 1.275-12.122 1.443-5.382 3.4-8.945 4.957-11.136.78-1.097 1.463-1.854 1.936-2.327a9.598 9.598 0 01.666-.607l.022-.017-.002.001-.002.002.004-.003a1.5 1.5 0 00-1.784-2.412l.889 1.208-.89-1.207-.002.001-.003.003-.008.006-.022.016-.065.05c-.053.043-.124.1-.212.175-.175.15-.417.368-.71.661-.587.586-1.38 1.472-2.262 2.712-1.765 2.483-3.875 6.371-5.41 12.098C3.7 84.026 3.584 88.448 3.87 91.48c.143 1.515.387 2.678.602 3.48.108.4.208.71.285.927a7.456 7.456 0 00.127.333l.01.025.005.01.001.004.001.001c0 .001 0 .002 1.375-.6l-1.374.602a1.5 1.5 0 002.75-1.197z'
					clipRule='evenodd'
				></path>
				<path
					fill='#C8D6FF'
					fillRule='evenodd'
					d='M16.412 83.25a1.5 1.5 0 011.5-1.5h156a1.5 1.5 0 110 3h-156a1.5 1.5 0 01-1.5-1.5z'
					clipRule='evenodd'
				></path>
				<path
					fill='#F0F4FF'
					d='M76.412 80.75a6 6 0 016-6h27a6 6 0 016 6v4a6 6 0 01-6 6h-27a6 6 0 01-6-6v-4z'
				></path>
				<path
					fill='#C8D6FF'
					fillRule='evenodd'
					d='M109.412 77.75h-27a3 3 0 00-3 3v4a3 3 0 003 3h27a3 3 0 003-3v-4a3 3 0 00-3-3zm-27-3a6 6 0 00-6 6v4a6 6 0 006 6h27a6 6 0 006-6v-4a6 6 0 00-6-6h-27z'
					clipRule='evenodd'
				></path>
			</g>
			<defs>
				<clipPath id='clip0_185_2137'>
					<path fill='#fff' d='M0 0H198.85V145.5H0z' transform='translate(.575 .25)'></path>
				</clipPath>
			</defs>
		</svg>
	);
}

export default memo(MeetingCompleteIcon);
