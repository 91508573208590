import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		display: block;
		width: 100%;
		margin: 24px 0;
		padding: 0;
		text-align: right;

		.ant-pagination {
			.ant-pagination-item-link,
			.ant-pagination-item,
			.ant-pagination-prev,
			.ant-pagination-next {
				border-color: ${COLORS.neutral5};
				border-radius: 2px;
			}

			.ant-pagination-item-active {
				border-color: ${COLORS.blue};

				a {
					color: ${COLORS.blue};
				}
			}

			.ant-pagination-options-size-changer {
				.ant-select-selector {
					border-radius: 2px;
				}
			}

			.ant-pagination-options-quick-jumper {
				input {
					border-radius: 2px;
				}
			}

			.ant-pagination-item-link {
				border-radius: 2px;
			}
		}
	`;
}
