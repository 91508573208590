import { FC, useCallback } from 'react';
import { connect } from 'react-redux';

import Spinner from 'components/Spinner';
import Table from 'components/Table';
import { useMount } from 'hooks';
import { atsDucks } from 'modules/ATS/ducks';
import { DEFAULT_PAGE_SIZE, DEFAULT_CURRENT_PAGE } from 'modules/Common/constants/table';
import { AssignCandidateToVacancyResponseType } from 'modules/Common/types';
import { handleJobAssignNotificationMessages } from 'modules/Common/utils/commonHelpers';
import { GenericType, IOption } from 'types';

import { columns } from './GravityJobCandidates.entities';
import { GravityJobsListType } from './GravityJobCandidates.types';

type GravityJobsProps = {
	jobId: string;
	activeJobsList: IOption[];
	gravityJobCandidateList: GravityJobsListType;
	loading: GenericType;
	getAtsActiveJobsRequested: () => void;
	getListOfGravityCandidatesByJobIdRequested: ({
		jobId,
		page,
		size,
	}: {
		jobId: string;
		page: number;
		size: number;
	}) => void;
	assignGravityCandidateToVacancyRequested: (
		gravityCandidateId: number,
		jobs: number[],
		callback: (response: AssignCandidateToVacancyResponseType) => void,
	) => void;
};

export const GravityJobCandidates: FC<GravityJobsProps> = ({
	jobId,
	activeJobsList,
	gravityJobCandidateList,
	loading,
	getAtsActiveJobsRequested,
	getListOfGravityCandidatesByJobIdRequested,
	assignGravityCandidateToVacancyRequested,
}) => {
	useMount(() => {
		!activeJobsList?.length && getAtsActiveJobsRequested();
		getListOfGravityCandidatesByJobIdRequested({
			jobId,
			page: DEFAULT_CURRENT_PAGE,
			size: DEFAULT_PAGE_SIZE,
		});
	});

	const handlePaginationChange = useCallback((page: number, size: number) => {
		getListOfGravityCandidatesByJobIdRequested({ jobId, page, size });
	}, []);

	const handleAssignCandidateToJobs = (
		applicationId: number,
		prevValues: number[],
		allValues: number[],
		closeDropdown: () => void,
	) => {
		const prevValuesSet = new Set(prevValues);
		const newValues = allValues.filter((value) => !prevValuesSet.has(value));

		assignGravityCandidateToVacancyRequested(applicationId, newValues, (res) => {
			handleJobAssignNotificationMessages(res);
			closeDropdown();
			getListOfGravityCandidatesByJobIdRequested({
				jobId,
				page: DEFAULT_CURRENT_PAGE,
				size: DEFAULT_PAGE_SIZE,
			});
		});
	};

	const columnsData = columns(activeJobsList, handleAssignCandidateToJobs);

	const gravityJobsLoading = !!loading?.getGravityJobsLoad;

	if (!gravityJobCandidateList?.data || !columnsData?.length || gravityJobsLoading) {
		return <Spinner fixed />;
	}

	return (
		<>
			{gravityJobCandidateList && (
				<Table
					pageSize={gravityJobCandidateList.pageSize}
					current={gravityJobCandidateList.pageIndex}
					total={gravityJobCandidateList.totalCount}
					data={gravityJobCandidateList.data}
					columns={columnsData}
					onChange={handlePaginationChange}
					rowKey='candidateApplicationId'
				/>
			)}
		</>
	);
};

export default connect(
	(state) => ({
		activeJobsList: atsDucks.atsSelectors.getActiveJobsData(state),
		gravityJobCandidateList: atsDucks.atsSelectors.getCurrentGravityCandidatesData(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getAtsActiveJobsRequested: atsDucks.atsActions.getAtsActiveJobsRequested,
		assignGravityCandidateToVacancyRequested:
			atsDucks.atsActions.assignGravityCandidateToVacancyRequested,
		getListOfGravityCandidatesByJobIdRequested:
			atsDucks.atsActions.getListOfGravityCandidatesByJobIdRequested,
	},
)(GravityJobCandidates);
