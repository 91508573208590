import React, { useState } from 'react';

import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { message as antMessage } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import Spinner from 'components/Spinner';
import StripeLock from 'components/SVG/StripeLock';
import StripeLogo from 'components/SVG/StripeLogo';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';

import * as Styled from './CheckoutForm.styled';

const CheckoutForm = ({ total, currency, redirectUrl }) => {
	const stripe = useStripe();
	const elements = useElements();
	const [submitDisabled, setSubmitDisabled] = useState(false);

	const handleSubmit = async (event) => {
		setSubmitDisabled(true);
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		const result = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// eslint-disable-next-line camelcase
				return_url: redirectUrl,
			},
		});

		if (result.error) {
			antMessage.error(result.error.message);
		}

		setSubmitDisabled(false);
	};

	if (!stripe) {
		return <Spinner fixed />;
	}

	return (
		<Styled.Root>
			<Styled.PaymentFormTitle>Pay by Card</Styled.PaymentFormTitle>
			<Styled.PaymentFormDesc>
				Please make your payment of {currency}
				{total?.toFixed(2)}
			</Styled.PaymentFormDesc>
			<form onSubmit={handleSubmit}>
				<Styled.FormWrapper>
					<PaymentElement />
				</Styled.FormWrapper>
				<Styled.Footer>
					<StripeLogo />
					<Styled.FooterMain>
						<Styled.FooterInfo>
							<StripeLock />
							<Styled.FooterInfoDesc>Secure server</Styled.FooterInfoDesc>
						</Styled.FooterInfo>
						<ATSThemedButton
							buttonType={ButtonTypes.primary}
							type='submit'
							disabled={!stripe || submitDisabled}
						>
							Submit Payment
						</ATSThemedButton>
					</Styled.FooterMain>
				</Styled.Footer>
			</form>
		</Styled.Root>
	);
};

export default CheckoutForm;
