import { Badge } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const IconBadge = styled((props) => <Badge {...props} color={COLORS.badgeColor} />)`
		display: flex;

		sup.ant-badge-dot {
			box-shadow: none;
		}

		svg path {
			transition: fill ease 0.2s;
		}
	`;
}
