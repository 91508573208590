import React from 'react';
import { useRouteError } from 'react-router-dom';

import UnregisteredLayout from 'modules/Unregistered/components/UnregisteredLayout';

import { Styled } from './ErrorPages.styled';

export default function ErrorPage() {
	const error = useRouteError() as { statusText: string; message: string };

	console.error(error);

	return (
		<UnregisteredLayout>
			<Styled.Root id='error-page'>
				<Styled.ImageBox />
				<Styled.BoxWrapper>
					<h3>Ooops!</h3>
					<h2>Sorry, an unexpected error has occurred.</h2>
					<p>{error?.statusText || error?.message}</p>
				</Styled.BoxWrapper>
			</Styled.Root>
		</UnregisteredLayout>
	);
}
