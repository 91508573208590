import React, { type FC } from 'react';
import { connect } from 'react-redux';

import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import { commonDucks } from 'modules/Common/ducks';
import unregisteredDucks from 'modules/Unregistered/ducks/unregisterdDucks';
import { CurrencyType, UserTypesEnum } from 'types';
import { getCurrencySymbolByName } from 'utils/helpers';

import { getContractTableData } from '../../utils/contracts';

import ContractDetails from './ContractDetails';
import { Styled } from './ContractView.styled';
import { ContractViewProps } from './ContractView.types';
import OrderCredits from './OrderCredits';

const ContractView: FC<ContractViewProps> = ({
	clientContextId = '',
	contract,
	contractId,
	creditsFields,
	getContractByIdRequested,
	getCreditsRequested,
	userType,
	loading,
}) => {
	const isBackOfficeUser = userType === UserTypesEnum.BACKOFFICE;

	const currencySymbol = getCurrencySymbolByName(contract?.currency) || CurrencyType.PoundSterling;

	useMount(() => {
		contractId && getContractByIdRequested(contractId, isBackOfficeUser);
		!creditsFields?.length && getCreditsRequested();
	});

	if (loading?.getContractByIdLoad || loading?.getContractVatLoad || loading?.getCreditsLoad) {
		return <Spinner fixed />;
	}

	const filteredData = getContractTableData(creditsFields, contract);

	return (
		<>
			{!!creditsFields?.length && (
				<Styled.Root>
					<ContractDetails
						contractFields={contract}
						isBackOfficeUser={isBackOfficeUser}
						clientContextId={clientContextId}
					/>
					{!!contract?.totalPrice && (
						<OrderCredits
							creditsFields={filteredData}
							currency={currencySymbol}
							totalPrice={contract?.totalPrice}
							totalPriceExVat={contract?.totalExVat}
							vatAmount={contract?.vatPrice}
							discountAmount={contract?.discountAmount}
							clientContextId={clientContextId}
						/>
					)}
				</Styled.Root>
			)}
		</>
	);
};

export default connect(
	(state) => ({
		contract: commonDucks.commonSelectors.getContractByIdDetails(state),
		creditsFields: commonDucks.commonSelectors.getCreditFields(state),
		userType: unregisteredDucks.unregisteredSelectors.getUserType(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		getContractByIdRequested: commonDucks.commonActions.getContractByIdRequested,
		getCreditsRequested: commonDucks.commonActions.getCreditsRequested,
	},
)(ContractView);
