import { IInvoicesTypes } from './invoices';

export enum ReceiptTypesEnum {
	Contract = 'CONTRACT',
	Invoice = 'INVOICE',
}

export type ReceiptDataType = {
	poNumber: string;
	clientAddress: string;
	clientName: string;
	btoReference: string;
	dateOfPayment: string;
	totalPrice: number;
	totalPriceExVat: number;
	chargeVat: boolean;
	vatPrice: number;
	premiumMultisite: number;
	premiumMultisitePrice: number;
	cvFiltering: number;
	cvFilteringPrice: number;
	guardian: number;
	guardianPrice: number;
	indeed: number;
	indeedPrice: number;
	invoiceType: IInvoicesTypes;
};
