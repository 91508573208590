import { type FC } from 'react';

import { Styled } from './AtsClientColorPicker.styled';
import { ColorPickerItem } from './ColorPickerItem';

type ColorPickerItemProps = {
	id: number;
	title: string;
	initialValue: string;
	hint?: string;
	onChange: (color: string) => void;
};

type AtsClientColorPickerProps = {
	data: ColorPickerItemProps[];
};

const AtsClientColorPicker: FC<AtsClientColorPickerProps> = ({ data }) => {
	return (
		<Styled.ColorPickerContent>
			{data.map((colorItem) => (
				<ColorPickerItem
					title={colorItem.title}
					pickedColor={colorItem.initialValue}
					key={colorItem.id}
					hint={colorItem.hint}
					onChange={colorItem.onChange}
				/>
			))}
		</Styled.ColorPickerContent>
	);
};

export default AtsClientColorPicker;
