import React, { FC } from 'react';
import { Editor } from 'react-draft-wysiwyg';

import { EditorState } from 'draft-js';

import { Styled } from 'modules/Common/components/FieldEditor/FieldEditor.styled';
import { toolbarBuilder } from 'modules/Common/components/FieldEditor/FiledEditor.constants';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

type FieldEditorControlledProps = {
	editorState: EditorState;
	placeholder: string;
	defaultValue?: EditorState;
	minRaws?: number;
	handleChange: (value: EditorState) => void;
};

const FieldEditorControlled: FC<FieldEditorControlledProps> = ({
	editorState,
	placeholder,
	minRaws = 1,
	defaultValue,
	handleChange,
}) => {
	const toolbar = toolbarBuilder();

	return (
		<Styled.Root minRaws={minRaws}>
			<Editor
				editorState={editorState}
				defaultEditorState={defaultValue}
				onEditorStateChange={handleChange}
				handlePastedText={() => false}
				placeholder={placeholder}
				toolbar={toolbar}
				wrapperClassName='wrapper-class'
				editorClassName='editor-class ant-input'
				toolbarClassName='toolbar-class'
			/>
		</Styled.Root>
	);
};

export default FieldEditorControlled;
