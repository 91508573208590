import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function RocketIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.63279 8.68738C7.00984 9.15816 8 10.4634 8 12V14C8 15.933 6.433 17.5 4.5 17.5C2.567 17.5 1 15.933 1 14V12C1 10.3736 2.10938 9.00625 3.61271 8.61345C4.27471 4.57855 7.77793 1.5 12.0002 1.5C16.2225 1.5 19.7257 4.5786 20.3877 8.61356C21.8908 9.00648 23 10.3737 23 12V14C23 15.5536 21.9878 16.8708 20.5868 17.328C20.42 19.3837 18.6988 21 16.6001 21H14.792C14.4062 21.883 13.5252 22.5 12.5 22.5H11.5C10.1193 22.5 9 21.3807 9 20C9 18.6193 10.1193 17.5 11.5 17.5H12.5C13.5252 17.5 14.4062 18.117 14.792 19H16.6001C17.5769 19 18.3902 18.2997 18.5652 17.3738C17.0861 16.9649 16 15.6093 16 14V12C16 10.4633 16.9903 9.15794 18.3676 8.68726C17.7604 5.7267 15.1403 3.5 12.0002 3.5C8.86 3.5 6.2399 5.72676 5.63279 8.68738ZM4.5 10.5C3.67157 10.5 3 11.1716 3 12V14C3 14.8284 3.67157 15.5 4.5 15.5C5.32843 15.5 6 14.8284 6 14V12C6 11.1716 5.32843 10.5 4.5 10.5ZM18 12C18 11.1716 18.6716 10.5 19.5 10.5C20.3284 10.5 21 11.1716 21 12V14C21 14.8284 20.3284 15.5 19.5 15.5C18.6716 15.5 18 14.8284 18 14V12ZM13 20C13 19.7239 12.7761 19.5 12.5 19.5H11.5C11.2239 19.5 11 19.7239 11 20C11 20.2761 11.2239 20.5 11.5 20.5H12.5C12.7761 20.5 13 20.2761 13 20Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(RocketIcon);
