import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function Icon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M18.8128 17.1886C19.2619 17.6377 19.2619 18.3658 18.8128 18.8149C18.3637 19.264 17.6355 19.264 17.1864 18.8149L11.9997 13.6281L6.81279 18.8151C6.36369 19.2642 5.63555 19.2642 5.18644 18.8151C4.73734 18.366 4.73733 17.6379 5.18643 17.1888L10.3734 12.0017L5.18643 6.81473C4.73733 6.36562 4.73734 5.63748 5.18644 5.18838C5.63555 4.73928 6.36369 4.73929 6.81279 5.1884L11.9997 10.3754L17.1864 5.18858C17.6355 4.73947 18.3637 4.73947 18.8128 5.18857C19.2619 5.63767 19.2619 6.36581 18.8128 6.81491L13.626 12.0017L18.8128 17.1886Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(Icon);
