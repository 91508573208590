import styled from 'styled-components/macro';

import { COLORS } from 'theme';

interface IUserIconProps {
	bgColor?: string;
}

interface ISenderProps {
	sender: string;
}

export namespace Styled {
	export const Root = styled.div`
		background-color: ${COLORS.white};
	`;

	export const Main = styled.main`
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		width: 100%;
		padding-left: 32px;
		padding-right: 32px;
	`;

	export const MessageContainer = styled.div`
		font-family: 'Arial', sans-serif;
		padding: 24px;
		margin: auto;
	`;

	export const DateSection = styled.div`
		margin-bottom: 20px;
	`;

	export const DateHeader = styled.h2`
		color: ${COLORS.darkGray7};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		text-align: center;
		margin-bottom: 16px;
	`;

	export const Message = styled.div<ISenderProps>`
		display: flex;
		margin-bottom: 10px;
		align-items: flex-end;
		justify-content: ${({ sender }) => (sender === 'user' ? 'flex-end' : 'flex-start')};
		margin-bottom: 16px;
	`;

	export const MessageWrap = styled.div`
		display: flex;
		flex-direction: column;
		width: 63%;
	`;

	export const MessageContent = styled.div<ISenderProps>`
		padding: 8px 12px;
		border-radius: 12px;
		background-color: ${({ sender }) => (sender === 'user' ? COLORS.blue5 : COLORS.lightGray5)};
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		p {
			margin: 0;
		}
		span {
			background-color: ${({ sender }) =>
		sender === 'user' ? COLORS.blue5 : COLORS.lightGray5}!important;
			color: ${COLORS.black}!important;
			font-family: Inter !important;
			font-size: 16px !important;
		}
		ul {
			list-style-type: disc;
			padding-left: 15px;
		}
	`;

	export const MessageInfoWrap = styled.div<ISenderProps>`
		display: flex;
		justify-content: ${({ sender }) => (sender === 'user' ? 'flex-end' : 'flex-start')};
	`;

	export const MessageInfo = styled.span<ISenderProps>`
		font-size: 12px;
		display: flex;
		align-items: center;
		flex-direction: ${({ sender }) => (sender === 'user' ? 'row-reverse' : 'row')};
		margin: ${({ sender }) => (sender === 'user' ? '0 10px 0 0' : '0 0 0 10px')};
		text-align: ${({ sender }) => (sender === 'user' ? 'right' : 'left')};
		justify-content: ${({ sender }) => (sender === 'user' ? 'flex-start' : 'flex-end')};
		gap: 4px;
		margin-bottom: 4px;
	`;

	export const MessageInfoUserIcon = styled.div<IUserIconProps>`
		width: 28px;
		height: 28px;
		background-color: ${({ bgColor }) => (bgColor ? bgColor : COLORS.green)};
		border-radius: 50%;
		color: ${COLORS.white};
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-weight: bold;
	`;

	export const MessageInfoTime = styled.span`
		color: ${COLORS.darkGray1};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	`;

	export const MessageInfoUser = styled.span`
		color: ${COLORS.darkGray4};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
	`;

	export const Attachment = styled.div`
		border: 1px solid ${COLORS.gray};
		display: flex;
		justify-content: space-between;
		padding: 10px;
		margin-top: 12px;
		transition: all 0.2s ease;
		color: ${COLORS.black};
		border-radius: 6px;
		&:hover {
			background-color: transparent;
			box-shadow: 0px 3px 5px 0px ${COLORS.gray};
			cursor: pointer;
		}
	`;

	export const AttachmentFileName = styled.div`
		display: flex;
		svg {
			margin-right: 8px;
		}
	`;
}
