import React, { useCallback, type FC } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useMount } from 'hooks';
import SendSMS from 'modules/ATS/containers/SendSMS';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { commonDucks } from 'modules/Common/ducks';
import { Routes } from 'types';

import { Styled } from './ATSSendSMSPage.styled';

type smsCountType = {
	available: number;
	total: number;
};

interface ATSSendSMSPageProps {
	smsCount: smsCountType | null;
	getSMSCountRequested: () => void;
}

const ATSSendSMSPage: FC<ATSSendSMSPageProps> = ({ smsCount, getSMSCountRequested }) => {
	const location = useLocation();
	const { prevPage } = location.state || {};
	const [selectedCandidatesIds, setSelectedCandidatesIds] = React.useState<number[]>([]);

	useMount(() => {
		!smsCount && getSMSCountRequested();
	});

	const buttonText = smsCount?.available === 0 ? 'Buy Now' : `from ${smsCount?.total}`;

	const navigate = useNavigate();

	const handleClick = useCallback(() => {
		navigate(`${Routes.ATS}${Routes.BuySMSCredits}`, {
			state: { prevPage: location.pathname, selectedCandidateIds: selectedCandidatesIds },
		});
	}, [navigate, selectedCandidatesIds]);

	return (
		<>
			<Styled.Head>
				<PageNavTitle
					title={'New SMS'}
					navigationLink={prevPage || `${Routes.ATS}${Routes.Candidates}`}
				/>
				<Styled.AvailableSMSLimitsBox onClick={handleClick}>
					<Styled.LeftSMS>{smsCount?.available}</Styled.LeftSMS>
					<Styled.TotalSMS>
						<Styled.TotalSMSFirstLine>SMS Available</Styled.TotalSMSFirstLine>
						<Styled.TotalSMSSecondLine buyNow={smsCount?.available === 0}>
							{buttonText}
						</Styled.TotalSMSSecondLine>
					</Styled.TotalSMS>
				</Styled.AvailableSMSLimitsBox>
			</Styled.Head>
			<Styled.Content>
				<SendSMS
					smsCount={smsCount}
					getSMSCountRequested={getSMSCountRequested}
					updateListOfSelectedCandidates={setSelectedCandidatesIds}
				/>
			</Styled.Content>
		</>
	);
};

export default connect(
	(state) => ({
		contract: commonDucks.commonSelectors.getContractByIdDetails(state),
		creditsFields: commonDucks.commonSelectors.getCreditFields(state),
		smsCount: commonDucks.commonSelectors.getSMSCount(state),
	}),
	{
		getSMSCountRequested: commonDucks.commonActions.getSMSCountRequested,
	},
)(ATSSendSMSPage);
