import { type FC, useState } from 'react';

import { Modal } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import LetterHeartIcon from 'components/SVG/LetterHeartIcon';
import CompanyApply from 'modules/Common/containers/CompanyApply';

import { Styled } from '../CareerPageJobs.styled';

type CareerPageJobsProps = {
	clientId?: number | string;
	highlightColour: string;
};

const CareerPageJobs: FC<CareerPageJobsProps> = ({ highlightColour, clientId }) => {
	const [applyModalOpen, setApplyModalOpen] = useState(false);
	const handleCancel = () => setApplyModalOpen(false);
	const handleSendCVClick = () => setApplyModalOpen(true);

	return (
		<>
			<Styled.JobsContactsApply>
				<Styled.JobsContactsApplyIcon>
					<LetterHeartIcon />
				</Styled.JobsContactsApplyIcon>
				<Styled.JobsContactsApplyTitle>Didn’t find a suitable job?</Styled.JobsContactsApplyTitle>
				<Styled.JobsContactsApplyDesc>
					Submit your CV and we will try to find something just for you!
				</Styled.JobsContactsApplyDesc>
				<Styled.JobsContactsApplyButton
					buttonType={ButtonTypes.primary}
					highlightColour={highlightColour}
					onClick={handleSendCVClick}
				>
					Send CV
				</Styled.JobsContactsApplyButton>
			</Styled.JobsContactsApply>

			<Modal open={applyModalOpen} footer={null} centered onCancel={handleCancel} width={751}>
				<CompanyApply closeModal={() => setApplyModalOpen(false)} clientId={clientId} />
			</Modal>
		</>
	);
};

export default CareerPageJobs;
