import React, { FC } from 'react';
import { Field } from 'react-final-form';

import { Select } from 'antd';
import PropTypes from 'prop-types';

import FieldWrapper from 'components/FieldWrapper';
import * as Styled from 'modules/BackOffice/containers/ClientChangesFormBranding/ClientChangesFormBranding.styled';
import FormBlock from 'modules/Common/components/FormBlock';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { ClientChangeFormDataType } from 'modules/Common/types';
import { OptionsType } from 'types';
import { filterSelectOptions } from 'utils/helpers';

type ClientRelationshipProps = {
	parentsList: OptionsType[];
	clientChangesFormData: ClientChangeFormDataType;
	onClear?: () => void;
	onChange: (value: number) => void;
};

const ClientRelationship: FC<ClientRelationshipProps> = ({
	parentsList,
	clientChangesFormData,
	onClear,
	onChange,
}) => {
	return (
		<FormBlock title='Client Relationships'>
			<FormBlockLine>
				<Styled.FieldWrapper>
					<Styled.ClientBaseDescription>
						Clients can be related to other clients on a parent {'>'} child basis.
					</Styled.ClientBaseDescription>
					<Styled.ClientBaseDescription>
						Note: Child clients will share the credits applied to their parent.
					</Styled.ClientBaseDescription>
				</Styled.FieldWrapper>
				<Styled.FieldWrapper>
					<Field name='parent' initialValue={clientChangesFormData?.parent}>
						{({ input }) => (
							<FieldWrapper name='parent' label='Parent Client'>
								<Select
									{...input}
									value={input.value || null}
									options={parentsList}
									onChange={(value) => {
										onChange(value);
										input.onChange(value);
									}}
									placeholder='-Choose parent client-'
									onClear={() => {
										onClear && onClear();
										input.onChange(null);
									}}
									fieldNames={{ label: 'name', value: 'id' }}
									filterOption={(inputValue, option) =>
										filterSelectOptions(inputValue, option, 'name')
									}
									showSearch
									allowClear
								/>
							</FieldWrapper>
						)}
					</Field>
				</Styled.FieldWrapper>
			</FormBlockLine>
		</FormBlock>
	);
};

ClientRelationship.propTypes = {
	parentsList: PropTypes.array.isRequired,
};

export default ClientRelationship;
