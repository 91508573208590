import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;

		.ant-tabs .ant-tabs-nav {
			.ant-tabs-tab-btn {
				position: relative;
				padding: 0 10px;
			}

			&:before {
				display: none;
			}
		}
	`;
}
