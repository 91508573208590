import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function GuardianIcon({ fill = COLORS.black }: IconType) {
	return (
		<svg width='9' height='12' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.17809 3.05966C6.10707 2.82955 6.00906 2.62358 5.88406 2.44176C5.7619 2.2571 5.61417 2.09943 5.44087 1.96875C5.27042 1.83807 5.0744 1.74006 4.85281 1.67472C4.63121 1.60653 4.38974 1.57244 4.12837 1.57244C3.65962 1.57244 3.24201 1.69034 2.87553 1.92614C2.50906 2.16193 2.2207 2.50852 2.01048 2.96591C1.80309 3.42045 1.6994 3.97443 1.6994 4.62784C1.6994 5.28693 1.80309 5.84517 2.01048 6.30256C2.21786 6.75994 2.50621 7.10795 2.87553 7.34659C3.24485 7.58239 3.67383 7.70028 4.16246 7.70028C4.60565 7.70028 4.98917 7.61506 5.31303 7.4446C5.63974 7.27415 5.89116 7.03267 6.06729 6.72017C6.24343 6.40483 6.3315 6.03551 6.3315 5.61222L6.68945 5.66761H4.32014V4.43182H7.86133V5.48011C7.86133 6.22727 7.70224 6.87358 7.38406 7.41903C7.06587 7.96449 6.62837 8.38494 6.07156 8.6804C5.51474 8.97301 4.87553 9.11932 4.15394 9.11932C3.34996 9.11932 2.644 8.93892 2.03604 8.57812C1.43093 8.21449 0.957919 7.69886 0.61701 7.03125C0.278942 6.3608 0.109908 5.56534 0.109908 4.64489C0.109908 3.94034 0.209339 3.31108 0.408203 2.7571C0.609908 2.20312 0.891158 1.73295 1.25195 1.34659C1.61275 0.957386 2.03604 0.661932 2.52184 0.460227C3.00764 0.255682 3.53604 0.153409 4.10707 0.153409C4.59002 0.153409 5.04031 0.224432 5.45792 0.366477C5.87553 0.505682 6.24627 0.704545 6.57013 0.963068C6.89684 1.22159 7.16531 1.52841 7.37553 1.88352C7.58576 2.23864 7.72354 2.63068 7.78888 3.05966H6.17809Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(GuardianIcon);
