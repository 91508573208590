import { NavigateFunction } from 'react-router-dom';

import { message } from 'antd';

import { Routes } from 'types/routes';

import { CreateEmailResponseType } from './PostMessage.types';

export const handleCreateEmailCallback = (
	response: CreateEmailResponseType,
	navigateTo: NavigateFunction,
) => {
	const successfullySent = response.threads.length;
	const failedToSend = response.failedToSendApplicationIds.length;
	const allEmails = successfullySent + failedToSend;
	if (!successfullySent) {
		return message.error('Failed to send messages to all recipients');
	} else if (!failedToSend) {
		message.success('The email has been sent');
	} else {
		message.warning(
			`Your email has successfully sent to ${successfullySent}/${allEmails} candidates`,
		);
	}

	navigateTo(`${Routes.ATS}${Routes.Messages}`);
};
