import React, { Key } from 'react';

import { Button, Space } from 'antd';

import { ColumnsDataType } from 'components/Table/Table.types';
import { JobDataType } from 'modules/Common/types';
import { DataFormatEnum, Routes } from 'types';
import { getDateFormat } from 'utils/helpers';

import { Styled } from './JobsListTable.styled';

type DataTableType = JobDataType & {
	key?: Key;
};

export const columns = (handleCopyJob: (id: number) => void): ColumnsDataType => [
	{
		title: 'Title',
		dataIndex: 'title',
		key: 'title',
		render: (_: string, { title, id }: Partial<DataTableType>) => (
			<Styled.PageLink to={`${Routes.ATS}${Routes.Jobs}/${id}`}>{title}</Styled.PageLink>
		),
	},
	{
		title: 'Applications',
		dataIndex: 'numberOfApplications',
		key: 'numberOfApplications',
		align: 'center',
		render: (_: string, { numberOfApplications }: Partial<DataTableType>) => (
			// TODO Will be redirected with a separate view on the application details
			<>
				{numberOfApplications ? (
					<Styled.PageLink
						to={`${Routes.BackOffice}${Routes.Jobs}`}
						style={{ justifyContent: 'center' }}
					>
						<span>{numberOfApplications}</span>
					</Styled.PageLink>
				) : (
					0
				)}
			</>
		),
	},
	{
		title: 'Date Created',
		dataIndex: 'startDate',
		key: 'startDate',
		align: 'center',
		render: (_: string, { startDate }: Partial<DataTableType>) => {
			const formattedData = getDateFormat(startDate, DataFormatEnum.Full) ?? '-';

			return <>{formattedData}</>;
		},
	},
	{
		title: 'Action',
		key: 'action',
		align: 'center',
		width: '176px',
		render: (_: string, { id }: Partial<DataTableType>) => (
			<Space size='middle' onClick={() => id && handleCopyJob(id)}>
				<Button type='default' size='small'>
					Copy
				</Button>
			</Space>
		),
	},
];
