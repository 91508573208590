import React from 'react';

import { IFieldProps } from 'types';

import { Styled } from './FieldWrapper.styled';

export const FieldWrapper: React.FC<IFieldProps> = ({
	name,
	label,
	errorMessage,
	hint,
	isInline = false,
	isFixed = false,
	fullWidth = false,
	children,
	required = false,
}) => {
	return (
		<Styled.Field isFullWidth={fullWidth}>
			<Styled.FieldContent isInline={isInline}>
				{label && name && (
					<Styled.Label htmlFor={name} isInline={isInline}>
						{label}
						{required && <span>*</span>}
					</Styled.Label>
				)}

				<>{children}</>
			</Styled.FieldContent>
			<Styled.FieldHelpers>
				{errorMessage && (
					<Styled.ErrorMessage isInline={isInline} isFixed={isFixed}>
						{errorMessage}
					</Styled.ErrorMessage>
				)}
				{hint && (
					<Styled.FieldHint isInline={isInline} isError={!!errorMessage}>
						{hint}
					</Styled.FieldHint>
				)}
			</Styled.FieldHelpers>
		</Styled.Field>
	);
};
