import { type FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { isEmpty, pick } from 'lodash';

import Spinner from 'components/Spinner';
import { atsDucks } from 'modules/ATS/ducks';
import { BrandingMode } from 'modules/Common/components/BrandingContainer/BrandingContainer.types';
import { acceptedImageFileFormat, DEFAULT_CAREER_BG, DEFAULT_LOGO } from 'modules/Common/constants';
import { ADDRESS_FIELDS } from 'modules/Common/constants/addressFields';
import { SocialNetworksAllowedFields } from 'modules/Common/constants/clientChangesForm.constants';
import CareerBrandingInner from 'modules/Common/containers/CareerBrandingInner';
import { commonDucks } from 'modules/Common/ducks';
import {
	BenefitType,
	CareerBrandingType,
	ClientAddressType,
	ClientInfoType,
	IUser,
} from 'modules/Common/types';
import { getSingleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import { prepareSocialNetworksData } from 'modules/Common/utils/commonHelpers';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { GenericType } from 'types';

import { jobsData } from './ClientCareerPageContainer.constants';

type CareerPageContainerProps = {
	user: IUser;
	clientCareerBranding: CareerBrandingType & {
		removedBenefits: BenefitType[];
	};
	getClientInfo: (id: number) => void;
	saveClientProcess: (props: GenericType) => void;

	clientInfo: Partial<ClientInfoType>;
	updateClientRequested: (values: Partial<ClientInfoType>) => void;
	saveClientAtsBranding: (props: GenericType) => void;
};

const ClientCareerPageContainer: FC<CareerPageContainerProps> = ({
	user,
	saveClientProcess,
	clientCareerBranding,
	getClientInfo,
	saveClientAtsBranding,
	updateClientRequested,
	clientInfo,
}) => {
	const { clientId } = user;

	useEffect(() => {
		getClientInfo(clientId);
	}, [clientId, getClientInfo]);

	const {
		clientName,
		whyOurCompany,
		benefits,
		logo = DEFAULT_LOGO,
		backgroundImage = DEFAULT_CAREER_BG,
		highlightColour,
		videoLink,
	} = clientCareerBranding;

	const socialNetworks = pick(clientCareerBranding.socialNetworks, SocialNetworksAllowedFields);
	const [colors, setColors] = useState({ highlightColour });

	useEffect(() => {
		setColors({ highlightColour: clientCareerBranding.highlightColour });
	}, [clientCareerBranding]);

	if (isEmpty(clientCareerBranding)) {
		return <Spinner />;
	}

	const changewhyOurCompany = (v: string): void => {
		saveClientProcess({ whyOurCompany: v });
	};

	const setLogoUrl = (v: string): void => {
		saveClientProcess({
			logo: v,
		});
		saveClientAtsBranding({
			logo: v,
		});
		updateClientRequested({
			logo: v,
			clientName: clientInfo.clientName,
			companyAddress: clientInfo.companyAddress,
			id: clientInfo.id,
		});
	};
	const setBgUrl = (v: string): void => {
		saveClientProcess({
			backgroundImage: v,
		});
	};
	const changevideoLink = (v: string): void => {
		saveClientProcess({ videoLink: v });
	};
	const changeClientAddress = (v: ClientAddressType): void => {
		saveClientProcess(v);
	};
	const socialNetworksData = prepareSocialNetworksData(socialNetworks);
	const onBgRemove = () => {
		setBgUrl('');
	};

	const bgUploaderProps = getSingleFileUploaderProps(
		(url, responseData) => {
			setBgUrl(responseData);
		},
		{ showUploadList: false, accept: acceptedImageFileFormat },
	);

	const logoUploaderProps = getSingleFileUploaderProps(
		(url, responseData) => {
			setLogoUrl(responseData);
		},
		{ showUploadList: false, accept: acceptedImageFileFormat },
	);

	const ajustColorData = [
		{
			id: 0,
			title: 'Highlight Colour',
			initialValue: colors.highlightColour,
			onChange: (color: string): void => setColors({ ...colors, highlightColour: color }),
		},
	];
	const applyColors = () => saveClientProcess(colors);
	const resetColors = () => setColors({ highlightColour: clientCareerBranding.highlightColour });

	const clientAddress = pick(clientCareerBranding, ADDRESS_FIELDS);

	const changeBenefits = (v: BenefitType[]): void => {
		const storedBenefits = clientCareerBranding.benefits;
		const filteredRemovedBenefits = v.filter((b) => b.isDeleted);
		const removedBenefits = storedBenefits?.reduce((acc: BenefitType[], sb: BenefitType) => {
			const isBenefitRemoved = filteredRemovedBenefits.some((b) => b.id === sb.id) && !sb.isDeleted;

			if (isBenefitRemoved) {
				return [...acc, sb];
			}

			return acc;
		}, []);

		removedBenefits &&
			saveClientProcess({
				benefits: v,
				removedBenefits,
				id: clientCareerBranding.id,
				client: clientCareerBranding.client,
			});
	};

	return (
		<>
			<CareerBrandingInner
				mode={BrandingMode.Edit}
				ajustColorData={ajustColorData}
				applyColors={applyColors}
				resetColors={resetColors}
				backgroundImage={backgroundImage}
				bgUploaderProps={bgUploaderProps}
				logoUploaderProps={logoUploaderProps}
				onBgRemove={onBgRemove}
				jobsData={jobsData}
				clientName={clientName}
				logo={logo}
				whyOurCompany={whyOurCompany}
				changewhyOurCompany={changewhyOurCompany}
				benefits={benefits}
				videoLink={videoLink}
				clientAddress={clientAddress}
				changeBenefits={changeBenefits}
				socialNetworksData={socialNetworksData}
				changevideoLink={changevideoLink}
				changeClientAddress={changeClientAddress}
				colors={colors}
				setBgUrl={setBgUrl}
			/>
		</>
	);
};

export default connect(
	(state) => ({
		clientCareerBranding: atsDucks.atsSelectors.getClientCareerBrandingData(state),
		user: unregisteredDucks.unregisteredSelectors.getUser(state),
		clientInfo: commonDucks.commonSelectors.getClientData(state),
	}),
	{
		getClientInfo: atsDucks.atsActions.getATSCareerBrandingByClientId,
		saveClientProcess: atsDucks.atsActions.saveClientCareerBranding,
		saveClientAtsBranding: atsDucks.atsActions.saveClientAtsBranding,
		updateClientRequested: commonDucks.commonActions.updateClientRequested,
	},
)(ClientCareerPageContainer);
