import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ZoomVideo from '@zoom/videosdk';
import { Tooltip, message } from 'antd';

import API from 'api';
import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import Spinner from 'components/Spinner';
import CameraIcon from 'components/SVG/CameraIcon';
import MembersIcon from 'components/SVG/MembersIcon';
import MuteIcon from 'components/SVG/MuteIcon';
import { COLORS } from 'theme';

import MembersInfo from '../MembersInfo';

import InterviewProcessDevices from './InterviewProcessDevices/InterviewProcessDevices';
import VideoInterviewParticipants from './VideoInterviewParticipants';
import { Styled } from './VideoInterviewProcess.styled';

type VideoInterviewProccessProps = {
	token: string;
	recording: boolean;
	username: string;
	isInterviewer: boolean;
	isCandidate: boolean;
	recordingRequestAccepted: boolean;
	onZoomRoomCreated: () => void;
	roomName: string;
	meetingDescription: string;
	handleLeave: () => void;
	meetingHistory: {
		message: string;
		time: string;
	}[];
};

const VideoInterviewProccess: FC<VideoInterviewProccessProps> = ({
	token,
	username,
	isInterviewer,
	recordingRequestAccepted,
	roomName,
	recording,
	onZoomRoomCreated,
	meetingDescription,
	handleLeave,
	meetingHistory,
}) => {
	const [zoomClient, setZoomClient] = useState(null);
	const { interviewId } = useParams();
	const [showSelfCanvas, setShowSelfCanvas] = useState(true);
	const [showSelfHtml, setShowSelfHtml] = useState(true);
	const [loading, setLoading] = useState(true);
	const [participants, setParticipants] = useState([]);

	const [muted, setMuted] = useState(false);
	const [cameraDisabled, setCameraDisabled] = useState(false);
	const [zoomUser, setZoomUser] = useState(null);
	const [stream, setMediaStream] = useState<MediaStream | null>(null);
	const [showMembersInfo, setShowMembersInfro] = useState<boolean>(false);

	const [joined, setJoined] = useState([]);
	const [listOfSpeakingParticipants, setListOfSpeakingParticipants] = useState([]);

	useEffect(() => {
		setZoomClient(ZoomVideo.createClient());
	}, []);

	useEffect(() => {
		if (zoomClient) {
			const activeParticipantChangeHandler = (payload) => {
				const activeSpeakerIds = payload.map((participant) => participant.userId);
				setListOfSpeakingParticipants(activeSpeakerIds);
			};

			zoomClient.on('active-speaker', activeParticipantChangeHandler);

			return () => {
				zoomClient.off('active-speaker', activeParticipantChangeHandler);
			};
		}
	}, [zoomClient]);

	useEffect(() => {
		if (zoomClient) {
			const currentUser = zoomClient.getCurrentUserInfo();

			setJoined([{ isCurrentUser: true, ...currentUser }, ...participants]);
		}
	}, [participants, zoomClient]);

	const handleSession = async (tkn: string) => {
		try {
			setShowSelfCanvas(true);
			setShowSelfHtml(true);

			await zoomClient.init('en-US', 'Global', { patchJsMedia: true, enforceMultipleVideos: true });
			await zoomClient.join(roomName, tkn, username);
			const createdStream = await zoomClient.getMediaStream();
			setMediaStream(createdStream);

			if (createdStream.isRenderSelfViewWithVideoElement()) {
				setShowSelfCanvas(false);
				await createdStream.startVideo({
					videoElement: document.querySelector('#my-self-view-video'),
				});
			} else {
				setShowSelfHtml(false);
				await createdStream.startVideo();
				await createdStream.renderVideo(
					document.querySelector('#my-self-view-canvas'),
					zoomClient.getCurrentUserInfo().userId,
					1920,
					1080,
					0,
					0,
					3,
				);
			}

			await createdStream.startAudio();

			const currrentUser = zoomClient.getCurrentUserInfo();
			setZoomUser(currrentUser);

			setLoading(false);
			if (recordingRequestAccepted) {
				onZoomRoomCreated();
			}
		} catch (err) {
			console.error('handle session error', err);
		}
	};

	useEffect(() => {
		if (token && zoomClient) {
			setLoading(true);
			handleSession(token);
		}
	}, [token, zoomClient]);

	if (!zoomClient) {
		return <Spinner />;
	}

	const handleMute = async () => {
		try {
			if (muted) {
				await stream?.unmuteAudio();
				setMuted(false);
			} else {
				await stream?.muteAudio();
				setMuted(true);
			}
		} catch (e) {
			console.error('handle mute error', e);
		}
	};

	const handleCamera = async () => {
		try {
			if (cameraDisabled) {
				if (stream.isRenderSelfViewWithVideoElement()) {
					await stream.startVideo({ videoElement: document.querySelector('#my-self-view-video') });
				} else {
					await stream.startVideo();
					await stream.renderVideo(
						document.querySelector('#my-self-view-canvas'),
						zoomClient.getCurrentUserInfo().userId,
						1920,
						1080,
						0,
						0,
						3,
					);
				}

				setCameraDisabled(false);
			} else {
				await stream?.stopVideo();
				await stream?.detachVideo(zoomUser.userId);

				setCameraDisabled(true);
			}
		} catch (e) {
			console.error('handleCamera error', e);
		}
	};

	const onLeave = () => {
		zoomClient.leave();
		handleLeave();
	};

	const onResendLink = async () => {
		try {
			await API.interviewService.resendAtsInterviewLink(interviewId);
			message.success('Link has been sent');
		} catch (e) {
			message.error('Failed to send the link');
		}
	};

	const isSelfVideoReady = !(showSelfHtml && showSelfCanvas) && !loading;
	const selfIsActive =
		listOfSpeakingParticipants.includes(zoomClient?.getCurrentUserInfo()?.userId) &&
		!zoomClient?.getCurrentUserInfo().muted;

	return (
		<Styled.Root>
			<Styled.VideoInterviewHeader>
				<Styled.VideoInterviewTitle>Video Interview</Styled.VideoInterviewTitle>
				<Styled.VideoInterviewHeaderButtons>
					<Tooltip title={'Click to view interview participants and history'}>
						<Styled.MembersIcon
							onClick={() => setShowMembersInfro((ps) => !ps)}
							active={showMembersInfo}
						>
							<MembersIcon fill={showMembersInfo ? COLORS.white : COLORS.darkGray4} />
						</Styled.MembersIcon>
					</Tooltip>
					{isInterviewer && (
						<Button buttonType={ButtonTypes.secondary} onClick={onResendLink}>
							Send Link
						</Button>
					)}
					<Button buttonType={ButtonTypes.danger} color={COLORS.red} onClick={onLeave}>
						Leave
					</Button>
				</Styled.VideoInterviewHeaderButtons>
			</Styled.VideoInterviewHeader>
			<Styled.VideoInterviewDescription>{meetingDescription}</Styled.VideoInterviewDescription>
			<InterviewProcessDevices stream={stream} zoomClient={zoomClient} />
			<Styled.Content>
				<video-player-container>
					<Styled.VideoContainer participantsAmount={participants?.length || 0}>
						<Styled.SelfVideoContainer
							isSelfVideoReady={isSelfVideoReady}
							participantIsSpeaking={selfIsActive}
						>
							<Styled.RecordingLabel>
								{recording ? (
									<Styled.Recording>
										<Styled.RecordingDot />
										<span>Recording</span>
									</Styled.Recording>
								) : (
									'Not Recorded'
								)}
							</Styled.RecordingLabel>

							{showSelfHtml && <Styled.Video id='my-self-view-video' />}
							{showSelfCanvas && (
								<Styled.Canvas id='my-self-view-canvas' width='1920' height='1080' />
							)}
							{cameraDisabled && showSelfCanvas && <Styled.PseudoCanvas />}
							<Styled.ButtonsControlWrapper>
								<Styled.SelfVideoMute onClick={handleMute}>
									<MuteIcon active={!muted} />
								</Styled.SelfVideoMute>
								<Styled.SelfVideoCamera onClick={handleCamera}>
									<CameraIcon active={!cameraDisabled} />
								</Styled.SelfVideoCamera>
							</Styled.ButtonsControlWrapper>
						</Styled.SelfVideoContainer>
						{loading ? (
							<Spinner />
						) : (
							<VideoInterviewParticipants
								stream={stream}
								zoomClient={zoomClient}
								participants={participants}
								setParticipants={setParticipants}
								listOfSpeakingParticipants={listOfSpeakingParticipants}
							/>
						)}
					</Styled.VideoContainer>
				</video-player-container>
				{showMembersInfo && <MembersInfo joined={joined} meetingHistory={meetingHistory} />}
			</Styled.Content>
		</Styled.Root>
	);
};

export default VideoInterviewProccess;
