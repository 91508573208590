import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		position: relative;
		height: 100%;
		background-color: ${COLORS.white};
	`;
	export const ContentWrapper = styled.div`
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		height: 100%;
	`;
	export const ContentInner = styled.div`
		width: 100%;
		height: 100%;
	`;
}
