import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		margin-bottom: 24px;
		background-color: ${COLORS.white};
		padding: 24px;
		border-radius: 6px;
		postion: relative;
	`;

	export const Head = styled.div`
		margin-bottom: 32px;
	`;

	export const Title = styled.h3`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.22px;
	`;

	export const Content = styled.div`
		display: flex;
		flex-direction: column;
	`;
}
