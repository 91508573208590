import React from 'react';

import AssessmentContainer from 'modules/Common/containers/AssessmentContainer';

import { Styled } from './CareerJobAssessment.styled';

const CareerJobAssessment = () => {
	return (
		<Styled.Root>
			<AssessmentContainer />
		</Styled.Root>
	);
};

export default CareerJobAssessment;
