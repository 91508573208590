import { FC } from 'react';

import { Select } from 'antd';

import Box from 'components/Box';
import FormSwitch from 'components/FormSwitch';
import ClipIcon from 'components/SVG/ClipIcon';
import DownloadIcon from 'components/SVG/DownloadIcon';
import TicketNotes from 'modules/Common/components/TicketNotes';
import { IColorProps, ITicketDetails, IUser } from 'modules/Common/types';
import { getColorProps } from 'modules/Common/utils/commonHelpers';
import { convertContentToHtml } from 'modules/Common/utils/editorHelper';
import { COLORS } from 'theme';
import { IOption } from 'types';
import { handleFileDownload } from 'utils/helpers';

import { Styled } from './TicketDetails.styled';

const { Option } = Select;

interface IStyles {
	styles: IColorProps;
}

type TicketDetailsProps = {
	ticketData: ITicketDetails;
	clientContextId?: string;
	ticketStatuses: IOption[];
	user: IUser;
	handleUpdateTicketStatus: (statusId: number) => void;
};

const TicketDetails: FC<TicketDetailsProps> = ({
	ticketData,
	clientContextId = '',
	ticketStatuses,
	user,
	handleUpdateTicketStatus,
}) => {
	const descStartIndex = ticketData?.description.indexOf('Job description: ') + 17;
	const descLastIndex1 = ticketData?.description.lastIndexOf('>') + 1;
	const descLastIndex2 = ticketData?.description.lastIndexOf('}}') + 2;
	const descLastIndex = descLastIndex1 > descLastIndex2 ? descLastIndex1 : descLastIndex2;
	const descAssessmentIndexStart = ticketData?.description.indexOf('Assessment:');
	const descStringFirstPart = ticketData?.description.slice(0, descStartIndex);
	const descStringSecondtPart = ticketData?.description.slice(
		descLastIndex,
		descAssessmentIndexStart,
	);
	const richDescriptionString = ticketData?.description.slice(descStartIndex, descLastIndex);

	const descriptionHtml = richDescriptionString && convertContentToHtml(richDescriptionString);
	const { attachments, activities } = ticketData;

	const ticketStatusesWithColor = ticketStatuses?.map((item) => {
		return {
			...item,
			styles: getColorProps(item.name || ''),
		};
	});

	const getColorsForSelect = (id: number) => {
		return ticketStatusesWithColor?.find((item) => item.id === id)?.styles;
	};

	const handleStatusChange = (value: unknown) => {
		if (ticketData.ticketStatus.id === value) return;
		handleUpdateTicketStatus(value as number);
	};

	return (
		<Styled.Root>
			<Styled.BoxWrap isBoxComponent={!clientContextId}>
				<Styled.Header>
					<Styled.HeaderMain>
						<Styled.Title>{`${ticketData?.client?.name}, ${ticketData?.subject}`}</Styled.Title>
						<Styled.SubTitle>{`Submited by ${ticketData?.user}`}</Styled.SubTitle>
					</Styled.HeaderMain>
					<Styled.HeaderAdditional>
						<FormSwitch
							name='visibleToClient'
							value={ticketData.visibleToClient}
							title='Visible to Client'
							disabled
						/>
						<Styled.Select
							value={ticketData.ticketStatus.name || null}
							placeholder='Choose ticket status'
							{...getColorsForSelect(ticketData.ticketStatus.id)}
							onChange={handleStatusChange}
						>
							{ticketStatusesWithColor?.map((option: IOption & IStyles) => (
								<Option key={option.id} value={option.id} style={{ color: option.styles.color }}>
									{option.name}
								</Option>
							))}
						</Styled.Select>
					</Styled.HeaderAdditional>
				</Styled.Header>
				<Styled.FieldRow>
					<Styled.FieldWrapper>
						<Styled.FieldLabel>Client / Company Name</Styled.FieldLabel>
						<Styled.Field>
							<span>{ticketData?.client?.name}</span>
						</Styled.Field>
					</Styled.FieldWrapper>
					<Styled.FieldWrapper>
						<Styled.FieldLabel>Type</Styled.FieldLabel>
						<Styled.Field>
							<span>{ticketData.ticketType.name}</span>
						</Styled.Field>
					</Styled.FieldWrapper>
					<Styled.FieldWrapper>
						<Styled.FieldLabel>Subject</Styled.FieldLabel>
						<Styled.Field>
							<span>{ticketData.subject}</span>
						</Styled.Field>
					</Styled.FieldWrapper>
				</Styled.FieldRow>
				<Styled.FieldWrapper>
					<Styled.FieldLabel>Description</Styled.FieldLabel>
					<Styled.Field isTransparent>
						{descriptionHtml ? (
							<span>
								{descStringFirstPart}
								<Styled.RichDescription>{descriptionHtml}</Styled.RichDescription>
								{descStringSecondtPart}
							</span>
						) : (
							<span>{ticketData.description}</span>
						)}
					</Styled.Field>
					{ticketData.assessmentQuestions !== null && (
						<Styled.Field isTransparent>
							<div>Assessment: {ticketData.assessmentQuestions.length ? 'YES' : 'NO'}</div>
						</Styled.Field>
					)}
				</Styled.FieldWrapper>
				<>
					{attachments?.map(({ originalFileName, referenceUuid }) => {
						return (
							<Styled.Attachment
								key={referenceUuid}
								onClick={() => handleFileDownload(referenceUuid, originalFileName)}
							>
								<Styled.AttachmentFileName>
									<ClipIcon fill={COLORS.darkGray2} width='22' height='22' />
									{originalFileName}
								</Styled.AttachmentFileName>
								<DownloadIcon fill={COLORS.darkGray2} width='22' height='22' />
							</Styled.Attachment>
						);
					})}
				</>
			</Styled.BoxWrap>
			{activities.length > 0 && (
				<Box>
					<TicketNotes notes={activities} user={user} />
				</Box>
			)}
		</Styled.Root>
	);
};

export default TicketDetails;
