import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.section`
		h2 {
			margin-bottom: 32px;
		}
	`;

	export const GridContainer = styled.div`
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 32px;
		margin: 0 auto;
		padding-bottom: 24px;
	`;

	export const FieldWrapper = styled.div`
		height: 100%;

		.ant-select {
			width: 100%;
		}
	`;
}
