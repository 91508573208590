import type { FC } from 'react';

import { Spin } from 'antd';

import { S } from './Button.styled';
import { IButton } from './Button.types';

const Button: FC<IButton> = ({
	buttonType,
	icon,
	onClick = () => null,
	children,
	loading,
	isFullWidth = false,
	...props
}) => {
	return (
		<S.Button
			{...props}
			buttonType={buttonType}
			isFullWidth={isFullWidth}
			onClick={onClick}
			onlyIcon={icon && !children}
		>
			{loading ? (
				<Spin />
			) : (
				<>
					{icon && children ? <span>{icon}</span> : icon}
					{children}
				</>
			)}
		</S.Button>
	);
};

export { Button };
