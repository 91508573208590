import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';

import { INavListItem } from 'modules/Common/types';

import { S } from './MainMenu.styled';

type MainMenuType = {
	items: INavListItem[];
	isHrModule?: boolean;
	highlightColor?: string;
};

const MainMenu: React.FC<MainMenuType> = ({ items, isHrModule, highlightColor }) => {
	return (
		<S.Root>
			<S.MainMenu>
				{items?.map((item: INavListItem) => (
					<S.MenuItem key={item.id} highlightColor={highlightColor} isHrModule={isHrModule}>
						<NavLink to={item.path}>{item.name}</NavLink>
					</S.MenuItem>
				))}
			</S.MainMenu>
		</S.Root>
	);
};

export default memo(MainMenu);
