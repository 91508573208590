import React from 'react';

import { ReactParent } from 'types';

import { Styled } from './FormBlockGrid.styled';

interface IFormBlockLine extends ReactParent {
	columns?: number;
}

export const FormBlockGrid: React.FC<IFormBlockLine> = ({ columns = 3, children }) => (
	<Styled.FieldsBlock columns={columns}>{children}</Styled.FieldsBlock>
);
