import { type FC } from 'react';

import AjustColorPicker from 'modules/Common/components/AjustColorPicker';
import { BrandingMode } from 'modules/Common/components/BrandingContainer/BrandingContainer.types';
import BrandingImgUploader from 'modules/Common/components/BrandingImgUploader';
import CareerPage from 'modules/Common/containers/CareerPage';

import { Styled } from './CareerBrandingInner.styled';

type CareerBrandingInnerProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};

const CareerBrandingInner: FC<CareerBrandingInnerProps> = ({
	ajustColorData,
	applyColors,
	resetColors,
	backgroundImage,
	bgUploaderProps,
	logoUploaderProps,
	onBgRemove,
	jobsData,
	clientName,
	clientId,
	logo,
	whyOurCompany,
	changewhyOurCompany,
	benefits,
	changeBenefits,
	socialNetworksData,
	changevideoLink,
	changeClientAddress,
	clientAddress,
	colors,
	videoLink,
	mode,
	setBgUrl,
}) => {
	return (
		<>
			{mode === BrandingMode.Edit && (
				<Styled.ColorPickerWrapper>
					<AjustColorPicker
						ajustColorData={ajustColorData}
						applyColors={applyColors}
						resetColors={resetColors}
					/>
				</Styled.ColorPickerWrapper>
			)}
			<Styled.Root>
				{!backgroundImage && mode === BrandingMode.Edit && (
					<Styled.BgUploader>
						<BrandingImgUploader isCropped setBgUrl={setBgUrl} {...bgUploaderProps} />
					</Styled.BgUploader>
				)}

				{mode === BrandingMode.Edit && (
					<Styled.LogoUploader>
						<BrandingImgUploader {...logoUploaderProps} />
					</Styled.LogoUploader>
				)}

				<CareerPage
					mode={mode}
					backgroundImage={backgroundImage}
					onBgRemove={onBgRemove}
					jobsData={jobsData}
					clientName={clientName}
					logo={logo}
					clientId={clientId}
					whyOurCompany={whyOurCompany}
					changewhyOurCompany={changewhyOurCompany}
					benefitsData={benefits}
					videoLink={videoLink}
					changeBenefits={changeBenefits}
					socialNetworksData={socialNetworksData}
					changevideoLink={changevideoLink}
					changeClientAddress={changeClientAddress}
					clientAddress={clientAddress}
					highlightColour={colors.highlightColour}
				/>
			</Styled.Root>
		</>
	);
};

export default CareerBrandingInner;
