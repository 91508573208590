import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const BenefitsWrapper = styled.div`
		padding: 64px 72px 8px;
		position: relative;

		@media screen and (max-width: 450px) {
			padding: 0 16px;
		}
	`;
	export const BenefitsTitle = styled.div`
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 700;
		font-size: 36px;
		line-height: 40px;
		letter-spacing: 0.01em;
		color: ${COLORS.black};
		padding-bottom: 40px;
		width: 100%;
		text-align: center;

		@media screen and (max-width: 450px) {
			font-size: 24px;
			line-height: 28px;
			padding-bottom: 6px;
		}
	`;
	export const BenefitsControl = styled.div`
		position: absolute;
		top: 65px;
		right: 30px;
	`;
	export const BenefitsFormWrapper = styled.div`
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	`;
	export const BenefitsForm = styled.div`
		width: 350px;
		display: flex;
		align-items: center;
		flex-direction: column;
	`;
	export const BenefitsEmojiPicker = styled.div`
		width: 100%;
		margin-top: 16px;
	`;
	export const SelectedEmoji = styled.div`
		width: 100%;
		text-align: start;
		margin-bottom: 16px;
	`;
	export const BenefitsFormSubmit = styled.div`
		padding-top: 32px;
		width: 100%;
		display: flex;
		justify-content: flex-end;

		button {
			margin-left: 16px;
		}
	`;

	export const BenefitsItems = styled.div`
		align-content: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	`;
	export const BenefitsItem = styled.div`
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 24px 16px;
		position: relative;
		width: 300px;
		background: ${COLORS.white};
		border: 1px solid ${COLORS.lightGray2};
		box-shadow: 0px 2px 4px rgba(76, 98, 140, 0.12);
		border-radius: 12px;
		margin: 0 32px 32px 0;

		@media screen and (max-width: 450px) {
			margin: 10px 0;
		}
	`;
	export const BenefitsItemLogo = styled.div`
		padding-bottom: 24px;
	`;
	export const BenefitsItemTitle = styled.div`
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
		letter-spacing: -0.011em;
		color: ${COLORS.black};
	`;
	export const BenefitsItemRemove = styled.div`
		position: absolute;
		top: 15px;
		right: 15px;
	`;
}
