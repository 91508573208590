import React, { FC } from 'react';
import { Field } from 'react-final-form';

import { Input } from 'antd';

import FieldWrapper from 'components/FieldWrapper';
import { FieldInputType } from 'modules/Common/types';

const FieldInput: FC<FieldInputType> = ({
	name,
	label,
	placeholder,
	hint,
	onBlur,
	validate,
	disabled = true,
}) => {
	return (
		<Field name={name} validate={validate}>
			{({ input, meta }) => (
				<FieldWrapper
					name={name}
					label={label}
					hint={hint}
					errorMessage={meta.submitFailed && meta.touched && meta.error}
				>
					<Input
						{...input}
						onBlur={(e) => onBlur && onBlur(e)}
						placeholder={placeholder}
						autoComplete='off'
						disabled={disabled}
					/>
				</FieldWrapper>
			)}
		</Field>
	);
};

export default FieldInput;
