import styled from 'styled-components/macro';

import { COLORS, device } from 'theme';

interface INavItemProps {
	itemColor?: string;
	isExtended?: boolean;
}

export namespace Styled {
	export const Root = styled.nav``;

	export const NavMenu = styled.ul`
		display: flex;
		align-items: start;
		justify-content: space-between;
		flex-direction: column;
		gap: 20px;
		list-style: none;

		@media ${device.mobile} {
			gap: 10px;
		}
	`;

	export const NavItem = styled.li<INavItemProps>`
		a {
			display: block;
			color: ${({ theme }) => theme.colors.white};
			transition: color ease 0.2s;

			&.active,
			&.active svg path {
				${({ itemColor }) => `
        	color: ${itemColor};
					fill: ${itemColor};
        `};
			}

			span {
				transition: color ease 0.2s;
			}

			&:hover,
			&:focus,
			&.active,
			&:hover span,
			&:focus span,
			&:active span,
			&:hover svg path,
			&:focus svg path {
				${({ itemColor, theme }) => `
        	color: ${itemColor ?? theme.colors.lightBlue1};
					fill: ${itemColor ?? COLORS.lightBlue1};
        `};
			}
		}
	`;

	export const ItemName = styled.span<INavItemProps>`
		display: ${({ isExtended }) => (isExtended ? 'block' : 'none')};
		margin-left: 20px;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		white-space: nowrap;

		@media ${device.desktop} {
			display: none;
		}
	`;

	export const NavItemBox = styled.div`
		display: flex;
		align-items: center;
	`;
}
