import { Input as InputAnt } from 'antd';
import styled from 'styled-components/macro';

import { device } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		position: relative;
	`;
	export const Header = styled.div`
		padding-bottom: 24px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
	`;
	export const ExtraControls = styled.div`
		display: flex;
		input {
			margin-right: 16px;
		}
	`;

	export const Input = styled(InputAnt)`
		margin-right: 16px;
		width: 376px;
		@media ${device.desktop} {
			width: auto;
		}
	`;
}
