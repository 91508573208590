import React, { FC, useCallback, useState } from 'react';

import FieldWrapper from 'components/FieldWrapper';
import EyeCloseIcon from 'components/SVG/EyeCloseIcon';
import EyeOpenIcon from 'components/SVG/EyeOpenIcon';
import { Fields, IInputProps, SizeTypes } from 'types';

import { Styled } from './UnregisteredInput.styled';

export const UnregisteredInput: FC<IInputProps> = ({
	name,
	type = Fields.TEXT,
	size = SizeTypes.medium,
	onChange,
	placeholder,
	errorMessage,
	isFullWidth = false,
	...props
}) => {
	const [isVisibleToggle, setIsVisibleToggle] = useState(false);

	const handleToggle = useCallback(() => {
		setIsVisibleToggle((curState) => !curState);
	}, []);

	return (
		<FieldWrapper errorMessage={errorMessage} isFixed>
			<>
				<Styled.Input
					{...props}
					id={name}
					type={isVisibleToggle ? Fields.TEXT : type}
					sizeField={size}
					autoComplete='off'
					aria-invalid={!!errorMessage}
					isError={!!errorMessage}
					onChange={onChange}
					placeholder={placeholder}
					isFullWidth={isFullWidth}
				/>
				{type === Fields.PASSWORD && (
					<Styled.Button onClick={handleToggle}>
						{isVisibleToggle ? <EyeOpenIcon /> : <EyeCloseIcon />}
					</Styled.Button>
				)}
			</>
		</FieldWrapper>
	);
};
