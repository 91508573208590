import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CheckoutForm from './CheckoutForm';

const REACT_APP_STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);

export const StripeForm = ({ clientSecret, total, currency, redirectUrl }) => {
	const options = {
		clientSecret,
	};

	return (
		<Elements stripe={stripePromise} options={options}>
			<CheckoutForm total={total} currency={currency} redirectUrl={redirectUrl} />
		</Elements>
	);
};
