import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { UserAccessFormContainer } from 'modules/BackOffice/containers/UserAccessFormContainer/UserAccessFormContainer';
import { UserAccessFormContainerTypeProps } from 'modules/BackOffice/containers/UserAccessFormContainer/UserAccessFormContainer.types';
import { Routes } from 'types';

import { ClntContext } from '../../../containers/ClientContextContainer/ClientContextContainer';

export const ClientContextUserAccessFormPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const { clientId } = useParams();
	const clientStore = useContext(ClntContext)[clientId];

	const userRoute = `${Routes.BOClientContext}/${clientId}${Routes.Users}`;

	const state: Partial<UserAccessFormContainerTypeProps> = {
		loading: useSelector(clientStore?.backOfficeSelectors?.backOfficeLoading),
		userRoles: useSelector(clientStore?.backOfficeSelectors?.getBackOfficeUserRoles),
		currentUser: useSelector(clientStore?.backOfficeSelectors?.getBackOfficeCurrentUser),
		userManagers: useSelector(clientStore?.backOfficeSelectors?.getUserManagers),
	};

	const actions: Partial<UserAccessFormContainerTypeProps> = {
		deActivateUser: (userId, isActive) =>
			dispatch(
				clientStore?.backOfficeActions?.activationUserRequested(userId, isActive, true, () =>
					navigate(userRoute),
				),
			),
		getUserRoles: (value) =>
			dispatch(clientStore?.backOfficeActions?.getBackOfficeUserRolesRequested(value)),
		getUserById: (userId) =>
			dispatch(clientStore?.backOfficeActions?.getBackOfficeUserByIdRequested(userId, true)),
		updateUser: (values) =>
			dispatch(
				clientStore?.backOfficeActions?.updateBackOfficeUserRequested(values, true, () =>
					navigate(userRoute),
				),
			),
		createUser: (values) =>
			dispatch(
				clientStore?.backOfficeActions?.createBackOfficeUserRequested(
					{ ...values, client: clientId },
					true,
					() => navigate(userRoute),
				),
			),
	};

	const isEditMode = location?.pathname?.includes(`${userRoute}${Routes.Edit}`);

	return (
		<UserAccessFormContainer
			isClientContext
			{...state}
			{...actions}
			navigationLink={userRoute}
			isEditMode={isEditMode}
		/>
	);
};

export default ClientContextUserAccessFormPage;
