import Cookies from 'js-cookie';

import axiosInstance, { get, put, del, patch } from 'api/axiosInstance';
import { CookiesType, RequestsEnum } from 'types';
import { qs, getTableParams } from 'utils/helpers';

const baseUrl = process.env.REACT_APP_API_URL;

export const createContract = async (data) => {
	const response = await axiosInstance({
		url: `${baseUrl}/contract`,
		data,
		method: 'POST',
		headers: {
			'X-Authorization': `Bearer ${Cookies.get(CookiesType.JWT)}`,
		},
	});

	return response?.data;
};

export const acceptContractByClientId = async (clientId) => {
	const response = await axiosInstance({
		url: `${baseUrl}/contract/${clientId}`,
		method: 'PATCH',
		headers: {
			'X-Authorization': `Bearer ${Cookies.get(CookiesType.JWT)}`,
		},
	});

	return response?.data;
};

export const getContracts = async ({ page, ...rest }) => {
	const endpoint = rest?.client
		? `${RequestsEnum.ContractBackofficeClientContext}${getTableParams(page, rest)}`
		: `${RequestsEnum.ContractBackOffice}${getTableParams(page, rest)}`;

	return get(endpoint);
};

export const getAtsContracts = async ({ page, ...rest }) =>
	get(`${RequestsEnum.ContractATS}${getTableParams(page, rest)}`);

export const getContractStatuses = async () => {
	const response = await axiosInstance({
		url: `${baseUrl}/contract/state`,
		method: 'GET',
		headers: {
			'X-Authorization': `Bearer ${Cookies.get(CookiesType.JWT)}`,
		},
	});

	return response?.data;
};

export const updateContractStatus = async ({ contractId, data }) => {
	const response = await axiosInstance({
		url: `${baseUrl}/contract/${contractId}/state`,
		method: 'PATCH',
		headers: {
			'X-Authorization': `Bearer ${Cookies.get(CookiesType.JWT)}`,
		},
		data,
	});

	return response?.data;
};

export const getContractsByClientId = async ({ clientId, params }) => {
	const queryParams = qs(params);

	return get(`/contract/client/${clientId}${queryParams}`);
};

export const getContractsCSV = async (clientContextId) => {
	const endpoint = clientContextId
		? `${RequestsEnum.ContractBackofficeClientContextCSV}/${clientContextId}`
		: `${RequestsEnum.ContractCSV}`;

	return get(endpoint);
};

export const getContractVat = async () => {
	const response = await axiosInstance({
		url: `${baseUrl}/contract/vat`,
		method: 'GET',
		headers: {
			'X-Authorization': `Bearer ${Cookies.get(CookiesType.JWT)}`,
		},
	});

	return response?.data;
};

export const getContractReceipt = async ({ platform, id }) =>
	get(`/contract/${platform}/${id}/receipt`);

export const getATSContractById = async (contractId) =>
	get(`${RequestsEnum.ContractATS}/${contractId}`);

export const getBoClientContractsById = async (clientI) =>
	get(`${RequestsEnum.ContractBoClient}/${clientI}`);

export const getBackOfficeContractById = async (contractId) =>
	get(`${RequestsEnum.ContractBackOffice}/${contractId}`);

export const updateBackOfficeContractById = async ({ contractId, updatedData }) =>
	put(`${RequestsEnum.ContractBackOffice}/${contractId}`, updatedData);

export const approvePendingContract = async (id) =>
	patch(`${RequestsEnum.ContractBackOffice}/${id}${RequestsEnum.Approve}`);

export const deleteContractById = async (contractId) =>
	del(`${RequestsEnum.Contract}/${contractId}`);

const contractsService = {
	getATSContractById,
	createContract,
	acceptContractByClientId,
	getContracts,
	getAtsContracts,
	getContractsCSV,
	getContractStatuses,
	updateContractStatus,
	getContractsByClientId,
	getContractVat,
	getContractReceipt,
	getBackOfficeContractById,
	updateBackOfficeContractById,
	approvePendingContract,
	deleteContractById,
	getBoClientContractsById,
};

export default contractsService;
