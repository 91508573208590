export const MESSAGES = {
	passwordNotMatch: 'Passwords don’t match',
	weakPassword:
		'Weak password. Please make sure that your password contains at least 8 characters, one uppercase letter, one lowercase letter, and one special character',
	mandatoryField: 'This field is mandatory. Please enter a valid value',
	textFieldValidation: 'Please enter a valid value for this field',
	incorrectValue: 'You have identical answers. Please ensure that each answer is unique',
	incorrectEmailValue: 'You have identical email. Please ensure that email is not repeated',
	companyDetailsUpdated: 'Your settings have been updated',
	companyDetailsError: 'Your settings haven’t been updated',
	validateEmailMessage: 'Please enter valid email',
	validatePhoneMessage: 'Please enter valid phone number',
	validateSMSMessage: 'Please enter valid sms message',
	validateEndDate: 'End date can not be earlier or equal to the start date',
	imageUploadErrorText:
		'Sorry, we couldn’t upload your file. Please make sure it’s the PNG, JPEG or SVG image and it’s under 15 Mb',
	editCountryNonUK: 'Important! All available credits will be wiped',
	addParentCompany: 'Important! Both companies’ credits will be merged',
	changeParentCompany:
		'Important! The company’s available credits will change to match the new parent company',
	removeParentCompany: 'Important! All available credits will wiped',
	successfulAcceptedContract:
		'You’ve successfully accepted the contract. Credits are added to your account',
	successfullyUpdatedContract: 'The contract has been updated',
	successfullyCreated: 'Created successfully!',
	successfullyDeletedContract: 'The contracts has been deleted',
	successfullyCanceled: 'Canceled successfully!',
	successfullySaved: 'Saved successfully!',
	successfullySent: 'Sent successfully!',
	successfullySentApplication:
		'Thank you, your application has been submitted and will shortly be processed. Good luck!',
	successfullySentMessage:
		'Your message has been sent successfully and we will be in touch shortly',
	successfullySentApplicationGetInTouch:
		'Your application has been sent. We will reach out to you once we have a relevant position that matches your skills and experience',
	successfullyUpdated: 'Updated successfully!',
	successfullyArchived: 'Archived successfully!',
	successfullyUnarchived: 'Unarchived successfully!',
	successfullyDeleted: 'Deleted successfully!',
	successfullyDeletedInvoice: 'The invoice has been deleted',
	successfullySentInvoice: 'The invoice has been sent',
	successfullyUpdatedInvoice: 'The invoice has been updated',
	successfullyAssigned: 'Assigned successfully!',
	successfullySavedChanges: 'Changes have been saved!',
	successfullyDeclinedInterview: 'Interview declined successfully!',
	successfullySavedSelfJobFreePlan: 'Thank you! Your job has been created',
	successfullySavedSelfJobPremiumPlan:
		'Thank you! Your job has been created and posted to your Careers Page',
	successfullyUpdatedJobFreePlan: 'Your job has been updated',
	successfullyUpdatedJobPremiumPlan:
		'Thank you! Your job has been updated and published to Careers Page',
	successfullyUpdatedRejectionMessage: 'The rejection message for this job has been updated',
	successfullySavedPaidJob:
		'Thank you! Your request has been sent and one of our account managers will shortly be in touch',
	userDeleted: 'The user has been de-activated!',
	userActivated: 'The user has been activated!',
	questionnaireOutstandingMessage:
		'Any outstanding questionnaires will NOT be updated with the new questions. In\n' +
		'order to update previously sent questionnaires please recall and send the questionnaire\n' +
		'again to a candidate.',
	successfullyDeletedQuestionnaire: 'The questionnaire has been deleted',
	successfullyUpdatedCandidate: 'Candidates have been successfully updated',
	successfullyUpdatedCandidateStatus: 'Candidate status updated',
	successfullySentQuestionnaire: 'The questionnaire has been sent',
	failedSentQuestionnaireWrongEmail:
		'The questionnaire has failed to be sent due to invalid candidate’s email.',
	successfullyReSentQuestionnaire: 'The questionnaire has been re-sent',
	successfullyRecalledQuestionnaire: 'The questionnaire has been recalled',
	successfullyCreatedATSTicket:
		'Thank you! Your ticket has been created. Our support team will review your ticket and provide assistance as soon as possible',
	genericErrorMessage: 'Something went wrong. Please try again later',
	successfullyCreatedBackOfficeTicket: 'Thank you! Your ticket has been created.',
	successfullyRemovedAttachment: 'Attachment removed!',
	successfullyPaySubscription:
		'Congratulations! You now have access to unlimited branding options, HR module, candidate database and much more!',
	successfullyUpdatedCard: 'Card has been updated successfully',
	warningNotFoundClients:
		'Sorry, no results found for your search query. Try refining your search criteria to find the required information',
	noCandidatesData: 'There are no candidates yet',
	successfullySavedSocialNetworks: 'Social networks saved.',
	successfullySavedPersonalData: 'You’ve successfully updated your personal data',
	successfullyBoostedJob:
		'Thank you! Your request has been sent and the job is in a process of boosting.',
	successfullyCreatedEmployeeAccount: 'The employee’s account has been created!',
	successfullyArchivedEmployee: 'The employee has been archived!',
	successfullyUnArchivedEmployee: 'The employee has been Unarchived!',
	successfullyUpdatedEmployeeStatus: 'The employee status has been updated!',
} as const;
