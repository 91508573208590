import React, { type FC } from 'react';

import { Modal } from 'antd';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';

import { Styled } from './RecordingRequestModal.styled';

type RecordingRequestModalProps = {
	open: boolean;
	onCancel: () => void;
	onAccept: () => void;
};

export const RecordingRequestModal: FC<RecordingRequestModalProps> = ({
	open,
	onCancel,
	onAccept,
}) => {
	return (
		<Modal open={open} footer={null} centered closable={false} width='auto'>
			<Styled.Root>
				<Styled.ModalHead>
					<Styled.ModalTitle>Recorded Interview</Styled.ModalTitle>
				</Styled.ModalHead>
				<Styled.ModalContent>
					<Styled.ModalDescLine>
						The client would like to record this interview.
					</Styled.ModalDescLine>
					<Styled.ModalDescLine>Would you like to accept?</Styled.ModalDescLine>
				</Styled.ModalContent>
				<Styled.ModalButtons>
					<Button buttonType={ButtonTypes.primary} onClick={onAccept}>
						Accept
					</Button>
					<Button buttonType={ButtonTypes.tertiary} color='red' onClick={onCancel}>
						Decline Recording
					</Button>
				</Styled.ModalButtons>
			</Styled.Root>
		</Modal>
	);
};

export default RecordingRequestModal;
