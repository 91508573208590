import {
	DEFAULT_ATS_BG,
	DEFAULT_ATS_JOBS_PAGE_SIZE,
	DEFAULT_CAREER_BG,
	DEFAULT_CURRENT_PAGE,
	DEFAULT_PAGE_SIZE,
	DEFAULT_LOGO,
} from 'modules/Common/constants';

export const initialState = {
	settingsState: {
		companyDetailsData: null,
		defaultMessagesData: null,
		clientApplicationNotification: null,
		userAccessForm: {},
		socialNetworks: {},
		emailSignatureData: {},
		emailNotificationsData: {},
		smsNotificationsData: {},
		autoPopData: false,
		twoFA: false,
		personalData: {},
	},
	subscription: {
		currentSubscription: null,
		subscriptionTypes: [],
		subscriptionPlans: {
			isPremiumPlan: false,
		},
	},
	invoices: {
		data: [],
		pageIndex: DEFAULT_CURRENT_PAGE,
		pageSize: DEFAULT_PAGE_SIZE,
		totalCount: 0,
	},
	currentInvoice: null,
	tickets: null,
	ticketDetails: null,
	candidatesDatabase: null,
	candidateDatabaseDetails: null,
	credits: [],
	jobUpgrades: [],
	clientBrandingState: {
		clientAtsBrandingData: {
			backgroundImage: DEFAULT_ATS_BG,
			logo: DEFAULT_LOGO,
		},
		clientCareerBrandingData: {
			backgroundImage: DEFAULT_CAREER_BG,
			logo: DEFAULT_LOGO,
			removedBenefits: [],
		},
	},
	postJobChangesFormState: {
		data: {},
	},
	candidatesState: {
		candidateApplicationData: [],
		candidateAssessmentApplication: null,
		candidateNotes: [],
		allCandidateApplicationsByJob: [],
	},
	jobsState: {
		jobsData: {
			data: [],
			pageIndex: DEFAULT_CURRENT_PAGE,
			pageSize: DEFAULT_ATS_JOBS_PAGE_SIZE,
			totalCount: 0,
		},
		activeJobs: [],
		allJobsList: [],
		gravityJobs: {
			data: [],
			pageIndex: DEFAULT_CURRENT_PAGE,
			pageSize: DEFAULT_ATS_JOBS_PAGE_SIZE,
			totalCount: 0,
		},
		currentGravityJobCandidates: {
			data: [],
			pageIndex: DEFAULT_CURRENT_PAGE,
			pageSize: DEFAULT_ATS_JOBS_PAGE_SIZE,
			totalCount: 0,
		},
	},
	interviewState: {
		interviewTypes: [],
		interviewUsers: [],
		interviewJobsList: [],
		interviewEditJobsList: [],
		interviewCalendarList: [],
		interviewData: {},
		interviewVideoCount: {},
	},
	statistics: {
		candidateStatistics: [],
		applicationsStatistics: [],
		candidateApplicationsPerState: [],
		jobsMonthStatistic: null,
		acceptedOffersStatistics: null,
		applicationSourcesStatistics: null,
		timeToHireMonth: null,
		timeToHireYear: null,
		applicationsYear: null,
		applicationsMonth: null,
	},
	unappliedCandidateJobsState: [],
	atsUsers: {
		data: [],
		pageIndex: DEFAULT_CURRENT_PAGE,
		pageSize: DEFAULT_ATS_JOBS_PAGE_SIZE,
		totalCount: 0,
	},
	atsUserRoles: [],
	currentOrder: null,
	candidatesSMSInformation: [],
	emailsState: {
		dashboardUnreadEmails: {},
		emailTemplates: [],
		inboxThreads: {},
		currentEmailThread: null,
	},
};
