import {
	ApplicationFormFieldsEnum,
	AppMethodsEnum,
	FieldTypesEnum,
	RadioFieldOption,
} from 'modules/Common/types';

export const AppMethodsFreeData: RadioFieldOption[] = [
	{ label: 'CV and Cover Letter', value: AppMethodsEnum.cvAndCoverLetter },
	{
		label: 'CV and Cover Letter to my email address.',
		value: AppMethodsEnum.cvAndCoverLetterEmail,
		fields: [
			{
				id: '1',
				name: ApplicationFormFieldsEnum.applicationEmail,
				type: FieldTypesEnum.Email,
				placeholder: 'Email address',
				hint: 'Please note some spam filters will block incoming CV’s',
			},
		],
	},
	{
		label: 'Application Form',
		value: AppMethodsEnum.applicationForm,
		fields: [
			{
				id: '1',
				name: ApplicationFormFieldsEnum.ApplicationFormUrl,
				label: 'Application Form URL',
				placeholder: 'Email URL',
				type: FieldTypesEnum.Email,
				hint: 'Assessment forms cannot be completed with this application \nmethod so have been removed',
			},
		],
	},
];

export const AppMethodsPaidData: RadioFieldOption[] = [
	{ label: 'CV and Cover Letter', value: AppMethodsEnum.cvAndCoverLetter },
	{
		label: 'CV and Cover Letter to my email address.',
		value: AppMethodsEnum.cvAndCoverLetterEmail,
		fields: [
			{
				id: '1',
				name: ApplicationFormFieldsEnum.applicationEmail,
				type: FieldTypesEnum.Email,
				placeholder: 'Email address',
				hint: 'Please note some spam filters will block incoming CV’s',
			},
		],
	},
	{
		label: 'Application Form',
		value: AppMethodsEnum.applicationForm,
		fields: [
			{
				id: '1',
				name: ApplicationFormFieldsEnum.ApplicationFormAttachment,
				label: 'Upload Form',
				placeholder: 'Click to upload',
				type: FieldTypesEnum.File,
			},
			{
				id: '2',
				name: ApplicationFormFieldsEnum.ApplicationFormUrl,
				label: 'Application Form URL',
				placeholder: 'Email URL',
				type: FieldTypesEnum.Email,
				hint: 'Assessment forms cannot be completed with this application \nmethod so have been removed',
			},
		],
	},
];

export const ActivateJobOptions = [
	{ id: 1, name: 'Published', value: true },
	{ id: 2, name: 'Archived', value: false },
];
