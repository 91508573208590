import React from 'react';

import { Switch, SwitchProps } from 'antd';
import _ from 'lodash';

import { Styled } from './FormSwitch.styled';

interface IFormSwitch extends SwitchProps {
	title?: string;
	name: string;
	value: boolean;
	hint?: string;
	defaultChecked?: boolean;
}

export const FormSwitch: React.FC<IFormSwitch> = ({
	title,
	value,
	defaultChecked,
	hint,
	...rest
}) => {
	const state = _.isBoolean(value) ? value : defaultChecked;

	return (
		<Styled.FormSwitchWrapper>
			<Switch {...rest} checked={state} defaultChecked={state} />
			{title && (
				<Styled.FormSwitchTitle isDisabled={!!rest.disabled}>{title}</Styled.FormSwitchTitle>
			)}
			{hint && <Styled.Hint isDisabled={!!rest.disabled}>{hint}</Styled.Hint>}
		</Styled.FormSwitchWrapper>
	);
};
