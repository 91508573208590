import styled from 'styled-components/macro';

import { COLORS } from 'theme';

type JBLogoImageProps = {
	logoUrl: string;
	height: string;
};

export namespace Styled {
	export const JB = styled.div``;
	export const TextHint = styled.p`
		font-size: 16px;
	`;
	export const JBSection = styled.section`
		padding-top: 24px;
	`;
	export const JBSectionTitle = styled.div`
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		color: ${COLORS.black};
		margin-bottom: 8px;
	`;
	export const JBTitleHighlight = styled.span`
		color: ${COLORS.blue};
	`;
	export const JBLogos = styled.div`
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
		column-gap: 16px;
		row-gap: 8px;
	`;
	export const JBLogo = styled.div`
		height: 68px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0px, 7px;
		border-radius: 6px;
		background-color: ${COLORS.lightGray3};
	`;
	export const JBLogoImage = styled.div<JBLogoImageProps>`
		${({ logoUrl }) => `background: url(${logoUrl}) no-repeat;`};
		${({ height }) => `height: ${height};`};
		width: 96px;
	`;
	export const JBNote = styled.div`
		margin-top: 24px;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		color: ${COLORS.black};
	`;
}
