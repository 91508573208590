import type { FC } from 'react';

import Container from 'components/Container';
import { Direction } from 'components/Container/Container.types';
import { ReactParent } from 'types';

import { Styled } from './OpenPageLayout.styled';

const OpenPageLayout: FC<ReactParent> = ({ children }) => {
	return (
		<Styled.Root>
			<Container noSpaces direction={Direction.Row} fullWidth>
				<Styled.Main>
					<Container>
						<>{children}</>
					</Container>
				</Styled.Main>
			</Container>
		</Styled.Root>
	);
};

export default OpenPageLayout;
