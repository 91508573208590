import React, { FC, useState, useEffect, useRef } from 'react';

import { Participant, VideoQuality, type VideoPlayer } from '@zoom/videosdk';

import { usePrevious } from 'hooks';
import { GenericType } from 'types';

import { useParticipantsChange } from '../../VideoInterview.hooks';

import { Styled } from './VideoInterviewParticipants.styled';

type VideoInterviewParticipantsProps = {
	zoomClient: GenericType;
	stream: GenericType;
	setParticipants: (p: Participant[]) => void;
	participants: Participant[];
	listOfSpeakingParticipants: number[];
};

const VideoInterviewParticipants: FC<VideoInterviewParticipantsProps> = ({
	zoomClient,
	stream,
	setParticipants,
	participants,
	listOfSpeakingParticipants,
}) => {
	const videoPlayerListRef = useRef<Record<string, VideoPlayer>>({});
	const [subscribers, setSubscribers] = useState<number[]>([]);
	const previousSubscribers: number[] | null = usePrevious(subscribers);
	const previousParicipants: Participant[] | null = usePrevious(participants);
	const NUMBER_OF_PART_VIEW = 3;

	const sortActiveParticipantsFirst = (listOfParticipants: Participant[]) => {
		const speakingParticipants = [] as Participant[];
		const notActiveParticipants = [] as Participant[];

		listOfParticipants.forEach((user) => {
			if (listOfSpeakingParticipants.includes(user.userId)) {
				speakingParticipants.push(user);
			} else {
				notActiveParticipants.push(user);
			}
		});

		return [...speakingParticipants, ...notActiveParticipants];
	};

	const setVideoPlayerRef = (id: number, element: VideoPlayer | null) => {
		if (element) {
			videoPlayerListRef.current[`id_${id}`] = element;
		}
	};

	useParticipantsChange(
		zoomClient,
		(prt) => {
			let pageParticipants: Participant[] = [];

			if (prt.length > 0) {
				pageParticipants = prt
					.filter((user) => user.userId !== zoomClient.getSessionInfo().userId)
					.sort((user1, user2) => Number(user2.bVideoOn) - Number(user1.bVideoOn));
			}

			const sortedParticipants = sortActiveParticipantsFirst(pageParticipants);
			setParticipants(sortedParticipants);
			setSubscribers(pageParticipants.filter((user) => user.bVideoOn).map((u) => u.userId));
		},
		listOfSpeakingParticipants,
	);

	useEffect(() => {
		const addedUsers = subscribers.filter((user) => !(previousSubscribers || []).includes(user));
		const removedUsers = (previousSubscribers || []).filter((user) => !subscribers.includes(user));
		if (removedUsers.length > 0) {
			removedUsers.forEach((userId) => {
				stream?.detachVideo(userId);
			});
		}
		if (addedUsers.length > 0) {
			addedUsers.forEach((userId, idx) => {
				const attachment = videoPlayerListRef.current[`id_${userId}`];
				if (attachment) {
					stream?.attachVideo(userId, VideoQuality.Video_360P, attachment);
				}
			});
		}
	}, [subscribers, previousSubscribers, stream]);

	useEffect(() => {
		const cutList = (list: Participant[]): Participant[] => list.slice(0, NUMBER_OF_PART_VIEW);
		const cutedPrevParicipants: Participant[] | [] = cutList(previousParicipants || []);
		const cutedParticipants: Participant[] | [] = cutList(participants || []);
		const addedToViewParticipants: Participant[] | [] = cutedParticipants.filter(
			(user: { userId: number }) =>
				!cutedPrevParicipants.some(
					(prevUser: { userId: number }) => prevUser.userId === user.userId,
				),
		);
		const removedFromViewParticipants: Participant[] | [] = cutedPrevParicipants.filter(
			(user: { userId: number }) =>
				!cutedParticipants.some((prevUser: { userId: number }) => prevUser.userId === user.userId),
		);

		if (
			removedFromViewParticipants.length > 0 &&
			addedToViewParticipants.length > 0 &&
			cutedPrevParicipants?.length
		) {
			cutedPrevParicipants.forEach(({ userId }) => {
				stream?.detachVideo(userId);
			});
			cutedParticipants.forEach(({ userId }, idx) => {
				const attachment = videoPlayerListRef.current[`id_${userId}`];
				if (attachment) {
					stream?.attachVideo(userId, VideoQuality.Video_360P, attachment);
				}
			});
		}
	}, [participants, previousParicipants]);

	return (
		<>
			{participants?.slice(0, NUMBER_OF_PART_VIEW).map((user, idx) => (
				<Styled.Participant
					key={user.userId}
					participantsIdx={idx}
					participantIsSpeaking={listOfSpeakingParticipants.includes(user.userId)}
				>
					{user.bVideoOn ? (
						<Styled.EnableVideoTile>
							<Styled.ParticipantName>{user.displayName}</Styled.ParticipantName>
							<video-player
								ref={(element: VideoPlayer | null) => {
									setVideoPlayerRef(user.userId, element);
								}}
							/>
						</Styled.EnableVideoTile>
					) : (
						<Styled.DisabledVideoTile>
							<Styled.DisabledVideoTileName>{user.displayName}</Styled.DisabledVideoTileName>
						</Styled.DisabledVideoTile>
					)}
				</Styled.Participant>
			))}
			{participants.length > NUMBER_OF_PART_VIEW && (
				<Styled.HiddenNumberOfParticipants>
					+{participants.length - NUMBER_OF_PART_VIEW}
				</Styled.HiddenNumberOfParticipants>
			)}
		</>
	);
};

export default VideoInterviewParticipants;
