import React from 'react';

// import Button from 'components/Button';
// import { ButtonTypes } from 'components/Button/Button.types';
import InterviewCompleteIcon from 'components/SVG/InterviewCompleteIcon';

import { Styled } from './VideoInterviewComplete.styled';

const VideoInterviewComplete = () => {
	return (
		<Styled.Root>
			<Styled.Title>Video Interview</Styled.Title>
			<Styled.Content>
				<Styled.ContentInner>
					<InterviewCompleteIcon />
					<Styled.ContentTitle>Session Complete. Thank you!</Styled.ContentTitle>
					<Styled.ContentDescription>
						<Styled.ContentDescriptionLine>
							Your interview is complete.
						</Styled.ContentDescriptionLine>
						<Styled.ContentDescriptionLine>
							If you have any questions or comments, please contact us.
						</Styled.ContentDescriptionLine>
					</Styled.ContentDescription>
					{/* <Button buttonType={ButtonTypes.primary} onClick={handleDisconnect}>
						Disconnect
					</Button> */}
				</Styled.ContentInner>
			</Styled.Content>
		</Styled.Root>
	);
};

export default VideoInterviewComplete;
