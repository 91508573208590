import React, { type FC, memo, useEffect } from 'react';
import { Field, useForm } from 'react-final-form';

import { DatePicker, Input, Select } from 'antd';

import FieldWrapper from 'components/FieldWrapper';
import FieldEditor from 'modules/Common/components/FieldEditor';
import FormBlockGrid from 'modules/Common/components/FormBlockGrid';
import { DataFormatEnum, IOption } from 'types';
import { filterSelectOptions } from 'utils/helpers';
import {
	composeValidators,
	editorFieldValidator,
	requiredFieldValidator,
	textMaxLengthValidator,
} from 'utils/validators';

import { Styled } from '../JobChangesFormContainer.styled';

type MainInfoProps = {
	clientsData: IOption[];
	jobTypes: IOption[];
	employmentTypes: IOption[];
	educationTypes: IOption[];
	jobIndustries: IOption[];
	onChangeClient: (clientId: number) => void;
	isPaidJob: boolean;
	isPublished: boolean;
	isEditMode?: boolean;
	isClientContext?: boolean;
};

const { Option } = Select;

const MainInfo: FC<MainInfoProps> = ({
	clientsData,
	jobTypes,
	employmentTypes,
	educationTypes,
	jobIndustries,
	onChangeClient,
	isPaidJob = false,
	isPublished = false,
	isEditMode = false,
	isClientContext = false,
}) => {
	const dataFormat = DataFormatEnum.UK ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
	const form = useForm();

	// added to update form state in order to trigger correct validation for paid or free job
	useEffect(() => {
		form.change('jobTypeField', isPaidJob);
	}, [isPaidJob]);

	return (
		<>
			<FormBlockGrid>
				<Field name='clientId' validate={requiredFieldValidator}>
					{({ input, meta }) => (
						<FieldWrapper
							name='clientId'
							label='Client'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
							required
						>
							<Select
								{...input}
								value={+input.value || null}
								options={clientsData}
								onChange={(id) => {
									onChangeClient && onChangeClient(id);

									return input.onChange(id);
								}}
								fieldNames={{ label: 'name', value: 'id' }}
								filterOption={(inputValue, option) =>
									filterSelectOptions(inputValue, option, 'name')
								}
								disabled={isEditMode || isClientContext}
								placeholder='Select a client'
								showSearch
							/>
						</FieldWrapper>
					)}
				</Field>

				<Field name='jobStartDate'>
					{({ input, meta }) => (
						<FieldWrapper
							name='jobStartDate'
							label='Date start'
							hint={
								!isPublished && isPaidJob
									? 'Paid job start and end dates details can be managed only after posting to Broadbean'
									: ''
							}
						>
							<DatePicker
								{...input}
								format={dataFormat}
								disabled={isEditMode || isPaidJob}
								inputReadOnly={isPaidJob}
							/>
						</FieldWrapper>
					)}
				</Field>
				<>
					{isPaidJob && (
						<Field name='jobEndDate'>
							{({ input, meta }) => (
								<FieldWrapper name='jobEndDate' label='Date end'>
									<DatePicker
										{...input}
										format={dataFormat}
										disabled={isPaidJob && !isPublished}
										inputReadOnly={isPaidJob && !isPublished}
									/>
								</FieldWrapper>
							)}
						</Field>
					)}
				</>

				<Field name='referenceId'>
					{({ input, meta }) => (
						<FieldWrapper name='referenceId' label='Reference ID'>
							<Input
								{...input}
								autoComplete='off'
								placeholder='Auto-Generated'
								disabled={!isEditMode}
							/>
						</FieldWrapper>
					)}
				</Field>

				<Field
					name='title'
					validate={composeValidators(requiredFieldValidator, textMaxLengthValidator)}
				>
					{({ input, meta }) => (
						<FieldWrapper
							name='title'
							label='Title'
							required
							errorMessage={meta.submitFailed && meta.touched && meta.error}
						>
							<Input {...input} autoComplete='off' placeholder='' />
						</FieldWrapper>
					)}
				</Field>

				<Field name='jobType' validate={requiredFieldValidator}>
					{({ input, meta }) => (
						<FieldWrapper
							name='jobType'
							label='Job Type'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
							required
						>
							<Select {...input} value={input.value || null} placeholder='Select a job type'>
								{jobTypes?.map((option: IOption) => (
									<Option key={option.id} value={option.id}>
										{option.name}
									</Option>
								))}
							</Select>
						</FieldWrapper>
					)}
				</Field>
				<Field name='location' validate={requiredFieldValidator}>
					{({ input, meta }) => (
						<FieldWrapper
							name='location'
							label='Location'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
							required
						>
							<Input {...input} placeholder='' />
						</FieldWrapper>
					)}
				</Field>
				<Field name='salary' validate={requiredFieldValidator}>
					{({ input, meta }) => (
						<FieldWrapper
							name='salary'
							label='Salary'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
							required
						>
							<Input {...input} autoComplete='off' placeholder='' />
						</FieldWrapper>
					)}
				</Field>
				<Field name='jobIndustry' validate={isPaidJob ? requiredFieldValidator : undefined}>
					{({ input, meta }) => (
						<FieldWrapper
							name='jobIndustry'
							label='Industry'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
							required={isPaidJob}
						>
							<Select
								{...input}
								value={input.value || null}
								options={jobIndustries}
								filterOption={(inputValue, option) =>
									filterSelectOptions(inputValue, option, 'name')
								}
								fieldNames={{ label: 'name', value: 'id' }}
								placeholder='Select a job industry'
								showSearch
								allowClear
							/>
						</FieldWrapper>
					)}
				</Field>
				<Field name='experienceLevel' validate={isPaidJob ? requiredFieldValidator : undefined}>
					{({ input, meta }) => (
						<FieldWrapper
							name='experienceLevel'
							label='Experience Level'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
							required={isPaidJob}
						>
							<Input {...input} placeholder='e.g., Entry-level position' />
						</FieldWrapper>
					)}
				</Field>
				<Field name='educationType' validate={isPaidJob ? requiredFieldValidator : undefined}>
					{({ input, meta }) => (
						<FieldWrapper
							name='educationType'
							label='Education'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
							required={isPaidJob}
						>
							<Select
								{...input}
								value={input.value || null}
								placeholder='Select education level'
								allowClear
							>
								{educationTypes?.map((option: IOption) => (
									<Option key={`education-${option.id}`} value={option.id}>
										{option.name}
									</Option>
								))}
							</Select>
						</FieldWrapper>
					)}
				</Field>
				<Field name='employmentType' validate={isPaidJob ? requiredFieldValidator : undefined}>
					{({ input, meta }) => (
						<FieldWrapper
							name='employmentType'
							label='Employment Type'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
							required={isPaidJob}
						>
							<Select
								{...input}
								value={input.value || null}
								placeholder='Select employment type'
								allowClear
							>
								{employmentTypes?.map((option: IOption) => (
									<Option key={`employment-${option.id}`} value={option.id}>
										{option.name}
									</Option>
								))}
							</Select>
						</FieldWrapper>
					)}
				</Field>
			</FormBlockGrid>
			<Styled.AdditionalRow>
				<Field name='jobDescription' validate={editorFieldValidator}>
					{({ input, meta }) => {
						return (
							<FieldWrapper
								name='jobDescription'
								label='Job Description'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
								required
								isFixed
							>
								<>
									<FieldEditor
										onChange={(value) => input.onChange(value)}
										defaultValue={input.value}
										placeholder='Please provide a brief overview of the position'
										minRaws={20}
									/>
								</>
							</FieldWrapper>
						);
					}}
				</Field>
			</Styled.AdditionalRow>
		</>
	);
};

export default memo(MainInfo);
