import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const ActionButtons = styled.div`
		display: flex;
		justify-content: center;
		gap: 8px;

		button {
			width: 28px;
			height: 28px;
		}
	`;
	export const SelectWrapper = styled.div`
		text-align: center;
		.ant-select-selector {
			height: 40px !important;
			width: 220px;
			display: flex;
			align-items: center;
		}
		.ant-select-selection-overflow {
			justify-content: center;
			flex-wrap: nowrap;
		}
		.ant-select-selection-item {
			background-color: ${COLORS.white};
			font-weight: bold;
		}
	`;
	export const CandidateName = styled.div`
		height: auto;
		padding: 0;
		line-height: 28px;
		font-weight: 500;
		font-size: 16px;
		white-space: nowrap;
		width: 292px;
		overflow: hidden;
		text-overflow: ellipsis;
	`;
}
