import React, { ChangeEvent, type FC, forwardRef } from 'react';

import { Input, Spin } from 'antd';

import SearchIcon from 'components/SVG/SearchIcon';
import { COLORS } from 'theme';

import { Styled } from './SearchField.styled';

type SearchFieldProps = {
	onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	defaultValue?: string;
	isLoading?: boolean;
};

const SearchField: FC<SearchFieldProps> = forwardRef<HTMLInputElement, SearchFieldProps>(
	({ onSearch, isLoading = false, defaultValue, placeholder = 'Search' }, ref) => {
		return (
			<Styled.SearchField>
				<Styled.Icon>
					<SearchIcon fill={COLORS.darkGray1} />
				</Styled.Icon>
				<Input
					name='search'
					ref={ref}
					onChange={onSearch}
					placeholder={placeholder}
					defaultValue={defaultValue}
					autoComplete='off'
				/>
				<Styled.Loader>{isLoading ? <Spin style={{ marginLeft: '12px' }} /> : ''}</Styled.Loader>
			</Styled.SearchField>
		);
	},
);

SearchField.displayName = 'SearchField';

export { SearchField };
