import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function MembersIcon({ fill = COLORS.darkGray4, width = '24', height = '24' }: IconType) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			fill='none'
			viewBox='0 0 24 24'
		>
			<path
				fill={fill}
				fillRule='evenodd'
				d='M13 7a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm-2 0a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM21 8a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zm-2 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM4.632 13.5A2.632 2.632 0 002 16.132v1.265c0 1.282.745 2.447 1.91 2.984a10.955 10.955 0 009.18 0A3.286 3.286 0 0015 17.397v-1.265a2.632 2.632 0 00-2.632-2.632H4.632zm0 2a.632.632 0 00-.632.632v1.265c0 .502.292.958.747 1.168a8.955 8.955 0 007.506 0c.455-.21.747-.666.747-1.168v-1.265a.632.632 0 00-.632-.632H4.632z'
				clipRule='evenodd'
			></path>
			<path
				fill={fill}
				d='M17 13.5a1 1 0 100 2h2.722c.153 0 .278.124.278.278v.744c0 .587-.355 1.116-.898 1.338l-.092.038c-.942.387-2.126.706-2.126.706A1 1 0 0017 20.59c.54 0 1.902-.455 2.075-.535.224-.102.476-.218.786-.345A3.447 3.447 0 0022 16.52v-.743a2.278 2.278 0 00-2.278-2.278H17z'
			></path>
		</svg>
	);
}

export default memo(MembersIcon);
