import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function SettingsIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M19.5018 12C19.5018 12.34 19.4718 12.66 19.4318 12.98L21.5418 14.63C21.7318 14.78 21.7818 15.05 21.6618 15.27L19.6618 18.73C19.5418 18.95 19.2818 19.04 19.0518 18.95L16.5618 17.95C16.0418 18.34 15.4818 18.68 14.8718 18.93L14.4918 21.58C14.4618 21.82 14.2518 22 14.0018 22H10.0018C9.75179 22 9.54179 21.82 9.51179 21.58L9.13179 18.93C8.52179 18.68 7.96179 18.35 7.44179 17.95L4.95179 18.95C4.73179 19.03 4.46179 18.95 4.34179 18.73L2.34179 15.27C2.22179 15.05 2.27179 14.78 2.46179 14.63L4.57179 12.98C4.53179 12.66 4.50179 12.33 4.50179 12C4.50179 11.67 4.53179 11.34 4.57179 11.02L2.46179 9.37C2.27179 9.22 2.21179 8.95 2.34179 8.73L4.34179 5.27C4.46179 5.05 4.72179 4.96 4.95179 5.05L7.44179 6.05C7.96179 5.66 8.52179 5.32 9.13179 5.07L9.51179 2.42C9.54179 2.18 9.75179 2 10.0018 2H14.0018C14.2518 2 14.4618 2.18 14.4918 2.42L14.8718 5.07C15.4818 5.32 16.0418 5.65 16.5618 6.05L19.0518 5.05C19.2718 4.97 19.5418 5.05 19.6618 5.27L21.6618 8.73C21.7818 8.95 21.7318 9.22 21.5418 9.37L19.4318 11.02C19.4718 11.34 19.5018 11.66 19.5018 12ZM8.99963 12C8.99963 13.6543 10.3453 15 11.9996 15C13.6539 15 14.9996 13.6543 14.9996 12C14.9996 10.3457 13.6539 9 11.9996 9C10.3453 9 8.99963 10.3457 8.99963 12Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(SettingsIcon);
