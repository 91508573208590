import React, { type FC } from 'react';

import { Styled } from './BrandingPageSwitcher.styled';

type BrandingPageSwitcherProps = {
	activeItem: number;
	items: {
		id: number;
		value: string;
	}[];
	onSwitch: (id: number) => void;
};

export const BrandingPageSwitcher: FC<BrandingPageSwitcherProps> = ({
	items,
	onSwitch,
	activeItem,
}) => (
	<Styled.BrandingSwitchWrapper>
		{items.map((i) => (
			<Styled.BrandingSwitchItem
				isItemActive={activeItem === i.id}
				key={i.value}
				onClick={() => onSwitch(i.id)}
			>
				<Styled.BrandingSwitchItemTitle>{i.value}</Styled.BrandingSwitchItemTitle>
			</Styled.BrandingSwitchItem>
		))}
	</Styled.BrandingSwitchWrapper>
);
