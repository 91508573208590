export const createActions = (TYPES) => {
	const saveClientChangesFormProcess = (values) => ({
		type: TYPES.SET_CLIENT_CHANGES_FORM,
		payload: values,
	});
	const omitClientChangesFormValues = (values) => ({
		type: TYPES.OMIT_CLIENT_CHANGES_FORM_VALUES,
		payload: values,
	});
	const resetClientChangesFormProcess = () => ({
		type: TYPES.RESET_CLIENT_CHANGES_FORM,
	});
	const resetPostTicketDetailsProcess = () => ({
		type: TYPES.RESET_TICKET_DETAILS,
	});
	const getCurrentClientRequested = (clientId) => ({
		type: TYPES.GET_CURRENT_CLIENT.REQUESTED,
		payload: clientId,
	});
	const getClientByIdShortRequested = (clientId, cb) => ({
		type: TYPES.GET_CLIENT_BY_ID_SHORT.REQUESTED,
		payload: clientId,
		cb,
	});
	const getBoContractsByClientIdRequested = (clientId) => ({
		type: TYPES.GET_BO_CONTRACTS_BY_CLIENT_ID.REQUESTED,
		payload: clientId,
	});
	const editInvoiceRequested = (invoiceId, values) => ({
		type: TYPES.GET_CURRENT_CLIENT.REQUESTED,
		payload: { invoiceId, values },
	});
	const getClientSubsInfoRequested = (clientId) => ({
		type: TYPES.GET_CLIENT_SUBS_INFO.REQUESTED,
		payload: clientId,
	});
	const getInvoiceByIdRequested = (invoiceId) => ({
		type: TYPES.GET_INVOICE_BY_ID.REQUESTED,
		payload: invoiceId,
	});
	const deleteInvoiceByIdRequested = (invoiceId, callback) => ({
		type: TYPES.DELETE_INVOICE_BY_ID.REQUESTED,
		payload: invoiceId,
		callback,
	});
	const createAndUpdateClientChangesFormRequested = (
		values,
		cb,
		isUpdate,
		isSuperUser,
		subsInfo,
	) => ({
		type: TYPES.CREATE_AND_UPDATE_CLIENT.REQUESTED,
		payload: values,
		cb,
		isUpdate,
		isSuperUser,
		subsInfo,
	});
	const getClientsRequested = () => ({ type: TYPES.GET_CLIENTS.REQUESTED });
	const getBOClientsRequested = (params) => ({
		type: TYPES.GET_BO_CLIENTS.REQUESTED,
		payload: params,
	});
	const getContextClientByIdRequested = (clientId) => ({
		type: TYPES.GET_CONTEXT_CLIENT.REQUESTED,
		payload: clientId,
	});
	const getClientsContextRequested = (params) => ({
		type: TYPES.GET_CLIENT_CONTEXT_CLIENTS_LIST.REQUESTED,
		payload: params,
	});
	const saveClientContextSelectedList = (values) => ({
		type: TYPES.SAVE_CLIENT_CONTEXT_SELECTED_LIST,
		payload: values,
	});

	const getClientsSubsRequested = () => ({ type: TYPES.GET_CLIENTS_SUBS.REQUESTED });
	const getClientRegionsRequested = () => ({ type: TYPES.GET_CLIENT_REGIONS.REQUESTED });
	const getBackofficeInvoicesRequested = (params) => ({
		type: TYPES.GET_BACKOFFICE_INVOICES.REQUESTED,
		payload: params,
	});
	const getClientsByRegionRequested = (region, params) => ({
		type: TYPES.GET_CLIENTS_BY_REGION.REQUESTED,
		payload: {
			region,
			params,
		},
	});
	const setClientNetworksRequested = () => ({ type: TYPES.SET_CLIENT_NETWORKS.REQUESTED });

	const createContractRequested = (values, cb) => ({
		type: TYPES.CREATE_CONTRACT.REQUESTED,
		payload: values,
		cb,
	});

	const createInvoiceRequested = (values, cb) => ({
		type: TYPES.CREATE_INVOICE.REQUESTED,
		payload: values,
		cb,
	});

	const updateContractRequested = (values, cb) => ({
		type: TYPES.UPDATE_CONTRACT.REQUESTED,
		payload: values,
		cb,
	});

	const updateInvoiceRequested = (values, cb) => ({
		type: TYPES.UPDATE_INVOICE.REQUESTED,
		payload: values,
		cb,
	});

	const getBackOfficeContractByIdRequested = (contractId, cb) => ({
		type: TYPES.GET_BACKOFFICE_CONTRACT_BY_ID.REQUESTED,
		payload: contractId,
		cb,
	});

	const getTicketDetailsRequested = (ticketId) => ({
		type: TYPES.GET_TICKET_DETAILS.REQUESTED,
		payload: ticketId,
	});

	const getAllBackOfficeJobsRequested = (params, isClientContext = false, isDashboard = false) => ({
		type: TYPES.GET_ALL_BACKOFFICE_JOBS.REQUESTED,
		payload: params,
		isClientContext,
		isDashboard,
	});
	const getBackOfficeCandidatesRequested = (params, isClientContext = false) => ({
		type: TYPES.GET_BACKOFFICE_CANDIDATES.REQUESTED,
		payload: params,
		isClientContext,
	});
	const getBackOfficeJobByIdRequested = (id) => ({
		type: TYPES.GET_BACKOFFICE_JOB_BY_ID.REQUESTED,
		payload: id,
	});
	const resetBackOfficeJobChangesFormRequested = () => ({
		type: TYPES.RESET_BACKOFFICE_JOB_CHANGES_FORM,
	});
	const getBoApplicationMessagesByClientIdRequested = (id) => ({
		type: TYPES.GET_BO_APPLICATION_MESSAGES_BY_CLIENT_ID.REQUESTED,
		payload: id,
	});
	const geTicketsRequested = (params) => ({
		type: TYPES.GET_TICKETS.REQUESTED,
		payload: params,
	});

	const getCreditsDefaultPricesRequested = () => ({
		type: TYPES.GET_CREDITS_DEFAULT_PRICES.REQUESTED,
	});

	const updateCreditsDefaultPricesRequested = (prices) => ({
		type: TYPES.UPDATE_CREDITS_DEFAULT_PRICES.REQUESTED,
		payload: prices,
	});
	const getSubsDefaultPricesRequested = () => ({
		type: TYPES.GET_SUBS_DEFAULT_PRICES.REQUESTED,
	});

	const updateSubsDefaultPricesRequested = (prices) => ({
		type: TYPES.UPDATE_SUBS_DEFAULT_PRICES.REQUESTED,
		payload: prices,
	});
	const createBackOfficeTicketRequested = (values, callback) => ({
		type: TYPES.CREATE_BACKOFFICE_TICKET.REQUESTED,
		payload: values,
		callback,
	});
	const updateBackOfficeTicketRequested = (values, callback) => ({
		type: TYPES.UPDATE_BACKOFFICE_TICKET.REQUESTED,
		payload: values,
		callback,
	});
	const updateBackOfficeTicketStatusRequested = (values, callback) => ({
		type: TYPES.UPDATE_BACKOFFICE_TICKET_STATUS.REQUESTED,
		payload: values,
		callback,
	});
	const getInvoiceStatusesRequested = () => ({
		type: TYPES.GET_INVOICE_STATUSES.REQUESTED,
	});
	const getInvoicePaymentMethodsRequested = () => ({
		type: TYPES.GET_INVOICE_PAYMENT_METHODS.REQUESTED,
	});
	const updateInvoiceStatusRequested = (invoiceId, values, callback) => ({
		type: TYPES.UPDATE_INVOICE_STATUS.REQUESTED,
		payload: { invoiceId, values },
		callback,
	});
	const changeUserPasswordRequested = (values, callback) => ({
		type: TYPES.CHANGE_USER_PASSWORD.REQUESTED,
		payload: values,
		callback,
	});

	const createBackOfficeUserRequested = (values, isClientContext = false, callback) => ({
		type: TYPES.CREATE_BO_USER.REQUESTED,
		payload: values,
		isClientContext,
		callback,
	});

	const getBackOfficeUserByIdRequested = (values, isClientContext = false) => ({
		type: TYPES.GET_BO_USER_BY_ID.REQUESTED,
		payload: values,
		isClientContext,
	});

	const updateBackOfficeUserRequested = (values, isClientContext = false, callback) => ({
		type: TYPES.UPDATE_BO_USER.REQUESTED,
		payload: values,
		isClientContext,
		callback,
	});

	const getBackOfficeUsersRequested = (params, isClientContext = false) => ({
		type: TYPES.GET_BO_USERS.REQUESTED,
		payload: params,
		isClientContext,
	});

	const getBackOfficeUserRolesRequested = (isClientContext = false) => ({
		type: TYPES.GET_BO_USER_ROLES.REQUESTED,
		isClientContext,
	});

	const getBackOfficeUserManagersRequested = () => ({
		type: TYPES.GET_BO_USER_MANAGERS.REQUESTED,
	});

	const activationUserRequested = (id, active = false, isClientContext = false, callback) => ({
		type: TYPES.ACTIVATE_BO_USER.REQUESTED,
		payload: { id, active },
		isClientContext,
		callback,
	});

	const getClientContextInterviewList = (params) => ({
		type: TYPES.GET_CLIENT_CONTEXT_INTERVIEW_LIST.REQUESTED,
		payload: params,
	});

	const getCandidateStatisticsBORequested = (timeFrameInDays, client) => ({
		type: TYPES.GET_CANDIDATE_STATISTICS_BO.REQUESTED,
		payload: { timeFrameInDays, client },
	});
	const getBOJobsMonthStatisticRequested = (clientId) => ({
		type: TYPES.GET_JOBS_MONTH_STATISTIC_BO.REQUESTED,
		payload: clientId,
	});

	const getClientContextDashboardUnreadEmailsRequested = (client) => ({
		type: TYPES.GET_CLIENT_CONTEXT_DASHBOARD_UNREAD_EMAILS.REQUESTED,
		payload: client,
	});
	const getClientsShortRequested = (isParentClient = false) => ({
		type: TYPES.GET_CLIENTS_SHORT.REQUESTED,
		payload: { isParentClient },
	});
	const boostJobRequested = (jobId, cb) => ({
		type: TYPES.BOOST_JOB_BO.REQUESTED,
		payload: jobId,
		cb,
	});

	return {
		saveClientChangesFormProcess,
		omitClientChangesFormValues,
		resetClientChangesFormProcess,
		resetPostTicketDetailsProcess,
		getBackofficeInvoicesRequested,
		editInvoiceRequested,
		getInvoiceByIdRequested,
		deleteInvoiceByIdRequested,
		getClientsRequested,
		getBOClientsRequested,
		getContextClientByIdRequested,
		getClientsContextRequested,
		saveClientContextSelectedList,
		getClientsSubsRequested,
		getClientRegionsRequested,
		getClientsByRegionRequested,
		setClientNetworksRequested,
		createAndUpdateClientChangesFormRequested,
		getCurrentClientRequested,
		createContractRequested,
		createInvoiceRequested,
		updateInvoiceRequested,
		geTicketsRequested,
		getTicketDetailsRequested,
		getAllBackOfficeJobsRequested,
		getBackOfficeCandidatesRequested,
		getBackOfficeJobByIdRequested,
		resetBackOfficeJobChangesFormRequested,
		getBoApplicationMessagesByClientIdRequested,
		getCreditsDefaultPricesRequested,
		updateCreditsDefaultPricesRequested,
		getSubsDefaultPricesRequested,
		updateSubsDefaultPricesRequested,
		getClientSubsInfoRequested,
		createBackOfficeTicketRequested,
		updateBackOfficeTicketRequested,
		updateBackOfficeTicketStatusRequested,
		getBackOfficeContractByIdRequested,
		getInvoiceStatusesRequested,
		getInvoicePaymentMethodsRequested,
		updateInvoiceStatusRequested,
		updateContractRequested,
		changeUserPasswordRequested,
		getBackOfficeUserByIdRequested,
		createBackOfficeUserRequested,
		updateBackOfficeUserRequested,
		getBackOfficeUsersRequested,
		getBackOfficeUserRolesRequested,
		getBackOfficeUserManagersRequested,
		activationUserRequested,
		getBoContractsByClientIdRequested,
		getClientContextInterviewList,
		getCandidateStatisticsBORequested,
		getClientContextDashboardUnreadEmailsRequested,
		getBOJobsMonthStatisticRequested,
		getClientsShortRequested,
		getClientByIdShortRequested,
		boostJobRequested,
	};
};
