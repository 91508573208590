export enum AuthRequestsEnum {
	Login = '/auth/login',
	Logout = '/auth/logout',
	TwoFALogin = '/auth/login/2fa',
	TwoFALoginResendCode = '/auth/login/2fa/resend-code',
	ForgotPassword = '/auth/forgot-password',
	UserResetPassword = '/auth/reset-password',
}

export enum RequestsEnum {
	// Users
	User = '/user',
	UserDTO = '/user/dto',
	ATSUser = '/ats/user',
	BOUser = '/backoffice/user',
	UserRoles = '/user/roles',
	ATSUserRole = '/ats/user/role',
	ATSUserSetPasswordNew = '/ats/user/set-password/new',
	ATSUserSetPassword = '/ats/user/set-password',
	ATSUserPersonalData = '/ats/user/personal-data',
	BOUserSetPassword = '/backoffice/user/set-password',
	BOEmailDefaultTemplate = '/backoffice/email/default-template',
	ATSEmailDefaultTemplate = '/ats/email/template',
	BOEmailPlaceholder = '/email/placeholder',
	BOUserRole = '/backoffice/user/role',
	BOUserManager = '/backoffice/user/manager',
	// Client
	Client = '/client',
	ClientParent = '/client/parent',
	ClientRegion = '/client/region',
	Short = '/short',
	ClientApplicationMessage = '/client/application-message',
	ClientApplicationNotification = '/client/application-notification',
	ATSUserAutoPop = '/ats/user/auto-pop',
	ATSClient2FA = '/ats/client/2fa',
	ATSUserEmailNotifications = '/ats/user/email-notifications',
	ClientApplicationNotifications = '/ats/client/application-notification',
	UserEmailSignature = '/ats/user/email-signature',
	ClientBOApplicationMessages = '/client/backoffice/application-message',
	ClientUsers = '/client/user',
	JobClient = '/client/jobs/new',
	BOClient = '/backoffice/client',
	ClientContext = '/backoffice/client-context/client',
	ClientContextUser = '/backoffice/client-context/user',
	ClientShort = '/client/short',
	// Job
	Job = '/job',
	JobUpgrades = '/job/upgrade',
	Restrictions = '/restrictions',
	BackofficeJobs = '/backoffice/job',
	BackofficeClientContextJobs = '/backoffice/client-context/job',
	BOClientContextDashboardJobs = '/backoffice/client-context/dashboard/job',
	BackofficeClientContextCandidate = '/backoffice/client-context/candidate',
	JobBackofficeSelfService = '/job/backoffice/selfservice',
	JobBackofficePremium = '/job/backoffice/premium',
	JobPremium = '/job/premium',
	JobPremiumBoost = '/job/boost',
	BOJobBoost = '/backoffice/job/boost',
	JobType = '/jobType',
	AtsJobs = '/ats/job',
	AtsJobsActive = '/ats/job/active',
	ATSJobCopy = '/ats/job/copy',
	ATSJobSelfService = '/ats/job/selfservice',
	AtsJobUnappliedCandidate = '/ats/job/unapplied/candidate',
	JobOpen = '/open/job',
	JobOpenJobs = '/open/job/jobs',
	AtsJobGravity = '/ats/job/gravity',
	AtsCandidateGravity = '/ats/candidate/gravity',
	Application = '/application',
	// Candidate
	BackofficeCandidatesCsv = '/backoffice/candidate/csv',
	AtsCandidateAssign = '/ats/candidate/assign',
	AtsCandidateDatabaseAssign = '/ats/candidate/database/assign',
	CandidateApplication = '/candidate/application',
	ATSCandidateApplication = 'ats/candidate/application',
	ATSCandidateApplicationSMS = 'ats/candidate/application/sms',
	AtsCandidateApplicationInterview = '/ats/candidate/application/interview',
	AtsCandidateApplicationInterviewJobs = '/ats/candidate/application/interview/jobs',
	ATSSMSCount = '/ats/sms/count',
	AtsCandidatesCsv = '/ats/candidate/csv',
	AtsCandidatesZip = '/ats/candidate/application/zip',
	CandidateOpen = '/open/candidate',
	CandidateUnassignedOpen = '/open/candidate/unassigned',
	CandidateSendMessage = '/open/candidate/send-message',
	AtsCandidateDatabase = '/ats/candidate/database',
	OpenCandidateDatabaseRemove = 'open/candidate/database/remove',
	BOClientContextCandidates = '/backoffice/client-context/candidate',
	Rank = '/rank',
	Note = '/note',
	Phone = '/phone',
	ShowAction = '/show-action',
	JobIndustry = '/job/industry',
	// Interview
	AtsInterview = '/ats/interview',
	AtsInterviewType = '/ats/interview/type',
	AtsInterviewList = '/ats/interview/list',
	AtsEditInterviewList = '/ats/candidate/application/interview/jobs/',
	AtsUserInterview = '/ats/user/interview',
	AtsInterviewCalendar = '/ats/interview/calendar',
	AtsInterviewResend = '/ats/interview/resend',
	CalendarLinkOpen = '/open/calendar',
	InterviewOpen = '/open/interview',
	DeclineInterviewOpen = '/open/interview/decline',
	BOClientContextInterview = '/backoffice/client-context/interview',
	// Price
	Price = '/price',
	PriceClient = '/price/client',
	OrderSmsPrice = '/order/sms/price',
	OrderInterviewPrice = '/order/interview/price',
	// Invoices
	Invoice = '/invoice',
	InvoiceCSV = '/invoice/csv',
	BackofficeClientContextInvoicesCSV = '/backoffice/client-context/invoice/csv',
	AtsInvoice = '/ats/invoice',
	AtsInvoiceForward = '/ats/invoice/forward',
	InvoiceAts = '/invoice/ats',
	BackofficeInvoice = '/backoffice/invoice',
	BackofficeClientContextInvoice = '/backoffice/client-context/invoice',
	InvoiceBackoffice = '/invoice/backoffice',
	PayForInvoice = '/pay/invoice',
	InvoiceStatuses = '/invoice/statuses',
	InvoicePaymentMethods = '/invoice/payment-methods',
	// Contracts
	Contract = '/contract',
	ContractBackOffice = '/contract/backoffice',
	ContractATS = '/contract/ats',
	ContractBoClient = '/contract/backoffice/client',
	ContractCSV = '/contract/csv',
	ContractBackofficeClientContext = '/contract/backoffice/client-context',
	ContractBackofficeClientContextCSV = '/contract/backoffice/client-context/csv',
	Approve = '/approve',
	// Credits
	PayForCredit = '/pay/credits',
	OrderCredits = '/order/credits',
	OrderSms = '/order/sms',
	PaySms = '/pay/sms',
	OrderInterview = '/order/interview',
	PayInterview = '/pay/interview',
	Credit = '/credit',
	CreditList = '/credit/list',
	CreditClient = '/credit/client',
	CreditsDefault = '/credit/default',
	// Assessments
	Assessment = '/assessment',
	AssessmentBackoffice = '/assessment/backoffice',
	AssessmentNew = '/assessment/new',
	AssessmentJob = '/assessment/job',
	AssessmentApplicationCandidate = '/assessment/application/candidate',
	AssessmentApplicationOpen = '/open/assessment/application',
	AssessmentJobOpen = '/open/assessment/job',
	AssessmentNewOpen = '/open/assessment/new',
	// TODO Add Branding later
	BrandingAts = '/branding/ats',
	RejectionMessage = '/rejection-message',
	BackofficeTickets = '/backoffice/ticket',
	BackofficeClientTickets = '/backoffice/client-context/ticket',
	ATSTickets = '/ats/ticket',
	TicketTypes = '/ticket/type',
	TicketStatus = '/ticket/status',
	EmploymentType = '/employmentType',
	EducationType = '/educationType',
	EducationTypeRegion = '/educationType/region',
	SocialNetworks = '/socialNetworks',
	SocialNetworksATS = '/socialNetworks/ats',
	SubscriptionPlan = '/subscriptionPlan/',
	Region = '/region',
	Status = '/status',
	// Questionnaires
	Questionnaire = '/backoffice/video-questionnaire',
	ClientContextQuestionnaire = '/backoffice/client-context/video-questionnaire',
	ATSQuestionnaire = '/ats/video-questionnaire',
	ATSQuestionnaireCandidate = '/ats/video-questionnaire/candidate',
	ATSQuestionnaireCandidateResend = '/ats/video-questionnaire/resend',
	ATSQuestionnaireCandidateRecall = '/ats/video-questionnaire/recall',
	QuestionnaireCopy = '/ats/video-questionnaire/copy',
	QuestionnaireSend = '/ats/video-questionnaire/send',
	QuestionnaireDuration = '/ats/video-questionnaire/duration',
	ATSUserQuestionnaire = '/ats/user/video-questionnaire',
	OpenQuestionnaire = '/open/video-questionnaire',
	ATSVideoInterviewCount = '/ats/video-interview/count',
	// Subscriptions
	SubscriptionTypes = '/pay/sub/type',
	ClientPremiumPay = '/pay/premium',
	ClientPremiumConfirm = '/pay/sub/success',
	ClientPremiumDiscard = '/pay/premium/discard',
	ClientPremiumSchedule = '/pay/premium/schedule',
	ClientPremiumTrial = '/pay/trial/activate',
	CancelClientPremiumTrial = '/pay/trial/cancel',
	WipePremium = '/pay/test/sub/wipe-all-history',
	WipeTrial = '/pay/test/sub/wipe-all-history?type=trial',
	CurrentSubscriptionInfo = '/pay/sub/current',
	CurrentSubscriptionInfoBO = 'pay/sub/current',
	SubscriptionClientTypes = '/pay/trial/type',
	SubscriptionSettings = '/pay/trial/settings',
	SubscriptionsPrices = '/pay/sub/default',
	// Statistics
	StatisticsAtsCandidates = '/statistics/ats/candidates',
	StatisticsAtsCandidatesMonth = '/statistics/ats/candidates/month',
	StatisticsBOClientContextCandidates = '/statistics/backoffice/client-context/candidates',
	StatisticsAtsDashboardApplicationsMonth = '/statistics/ats/dashboard/applications/month',
	StatisticsAtsCandidatesTimePerStateMonth = '/statistics/ats/candidates/time-per-state/month',
	StatisticsAtsCandidatesTimePerStateYear = '/statistics/ats/candidates/time-per-state/year',
	StatisticsAtsAcceptedOffersMonth = '/statistics/ats/accepted-offers/month',
	SubscriptionManualLink = '/pay/manual/link',
	StatisticsAtsAcceptedOffersYear = '/statistics/ats/accepted-offers/year',
	StatisticsTimeToHireMonth = '/statistics/ats/time-to-hire/month',
	StatisticsTimeToHireYear = '/statistics/ats/time-to-hire/year',
	StatisticsApplicationsYear = '/statistics/ats/new-applications/year',
	StatisticsApplicationsMonth = '/statistics/ats/applications/month',
	StatisticsApplicationsLastMonth = '/statistics/ats/dashboard/applications/month',
	StatisticsBOClientContext = '/statistics/backoffice/client-context',
	StatisticsAtsSourceYear = '/statistics/ats/source/year',
	StatisticsAtsSourceMonth = '/statistics/ats/source/month',
	// Emails
	StatisticsATSDashboardUnreadEmails = '/statistics/ats/dashboard/unread-emails',
	StatisticsBOClientContextUnreadEmails = '/statistics/backoffice/client-context/unread-emails',
	ATSJobAll = '/ats/job/all',
	ATSCandidateApplicationJob = '/ats/candidate/application/job',
	ATSEmailThread = '/ats/email/thread',
	ATSEmailTemplate = '/ats/email/template',
	OpenEmailThread = '/open/email/thread',
	ATSEmailInbox = '/ats/email/inbox',
	ATSEmailSent = '/ats/email/sent',
	File = '/file',
	Reply = '/reply',
	Message = '/message',
	// Employees
	HREmployee = '/hr/employee',
	HREmployeeRoles = '/hr/employee/role',
	HREmployeePhoto = '/hr/employee/photo',
	HREmployeeSuperUser = '/hr/employee/super',
	HREmployeeNonSuperUser = '/hr/employee/non-super',
	HREmployeeStatus = '/hr/employee/status',
	Archive = '/archive',
	Unarchive = '/unarchive',
	SuperUser = '/super',
	NonSuperUser = '/non-super',
}

export enum OpenRequestsEnum {
	BackendVersion = 'open/version',
	Client = '/open/job/client',
}
