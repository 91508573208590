import { type FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { isEmpty } from 'lodash';

import Spinner from 'components/Spinner';
import JobIcon from 'components/SVG/JobIcon';
import MapMarkerIcon from 'components/SVG/MapMarkerIcon';
import UsdIcon from 'components/SVG/UsdIcon';
import { useMount } from 'hooks';
import { BrandingMode } from 'modules/Common/components/BrandingContainer/BrandingContainer.types';
import JobRoleInfo from 'modules/Common/components/JobViewComponents/JobRoleInfo';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { commonDucks } from 'modules/Common/ducks';
import { CareerPageJobProps } from 'modules/Common/types';
import { Routes } from 'types';

import JobApply from '../../JobApply';
import CompanyApplyBlock from '../CareerPageJobs/CompanyApplyBlock';

import { Styled } from './CareerPageJobView.styled';

const CareerPageJobView: FC<CareerPageJobProps> = ({
	titleColor,
	jobId,
	jobData,
	clientBranding,
	getClientOpenBrandingRequested,
	getOpenJobByIdRequested,
}) => {
	useMount(() => {
		const shouldObtainJob = (!jobData && jobId) || (jobId && jobData?.jobId !== jobId);

		if (shouldObtainJob) {
			getOpenJobByIdRequested(jobId);
		}
	});
	const client = jobData?.client;

	useEffect(() => {
		if (client) {
			getClientOpenBrandingRequested(client);
		}
	}, [client]);

	if (!jobData || isEmpty(clientBranding)) {
		return <Spinner fullWidth />;
	}

	const { highlightColour } = clientBranding;

	const jobsData = [jobData];

	return (
		<Styled.Root>
			<Styled.Head>
				<PageNavTitle navigationLink={Routes.CareerPage} styles={{ fontFamily: 'Raleway'}}>
					<h2>Back to list</h2>
				</PageNavTitle>
			</Styled.Head>
			<Styled.Content>
				<Styled.ContentInner>
					<Styled.JobWrapper>
						<Styled.Title color={highlightColour}>{jobData?.title}</Styled.Title>
						<Styled.Info>
							<Styled.InfoItem>
								<MapMarkerIcon width='24' height='24' />
								<Styled.Label>{jobData?.location}</Styled.Label>
							</Styled.InfoItem>
							<Styled.InfoItem>
								<UsdIcon width='24' height='24' />
								<Styled.Label>{jobData?.salary}</Styled.Label>
							</Styled.InfoItem>
							<Styled.InfoItem>
								<JobIcon width='24' height='24' />
								<Styled.Label>{jobData?.jobType?.name}</Styled.Label>
							</Styled.InfoItem>
						</Styled.Info>
						<Styled.Description>
							<JobRoleInfo jobData={jobData} />
						</Styled.Description>
					</Styled.JobWrapper>
					<CompanyApplyBlock clientId={jobData.client} highlightColour={highlightColour} />
				</Styled.ContentInner>
				<JobApply
					jobsData={jobsData}
					highlightColour={highlightColour}
					mode={BrandingMode.View}
					clientName={jobData.clientName}
				/>
			</Styled.Content>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		clientBranding: commonDucks.commonSelectors.getClientBranding(state),
		jobApplyState: commonDucks.commonSelectors.getJobApply(state),
		jobData: commonDucks.commonSelectors.getJobData(state),
	}),
	{
		getClientOpenBrandingRequested: commonDucks.commonActions.getClientBrandingRequested,
		getOpenJobByIdRequested: commonDucks.commonActions.getOpenJobByIdRequested,
	},
)(CareerPageJobView);
