import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		display: flex;
		justify-content: center;
	`;
	export const Inner = styled.section`
		max-width: 1328px;
	`;
	export const Title = styled.p`
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		padding-bottom: 40px;
	`;
	export const FormTitle = styled.h1`
		font-size: 24px;
		font-weight: 600;
		line-height: 32px;
		padding-bottom: 24px;
	`;
	export const FormWrappen = styled.div``;
	export const FormSubmitWrappen = styled.div`
		padding-top: 40px;
		width: 100%;
		display: flex;
		justify-content: flex-end;
	`;
	export const FieldBlockWrapper = styled.div`
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 24px 0;
		border-radius: 10px;
		border: 1px solid ${COLORS.lightGray3};
		background: ${COLORS.lightGray3};
		overflow: hidden;
		margin-bottom: 24px;
	`;
	export const FieldBlockInner = styled.div`
		width: 513px;
	`;
	export const FieldBlockTitle = styled.div`
		font-family: 'Raleway';
		text-align: center;
		font-size: 20px;
		font-weight: 600;
		line-height: 24px;

		span {
			display: inline-block;
			color: ${COLORS.red};
			padding: 0 4px;
		}
	`;
	export const FieldBlockHint = styled.div`
		font-family: 'Raleway';
		color: ${COLORS.darkGray2};
		text-align: center;
		font-size: 14px;
		font-weight: 600;
		line-height: 24px;
		padding-top: 8px;
	`;
	export const FieldBlockMain = styled.div`
		padding-top: 24px;
	`;
}
