import { memo } from 'react';

import { IconType } from '../SVG.types';

function InstagramIcon({ width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fill='#39393B'
				d='M12 6.502a5.486 5.486 0 00-5.494 5.494 5.486 5.486 0 005.493 5.494 5.486 5.486 0 005.494-5.494 5.486 5.486 0 00-5.494-5.494zm0 9.065a3.578 3.578 0 01-3.571-3.57 3.578 3.578 0 013.57-3.571 3.578 3.578 0 013.57 3.57 3.578 3.578 0 01-3.57 3.57zm5.718-10.57c-.71 0-1.283.573-1.283 1.283s.573 1.283 1.283 1.283a1.28 1.28 0 001.186-1.774 1.283 1.283 0 00-1.186-.792zm4.99 7c0-1.48.014-2.945-.07-4.42-.083-1.715-.474-3.236-1.727-4.49-1.256-1.256-2.775-1.644-4.49-1.728-1.478-.083-2.943-.07-4.419-.07-1.479 0-2.944-.013-4.42.07-1.714.084-3.235.475-4.489 1.728-1.256 1.256-1.645 2.775-1.728 4.49-.083 1.478-.07 2.943-.07 4.42 0 1.475-.013 2.943.07 4.419.083 1.714.474 3.235 1.728 4.489 1.256 1.256 2.775 1.645 4.49 1.728 1.478.083 2.943.07 4.419.07 1.478 0 2.944.013 4.42-.07 1.714-.083 3.235-.474 4.489-1.728 1.256-1.256 1.644-2.775 1.728-4.49.085-1.475.07-2.94.07-4.419zm-2.357 6.315a3.405 3.405 0 01-.809 1.227c-.378.378-.74.613-1.227.809-1.409.56-4.754.434-6.316.434-1.561 0-4.91.126-6.318-.431a3.404 3.404 0 01-1.227-.81 3.426 3.426 0 01-.81-1.226c-.556-1.412-.43-4.757-.43-6.319 0-1.562-.126-4.91.43-6.319.196-.487.432-.851.81-1.226.377-.375.739-.614 1.227-.81 1.408-.557 4.757-.43 6.318-.43 1.562 0 4.91-.127 6.319.43.487.196.852.432 1.227.81.377.377.613.739.809 1.226.557 1.41.431 4.758.431 6.32 0 1.56.126 4.906-.434 6.315z'
			></path>
		</svg>
	);
}

export default memo(InstagramIcon);
