export const getOrCreateLegendList = (id) => {
	const legendContainer = document.getElementById(id);
	let listContainer = legendContainer?.querySelector('ul');
	listContainer?.remove();

	listContainer = document.createElement('ul');
	listContainer.style.display = 'flex';
	listContainer.style.flexDirection = 'row';
	listContainer.style.margin = '0';
	listContainer.style.padding = '0';

	legendContainer?.appendChild(listContainer);

	return listContainer;
};

export const createGraphLegendItem = (item, ul, chart) => {
	const li = document.createElement('li');
	li.style.alignItems = 'center';
	li.style.cursor = 'pointer';
	li.style.display = 'flex';
	li.style.lineHeight = '24px';
	li.style.fontSize = '16px';
	li.style.fontFamily = 'Inter';
	li.style.margin = '12px 0 12px 32px';

	li.onclick = () => {
		const { type } = chart.config;
		if (type === 'pie' || type === 'doughnut') {
			// Pie and doughnut charts only have a single dataset and visibility is per item
			chart.toggleDataVisibility(item.index);
		} else {
			chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
		}
		chart.update();
	};

	const boxSpan = document.createElement('span');
	boxSpan.style.background = item.fillStyle;
	boxSpan.style.borderColor = item.strokeStyle;
	boxSpan.style.borderWidth = item.lineWidth + 'px';
	boxSpan.style.display = 'inline-block';
	boxSpan.style.flexShrink = 0;
	boxSpan.style.height = '16px';
	boxSpan.style.marginRight = '8px';
	boxSpan.style.width = '16px';
	boxSpan.style.borderRadius = '2px';

	const textContainer = document.createElement('p');
	textContainer.style.color = item.fontColor;
	textContainer.style.margin = 0;
	textContainer.style.padding = 0;
	textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

	const text = document.createTextNode(item.text);
	textContainer.appendChild(text);

	li.appendChild(boxSpan);
	li.appendChild(textContainer);

	ul.appendChild(li);
};

export const getHtmlLegendPlugin = id => ({
	id,
	afterUpdate(chart, args, options) {
		try {
			const ul = getOrCreateLegendList(options.containerID);

			while (ul.firstChild) {
				ul.firstChild.remove();
			}

			ul.style.display = 'flex';
			ul.style.flexWrap = 'wrap';
			ul.style.alignItems = 'center';
			ul.style.justifyContent = 'center';


			const items = chart.options.plugins.legend.labels.generateLabels(chart);

			items.forEach((item) => {
				createGraphLegendItem(item, ul, chart);
			});
		} catch(e) {
			console.error('errror ', e);
		}
		
	},
});
