import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';

import { Styled } from './SettingsPanelMenu.styled';

export interface IPanelListItem {
	id: number;
	name: string;
	path: string;
	icon: JSX.Element;
}

type NavigationMenuProps = {
	items: IPanelListItem[];
};

const SettingsPanelMenu: React.FC<NavigationMenuProps> = ({ items }) => {
	return (
		<Styled.Root>
			<Styled.PanelMenu>
				{items?.map((item: IPanelListItem) => (
					<Styled.PanelItem key={item.id}>
						<NavLink to={`${item.path}`}>
							<Styled.PanelItemBox>
								<Styled.ItemIcon>{item.icon}</Styled.ItemIcon>
								<Styled.ItemName>{item.name}</Styled.ItemName>
							</Styled.PanelItemBox>
						</NavLink>
					</Styled.PanelItem>
				))}
			</Styled.PanelMenu>
		</Styled.Root>
	);
};

export default memo(SettingsPanelMenu);
