import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { message } from 'antd';
import createDecorator from 'final-form-calculate';
import { isEmpty } from 'lodash';

import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import { MESSAGES } from 'modules/Common/constants';
import { commonDucks } from 'modules/Common/ducks';
import {
	ClientChangeFormDataType,
	ClientInfoType,
	BaseCreditType,
	CreditDefaultBundleType,
	ISubsPrices,
	SubsInfoType,
} from 'modules/Common/types';
import { cleanUpCreditAndPrices } from 'modules/Common/utils/commonHelpers';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { AppModes, GenericType, OptionsType, UserRolesType, Routes } from 'types';

import { backOfficeDucks } from '../../ducks';

import Additional from './Additional';
import ClientRelationship from './ClientRelationship';
import CreditCheck from './CreditCheck';
import Credits from './Credits';
import CustomBranding from './CustomBranding';
import MainDetails from './MainDetails';
import SubmitContainer from './SubmitContainer';
import Subscriptions from './Subscriptions';

type ClientChangesFormProps = {
	createAndUpdateClientChangesForm: (
		values: ClientChangeFormDataType,
		callback: () => void,
		isUpdate: boolean,
		isSuperUser: boolean,
		subsInfo?: SubsInfoType,
	) => void;
	saveClientChangesFormProcess: (values: GenericType) => void;
	getClientsListRequested: (isParentClient?: boolean) => void;
	getClientByIdShortRequested: (
		clientId: number | string,
		cb?: (clientData: ClientInfoType) => void,
	) => void;
	getClientsSubsRequested: () => void;
	getClientRegionRequested: () => void;
	getSubsDefaultPrices: () => void;
	subsDefaultPrices: ISubsPrices;
	getClientSubsInfoRequested: (cientId: number | string) => void;
	getCreditsDefaultPrices: () => void;
	getCreditsRequested: () => void;
	omitClientChangeFormValues: (fieldsToOmit: string[]) => void;
	clientRegions: OptionsType[];
	baseCredits: BaseCreditType[];
	subscriptionOptions: OptionsType[];
	creditsDefaultPrices: CreditDefaultBundleType[];
	currentClientShortData: ClientInfoType;
	clientChangesFormData: ClientChangeFormDataType;
	getClientById: (clientId: string | undefined) => void;
	parentsList: OptionsType[];
	loading: GenericType;
	roles: UserRolesType;
	mode?: AppModes;
};

const ClientChangesForm: FC<ClientChangesFormProps> = ({
	getClientsListRequested,
	getClientByIdShortRequested,
	getClientsSubsRequested,
	getClientRegionRequested,
	getClientSubsInfoRequested,
	getCreditsDefaultPrices,
	createAndUpdateClientChangesForm,
	getCreditsRequested,
	clientRegions,
	baseCredits,
	subscriptionOptions,
	creditsDefaultPrices,
	saveClientChangesFormProcess,
	omitClientChangeFormValues,
	currentClientShortData,
	clientChangesFormData,
	subsDefaultPrices,
	getSubsDefaultPrices,
	getClientById,
	parentsList,
	loading,
	roles,
	mode,
}) => {
	const navigate = useNavigate();
	const { clientId } = useParams();
	const isEdit = !!clientId && mode === AppModes.Edit;

	useEffect(() => {
		getClientsListRequested(true);
		getClientsSubsRequested();
		getClientRegionRequested();
		getCreditsDefaultPrices();
		getCreditsRequested();
		getSubsDefaultPrices();

		if (clientId) {
			getClientSubsInfoRequested(clientId);
		}
	}, [
		getClientsListRequested,
		getClientsSubsRequested,
		getClientRegionRequested,
		getCreditsRequested,
		clientId,
	]);

	useMount(() => {
		if (clientId && !clientChangesFormData?.id && isEdit) {
			getClientById(clientId);
		}
	});

	const onSubmit = (values: ClientChangeFormDataType) => {
		if (!clientChangesFormData?.hasSavedParent && clientChangesFormData?.parent && isEdit) {
			const creditAndPrices = cleanUpCreditAndPrices(clientChangesFormData);

			saveClientChangesFormProcess({ ...creditAndPrices });
		}

		createAndUpdateClientChangesForm(
			values,
			() => navigate(`${Routes.BackOffice}${Routes.Clients}`),
			isEdit,
			roles.isSuperUser,
			clientChangesFormData?.subsInfo,
		);
	};

	const filteredParentsList = isEdit
		? parentsList?.filter((parent: OptionsType) => parent.value !== clientId)
		: parentsList;

	const handleChangeParent = useCallback((value: number) => {
		if (value) {
			message.warning(MESSAGES.changeParentCompany);
		}
	}, []);

	const handleCleanParent = () => {
		if (clientChangesFormData?.hasSavedParent) {
			const fieldsToOmit = Object.keys(clientChangesFormData).filter(
				(k) => k.startsWith('credit-') || k.startsWith('price-'),
			);
			omitClientChangeFormValues(fieldsToOmit);
			saveClientChangesFormProcess({ parent: null });
			message.warning(MESSAGES.removeParentCompany);
		}
	};

	const handleCancelForm = useCallback(() => {
		clientId && getClientById(clientId);
	}, []);

	const formDecorator = useMemo(
		() =>
			createDecorator(
				{
					field: 'freeTrial',
					updates: {
						hrModule: (freeTrialValue) => freeTrialValue,
						candidatesDatabaseEnabled: (freeTrialValue) => freeTrialValue,
						questionnairesFunctionality: (freeTrialValue) => freeTrialValue,
					},
				},
				{
					field: 'parent',
					updates: {
						region: (newParentId, currentVal, prevVal) => {
							if (clientChangesFormData?.region) {
								return clientChangesFormData?.region;
							}
							if (isEmpty(prevVal)) return;

							if (newParentId) {
								return new Promise((resolve) =>
									getClientByIdShortRequested(newParentId, (data) => resolve(data.region)),
								);
							}
						},
					},
				},
				{
					field: 'region',
					updates: {
						annualPrice: (newRegion, prevRegion) => {
							if (!newRegion) {
								return null;
							}
							const predifinedAnnualPrice = clientChangesFormData?.subsInfo?.annualPrice;

							const defaultAnnualPrice =
								newRegion === 1
									? subsDefaultPrices.ukAnnualPrice
									: subsDefaultPrices.usaAnnualPrice;

							return predifinedAnnualPrice ?? defaultAnnualPrice;
						},
						monthlyPrice: (newRegion) => {
							if (!newRegion) {
								return null;
							}

							const predifinedMonthlyPrice = clientChangesFormData?.subsInfo?.monthlyPrice;

							const defaultMonthlyPrice =
								newRegion === 1
									? subsDefaultPrices.ukMonthlyPrice
									: subsDefaultPrices.usaMonthlyPrice;

							return predifinedMonthlyPrice ?? defaultMonthlyPrice;
						},
					},
				},
			),
		[currentClientShortData, clientChangesFormData, subsDefaultPrices],
	) as never;

	if (loading?.getCurrentClientLoad) {
		return <Spinner fixed />;
	}

	return (
		<Form
			onSubmit={onSubmit}
			decorators={[formDecorator]}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<ClientRelationship
						parentsList={filteredParentsList}
						clientChangesFormData={clientChangesFormData}
						onChange={handleChangeParent}
						onClear={handleCleanParent}
					/>
					<MainDetails
						clientRegions={clientRegions}
						clientChangesFormData={clientChangesFormData}
						isEdit={isEdit}
					/>
					<CreditCheck clientChangesFormData={clientChangesFormData} />
					<CustomBranding
						saveClientChangesFormProcess={saveClientChangesFormProcess}
						clientChangesFormData={clientChangesFormData}
					/>
					{clientChangesFormData.subsInfo || !isEdit ? (
						<Subscriptions
							saveClientChangesFormProcess={saveClientChangesFormProcess}
							subscriptionOptions={subscriptionOptions}
							isEdit={isEdit}
							isSuperUser={roles.isSuperUser}
							clientChangesFormData={clientChangesFormData}
						/>
					) : null}
					<Credits
						baseCredits={baseCredits}
						clientChangesFormData={clientChangesFormData}
						isEdit={isEdit}
						creditsDefaultPrices={creditsDefaultPrices}
					/>
					<Additional clientChangesFormData={clientChangesFormData} />
					<SubmitContainer loading={loading} isEdit={isEdit} onClick={handleCancelForm} />
				</form>
			)}
		/>
	);
};

export default connect(
	(state) => ({
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
		clientRegions: backOfficeDucks.backOfficeSelectors.getClientsRegionsOptions(state),
		subsDefaultPrices: backOfficeDucks.backOfficeSelectors.getSubsDefaultPricesState(state),
		subscriptionOptions: [
			{
				id: 1,
				name: 'Manually',
				label: 'Manually',
				value: 1,
			},
			{
				id: 2,
				name: 'Automatically',
				label: 'Automatically',
				value: 2,
			},
		],
		clientChangesFormData: backOfficeDucks.backOfficeSelectors.getClientChangesFormState(state),
		currentClientShortData: backOfficeDucks.backOfficeSelectors.getCurrentClientShortData(state),
		baseCredits: commonDucks.commonSelectors.getCredits(state),
		parentsList: backOfficeDucks.backOfficeSelectors.getClientsShortData(state),
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
		creditsDefaultPrices: backOfficeDucks.backOfficeSelectors.getCreditsDefaultPricesState(state),
	}),
	{
		omitClientChangeFormValues: backOfficeDucks.backOfficeActions.omitClientChangesFormValues,
		createAndUpdateClientChangesForm:
			backOfficeDucks.backOfficeActions.createAndUpdateClientChangesFormRequested,
		getClientSubsInfoRequested: backOfficeDucks.backOfficeActions.getClientSubsInfoRequested,
		getCreditsDefaultPrices: backOfficeDucks.backOfficeActions.getCreditsDefaultPricesRequested,
		getClientsListRequested: backOfficeDucks.backOfficeActions.getClientsShortRequested,
		getClientByIdShortRequested: backOfficeDucks.backOfficeActions.getClientByIdShortRequested,
		getClientsSubsRequested: backOfficeDucks.backOfficeActions.getClientsSubsRequested,
		getClientRegionRequested: backOfficeDucks.backOfficeActions.getClientRegionsRequested,
		getCreditsRequested: commonDucks.commonActions.getCreditsRequested,
		getSubsDefaultPrices: backOfficeDucks.backOfficeActions.getSubsDefaultPricesRequested,
		getClientById: backOfficeDucks.backOfficeActions.getCurrentClientRequested,
		saveClientChangesFormProcess: backOfficeDucks.backOfficeActions.saveClientChangesFormProcess,
	},
)(ClientChangesForm);
