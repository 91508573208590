import React, { type FC } from 'react';

import { SMSHistory } from '../SendSmsTypes';

import { Styled } from './SMSHistoryModal.styled';

type SMSHistoryModalProps = {
	candidateSMSHistory: SMSHistory | null;
	onCancel: () => void;
};

export const SMSHistoryModal: FC<SMSHistoryModalProps> = ({
	candidateSMSHistory,
	onCancel,
}) => {
	return (
		<Styled.Modal open={!!candidateSMSHistory} footer={null} centered onCancel={onCancel} width='auto'>
			<Styled.ModalHead>
				<h3>SMS History</h3>
			</Styled.ModalHead>
			<Styled.ModalContent>
				{candidateSMSHistory?.map((sms, index) => {
					return (
						<Styled.SMSHistoryItem key={sms.id}>
							<Styled.SMSHistoryItemDate>{sms.sentDate}</Styled.SMSHistoryItemDate>
							<Styled.SMSHistoryItemText>{sms.text}</Styled.SMSHistoryItemText>
						</Styled.SMSHistoryItem>
					);
				})}
			</Styled.ModalContent>
		</Styled.Modal>
	);
};
