import React, { FC, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button, { ButtonTypes } from 'components/Button';
import { unregisteredDucks } from 'modules/Unregistered/ducks';

import { Styled } from './UnImpersonateContainer.styled';

type UnImpersonateContainerProps = {
	unImpersonateUser: (callbakc: () => void) => null;
};

const UnImpersonateContainer: FC<UnImpersonateContainerProps> = ({ unImpersonateUser }) => {
	const navigate = useNavigate();

	const handleUnImpersonate = useCallback(() => unImpersonateUser(() => navigate(0)), []);

	return (
		<Styled.UnPersonateContainer>
			<Styled.UnPersonateContainerInner>
				<Styled.UnPersonateContainerMessage>
					You are currently in the impersonate mode
				</Styled.UnPersonateContainerMessage>
				<div>
					<Button buttonType={ButtonTypes.primary} onClick={handleUnImpersonate}>
						Back to Back Office
					</Button>
				</div>
			</Styled.UnPersonateContainerInner>
		</Styled.UnPersonateContainer>
	);
};

export default connect(() => ({}), {
	unImpersonateUser: unregisteredDucks.unregisteredActions.unImpersonateUserRequested,
})(UnImpersonateContainer);
