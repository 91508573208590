import React, { FC, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';

import Box from 'components/Box';
import { useMount } from 'hooks';
import UserAccessContainer from 'modules/BackOffice/containers/UserAccessContainer';
import { DEFAULT_PAGE_SIZE } from 'modules/Common/constants';
import { BackOfficeSettingsRoutes, IOption } from 'types';

import { UserAccessType } from '../../../containers/UserAccessContainer/UserAccessContainer.types';
import { backOfficeDucks } from '../../../ducks';

import FilterFields from './FilterFields';

type UserAccessPageProps = UserAccessType & {
	getUserRoles: () => void;
	userRoles: IOption[];
};

const defaultRole = { id: 0, value: 'All', name: 'ALL' };

export const UserAccessPage: FC<UserAccessPageProps> = ({
	getUsersRequested,
	deActivateUserRequested,
	getUserRoles,
	userRoles,
	users,
	loading,
}) => {
	const navigate = useNavigate();
	const [role, setRole] = useState<number>(defaultRole?.id);

	const handleAddUser = () => {
		navigate(BackOfficeSettingsRoutes.UserAccessCreate);
	};

	const handleChangeValue = useCallback((value: number, isActive: boolean) => {
		setRole(value);

		getUsersRequested({
			page: 0,
			size: DEFAULT_PAGE_SIZE,
			isActive,
			...(value !== 0 && { role: value }),
		});
	}, []);

	useMount(() => {
		!userRoles?.length && getUserRoles();
	});

	const filteredUserRoles = [defaultRole, ...userRoles];

	return (
		<Box noSpaces>
			<UserAccessContainer
				users={users}
				getUsersRequested={getUsersRequested}
				deActivateUserRequested={deActivateUserRequested}
				headerComponent={
					<>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<h2>Admin Accounts</h2>
							<Button type='primary' htmlType='button' onClick={handleAddUser}>
								Add User
							</Button>
						</div>
						<p>You can manage all user accounts below.</p>
					</>
				}
				editUserLink={BackOfficeSettingsRoutes.UserAccessEdit}
				loading={loading}
				role={role}
				filterComponent={(props) => (
					<FilterFields
						{...props}
						value={role}
						options={filteredUserRoles}
						onChange={handleChangeValue}
					/>
				)}
			/>
		</Box>
	);
};

export default connect(
	(state) => ({
		userRoles: backOfficeDucks.backOfficeSelectors.getBackOfficeUserRoles(state),
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
		users: backOfficeDucks.backOfficeSelectors.getBackOfficeUsersList(state),
	}),
	{
		getUserRoles: backOfficeDucks.backOfficeActions.getBackOfficeUserRolesRequested,
		getUsersRequested: backOfficeDucks.backOfficeActions.getBackOfficeUsersRequested,
		deActivateUserRequested: backOfficeDucks.backOfficeActions.activationUserRequested,
	},
)(UserAccessPage);
