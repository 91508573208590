import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';
import { COLORS } from 'theme';

interface TextHintProps {
	fontSize?: string;
	fontWeight?: string;
}

export namespace Styled {
	export const TextHint = styled.p<TextHintProps>`
		font-size: ${({ fontSize }) => fontSize || '16px'};
		font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
	`;

	export const AddQnButton = styled(ButtonComponent)`
		display: flex;
		width: 100%;
		height: 72px;
		margin: 24px 0;
		padding: 24px;
		line-height: 72px;
		text-align: start;
		font-size: 20px;
		color: ${COLORS.blue};
		border: 1px solid ${COLORS.blue};
		border-radius: 10px;
    box-shadow: ${COLORS.elevationShadow1});
		
		&:disabled {
      border: 1px solid ${COLORS.lightGray1};
    }
	`;

	export const AQContainer = styled.section`
		display: flex;
		flex-direction: column;
		gap: 24px;
	`;

	export const AQItem = styled.section`
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 16px 24px 32px;
		background: ${COLORS.white};
		border: 1px solid ${COLORS.lightGray2};
		box-shadow: ${COLORS.elevationShadow1};
		border-radius: 10px;
	`;

	export const AQTitle = styled.h3``;

	export const AQButtons = styled.div`
		display: flex;
		align-items: center;
		gap: 16px;
	`;

	export const AQHead = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		.ant-btn {
			padding: 0;
			font-size: 16px;
			font-weight: 500;
			transition: all 0.2s ease;

			&:hover {
				color: ${COLORS.red};
			}
		}
	`;

	export const AQItemHead = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	`;

	export const AQRow = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-top: 32px;
		gap: 32px;
	`;

	export const AQContent = styled.div`
		width: 100%;
		text-align: center;

		p {
			color: ${COLORS.darkGray2};
		}
	`;

	export const Button = styled(ButtonComponent)`
		width: 40px;
		height: 40px;
		border: 1px solid ${COLORS.lightGray2};
		box-shadow: ${COLORS.elevationShadow1};
	`;

	export const DeleteButton = styled(ButtonComponent)`
		width: 40px;
		height: 40px;
		border: 1px solid ${COLORS.red};
		box-shadow: ${COLORS.elevationShadow1};
	`;
}
