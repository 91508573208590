import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.div`
		position: relative;
	`;
	export const ContentWrapper = styled.div`
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	`;
	export const ContentInner = styled.div`
		max-width: 1340px;
		width: 100%;
	`;
	export const NoContentSpace = styled.div`
		margin-bottom: 20px;
	`;
}
