import { type ChangeEvent, type FC, useEffect, useState } from 'react';
import { Field } from 'react-final-form';

import TextArea from 'antd/es/input/TextArea';

import FieldWrapper from 'components/FieldWrapper';
import { requiredFieldValidator } from 'utils/validators';

import {
	ApplicationQuestion,
	AssessmentType,
} from '../../AssessmentContainer/AssessmentContainer.types';

type AssessmentFormTextFieldProps = {
	assessment: AssessmentType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cb: any;
};

const AssessmentFormTextField: FC<AssessmentFormTextFieldProps> = ({ assessment, cb }) => {
	const [value, setValue] = useState<string>('');

	const [preparedValue, setPreparedValue] = useState({
		question: assessment.id,
		applicationAnswers: [
			{
				text: '',
			},
		],
	});

	const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setValue(e.target.value);
	};

	useEffect(() => {
		setPreparedValue({
			question: assessment.id,
			applicationAnswers: [
				{
					text: value,
				},
			],
		});
	}, [value]);

	useEffect(() => {
		cb((init: ApplicationQuestion[]) => {
			return [preparedValue, ...init.filter((aa) => aa.question !== preparedValue.question)];
		});
	}, [preparedValue]);

	return (
		<div>
			<Field name={`assessment-${assessment.id}`} validate={requiredFieldValidator}>
				{({ input, meta }) => (
					<FieldWrapper
						name={`assessment-${assessment.id}`}
						errorMessage={meta.submitFailed && meta.touched && meta.error}
					>
						<TextArea
							{...input}
							onChange={(e) => {
								onChange(e);

								return input.onChange(e);
							}}
						/>
					</FieldWrapper>
				)}
			</Field>
		</div>
	);
};

export default AssessmentFormTextField;
