import { Button } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const HeaderContainerWrapper = styled.nav`
		position: absolute;
		width: 100%;
		height: 80px;
		background: rgba(7, 15, 57, 0.1);
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2;
	`;
	export const HeaderContainer = styled.main`
		display: flex;
		width: 100%;
		max-width: 1250px;
		padding: 0 32px;
		justify-content: space-between;
		align-items: center;
	`;
	export const HeaderNavSection = styled.section``;
	export const HeaderActions = styled.section`
		display: flex;
		align-items: center;
		justify-content: flex-start;
	`;
	export const HeaderActionsItem = styled.a`
		margin-right: 40px;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		color: ${COLORS.white};
		cursor: pointer;

		:hover {
			color: ${COLORS.darkGray1};
		}
	`;
	export const GetInTouch = styled(Button)`
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		padding: 8px 24px;
		border: 1px solid ${COLORS.white};
		border-radius: 4px;
		width: 145px;
		height: 40px;

		:hover {
			border: 1px solid ${COLORS.darkGray1};
			color: ${COLORS.darkGray1};
		}

		@media screen and (max-width: 800px) {
			display: none;
		}
	`;
}
