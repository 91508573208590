import { type FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import JobArrow from 'components/SVG/JobArrow';
import MapMarkerIcon from 'components/SVG/MapMarkerIcon';
import UsdIcon from 'components/SVG/UsdIcon';
import { BrandingMode } from 'modules/Common/components/BrandingContainer/BrandingContainer.types';
import { Routes } from 'types';

import { Styled } from './CareerPageJob.styled';

type CareerPageJobProps = {
	mode?: string;
	clientId?: number;
	job: {
		jobId: number;
		title: string;
		candidatesStatistic?: {
			id: number;
			title: string;
			amount: number;
		}[];
		location: string;
		expireData?: string;
		salary?: string;
	};
	highlightColour: string;
};

export const CareerPageJob: FC<CareerPageJobProps> = ({ job, highlightColour, mode, clientId }) => {
	const navigate = useNavigate();
	const handleJobClick = useCallback(() => {
		if (mode === BrandingMode.Edit) {
			return null;
		}

		return navigate(`${Routes.CareerJobPage}/${job.jobId}`);
	}, [job.jobId, navigate, mode, clientId]);

	return (
		<Styled.JobItem key={job.jobId}>
			<Styled.JobItemMain>
				<Styled.JobItemTitle onClick={handleJobClick} color={highlightColour}>
					{job.title}
				</Styled.JobItemTitle>
				<Styled.JobItemAdditional>
					<Styled.JobItemAdditionalLocation>
						<MapMarkerIcon height='17' width='17' />
						<Styled.JobItemAdditionalLabel>{job.location}</Styled.JobItemAdditionalLabel>
					</Styled.JobItemAdditionalLocation>
					<Styled.JobItemAdditionalSalary>
						<UsdIcon height='17' width='17' />
						<Styled.JobItemAdditionalLabel>{job.salary}</Styled.JobItemAdditionalLabel>
					</Styled.JobItemAdditionalSalary>
				</Styled.JobItemAdditional>
			</Styled.JobItemMain>
			<Styled.JobItemNav onClick={handleJobClick}>
				<JobArrow />
			</Styled.JobItemNav>
		</Styled.JobItem>
	);
};
