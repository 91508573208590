import { centerCrop, makeAspectCrop } from 'react-image-crop';

export const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
	return centerCrop(
		makeAspectCrop(
			{
				unit: '%',
				width: 90,
			},
			aspect,
			mediaWidth,
			mediaHeight,
		),
		mediaWidth,
		mediaHeight,
	);
};

export const calculateMaxDimensions = (
	imageWidth: number,
	imageHeight: number,
	cropAspectRatio: number,
) => {
	const imageAspectRatio = imageWidth / imageHeight;

	let maxImageWidth, maxImageHeight;

	if (imageAspectRatio > cropAspectRatio) {
		maxImageWidth = imageHeight * cropAspectRatio;
		maxImageHeight = imageHeight;
	} else {
		maxImageWidth = imageWidth;
		maxImageHeight = imageWidth / cropAspectRatio;
	}

	return {
		maxWidth: maxImageWidth,
		maxHeight: maxImageHeight,
	};
};
