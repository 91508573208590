import React from 'react';

import { Popconfirm } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import DeleteIcon from 'components/SVG/DeleteIcon';
import EditIcon from 'components/SVG/EditIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
import { UserAccessColumnsType } from 'modules/Common/types';
import { COLORS, TStyled } from 'theme';
import { SettingsRoutes } from 'types';

import { Styled } from './ATSUserAccess.styled';

export const userAccessColumns = (deleteUser: (id: number) => void): ColumnsDataType => [
	{
		title: 'NAME',
		dataIndex: 'fullName',
		key: 'fullName',
		render: (_: string, { fullName }: Partial<UserAccessColumnsType>) => <span>{fullName}</span>,
	},
	{
		title: 'email address',
		dataIndex: 'email',
		key: 'email',
		render: (_: string, { email }: Partial<UserAccessColumnsType>) => {
			return <span>{email}</span>;
		},
	},
	{
		title: 'type',
		dataIndex: 'role',
		key: 'role',
		render: (_: string, { role }: Partial<UserAccessColumnsType>) => {
			return <span>{role}</span>;
		},
	},
	{
		title: 'ACTIONS',
		key: 'action',
		align: 'center',
		width: '54px',
		render: (_, { id, role }: Partial<UserAccessColumnsType>) => {
			const isHrUser = ['HR Manager', 'Employee'].includes(role);

			if (isHrUser) {
				return null;
			}

			return (
				<Styled.ButtonsWrapper>
					<TStyled.ButtonLink to={`${SettingsRoutes.UserAccessEdit}/${id}`}>
						<Styled.Button
							buttonType={ButtonTypes.default}
							icon={<EditIcon fill={COLORS.black} />}
						/>
					</TStyled.ButtonLink>
					<Styled.DeleteButtonWrapper>
						<Popconfirm
							placement='topRight'
							title='Are you sure you would like to revoke access from Connect? 
						Important: you may change the user role to "Employee" to revoke access from ATS only.'
							onConfirm={() => id && deleteUser(+id)}
							okText='Yes'
							cancelText='Cancel'
							style={{ width: '300px' }}
						>
							<>
								<Styled.Button
									buttonType={ButtonTypes.default}
									icon={<DeleteIcon width='16' height='16' fill={COLORS.black} />}
								/>
							</>
						</Popconfirm>
					</Styled.DeleteButtonWrapper>
				</Styled.ButtonsWrapper>
			);
		},
	},
];
