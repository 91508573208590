import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function Icon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_1903_22484)'>
				<path
					d='M11.9994 1C13.5884 1 14.8863 2.24606 14.9591 3.80902C17.7276 4.975 19.592 7.69512 19.592 10.7743V14.6546C20.833 14.7493 21.8142 15.784 21.8142 17.0451V18.5214C21.8142 19.235 21.2324 19.8115 20.517 19.8115H3.48181C2.76473 19.8115 2.18457 19.2349 2.18457 18.5214V17.0451C2.18457 15.7855 3.16446 14.7494 4.40679 14.6546V10.7743C4.40679 7.69657 6.27159 4.97594 9.03963 3.80945C9.11232 2.24594 10.4115 1 11.9994 1ZM19.9623 17.9672V17.0451C19.9623 16.741 19.7116 16.4918 19.406 16.4918C18.4859 16.4918 17.7401 15.749 17.7401 14.8326V10.7743C17.7401 8.27079 16.11 6.08087 13.7539 5.32893L13.1105 5.12361V3.9477C13.1105 3.33942 12.6128 2.84426 11.9994 2.84426C11.3868 2.84426 10.8883 3.33955 10.8883 3.9477V5.12373L10.2451 5.32915C7.88939 6.08153 6.25864 8.2723 6.25864 10.7743V14.8326C6.25864 15.7487 5.51165 16.4918 4.59275 16.4918C4.28683 16.4918 4.03642 16.7414 4.03642 17.0451V17.9672H19.9623ZM8.48087 19.8115H10.3327C10.3327 20.7282 11.0789 21.4713 11.9994 21.4713C12.9199 21.4713 13.6661 20.7282 13.6661 19.8115H15.5179C15.5179 21.7467 13.9426 23.3156 11.9994 23.3156C10.0562 23.3156 8.48087 21.7467 8.48087 19.8115Z'
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id='clip0_1903_22484'>
					<rect width='20' height='22.5' fill='white' transform='translate(2 1)' />
				</clipPath>
			</defs>
		</svg>
	);
}

export default memo(Icon);
