import React from 'react';

import Dashboard from 'modules/ATS/containers/Dashboard';

import { Styled } from './ATSDashboard.styled';

export const ATSDashboard = () => {
	return (
		<Styled.Root>
			<Dashboard />
		</Styled.Root>
	);
};
