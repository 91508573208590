export const getSubscriptionPremiumBenifits = (region:number) => ([
	{
		name: 'Open-source Job Posting',
		value: true,
	},
	{
		name: 'Candidate Pipeline',
		value: true,
	},
	{
		name: 'Face to Face Video Interviews',
		value: true,
	},
	{
		name: 'Set Company Logo',
		value: true,
	},
	{
		name: 'Full Company Branding',
		value: true,
	},
	{
		name: 'Video Questionnaires',
		value: true,
	},
	{
		name: 'Careers Page',
		value: true,
	},
	{
		name: 'Statistics Page',
		value: true,
	},
	{
		name: `${region === 1 ? 'CV' : 'Resume'} Database`,
		value: true,
	},
	{
		name: 'Membership Discounts',
		value: true,
	},
	{
		name: 'Coming soon HR Module',
		value: true,
	},
	{
		name: '100 direct SMS per month',
		value: true,
	},
	{
		name: 'Fully Account Managed',
		value: true,
	},
]);
