import React, { useState, useEffect } from 'react';

import { Button, Select, Space } from 'antd';

import { IOption } from 'types';

type CandidateDatabaseJobsSelectProps = {
	activeJobsList: IOption[];
	selectedJobs: number[];
	handleSelectJobs: (value: number[], callback: () => void) => void;
	showSearch?: boolean;
};

const { Option } = Select;

export const CandidateDatabaseJobsSelect: React.FC<CandidateDatabaseJobsSelectProps> = ({
	activeJobsList,
	selectedJobs,
	handleSelectJobs,
	showSearch = false,
}) => {
	const [draftSelections, setDraftSelections] = useState<number[]>(selectedJobs);
	const [confirmedSelections, setConfirmedSelections] = useState<number[]>(selectedJobs);
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
	const [isSearching, setIsSearching] = useState<boolean>(false);

	useEffect(() => {
		setDraftSelections(selectedJobs);
		setConfirmedSelections(selectedJobs);
	}, [selectedJobs]);

	const handleDraftSelectionChange = (values: number[]) => {
		const newSelectionsArray = Array.from(new Set([...selectedJobs, ...values]));
		setDraftSelections(newSelectionsArray);
	};

	const handleSave = () => {
		handleSelectJobs(draftSelections, () => {
			setIsDropdownOpen(false);
			isSearching && setIsSearching(false);
		});
	};

	const handleCancel = () => {
		setDraftSelections(confirmedSelections);
		setIsDropdownOpen(false);
		isSearching && setIsSearching(false);
	};

	const disabledSavedButton =
		draftSelections.length === 0 || draftSelections.length === selectedJobs.length;

	const dropdownRender = (menu: React.ReactElement) => (
		<div>
			{menu}
			<Space style={{ padding: '8px', width: '100%', justifyContent: 'space-between' }}>
				<Button type='primary' onClick={handleSave} disabled={disabledSavedButton}>
					Save
				</Button>
				<Button onClick={handleCancel}>Cancel</Button>
			</Space>
		</div>
	);

	const customFilterOption = (input: string, option?: { children: React.ReactNode }) => {
		return option?.children?.toLowerCase().includes(input.toLowerCase());
	};

	return (
		<Select
			mode='multiple'
			placeholder='- Select Job -'
			value={draftSelections}
			open={isDropdownOpen}
			maxTagCount={0}
			maxTagPlaceholder={
				isSearching
					? undefined
					: (values) =>
						`${values.length === 1 ? values[0].label : values.length + ' jobs selected'}`
			}
			onChange={handleDraftSelectionChange}
			dropdownRender={dropdownRender}
			onDropdownVisibleChange={(open) => {
				setIsDropdownOpen(open);
				setIsSearching(false);
				if (!open) {
					setDraftSelections(confirmedSelections);
				}
			}}
			showSearch={showSearch}
			onSearch={(value) => setIsSearching(value.length > 0)}
			filterOption={customFilterOption}
		>
			{activeJobsList.map((option) => (
				<Option
					key={option.id}
					value={option.id}
					disabled={selectedJobs.includes(option.id as number)}
				>
					{option.title}
				</Option>
			))}
		</Select>
	);
};
