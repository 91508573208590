import React from 'react';

import UnregisteredLayout from 'modules/Unregistered/components/UnregisteredLayout';

import { Styled } from './ErrorPages.styled';

export default function ErrorPage() {
	return (
		<UnregisteredLayout>
			<Styled.Root id='error-page'>
				<Styled.ImageBox>
					<img src='/images/500.png' alt='Internal Server Error' />
				</Styled.ImageBox>
				<Styled.BoxWrapper>
					<h3>Ooops!</h3>
					<h2>Internal Server Error</h2>
					<p>
						We apologise for the inconvenience. Our team has been notified of the issue. Please try
						again later
					</p>
				</Styled.BoxWrapper>
			</Styled.Root>
		</UnregisteredLayout>
	);
}
