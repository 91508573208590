import React, { type FC, useMemo } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Spin } from 'antd';
import { convertToRaw } from 'draft-js';
import arrayMutators from 'final-form-arrays';

import Box from 'components/Box';
import { ButtonTypes } from 'components/Button/Button.types';
import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import { commonDucks } from 'modules/Common/ducks';
import { convertContentFromHtml, convertContentToHtml } from 'modules/Common/utils/editorHelper';
import { GenericType, Routes } from 'types';

import AutoFilledInfo from './AutoFilledInfo';
import { Styled } from './EmailTemplates.styled';
import { EmailTemplatesDataType } from './EmailTemplates.types';
import TemplateFields from './TemplateFields/TemplateFields';

export type EmailTemplatesProps = {
	emailTemplatesData: EmailTemplatesDataType[];
	getClientEmailTemplates: (isAtsRoute: boolean) => void;
	updateClientEmailTemplates: (
		values: Partial<EmailTemplatesDataType[]>,
		isAtsRoute: boolean,
	) => void;
	loading: GenericType;
};

const EmailTemplates: FC<EmailTemplatesProps> = ({
	emailTemplatesData,
	getClientEmailTemplates,
	updateClientEmailTemplates,
	loading,
}) => {
	const { pathname } = useLocation();
	const isAtsRoute = pathname.includes(Routes.ATS);

	const onSubmit = (values: { templates: EmailTemplatesDataType[] }) => {
		const preparedValues = {
			templates: values?.templates?.map((item) => {
				const content =
					item?.content &&
					convertContentToHtml(
						typeof item?.content === 'string'
							? item?.content
							: JSON.stringify(convertToRaw(item?.content?.getCurrentContent())),
					)?.props?.dangerouslySetInnerHTML.__html;

				return {
					...item,
					content,
				};
			}),
		};

		updateClientEmailTemplates(preparedValues, isAtsRoute);
	};

	const initialValues = useMemo(
		() => ({
			templates: emailTemplatesData?.map((item) => ({
				id: item?.id,
				name: item?.name,
				content: convertContentFromHtml(item.content),
			})),
		}),
		[emailTemplatesData],
	);

	useMount(() => {
		getClientEmailTemplates(isAtsRoute);
	});

	const isUpdating = !!loading?.updateEmailDefaultTemplatesLoad;

	return (
		<Styled.Root>
			{loading.getEmailDefaultTemplatesLoad ? (
				<Spinner fullWidth />
			) : (
				<>
					<Form
						onSubmit={onSubmit}
						initialValues={initialValues}
						mutators={{
							...arrayMutators,
						}}
						render={({ handleSubmit, dirty }) => (
							<form onSubmit={handleSubmit}>
								<Box noSpaces fullWidth style={{ flex: '1 1 auto' }}>
									<>
										<h2>Email Templates</h2>
										{!!initialValues?.templates?.length && (
											<>
												<p>You can edit the standard email templates below.</p>

												<AutoFilledInfo />
												<TemplateFields />
											</>
										)}
									</>
								</Box>

								{!!initialValues?.templates?.length && (
									<Styled.ButtonWrapper>
										<ATSThemedButton
											buttonType={ButtonTypes.primary}
											type='submit'
											disabled={!dirty || isUpdating}
										>
											Save
											{isUpdating ? <Spin style={{ marginLeft: '12px' }} /> : ''}
										</ATSThemedButton>
									</Styled.ButtonWrapper>
								)}
							</form>
						)}
					/>
				</>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		emailTemplatesData: commonDucks.commonSelectors.getEmailTemplatesData(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		getClientEmailTemplates: commonDucks.commonActions.getEmailDefaultTemplatesRequested,
		updateClientEmailTemplates: commonDucks.commonActions.updateEmailDefaultTemplatesRequested,
	},
)(EmailTemplates);
