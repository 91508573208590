import { message } from 'antd';
import Cookies from 'js-cookie';
import { call, put } from 'redux-saga/effects';

import API from 'api';
import { CookiesType, Routes, UserRolesEnum, UserTypesEnum, TokenPurposesEnum } from 'types';
import { LocalStorageType } from 'types/storage';
import { getFromLocalStorage } from 'utils/helpers';

import { getLoginRoute, HR_MANAGER_ROLE_ID, EMPLOYEE_ROLE_ID } from './helpers';

export const createSagas = (TYPES) => {
	function* checkClientInfo({ payload, cb }) {
		try {
			const response = yield call(API.authService.getLogoBySubdomain, payload);

			yield put({
				type: TYPES.CHECK_CLIENT_INFO.SUCCESS,
				payload: response,
			});
		} catch (error) {
			console.error(TYPES.CHECK_CLIENT_INFO.FAILED, error);
			yield put({ type: TYPES.CHECK_CLIENT_INFO.FAILED, payload: error });
			yield call(cb);
		}
	}

	function* getUserById({ payload }) {
		try {
			const user = yield call(API.usersService.getUserById, payload);
			yield put({
				type: TYPES.GET_USER.SUCCESS,
				payload: user,
			});
		} catch (error) {
			console.error(TYPES.GET_USER.FAILED, error);
			yield put({ type: TYPES.GET_USER.FAILED, payload: error });
		}
	}

	function* getQuestionnaireWelcomeSaga({ payload }) {
		try {
			const questionnaireWelcome = yield call(
				API.questionnairesService.getVideoQuestionnaireWellcome,
				payload,
			);

			yield put({
				type: TYPES.GET_QUESTIONNAIRE_WELCOME.SUCCESS,
				payload: questionnaireWelcome,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_QUESTIONNAIRE_WELCOME.FAILED, payload: error });
		}
	}

	function* getQuestionnaireDetailsSaga({ payload }) {
		try {
			const questionnaireDetails = yield call(
				API.questionnairesService.getVideoQuestionnaireDetails,
				payload,
			);

			yield put({
				type: TYPES.GET_QUESTIONNAIRE_DETAILS.SUCCESS,
				payload: questionnaireDetails,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_QUESTIONNAIRE_DETAILS.FAILED, payload: error });
		}
	}

	function* getCurrentQuestionSaga({ payload }) {
		try {
			const currentQuestion = yield call(
				API.questionnairesService.getVideoQuestionnaireQuestionDetails,
				payload,
			);

			yield put({
				type: TYPES.GET_CURRENT_QUESTION.SUCCESS,
				payload: currentQuestion,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_CURRENT_QUESTION.FAILED, payload: error });
		}
	}

	function* getEmailThreadSaga({ payload, cb }) {
		try {
			const emailThread = yield call(API.emailService.getEmailThreadOnCandidateOpenPage, payload);

			yield put({
				type: TYPES.GET_EMAIL_THREAD.SUCCESS,
				payload: emailThread,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_EMAIL_THREAD.FAILED, payload: error });
			cb && (yield call(cb));
		}
	}

	function* replyToEmailThreadSaga({ payload, cb }) {
		try {
			const emailThread = yield call(
				API.emailService.replyToEmailThreadOnCandidateOpenPage,
				payload,
			);

			yield put({
				type: TYPES.REPLY_TO_EMAIL_THREAD.SUCCESS,
				payload: emailThread,
			});
			message.success('The email has been sent');

			cb && (yield call(cb));
		} catch (error) {
			yield put({ type: TYPES.REPLY_TO_EMAIL_THREAD.FAILED, payload: error });
			message.error('The email has not been sent');
		}
	}

	function* changePasswordSaga({ payload, callback }) {
		try {
			let route = '';
			const user = getFromLocalStorage(LocalStorageType.User);
			const isBOUser = user?.userType === UserTypesEnum.BACKOFFICE;
			const isAtsUser = user?.userType === UserTypesEnum.ATS;
			const isHrUser = [HR_MANAGER_ROLE_ID, EMPLOYEE_ROLE_ID].includes(user?.roles[0].id);

			const { isTempPassword } = yield call(API.authService.changePassword, isBOUser, payload);

			localStorage.setItem(LocalStorageType.User, JSON.stringify({ ...user, isTempPassword }));

			yield put({ type: TYPES.CHANGE_PASSWORD.SUCCESS, payload: { ...user, isTempPassword } });

			if (isBOUser) {
				route = `${Routes.BackOffice}${Routes.Dashboard}`;
			} else if (isHrUser) {
				route = `${Routes.HRModule}${Routes.Dashboard}`;
			} else if (isAtsUser && user?.roles[0].id === UserRolesEnum.JAT_FINANCE_USER) {
				route = `${Routes.ATS}${Routes.Credits}`;
			} else if (isAtsUser) {
				route = `${Routes.ATS}${Routes.Dashboard}`;
			}

			yield callback(route);
		} catch (error) {
			console.error(TYPES.CHANGE_PASSWORD.FAILED, error);
			yield put({ type: TYPES.CHANGE_PASSWORD.FAILED, payload: error });
		}
	}

	function* forgotPasswordSaga({ payload, callback }) {
		try {
			yield call(API.authService.forgotPassword, payload);
			yield put({
				type: TYPES.FORGOT_PASSWORD.SUCCESS,
			});

			yield call(callback);
		} catch (error) {
			yield put({ type: TYPES.FORGOT_PASSWORD.FAILED, payload: error });
		}
	}

	function* resetPasswordSaga({ payload, callback }) {
		try {
			const { email } = yield call(API.authService.resetPassword, payload);

			yield put({ type: TYPES.RESET_PASSWORD.SUCCESS });

			yield put({
				type: TYPES.LOGIN.REQUESTED,
				payload: { email, password: payload?.password },
				callback,
				isResetPass: true,
			});
		} catch (error) {
			yield put({ type: TYPES.RESET_PASSWORD.FAILED, payload: error });
		}
	}

	function* impersonateSaga({ payload }) {
		try {
			const { userId, callback } = payload;
			const { token, ...user } = yield call(API.authService.impersonateUser, userId);

			const boImpersonateJWT = Cookies.get(CookiesType.JWT);
			const boImpersonateUserId = Cookies.get(CookiesType.UserId);

			yield call(Cookies.set, CookiesType.boImpersonateJWT, boImpersonateJWT);
			yield call(Cookies.set, CookiesType.boImpersonateUserId, boImpersonateUserId);
			yield call(Cookies.set, CookiesType.JWT, token);
			yield call(Cookies.set, CookiesType.UserId, user.userId);

			yield localStorage.clear;
			localStorage.setItem(LocalStorageType.User, JSON.stringify(user));

			yield put({ type: TYPES.IMPERSONATE.SUCCESS, payload: user });
			yield call(callback);
			const updatedUser = yield call(API.usersService.getUserById, user.userId);
			yield put({
				type: TYPES.GET_USER.SUCCESS,
				payload: updatedUser,
			});
		} catch (e) {
			yield put({ type: TYPES.IMPERSONATE.FAILED, payload: e });
		}
	}

	function* unImpersonateSaga({ payload }) {
		try {
			const userId = Cookies.get(CookiesType.boImpersonateUserId);
			const token = Cookies.get(CookiesType.boImpersonateJWT);
			yield call(Cookies.set, CookiesType.JWT, token);
			yield call(Cookies.set, CookiesType.UserId, userId);

			const user = yield call(API.usersService.getUserById, userId);

			yield call(Cookies.remove, CookiesType.boImpersonateUserId);
			yield call(Cookies.remove, CookiesType.boImpersonateJWT);

			yield localStorage.clear;
			localStorage.setItem(LocalStorageType.User, JSON.stringify(user));
			yield call(payload);

			yield put({ type: TYPES.UN_IMPERSONATE.SUCCESS });
		} catch (e) {
			yield put({ type: TYPES.UN_IMPERSONATE.FAILED, payload: e });
		}
	}

	function* finishLogin({ isResetPass, isTempPassword, token, user }) {
		try {
			yield call(Cookies.set, CookiesType.JWT, token);
			yield call(Cookies.set, CookiesType.UserId, user.userId);

			// Not saving isTempPassword after reset password
			yield localStorage.clear;
			localStorage.setItem(
				LocalStorageType.User,
				JSON.stringify({ ...(!isResetPass && { isTempPassword }), ...user }),
			);

			const updatedUser = yield call(API.usersService.getUserById, user.userId);
			yield put({
				type: TYPES.GET_USER.SUCCESS,
				payload: updatedUser,
			});
		} catch (e) {
			console.error('finish login error', e);
		}
	}

	function* twoFALoginSaga({ payload }) {
		try {
			const { values, jwt2faToken, callback } = payload;
			const { token, isTempPassword, ...user } = yield call(
				API.authService.twoFALogin,
				values,
				jwt2faToken,
			);

			yield call(finishLogin, { isTempPassword, token, user });

			const route = getLoginRoute({
				user,
				isTempPassword,
			});

			yield put({ type: TYPES.TWOFA_LOGIN.SUCCESS, payload: user });

			yield callback(route);
		} catch (error) {
			yield put({
				type: TYPES.TWOFA_LOGIN.FAILED,
				payload: error,
			});
			console.error('finish login error', error);
		}
	}

	function* resendTwoFACodeSaga({ payload }) {
		try {
			const { jwt2faToken, callback } = payload;
			const { token } = yield call(API.authService.twoFAResendCode, jwt2faToken);

			yield call(callback, token);
			yield put({ type: TYPES.RESEND_TWOFA_CODE.SUCCESS });
		} catch (error) {
			yield put({ type: TYPES.RESEND_TWOFA_CODE.FAILED, payload: error });
		}
	}

	function* login({ payload, callback, isResetPass = false }) {
		try {
			const creds = payload;

			const { token, isTempPassword, tokenPurpose, ...user } = yield call(
				API.authService.login,
				creds,
			);

			yield put({ type: TYPES.LOGIN.SUCCESS, payload: user });

			if (tokenPurpose === TokenPurposesEnum.AUTH) {
				yield call(finishLogin, { isResetPass, isTempPassword, token, user });
			}

			const is2FA = tokenPurpose === TokenPurposesEnum.TWO_FA;

			const route = getLoginRoute({
				user,
				isTempPassword,
				is2FA,
				jwt2fa: is2FA ? token : null,
			});

			yield callback(route);
		} catch (error) {
			console.error(TYPES.LOGIN.FAILED, error);
			yield put({ type: TYPES.LOGIN.FAILED, payload: 'The email or password is incorrect' });
		}
	}

	function* logOut({ payload: { callback } }) {
		try {
			yield call(API.authService.logout);

			yield put({ type: TYPES.LOGOUT.SUCCESS });
			yield call(Cookies.remove, CookiesType.boImpersonateJWT);
			yield call(Cookies.remove, CookiesType.boImpersonateUserId);
			yield call(Cookies.remove, CookiesType.JWT);
			yield call(Cookies.remove, CookiesType.UserId);
			yield localStorage.clear;
			yield call(callback);
			window.location.reload();
		} catch (error) {
			console.error(TYPES.LOGOUT.FAILED, error);
			yield put({ type: TYPES.LOGOUT.FAILED, payload: error });
		}
	}

	return {
		checkClientInfo,
		getQuestionnaireWelcomeSaga,
		getQuestionnaireDetailsSaga,
		getCurrentQuestionSaga,
		getEmailThreadSaga,
		replyToEmailThreadSaga,
		changePasswordSaga,
		forgotPasswordSaga,
		resetPasswordSaga,
		getUserById,
		login,
		logOut,
		impersonateSaga,
		unImpersonateSaga,
		twoFALoginSaga,
		resendTwoFACodeSaga,
	};
};
