import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function BlueTownSmallLogo({ fill = COLORS.black, width = '26', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 26 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M25.4859 7.7068V3.12036C25.4859 2.82466 25.3288 2.55171 25.0724 2.40386L21.1001 0.110643C20.8447 -0.0372065 20.5283 -0.0372065 20.273 0.110643L16.3027 2.40283H16.3017L12.743 4.45721L9.18424 2.40283L5.21299 0.110643C4.95761 -0.0372065 4.64123 -0.0372065 4.38586 0.110643L0.413565 2.40386C0.157155 2.55171 0 2.82466 0 3.12036V7.7068C0 8.0025 0.157155 8.27545 0.413565 8.4233L3.14516 10L0.413565 11.5767C0.157155 11.7246 0 11.9975 0 12.2932V16.8797C0 17.1754 0.157155 17.4483 0.413565 17.5962L4.38586 19.8894C4.51406 19.9628 4.65674 20 4.79942 20C4.9421 20 5.08478 19.9628 5.21299 19.8894L9.18528 17.5962L12.744 15.5418L16.3027 17.5962C16.3027 17.5962 16.3027 17.5962 16.3038 17.5962L20.274 19.8884C20.4022 19.9618 20.5449 19.999 20.6876 19.999C20.8302 19.999 20.9729 19.9618 21.1011 19.8884L25.0734 17.5951C25.3298 17.4473 25.487 17.1743 25.487 16.8786V12.2922C25.487 11.9965 25.3298 11.7235 25.0734 11.5757L22.3418 9.99898L25.0734 8.42226C25.3298 8.27442 25.487 8.00146 25.487 7.70576L25.4859 7.7068ZM9.59781 8.18447L12.743 6.36891L15.8881 8.18447V11.8156L12.743 13.6311L9.59781 11.8156V8.18447ZM15.8871 6.27379L14.3962 5.41358L15.8871 4.55336V6.27483V6.27379ZM9.59781 6.27379V4.55233L11.0887 5.41255L9.59781 6.27276V6.27379ZM7.94355 10.8602L6.45265 10L7.94355 9.1398V10.8613V10.8602ZM9.59781 13.7252L11.0887 14.5854L9.59781 15.4456V13.7242V13.7252ZM15.8871 13.7252V15.4467L14.3962 14.5865L15.8871 13.7262V13.7252ZM17.5414 9.13877L19.0323 9.99898L17.5414 10.8592V9.13773V9.13877ZM1.65426 3.59803L4.79942 1.78248L7.94458 3.59803V7.22913L4.79942 9.04468L1.65426 7.22913V3.59803ZM7.94355 16.402L4.79839 18.2176L1.65323 16.402V12.7709L4.79839 10.9554L7.94355 12.7709V16.402ZM23.8317 16.402L20.6865 18.2176L17.5414 16.402V12.7709L20.6865 10.9554L23.8317 12.7709V16.402ZM23.8317 7.22913L20.6865 9.04468L17.5414 7.22913V3.59803L20.6865 1.78248L23.8317 3.59803V7.22913Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(BlueTownSmallLogo);
