import { IOption } from 'types';

export enum TypeOfQuestionTitleEnum {
	textField = 'Text field',
	yesNoQn = 'Yes/No Question',
	multiChoice = 'Multi-choice',
}

export enum TypeOfQuestionEnum {
	textField = 'TEXT',
	yesNoQn = 'BOOLEAN',
	multiChoice = 'MULTI',
}

export interface IAssessmentAnswers {
	id: number;
	answer: number;
	correct: boolean;
	text: string;
	checked: boolean;
}

export interface IAssessmentQuestions {
	id: number;
	job?: number;
	autoreject: boolean;
	questionText: string;
	questionType: TypeOfQuestionEnum;
	candidateApplication: string;
	candidateApplicationAnswers?: IAssessmentAnswers[];
	assessmentAnswers?: IAssessmentAnswers[];
}

export interface IAnswersOptions {
	answer: string;
	correct: boolean;
}

export type AQFieldProps = {
	options?: IAnswersOptions[];
	onChangeCallback?: (data: IAnswersOptions[]) => void;
	readOnly?: boolean;
};

export const InitYesNoQns = [
	{ answer: 'Yes', correct: true },
	{ answer: 'No', correct: false },
];

export const InitItem = {
	autoreject: true,
	questionText: '',
	questionType: TypeOfQuestionEnum.yesNoQn,
	assessmentAnswers: InitYesNoQns,
};

export const typeOfQuestionsData: IOption[] = [
	{
		name: TypeOfQuestionTitleEnum.yesNoQn,
		id: TypeOfQuestionEnum.yesNoQn,
	},
	{
		name: TypeOfQuestionTitleEnum.multiChoice,
		id: TypeOfQuestionEnum.multiChoice,
	},
	{
		name: TypeOfQuestionTitleEnum.textField,
		id: TypeOfQuestionEnum.textField,
	},
];
