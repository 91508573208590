import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Modal } from 'antd';

import Button, { ButtonTypes } from 'components/Button';
import { SettingsRoutes } from 'types';

import { Styled } from './SubscriptionModal.styled';

type DashboardProps = {
	modalOpen: boolean;
	handleCloseModal: () => void;
};

const SubscriptionModal = ({ modalOpen, handleCloseModal }: DashboardProps) => {
	const navigate = useNavigate();

	return (
		<Modal open={!!modalOpen} footer={null} centered onCancel={handleCloseModal} width={500}>
			<Styled.ConfirmModalTitle>
				This feature requires a pro membership, upgrade or try free here
			</Styled.ConfirmModalTitle>
			<Styled.ConfirmModalButtons>
				<Button
					buttonType={ButtonTypes.primary}
					onClick={() => navigate(SettingsRoutes.Subscription)}
				>
					Upgrade Plan
				</Button>
				<Button buttonType={ButtonTypes.secondary} onClick={handleCloseModal}>
					Cancel
				</Button>
			</Styled.ConfirmModalButtons>
		</Modal>
	);
};

export default SubscriptionModal;
