import { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Button, Tabs, Tooltip } from 'antd';

import Box from 'components/Box';
import Spinner from 'components/Spinner';
import DownloadIcon from 'components/SVG/DownloadIcon';
import EmailFilledIcon from 'components/SVG/EmailFilledIcon';
import EyeCloseIcon from 'components/SVG/EyeCloseIcon';
import EyeOpenIcon from 'components/SVG/EyeOpenIcon';
import PhoneIcon from 'components/SVG/PhoneIcon';
import { useMount } from 'hooks';
import CandidateDatabaseJobsSelect from 'modules/ATS/components/CandidateDatabaseJobsSelect';
import { atsDucks } from 'modules/ATS/ducks';
import PDFViewer from 'modules/Common/components/PDFViewer';
import { AssignCandidateToVacancyDatabaseResponseType } from 'modules/Common/types';
import { handleJobAssignNotificationMessages } from 'modules/Common/utils/commonHelpers';
import { COLORS } from 'theme';
import { GenericType, IOption } from 'types';
import { handleFileDownload } from 'utils/helpers';

import { Styled } from './CandidateDatabaseApplicationDetails.styled';
import { CandidateType } from './CandidateDatabaseApplicationDetails.types';

type CandidateDatabaseApplicationDetailsProps = {
	applicationId: number;
	candidateDatabaseDetails: CandidateType;
	activeJobsList: IOption[];
	loading: GenericType;
	getCandidateDatabaseByIdRequested: (applicationId: number) => void;
	getAtsActiveJobsRequested: () => void;
	showHideCandidateInDatabaseRequested: (
		applicationId: number,
		data: { showAction: string },
	) => void;
	assignCandidateToVacancyDatabase: (
		candidateApplicationId: number,
		jobs: number[],
		callback: (response: AssignCandidateToVacancyDatabaseResponseType) => void,
	) => void;
};

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const CandidateDatabaseApplicationDetails: FC<CandidateDatabaseApplicationDetailsProps> = ({
	applicationId,
	candidateDatabaseDetails,
	activeJobsList,
	loading,
	getCandidateDatabaseByIdRequested,
	getAtsActiveJobsRequested,
	assignCandidateToVacancyDatabase,
	showHideCandidateInDatabaseRequested,
}) => {
	const [selectedJobs, setSelectedJobs] = useState<number[]>([]);
	const {
		candidate: candidateName,
		hidden: candidateIsHidden,
		phoneNumber,
		email,
		assignedToJobs,
		cv,
	} = candidateDatabaseDetails || {};

	useEffect(() => {
		const jobs = assignedToJobs?.map((job) => job.id) || [];
		setSelectedJobs(jobs);
	}, [candidateDatabaseDetails]);

	useMount(() => {
		!candidateDatabaseDetails && getCandidateDatabaseByIdRequested(applicationId);
		!activeJobsList?.length && getAtsActiveJobsRequested();
	});

	const icon = candidateIsHidden ? (
		<EyeCloseIcon fill={COLORS.black} width='16' height='16' />
	) : (
		<EyeOpenIcon fill={COLORS.black} width='16' height='16' />
	);

	const items = [
		{
			label: 'CV',
			key: 'CV',
			children: (
				<>
					{candidateDatabaseDetails?.cv?.referenceUuid && (
						<PDFViewer
							pdfUrl={`${baseUrl}/file/${candidateDatabaseDetails.cv.referenceUuid}`}
							isBorder
						/>
					)}
				</>
			),
		},
	];

	const handleCVDownload = useCallback(() => {
		const { referenceUuid, originalFileName } = candidateDatabaseDetails.cv;
		if (referenceUuid && originalFileName) {
			handleFileDownload(referenceUuid, originalFileName);
		}
	}, [candidateDatabaseDetails]);

	const handleVisibilityOfCandidate = () => {
		showHideCandidateInDatabaseRequested(applicationId, {
			showAction: candidateIsHidden ? 'SHOW' : 'HIDE',
		});
	};

	const handleSelectJobs = (values: number[], closeDropdown: () => void) => {
		const prevValuesSet = new Set(selectedJobs);
		const newValues = values.filter((value) => !prevValuesSet.has(value));

		assignCandidateToVacancyDatabase(applicationId, newValues, (res) => {
			handleJobAssignNotificationMessages(res);
			setSelectedJobs((prev) => [...prev, ...newValues]);
			closeDropdown();
			getCandidateDatabaseByIdRequested(applicationId);
		});
	};

	if (loading?.getCandidateDatabaseByIdLoad) {
		return <Spinner fixed />;
	}

	const isDisabledCVButton = !cv || !cv?.referenceUuid || cv?.referenceUuid === '?';

	return (
		<Box>
			<Styled.HeadWrapper>
				<Styled.Title>{candidateName}</Styled.Title>
				<Styled.HeadActions>
					<Styled.SelectWrapper>
						<CandidateDatabaseJobsSelect
							activeJobsList={activeJobsList}
							selectedJobs={selectedJobs}
							handleSelectJobs={handleSelectJobs}
						/>
					</Styled.SelectWrapper>
					<Tooltip placement='top' title={candidateIsHidden ? 'Show Candidate' : 'Hide Candidate'}>
						<Button icon={icon} size='large' onClick={handleVisibilityOfCandidate} />
					</Tooltip>
					<Tooltip placement='top' title='Download CV'>
						<Button
							icon={<DownloadIcon fill={COLORS.black} width='24' height='24' />}
							size='large'
							onClick={handleCVDownload}
							disabled={isDisabledCVButton}
						/>
					</Tooltip>
				</Styled.HeadActions>
			</Styled.HeadWrapper>
			<Styled.CandidateInfo>
				{phoneNumber && (
					<a href={`tel:${phoneNumber}`}>
						<PhoneIcon fill={COLORS.gray} width='16' height='16' />
						<span>{phoneNumber}</span>
					</a>
				)}
				{email && (
					<a href={`mailto:${email}`}>
						<EmailFilledIcon fill={COLORS.gray} width='16' height='16' />
						<span>{email}</span>
					</a>
				)}
			</Styled.CandidateInfo>
			<Styled.DetailsInfoMain>
				<Tabs defaultActiveKey={'CV'} items={items} tabBarStyle={{ fontFamily: 'Inter' }} />
			</Styled.DetailsInfoMain>
		</Box>
	);
};

export default connect(
	(state) => ({
		candidateDatabaseDetails: atsDucks.atsSelectors.getAtsCandidateDatabaseDetailsState(state),
		activeJobsList: atsDucks.atsSelectors.getActiveJobsData(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getCandidateDatabaseByIdRequested: atsDucks.atsActions.getCandidateDatabaseByIdRequested,
		getAtsActiveJobsRequested: atsDucks.atsActions.getAtsActiveJobsRequested,
		showHideCandidateInDatabaseRequested: atsDucks.atsActions.showHideCandidateInDatabaseRequested,
		assignCandidateToVacancyDatabase: atsDucks.atsActions.assignCandidateToVacancyDatabaseRequested,
	},
)(CandidateDatabaseApplicationDetails);
