import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		background-color: ${COLORS.white};
		padding: 56px;
	`;
}
