import React, { MouseEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'antd';

import { getInvoicesCSV } from 'api/endpoints/invoices';
import { Routes } from 'types';
import { saveFile } from 'utils/helpers';

import Invoices from '../../containers/Invoices';

import { Styled } from './InvoicesPage.styled';

export const InvoicesPage = () => {
	const navigate = useNavigate();
	const { clientId } = useParams();

	const handleDownloadInvoices = async (event: MouseEvent) => {
		event.preventDefault();

		const csv = await getInvoicesCSV(clientId);
		const blob = new Blob([csv], { type: 'text/csv' });

		saveFile(blob, 'invoices');
	};

	const createInvoicePath = clientId
		? `${Routes.BOClientContext}/${clientId}${Routes.InvoicesCreate}`
		: `${Routes.BackOffice}${Routes.InvoicesCreate}`;

	return (
		<>
			<Styled.Head>
				<Styled.Title>Invoices</Styled.Title>
				<Styled.ButtonsBox>
					<Button type='default' htmlType='button' onClick={handleDownloadInvoices}>
						Download CSV
					</Button>
					<Button type='primary' htmlType='button' onClick={() => navigate(createInvoicePath)}>
						Create New
					</Button>
				</Styled.ButtonsBox>
			</Styled.Head>
			<Styled.Content>
				<Invoices clientContextId={clientId} />
			</Styled.Content>
		</>
	);
};
