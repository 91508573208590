import { Select as SelectComponent } from 'antd';
import styled from 'styled-components/macro';

import Box from 'components/Box';
import { StatusColorsType } from 'modules/Common/types';
import { COLORS, device } from 'theme';

interface ITicketFieldProps {
	isTransparent?: boolean;
}

type SelectItemProps = {
	background?: string;
	color?: string;
};

export namespace Styled {
	export const Root = styled.div``;
	export const BoxWrap = styled(({ isBoxComponent, ...props }) => {
		const Component = isBoxComponent ? Box : 'div';

		return <Component {...props} />;
	})``;
	export const Header = styled.div`
		padding-bottom: 24px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
	`;
	export const HeaderMain = styled.div`
		padding-bottom: 24px;
	`;
	export const HeaderAdditional = styled.div`
		padding-top: 8px;
		display: flex;
		align-items: center;
	`;
	export const Title = styled.div`
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
		margin-bottom: 8px;
	`;
	export const SubTitle = styled.div`
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	`;
	export const StatusWrapper = styled.div`
		margin-left: 32px;
	`;

	export const FieldRow = styled.div`
		display: flex;
		gap: 32px;
		margin-bottom: 24px;

		&:last-child > div {
			margin-bottom: 0;
		}
	`;

	export const FieldWrapper = styled.div`
		width: 100%;
	`;

	export const FieldLabel = styled.div`
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
	`;

	export const Field = styled.div<ITicketFieldProps>`
		display: flex;
		flex-direction: column;
		width: 100%;

		span {
			display: block;
			width: 100%;
			min-height: 40px;
			margin-top: 8px;
			padding: ${({ isTransparent }) => (isTransparent ? '0' : '8px 12px')};
			flex: 1;
			font-weight: ${({ isTransparent }) => (isTransparent ? '400' : '500')};
			line-height: 22px;
			background: ${({ isTransparent }) => (isTransparent ? 'transparent' : COLORS.lightGray3)};
			border: ${({ isTransparent }) => (isTransparent ? 'none' : `1px solid ${COLORS.lightGray1}`)};
			border-radius: 4px;
			color: ${({ isTransparent }) => (isTransparent ? COLORS.black : COLORS.darkGray2)};
			white-space: break-spaces;
		}

		@media ${device.tablet} {
			width: 100%;
		}
	`;

	export const RichDescription = styled.div`
		white-space: normal;

		ul {
			margin: auto;
			margin-bottom: 1em;
			padding: revert;
			list-style: disc;
		}
	`;

	export const Attachment = styled.div`
		border: 1px solid ${COLORS.gray};
		display: flex;
		justify-content: space-between;
		padding: 10px;
		margin-top: 8px;
		transition: all 0.2s ease;
		color: ${COLORS.black};
		&:hover {
			background-color: transparent;
			box-shadow: 0px 3px 5px 0px ${COLORS.gray};
			cursor: pointer;
		}
	`;

	export const AttachmentFileName = styled.div`
		display: flex;
		svg {
			margin-right: 8px;
		}
	`;

	export const Select = styled(SelectComponent)<SelectItemProps>`
		&.ant-select {
			min-width: 140px;
			text-align: center;
			margin-left: 20px;
			.ant-select-selector {
				background: ${(props) => props.background || StatusColorsType.LightBlue};
				color: ${(props) => props.color || StatusColorsType.DarkBlue};
				border: 1px solid ${(props) => props.color || StatusColorsType.DarkBlue};
				font-weight: bold;
			}
		}
	`;
}
