import styled from 'styled-components/macro';

export namespace Styled {
	export const Chart = styled.div`
		width: 230px;
		height: 230px;
		margin: 0 auto;
	`;

	export const Legend = styled.ul`
		margin-top: 24px;
		li {
			margin-bottom: 12px;
			display: flex;
		}
		.legendBox {
			display: inline-block;
			width: 16px;
			height: 16px;
			margin-right: 12px;
			border-radius: 2px;
		}
		.legendText {
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
		}
		.legendValue {
			font-weight: bold;
			margin-left: auto;
		}
	`;
}
