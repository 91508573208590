import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import TwoFAForm from 'modules/Unregistered/containers/TwoFAForm';

import UnregisteredLayout from '../../components/UnregisteredLayout';
import UnregisteredUser from '../../containers/UnregisteredUser';

import * as Styled from './TwoFAPage.styled';

type TwoFAPageProps = {
	isFinishRegistration?: boolean;
};

export const TwoFAPage: FC<TwoFAPageProps> = () => {
	const { search } = useLocation();
	const urlParams = new URLSearchParams(search);

	const jwt2faToken = urlParams?.get('jwt2fa');

	return (
		<UnregisteredLayout>
			<UnregisteredUser
				pageContent={
					<Styled.FormWrapper>
						<Styled.FormTitle>Enter 2FA code</Styled.FormTitle>
						{jwt2faToken && <TwoFAForm jwt2faToken={jwt2faToken} />}
					</Styled.FormWrapper>
				}
			/>
		</UnregisteredLayout>
	);
};
