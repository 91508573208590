import { InvoiceTypeValuesEnum } from 'modules/Common/types';

export const InvoiceFields = [
	'id',
	'name',
	'contract',
	'currency',
	'dateDue',
	'receipt',
	'btoReference',
	'clientName',
	'paymentStatus',
	'status',
	'value',
	'invoiceType',
	'premiumPrice',
	'subscriptionPlanType',
	'currency',
];

export const HiddenContracts = [InvoiceTypeValuesEnum.CreditsPurchase, InvoiceTypeValuesEnum.PremiumSubscritionManual];
