import dayjs from 'dayjs';

interface DateRange {
	fromDate: string;
	toDate: string;
}

export const generateDateParams = (dateRange: DateRange): string[] => {
	const startDate = dayjs(dateRange.fromDate);
	const endDate = dayjs(dateRange.toDate);

	if (startDate.get('month') === endDate.get('month')) return [startDate.format('YYYY-MM')];

	if (startDate.add(2, 'month').isSame(endDate, 'month')) {
		return [startDate.add(1, 'month').format('YYYY-MM')];
	}

	return [startDate.format('YYYY-MM'), endDate.format('YYYY-MM')];
};
