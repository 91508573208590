export interface ISubsPrices {
	ukMonthlyPrice: number;
	ukAnnualPrice: number;
	usaMonthlyPrice: number;
	usaAnnualPrice: number;
}

export interface IDefaultPrice {
	id: number;
	interval: 'MONTH' | 'YEAR';
	value: 'Annually' | 'Monthly';
	name: 'MONTHLY' | 'ANNUALLY';
	price: number;
}

export const enum PremiumStatusEnum {
	Active = 'ACTIVE',
	Canceled = 'CANCELED',
	Postponed = 'POSTPONED',
	Scheduled = 'SCHEDULED',
}

export interface ICurrentSubscription {
	premiumType: {
		name: 'Annually' | 'Monthly';
		price: number;
		interval: 'YEAR' | 'MONTH';
	};
	nextAnnualPrice: string;
	nextMonthlyPrice: string;
	premium: true;
	nextChargeDate: string;
	premiumEndDate: string;
	premiumStartDate: string;
	premiumStatus: PremiumStatusEnum;
	price: string;
	trial: false;
	trialAvailable: true;
	trialEndDate: string;
	trialState: string;
	chargeVat: boolean;
}

export enum SubscriptionPayPremiumStatusesEnum {
	Success = 'success',
	Fail = 'fail',
	CardUpdated = 'card_updated',
}
