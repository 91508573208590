import React, { FC, useEffect, useState } from 'react';
import { Field, FieldInputProps, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import TextArea from 'antd/es/input/TextArea';
import _ from 'lodash';

import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import Spinner from 'components/Spinner';
import CloseIcon from 'components/SVG/CloseIcon';
import EditIcon from 'components/SVG/EditIcon';
import { useMount, useUnmount } from 'hooks';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import { atsDucks } from 'modules/ATS/ducks';
import FormBlock from 'modules/Common/components/FormBlock';
import { COLORS } from 'theme';
import { GenericType, Routes } from 'types';
import {
	composeValidators,
	lengthValidator,
	requiredFieldValidator,
	smsValidator,
	textValidator,
} from 'utils/validators';

import { EditReceiverModal } from './EditReceiverModal/EditReceiverModal';
import { classifyAndHandleErrorMessage } from './SendSMS.helpers';
import { Styled } from './SendSMS.styled';
import { CandidateInformation, SMSHistory } from './SendSmsTypes';
import { SMSHistoryModal } from './SMSHistoryModal/SMSHistoryModal';

const MAX_CHARACTERS = 160;

type SMSCountType = {
	available: number;
	total: number;
};

type UpdatedCandidateType = {
	email: string;
	fullName: string;
	id: number;
	jobId: number;
	jobTitle: string;
	phoneNumber: string;
};

type SendSMSProps = {
	smsCount: SMSCountType | null;
	candidatesSMSInformation: CandidateInformation[];
	atsLoading?: GenericType;
	updateListOfSelectedCandidates: (selectedCandidates: number[]) => void;
	getSMSCountRequested: () => void;
	getCandidateSMSInformationRequested: (ids: string[]) => void;
	updateCandidatePhoneNumberRequested: (
		values: {
			candidateAppId: number;
			phoneNumber: string;
		},
		callback: (candidate: UpdatedCandidateType) => void,
	) => void;
	sendSMSToCandidateRequested: (
		values: { candidateIds: number[]; text: string },
		callback: (errorMessage?: string) => void,
	) => void;
	resetCandidatesSMSInformation: () => void;
};

const SendSMS: FC<SendSMSProps> = ({
	smsCount,
	candidatesSMSInformation,
	atsLoading,
	updateListOfSelectedCandidates,
	getSMSCountRequested,
	getCandidateSMSInformationRequested,
	updateCandidatePhoneNumberRequested,
	sendSMSToCandidateRequested,
	resetCandidatesSMSInformation,
}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { selectedCandidateIds, prevPage } = location.state || {};
	const [editedCandidate, setEditedCandidate] = useState<CandidateInformation | null>(null);
	const [charCount, setCharCount] = useState<number>(0);
	const [candidateSMSHistory, setCandidateSMSHistory] = useState<SMSHistory | null>(null);
	const [candidatesList, setCandidatesList] = useState<CandidateInformation[]>([]);
	const [availableSMSLimitError, setAvailableSMSLimitError] = useState<boolean>(false);
	const [smsLastSendingError, setSMSLastSendingError] = useState<string>('');
	const { total: totalNumOfSMS = 100, available: availableNumOfSMS = 100 } = smsCount || {};

	useMount(() => {
		if (selectedCandidateIds) {
			getCandidateSMSInformationRequested(selectedCandidateIds);
		}
	});

	useUnmount(() => {
		resetCandidatesSMSInformation();
	});

	useEffect(() => {
		if (candidatesSMSInformation?.length) {
			if (candidatesList.length) {
				const localCandidatesIds = candidatesList.map(
					(candidate: CandidateInformation) => candidate.id,
				);

				setCandidatesList(() => [
					...candidatesSMSInformation.filter((candidate) =>
						localCandidatesIds.includes(candidate.id),
					),
				]);
			} else {
				setCandidatesList(candidatesSMSInformation);
			}
		}
	}, [candidatesSMSInformation]);

	useEffect(() => {
		updateListOfSelectedCandidates(candidatesList.map((candidate) => candidate.id));
	}, [candidatesList]);

	const handleCloseForm = () => {
		navigate(prevPage || `${Routes.ATS}${Routes.Candidates}`);
	};

	const handleDeleteReceiver = (id: number) => {
		setCandidatesList(candidatesList.filter((candidate) => candidate.id !== id));
		if (availableNumOfSMS >= candidatesList.length) setAvailableSMSLimitError(false);
	};

	const handleEditReceiver = (candidate: CandidateInformation) => {
		setEditedCandidate(candidate);
	};

	const handleCandidatePhoneChange = (candidateAppId: number, phoneNumber: string) => {
		const updateCandidateInList = (candidate: UpdatedCandidateType) => {
			const updatedCandidatesList = _.map(candidatesList, (c) => {
				return c.id === candidate.id
					? _.assign({}, c, {
						phoneNumber: candidate.phoneNumber,
						smsFailedToBeSent: false,
						isInvalidPhoneNumber: false,
					  })
					: c;
			});
			setCandidatesList(updatedCandidatesList);
		};

		updateCandidatePhoneNumberRequested({ candidateAppId, phoneNumber }, updateCandidateInList);
		handleCloseReceiverModal();
	};

	const handleCloseReceiverModal = () => setEditedCandidate(null);

	const handleShowSMSHistory = (smsHistory: SMSHistory) => {
		setCandidateSMSHistory(smsHistory);
	};

	const handleHideSMSHistory = () => {
		setCandidateSMSHistory(null);
	};

	const handleTextChange = (input: FieldInputProps<string, HTMLElement>) => {
		const inputLength = input.value.length;
		setCharCount(inputLength);
		input.onChange(input.value);
	};

	const allNumbersValid = candidatesList.every((candidate) => !candidate.isInvalidPhoneNumber);
	const smsSendingFailedPhoneNumber = candidatesList.some(
		(candidate) => candidate?.smsFailedToBeSent === true,
	);

	const handlesSMSSendCallback = (errorMessage?: string) => {
		if (errorMessage) {
			classifyAndHandleErrorMessage({
				message: errorMessage,
				candidatesList,
				getSMSCount: getSMSCountRequested,
				setCandidatesList,
				setErrorMessage: setSMSLastSendingError,
			});
		} else {
			handleCloseForm();
		}
	};

	const onSubmit = async (values: { text: string }) => {
		if (candidatesList.length > availableNumOfSMS) return;
		if (!allNumbersValid || smsSendingFailedPhoneNumber) return;

		const candidateIds = candidatesList.map((candidate: CandidateInformation) => candidate.id);
		const text = values?.text as string;

		await sendSMSToCandidateRequested({ candidateIds, text }, handlesSMSSendCallback);
	};

	if (atsLoading?.getCandidateSmsInformationLoad) {
		return <Spinner fixed />;
	}

	const submitButtonDisabled = !!atsLoading?.sendSmsToCandidateLoad;

	return (
		<>
			<Styled.Main>
				<Form
					onSubmit={onSubmit}
					initialValues={{}}
					autoComplete='off'
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<FormBlock>
								<Styled.Receivers>
									<Styled.ReceiversHeader>Receivers</Styled.ReceiversHeader>
									<Styled.ReceiversList>
										{candidatesList.map((candidate, __, list) => (
											<Styled.ReceiverBox
												key={candidate.id}
												className={
													candidate.isInvalidPhoneNumber === true ||
													candidate?.smsFailedToBeSent === true
														? 'error'
														: ''
												}
											>
												<Styled.ReceiverFirstLine>
													<Styled.ReceiverName>{candidate.fullName}</Styled.ReceiverName>
													{candidate.smsHistory.length ? (
														<Styled.ReceiverHistoryBadge
															onClick={() => handleShowSMSHistory(candidate.smsHistory)}
														>
															{candidate.smsHistory.length}
														</Styled.ReceiverHistoryBadge>
													) : (
														<></>
													)}
													{list.length > 1 ? (
														<Styled.ReceiverRemoveIcon
															onClick={() => handleDeleteReceiver(candidate.id)}
														>
															<CloseIcon fill={COLORS.darkGray2} width={'16'} height={'16'} />
														</Styled.ReceiverRemoveIcon>
													) : (
														<></>
													)}
												</Styled.ReceiverFirstLine>
												<Styled.ReceiverSecondLine>
													<Styled.ReceiverPhone>{candidate.phoneNumber}</Styled.ReceiverPhone>
													<Styled.ReceiverEditIcon onClick={() => handleEditReceiver(candidate)}>
														<EditIcon fill={COLORS.black} />
													</Styled.ReceiverEditIcon>
												</Styled.ReceiverSecondLine>
											</Styled.ReceiverBox>
										))}
									</Styled.ReceiversList>
									{!allNumbersValid && (
										<Styled.ValidationError>
											Please update phone number of candidate(s) to send SMS
										</Styled.ValidationError>
									)}
									{availableSMSLimitError && (
										<Styled.ValidationError>
											{`The number of available sms per month is exceeded: used ${
												totalNumOfSMS - availableNumOfSMS
											} of ${totalNumOfSMS}.`}
										</Styled.ValidationError>
									)}
									{smsSendingFailedPhoneNumber && (
										<Styled.ValidationError>{smsLastSendingError}</Styled.ValidationError>
									)}
								</Styled.Receivers>
								<Field
									name='text'
									validate={composeValidators(
										requiredFieldValidator,
										textValidator,
										smsValidator,
										lengthValidator(1, MAX_CHARACTERS),
									)}
								>
									{({ input, meta }) => (
										<Styled.Field>
											<FieldWrapper
												name='text'
												label='Message'
												required
												errorMessage={meta.submitFailed && meta.touched && meta.error}
											>
												<TextArea
													{...input}
													placeholder='Enter your message'
													autoSize={{ minRows: 8, maxRows: 8 }}
													onChange={(e) => handleTextChange({ ...input, value: e.target.value })}
													maxLength={MAX_CHARACTERS}
												/>
												<Styled.SmsRemainingChars>
													{MAX_CHARACTERS - charCount} / {MAX_CHARACTERS} characters remaining
												</Styled.SmsRemainingChars>
											</FieldWrapper>
										</Styled.Field>
									)}
								</Field>
							</FormBlock>
							<Styled.ButtonBox>
								<ATSThemedButton
									type='button'
									buttonType={ButtonTypes.secondary}
									onClick={() => handleCloseForm()}
								>
									Cancel
								</ATSThemedButton>
								<ATSThemedButton
									type='submit'
									buttonType={ButtonTypes.primary}
									loading={false}
									disabled={submitButtonDisabled}
								>
									{'Send SMS'}
								</ATSThemedButton>
							</Styled.ButtonBox>
						</form>
					)}
				/>
				<EditReceiverModal
					editedCandidate={editedCandidate}
					onCancel={handleCloseReceiverModal}
					handleCandidatePhoneChange={handleCandidatePhoneChange}
				/>
				<SMSHistoryModal
					candidateSMSHistory={candidateSMSHistory}
					onCancel={handleHideSMSHistory}
				/>
			</Styled.Main>
		</>
	);
};

export default connect(
	(state) => ({
		candidatesSMSInformation: atsDucks.atsSelectors.getSMSCandidatesInformation(state),
		atsLoading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getCandidateSMSInformationRequested: atsDucks.atsActions.getCandidateSMSInformationRequested,
		updateCandidatePhoneNumberRequested: atsDucks.atsActions.updateCandidatePhoneNumberRequested,
		sendSMSToCandidateRequested: atsDucks.atsActions.sendSMSToCandidateRequested,
		resetCandidatesSMSInformation: atsDucks.atsActions.resetCandidatesSMSInformation,
	},
)(SendSMS);
