import React, { type FC } from 'react';
import { Field } from 'react-final-form';

import Box from 'components/Box';
import FieldWrapper from 'components/FieldWrapper';
import FormSwitch from 'components/FormSwitch';
import { IOption } from 'types';

import { Styled } from './Upgrades.styled';

type JobUpgradesProps = {
	jobUpgrades: IOption[];
};

const Upgrades: FC<JobUpgradesProps> = ({ jobUpgrades }) => {
	return (
		<Box>
			<h2 style={{ marginBottom: '24px' }}>Upgrades</h2>
			<Styled.Content>
				{jobUpgrades?.map((item) => (
					<Field name={item.value} key={item.value} defaultValue={false}>
						{({ input }) => (
							<FieldWrapper>
								<FormSwitch {...input} title={item.name} />
							</FieldWrapper>
						)}
					</Field>
				))}
			</Styled.Content>
		</Box>
	);
};

export default Upgrades;
