import { memo } from 'react';

import { IconType } from '../SVG.types';

function LinkedinIcon({ width = '22', height = '22' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M20.9924 1.2832H3.00845C2.05756 1.2832 1.28613 2.05463 1.28613 3.00552V20.9895C1.28613 21.9403 2.05756 22.7118 3.00845 22.7118H20.9924C21.9433 22.7118 22.7147 21.9403 22.7147 20.9895V3.00552C22.7147 2.05463 21.9433 1.2832 20.9924 1.2832ZM20.9924 20.9975C8.99774 20.9948 3.00042 20.9921 3.00042 20.9895C3.0031 8.99481 3.00578 2.99749 3.00845 2.99749C15.0031 3.00017 21.0004 3.00285 21.0004 3.00552C20.9977 15.0002 20.9951 20.9975 20.9924 20.9975ZM4.46292 9.31624H7.64238V19.543H4.46292V9.31624ZM6.05399 7.91803C7.06917 7.91803 7.89685 7.09302 7.89685 6.07517C7.89685 5.83316 7.84918 5.59352 7.75657 5.36994C7.66396 5.14635 7.52821 4.9432 7.35709 4.77207C7.18596 4.60095 6.98281 4.4652 6.75922 4.37259C6.53564 4.27998 6.296 4.23231 6.05399 4.23231C5.81198 4.23231 5.57235 4.27998 5.34876 4.37259C5.12517 4.4652 4.92202 4.60095 4.75089 4.77207C4.57977 4.9432 4.44402 5.14635 4.35141 5.36994C4.2588 5.59352 4.21113 5.83316 4.21113 6.07517C4.20845 7.09302 5.03345 7.91803 6.05399 7.91803ZM12.812 14.4832C12.812 13.1493 13.0665 11.8582 14.7192 11.8582C16.3477 11.8582 16.3718 13.3823 16.3718 14.5689V19.543H19.5486V13.9341C19.5486 11.1805 18.954 9.06178 15.737 9.06178C14.1915 9.06178 13.1549 9.91088 12.729 10.7145H12.6861V9.31624H9.63524V19.543H12.812V14.4832Z'
				fill='#39393B'
			/>
		</svg>
	);
}

export default memo(LinkedinIcon);
