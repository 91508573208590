import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function CandidatesIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13 7C13 9.48528 10.9853 11.5 8.5 11.5C6.01472 11.5 4 9.48528 4 7C4 4.51472 6.01472 2.5 8.5 2.5C10.9853 2.5 13 4.51472 13 7ZM11 7C11 8.38071 9.88071 9.5 8.5 9.5C7.11929 9.5 6 8.38071 6 7C6 5.61929 7.11929 4.5 8.5 4.5C9.88071 4.5 11 5.61929 11 7Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21 8C21 9.933 19.433 11.5 17.5 11.5C15.567 11.5 14 9.933 14 8C14 6.067 15.567 4.5 17.5 4.5C19.433 4.5 21 6.067 21 8ZM19 8C19 8.82843 18.3284 9.5 17.5 9.5C16.6716 9.5 16 8.82843 16 8C16 7.17157 16.6716 6.5 17.5 6.5C18.3284 6.5 19 7.17157 19 8Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.63197 13.5C3.17837 13.5 2 14.6784 2 16.132V17.3972C2 18.6791 2.74534 19.844 3.90925 20.3812C6.82211 21.7256 10.1779 21.7256 13.0907 20.3812C14.2547 19.844 15 18.6791 15 17.3972V16.132C15 14.6784 13.8216 13.5 12.368 13.5H4.63197ZM4.63197 15.5C4.28294 15.5 4 15.7829 4 16.132V17.3972C4 17.899 4.29176 18.355 4.74737 18.5653C7.12844 19.6642 9.87156 19.6642 12.2526 18.5653C12.7082 18.355 13 17.899 13 17.3972V16.132C13 15.7829 12.7171 15.5 12.368 15.5H4.63197Z'
				fill={fill}
			/>
			<path
				d='M17 13.5C16.4477 13.5 16 13.9477 16 14.5C16 15.0523 16.4477 15.5 17 15.5H19.722C19.8755 15.5 20 15.6245 20 15.778V16.5216C20 17.1087 19.6453 17.6376 19.1021 17.8604L19.0101 17.8982C18.0677 18.285 16.8839 18.6035 16.8839 18.6035C16.3381 18.6876 15.9638 19.1982 16.0479 19.7441C16.1222 20.2264 16.5296 20.5748 17 20.5913C17.5391 20.5913 18.9022 20.136 19.0754 20.0565C19.2992 19.9537 19.5511 19.838 19.8613 19.7108C21.1551 19.18 22 17.9201 22 16.5216V15.778C22 14.5199 20.9801 13.5 19.722 13.5H17Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(CandidatesIcon);
