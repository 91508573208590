import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';

import Badge from 'modules/Common/components/Badge';
import { INavListItem } from 'modules/Common/types';

import { Styled } from './NavigationMenu.styled';

type NavigationMenuProps = {
	items: INavListItem[];
	itemColor: string;
	isExtended: boolean;
	onMenuItemClick: () => void;
};

const NavigationMenu: React.FC<NavigationMenuProps> = ({
	isExtended,
	items,
	itemColor,
	onMenuItemClick,
}) => {
	return (
		<Styled.Root>
			<Styled.NavMenu>
				{items?.map((item: INavListItem) => (
					<Styled.NavItem key={item.id} itemColor={itemColor}>
						<NavLink to={`${item.path}`} onClick={onMenuItemClick}>
							<Styled.NavItemBox>
								<Badge dot={!!item.messages || !!item.outstandingContracts}>
									<>{item.icon}</>
								</Badge>
								<Styled.ItemName isExtended={isExtended}>{item.name}</Styled.ItemName>
							</Styled.NavItemBox>
						</NavLink>
					</Styled.NavItem>
				))}
			</Styled.NavMenu>
		</Styled.Root>
	);
};

export default memo(NavigationMenu);
