export interface IApplicationStatisticData {
    date: string;
    count: number;
};

export interface IJobStatistic {
    id: number;
    title: string;
    archivingDate: string | null;
    applicationsData: IApplicationStatisticData[];
}

export interface JobsStatisticType {
    jobs: IJobStatistic[];
};

export type CandidateStatisticsType = {
	numOfCandidates: number;
	state: { id: number; name: string; value: string };
};

export type DaysToHireJob = {
    id: number;
    title: string;
    daysToHire: number;
}

export enum StatisticPeriodEnum {
	Year = 'year',
	Month = 'month',
}

export type JobApplicatonsPerMonth = {
    id: number;
    title: string;
    archivingDate: string;
    count: number;
}

export type JobMonth = {
    date: string;
    jobs: JobApplicatonsPerMonth[];
}

export interface IApplicationYear {
    months: JobMonth[]
}

export interface IDaysToHire {
    jobs: DaysToHireJob[]
}

export type CandidateApplicationSourcesType = {
	applicationsPerSource: {
		[key: string]: number;
	};
};
