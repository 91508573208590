import styled from 'styled-components/macro';

import { device } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		margin-top: -20px;
	`;

	export const Header = styled.div`
		margin-right: -32px;
		margin-left: -32px;
	`;

	export const Content = styled.div`
		display: flex;
		justify-content: space-between;
		@media ${device.tablet} {
			flex-direction: column;
		}
	`;

	export const LeftColumn = styled.div`
		width: 62.8%;
		@media ${device.tablet} {
			width: 100%;
		}
	`;

	export const RightColumn = styled.div`
		width: 35.2%;
		@media ${device.tablet} {
			width: 100%;
		}
	`;

	export const DashboardTile = styled.div`
		margin-bottom: 24px;
		background-color: #fff;
		min-height: 200px;
		border-radius: 6px;
		display: flex;
		align-items: center;
		justify-content: center;
	`;
}
