import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		min-height: 100%;

		form {
			display: flex;
			flex-direction: column;
			height: 100%;
		}

		section {
			height: 100%;
		}
	`;
}
