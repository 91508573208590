import { Upload as UploadAnt } from 'antd';
import { darken } from 'polished';
import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';
import { COLORS } from 'theme';

type ButtonProps = {
	highlightColour?: string;
};
interface IJobApply {
	isCentered: boolean;
}

export namespace Styled {
	export const Root = styled.div`
		position: relative;
	`;
	export const ContentWrapper = styled.div`
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		padding: 42px 0;
		overflow: hidden;
	`;
	export const BgWrapper = styled.div`
		position: absolute;
		width: 100%;

		svg {
			position: absolute;
			top: 40px;
			left: 0;
			right: 0;
		}
	`;

	export const ContentInner = styled.div`
		max-width: 634px;
		width: 100%;
	`;
	export const FormTitle = styled.div`
		font-family: 'Raleway';
		width: 100%;
		font-style: normal;
		font-weight: 700;
		font-size: 36px;
		line-height: 42px;
		display: flex;
		align-items: center;
		text-align: center;
		color: ${COLORS.black};
		padding-bottom: 40px;
	`;

	export const JobsApplyButton = styled(ButtonComponent)<ButtonProps>`
		background: ${({ highlightColour }) => highlightColour};

		:hover {
			background: ${({ highlightColour }) => highlightColour && darken(0.1, highlightColour)};
		}
	`;

	export const FieldWrapper = styled.div`
		padding-bottom: 16px;

		.ant-select {
			width: 100%;
		}
		label {
			font-family: 'Raleway';
		}
		[class*='FieldHint'] {
			font-family: 'Raleway';
		}
	`;

	export const FieldCheckboxWrapper = styled.div`
		padding-bottom: 16px;

		.ant-checkbox-wrapper {
			display: flex;
			align-items: center;
			margin-left: 172px;
			font-family: 'Raleway';
		}
	`;

	export const SubmitWrapper = styled.div<IJobApply>`
		${({ isCentered }) =>
		isCentered ? 'padding-left: 86px; padding-right: 86px;' : 'padding-left: 172px;'};
	`;

	export const PhoneFieldWrapper = styled.div`
		width: 100%;

		input {
			color: rgba(0, 0, 0, 0.88);
			border: 1px solid ${COLORS.neutral5};
			border-radius: 6px;
			transition: all 0.2s;
			font-size: 14px;
			padding: 4px 11px;
			line-height: 1.57;

			&:hover {
				border-color: ${COLORS.inputColor};
			}

			&:focus {
				border-color: ${COLORS.inputColor};
				box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
				border-inline-end-width: 1px;
				outline: 0;
			}
		}
	`;

	export const FieldError = styled.span`
		color: ${COLORS.red};
		font-size: 14px;
		margin-top: 4px;
		margin-left: 172px;
	`;

	export const Upload = styled(UploadAnt)`
		width: 100%;
		.ant-upload {
			width: 100%;
		}
	`;
}
