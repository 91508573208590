import { paletteColors } from 'theme/colors';

export const CalendarColors = [
	{ color: paletteColors.green2, bgColor: paletteColors.lightGreen },
	{ color: paletteColors.blue4, bgColor: paletteColors.lightBlue6 },
	{ color: paletteColors.yellow2, bgColor: paletteColors.lightYellow },
	{ color: paletteColors.red, bgColor: paletteColors.lightRed },
	{ color: paletteColors.darkOrange, bgColor: paletteColors.lightOrange },
	{ color: paletteColors.darkBlue, bgColor: paletteColors.lightBlue2 },
	{ color: paletteColors.darkGray5, bgColor: paletteColors.lightGray1 },
];
