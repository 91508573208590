import type { FC } from 'react';

import BlueTownSpinner from 'components/SVG/BlueTownSpinner';

import { Styled } from './Spinner.styled';

type SpinnerProps = {
	fullWidth?: boolean;
	fixed?: boolean;
	overlay?: boolean;
	height?: string;
	width?: string;
};

export const Spinner: FC<SpinnerProps> = ({
	fullWidth = false,
	fixed,
	overlay = false,
	height,
	width,
}) => {
	const position = fixed && overlay ? 'absolute' : 'fixed';

	return (
		<>
			<Styled.Root fullWidth={fullWidth} fixed={fixed} position={position}>
				<BlueTownSpinner height={height} width={width} />
			</Styled.Root>
			{overlay && <Styled.Overlay />}
		</>
	);
};
