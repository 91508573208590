import { Collapse as CollapseComponent } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Collapse = styled(CollapseComponent)`
		background: none;
		font-family: 'Inter';

		&.ant-collapse {
			.ant-collapse-header {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding-left: 12px;
				padding-right: 12px;

				.ant-collapse-header-text {
					display: inline-block;
					margin: 0;
					flex: initial;
				}

				&:hover {
					cursor: default;
				}
				&:disabled &:hover {
					cursor: default;
				}
			}

			.ant-collapse-content .ant-collapse-content-box {
				padding-left: 12px;
				padding-right: 12px;
			}

			.ant-collapse-item {
				border: none;
			}
		}

		.ant-collapse-item {
			.ant-collapse-header a {
				position: relative;
				display: inline-block;
				margin-right: 6px;
				font-weight: 600;
				font-size: 20px;
				line-height: 24px;
				color: ${COLORS.blue};
				text-decoration: underline;
				text-underline-offset: 5px;
			}

			&.ant-collapse-item-disabled {
				.ant-collapse-header a {
					color: ${COLORS.black};
				}
			}
		}

		.ant-collapse-expand-icon {
			cursor: pointer;
		}
	`;
}
