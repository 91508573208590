import React, { FC } from 'react';
import { useFormState } from 'react-final-form';

import { Button } from 'antd';

import * as Styled from 'modules/BackOffice/containers/ClientChangesFormBranding/ClientChangesFormBranding.styled';
import { GenericType } from 'types';

type SubmitContainerTypeProps = {
	isEdit: boolean;
	onClick: () => void;
	loading: GenericType;
};

const SubmitContainer: FC<SubmitContainerTypeProps> = ({ loading, isEdit, onClick }) => {
	const { values } = useFormState();

	return (
		<Styled.SubmitContainer>
			{isEdit && (
				<Button type='ghost' htmlType='button' onClick={onClick}>
					Cancel
				</Button>
			)}
			<Button
				disabled={!values.creditChecked || !!loading?.createAndUpdateClientLoad}
				type='primary'
				loading={!!loading?.createAndUpdateClientLoad}
				htmlType='submit'
			>
				{isEdit ? 'Save Changes' : 'Save New Client'}
			</Button>
		</Styled.SubmitContainer>
	);
};

export default SubmitContainer;
