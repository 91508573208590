import React, { useEffect, useState, useCallback, type FC } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useUnmount } from 'react-use';

import dayjs from 'dayjs';

import Spinner from 'components/Spinner';
import StripeForm from 'components/StripeForm';
import { atsDucks } from 'modules/ATS/ducks';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import OrderCredits from 'modules/Common/containers/ContractView/OrderCredits';
import { AtsNavigationMenuList } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { CountriesType, CurrencyType, GenericType, Routes, SubscriptionPlansType } from 'types';

import { getOrderDetails } from './ATSCreditDetailsPage.helpers';
import { Styled } from './ATSCreditDetailsPage.styled';

export enum OrderTypeEnum {
	CREDITS = 'CREDITS',
	SMS = 'SMS',
	INTERVIEW = 'INTERVIEW',
}

export type CurrentOrderType = {
	id: number;
	chargeVat: true;
	totalPrice: number;
	totalPriceExVat: number;
	vatPrice: number;
	discountAmount?: number;
	// temp
	cvFiltering: number;
	cvFilteringPrice: number;
	guardian: number;
	guardianPrice: number;
	indeed: number;
	indeedPrice: number;
	premiumMultisite: number;
	orderType: OrderTypeEnum;
	premiumMultisitePrice: number;
	interviewPrice: number;
	smsPrice: number;
	unitForMonths: { count: number; yearMonth: string }[];
};

type ATSCreditDetailsPageProps = {
	countries: CountriesType;
	loading: GenericType;
	subscriptionPlans: SubscriptionPlansType;
	getCurrentOrderRequested: (id: string) => void;
	resetCurrentOrder: () => void;
	currentOrder: CurrentOrderType;
};

type CreditFieldsType = {
	key: string | number;
	name: string;
	amount: number;
	unitPrice: number;
	totalPrice: number;
};

const prepareCreditsFields = (
	cvFiltering: number,
	cvFilteringPrice: number,
	guardian: number,
	guardianPrice: number,
	indeed: number,
	indeedPrice: number,
	premiumMultisite: number,
	premiumMultisitePrice: number,
	orderType: string,
	interviewPrice: number,
	smsPrice: number,
	unitForMonths: { count: number; yearMonth: string }[],
) => {
	const isCreditsOrderType = orderType === 'CREDITS';

	let unitFields = [] as CreditFieldsType[];

	if (!isCreditsOrderType) {
		const sortedUnitForMonths = unitForMonths?.sort((a, b) =>
			dayjs(a.yearMonth).isAfter(dayjs(b.yearMonth)) ? 1 : -1,
		);
		unitFields = sortedUnitForMonths?.map((unit, index) => {
			const name =
				orderType === 'SMS'
					? `SMS: <b>${dayjs(unit.yearMonth, 'YYYY-MM').format('MMMM-YYYY')}</b>`
					: `Video Interviews: <b>${dayjs(unit.yearMonth, 'YYYY-MM').format('MMMM-YYYY')}</b>`;
			const unitPrice = orderType === 'SMS' ? smsPrice : interviewPrice;
			const totalPrice = unit.count * (orderType === 'SMS' ? smsPrice : interviewPrice);

			return {
				key: index + orderType,
				name,
				amount: unit.count,
				unitPrice,
				totalPrice,
			};
		});
	}

	return [
		{
			key: 0,
			name: 'Premium Multisite',
			amount: premiumMultisite,
			unitPrice: premiumMultisitePrice,
			totalPrice: premiumMultisite * premiumMultisitePrice,
		},
		{
			key: 1,
			name: 'CV Filtering',
			amount: cvFiltering,
			unitPrice: cvFilteringPrice,
			totalPrice: cvFilteringPrice * cvFiltering,
		},
		{
			key: 2,
			name: 'Guardian',
			amount: guardian,
			unitPrice: guardianPrice,
			totalPrice: guardian * guardianPrice,
		},
		{
			key: 3,
			name: 'Indeed',
			amount: indeed,
			unitPrice: indeedPrice,
			totalPrice: indeed * indeedPrice,
		},
		...unitFields,
	].filter((i) => !!i.amount);
};

const ATSCreditDetailsPage: FC<ATSCreditDetailsPageProps> = ({
	countries,
	loading,
	currentOrder,
	getCurrentOrderRequested,
	resetCurrentOrder,
}) => {
	const { creditId } = useParams();
	const [creditIntend, setCreditIntend] = useState(null);
	const navigate = useNavigate();
	const { paymentEndpoint, backToCreditsLink } = getOrderDetails(currentOrder?.orderType);
	const currencySymbol = countries.isUK ? CurrencyType.PoundSterling : CurrencyType.USDollar;

	const prepareCreditIntend = useCallback(async () => {
		if (!currentOrder) return;
		try {
			const res = await paymentEndpoint({ itemId: creditId });
			setCreditIntend(res.client_secret);
		} catch {
			try {
				navigate(-1);
			} catch (error) {
				navigate(`${Routes.ATS}${Routes.Credits}`);
			}
		}
	}, [creditId, currentOrder]);

	useUnmount(() => {
		resetCurrentOrder();
	});

	useEffect(() => {
		creditId && getCurrentOrderRequested(creditId);
	}, []);

	useEffect(() => {
		if (currentOrder) {
			prepareCreditIntend();
		}
	}, [currentOrder]);

	const redirectUrl = `${window.location.origin}/ats/finance?order_id=${creditId}`;

	if (loading.getOrderByIdLoad || !currentOrder) {
		return <Spinner fixed />;
	}

	const creditFields = prepareCreditsFields(
		currentOrder.cvFiltering,
		currentOrder.cvFilteringPrice,
		currentOrder.guardian,
		currentOrder.guardianPrice,
		currentOrder.indeed,
		currentOrder.indeedPrice,
		currentOrder.premiumMultisite,
		currentOrder.premiumMultisitePrice,
		currentOrder.orderType,
		currentOrder.interviewPrice,
		currentOrder.smsPrice,
		currentOrder.unitForMonths,
	);

	return (
		<Styled.Root>
			<Styled.Head>
				<PageNavTitle title={AtsNavigationMenuList.Checkout} navigationLink={backToCreditsLink} />
			</Styled.Head>
			<OrderCredits
				creditsFields={creditFields}
				currency={currencySymbol}
				totalPrice={currentOrder.totalPrice}
				totalPriceExVat={currentOrder.totalPriceExVat}
				vatAmount={currentOrder.vatPrice}
				discountAmount={currentOrder.discountAmount}
			/>
			{creditIntend && (
				<StripeForm
					clientSecret={creditIntend}
					total={currentOrder.totalPrice}
					currency={currencySymbol}
					redirectUrl={redirectUrl}
				/>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
		currentOrder: atsDucks.atsSelectors.getCurrentOrderState(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getCurrentOrderRequested: atsDucks.atsActions.getOrderByIdRequested,
		resetCurrentOrder: atsDucks.atsActions.resetCurrentOrder,
	},
)(ATSCreditDetailsPage);
