import _ from 'lodash';

import { statusOptions } from 'modules/ATS/containers/CandidatesApplication/CandidatesApplication.constants';
import { initialState } from 'modules/Common/ducks/redux.initial_state';
import { handlersDefaultCase } from 'utils/reduxHelpers';

export const CommonReducer = (TYPES) => {
	return (state = initialState, action = {}) => {
		const handlers = {
			[TYPES.GET_CONTRACTS.SUCCESS]: () => ({
				...state,
				contractsState: {
					...state.contractsState,
					contracts: action.payload,
				},
			}),
			[TYPES.GET_CONTRACTS.FAILED]: () => ({
				...state,
				contractsState: {
					...state.contractsState,
					contracts: initialState.contractsState.contracts,
					outstandingCount: initialState.contractsState.outstandingCount,
				},
			}),
			[TYPES.RESET_CONTRACTS_STATE]: () => ({
				...state,
				contractsState: {
					...initialState.contractsState,
				},
			}),
			[TYPES.GET_CONTRACT_STATUSES.SUCCESS]: () => ({
				...state,
				contractsState: {
					...state.contractsState,
					contractStatuses: action.payload,
				},
			}),
			[TYPES.GET_CONTRACT_STATUSES.FAILED]: () => ({
				...state,
				contractsState: {
					...state.contractsState,
					contractStatuses: initialState.contractsState.contractStatuses,
				},
			}),
			[TYPES.GET_CONTRACTS_BY_CLIENT_ID.REQUESTED]: () => ({
				...state,
				contractsState: {
					...state.contractsState,
					clientContracts: initialState.contractsState.clientContracts,
					outstandingCount: initialState.contractsState.outstandingCount,
				},
			}),
			[TYPES.GET_CONTRACTS_BY_CLIENT_ID.SUCCESS]: () => ({
				...state,
				contractsState: {
					...state.contractsState,
					clientContracts: action.payload?.data,
					outstandingCount: action.payload?.outstandingCount,
				},
			}),
			[TYPES.GET_CONTRACTS_BY_CLIENT_ID.FAILED]: () => ({
				...state,
				contractsState: {
					...state.contractsState,
					clientContracts: initialState.contractsState.clientContracts,
					outstandingCount: initialState.contractsState.outstandingCount,
				},
			}),
			[TYPES.GET_CONTRACT_BY_ID.REQUESTED]: () => ({
				...state,
				contractsState: {
					...state.contractsState,
					contractDetails: initialState.contractsState.contractDetails,
				},
			}),
			[TYPES.GET_CONTRACT_BY_ID.SUCCESS]: () => ({
				...state,
				contractsState: {
					...state.contractsState,
					contractDetails: action.payload,
				},
			}),
			[TYPES.GET_CONTRACT_BY_ID.FAILED]: () => ({
				...state,
				contractsState: {
					...state.contractsState,
					contractDetails: initialState.contractsState.contractDetails,
				},
			}),
			[TYPES.DELETE_CONTRACT_BY_ID.SUCCESS]: () => ({
				...state,
				contractsState: {
					...state.contractsState,
					contracts: state.contractsState.contracts?.data.filter(
						(contract) => contract.id !== action.payload && contract.contractId !== action.payload,
					),
				},
			}),
			[TYPES.GET_CONTRACT_RECEIPT.REQUESTED]: () => ({
				...state,
				contractsState: {
					...state.contractsState,
					receiptData: initialState.contractsState.receiptData,
				},
			}),
			[TYPES.GET_CONTRACT_RECEIPT.SUCCESS]: () => ({
				...state,
				contractsState: {
					...state.contractsState,
					receiptData: action.payload,
				},
			}),
			[TYPES.GET_CONTRACT_RECEIPT.FAILED]: () => ({
				...state,
				contractsState: {
					...state.contractsState,
					receiptData: initialState.contractsState.receiptData,
				},
			}),
			[TYPES.GET_CONTRACT_VAT.SUCCESS]: () => ({
				...state,
				contractsState: {
					...state.contractsState,
					chargeVat: action.payload,
				},
			}),
			[TYPES.GET_CONTRACT_VAT.FAILED]: () => ({
				...state,
				contractsState: {
					...state.contractsState,
					chargeVat: initialState.contractsState.chargeVat,
				},
			}),
			[TYPES.GET_CLIENT_BY_ID.REQUESTED]: () => ({
				...state,
				clientsState: {
					...state.clientsState,
					clientData: initialState.clientsState.clientData,
				},
			}),
			[TYPES.GET_CLIENT_BY_ID.SUCCESS]: () => ({
				...state,
				clientsState: {
					...state.clientsState,
					clientData: action.payload,
				},
			}),
			[TYPES.GET_CLIENT_BY_ID.FAILED]: () => ({
				...state,
				clientsState: {
					...state.clientsState,
					clientData: initialState.clientsState.clientData,
				},
			}),
			[TYPES.GET_CLIENT_USERS.SUCCESS]: () => ({
				...state,
				clientsState: {
					...state.clientsState,
					clientUsers: action.payload,
				},
			}),
			[TYPES.GET_CREDITS.SUCCESS]: () => ({
				...state,
				creditsState: {
					...state.creditsState,
					credits: action.payload,
				},
			}),
			[TYPES.GET_CREDITS.FAILED]: () => ({
				...state,
				creditsState: {
					...state.creditsState,
					credits: initialState.creditsState.credits,
				},
			}),
			[TYPES.RESET_JOB_STATE]: () => ({
				...state,
				jobsState: initialState.jobsState,
			}),
			[TYPES.GET_JOB_BY_ID.REQUESTED]: () => ({
				...state,
				jobsState: {
					jobData: initialState.jobsState.jobData,
				},
			}),
			[TYPES.GET_JOB_BY_ID.SUCCESS]: () => ({
				...state,
				jobsState: {
					...state.jobsState,
					jobData: action.payload,
				},
			}),
			[TYPES.GET_JOB_BY_ID.FAILED]: () => ({
				...state,
				jobsState: {
					...state.jobsState,
					jobData: initialState.jobsState.jobData,
				},
			}),
			[TYPES.UPDATE_APPLICATION_STATUS.SUCCESS]: () => {
				const currentClientJobsData = state.jobsState.currentClientJobs?.map((ccj) => {
					if (ccj.id !== action.payload?.jobId) {
						return ccj;
					}

					return {
						...ccj,
						candidates: ccj.candidates.map((c) => {
							const { id, status } = action.payload;
							if (id !== c.id) {
								return c;
							}

							return {
								...c,
								candidateState: statusOptions.find((so) => so.value === status),
							};
						}),
					};
				});

				const jobData = {
					...state.jobsState.jobData,
					candidates: state.jobsState?.jobData?.candidates?.map((c) => {
						const { id, status } = action.payload;
						if (id !== c.id) {
							return c;
						}

						return {
							...c,
							candidateState: statusOptions.find((so) => so.value === status),
						};
					}),
				};

				return {
					...state,
					jobsState: {
						...state.jobsState,
						currentClientJobs: currentClientJobsData
							? currentClientJobsData
							: initialState.jobsState.currentClientJobs,
						jobData: jobData ? jobData : initialState.jobsState.jobData,
					},
				};
			},
			[TYPES.GET_CANDIDATE_BY_ID.SUCCESS]: () => {
				const jobData = {
					...state.jobsState.jobData,
					candidates: state.jobsState?.jobData?.candidates?.map((c) => {
						const { id } = action.payload;
						if (id !== c.id) {
							return c;
						}

						return {
							...c,
							isNew: false,
						};
					}),
				};

				return {
					...state,
					jobsState: {
						...state.jobsState,
						jobData: jobData ? jobData : initialState.jobsState.jobData,
					},
				};
			},
			[TYPES.UPDATE_CANDIDATE_RANK.SUCCESS]: () => {
				const { candidateData, candidateAppId, jobId } = action.payload;

				const newState = _.cloneDeep(state);

				_.forEach(newState.jobsState.currentClientJobs, (ccj) => {
					if (ccj.id === jobId) {
						_.forEach(ccj.candidates, (c) => {
							if (c.id === candidateAppId) {
								_.assign(c, candidateData);
							}
						});
					}
				});

				_.forEach(newState.jobsState.jobData?.candidates, (c) => {
					if (c.id === candidateAppId) {
						_.assign(c, candidateData);
					}
				});

				return newState;
			},
			[TYPES.GET_OPEN_JOB_BY_ID.REQUESTED]: () => ({
				...state,
				jobsState: {
					jobData: initialState.jobsState.jobData,
				},
			}),
			[TYPES.GET_OPEN_JOB_BY_ID.SUCCESS]: () => ({
				...state,
				jobsState: {
					...state.jobsState,
					jobData: action.payload,
				},
			}),
			[TYPES.GET_OPEN_JOB_BY_ID.FAILED]: () => ({
				...state,
				jobsState: {
					...state.jobsState,
					jobData: initialState.jobsState.jobData,
				},
			}),
			[TYPES.GET_JOB_TYPES.SUCCESS]: () => ({
				...state,
				jobsState: {
					...state.jobsState,
					jobTypes: action.payload,
				},
			}),
			[TYPES.GET_JOB_TYPES.FAILED]: () => ({
				...state,
				jobsState: {
					...state.jobsState,
					jobTypes: initialState.jobsState.jobTypes,
				},
			}),
			[TYPES.GET_EDUCATION_TYPES.SUCCESS]: () => ({
				...state,
				educationState: {
					...state.educationState,
					educationTypes: action.payload,
				},
			}),
			[TYPES.GET_EDUCATION_TYPES.FAILED]: () => ({
				...state,
				educationState: {
					...state.educationState,
					educationTypes: initialState.educationState.educationTypes,
				},
			}),
			[TYPES.RESET_EDUCATION_TYPES]: () => ({
				...state,
				educationState: {
					...state.educationState,
					educationTypes: initialState.educationState.educationTypes,
				},
			}),
			[TYPES.GET_EDUCATION_TYPES_BY_REGION_ID.REQUESTED]: () => ({
				...state,
				educationState: {
					...state.educationState,
					educationTypes: initialState.educationState.educationTypes,
				},
			}),
			[TYPES.GET_EDUCATION_TYPES_BY_REGION_ID.SUCCESS]: () => ({
				...state,
				educationState: {
					...state.educationState,
					educationTypes: action.payload,
				},
			}),
			[TYPES.GET_EDUCATION_TYPES_BY_REGION_ID.FAILED]: () => ({
				...state,
				educationState: {
					...state.educationState,
					educationTypes: initialState.educationState.educationTypes,
				},
			}),
			[TYPES.GET_EMPLOYMENT_TYPES.SUCCESS]: () => ({
				...state,
				employmentState: {
					...state.employmentState,
					employmentTypes: action.payload,
				},
			}),
			[TYPES.GET_CLIENT_BRANDING.REQUESTED]: () => ({
				...state,
				currentClientBranding: initialState.currentClientBranding,
			}),
			[TYPES.GET_CLIENT_BRANDING.SUCCESS]: () => ({
				...state,
				currentClientBranding: action.payload,
			}),
			[TYPES.GET_CLIENT_JOBS.REQUESTED]: () => ({
				...state,
				jobsState: {
					...state.jobsState,
					currentClientJobs: initialState.jobsState.currentClientJobs,
				},
			}),
			[TYPES.GET_CLIENT_JOBS.SUCCESS]: () => ({
				...state,
				jobsState: {
					...state.jobsState,
					currentClientJobs: action.payload,
				},
			}),
			[TYPES.GET_CLIENT_COPY_JOBS.REQUESTED]: () => ({
				...state,
				jobsState: {
					...state.jobsState,
					currentClientCopyJobs: initialState.jobsState.currentClientCopyJobs,
				},
			}),
			[TYPES.GET_CLIENT_COPY_JOBS.SUCCESS]: () => ({
				...state,
				jobsState: {
					...state.jobsState,
					currentClientCopyJobs: action.payload,
				},
			}),
			[TYPES.GET_EMPLOYMENT_TYPES.REQUESTED]: () => ({
				...state,
				employmentState: {
					...state.employmentState,
					assessment: initialState.employmentState.employmentTypes,
				},
			}),
			[TYPES.GET_EMPLOYMENT_TYPES.FAILED]: () => ({
				...state,
				employmentState: {
					...state.employmentState,
					employmentTypes: initialState.employmentState.employmentTypes,
				},
			}),
			[TYPES.GET_EMPLOYMENT_TYPES.SUCCESS]: () => ({
				...state,
				employmentState: {
					...state.jobApply,
					employmentTypes: action.payload,
				},
			}),
			[TYPES.GET_JOB_ASSESSMENT.REQUESTED]: () => ({
				...state,
				jobApply: {
					...state.jobApply,
					assessment: initialState.jobApply.assessment,
				},
			}),
			[TYPES.GET_JOB_ASSESSMENT.SUCCESS]: () => ({
				...state,
				jobApply: {
					...state.jobApply,
					assessment: action.payload,
				},
			}),
			[TYPES.SET_JOB_APPLY_STATE]: () => ({
				...state,
				jobApply: {
					...state.jobApply,
					...action.payload,
				},
			}),
			[TYPES.APPLY_ON_JOB.SUCCESS]: () => ({
				...state,
				jobApply: initialState.jobApply,
			}),
			[TYPES.APPLY_ON_JOB.FAILED]: () => ({
				...state,
				jobApply: initialState.jobApply,
			}),
			[TYPES.GET_INTERVIEW_OPEN.REQUESTED]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewData: initialState.interviewState.interviewData,
				},
			}),
			[TYPES.GET_INTERVIEW_OPEN.SUCCESS]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewData: action.payload,
				},
			}),
			[TYPES.GET_INTERVIEW_OPEN.FAILED]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewData: initialState.interviewState.interviewData,
				},
			}),
			[TYPES.GET_ALL_BO_QUESTIONNAIRES.REQUESTED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					questionnairesData: initialState.questionnairesState.questionnairesData,
				},
			}),
			[TYPES.GET_ALL_BO_QUESTIONNAIRES.SUCCESS]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					questionnairesData: action.payload,
				},
			}),
			[TYPES.GET_ALL_BO_QUESTIONNAIRES.FAILED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					questionnairesData: initialState.questionnairesState.questionnairesData,
				},
			}),
			[TYPES.GET_ALL_ATS_QUESTIONNAIRES.REQUESTED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					atsQuestionnairesData: initialState.questionnairesState.atsQuestionnairesData,
				},
			}),
			[TYPES.GET_ALL_ATS_QUESTIONNAIRES.SUCCESS]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					atsQuestionnairesData: action.payload,
				},
			}),
			[TYPES.GET_ALL_ATS_QUESTIONNAIRES.FAILED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					atsQuestionnairesData: initialState.questionnairesState.atsQuestionnairesData,
				},
			}),
			[TYPES.GET_QUESTIONNAIRE_RESULT.REQUESTED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					questionnaireResult: initialState.questionnairesState.questionnaireResult,
				},
			}),
			[TYPES.GET_QUESTIONNAIRE_RESULT.SUCCESS]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					questionnaireResult: action.payload,
				},
			}),
			[TYPES.GET_QUESTIONNAIRE_RESULT.FAILED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					questionnaireResult: initialState.questionnairesState.questionnaireResult,
				},
			}),
			[TYPES.GET_QUESTIONNAIRE_BY_ID.REQUESTED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					currentQuestionnaire: initialState.questionnairesState.currentQuestionnaire,
				},
			}),
			[TYPES.GET_QUESTIONNAIRE_BY_ID.SUCCESS]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					currentQuestionnaire: action.payload,
				},
			}),
			[TYPES.GET_QUESTIONNAIRE_BY_ID.FAILED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					currentQuestionnaire: initialState.questionnairesState.currentQuestionnaire,
				},
			}),
			[TYPES.GET_COPY_QUESTIONNAIRES.REQUESTED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					copyQuestionnairesData: initialState.questionnairesState.copyQuestionnairesData,
				},
			}),
			[TYPES.GET_COPY_QUESTIONNAIRES.SUCCESS]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					copyQuestionnairesData: action.payload,
				},
			}),
			[TYPES.GET_COPY_QUESTIONNAIRES.FAILED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					copyQuestionnairesData: initialState.questionnairesState.copyQuestionnairesData,
				},
			}),
			[TYPES.GET_SEND_QUESTIONNAIRES_LIST.REQUESTED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					sendQuestionnairesData: initialState.questionnairesState.sendQuestionnairesData,
				},
			}),
			[TYPES.GET_SEND_QUESTIONNAIRES_LIST.SUCCESS]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					sendQuestionnairesData: action.payload,
				},
			}),
			[TYPES.GET_SEND_QUESTIONNAIRES_LIST.FAILED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					sendQuestionnairesData: initialState.questionnairesState.sendQuestionnairesData,
				},
			}),
			[TYPES.GET_VIDEO_QUESTIONNAIRE_BY_CANDIDATE_ID.REQUESTED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					candidateVideoQuestionnaireData:
						initialState.questionnairesState.candidateVideoQuestionnaireData,
				},
			}),
			[TYPES.GET_VIDEO_QUESTIONNAIRE_BY_CANDIDATE_ID.SUCCESS]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					candidateVideoQuestionnaireData: action.payload,
				},
			}),
			[TYPES.GET_VIDEO_QUESTIONNAIRE_BY_CANDIDATE_ID.FAILED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					candidateVideoQuestionnaireData:
						initialState.questionnairesState.candidateVideoQuestionnaireData,
				},
			}),
			[TYPES.GET_QUESTIONNAIRE_DURATION.REQUESTED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					durations: initialState.questionnairesState.durations,
				},
			}),
			[TYPES.GET_QUESTIONNAIRE_DURATION.SUCCESS]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					durations: action.payload,
				},
			}),
			[TYPES.GET_QUESTIONNAIRE_DURATION.FAILED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					durations: initialState.questionnairesState.durations,
				},
			}),
			[TYPES.GET_QUESTIONNAIRE_USERS.REQUESTED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					questionnaireUsers: initialState.questionnairesState.questionnaireUsers,
				},
			}),
			[TYPES.GET_QUESTIONNAIRE_USERS.SUCCESS]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					questionnaireUsers: action.payload,
				},
			}),
			[TYPES.GET_QUESTIONNAIRE_USERS.FAILED]: () => ({
				...state,
				questionnairesState: {
					...state.questionnairesState,
					questionnaireUsers: initialState.questionnairesState.questionnaireUsers,
				},
			}),
			[TYPES.GET_JOB_INDUSTRIES.SUCCESS]: () => ({
				...state,
				jobIndustries: action.payload,
			}),
			[TYPES.GET_TICKET_TYPES.SUCCESS]: () => ({
				...state,
				ticketTypes: action.payload,
			}),
			[TYPES.GET_TICKET_STATUSES.SUCCESS]: () => ({
				...state,
				ticketStatuses: action.payload,
			}),
			[TYPES.GET_SMS_COUNT.SUCCESS]: () => ({
				...state,
				smsCountState: action.payload,
			}),
			[TYPES.GET_SMS_ORDER_PRICE.SUCCESS]: () => ({
				...state,
				orderPrices: {
					...state.orderPrices,
					smsPrice: action.payload,
				},
			}),
			[TYPES.GET_VIDEO_INTERVIEW_ORDER_PRICE.SUCCESS]: () => ({
				...state,
				orderPrices: {
					...state.orderPrices,
					videoInterviewPrice: action.payload,
				},
			}),
			[TYPES.GET_EMAIL_AUTO_FILLED_DATA.REQUESTED]: () => ({
				...state,
				emailTemplatesState: {
					...initialState.emailTemplatesState,
					emailAutoFilledData: initialState.emailTemplatesState.emailAutoFilledData,
				},
			}),
			[TYPES.GET_EMAIL_AUTO_FILLED_DATA.SUCCESS]: () => ({
				...state,
				emailTemplatesState: {
					...initialState.emailTemplatesState,
					emailAutoFilledData: action.payload,
				},
			}),
			[TYPES.GET_EMAIL_AUTO_FILLED_DATA.FAILED]: () => ({
				...state,
				emailTemplatesState: {
					...initialState.emailTemplatesState,
					emailAutoFilledData: initialState.emailTemplatesState.emailAutoFilledData,
				},
			}),
			[TYPES.GET_EMAIL_DEFAULT_TEMPLATES.REQUESTED]: () => ({
				...state,
				emailTemplatesState: {
					...initialState.emailTemplatesState,
					emailTemplatesData: initialState.emailTemplatesState.emailTemplatesData,
				},
			}),
			[TYPES.GET_EMAIL_DEFAULT_TEMPLATES.SUCCESS]: () => ({
				...state,
				emailTemplatesState: {
					...initialState.emailTemplatesState,
					emailTemplatesData: action.payload,
				},
			}),
			[TYPES.GET_EMAIL_DEFAULT_TEMPLATES.FAILED]: () => ({
				...state,
				emailTemplatesState: {
					...initialState.emailTemplatesState,
					emailTemplatesData: initialState.emailTemplatesState.emailTemplatesData,
				},
			}),
			[TYPES.GET_PRICES_BY_CLIENT_ID.SUCCESS]: () => ({
				...state,
				clientPrices: {
					clientId: action.payload?.clientId,
					prices: action.payload?.prices,
				},
			}),
			[TYPES.GET_PRICES_BY_CLIENT_ID.FAILED]: () => ({
				...state,
				clientPrices: initialState.clientPrices,
			}),
			[TYPES.SAVE_SEARCH_QUERY_PARAMS]: () => ({
				...state,
				queryParams: {
					...initialState.queryParams,
					search: action.payload,
				},
			}),
		};

		return handlersDefaultCase(handlers, action, state);
	};
};
