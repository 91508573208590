import React, { useCallback, type FC } from 'react';
import { connect } from 'react-redux';

import Spinner from 'components/Spinner';
import Table from 'components/Table';
import { useMount } from 'hooks';
import { atsDucks } from 'modules/ATS/ducks';
import { IOption, Routes } from 'types';
import { handleFileDownload } from 'utils/helpers';

import { CandidatesDatabaseType } from '../CandidatesDatabase.types';

import { columns } from './CandidatesDatabaseTable.entities';

type CandidatesDatabaseTableProps = {
	loading: boolean;
	candidates: CandidatesDatabaseType;
	activeJobsList: IOption[];
	getAtsActiveJobsRequested: () => void;
	handleAssignCandidateToJobs: (
		applicationId: number,
		prevValues: number[],
		allValues: number[],
	) => void;
	handleVisibilityOfCandidate: (candidateApplicationId: number, hidden: boolean) => void;
	handlePaginationChange: (page: number, size: number) => void;
};

const CandidatesDatabaseTable: FC<CandidatesDatabaseTableProps> = ({
	loading,
	candidates,
	activeJobsList,
	getAtsActiveJobsRequested,
	handleVisibilityOfCandidate,
	handleAssignCandidateToJobs,
	handlePaginationChange,
}) => {
	useMount(() => {
		!activeJobsList?.length && getAtsActiveJobsRequested();
	});

	const handleViewCandidate = useCallback((candidateApplicationId: number) => {
		const url = `${window.location.origin}${Routes.ATS}${Routes.CandidatesDatabase}/${candidateApplicationId}`;
		window.open(url, '_blank');
	}, []);

	const handleCVDownload = useCallback(
		({ originalFileName, referenceUuid }: { originalFileName: string; referenceUuid: string }) => {
			handleFileDownload(referenceUuid, originalFileName);
		},
		[],
	);

	const columnsData = columns(
		activeJobsList,
		handleViewCandidate,
		handleCVDownload,
		handleAssignCandidateToJobs,
		handleVisibilityOfCandidate,
	);

	if (!candidates?.data || !columnsData?.length || loading) {
		return <Spinner fixed />;
	}

	return (
		<>
			{candidates && (
				<Table
					pageSize={candidates.pageSize}
					current={candidates.pageIndex}
					total={candidates.totalCount}
					data={candidates.data}
					columns={columnsData}
					onChange={handlePaginationChange}
					rowKey='candidateApplicationId'
				/>
			)}
		</>
	);
};

export default connect(
	(state) => ({
		activeJobsList: atsDucks.atsSelectors.getActiveJobsData(state),
	}),
	{
		getAtsActiveJobsRequested: atsDucks.atsActions.getAtsActiveJobsRequested,
	},
)(CandidatesDatabaseTable);
