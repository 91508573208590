import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import PageNavTitle from 'modules/Common/components/PageNavTitle';
import ATSQuestionnaire from 'modules/Common/containers/ATSQuestionnaire';
import { Routes } from 'types';

import { Styled } from './ATSQuestionnairePage.styled';

const ATSQuestionnairePage = () => {
	const { id, candidateId } = useParams();
	const { pathname, state } = useLocation();

	const { candidateIds, jobId } = state || {};

	const isEditMode = pathname.includes(Routes.QuestionnairesEdit);
	const isCopyMode = pathname.includes(Routes.QuestionnairesCopy);
	const isSendMode = pathname.includes(Routes.QuestionnairesSend);

	return (
		<>
			<Styled.Head>
				<PageNavTitle
					title={isEditMode && state?.title ? state?.title : 'New Video Questionnaire'}
					navigationLink={-1}
					confirmationMessage='Are you sure? Leaving now will discard your changes'
				/>
			</Styled.Head>
			<Styled.Content>
				<ATSQuestionnaire
					isCopyMode={isCopyMode}
					isEditMode={isEditMode}
					isSendMode={isSendMode}
					questionnaireId={id}
					jobId={jobId}
					candidateIds={candidateId ? [candidateId] : candidateIds}
				/>
			</Styled.Content>
		</>
	);
};

export default ATSQuestionnairePage;
