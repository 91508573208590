import styled from 'styled-components/macro';

import { FormBlock, FormBlockProps } from 'modules/Common/components/FormBlock/FormBlock';
import { COLORS } from 'theme';

type FormBlockPropsExtended = FormBlockProps & {
	clientContextId?: string;
};

type ToggleFieldProps = {
	marginTop?: string;
};

export namespace Styled {
	export const FormBlockWrap = styled(FormBlock).attrs<FormBlockPropsExtended>(
		({ clientContextId }) => ({
			style: clientContextId
				? {
					border: 'none',
					boxShadow: 'none',
					padding: '0',
				  }
				: {},
		}),
	)<FormBlockPropsExtended>``;

	export const RadioFieldsBox = styled.div`
		display: flex;
		gap: 65px;
	`;

	export const FieldsBox = styled.div`
		display: flex;
		align-items: flex-end;
		gap: 10px;

		input,
		.ant-select {
			max-width: 160px;
			width: 100%;
		}
	`;

	export const FieldWrapper = styled.div`
		span {
			white-space: nowrap;
		}
	`;

	export const FieldToggle = styled.div<ToggleFieldProps>`
		margin-top: ${({ marginTop }) => marginTop || 'auto'};
	`;

	export const FieldHint = styled.div`
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		margin-top: 5px;
		color: ${COLORS.darkGray2};
	`;
	export const FieldGap = styled.div`
		height: 35px;
	`;
}
