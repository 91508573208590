export type DashboardUnreadEmailsType = {
	unreadMessagesCount?: number;
	data: {
		id: number;
		candidateApplication: {
			id: number;
			fullName: string;
		};
		topic: string;
		date: string;
		threadId: number;
	}[];
};

export enum EmailVariablesEnum {
	CANDIDATE_NAME = '[Candidate Name]',
	COMPANY_NAME = '[Company Name]',
	URL = '[URL]',
	JOB_TITLE = '[Job Title]',
	ROLE_NAME = '[Role Name]',
	YOUR_NAME= '[Your Name]',
}

export type EmailMessageType = {
	id: number;
	content: string;
	creationDate: string;
	attachments: {
		originalFileName: string;
		referenceUuid: string;
	}[];
	candidateApplicationId?: number;
	userId?: number;
};

export type EmailThreadType = {
	id: number;
	topic: string;
	user: { id: number; email: string; fullName: string };
	candidateApplication: {
		id: number;
		fullName: string;
		email: string;
		jobId: number;
		phoneNumber: string;
		jobTitle: string;
	};
	messages: EmailMessageType[];
	client: string;
	region: {
		id: number;
		name: string;
	};
	logo: string;
	backgroundImage: null | string;
	highlightColour: null | string;
};

export type ThreadType = {
	id: number;
	topic: string;
	unreadMessagesCount: number;
	totalMessagesCount: number;
	lastMessageDate: string;
};

export type EmailDataType = {
	candidateApplicationId: number;
	email: string;
	phoneNumber: string;
	jobLocation: string | null;
	currentClientApplication: boolean;
	removedFromCandidateDatabase: boolean | null;
	threads: ThreadType[];
	candidate: string;
	jobId?: number;
};

export type PaginationProps = {
	pageIndex: number;
	pageSize: number;
	totalCount: number;
};

export type InboxEmailThreadsType = {
	data: EmailDataType[];
	unreadMessagesCount: number;
} & PaginationProps;

export type SentEmailThreadsType = {
	data: EmailDataType[];
} & PaginationProps;
