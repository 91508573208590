import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { Routes } from 'types';

import Contracts from '../../containers/Contracts';

import { Styled } from './ClientContractsPage.styled';

export const ClientContractsPage = () => {
	const navigate = useNavigate();
	const { clientId } = useParams();

	return (
		<>
			<Styled.Head>
				<PageNavTitle
					title={'Contracts'}
					navigationLink={`${Routes.BackOffice}${Routes.Contracts}`}
				/>
				<Button
					buttonType={ButtonTypes.primary}
					onClick={() => navigate(`${Routes.BackOffice}${Routes.ContractsCreate}`)}
				>
					Create Contract
				</Button>
			</Styled.Head>
			<Styled.Content>
				<Contracts clientId={clientId} isClientContext={true} />
			</Styled.Content>
		</>
	);
};
