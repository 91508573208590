import React, { type FC } from 'react';

import Spinner from 'components/Spinner';
import Table from 'components/Table';
import { GenericType } from 'types';

import { columns } from './AtsSupportTickets.entities';

type AtsSupportTicketsProps = {
	loading?: GenericType;
	tickets: {
		data: object[];
		pageIndex: number;
		pageSize: number;
		totalCount: number;
	};
	handlePaginationChange: (page: number, size: number) => void;
};

const AtsSupportTickets: FC<AtsSupportTicketsProps> = ({ loading, tickets, handlePaginationChange }) => {
	const columnsData = columns();

	if (!tickets?.data || !columnsData?.length || loading?.getTicketsLoad) {
		return <Spinner fixed />;
	}

	return (
		<>
			{tickets && (
				<Table
					pageSize={tickets.pageSize}
					current={tickets.pageIndex}
					total={tickets.totalCount}
					data={tickets.data}
					columns={columnsData}
					onChange={handlePaginationChange}
				/>
			)}
		</>
	);
};

export default AtsSupportTickets;
