import { Routes, SettingsRoutes } from './types';

enum UserRolesType {
	isAtsFinanceUser = 'isAtsFinanceUser',
	isAtsStandardUser = 'isAtsStandardUser',
	isAtsSuperUser = 'isAtsSuperUser',
}

enum UserCountryType {
	isUK = 'isUK',
	isUS = 'isUS',
}

export const HrRouteRoleMap = {
	[`${Routes.HRModule}${Routes.Dashboard}`]: [
		UserRolesType.isAtsFinanceUser,
		UserRolesType.isAtsStandardUser,
		UserRolesType.isAtsSuperUser,
	],
	[`${Routes.HRModule}${Routes.Calendar}`]: [
		UserRolesType.isAtsFinanceUser,
		UserRolesType.isAtsStandardUser,
		UserRolesType.isAtsSuperUser,
	],
	[`${Routes.HRModule}${Routes.PeopleManagement}`]: [
		UserRolesType.isAtsFinanceUser,
		UserRolesType.isAtsStandardUser,
		UserRolesType.isAtsSuperUser,
	],
	[`${Routes.HRModule}${Routes.ModuleSettings}`]: [
		UserRolesType.isAtsFinanceUser,
		UserRolesType.isAtsStandardUser,
		UserRolesType.isAtsSuperUser,
	],
	[`${Routes.HRModule}${Routes.Settings}`]: [
		UserRolesType.isAtsFinanceUser,
		UserRolesType.isAtsStandardUser,
		UserRolesType.isAtsSuperUser,
	],
	[`${SettingsRoutes.UserDetails}`]: [
		UserRolesType.isAtsFinanceUser,
		UserRolesType.isAtsStandardUser,
		UserRolesType.isAtsSuperUser,
	],
	[`${Routes.HRModule}${Routes.EmployeeCreate}`]: [
		UserRolesType.isAtsFinanceUser,
		UserRolesType.isAtsStandardUser,
		UserRolesType.isAtsSuperUser,
	],
	[`${Routes.HRModule}${Routes.EmployeeEdit}`]: [
		UserRolesType.isAtsFinanceUser,
		UserRolesType.isAtsStandardUser,
		UserRolesType.isAtsSuperUser,
	],
	[`${Routes.HRModule}${Routes.Employee}`]: [
		UserRolesType.isAtsFinanceUser,
		UserRolesType.isAtsStandardUser,
		UserRolesType.isAtsSuperUser,
	],
};

export const HrRouteCountryMap = {
	[`${Routes.HRModule}${Routes.Dashboard}`]: [UserCountryType.isUK, UserCountryType],
	[`${Routes.HRModule}${Routes.EmployeeCreate}`]: [UserCountryType.isUK, UserCountryType],
	[`${Routes.HRModule}${Routes.EmployeeEdit}`]: [UserCountryType.isUK, UserCountryType],
	[`${Routes.HRModule}${Routes.Employee}`]: [UserCountryType.isUK, UserCountryType],
	[`${SettingsRoutes.UserDetails}`]: [UserCountryType.isUK, UserCountryType],
};
