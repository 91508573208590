import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
	`;

	export const NotInTimeRoot = styled.section`
		width: 100%;
		max-width: 1368px;
		margin: 0 auto;
		padding: 0;
	`;

	export const NotInTimeContent = styled.div`
		border-radius: 4px;
		border: 1px solid ${COLORS.lightGray2};
		background: ${COLORS.lightGray3};
		box-shadow: ${COLORS.elevationShadow};
		padding: 32px 32px 40px;
		color: ${COLORS.black};
		font-size: 32px;
		margin-top: 50px;
		text-align: center;
	`;
	export const InterviewStartTime = styled.p`
		margin: 10px 0 0;
	`;

	export const SelfVideoMute = styled.div`
		cursor: pointer;
	`;
	export const SelfVideoCamera = styled.div`
		cursor: pointer;
	`;
	export const Video = styled.video`
		width: 100%;
		height: auto;
	`;
	export const Canvas = styled.canvas`
		width: 100%;
		height: auto;
	`;
	export const VideoPlayerContainer = styled.div`
		width: 100%;
		height: 1000px;
	`;
	export const VideoPlayer = styled.video`
		width: 100%;
		height: auto;
		aspect-ratio: 16/9;
	`;
}
