import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Badge, Button } from 'antd';
import dayjs from 'dayjs';

import { ButtonTypes } from 'components/Button/Button.types';
import Spinner from 'components/Spinner';
import EmailIcon from 'components/SVG/EmailIcon';
import EyeOpenIcon from 'components/SVG/EyeOpenIcon';
import MapMarkerIcon from 'components/SVG/MapMarkerIcon';
import NavBackButton from 'components/SVG/NavBackButton';
import PhoneIcon from 'components/SVG/PhoneIcon';
import { useMount } from 'hooks';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import { atsDucks } from 'modules/ATS/ducks';
import EmailThreadComponent from 'modules/Common/components/EmailThreadComponent';
import { DEFAULT_PAGE_SIZE } from 'modules/Common/constants';
import ReplyEmailForm from 'modules/Common/containers/ReplyEmailForm';
import {
	EmailDataType,
	EmailThreadType,
	InboxEmailThreadsType,
	SentEmailThreadsType,
} from 'modules/Common/types';
import { COLORS } from 'theme';
import { GenericType, Routes } from 'types';

import { Styled } from './MessageTab.styled';
import PanelMenu from './PanelMenu';

type MessageTabProps = {
	emailThreads: InboxEmailThreadsType | SentEmailThreadsType;
	currentEmailThread: EmailThreadType;
	searchTerm: string;
	loading: GenericType;
	emailSignatureData: string;
	getClientEmailSignature: () => void;
	getAtsEmailThreadByIdRequested: (id: number) => void;
	resetAtsCurrentEmailThread: () => void;
	postAtsToExistingEmailThreadRequested: (
		threadId: number,
		data: { content: string; attachments: string[] },
		cb: () => void,
	) => void;
	onChangePage: (page: number, size: number) => void;
	updateAtsEmailUnreadThreadCount: (applicationId: number, threadId: number) => void;
};

export const enum MessageTitlesEnum {
	Inbox = '1',
	Sent = '2',
}

const MessageTab: FC<MessageTabProps> = ({
	emailThreads,
	currentEmailThread,
	searchTerm,
	emailSignatureData,
	loading,
	getClientEmailSignature,
	getAtsEmailThreadByIdRequested,
	resetAtsCurrentEmailThread,
	postAtsToExistingEmailThreadRequested,
	onChangePage,
	updateAtsEmailUnreadThreadCount,
}) => {
	const [currentCandidate, setCurrentCandidate] = useState<EmailDataType | undefined>();
	const [candidateList, setCandidateList] = useState<
		{ candidate: string; candidateApplicationId: number; unreadMessages: boolean }[]
	>([]);
	const [openThread, setOpenThread] = useState<boolean | EmailThreadType>(false);
	const location = useLocation();
	const navigate = useNavigate();

	useMount(() => {
		getClientEmailSignature();
	});

	useEffect(() => {
		const list = emailThreads?.data?.map((item) => ({
			candidate: item.candidate,
			candidateApplicationId: item.candidateApplicationId,
			unreadMessages: item.threads.some((t) => t?.unreadMessagesCount > 0),
		}));
		setCandidateList(list);
	}, [emailThreads]);

	useEffect(() => {
		currentEmailThread ? setOpenThread(currentEmailThread) : setOpenThread(false);
	}, [currentEmailThread]);

	useEffect(() => {
		if (openThread) {
			const isFirstTimeOpeningThread = !!currentCandidate?.threads.find(
				(item) => item.id === (openThread as EmailThreadType).id,
			)?.unreadMessagesCount;
			if (isFirstTimeOpeningThread) {
				updateAtsEmailUnreadThreadCount(currentCandidate?.candidateApplicationId, openThread?.id);
			}
		}
	}, [openThread]);

	const selectCandidate = (id: number) => {
		openThread && resetAtsCurrentEmailThread();
		const candidate = emailThreads.data.find((item) => item.candidateApplicationId === id);
		setCurrentCandidate(candidate);
	};

	const handleViewEmail = (id: number) => {
		getAtsEmailThreadByIdRequested(id);
	};

	const handleCloseThread = () => {
		resetAtsCurrentEmailThread();
	};

	const handleSubmitReply = (
		values: { content: string; attachments: string[] },
		cb: () => void,
	) => {
		postAtsToExistingEmailThreadRequested((openThread as EmailThreadType).id, values, cb);
	};

	useEffect(() => {
		if (
			location?.state?.candidateApplicationId &&
			location?.state?.threadId &&
			emailThreads?.data?.length > 0
		) {
			selectCandidate(location.state.candidateApplicationId);
			handleViewEmail(location.state.threadId);
			// remove state so its triggered only once after the link to the thread is clicked from dashboard or other places
			navigate(location.pathname, { replace: true, state: undefined });
		}
	}, [emailThreads, location]);

	const paginationData = {
		pageSize: emailThreads?.pageSize || DEFAULT_PAGE_SIZE,
		current: emailThreads?.pageIndex + 1,
		total: emailThreads?.totalCount,
	};

	const profileLinkProps = currentCandidate?.currentClientApplication
		? {
			to: `${Routes.ATS}${Routes.AppliedJobsCandidate}/${currentCandidate?.candidateApplicationId}`,
			state: { jobId: currentCandidate?.jobId },
		  }
		: {
			to: `${Routes.ATS}${Routes.CandidatesDatabase}/${currentCandidate?.candidateApplicationId}`,
		  };

	const loadingEmailThread = !!loading?.getAtsEmailThreadByIdLoad;
	const sendingReply = !!loading?.postAtsToExistingEmailThreadLoad;

	if (searchTerm && !candidateList.length) {
		return (
			<Styled.Root>
				<Styled.NoSearchResults>
					Sorry, no results found for your search query. Try refining your search criteria to find
					the required information
				</Styled.NoSearchResults>
			</Styled.Root>
		);
	}

	return (
		<Styled.Root>
			<Styled.Content>
				<PanelMenu
					candidateList={candidateList}
					currentCandidateThread={currentCandidate}
					handleSelectCandidate={selectCandidate}
					handlePagination={onChangePage}
					paginationData={paginationData}
					searchTerm={searchTerm}
				/>
				{currentCandidate && (
					<Styled.Details>
						<Styled.CandidateInfo>
							<Styled.CandidateFirstLine>
								<Styled.CandidateInfoName>{currentCandidate.candidate}</Styled.CandidateInfoName>
								<Styled.CandidateInfoViewProfileButton>
									<Link {...profileLinkProps}>
										<Button
											icon={<EyeOpenIcon width='16' height='16' fill={COLORS.darkGray2} />}
											size='large'
											disabled={currentCandidate.removedFromCandidateDatabase === true}
										>
											View Profile
										</Button>
									</Link>
								</Styled.CandidateInfoViewProfileButton>
							</Styled.CandidateFirstLine>
							<Styled.CandidateSecondLine>
								<Styled.CandidateInfoLocation>
									<MapMarkerIcon width='16' height='16' fill={COLORS.gray} />
									<span>{currentCandidate.jobLocation}</span>
								</Styled.CandidateInfoLocation>
								<Styled.CandidateInfoPhone>
									<PhoneIcon width='16' height='16' fill={COLORS.gray} />
									<span>{currentCandidate.phoneNumber}</span>
								</Styled.CandidateInfoPhone>
								<Styled.CandidateInfoEmail>
									<EmailIcon width='16' height='16' fill={COLORS.gray} />
									<span>{currentCandidate.email}</span>
								</Styled.CandidateInfoEmail>
							</Styled.CandidateSecondLine>
						</Styled.CandidateInfo>
						{loadingEmailThread && <Spinner fixed />}
						{openThread && (
							<Styled.OpenThread>
								<Styled.OpenThreadHeader>
									<Styled.OpenThreadTopic onClick={handleCloseThread}>
										<NavBackButton />
										{(openThread as EmailThreadType).topic}
									</Styled.OpenThreadTopic>
								</Styled.OpenThreadHeader>
								<EmailThreadComponent emailThread={openThread as EmailThreadType} />
								<ReplyEmailForm
									submitButtonLoading={sendingReply}
									handleSubmitReply={handleSubmitReply}
									clientEmailSignature={emailSignatureData}
								/>
							</Styled.OpenThread>
						)}
						{!openThread && !loadingEmailThread && (
							<Styled.CandidateThreads>
								{currentCandidate.threads.map((thread) => (
									<Styled.CandidateThread key={thread.id}>
										<Styled.CandidateThreadPart>
											<Styled.CandidateThreadTopic>{thread.topic}</Styled.CandidateThreadTopic>
											<Styled.CandidateThreadNumberOfMessages>
												<Badge
													dot={!!thread.unreadMessagesCount}
													style={{ background: COLORS.blue, width: '8px', height: '8px' }}
													offset={[8, -5]}
												>
													{thread.totalMessagesCount}
												</Badge>
											</Styled.CandidateThreadNumberOfMessages>
										</Styled.CandidateThreadPart>
										<Styled.CandidateThreadPart>
											<Styled.CandidateThreadDate>
												{dayjs(thread.lastMessageDate).format('MMM DD, YYYY')}
											</Styled.CandidateThreadDate>
											<Styled.CandidateThreadDate>
												{dayjs(thread.lastMessageDate).format('HH:mm')}
											</Styled.CandidateThreadDate>
											<ATSThemedButton
												buttonType={ButtonTypes.primary}
												onClick={() => handleViewEmail(thread.id)}
												style={{ fontWeight: 500 }}
											>
												View
											</ATSThemedButton>
										</Styled.CandidateThreadPart>
									</Styled.CandidateThread>
								))}
							</Styled.CandidateThreads>
						)}
					</Styled.Details>
				)}
			</Styled.Content>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		currentEmailThread: atsDucks.atsSelectors.getCurrentEmailThread(state),
		emailSignatureData: atsDucks.atsSelectors.getClientEmailSignature(state)?.emailSignatureHtml,
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getAtsEmailThreadByIdRequested: atsDucks.atsActions.getAtsEmailThreadByIdRequested,
		resetAtsCurrentEmailThread: atsDucks.atsActions.resetAtsCurrentEmailThread,
		postAtsToExistingEmailThreadRequested:
			atsDucks.atsActions.postAtsToExistingEmailThreadRequested,
		updateAtsEmailUnreadThreadCount: atsDucks.atsActions.updateAtsEmailUnreadThreadCount,
		getClientEmailSignature: atsDucks.atsActions.getClientEmailSignatureRequested,
	},
)(MessageTab);
