import React, { FC, useEffect, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { Input } from 'antd';
import arrayMutators from 'final-form-arrays';

import FieldWrapper from 'components/FieldWrapper';
import Spinner from 'components/Spinner';
import { commonDucks } from 'modules/Common/ducks';
import { IQuestionnaireValues } from 'modules/Common/types';
import { GenericType, IOption } from 'types';
import { requiredFieldValidator } from 'utils/validators';

import { ItemQuestionnaire } from '../../../containers/ATSQuestionnaire/ATSQuestionnaire.constants';
import FormBlockLine from '../../FormBlockLine';
import QuestionnaireBlocks from '../../QuestionBlocks';

type VideoQuestionnaireFormProps = {
	candidateId: number | undefined;
	currentQuestionnaire: IQuestionnaireValues;
	durationData: IOption[];
	getQuestionnaireDurations: () => void;
	getVideoQuestionnaireByCandidateId: (id: number) => void;
	loading: GenericType;
};

const VideoQuestionnaireForm: FC<VideoQuestionnaireFormProps> = ({
	candidateId,
	currentQuestionnaire,
	durationData,
	getQuestionnaireDurations,
	getVideoQuestionnaireByCandidateId,
	loading,
}) => {
	const onSubmit = (values: IQuestionnaireValues) => values;

	useEffect(() => {
		getQuestionnaireDurations();

		candidateId && getVideoQuestionnaireByCandidateId(+candidateId);
	}, [candidateId]);

	const initialValues = useMemo(
		() => ({
			title: currentQuestionnaire?.title || '',
			questions: currentQuestionnaire?.questions?.length
				? currentQuestionnaire?.questions?.map((i) => ({
					duration: i?.duration?.id,
					questionText: i?.questionText,
					description: i?.description,
				  }))
				: [ItemQuestionnaire],
			restrictedUserIds: currentQuestionnaire?.restrictedUserIds,
		}),
		[currentQuestionnaire, candidateId],
	);

	if (loading?.getVideoQuestionnaireByCandidateIdLoad) {
		return <Spinner fixed />;
	}

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={initialValues}
			autoComplete='off'
			mutators={{
				...arrayMutators,
			}}
			render={({ handleSubmit, dirty, values, form }) => (
				<form onSubmit={handleSubmit}>
					<FormBlockLine>
						<Field
							name='title'
							validate={requiredFieldValidator}
							initialValue={currentQuestionnaire?.title || ''}
						>
							{({ input, meta }) => (
								<FieldWrapper
									name='title'
									label='Title'
									required
									errorMessage={meta.submitFailed && meta.touched && meta.error}
									isFixed
								>
									<Input {...input} placeholder='' readOnly />
								</FieldWrapper>
							)}
						</Field>
					</FormBlockLine>

					<QuestionnaireBlocks form={form} durations={durationData} values={values} isPreviewMode />
				</form>
			)}
		/>
	);
};

export default connect(
	(state) => ({
		currentQuestionnaire: commonDucks.commonSelectors.getCandidateVideoQuestionnaire(state),
		durationData: commonDucks.commonSelectors.getQuestionnaireDurations(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		getVideoQuestionnaireByCandidateId:
			commonDucks.commonActions.getVideoQuestionnaireByCandidateIdRequested,
		getQuestionnaireDurations: commonDucks.commonActions.getQuestionnaireDurationsRequested,
	},
)(VideoQuestionnaireForm);
