import { Radio } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

const { Group } = Radio;

export namespace Styled {
	export const RadioGroup = styled(Group)`
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
	`;

	export const RadioOption = styled(Radio)`
		font-size: 16px;
	`;

	export const RadioFields = styled.div`
		display: flex;
		width: 100%;
		gap: 32px;
	`;

	export const FieldWrapper = styled.div`
		min-width: 33.3%;

		label {
			margin-bottom: 8px;
			color: ${COLORS.darkGray1};
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
		}

		.ant-upload-list-item a.ant-upload-list-item-thumbnail {
			width: 30px !important;
			height: 30px !important;
		}
	`;
}
