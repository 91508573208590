import type { FC } from 'react';

import { JobDataType } from 'modules/Common/types';

import JobApply from '../../JobApply';
import { Styled } from '../CareerPage.styled';

type CareerPageContactFormProps = {
	clientName: string;
	mode?: string;
	highlightColour: string;
	jobsData?: JobDataType[];
};

const CareerPageContactForm: FC<CareerPageContactFormProps> = ({
	mode,
	jobsData,
	highlightColour,
	clientName,
}) => {
	return (
		<Styled.Root>
			{jobsData?.length ? (
				<JobApply
					highlightColour={highlightColour}
					jobsData={jobsData}
					mode={mode}
					clientName={clientName}
				/>
			) : (
				<Styled.NoContentSpace />
			)}
		</Styled.Root>
	);
};

export default CareerPageContactForm;
