import styled from 'styled-components/macro';

import { COLORS } from 'theme';
interface IParticipantProps {
	participantsIdx: number;
	participantIsSpeaking: boolean;
}

export namespace Styled {
	export const Participant = styled.div<IParticipantProps>`
        ${({ participantsIdx }) => {
    	if(participantsIdx === 0) {
    		return 'grid-area: B;';
    	};
    	if(participantsIdx === 1) {
    		return `
                grid-area: C;
            `;
    	};
    	if(participantsIdx === 2) {
    		return `
                grid-area: D;
            `;
    	};

    	return '';
	}};
        border: ${({ participantIsSpeaking }) => participantIsSpeaking ? '4px solid ' + COLORS.green : 'none'};
    `;
    export const EnableVideoTile = styled.div`
        background: ${COLORS.black};

        video-player {
            z-index: 2;
        }
    `;
    export const ParticipantName = styled.div`
		border-radius: 4px;
		background: rgba(255, 255, 255, 0.5);
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
		text-align: center;
		padding: 4px 16px;
        overflow: hidden;
        text-overflow: ellipsis;
    `;
	export const DisabledVideoTile = styled.div`
        width: 100%;
        min-height: 300px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${COLORS.black};
    `;
	export const DisabledVideoTileName = styled.div`
        border-radius: 4px;
        background: rgba(255, 255, 255, 0.50);
        box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        text-align: center;
        padding: 24px;
        text-overflow: ellipsis;
        max-width: 450px;
        overflow: hidden;
    `;
    export const HiddenNumberOfParticipants = styled.div`
        background: ${COLORS.black};
        color: ${COLORS.white};
        padding: 10px;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 16px;
        font-weight: bold;
        grid-area: E;
    `;
};