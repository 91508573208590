import { Select as SelectComponent, Button as ButtonComponent } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

const { Option } = SelectComponent;

export namespace Styled {
	export const Root = styled.section``;

	export const Head = styled.section`
		display: flex;
		justify-content: space-between;
	`;

	export const MainInfo = styled.section`
		h2 {
			margin-bottom: 0;
		}

		p {
			margin: 0;
			white-space: break-spaces;
			line-height: 24px;
		}
	`;

	export const NoInfo = styled.section`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		min-height: 500px;
		gap: 24px;
	`;

	export const BoxWrapper = styled.div`
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 16px 24px;
		margin-bottom: 32px;
		background: ${COLORS.white};
		box-shadow: ${COLORS.elevationShadow1};
		border-radius: 10px;
		gap: 12px;
	`;

	export const InfoHead = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		gap: 16px;

		.ant-btn {
			background-color: ${COLORS.lightGray3};

			.ant-btn-icon {
				vertical-align: middle;

				svg,
				path {
					transition: fill 0.2s ease;
				}
			}

			&:hover {
				.ant-btn-icon {
					svg,
					path {
						fill: ${COLORS.inputColor};
					}
				}
			}
		}
	`;

	export const ControlButtonsBox = styled.div`
		display: flex;
		gap: 16px;
	`;

	export const Button = styled(ButtonComponent)`
		&.ant-btn {
			padding: 0 24px;
			font-weight: 700;
			color: ${COLORS.darkGray2};
			border: 1px solid ${COLORS.borderColor};
			box-shadow: ${COLORS.elevationShadow1};

			span {
				font-size: 14px;
			}
		}
	`;

	export const Select = styled(SelectComponent)`
		&.ant-select {
			position: relative;
			height: 40px;

			.ant-select-selector {
				position: relative;
				height: 40px;
				padding: 0 24px;
				border: 1px solid ${COLORS.borderColor};
				background-color: ${COLORS.lightGray3};
				box-shadow: ${COLORS.elevationShadow1};

				.ant-select-selection-item {
					font-size: 14px;
					font-weight: 700;
					line-height: 38px;
					color: ${COLORS.darkGray2};
				}
			}
		}
	`;

	export const SelectOption = styled(Option)``;

	export const InfoDetails = styled.div`
		display: flex;
		width: 100%;
		align-items: center;
		font-weight: 400;
		font-size: 16px;
		gap: 16px;
	`;

	export const InfoDetailsItem = styled.div`
		display: flex;
		align-items: center;
	`;

	export const Label = styled.div`
		margin-left: 10px;
		font-weight: 400;
		color: ${COLORS.darkGray2};
	`;

	export const Data = styled.div`
		margin-left: auto;
	`;

	export const ContentTitlePanel = styled.div`
		margin: 36px 0;
		justify-content: space-between;
		display: flex;
	`;

	export const ContentTitle = styled.h2`
		margin-bottom: 0!important;
	`;

	export const Amount = styled.div`
		display: inline-block;
		margin-left: 8px;
		padding: 4px 8px;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		color: ${COLORS.darkGray2};
		background: ${COLORS.lightGray2};
		border-radius: 4px;
	`;
}
