import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		min-height: 100%;

		h2 {
			margin-bottom: 24px;
		}

		p {
			margin-bottom: 24px;
		}

		form {
			display: flex;
			flex-direction: column;
			height: 100%;
		}

		section {
			height: 100%;
		}
	`;

	export const ButtonWrapper = styled.div`
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		padding-top: 36px;
	`;
}
