export function getActionWithoutSuffix(type) {
	return type
		.split('_')
		.slice(0, -1)
		.join('_');
}

export function getSuccessActionType(action) {
	return `${getActionWithoutSuffix(action)}_SUCCESS`;
}

export function getFailedActionType(action) {
	return `${getActionWithoutSuffix(action)}_FAILED`;
}

export const createAsyncConstants = (prefix, actionName) => ({
	REQUESTED: `${prefix}//${actionName}_REQUESTED`,
	SUCCESS: `${prefix}//${actionName}_SUCCESS`,
	FAILED: `${prefix}//${actionName}_FAILED`
});

export const createAsyncConstantsMap = (prefix, actionNames) =>
	actionNames.reduce((acc, an) => {
		const currentActionName = createAsyncConstants(prefix, an);

		acc[an] = currentActionName;

		return acc;
	}, {});

export const createSyncConstant = (prefix, actionName) => `${prefix}//${actionName}`;

export const createSyncConstantsMap = (prefix, actionNames) =>
	actionNames.reduce((acc, an) => {
		const currentActionName = createSyncConstant(prefix, an);

		acc[an] = currentActionName;

		return acc;
	}, {});

export const handlersDefaultCase = (handlers, action = {}, state) =>
	// eslint-disable-next-line no-prototype-builtins
	handlers.hasOwnProperty(action.type) ? handlers[action.type]() : state;

export const createReducer = (initialState, handlers) => {
	return function reducer(state = initialState, action = {}) {
		// eslint-disable-next-line no-prototype-builtins
		if (handlers.hasOwnProperty(action.type)) {
			return handlers[action.type](state, action);
		} else {
			return state;
		}
	};
};
