export const STATISTICS_COLORS = [
	'#CD8CFF',
	'#56CCF2',
	'#F1AC6C',
	'#B7EB8F',
	'#FFB7F8',
	'#FFC36A',
	'#FFF282',
	'#A4FFF1',
	'#FF5AB3',
	'#8F9FFF',
	'#9B51E0',
	'#2F80ED',
	'#B06927',
	'#219653',
	'#FD70EF',
	'#F2994A',
	'#F4D062',
	'#3EEBD0',
	'#CC006E',
	'#00169D',
	'#681FAD',
	'#0F50A9',
	'#714319',
	'#156035',
	'#FC0DE4',
	'#E97711',
	'#F4B801',
	'#14BDA3',
	'#940050',
	'#00169D',
];

export const STATISTICS_CANDIDATE_HIRING_STAGE_COLORS = [
	'#FBBC04',
	'#5B94F0',
	'#66D3D8',
	'#98DB7A',
	'#009437',
	'#940050',
];

export const STATISTICS_COLORS_NO_DATA = ['#F8F8F8'];

export const STATISTICS_COLORS_ACCEPTED_REJECTED = ['#6AA84F', '#EA4335'];
