import React from 'react';

import { AtsNavigationMenuList } from 'modules/Common/types';

import CandidatesApplication from '../../containers/CandidatesApplication';

import { Styled } from './ATSCandidatesApplicationPage.styled';

const ATSCandidatesApplicationPage = () => {
	return (
		<>
			<Styled.Head>
				<Styled.Title>{AtsNavigationMenuList.CVDatabase}</Styled.Title>
			</Styled.Head>
			<Styled.Content>
				<CandidatesApplication />
			</Styled.Content>
		</>
	);
};

export default ATSCandidatesApplicationPage;
