import _ from 'lodash';

import { handlersDefaultCase } from 'utils/reduxHelpers';

export const getActionNamesArr = obj => Object.keys(obj);

export const getActionNamesMap = arr =>
	arr.reduce((acc, an) => {
		acc[an] = `${_.camelCase(an)}Load`;

		return acc;
	}, {});

export const getInitLoadState = obj =>
	Object.values(obj).reduce((acc, anm) => {
		acc[anm] = false;

		return acc;
	}, {});

export default function createLoading(actionTypes) {
	const actionNames = getActionNamesArr(actionTypes);
	const actionNamesMap = getActionNamesMap(actionNames);
	const INITIAL_STATE = getInitLoadState(actionNamesMap);

	const reducer = (state = INITIAL_STATE, action = {}) => {
		const handlers = actionNames.reduce((acc, an) => {
			acc[actionTypes[an].REQUESTED] = () => ({ ...state, [actionNamesMap[an]]: true });
			acc[actionTypes[an].SUCCESS] = () => ({ ...state, [actionNamesMap[an]]: false });
			acc[actionTypes[an].FAILED] = () => ({ ...state, [actionNamesMap[an]]: false });

			return acc;
		}, {});

		return handlersDefaultCase(handlers, action, state);
	};

	return { reducer };
}
