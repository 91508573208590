import React, { FC, useCallback, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { TStyled } from 'theme';
import { Fields, IUserFormValues } from 'types';
import { emailValidator, composeValidators, requiredValidator } from 'utils/validators';

import UnregisteredInput from '../../components/UnregisteredInput';

import * as Styled from './LoginForm.styled';

type LoginFormProps = {
	loginRequested: (values: IUserFormValues, cb: (route: string) => void) => void;
	errorMessage: string;
	loading: boolean;
};

const LoginForm: FC<LoginFormProps> = ({ loginRequested, errorMessage, loading }) => {
	const navigate = useNavigate();

	const [showCredentialsError, setCredErrorVisualization] = useState(false);

	const handleSetCredError = useCallback((value: boolean) => setCredErrorVisualization(value), []);

	const onSubmit = (values: IUserFormValues) => {
		const { email } = values;
		const trimmedEmail = email.trim();
		loginRequested(
			{ ...values, email: trimmedEmail },
			(route) => route && navigate(route, { state: { oldPassword: values.password } }),
		);
		handleSetCredError(true);
	};

	return (
		<Styled.Form>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit, valid, dirtySinceLastSubmit }) => {
					if (dirtySinceLastSubmit) {
						handleSetCredError(false);
					}

					return (
						<form onSubmit={handleSubmit}>
							<Field name='email' validate={composeValidators(requiredValidator, emailValidator)}>
								{({ input, meta }) => (
									<UnregisteredInput
										{...input}
										type={Fields.TEXT}
										placeholder='Email Address'
										isFullWidth
										errorMessage={meta.error && meta.dirty && meta.error}
									/>
								)}
							</Field>
							<Field name='password'>
								{({ input, meta }) => (
									<UnregisteredInput
										{...input}
										type={Fields.PASSWORD}
										placeholder='Password'
										isFullWidth
									/>
								)}
							</Field>
							{showCredentialsError && errorMessage && typeof errorMessage === 'string' && (
								<TStyled.ErrorMessage>{errorMessage}</TStyled.ErrorMessage>
							)}

							<Button
								isFullWidth
								buttonType={ButtonTypes.primary}
								type='submit'
								disabled={!valid || loading}
								loading={loading}
							>
								Login
							</Button>
						</form>
					);
				}}
			/>
		</Styled.Form>
	);
};

export default connect(
	(state) => ({
		errorMessage: unregisteredDucks.unregisteredSelectors.getErrorMessage(state),
		loading: unregisteredDucks.unregisteredSelectors.getLoading(state).loginLoad,
	}),
	{
		loginRequested: unregisteredDucks.unregisteredActions.login,
	},
)(LoginForm);
