import { isEmpty } from 'lodash';

import { del, get, patch, post } from 'api/axiosInstance';
import { RequestsEnum } from 'types';
import { getTableParams, qs } from 'utils/helpers';

export const getQuestionnaireDuration = async () => get(RequestsEnum.QuestionnaireDuration);

export const getAllBackofficeQuestionnaires = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	const endpoint = params.client
		? `${RequestsEnum.ClientContextQuestionnaire}${queryParams}`
		: `${RequestsEnum.Questionnaire}${queryParams}`;

	return get(endpoint);
};

export const getAllATSQuestionnaires = async (params) => {
	const queryParams = !isEmpty(params) ? qs(params) : '';

	return get(`${RequestsEnum.ATSQuestionnaire}${queryParams}`);
};

export const getQuestionnairesCopy = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.QuestionnaireCopy}${queryParams}`);
};

export const getQuestionnairesSendById = async (id, { page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.QuestionnaireSend}/${id}${queryParams}`);
};

export const getQuestionnaireById = async (id) => get(`${RequestsEnum.ATSQuestionnaire}/${id}`);

export const getQuestionnaireUsers = async (params) => {
	const queryParams = !isEmpty(params) ? qs(params) : '';

	return get(`${RequestsEnum.ATSUserQuestionnaire}${queryParams}`);
};

export const saveQuestionnaire = async (data) => post(RequestsEnum.ATSQuestionnaire, data);

export const sendCandidateQuestionnaire = async (data) =>
	post(RequestsEnum.QuestionnaireSend, data, { 'X-Hide-Alert-Error': true });

export const resendQuestionnaireToCandidate = async (data) =>
	post(RequestsEnum.ATSQuestionnaireCandidateResend, data);

export const recallQuestionnaireToCandidate = async (data) =>
	post(RequestsEnum.ATSQuestionnaireCandidateRecall, data);

export const getVideoQuestionnaireByCandidateId = async (id) =>
	get(`${RequestsEnum.ATSQuestionnaireCandidate}/${id}`);

export const getVideoQuestionnaireResultByCandidateId = async (id) =>
	get(`${RequestsEnum.ATSQuestionnaireCandidate}/${id}/answer`);

export const getVideoQuestionnaireWellcome = async ({ pwd, qstId }) =>
	get(`${RequestsEnum.OpenQuestionnaire}/${qstId}/welcome?pwd=${pwd}`);

export const getVideoQuestionnaireDetails = async ({ pwd }) =>
	get(`${RequestsEnum.OpenQuestionnaire}/questions?pwd=${pwd}`);

export const getVideoQuestionnaireQuestionDetails = async ({ pwd, questionId }) =>
	get(`${RequestsEnum.OpenQuestionnaire}/questions/${questionId}?pwd=${pwd}`);

export const answerVideoQuestionnaireQuestion = async ({ pwd, questionId, data }, config) =>
	post(`${RequestsEnum.OpenQuestionnaire}/questions/${questionId}?pwd=${pwd}`, data, config);

export const updateQuestionnaireVersionById = async (id, data) =>
	post(`${RequestsEnum.ATSQuestionnaire}/${id}/version`, data);

export const deleteQuestionnaireById = async (id) => del(`${RequestsEnum.ATSQuestionnaire}/${id}`);

export const archiveUnarchiveQuestionnaireById = async (id, data) =>
	patch(`${RequestsEnum.ATSQuestionnaire}/${id}/state`, data);

export const getVideoInterviewCount = async (date) =>
	get(`${RequestsEnum.ATSVideoInterviewCount}?date=${date}`);

export const QuestionnairesService = {
	getAllBackofficeQuestionnaires,
	getAllATSQuestionnaires,
	getQuestionnaireDuration,
	getQuestionnairesCopy,
	getQuestionnairesSendById,
	getQuestionnaireById,
	getQuestionnaireUsers,
	saveQuestionnaire,
	sendCandidateQuestionnaire,
	getVideoQuestionnaireByCandidateId,
	getVideoQuestionnaireResultByCandidateId,
	resendQuestionnaireToCandidate,
	recallQuestionnaireToCandidate,
	updateQuestionnaireVersionById,
	deleteQuestionnaireById,
	archiveUnarchiveQuestionnaireById,
	getVideoQuestionnaireWellcome,
	getVideoQuestionnaireDetails,
	getVideoQuestionnaireQuestionDetails,
	answerVideoQuestionnaireQuestion,
	getVideoInterviewCount,
};

export default QuestionnairesService;
