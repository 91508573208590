import React, { FC } from 'react';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import { DEFAULT_LOGO } from 'modules/Common/constants';

import { Styled } from './QuetionnaireWelcome.styled';

type QuestionnaireWelcomeProps = {
	handleStartQuestionnaire: () => void;
	welcomeInfo: {
		backgroundImage: string;
		client: string;
		logo: string;
	};
};

const baseURL = `${process.env.REACT_APP_API_URL}/open/public/file/logo/`;

const QuestionnaireWelcome: FC<QuestionnaireWelcomeProps> = ({
	welcomeInfo,
	handleStartQuestionnaire,
}) => {
	const { client, logo = DEFAULT_LOGO } = welcomeInfo;
	const url = logo && !logo?.startsWith('/images') ? `${baseURL}${logo}` : logo;

	return (
		<Styled.Root>
			<Styled.ClientInfo>
				<Styled.LogoWrapper>
					<img src={url} alt='' />
				</Styled.LogoWrapper>
				<Styled.ClientName>{client}</Styled.ClientName>
			</Styled.ClientInfo>
			<Styled.Main>
				<Styled.Title>Video Questionnaire</Styled.Title>
				<Styled.Desc>Use the button below to start your video questionnaire.</Styled.Desc>
				<Styled.Desc>
					Please note, you’ll be given a time limit to answer each question, however we usually
					advise keeping it as concise as possible.
				</Styled.Desc>
				<Styled.ButtonWrapper>
					<Button onClick={handleStartQuestionnaire} buttonType={ButtonTypes.primary}>
						Start Video Questionnaire
					</Button>
				</Styled.ButtonWrapper>
			</Styled.Main>
		</Styled.Root>
	);
};

export default QuestionnaireWelcome;
