import styled from 'styled-components/macro';

import {COLORS} from 'theme';

export namespace Styled {
	export const Root = styled.div`
		position: relative;
		background-color: ${COLORS.white};
	`;
	export const ColorPickerWrapper = styled.div`
		margin-bottom: 32px;
	`;
	export const BgUploader = styled.div`
		position: absolute;
		z-index: 3;
		top: 175px;
		left: calc(50% - 12px);
	`;
	export const LogoUploader = styled.div`
		position: absolute;
		z-index: 15;
		top: 325px;
		left: 237px;
	`;
}
