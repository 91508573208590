import { message } from 'antd';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload';
import _ from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUploader =
	(
		setLinkCallback: (url: string) => void,
		setFileListCallback: (fileList: UploadFile[]) => void,
		type: string,
	) =>
		(payload: UploadChangeParam) => {
			const { fileList } = payload;
			const { status } = payload.file;

			if (status === undefined) return;
		
			setFileListCallback(fileList.map((f) => _.cloneDeep(f)));

			if (status === 'done') {
				const { response } = payload.file;

				setLinkCallback(response?.data);
				message.success(`${payload.file.name} file uploaded successfully.`);
			} else if (status === 'error') {
				message.error(`${payload.file.name} file upload failed.`);
				console.error('problem with uploading', payload.file, payload.fileList);
			}
		};
