import React, { type FC } from 'react';

import { Styled } from './CandidateListSwitch.styled';

type CandidateListSwitchProps = {
	activeItem: string;
	items: {
		id: string;
		value: string;
		icon: JSX.Element;
	}[];
	onSwitch: (id: string) => void;
};

export const CandidateListSwitch: FC<CandidateListSwitchProps> = ({
	items,
	onSwitch,
	activeItem,
}) => (
	<Styled.BrandingSwitchWrapper>
		{items.map((i) => (
			<Styled.BrandingSwitchItem
				isItemActive={activeItem === i.id}
				key={i.value}
				onClick={() => onSwitch(i.id)}
			>
				<Styled.BrandingSwitchItemIcon isItemActive={activeItem === i.id}>
					{i.icon}
				</Styled.BrandingSwitchItemIcon>
			</Styled.BrandingSwitchItem>
		))}
	</Styled.BrandingSwitchWrapper>
);
