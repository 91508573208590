import React, { FC } from 'react';

import { Styled } from './Ranking.styled';

type RankingProps = {
	value: number;
	disabled?: boolean;
	onChange?: (val: number, cb: () => void) => void;
};

const Ranking: FC<RankingProps> = ({ value, onChange, ...props }) => {
	const [currentRankValue, setCurrentRankValue] = React.useState<boolean | number>(false);

	const updateRank = (val: number) => {
		setCurrentRankValue(val);
		onChange && onChange(val, () => setCurrentRankValue(false));
	};

	return (
		<Styled.RankingStars
			value={typeof currentRankValue === 'boolean' ? value : currentRankValue}
			onChange={updateRank}
			{...props}
		/>
	);
};

export default Ranking;
