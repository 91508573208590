import styled from 'styled-components/macro';

type ContentProps = {
	maxElHeight: number;
	isShownAll: boolean;
};

export namespace Styled {
	export const Wrapper = styled.div`
		width: 100%;
	`;

	export const Content = styled.div<ContentProps>`
		${({ maxElHeight, isShownAll }) => {
		if(!isShownAll) {
			return (
				`
						height: ${maxElHeight}px;
						overflow: hidden;
					`
			);
		}
	}};
	`;
	export const ActionButtonWrapper = styled.div`
		display: flex;
		justify-content: center;
		width: 100%;
	`;

}
