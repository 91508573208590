import React, { useEffect, useLayoutEffect } from 'react';

import API from 'api';

export const VersionProvider = () => {
	useLayoutEffect(() => {
		document &&
			document
				?.querySelector('meta[name=viewport]')
				?.setAttribute(
					'content',
					'width=device-width, initial-scale=' + 1 / window.devicePixelRatio,
				);
	}, []);

	useEffect(() => {
		const getVersion = async () => {
			const beVerions = await API.versionService.getBackendVersion();

			console.info('backend version ', beVerions);

			// eslint-disable-next-line @typescript-eslint/no-var-requires
			const packageJSON = require('../../../package.json');

			console.info('frontend version', packageJSON.version);
		};
		getVersion();
	}, []);

	return <></>;
};
