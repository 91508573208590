import { IContractValues } from 'modules/Common/types';
import { GenericType } from 'types';

export const getContractTableData = (creditsFields: GenericType[], contract: IContractValues) =>
	creditsFields.reduce((acc, cur, index) => {
		const amount =
			contract?.[cur?.creditPrefix as keyof IContractValues] ||
			contract?.[cur?.value as keyof IContractValues] ||
			0;
		const unitPrice = contract?.[cur?.creditPricePrefix as keyof IContractValues] ?? 0;
		const totalPrice = +amount * +unitPrice;

		if (totalPrice) {
			acc.push({
				key: index,
				name: cur.name,
				amount,
				unitPrice,
				totalPrice,
			});
		}

		return acc;
	}, [] as GenericType[]);
