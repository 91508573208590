import React, { useState } from 'react';

import Button from 'components/Button';

import { Styled } from './ShowMoreWrapperItemRelated.styled';

interface ShowMoreWrapperItemRelatedProps {
	maxVisibleItems: number;
	children: React.ReactNode[];
	showMoreText?: string;
	hideText?: string;
	style?: React.CSSProperties;
}

export const ShowMoreWrapperItemRelated: React.FC<ShowMoreWrapperItemRelatedProps> = ({
	maxVisibleItems,
	children,
	showMoreText = 'Show all',
	hideText = 'Hide',
	style,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const childrenArray = React.Children.toArray(children);

	const showShowMoreButton = childrenArray.length > maxVisibleItems;

	const displayedChildren = isExpanded ? childrenArray : childrenArray.slice(0, maxVisibleItems);

	return (
		<Styled.Wrapper style={style}>
			{displayedChildren}

			{showShowMoreButton && (
				<Styled.ActionButtonWrapper>
					<Button onClick={() => setIsExpanded(!isExpanded)}>
						{isExpanded ? hideText : showMoreText}
					</Button>
				</Styled.ActionButtonWrapper>
			)}
		</Styled.Wrapper>
	);
};
