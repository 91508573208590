import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function PhoneIcon({ fill = COLORS.darkGray1, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M17.4 22.0001C13.3173 21.9948 9.40331 20.3706 6.5164 17.4837C3.62949 14.5968 2.00529 10.6828 2 6.60011C2 5.38012 2.48464 4.21009 3.34731 3.34742C4.20998 2.48476 5.38 2.00011 6.6 2.00011C6.85834 1.99815 7.11625 2.02159 7.37 2.07011C7.61531 2.10641 7.85647 2.1667 8.09 2.25011C8.25425 2.30774 8.40061 2.40725 8.51461 2.53879C8.62861 2.67033 8.7063 2.82934 8.74 3.00011L10.11 9.00011C10.1469 9.16298 10.1425 9.33249 10.0971 9.4932C10.0516 9.6539 9.96671 9.80067 9.85 9.92011C9.72 10.0601 9.71 10.0701 8.48 10.7101C9.46499 12.871 11.1932 14.6063 13.35 15.6001C14 14.3601 14.01 14.3501 14.15 14.2201C14.2694 14.1034 14.4162 14.0185 14.5769 13.9731C14.7376 13.9276 14.9071 13.9232 15.07 13.9601L21.07 15.3301C21.2353 15.3685 21.3881 15.4483 21.5141 15.562C21.64 15.6757 21.735 15.8196 21.79 15.9801C21.8744 16.2175 21.938 16.4617 21.98 16.7101C22.0202 16.9614 22.0403 17.2156 22.04 17.4701C22.0216 18.6849 21.5233 19.8431 20.654 20.6918C19.7847 21.5405 18.6149 22.0108 17.4 22.0001Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(PhoneIcon);
