import styled from 'styled-components/macro';

export namespace Styled {
	export const Head = styled.section`
		display: flex;
		justify-content: space-between;
	`;

	export const Content = styled.section`
		h2 {
			margin-bottom: 16px;
		}
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
		margin-bottom: 32px;
		gap: 16px;
	`;

	export const PdfButtonWrapper = styled.div`
		padding-top: 16px;
	`;
}
