import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';
import ClientChangesForm from 'modules/BackOffice/containers/ClientChangesForm';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { AppModes, Routes } from 'types';

import { Root } from './EditClient.styled';

const EditClientPage = () => {
	const anchorRef = useRef<HTMLDivElement>(null);
	const { state } = useLocation();
	const clientTitle = state?.clientName;

	useScrollToTopOnMount(anchorRef);

	return (
		<Root ref={anchorRef}>
			<PageNavTitle title={clientTitle} navigationLink={`${Routes.BackOffice}${Routes.Clients}`} />
			<ClientChangesForm mode={AppModes.Edit} />
		</Root>
	);
};

export default EditClientPage;
