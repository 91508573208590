import { useLocation } from 'react-router-dom';

export const useSubdomain = (isProd:boolean) => {
	const hostnameLength = isProd ? 3 : 4;
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const subdomainParam = urlParams.get('subdomain')?.toLowerCase();
	const hostnameData = window.location.hostname?.split('.');
	const subdomain = (hostnameData?.length === hostnameLength ? hostnameData[0] : null) || subdomainParam;

	return subdomain;
};
