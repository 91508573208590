import { connect } from 'react-redux';

import { backOfficeDucks } from '../../ducks';

import JobChangesFormContainer from './JobChangesFormContainer';

export default connect(
	(state) => ({
		clientsData: backOfficeDucks.backOfficeSelectors.getClientsShortData(state),
		jobData: backOfficeDucks.backOfficeSelectors.getBackOfficeJob(state),
		defaultThankYouMessage: backOfficeDucks.backOfficeSelectors.getDefaultThankYouMessage(state),
		loadingBackoffice: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
	}),
	{
		getClientsListRequested: backOfficeDucks.backOfficeActions.getClientsShortRequested,
		getBackOfficeJobById: backOfficeDucks.backOfficeActions.getBackOfficeJobByIdRequested,
		getDefaultMessageByClientId:
			backOfficeDucks.backOfficeActions.getBoApplicationMessagesByClientIdRequested,
		resetBackOfficeJobChangesForm:
			backOfficeDucks.backOfficeActions.resetBackOfficeJobChangesFormRequested,
	},
)(JobChangesFormContainer);
