import React, { type FC, memo } from 'react';
import { Field } from 'react-final-form';
import { Link } from 'react-router-dom';

import { Button, DatePicker, Select } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import FormSwitch from 'components/FormSwitch';
import FieldHtmlEditor from 'modules/Common/components/FieldEditor/FieldHtmlEditor';
import FormBlock from 'modules/Common/components/FormBlock';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { InterviewTypesFormEnum } from 'modules/Common/types';
import { TStyled } from 'theme/styled';
import { DataFormatEnum, GenericType, IOption, Routes } from 'types';
import { requiredFieldValidator } from 'utils/validators';

import { Styled } from '../ArrangeInterview.styled';
import { ArrangeInterviewFormParamsType, IArrangeCandidate } from '../ArrangeInterview.types';

type MainInfoProps = {
	jobs: IOption[];
	jobId?: string | number;
	values?: Partial<ArrangeInterviewFormParamsType>;
	defaultValues?: Partial<ArrangeInterviewFormParamsType>;
	candidates?: IArrangeCandidate[] | [];
	curCandidate?: IArrangeCandidate | null;
	interviewTypes: IOption[];
	onChangeJob?: (id: string | number) => void;
	onChangeCandidate?: (value: IArrangeCandidate | null) => void;
	handleCancelInterview?: () => void;
	isEditMode?: boolean;
	loading?: GenericType;
};
const { Option } = Select;

const Details: FC<MainInfoProps> = ({
	jobs,
	jobId,
	values,
	defaultValues,
	candidates,
	curCandidate,
	interviewTypes,
	onChangeJob,
	onChangeCandidate,
	handleCancelInterview,
	isEditMode,
	loading,
}) => {
	const dataFormat = DataFormatEnum.UK ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY HH:mm';

	return (
		<FormBlock>
			<FormBlockLine>
				<>
					<h2 style={{ width: '100%', margin: 0 }}>Details</h2>
					{isEditMode && (
						<TStyled.ButtonCancel
							type='button'
							buttonType={ButtonTypes.tertiary}
							onClick={handleCancelInterview}
							loading={!!loading?.cancelAtsInterviewByIdLoad}
						>
							Cancel Interview
						</TStyled.ButtonCancel>
					)}
				</>
			</FormBlockLine>
			<FormBlockLine>
				<Field name='role' validate={requiredFieldValidator}>
					{({ input, meta }) => (
						<FieldWrapper
							name='role'
							label='Role'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
							required
						>
							<Select
								{...input}
								value={input.value || null}
								onChange={(id) => {
									onChangeJob && onChangeJob(id);

									return input.onChange(id);
								}}
								placeholder='Select an active job'
							>
								{jobs?.map((option: IOption) => (
									<Option key={option.id} value={option.id}>
										{option.title}
									</Option>
								))}
							</Select>
						</FieldWrapper>
					)}
				</Field>
				<Field
					name='candidateApplicationId'
					validate={requiredFieldValidator}
					initialValue={defaultValues?.candidateApplicationId || curCandidate?.id}
				>
					{({ input, meta }) => (
						<FieldWrapper
							name='candidateApplicationId'
							label='Candidate'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
							required
						>
							<Select
								{...input}
								value={input.value || null}
								onChange={(id) => {
									const candidate = candidates?.find((i: { id: number }) => i?.id === id);
									candidate && onChangeCandidate && onChangeCandidate(candidate);

									return input.onChange(id);
								}}
								placeholder='Select a candidate'
								disabled={isEditMode}
							>
								{candidates?.length &&
									candidates.map((option: IArrangeCandidate) => (
										<Option key={`candidate-${option?.id}`} value={option?.id}>
											{option?.fullName}
										</Option>
									))}
							</Select>
						</FieldWrapper>
					)}
				</Field>
				<Styled.ButtonWrapper>
					{curCandidate?.id && values?.candidateApplicationId && (
						<Link
							to={`${Routes.ATS}${Routes.AppliedJobsCandidate}/${curCandidate?.id}?jobId=${
								curCandidate?.jobId || jobId
							}`}
							target='_blank'
						>
							<Button type='default' htmlType='button'>
								View Candidate
							</Button>
						</Link>
					)}
				</Styled.ButtonWrapper>
			</FormBlockLine>
			<FormBlockLine>
				<Field
					name='interviewType'
					validate={requiredFieldValidator}
					initialValue={defaultValues?.type?.id}
				>
					{({ input, meta }) => (
						<FieldWrapper
							name='interviewType'
							label='Interview Type'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
							required
						>
							<Select {...input} value={input.value || null} placeholder='Select an interview type'>
								{interviewTypes?.map((option: IOption) => (
									<Option key={option.id} value={option.id}>
										{option.name}
									</Option>
								))}
							</Select>
						</FieldWrapper>
					)}
				</Field>
				<Field name='startDate' validate={requiredFieldValidator}>
					{({ input, meta }) => (
						<FieldWrapper
							name='startDate'
							label='Select Date'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
							required
						>
							<DatePicker {...input} format={dataFormat} showTime />
						</FieldWrapper>
					)}
				</Field>
				<div />
			</FormBlockLine>

			<Styled.InfoBox>
				<h4>About Video Interviews</h4>
				<p>
					The candidate will be automatically sent a video session link around 30 minutes before the
					scheduled interview, however, you can resend the link at any time. To join the session
					yourself please proceed to the “interviews” page at the allotted time and follow the link.
				</p>
			</Styled.InfoBox>
			<FormBlockLine columns={1}>
				<Field name='information' initialValue={defaultValues?.information}>
					{({ input }) => {
						return (
							<FieldWrapper name='information' label='Interview details'>
								<>
									<FieldHtmlEditor
										onChange={(value) => input.onChange(value)}
										placeholder='Please enter any specific instructions or agenda for the interview here'
										defaultValue={defaultValues?.information}
										minRaws={20}
									/>
								</>
							</FieldWrapper>
						);
					}}
				</Field>
			</FormBlockLine>
			<FormBlockLine columns={1}>
				<Field
					name='sendCandidateSmsReminder'
					initialValue={defaultValues?.sendCandidateSmsReminder}
				>
					{({ input }) => (
						<>
							<FormSwitch
								{...input}
								title='Send SMS reminder for Video Interview'
								hint={'(30 mins before the interview)'}
								disabled={
									values?.interviewType === InterviewTypesFormEnum.FaceToFace ||
									values?.interviewType === InterviewTypesFormEnum.Phone
								}
							/>
						</>
					)}
				</Field>
			</FormBlockLine>
			<FormBlockLine columns={1}>
				<Field name='recordInterview' initialValue={defaultValues?.recordInterview}>
					{({ input }) => (
						<FormSwitch
							{...input}
							title='Record interview and generate video'
							disabled={
								values?.interviewType === InterviewTypesFormEnum.FaceToFace ||
								values?.interviewType === InterviewTypesFormEnum.Phone
							}
						/>
					)}
				</Field>
			</FormBlockLine>
		</FormBlock>
	);
};

export default memo(Details);
