import {
	DEFAULT_ATS_BG,
	DEFAULT_CAREER_BG,
	DEFAULT_CURRENT_PAGE,
	DEFAULT_LOGO,
	DEFAULT_PAGE_SIZE,
} from 'modules/Common/constants';

export const initialState = {
	clientBrandingState: {
		clientAtsBrandingData: {
			backgroundImage: DEFAULT_ATS_BG,
			logo: DEFAULT_LOGO,
		},
	},
	employeesList: {
		data: [],
		pageIndex: DEFAULT_CURRENT_PAGE,
		pageSize: DEFAULT_PAGE_SIZE,
		totalCount: 0,
	},
	employeeRoles: [],
	employeeStatuses: [],
	employeeDetails: null,
};
