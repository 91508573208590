import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonTypes } from 'components/Button/Button.types';
import Spinner from 'components/Spinner';
import BlueTownSmallLogo from 'components/SVG/BlueTownSmallLogo';
import { DEFAULT_ATS_BG, DEFAULT_LOGO } from 'modules/Common/constants';
import { ClientBrandingDataType } from 'modules/Common/types';
import { COLORS } from 'theme';
import { Routes, SettingsRoutes, SubscriptionPlansType } from 'types';

import HeroHeader from '../../HeroHeader';

import { Styled } from './DashboardBranding.styled';

type DashboardBrandingProps = {
	clientBrandingData: ClientBrandingDataType;
	subscriptionPlans: SubscriptionPlansType;
	isClientContextDashboard?: boolean;
	loading: boolean;
};

const baseUrl = `${process.env.REACT_APP_API_URL}/open/public/file/logo/`;

const DashboardBranding: FC<DashboardBrandingProps> = ({
	clientBrandingData,
	subscriptionPlans,
	isClientContextDashboard = false,
	loading,
}) => {
	const navigate = useNavigate();
	const { logo, backgroundImage, headerBackground, highlightColour, clientName, textColour } =
		clientBrandingData || {};

	const backgroundImageUrl = backgroundImage?.split('/').includes('images')
		? backgroundImage
		: backgroundImage && `${baseUrl}${backgroundImage}`;

	const logoUrl = logo?.split('/').includes('images') ? logo : logo && `${baseUrl}${logo}`;

	const { isPremiumPlan } = subscriptionPlans;
	const buttonProps = {
		buttonName: isPremiumPlan ? 'Careers Site' : 'Try PRO Free',
		linkPath: isPremiumPlan ? `${Routes.CareerPage}` : `${SettingsRoutes.Subscription}`,
	};

	const brandingValues = isPremiumPlan
		? { logo: logoUrl, backgroundImageUrl, textColour, highlightColour, headerBackground }
		: {
			logo: DEFAULT_LOGO,
			backgroundImageUrl: DEFAULT_ATS_BG,
			textColour: COLORS.blue,
			highlightColour: COLORS.blue,
			headerBackground: 'none',
		  };

	if (loading) {
		return <Spinner fixed />;
	}

	return (
		<Styled.Root>
			<HeroHeader
				backgroundImageUrl={brandingValues.backgroundImageUrl || '/images/background.png'}
				headerBackground={brandingValues.headerBackground}
				width='100%'
				height='264px'
			/>
			<Styled.Info isClientContextDashboard={isClientContextDashboard}>
				<Styled.Logo>
					<Styled.ImageWrapper>
						{logoUrl?.includes(DEFAULT_LOGO) ? (
							<BlueTownSmallLogo width='128px' height='128px' fill='blue' />
						) : (
							<img src={brandingValues.logo} alt='Client Logo' />
						)}
					</Styled.ImageWrapper>
				</Styled.Logo>
				<Styled.ClientName textColor={brandingValues.textColour}>{clientName}</Styled.ClientName>
				{!isClientContextDashboard && (
					<Styled.Button
						buttonColor={brandingValues.highlightColour}
						buttonType={ButtonTypes.primary}
						onClick={() => navigate(buttonProps.linkPath)}
					>
						{buttonProps.buttonName}
					</Styled.Button>
				)}
			</Styled.Info>
		</Styled.Root>
	);
};

export default DashboardBranding;
