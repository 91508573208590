import { get } from 'api/axiosInstance';
import { OpenRequestsEnum } from 'types';

export const getBackendVersion = async () => get(OpenRequestsEnum.BackendVersion);

const vesionsService = {
	getBackendVersion,
};

export default vesionsService;
