import { Button as ButtonComponent } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

interface IUserIconProps {
	bgColor?: string;
}

export namespace Styled {
	export const Root = styled.section``;

	export const NotesHeader = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
		margin-top: 20px;
	`;

	export const Button = styled(ButtonComponent)`
		&.ant-btn {
			padding: 0 24px;
			font-weight: 700;
			color: ${COLORS.darkGray2};
			border: 1px solid ${COLORS.borderColor};
			box-shadow: ${COLORS.elevationShadow1};
			background-color: ${COLORS.lightGray3};

			span {
				font-size: 14px;
			}

			.ant-btn-icon {
				vertical-align: middle;

				svg,
				path {
					transition: fill 0.2s ease;
				}
			}

			&:hover {
				.ant-btn-icon {
					svg,
					path {
						fill: ${COLORS.inputColor};
					}
				}
			}
		}
	`;

	export const UserIcon = styled.div<IUserIconProps>`
		width: 28px;
		height: 28px;
		background-color: ${({ bgColor }) => (bgColor ? bgColor : COLORS.green)};
		border-radius: 50%;
		margin-right: 10px;
		color: ${COLORS.white};
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-weight: bold;
	`;

	export const NoteItem = styled.div``;

	export const NoteInfo = styled.div`
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	`;

	export const NoteName = styled.div`
		font-weight: bold;
		margin-right: 8px;
	`;

	export const NoteDate = styled.div`
		color: ${COLORS.darkGray2};
	`;

	export const NoteText = styled.div`
		margin-left: 38px;
		margin-bottom: 15px;
	`;

	export const NoteForm = styled.div`
		display: flex;
	`;

	export const ButtonWrapper = styled.div`
		button {
			margin-top: 15px;
			margin-left: 35px;
		}
	`;
}
