import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function Icon({ fill = COLORS.green, width = '16', height = '16' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<circle cx='8.00016' cy='8.00065' r='6.66667' fill={fill} />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.7901 4.72627C12.0841 4.948 12.1426 5.36603 11.9209 5.65997L7.88955 11.0043C7.57209 11.4252 6.94145 11.4293 6.61853 11.0126L4.08457 7.74286C3.85903 7.45183 3.91212 7.03308 4.20315 6.80754C4.49418 6.582 4.91294 6.63509 5.13847 6.92612L7.24515 9.64453L10.8564 4.85703C11.0781 4.56309 11.4962 4.50455 11.7901 4.72627Z'
				fill='white'
			/>
		</svg>
	);
}

export default memo(Icon);
