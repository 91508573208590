import { type FC, useRef, useState } from 'react';

import { Button, Popover } from 'antd';

import AtsClientColorPicker from 'modules/Common/components/AtsClientColorPicker';

import { Styled } from './AjustColorPicker.styled';

type AjustColor = {
	id: number;
	title: string;
	initialValue: string;
	hint?: string;
	onChange: (color: string) => void;
};

type AjustColorPickerProps = {
	applyColors: () => void;
	resetColors: () => void;
	ajustColorData: AjustColor[];
};

export const AjustColorPicker: FC<AjustColorPickerProps> = ({
	ajustColorData,
	applyColors,
	resetColors,
}) => {
	const anchorRef = useRef<HTMLDivElement>(null);

	const [isAjustColorsActive, setAjustColorsActive] = useState(false);
	const onCancel = () => {
		setAjustColorsActive(false);
		resetColors();
	};
	const onSaveChanges = () => {
		setAjustColorsActive(false);
		applyColors();
	};

	const handleOpenColorPicker = () => {
		anchorRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });

		setAjustColorsActive(true);
	};

	return (
		<>
			<div ref={anchorRef} />
			<Styled.ButtonsContainer>
				{isAjustColorsActive && (
					<>
						<Button type='text' onClick={onCancel}>
							Cancel
						</Button>
						<Button type='primary' onClick={onSaveChanges}>
							Save Changes
						</Button>
					</>
				)}

				<Popover
					content={<AtsClientColorPicker data={ajustColorData} />}
					title={<Styled.PopoverTitle>Colors</Styled.PopoverTitle>}
					trigger='click'
					placement='bottomRight'
					open={isAjustColorsActive}
					overlayStyle={{
						width: '562px',
						padding: '0px',
					}}
				>
					<Button disabled={isAjustColorsActive} onClick={handleOpenColorPicker}>
						Adjust Colors
					</Button>
				</Popover>
			</Styled.ButtonsContainer>
		</>
	);
};
