import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';

import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

import {
	STATISTICS_CANDIDATE_HIRING_STAGE_COLORS,
	STATISTICS_COLORS_NO_DATA,
} from 'modules/Common/utils/StatisticsUtils';
import { createLegendItem } from 'utils/helpers';

import { Styled } from './DoughnutChart.styled';

type ChartDataType = {
	name: string;
	value: number;
};

type DoughnutChartProps = {
	data: ChartDataType[];
};

Chart.register(ArcElement, Tooltip, Legend);

const addPercentToValue = (percent: number) => `${percent}%`;

const DoughnutChart: FC<DoughnutChartProps> = ({ data }) => {
	const chartInstanceRef = useRef<HTMLCanvasElement>(null);
	const legendRef = useRef<HTMLUListElement>(null);
	const total = useMemo(() => data.reduce((acc, item) => acc + item.value, 0), [data]);

	const chartData = {
		labels:
			total === 0
				? ['Empty Data', ...data.map((item) => item.name)]
				: data.map((item) => item.name),
		datasets: [
			{
				label: 'Application Sources',
				data: total === 0 ? [1, ...data.map((item) => item.value)] : data.map((item) => item.value),
				backgroundColor:
					total === 0
						? [...STATISTICS_COLORS_NO_DATA, ...STATISTICS_CANDIDATE_HIRING_STAGE_COLORS]
						: STATISTICS_CANDIDATE_HIRING_STAGE_COLORS,
			},
		],
	};

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: true,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: total !== 0,
				mode: 'index',
				intersect: false,
			},
		},
	};

	useEffect(() => {
		if (!chartInstanceRef.current || !legendRef.current) return;

		const chartInstance = chartInstanceRef.current;
		const ul = legendRef.current;
		ul.innerHTML = '';

		chartInstance.data.labels.forEach((label, index) => {
			createLegendItem(chartInstance, index, label, ul, addPercentToValue);
		});
	}, [data]);

	return (
		<>
			<Styled.Chart>
				<Doughnut data={chartData} options={chartOptions} ref={chartInstanceRef} />
			</Styled.Chart>
			<Styled.Legend ref={legendRef}></Styled.Legend>
		</>
	);
};

export default DoughnutChart;
