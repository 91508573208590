import { createSelector } from 'reselect';

import { ATS_DEFAULT_COLORS } from 'common/colors';
import { InterviewGuestTypeEnum } from 'modules/Common/types';

export const createSelectors = (root) => {
	const atsRootState = createSelector([(state) => state[root]], (state) => state.data);
	const getAtsLoading = createSelector([(state) => state[root]], (state) => state.loadingReducer);

	const getClientState = createSelector(atsRootState, (data) => data.client);
	const getSubscriptionState = createSelector(atsRootState, (data) => data.subscription);
	const getCurrentSubscriptionState = createSelector(
		getSubscriptionState,
		(data) => data.currentSubscription,
	);
	const getSubscriptionTypesState = createSelector(
		getSubscriptionState,
		(data) => data.subscriptionTypes,
	);
	const getSubscriptionPlans = createSelector(
		getSubscriptionState,
		(data) => data?.subscriptionPlans,
	);
	const getClientBrandingState = createSelector(atsRootState, (data) => data?.clientBrandingState);
	const getClientAtsBrandingData = createSelector(
		atsRootState,
		(data) => data?.clientBrandingState?.clientAtsBrandingData,
	);
	const getClientAtsPremiumBrandingData = createSelector(getClientBrandingState, (data) => ({
		...ATS_DEFAULT_COLORS,
		...data?.clientAtsBrandingData,
	}));
	const getClientCareerBrandingData = createSelector(
		getClientBrandingState,
		(data) => data?.clientCareerBrandingData,
	);
	const getAtsTicketsState = createSelector(atsRootState, (rootData) => rootData.tickets);
	const getAtsTicketDetailsState = createSelector(
		atsRootState,
		(rootData) => rootData.ticketDetails,
	);

	const getAtsCandidatesDatabaseState = createSelector(
		atsRootState,
		(rootData) => rootData.candidatesDatabase,
	);
	const getAtsCandidateDatabaseDetailsState = createSelector(
		atsRootState,
		(data) => data.candidateDatabaseDetails,
	);

	const getCreditsState = createSelector(atsRootState, (data) => data.credits);
	const getCurrentOrderState = createSelector(atsRootState, (data) => data.currentOrder);

	const getInvoicesState = createSelector(atsRootState, (data) => data.invoices);
	const getCurrentInvoice = createSelector(atsRootState, (data) => data.currentInvoice);

	const getJobUpgradesState = createSelector(atsRootState, (data) => data.jobUpgrades);
	const getAtsUsersState = createSelector(atsRootState, (data) => data.atsUsers);
	const getAtsUserRolesState = createSelector(atsRootState, (data) => data.atsUserRoles);

	const getCandidateApplicationData = createSelector(
		atsRootState,
		(data) => data?.candidatesState?.candidateApplicationData,
	);

	const getCandidateAssessmentApplicationData = createSelector(
		atsRootState,
		(data) => data?.candidatesState?.candidateAssessmentApplication,
	);
	const getCandidateNotes = createSelector(
		atsRootState,
		(data) => data?.candidatesState?.candidateNotes,
	);

	const getAllCandidateApplicationByJobData = createSelector(
		atsRootState,
		(data) => data?.candidatesState?.allCandidateApplicationsByJob,
	);

	const getSettingsState = createSelector(atsRootState, (data) => data?.settingsState);

	const getSettingsCompanyDetails = createSelector(
		getSettingsState,
		(data) => data?.companyDetailsData,
	);
	const getSettingsDefaultMessages = createSelector(
		getSettingsState,
		(data) => data?.defaultMessagesData,
	);
	const getSettingsUserAccessForm = createSelector(
		getSettingsState,
		(data) => data?.userAccessForm,
	);

	const getClientApplicationNotification = createSelector(
		getSettingsState,
		(data) => data?.clientApplicationNotification,
	);

	const getClientSocialNetworks = createSelector(getSettingsState, (data) => data?.socialNetworks);
	const getClientEmailSignature = createSelector(
		getSettingsState,
		(data) => data?.emailSignatureData,
	);
	const getClientAutoPop = createSelector(getSettingsState, (data) => data?.autoPopData);
	const getClient2FAState = createSelector(getSettingsState, (data) => data?.twoFA);
	const getUserEmailNotifications = createSelector(
		getSettingsState,
		(data) => data?.emailNotificationsData,
	);
	const getClientSMSNotifications = createSelector(
		getSettingsState,
		(data) => data?.smsNotificationsData,
	);
	const getUserPersonalData = createSelector(getSettingsState, (data) => data?.personalData);

	const getPostJobChangesForm = createSelector(
		atsRootState,
		(data) => data.postJobChangesFormState?.data,
	);

	const getJobRestrictedUsers = createSelector(
		atsRootState,
		(data) => data.postJobChangesFormState?.data?.restrictedUsers ?? [],
	);

	const getJobsState = createSelector(atsRootState, (data) => data?.jobsState);
	const getJobsData = createSelector(getJobsState, (data) => data?.jobsData);
	const getActiveJobsData = createSelector(getJobsState, (data) => data?.activeJobs);
	const getAllJobsListData = createSelector(getJobsState, (data) => data?.allJobsList);
	const getAllGravityJobsListData = createSelector(getJobsState, (data) => data?.gravityJobs);
	const getCurrentGravityCandidatesData = createSelector(
		getJobsState,
		(data) => data?.currentGravityJobCandidates,
	);

	const getInterviewState = createSelector(atsRootState, (data) => data?.interviewState);
	const getInterviewTypes = createSelector(getInterviewState, (data) => data?.interviewTypes);
	const getInterviewUsers = createSelector(getInterviewState, (data) => data?.interviewUsers);
	const getInterviewJobsList = createSelector(getInterviewState, (data) => data?.interviewJobsList);
	const getInterviewVideoCount = createSelector(
		getInterviewState,
		(data) => data?.interviewVideoCount,
	);
	const getCandidateInterviewJobsList = createSelector(
		getInterviewState,
		(data) => data?.interviewEditJobsList,
	);
	const getInterviewCalendarList = createSelector(
		getInterviewState,
		(data) => data?.interviewCalendarList,
	);
	const getInterviewData = createSelector(getInterviewState, (data) => data?.interviewData);
	const getInterviewCandidateData = createSelector(getInterviewState, (data) => {
		const curCandidate = data?.interviewData?.guests?.find(
			(i) => i.type?.value === InterviewGuestTypeEnum.Candidate,
		);

		return { ...curCandidate, id: data?.interviewData?.candidateApplicationId };
	});

	const getUnappliedCandidateJobsData = createSelector(
		atsRootState,
		(data) => data.unappliedCandidateJobsState,
	);

	const getSMSCandidatesInformation = createSelector(
		atsRootState,
		(data) => data?.candidatesSMSInformation,
	);

	const getStatisticState = createSelector(atsRootState, (data) => data?.statistics);

	const getCandidateStatistics = createSelector(
		getStatisticState,
		(data) => data?.candidateStatistics,
	);

	const getApplicationStatistics = createSelector(
		getStatisticState,
		(data) => data?.applicationsStatistics,
	);

	const getJobMonthStatisticsState = createSelector(
		getStatisticState,
		(data) => data?.jobsMonthStatistic,
	);

	const getTimeToHireMonthState = createSelector(
		getStatisticState,
		(data) => data?.timeToHireMonth,
	);

	const getTimeToHireYearState = createSelector(getStatisticState, (data) => data?.timeToHireYear);

	const getApplicatonsYearState = createSelector(
		getStatisticState,
		(data) => data?.applicationsYear,
	);

	const getApplicationsMonthState = createSelector(
		getStatisticState,
		(data) => data?.applicationsMonth,
	);

	const getCandidateApplicationPerStateStatistics = createSelector(
		getStatisticState,
		(data) => data?.candidateApplicationsPerState,
	);

	const getAcceptedOffersStatistics = createSelector(
		getStatisticState,
		(data) => data?.acceptedOffersStatistics,
	);

	const getApplicationSourcesStatistics = createSelector(
		getStatisticState,
		(data) => data?.applicationSourcesStatistics,
	);

	const getEmailsState = createSelector(atsRootState, (data) => data?.emailsState);

	const getDashboardUnreadEmails = createSelector(
		getEmailsState,
		(data) => data?.dashboardUnreadEmails,
	);

	const getEmailTemplates = createSelector(getEmailsState, (data) => data?.emailTemplates);

	const getEmailInbox = createSelector(getEmailsState, (data) => data?.inboxThreads);

	const getCurrentEmailThread = createSelector(getEmailsState, (data) => data?.currentEmailThread);

	return {
		getClientState,
		getAtsLoading,
		getAtsUsersState,
		getAtsUserRolesState,
		getCurrentOrderState,
		getCandidateApplicationData,
		getCandidateAssessmentApplicationData,
		getInvoicesState,
		getJobUpgradesState,
		getCreditsState,
		getClientBrandingState,
		getClientAtsBrandingData,
		getClientAtsPremiumBrandingData,
		getClientCareerBrandingData,
		getSettingsCompanyDetails,
		getSettingsDefaultMessages,
		getSettingsUserAccessForm,
		getClientApplicationNotification,
		getClientSocialNetworks,
		getClientEmailSignature,
		getClientAutoPop,
		getClient2FAState,
		getUserEmailNotifications,
		getClientSMSNotifications,
		getUserPersonalData,
		getCurrentInvoice,
		getPostJobChangesForm,
		getJobsState,
		getJobsData,
		getActiveJobsData,
		getInterviewTypes,
		getInterviewUsers,
		getInterviewJobsList,
		getInterviewVideoCount,
		getInterviewCalendarList,
		getCandidateInterviewJobsList,
		getInterviewData,
		getInterviewCandidateData,
		getUnappliedCandidateJobsData,
		getAtsTicketsState,
		getAtsTicketDetailsState,
		getSubscriptionState,
		getCurrentSubscriptionState,
		getSubscriptionTypesState,
		getSubscriptionPlans,
		getCandidateNotes,
		getJobRestrictedUsers,
		getAtsCandidatesDatabaseState,
		getAtsCandidateDatabaseDetailsState,
		getSMSCandidatesInformation,
		getCandidateStatistics,
		getApplicationStatistics,
		getCandidateApplicationPerStateStatistics,
		getJobMonthStatisticsState,
		getAcceptedOffersStatistics,
		getEmailsState,
		getDashboardUnreadEmails,
		getApplicationSourcesStatistics,
		getTimeToHireMonthState,
		getTimeToHireYearState,
		getApplicatonsYearState,
		getApplicationsMonthState,
		getAllJobsListData,
		getAllCandidateApplicationByJobData,
		getEmailTemplates,
		getEmailInbox,
		getCurrentEmailThread,
		getAllGravityJobsListData,
		getCurrentGravityCandidatesData,
	};
};
