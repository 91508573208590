import BrandIcon from 'components/SVG/BrandIcon';
import EmailIcon from 'components/SVG/EmailIcon';
import InfoIcon from 'components/SVG/InfoIcon';
import KeyIcon from 'components/SVG/KeyIcon';
import MessageIcon from 'components/SVG/MessageIcon';
import MoneyIcon from 'components/SVG/MoneyIcon';
import NotificationIcon from 'components/SVG/NotificationIcon';
import PasswordIcon from 'components/SVG/PasswordIcon';
import ProfileIcon from 'components/SVG/ProfileIcon';
import SocialNetIcon from 'components/SVG/SocialNetIcon';
import { COLORS } from 'theme';
import { SettingsRoutes } from 'types';

import { SettingsPanelMenuList } from '../../containers/ATSSettings/ATSSettings.types';

export const SettingPanelList = [
	{
		id: 1,
		name: SettingsPanelMenuList.CompanyDetails,
		path: SettingsRoutes.CompanyDetails,
		icon: <InfoIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 2,
		name: SettingsPanelMenuList.Subscription,
		path: SettingsRoutes.Subscription,
		icon: <MoneyIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 3,
		name: SettingsPanelMenuList.Branding,
		path: SettingsRoutes.Branding,
		icon: <BrandIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 4,
		name: SettingsPanelMenuList.SocialNetworks,
		path: SettingsRoutes.SocialNetworks,
		icon: <SocialNetIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 5,
		name: SettingsPanelMenuList.EmailTemplates,
		path: SettingsRoutes.EmailTemplates,
		icon: <EmailIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 51,
		name: SettingsPanelMenuList.EmailNotifications,
		path: SettingsRoutes.EmailNotifications,
		icon: <NotificationIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 6,
		name: SettingsPanelMenuList.SMSReminders,
		path: SettingsRoutes.SMSReminders,
		icon: <NotificationIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 7,
		name: SettingsPanelMenuList.EmailSignature,
		path: SettingsRoutes.EmailSignature,
		icon: <EmailIcon fill={COLORS.lightBlue1} />,
		messages: 2,
	},
	{
		id: 8,
		name: SettingsPanelMenuList.DefaultMessages,
		path: SettingsRoutes.DefaultMessages,
		icon: <MessageIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 9,
		name: SettingsPanelMenuList.Authentication,
		path: SettingsRoutes.Authentication,
		icon: <KeyIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 10,
		name: SettingsPanelMenuList.AutoPop,
		path: SettingsRoutes.AutoPop,
		icon: <ProfileIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 11,
		name: SettingsPanelMenuList.ChangePassword,
		path: SettingsRoutes.ChangePassword,
		icon: <PasswordIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 12,
		name: SettingsPanelMenuList.UserAccess,
		path: SettingsRoutes.UserAccess,
		icon: <ProfileIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 13,
		name: SettingsPanelMenuList.PersonalData,
		path: SettingsRoutes.PersonalData,
		icon: <ProfileIcon fill={COLORS.lightBlue1} />,
	},
];

export const SettingPaneShortList = [
	{
		id: 1,
		name: SettingsPanelMenuList.CompanyDetails,
		path: SettingsRoutes.CompanyDetails,
		icon: <InfoIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 2,
		name: SettingsPanelMenuList.EmailNotifications,
		path: SettingsRoutes.EmailNotifications,
		icon: <NotificationIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 3,
		name: SettingsPanelMenuList.SMSReminders,
		path: SettingsRoutes.SMSReminders,
		icon: <NotificationIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 4,
		name: SettingsPanelMenuList.EmailSignature,
		path: SettingsRoutes.EmailSignature,
		icon: <EmailIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 5,
		name: SettingsPanelMenuList.AutoPop,
		path: SettingsRoutes.AutoPop,
		icon: <ProfileIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 6,
		name: SettingsPanelMenuList.ChangePassword,
		path: SettingsRoutes.ChangePassword,
		icon: <PasswordIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 13,
		name: SettingsPanelMenuList.PersonalData,
		path: SettingsRoutes.PersonalData,
		icon: <ProfileIcon fill={COLORS.lightBlue1} />,
	},
];

export const SettingPanelFinanceList = [
	{
		id: 1,
		name: SettingsPanelMenuList.CompanyDetails,
		path: SettingsRoutes.CompanyDetails,
		icon: <InfoIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 2,
		name: SettingsPanelMenuList.EmailNotifications,
		path: SettingsRoutes.EmailNotifications,
		icon: <NotificationIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 6,
		name: SettingsPanelMenuList.ChangePassword,
		path: SettingsRoutes.ChangePassword,
		icon: <PasswordIcon fill={COLORS.lightBlue1} />,
	},
];
