import { Checkbox as CheckboxComponent, Input as InputComponent } from 'antd';
import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';
import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		margin-top: 32px;
		text-align: center;

		p {
			margin-bottom: 16px;
			font-size: 14px;
			color: ${COLORS.darkGray2};
		}
	`;

	export const CheckboxField = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	`;

	export const CheckboxOptionWrapper = styled.div`
		display: flex;
		align-items: center;
		width: 100%;
		min-height: 48px;
		max-width: 545px;
		margin-bottom: 16px;
		padding: 0 16px;
		line-height: 48px;
		background-color: ${COLORS.lightGray3};
		border-radius: 10px;
	`;

	export const CheckboxOption = styled(CheckboxComponent)`
		display: flex;
		width: 100%;
		font-size: 16px;
	`;

	export const AddFieldWrapper = styled.div`
		display: inline-block;
		width: 100%;
		max-width: 545px;
	`;

	export const Button = styled(ButtonComponent)`
		display: block;
		width: 100%;
		height: 48px;
		padding: 0 16px;
		line-height: 48px;
		text-align: start;
		color: ${COLORS.blue};
		border: 1px solid ${COLORS.blue};
		border-radius: 10px;

		&:disabled {
			border: 1px solid ${COLORS.lightGray1};
		}
	`;

	export const FieldInput = styled(InputComponent)`
		width: 100%;
		height: 48px;
		padding: 0 16px;
		line-height: 48px;
		border-radius: 10px;
	`;
}
