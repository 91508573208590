import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		margin-bottom: 24px;
		background-color: ${COLORS.white};
		padding: 24px;
		border-radius: 6px;
		position: relative;
	`;

	export const Content = styled.div`
		display: flex;
		align-items: center;
	`;

	export const Chart = styled.div`
		position: relative;
		margin-right: 12px;
	`;

	export const ChartContainer = styled.div`
		width: 80px;
		height: 80px;
	`;

	export const ChartPercentValue = styled.div`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;
	`;

	export const Legend = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		font-weight: bold;
	`;
}
