import { type FC, useEffect, useState } from 'react';
import { Field } from 'react-final-form';

import { type RadioChangeEvent, Radio, Space } from 'antd';

import FieldWrapper from 'components/FieldWrapper';
import { requiredFieldValidator } from 'utils/validators';

import {
	ApplicationQuestion,
	AssessmentType,
} from '../../AssessmentContainer/AssessmentContainer.types';

type AssessmentFormCheckboxProps = {
	assessment: AssessmentType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cb: any;
};

const AssessmentFormCheckbox: FC<AssessmentFormCheckboxProps> = ({ assessment, cb }) => {
	const { assessmentAnswers, autoreject } = assessment;
	const [value, setValue] = useState<number>();
	const [preparedValue, setPreparedValue] = useState({
		question: assessment.id,
		applicationAnswers: assessmentAnswers.map((aa) => ({
			answer: aa.id,
			text: aa.answer,
			checked: false,
		})),
	});

	const onChange = (e: RadioChangeEvent) => {
		setValue(e.target.value);
	};

	useEffect(() => {
		const newApplicationAnswers = preparedValue.applicationAnswers.map((aa) => ({
			answer: aa.answer,
			text: aa.text,
			checked: aa.answer === value,
		}));

		setPreparedValue({
			question: assessment.id,
			applicationAnswers: newApplicationAnswers,
		});
	}, [value]);

	useEffect(() => {
		cb((init: ApplicationQuestion[]) => {
			return [preparedValue, ...init.filter((aa) => aa.question !== preparedValue.question)];
		});
	}, [preparedValue]);

	return (
		<div>
			<Field type='radio' name={`assessment-${assessment.id}`} validate={requiredFieldValidator}>
				{({ input, meta }) => (
					<FieldWrapper
						name={`assessment-${assessment.id}`}
						errorMessage={meta.submitFailed && meta.touched && meta.error}
						isFixed
					>
						<Radio.Group
							{...input}
							onChange={(e) => {
								onChange(e);

								return input.onChange(e);
							}}
							value={value}
						>
							<Space direction='vertical'>
								{assessmentAnswers.map((aa) => (
									<Radio key={aa.id} value={aa.id}>
										{aa.answer}
									</Radio>
								))}
							</Space>
						</Radio.Group>
					</FieldWrapper>
				)}
			</Field>
		</div>
	);
};

export default AssessmentFormCheckbox;
