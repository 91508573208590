import React, { type FC, useState, useEffect, useRef } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import _ from 'lodash';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { commonDucks } from 'modules/Common/ducks';
import { TypeOfQuestionEnum } from 'modules/Common/types';
import { GenericType, Routes } from 'types';

import AssessmentFormCheckbox from '../AssessmentForm/AssessmentFormCheckbox';
import AssessmentFormMultiselect from '../AssessmentForm/AssessmentFormMultiselect';
import AssessmentFormTextField from '../AssessmentForm/AssessmentFormTextField';

import { Styled } from './AssessmentContainer.styled';
import { AssessmentType } from './AssessmentContainer.types';

type AssessmentContainerProps = {
	jobApplyState: {
		assessment: AssessmentType[];
	};
	commonLoading: GenericType;
	applyOnJobRequested: (values: object, callback: () => void, assessment: object) => void;
	setJobApplyState: (values: object) => void;
};

const AssessmentContainer: FC<AssessmentContainerProps> = ({
	jobApplyState,
	commonLoading,
	applyOnJobRequested,
}) => {
	const { jobId } = useParams();
	const [preparedValues, setPreparedValues] = useState<object[]>([]);
	const navigate = useNavigate();
	const { assessment, ...firstStepValues } = jobApplyState;
	const formWasSubmittedRef = useRef(false);

	useEffect(() => {
		if ((!assessment || _.isEmpty(assessment)) && !formWasSubmittedRef.current) {
			navigate(`${Routes.CareerJobPage}/${jobId}?isAssessmentComplete=false`);
		}
	}, [assessment, jobId, navigate]);

	const onSubmit = () =>
		applyOnJobRequested(
			firstStepValues,
			() => {
				formWasSubmittedRef.current = true;
				navigate(`${Routes.CareerJobPage}/${jobId}?isAssessmentComplete=true`);
			},
			preparedValues,
		);

	if (!assessment) {
		return null;
	}

	return (
		<Styled.Root>
			<Styled.Inner>
				<PageNavTitle
					navigationLink={`${Routes.CareerJobPage}/${jobId}`}
					title={'Back to Job Description'}
					styles={{ fontFamily: 'Raleway' }}
				/>
				<Styled.Title>
					We would like to know more about you and your experience, so please answer a few
					questions. This will take several minutes.
				</Styled.Title>
				<Styled.FormTitle>Key Questions for the Applicant</Styled.FormTitle>
				<Styled.FormWrappen>
					<Form
						onSubmit={onSubmit}
						render={({ handleSubmit, dirty, values, pristine, form, submitting }) => (
							<form onSubmit={handleSubmit}>
								{jobApplyState.assessment.map((assessmentItem) => {
									return (
										<Styled.FieldBlockWrapper key={assessmentItem.id}>
											<Styled.FieldBlockInner>
												<Styled.FieldBlockTitle>
													{assessmentItem.questionText}
													{assessmentItem?.autoreject && <span>*</span>}
												</Styled.FieldBlockTitle>
												{assessmentItem.questionType === TypeOfQuestionEnum.multiChoice && (
													<Styled.FieldBlockHint>
														Please select all correct answers from the listed options. Hint: there
														may be more than one correct answer!
													</Styled.FieldBlockHint>
												)}
												<Styled.FieldBlockMain>
													{assessmentItem.questionType === TypeOfQuestionEnum.multiChoice && (
														<AssessmentFormMultiselect
															assessment={assessmentItem}
															cb={setPreparedValues}
														/>
													)}
													{assessmentItem.questionType === TypeOfQuestionEnum.textField && (
														<AssessmentFormTextField
															assessment={assessmentItem}
															cb={setPreparedValues}
														/>
													)}
													{assessmentItem.questionType === TypeOfQuestionEnum.yesNoQn && (
														<AssessmentFormCheckbox
															assessment={assessmentItem}
															cb={setPreparedValues}
														/>
													)}
												</Styled.FieldBlockMain>
											</Styled.FieldBlockInner>
										</Styled.FieldBlockWrapper>
									);
								})}
								<Styled.FormSubmitWrappen>
									<Button
										type='submit'
										buttonType={ButtonTypes.primary}
										disabled={!!commonLoading?.applyOnJobLoad}
										style={{ fontFamily: 'Inter' }}
									>
										Submit Application
									</Button>
								</Styled.FormSubmitWrappen>
							</form>
						)}
					></Form>
				</Styled.FormWrappen>
			</Styled.Inner>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		jobApplyState: commonDucks.commonSelectors.getJobApply(state),
		commonLoading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		applyOnJobRequested: commonDucks.commonActions.applyOnJobRequested,
		setJobApplyState: commonDucks.commonActions.setJobApplyState,
	},
)(AssessmentContainer);
