import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export const Root = styled.div`
    padding: 24px 32px;
    background-color: #fff;
`;

export const PaymentFormTitle = styled.div`
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    padding-bottom: 24px;
`;

export const PaymentFormDesc = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-bottom: 24px;
`;

export const FormWrapper = styled.div`
    border-radius: 10px;
    background: rgba(153, 167, 255, 0.15);
    max-width: 580px;
    padding: 24px;
    margin-bottom: 28px;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FooterMain = styled.div`
    display: flex;
    height: 40px;
    align-items: center;
`;

export const FooterInfo = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    margin-right: 16px;
`;

export const FooterInfoDesc = styled.div`
    color: ${COLORS.darkGray1};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

export const ButtonWrapper = styled.div`
	display: flex;
    justify-content: flex-end;
`;
