import styled from 'styled-components/macro';

import { COLORS } from 'theme';

import { FOOTER_HEIGHT } from '../../constants';

interface FooterStyledProps {
	bgColor: string;
}

export namespace Styled {
	export const Footer = styled.footer<FooterStyledProps>`
		display: flex;
		justify-content: center;
		align-items: center;
		height: ${FOOTER_HEIGHT}px;
		text-align: center;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		background: ${({ bgColor }) => bgColor};
		color: ${COLORS.darkGray2};

		span {
			display: flex;
		}

		a {
			display: flex;
			color: ${COLORS.darkGray2};
			transition: all 0.2ms ease;

			&:hover {
				text-decoration: underline;
			}
		}
	`;
}
