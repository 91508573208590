import React from 'react';

import ATSMessagesList from 'modules/ATS/containers/ATSMessagesList';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { AtsNavigationMenuList } from 'modules/Common/types';

import { Styled } from './ATSMessagesListPage.styled';

const ATSMessagesListPage = () => {
	return (
		<>
			<Styled.Head>
				<PageNavTitle title={AtsNavigationMenuList.Messages} />
			</Styled.Head>
			<Styled.Content>
				<ATSMessagesList />
			</Styled.Content>
		</>
	);
};

export default ATSMessagesListPage;
