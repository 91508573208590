import { memo } from 'react';

function JobApplyBgIcon() {
	return (

		<svg  viewBox="0 0 1414 553" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M1250.86 6.14767C1251.22 4.814 1250.43 3.44316 1249.09 3.08581C1247.76 2.72845 1246.39 3.51991 1246.03 4.85357L1240.98 23.6891L1222.15 18.6421C1220.81 18.2848 1219.44 19.0762 1219.09 20.4099C1218.73 21.7435 1219.52 23.1144 1220.85 23.4717L1239.69 28.5187L1234.64 47.3543C1234.28 48.688 1235.08 50.0588 1236.41 50.4162C1237.74 50.7735 1239.11 49.9821 1239.47 48.6484L1244.52 29.8128L1263.35 34.8598C1264.69 35.2171 1266.06 34.4257 1266.42 33.092C1266.77 31.7583 1265.98 30.3875 1264.65 30.0301L1245.81 24.9832L1250.86 6.14767Z" fill="#EBEDFF"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M283.554 243.916C284.749 244.606 286.278 244.197 286.969 243.001C287.659 241.805 287.249 240.276 286.054 239.586L269.166 229.836L278.916 212.948C279.606 211.753 279.197 210.224 278.001 209.533C276.805 208.843 275.276 209.253 274.586 210.448L264.836 227.336L247.948 217.586C246.753 216.896 245.224 217.305 244.533 218.501C243.843 219.697 244.253 221.226 245.448 221.916L262.336 231.666L252.586 248.554C251.896 249.749 252.305 251.278 253.501 251.969C254.697 252.659 256.226 252.249 256.916 251.054L266.666 234.166L283.554 243.916Z" fill="#EBEDFF"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M288.5 506C289.881 506 291 507.194 291 508.667V519.333C291 520.806 289.881 522 288.5 522C287.119 522 286 520.806 286 519.333V508.667C286 507.194 287.119 506 288.5 506ZM288.5 537C289.881 537 291 538.194 291 539.667V550.333C291 551.806 289.881 553 288.5 553C287.119 553 286 551.806 286 550.333V539.667C286 538.194 287.119 537 288.5 537ZM309.333 532C310.806 532 312 530.881 312 529.5C312 528.119 310.806 527 309.333 527H298.667C297.194 527 296 528.119 296 529.5C296 530.881 297.194 532 298.667 532H309.333ZM281 529.497C281 530.878 279.806 531.997 278.333 531.997H267.667C266.194 531.997 265 530.878 265 529.497C265 528.116 266.194 526.997 267.667 526.997H278.333C279.806 526.997 281 528.116 281 529.497ZM288.5 532C289.881 532 291 530.881 291 529.5C291 528.119 289.881 527 288.5 527C287.119 527 286 528.119 286 529.5C286 530.881 287.119 532 288.5 532Z" fill="#EBEDFF"/>
			<circle cx="60.5" cy="394.5" r="26" stroke="#EBEDFF" strokeWidth="5"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M1190.85 276.75C1191.54 277.946 1191.07 279.512 1189.79 280.248L1180.55 285.582C1179.28 286.318 1177.69 285.946 1177 284.75C1176.3 283.554 1176.78 281.988 1178.05 281.252L1187.29 275.918C1188.57 275.182 1190.16 275.554 1190.85 276.75ZM1164 292.25C1164.7 293.446 1164.22 295.012 1162.95 295.748L1153.71 301.082C1152.43 301.818 1150.84 301.446 1150.15 300.25C1149.46 299.054 1149.93 297.488 1151.21 296.752L1160.45 291.418C1161.72 290.682 1163.31 291.054 1164 292.25ZM1178.75 307.792C1179.49 309.068 1181.05 309.542 1182.25 308.852C1183.45 308.161 1183.82 306.568 1183.08 305.292L1177.75 296.055C1177.01 294.779 1175.45 294.305 1174.25 294.995C1173.05 295.686 1172.68 297.279 1173.42 298.555L1178.75 307.792ZM1166.75 282.003C1165.56 282.694 1163.99 282.219 1163.25 280.944L1157.92 271.706C1157.18 270.431 1157.56 268.837 1158.75 268.147C1159.95 267.457 1161.51 267.931 1162.25 269.206L1167.58 278.444C1168.32 279.719 1167.95 281.313 1166.75 282.003ZM1168.33 289.75C1169.03 290.946 1170.55 291.355 1171.75 290.665C1172.95 289.975 1173.36 288.446 1172.67 287.25C1171.97 286.054 1170.45 285.645 1169.25 286.335C1168.05 287.025 1167.64 288.554 1168.33 289.75Z" fill="#EBEDFF"/>
			<circle cx="1304.2" cy="422.196" r="12.1116" fill="#99A7FF" fillOpacity="0.2"/>
			<circle cx="1304.2" cy="470.643" r="12.1116" fill="#99A7FF" fillOpacity="0.2"/>
			<circle cx="1304.2" cy="519.089" r="12.1116" fill="#99A7FF" fillOpacity="0.2"/>
			<circle cx="1352.64" cy="422.197" r="12.1116" fill="#99A7FF" fillOpacity="0.2"/>
			<circle cx="1352.64" cy="470.642" r="12.1116" fill="#99A7FF" fillOpacity="0.2"/>
			<circle cx="1352.64" cy="519.089" r="12.1116" fill="#99A7FF" fillOpacity="0.2"/>
			<circle cx="1401.09" cy="422.196" r="12.1116" fill="#99A7FF" fillOpacity="0.2"/>
			<circle cx="1401.09" cy="470.643" r="12.1116" fill="#99A7FF" fillOpacity="0.2"/>
			<circle cx="1401.09" cy="519.089" r="12.1116" fill="#99A7FF" fillOpacity="0.2"/>
			<circle cx="9.79796" cy="26.3624" r="8" transform="rotate(-15 9.79796 26.3624)" fill="#99A7FF" fillOpacity="0.2"/>
			<circle cx="18.0802" cy="57.272" r="8" transform="rotate(-15 18.0802 57.272)" fill="#99A7FF" fillOpacity="0.2"/>
			<circle cx="26.3624" cy="88.1817" r="8" transform="rotate(-15 26.3624 88.1817)" fill="#99A7FF" fillOpacity="0.2"/>
			<circle cx="40.7076" cy="18.0802" r="8" transform="rotate(-15 40.7076 18.0802)" fill="#99A7FF" fillOpacity="0.2"/>
			<circle cx="48.9899" cy="48.9899" r="8" transform="rotate(-15 48.9899 48.9899)" fill="#99A7FF" fillOpacity="0.2"/>
			<circle cx="57.2721" cy="79.8995" r="8" transform="rotate(-15 57.2721 79.8995)" fill="#99A7FF" fillOpacity="0.2"/>
			<circle cx="71.6173" cy="9.79803" r="8" transform="rotate(-15 71.6173 9.79803)" fill="#99A7FF" fillOpacity="0.2"/>
			<circle cx="79.8995" cy="40.7076" r="8" transform="rotate(-15 79.8995 40.7076)" fill="#99A7FF" fillOpacity="0.2"/>
			<circle cx="88.1817" cy="71.6172" r="8" transform="rotate(-15 88.1817 71.6172)" fill="#99A7FF" fillOpacity="0.2"/>
		</svg>
	);
}

export default memo(JobApplyBgIcon);