import { Button } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		position: relative;

		.rbc-time-view,
		.rbc-time-header-content,
		.rbc-time-header,
		.rbc-time-header-cell,
		.rbc-header,
		.rbc-day-bg + .rbc-day-bg {
			border: none;
		}

		.rbc-time-view {
			border: ${COLORS.lightGray4};

			.rbc-row {
				min-height: 0;
			}
		}

		.rbc-header span {
			font-size: 12px;
			font-weight: 600;
		}

		.rbc-time-content {
			border: 0.25px solid ${COLORS.lightGray4};

			& > * + * > * {
				border-left: 0.25px solid ${COLORS.lightGray4};
			}
		}

		.rbc-allday-cell {
			.rbc-row {
				min-height: 0;
			}
		}

		.rbc-time-header {
			.rbc-header {
				display: flex;
				align-items: center;
				justify-content: center;
				min-height: 24px;
			}
		}

		.rbc-time-header-content {
			.rbc-today {
				color: ${COLORS.blue2};
				background-color: transparent;
			}
		}

		.rbc-btn-group {
			button {
				transition: color 0.2s ease;
				cursor: pointer;

				&:hover {
					color: ${COLORS.blue};
				}
			}
		}

		.rbc-events-container {
			margin: 0 10px;
			border: none;

			.rbc-event-label {
				display: none;
			}
		}

		.rbc-ellipsis,
		.rbc-show-more,
		.rbc-row-segment .rbc-event-content,
		.rbc-event-label {
			white-space: initial;
		}

		.rbc-timeslot-group {
			min-height: 48px;
			border-bottom: 0.25px solid ${COLORS.lightGray4};

			.rbc-time-slot {
				display: flex;
				justify-content: center;
				align-items: end;
			}

			.rbc-label {
				font-size: 12px;
			}
		}

		.rbc-day-slot {
			.rbc-time-slot {
				border: none;
			}

			.rbc-event-content {
				line-height: normal;
			}
		}

		.rbc-current-time-indicator {
			height: 2px;
			background-color: ${COLORS.blue};
		}

		.rbc-event {
			font-size: 12px;
			padding: 4px 6px;
			border-radius: 2px;
		}

		.rbc-time-column.rbc-now.rbc-today {
			width: 100%;
			background-color: ${COLORS.lightBlue7};
		}
	`;

	export const EventComponent = styled.div``;

	export const LinkPanel = styled.div``;

	export const InputWrapper = styled.div`
		display: flex;
		align-items: center;
		width: 100%;
		gap: 24px;

		label {
			flex: 0 0 auto;
			display: flex;
			align-items: center;
			font-size: 16px;
			line-height: 20px;
			gap: 4px;
			

			span {
				display: block;
				font-weight: 600;
			}
		}

		input {
			font-size: 12px;
			line-height: 24px;
		}
	`;

	export const ButtonInfo = styled(Button)`
		display: flex;
		justify-content: center;
		align-content: center;
		width: 16px;
		height: 16px;
		padding: 0;
		line-height: 16px;
		font-size: 12px;
		border-radius: 100%;
	`;

	export const EventWrapper = styled.div`
		position: relative;
		height: 100%;

		.rbc-month-view & {
			max-height: 14px;
		}

		span {
			display: flex;
			align-items: center;
			height: 100%;
		}
	`;

	export const PopoverHead = styled.div`
		display: flex;
		justify-content: space-between;
		max-width: 326px;
		margin: 0 -12px;
		padding: 4px 16px 8px 24px;
		border-bottom: 1px solid ${COLORS.lightGray2};
		gap: 16px;
		font-family: 'Inter';

		h4 {
			word-break: break-all;
		}

		a {
			font-size: 14px;
			line-height: 20px;
		}
	`;

	export const PopoverContent = styled.div`
		min-width: 302px;
		padding: 0 4px 4px 12px;
		font-family: 'Inter';
	`;

	export const PopoverDate = styled.span`
		display: block;
		margin-bottom: 16px;
		font-size: 14px;
		color: ${COLORS.darkGray2};
	`;

	export const PopoverList = styled.ul`
		margin-bottom: 16px;

		li {
			display: flex;
			align-items: center;
			margin-bottom: 8px;
			gap: 4px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	`;

	export const ButtonBox = styled.div`
		display: flex;
		flex-direction: column;
		gap: 8px;
		font-family: 'Inter';

		a {
			display: block;
		}

		button {
			width: 100%;
			height: 40px;
			font-size: 16px;
			font-weight: 500;

			&.ant-btn-primary {
				background-color: ${COLORS.blue};
			}
			&.ant-btn-default {
				color: ${COLORS.blue};
				border-color: ${COLORS.blue};
			}
		}
	`;
}
