import { Select as SelectComponent } from 'antd';
import DraggerAntd from 'antd/es/upload/Dragger';
import styled from 'styled-components/macro';

import Box from 'components/Box';
import { StatusColorsType } from 'modules/Common/types';
import { COLORS } from 'theme';

type SelectItemProps = {
	background?: string;
	color?: string;
};

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;
	`;

	export const BoxWrap = styled(({ isBoxComponent, ...props }) => {
		const Component = isBoxComponent ? Box : 'div';

		return <Component {...props} />;
	})``;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
		margin-bottom: 32px;
		gap: 16px;
	`;

	export const Header = styled.div`
		padding-bottom: 24px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
	`;
	export const FormHeader = styled.div`
		padding-bottom: 24px;
		margin-right: 20px;
	`;
	export const FormHeaderAdditional = styled.div`
		padding-top: 8px;
		display: flex;
	`;
	export const Title = styled.div`
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
		margin-bottom: 8px;
	`;
	export const SubTitle = styled.div`
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	`;
	export const StatusWrapper = styled.div`
		margin-left: 32px;
	`;
	export const Select = styled(SelectComponent)<SelectItemProps>`
		&.ant-select {
			min-width: 140px;
			text-align: center;
			.ant-select-selector {
				background: ${(props) => props.background || StatusColorsType.LightBlue};
				color: ${(props) => props.color || StatusColorsType.DarkBlue};
				border: 1px solid ${(props) => props.color || StatusColorsType.DarkBlue};
				font-weight: bold;
			}
		}
	`;

	export const Dragger = styled(DraggerAntd)`
		&.ant-upload-wrapper {
			.ant-upload-list {
				.ant-upload-list-item {
					border: 1px solid ${COLORS.gray};
					padding: 10px;
					height: auto;
					transition: all 0.2s ease;
					&:hover {
						background-color: transparent;
						box-shadow: 0px 3px 5px 0px ${COLORS.gray};
						cursor: pointer;
					}
					.ant-upload-list-item-actions {
						display: flex;
					}
					.ant-upload-icon {
						svg {
							font-size: 24px;
						}
					}
					.ant-upload-list-item-actions {
						.ant-btn {
							margin: 0 5px;
						}
					}
					.ant-upload-list-item-action {
						opacity: 1;
						color: ${COLORS.black};

						svg {
							font-size: 24px;
						}
					}
					.ant-upload-list-item-name {
						color: ${COLORS.black};
						font-size: 16px;
					}
				}
			}
		}
	`;
}
