import React, { FC, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions } from 'chart.js';

import Spinner from 'components/Spinner';
import { STATISTICS_COLORS_ACCEPTED_REJECTED } from 'modules/Common/utils/StatisticsUtils';

import { Styled } from './AcceptedOffersStatistics.styled';

type AcceptedOffersStatisticsProps = {
	acceptedOffersStatistics: { acceptedOffersPercent: number } | null;
	yearMonth?: string;
	loading: boolean;
	getAcceptedOffersStatistics: (yearMonth: string) => void;
};

ChartJS.register(ArcElement, Tooltip, Legend);

const options: ChartOptions<'doughnut'> = {
	cutout: '80%',
	plugins: {
		legend: {
			display: false,
		},
		tooltip: {
			enabled: false,
		},
	},
	elements: {
		arc: {
			borderWidth: 0,
		},
	},
};

const createDataForChart = (acceptedOffersPercent: number) => {
	return {
		labels: ['Accepted Offers', 'Remaining'],
		datasets: [
			{
				label: 'Dataset 1',
				data: [acceptedOffersPercent, 100 - acceptedOffersPercent],
				backgroundColor: STATISTICS_COLORS_ACCEPTED_REJECTED,
				hoverBackgroundColor: STATISTICS_COLORS_ACCEPTED_REJECTED,
			},
		],
	};
};

const AcceptedOffersStatistics: FC<AcceptedOffersStatisticsProps> = ({
	acceptedOffersStatistics,
	yearMonth = '',
	loading,
	getAcceptedOffersStatistics,
}) => {
	const [chartData, setChartData] = useState(createDataForChart(0));
	useEffect(() => {
		getAcceptedOffersStatistics(yearMonth);
	}, [yearMonth]);

	useEffect(() => {
		if (acceptedOffersStatistics) {
			setChartData(createDataForChart(acceptedOffersStatistics.acceptedOffersPercent));
		}
	}, [acceptedOffersStatistics]);

	if (loading) {
		return (
			<Styled.Root>
				<Spinner />
			</Styled.Root>
		);
	}

	const percentToView = acceptedOffersStatistics?.acceptedOffersPercent
		? Math.round(acceptedOffersStatistics?.acceptedOffersPercent)
		: 0;

	return (
		<Styled.Root>
			<Styled.Content>
				<Styled.Chart>
					<Styled.ChartContainer>
						<Doughnut data={chartData} options={options} />
					</Styled.ChartContainer>
					<Styled.ChartPercentValue>{percentToView}%</Styled.ChartPercentValue>
				</Styled.Chart>
				<Styled.Legend>Candidates Accepted Offers</Styled.Legend>
			</Styled.Content>
		</Styled.Root>
	);
};

export default AcceptedOffersStatistics;
