import React, { type FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Button, Popconfirm } from 'antd';

import Box from 'components/Box';
import BlueTownLogo from 'components/SVG/BlueTownLogo';
import { useSubdomain } from 'hooks/useSubdomain';
import Image from 'modules/Common/components/Image';
import { DataFormatEnum, GenericType, IOption } from 'types';
import { getDate, getDateFormat } from 'utils/helpers';

import { IOptionArrange } from '../../../ATS/containers/ArrangeInterview/ArrangeInterview.types';
import { commonDucks } from '../../ducks';
import { convertContentToHtml } from '../../utils/editorHelper';

import { Styled } from './DeclineInterview.styled';

type DeclineInterviewPropsType = {
	pwdId: string;
	interviewId: string;
	getInterviewOpenRequested: (interviewId: number, p: { pwd: string }) => void;
	declineInterviewOpenRequested: (
		interviewId: number,
		p: { pwd: string },
		callback: () => void,
	) => void;
	interviewData: {
		id: number;
		client: string;
		information: string;
		startDate: Date;
		type: IOption;
		guest: IOptionArrange & {
			isInterviewAccepted: boolean;
		};
	};
	loading: GenericType;
	clientInfo: {
		subdomain: string;
		logo: Blob | MediaSource | string;
	};
};

const DeclineInterview: FC<DeclineInterviewPropsType> = ({
	pwdId,
	interviewId,
	getInterviewOpenRequested,
	declineInterviewOpenRequested,
	interviewData,
	loading,
}) => {
	const subdomain = useSubdomain(process.env?.REACT_APP_ENV === 'prod');
	const [logo, setLogo] = useState<ReactNode | null>(null);
	const [error, setError] = useState<string>('');
	const [showResponse, setShowResponse] = useState<boolean>(false);

	const { client, type, startDate, information, guest } = interviewData || {};
	const handleDeclineInterview = useCallback((id: number) => {
		declineInterviewOpenRequested(id, { pwd: pwdId }, () => setShowResponse(true));
	}, []);

	const handleError = useCallback((value: string) => setError(value), []);

	useEffect(() => {
		getInterviewOpenRequested(+interviewId, { pwd: pwdId });
	}, [interviewId]);

	useEffect(() => {
		if (subdomain) {
			const logoComponent = subdomain && <Image path={subdomain} setError={handleError} />;
			setLogo(!error ? logoComponent : <BlueTownLogo />);
		} else {
			setLogo(<BlueTownLogo />);
		}
	}, [subdomain, error]);

	if (!interviewData || loading?.getInterviewOpenLoad) {
		return;
	}

	const date = getDateFormat(startDate, DataFormatEnum.FullMonth);
	const { hours, minutes } = (startDate && getDate(startDate)) || {};

	const informationComponent = information && convertContentToHtml(information);

	return (
		<Styled.Root>
			<Styled.LogoWrapper>{logo}</Styled.LogoWrapper>

			<h2>Interview response</h2>
			<Box>
				<h3>{`Interview with ${client}`}</h3>

				{!showResponse && guest?.isInterviewAccepted ? (
					<>
						<Styled.ContentInfo>
							{date && (
								<>
									<span>
										<b>Date: </b>
										{date}
									</span>
									<span>
										<b>Time: </b>
										{`${hours}:${minutes}`}
									</span>
								</>
							)}
							<span>
								<b>Type: </b>
								{type?.name}
							</span>

							{information && (
								<>
									<h4>Interview details: </h4>
									<div>{informationComponent}</div>
								</>
							)}
						</Styled.ContentInfo>

						<Styled.ButtonWrapper>
							<Popconfirm
								placement='top'
								title='Are you sure?'
								onConfirm={() => interviewId && handleDeclineInterview(+interviewId)}
								okText='Yes'
								cancelText='Cancel'
							>
								<Button type='primary' size='middle'>
									Decline interview
								</Button>
							</Popconfirm>
						</Styled.ButtonWrapper>
					</>
				) : (
					<p>You&apos;ve declined the interview. Thank you for your response!</p>
				)}
			</Box>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		interviewData: commonDucks.commonSelectors.getInterviewDataOpen(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		getInterviewOpenRequested: commonDucks.commonActions.getInterviewOpenRequested,
		declineInterviewOpenRequested: commonDucks.commonActions.declineInterviewOpenRequested,
	},
)(DeclineInterview);
