import React, { FC } from 'react';

import CheckCircleIcon from 'components/SVG/CheckCircleIcon';
import CrossCircleIcon from 'components/SVG/CrossCircleIcon';
import { MediaPermissionType } from 'modules/Common/types';

import HeroHeader from '../HeroHeader';

import { Styled } from './QuetionnaireCheck.styled';

const baseUrl = `${process.env.REACT_APP_API_URL}/open/public/file/logo/`;

type QuestionnaireCheckProps = {
	handleStart: () => void;
	isAudioAvailable: MediaPermissionType;
	isVideoAvailable: MediaPermissionType;
	online: boolean;
	title: string;
	backgroundImage?: string;
	highlightColor?: string;
	buttonText: string;
	description: string;
};
const QuestionnaireCheck: FC<QuestionnaireCheckProps> = ({
	handleStart,
	isVideoAvailable,
	isAudioAvailable,
	online,
	title,
	backgroundImage,
	highlightColor,
	description,
	buttonText,
}) => {
	const backgroundImageUrl = backgroundImage?.split('/').includes('images')
		? backgroundImage
		: backgroundImage && `${baseUrl}${backgroundImage}`;

	return (
		<Styled.Root>
			<HeroHeader
				backgroundImageUrl={backgroundImageUrl || '/images/backgroundCareer.png'}
				height='230px'
			/>
			<Styled.StepWrapper>
				<Styled.StepTitle>{title}</Styled.StepTitle>
				<Styled.StepContent>
					<Styled.StepContentDesc>{description}</Styled.StepContentDesc>
					<Styled.CheckTitle>Check your set up:</Styled.CheckTitle>
					<Styled.CheckItem>
						<Styled.CheckItemIcon>
							{isVideoAvailable.value ? <CheckCircleIcon /> : <CrossCircleIcon />}
						</Styled.CheckItemIcon>
						<Styled.CheckItemMain>
							<Styled.CheckItemTitle>Build-in Camera</Styled.CheckItemTitle>
							<Styled.CheckItemStatus>
								{isVideoAvailable.value
									? 'Camera check passed!'
									: isVideoAvailable.error?.message === 'Requested device not found'
										? 'Oops! We encountered an issue with your camera. Please check your camera settings and ensure it is properly connected'
										: isVideoAvailable.error?.message === 'Waiting for permission...'
											? 'Waiting for permission...'
											: 'Camera access denied. Please grant permission to use the camera in your browser settings to proceed'}
							</Styled.CheckItemStatus>
						</Styled.CheckItemMain>
					</Styled.CheckItem>
					<Styled.CheckItem>
						<Styled.CheckItemIcon>
							{isAudioAvailable.value ? <CheckCircleIcon /> : <CrossCircleIcon />}
						</Styled.CheckItemIcon>
						<Styled.CheckItemMain>
							<Styled.CheckItemTitle>Build-in Microphone</Styled.CheckItemTitle>
							<Styled.CheckItemStatus>
								{isAudioAvailable.value
									? 'Mic check passed!'
									: isVideoAvailable.error?.message === 'Requested device not found'
										? 'Oops! We encountered an issue with your microphone. Please check your microphone settings and ensure it is properly connected'
										: isVideoAvailable.error?.message === 'Waiting for permission...'
											? 'Waiting for permission...'
											: 'Mic access denied. Please grant permission to use the microphone in your browser settings to proceed'}
							</Styled.CheckItemStatus>
						</Styled.CheckItemMain>
					</Styled.CheckItem>
					<Styled.CheckItem>
						<Styled.CheckItemIcon>
							{online ? <CheckCircleIcon /> : <CrossCircleIcon />}
						</Styled.CheckItemIcon>
						<Styled.CheckItemMain>
							<Styled.CheckItemTitle>Internet Connection</Styled.CheckItemTitle>
							<Styled.CheckItemStatus>
								{online
									? 'Internet check passed!'
									: 'Oops! We encountered an issue with your internet connection. Please check your network settings and ensure you have a stable internet connection'}
							</Styled.CheckItemStatus>
						</Styled.CheckItemMain>
					</Styled.CheckItem>
					<Styled.StartButtonWrapper>
						<Styled.Button
							disabled={!isAudioAvailable.value || !isVideoAvailable.value || !online}
							highlightColor={highlightColor}
							onClick={handleStart}
						>
							{buttonText}
						</Styled.Button>
					</Styled.StartButtonWrapper>
				</Styled.StepContent>
			</Styled.StepWrapper>
		</Styled.Root>
	);
};

export default QuestionnaireCheck;
