import React, { type FC, memo, useState } from 'react';
import { Field } from 'react-final-form';
import { useLocation } from 'react-router-dom';

import { InboxOutlined } from '@ant-design/icons';
import { Input, Select, UploadFile } from 'antd';

import FieldWrapper from 'components/FieldWrapper';
import DownloadIcon from 'components/SVG/DownloadIcon';
import FieldEditor from 'modules/Common/components/FieldEditor';
import FormBlock from 'modules/Common/components/FormBlock';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { acceptedTicketUploadFormFileFormat } from 'modules/Common/constants';
import { FileUploadTypesEnum } from 'modules/Common/types';
import { getMultipleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import { COLORS } from 'theme';
import { IOption, Routes } from 'types';
import { filterSelectOptions, handleFileDownload } from 'utils/helpers';
import { editorFieldValidator, textMaxLengthValidator } from 'utils/validators';

import { Styled } from '../../PostTicket/PostTicket.styled';

type RoleInfoProps = {
	employmentTypes: IOption[];
	educationTypes: IOption[];
	jobIndustries: IOption[];
	isPaidJob: boolean;
	handleUploadFiles: (value: string[]) => void;
	readOnly?: boolean;
};

const { Option } = Select;

const RoleInfo: FC<RoleInfoProps> = ({
	employmentTypes,
	educationTypes,
	jobIndustries,
	isPaidJob = false,
	handleUploadFiles,
	readOnly,
}) => {
	const { pathname } = useLocation();
	const isAtsRoute = pathname.includes(Routes.ATS);
	const [fileList, setFileList] = useState<UploadFile[]>([]);

	const draggerProps = ({
		onChange,
		value = [],
	}: {
		onChange: (fileIds: string[]) => void;
		value: string[];
	}) =>
		getMultipleFileUploaderProps(
			(url, responseData) => {
				const data = [...value, responseData];
				onChange(data);
				data?.length && handleUploadFiles(data);
			},
			{
				fileList,
				accept: acceptedTicketUploadFormFileFormat,
				onRemove: (file: UploadFile) => {
					onChange([...value.filter((item) => item !== file?.response?.data)]);
				},
				onDownload: handleDownload,
				fileType: FileUploadTypesEnum.Ticket,
			},
			setFileList,
		);

	const handleDownload = (file: UploadFile) => {
		const { name } = file;
		const uid = file?.response?.data;
		handleFileDownload(uid, name);
	};

	const filterOption = (input: string, option?: { props: { children: string } }) =>
		option?.props?.children.toLowerCase().includes(input.toLowerCase());

	return (
		<FormBlock title='Role Info'>
			<FormBlockLine>
				<Field name='experienceLevel' validate={isAtsRoute ? undefined : textMaxLengthValidator}>
					{({ input, meta }) => (
						<FieldWrapper
							name='experienceLevel'
							label='Experience Level'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
						>
							<Input
								{...input}
								placeholder='e.g., Entry-level position'
								autoComplete='off'
								disabled={readOnly}
							/>
						</FieldWrapper>
					)}
				</Field>
				<Field name='educationType'>
					{({ input }) => (
						<FieldWrapper name='educationType' label='Education'>
							<Select
								{...input}
								value={input.value || null}
								placeholder='Select education level'
								disabled={readOnly}
								allowClear
							>
								{educationTypes?.map((option: IOption) => (
									<Option key={`education-${option.id}`} value={option.id}>
										{option.name}
									</Option>
								))}
							</Select>
						</FieldWrapper>
					)}
				</Field>
			</FormBlockLine>

			<FormBlockLine>
				<Field name='employmentType'>
					{({ input }) => (
						<FieldWrapper name='employmentType' label='Employment Type'>
							<Select
								{...input}
								value={input.value || null}
								placeholder='Select employment type'
								disabled={readOnly}
								allowClear
							>
								{employmentTypes?.map((option: IOption) => (
									<Option key={`employment-${option.id}`} value={option.id}>
										{option.name}
									</Option>
								))}
							</Select>
						</FieldWrapper>
					)}
				</Field>
				<Field name='jobIndustry'>
					{({ input }) => (
						<FieldWrapper name='jobIndustry' label='Industry'>
							<Select
								{...input}
								value={input.value || null}
								options={jobIndustries}
								filterOption={(inputValue, option) =>
									filterSelectOptions(inputValue, option, 'name')
								}
								fieldNames={{ label: 'name', value: 'id' }}
								placeholder='Select industry'
								disabled={readOnly}
								allowClear
								showSearch
							/>
						</FieldWrapper>
					)}
				</Field>
			</FormBlockLine>
			<FormBlockLine columns={1}>
				<Field name='jobDescription' validate={editorFieldValidator}>
					{({ input, meta }) => {
						return (
							<FieldWrapper
								name='jobDescription'
								label='Job Description'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
								required
							>
								<>
									<FieldEditor
										onChange={(value) => input.onChange(value)}
										defaultValue={input.value}
										placeholder='Please provide a brief overview of the position'
										disabled={readOnly}
										minRaws={20}
									/>
								</>
							</FieldWrapper>
						);
					}}
				</Field>
			</FormBlockLine>
			{isPaidJob && (
				<FormBlockLine columns={1}>
					<Field name='attachments'>
						{({ input, meta }) => (
							<FieldWrapper
								isFixed
								name='attachments'
								label='Upload'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Styled.Dragger
									{...draggerProps(input)}
									listType='text'
									onPreview={handleDownload}
									showUploadList={{
										showDownloadIcon: true,
										downloadIcon: <DownloadIcon fill={COLORS.darkGray2} />,
									}}
								>
									<p className='ant-upload-drag-icon'>
										<InboxOutlined />
									</p>
									<p className='ant-upload-text'>Upload a files or drag and drop it</p>
									<p className='ant-upload-hint'> PDF, DOC, DOCX</p>
								</Styled.Dragger>
							</FieldWrapper>
						)}
					</Field>
				</FormBlockLine>
			)}
		</FormBlock>
	);
};

export default memo(RoleInfo);
