import React, { type FC } from 'react';

import Table from 'components/Table';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from 'modules/Common/constants/table';
import { IQuestionnaires } from 'modules/Common/types';
import { propsFilter } from 'utils/helpers';

import { TableFields } from './CopyQuestionnaireListTable.constants';
import { columns } from './CopyQuestionnaireListTable.entities';

type JobsProps = {
	listData: {
		data: IQuestionnaires[];
		pageIndex: number;
		pageSize: number;
		totalCount: number;
	};
	handleCopyJob: (id: number) => void;
	handleTableChange: (page: number, size: number) => void;
	loading: boolean;
};

const CopyQuestionnaireListTable: FC<JobsProps> = ({
	listData,
	handleCopyJob,
	handleTableChange,
	loading,
}) => {
	const {
		pageIndex = DEFAULT_CURRENT_PAGE,
		pageSize = DEFAULT_PAGE_SIZE,
		totalCount,
		data,
	} = listData;
	const activeData = propsFilter(data, TableFields);

	return (
		<Table
			data={activeData}
			columns={columns(handleCopyJob)}
			loading={loading}
			pageSize={pageSize}
			current={pageIndex}
			total={totalCount}
			onChange={handleTableChange}
		/>
	);
};

export default CopyQuestionnaireListTable;
