import React, { Key } from 'react';
import { Link } from 'react-router-dom';

import { Button, Popconfirm, Popover, Space } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import DeleteIcon from 'components/SVG/DeleteIcon';
import EditIcon from 'components/SVG/EditIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
import Tag from 'modules/Common/components/Tag';
import {
	ContractStatusEnum,
	ContractStatusValuesEnum,
	IContractState,
	IContractValues,
	TagColoursType,
} from 'modules/Common/types';
import { COLORS } from 'theme';
import { IOption, Routes, UserRolesType } from 'types';
import { getCurrencySymbolByName, getDateFormat, handleBtoReference } from 'utils/helpers';

import { StatusStylesData } from './Contracts.constants';
import { Styled } from './Contracts.styled';

type DataTableType = IContractValues & {
	key?: Key;
	contractId?: number;
};

const ContractStatusColours = {
	[ContractStatusValuesEnum.Outstanding]: TagColoursType.Blue,
	[ContractStatusValuesEnum.Fulfilled]: TagColoursType.Orange,
	[ContractStatusValuesEnum.PendingForApproval]: TagColoursType.Volcano,
};

export const columns = (
	clientId: string,
	contractStatuses: IContractState[],
	buttonDisabledList: number[],
	handleChangeStatus: (contractId: number, statusId: number) => void,
	handleViewItem: (contractId: number) => void,
	handleEditItem: (contractId: number) => void,
	handlePendingContractApproval: (contractId: number) => void,
	handleDeleteItem: (contractId: number) => void,
	roles: UserRolesType,
	userId: number,
	isClientContext: boolean,
): ColumnsDataType => [
	{
		title: 'Client',
		dataIndex: 'clientName',
		sorter: isClientContext ? false : true,
		key: 'clientName',
		render: (_: string, { clientName, client }: Partial<DataTableType>) => (
			<Styled.Title>{clientName || client}</Styled.Title>
		),
	},
	{
		title: 'Contract',
		dataIndex: 'btoReference',
		key: 'btoReference',
		render: (_: string, { btoReference, id, contractId }: Partial<DataTableType>) => {
			const idOfContract = id || contractId;
			const path = clientId
				? `${Routes.BOClientContext}/${clientId}${Routes.ContractsView}/${idOfContract}`
				: `${Routes.BackOffice}${Routes.ContractsView}/${idOfContract}`;

			return (
				<Styled.PageLink to={path} state={{ contractName: btoReference }}>
					<span>{btoReference}</span>
				</Styled.PageLink>
			);
		},
	},
	{
		title: 'RELATED INVOICE(S)',
		dataIndex: 'relatedInvoice',
		key: 'relatedInvoice',
		align: 'center',
		render: (_: string, { invoices }: Partial<DataTableType>) => {
			if (!invoices?.length) {
				return <span>-</span>;
			}

			if (invoices.length === 1) {
				const currentInvoice = invoices[0];
				const invoiceId = currentInvoice.id || currentInvoice?.invoiceId;
				const path = clientId
					? `${Routes.BOClientContext}/${clientId}${Routes.InvoicesView}/${invoiceId}`
					: `${Routes.BackOffice}${Routes.InvoicesView}/${invoiceId}`;

				return (
					<Link to={path}>
						<Button type='default'>View</Button>
					</Link>
				);
			}

			const popoverContent = (
				<Styled.Popover>
					{invoices.map((currentInvoice) => {
						const { preparedBtoReference } = handleBtoReference(currentInvoice.btoReference);
						const invoiceId = currentInvoice.id || currentInvoice?.invoiceId;
						const path = clientId
							? `${Routes.BOClientContext}/${clientId}${Routes.InvoicesView}/${invoiceId}`
							: `${Routes.BackOffice}${Routes.InvoicesView}/${invoiceId}`;

						return (
							<Styled.PopoverInvoice key={currentInvoice.id}>
								<Link to={path}>
									<Button type='default'>{preparedBtoReference}</Button>
								</Link>
							</Styled.PopoverInvoice>
						);
					})}
				</Styled.Popover>
			);

			return (
				<Popover content={popoverContent} title='List of related invoices'>
					<Button type='default'>View</Button>
				</Popover>
			);
		},
	},
	{
		title: 'Value',
		dataIndex: 'totalPrice',
		key: 'totalPrice',
		align: 'center',
		render: (_: string, { currency, totalPrice }: Partial<DataTableType>) =>
			`${getCurrencySymbolByName(currency)}${Number(totalPrice)?.toFixed(2)}` ?? 0,
	},
	{
		title: 'Date Sent',
		dataIndex: 'startDate',
		key: 'startDate',
		align: 'center',
		sorter: true,
		render: (_: string, { startDate }: Partial<DataTableType>) => {
			const formattedData = getDateFormat(startDate) ?? '-';

			return <>{formattedData}</>;
		},
	},
	{
		title: 'Status',
		dataIndex: 'accepted',
		key: 'accepted',
		align: 'center',
		defaultSortOrder: 'ascend',
		sorter: true,
		render: (_, { contractState, id, contractId }: Partial<DataTableType>) => {
			const isFulfilled = contractState?.value === ContractStatusValuesEnum.Fulfilled;
			const isAccepted = contractState?.value === ContractStatusValuesEnum.Accepted;
			const styles = contractState?.name && StatusStylesData[contractState?.name];
			const idOfContract = id || contractId;
			const { isSuperUser } = roles || {};

			return (
				<>
					{(isFulfilled || isAccepted) && isSuperUser ? (
						<Styled.Select
							value={contractState?.id || null}
							styles={styles}
							onChange={(statusId: number) =>
								idOfContract && handleChangeStatus(idOfContract, statusId)
							}
							placeholder='Select a status'
							label=''
						>
							{contractStatuses?.map((option: IOption) => (
								<Styled.Option key={option.id} value={option.id}>
									{option.name}
								</Styled.Option>
							))}
						</Styled.Select>
					) : (
						<Tag
							color={ContractStatusColours[contractState?.value]}
							style={{
								boxSizing: 'content-box',
								minWidth: '120px',
							}}
						>
							{ContractStatusEnum[contractState?.value]}
						</Tag>
					)}
				</>
			);
		},
	},
	{
		title: 'Action',
		key: 'action',
		align: 'center',
		width: '150px',
		render: (_, { contractState, id, contractId, creator }: Partial<DataTableType>) => {
			const { isSuperUser, isSalesUser, isServiceUser, isManagerUser } = roles || {};
			const showApproveButton =
				contractState?.value === ContractStatusValuesEnum.PendingForApproval && isSuperUser;
			const idOfContract = id || contractId;
			const editableTypeOfContracts =
				contractState?.value === ContractStatusValuesEnum.Outstanding ||
				contractState?.value === ContractStatusValuesEnum.PendingForApproval;
			const isEditableForUser =
				editableTypeOfContracts && (!isServiceUser || (isServiceUser && userId === creator));
			const isNotDeletableTypeOfContracts =
				contractState?.value === ContractStatusValuesEnum.Fulfilled;
			const isDeletableForUser = !isNotDeletableTypeOfContracts && (isSuperUser || isManagerUser);

			return (
				<Space size='middle'>
					{showApproveButton && (
						<Styled.Button
							buttonType={ButtonTypes.default}
							onClick={() => idOfContract && handlePendingContractApproval(idOfContract)}
							disabled={!!idOfContract && buttonDisabledList.includes(idOfContract)}
						>
							Approve
						</Styled.Button>
					)}
					<Styled.Button
						buttonType={ButtonTypes.default}
						icon={<EditIcon fill={COLORS.black} />}
						onClick={() => idOfContract && handleEditItem(idOfContract)}
						disabled={!isEditableForUser}
					/>
					{!isSalesUser && (
						<Popconfirm
							placement='bottomRight'
							title='Are you sure?'
							onConfirm={() => idOfContract && handleDeleteItem(idOfContract)}
							okText='Yes'
							cancelText='Cancel'
						>
							<Styled.Button
								buttonType={ButtonTypes.default}
								icon={<DeleteIcon fill={COLORS.black} width='14' height='14' />}
								disabled={!isDeletableForUser}
							/>
						</Popconfirm>
					)}
				</Space>
			);
		},
	},
];
