import styled from 'styled-components/macro';

export namespace Styled {
	export const Field = styled.div`
		position: relative;
	`;

	export const Button = styled.span`
		position: absolute;
		bottom: 8px;
		right: 12px;
		display: flex;
		max-height: 16px;
		user-select: none;
		cursor: pointer;
	`;
}
