import React, { memo } from 'react';

import ArrowDown from 'components/SVG/ArrowDown';
import ArrowUp from 'components/SVG/ArrowUp';
import { ICandidatesSortedByStatus } from 'modules/Common/types';
import { ReactParent } from 'types';

import { statusOptions } from '../../CandidatesApplication/CandidatesApplication.constants';

import { Styled } from './CandidateApplicationPanelMenu.styled';

type CandidateApplicationPanelMenuProps = ReactParent & {
	activeId?: number;
	data: ICandidatesSortedByStatus[];
	onChangeCallback: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => void;
};

const CandidateApplicationPanelMenu: React.FC<CandidateApplicationPanelMenuProps> = ({
	activeId,
	data,
	onChangeCallback,
}) => {
	return (
		<Styled.Collapse
			bordered={false}
			expandIcon={({ isActive }) =>
				isActive ? <ArrowUp width='14' height='14' /> : <ArrowDown width='14' height='14' />
			}
			expandIconPosition={'end'}
			defaultActiveKey={statusOptions.map((status) => status.id)}
			items={data?.map((item) => ({
				key: item.status.id,
				label: <Styled.CollapseTitle>{item.status.value}</Styled.CollapseTitle>,
				children: (
					<Styled.CollapsePanel>
						{item?.options?.map((option) => (
							<Styled.Button
								key={option.id}
								onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
									option.id && onChangeCallback(e, option.id)
								}
								isActive={option.id === activeId}
							>
								{option.name}
							</Styled.Button>
						))}
					</Styled.CollapsePanel>
				),
			}))}
		/>
	);
};

export default memo(CandidateApplicationPanelMenu);
