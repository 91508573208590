import styled from 'styled-components/macro';

export namespace Styled {
	export const Wrapper = styled.div`
		width: 100%;
	`;
	export const ActionButtonWrapper = styled.div`
		display: flex;
		justify-content: center;
		width: 100%;
	`;
}
