import styled from 'styled-components/macro';

import { device } from 'theme';

interface IBrandingInnerProps {
	isCollapsed: boolean;
}

export namespace Styled {
	export const Root = styled.section`
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-bottom: 32px;
		width: 100%;
		max-width: 1368px;
	`;

	export const BgUploader = styled.div<IBrandingInnerProps>`
		position: absolute;
		z-index: 3;
		top: 238px;
		left: ${({ isCollapsed }) => (isCollapsed ? 'calc(50% + -5px)' : 'calc(50% + 87px)')};

		@media ${device.desktop} {
			left: calc(50% + -5px);
		}
	`;

	export const LogoUploader = styled.div<IBrandingInnerProps>`
		position: absolute;
		z-index: 15;
		top: 428px;
		left: 343px;

		@media ${device.desktop} {
			top: 428px;
			left: 316px;
		}
	`;
}
