import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

export const getMonthOptions = () => {
	const options = [];
	const today = dayjs();
	for (let i = 0; i < 12; i++) {
		const month = today.add(i, 'month');
		options.push({
			id: month.format('YYYY-MM'),
			name: month.format('MMMM YYYY'),
		});
	}

	return options;
};

export const calculateDateRange = (selectedMonth: string) => {
	const today = dayjs();
	const [year, month] = selectedMonth.split('-').map(Number);
	const selectedDate = dayjs(new Date(year, month - 1, 1));
	const startDate = selectedDate.isSameOrBefore(today) ? today : selectedDate;
	const endDate = startDate.endOf('month');

	if (startDate.isSame(endDate, 'day')) {
		return `Time for using: ${startDate.format('MMMM D, YYYY')}`;
	} else {
		return `Time for using: ${startDate.format('MMMM D')} - ${endDate.format('MMMM D, YYYY')}`;
	}
};
