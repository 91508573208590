import React from 'react';

import { ReactParent } from 'types';

import { Styled } from './FormBlockLine.styled';

interface IFormBlockLine extends ReactParent {
	columns?: number;
}

export const FormBlockLine: React.FC<IFormBlockLine> = ({ children, columns = 2 }) => (
	<Styled.FormBlockContent columns={columns}>{children}</Styled.FormBlockContent>
);
