import React, { FC, JSXElementConstructor, ReactElement, useState } from 'react';

import { message, Modal, Upload, UploadProps } from 'antd';
import type { RcFile, UploadFile, UploadChangeParam } from 'antd/es/upload/interface';

import AddIcon from 'components/SVG/AddIcon';
import {
	acceptedImageFileFormat,
	DEFAULT_LOGO,
	MAX_FILE_SIZE,
	MESSAGES,
} from 'modules/Common/constants';
import { COLORS } from 'theme';

import { Styled } from './ImageUploader.styled';

type ImageUploaderProps = {
	imageUrl?: string;
	uploadFileRequest: () => void;
	setErrorFileUpload: (message: string) => void;
	onChangeCallback: (value: string) => void;
	inputName: string;
};

const baseUrl = `${process.env.REACT_APP_API_URL}/open/public/file/logo/`;

const ImageUploader: FC<ImageUploaderProps> = ({
	imageUrl = DEFAULT_LOGO,
	uploadFileRequest,
	setErrorFileUpload,
	onChangeCallback,
	inputName,
}) => {
	const url = imageUrl && !imageUrl.startsWith('/images') ? `${baseUrl}${imageUrl}` : imageUrl;

	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImageUrl, setPreviewImageUrl] = useState(url);

	const defaultImage = imageUrl && {
		uid: '-1',
		name: 'image.png',
		status: 'done',
		url,
	};

	const [fileData, setFileData] = useState<UploadFile | unknown>(defaultImage ?? '');

	const handleCancel = () => setPreviewOpen(false);

	const draggerProps: UploadProps = {
		name: inputName ?? 'file',
		multiple: false,
		listType: 'picture-card',
		maxCount: 1,
		defaultFileList: [fileData as UploadFile],
		accept: acceptedImageFileFormat,
		itemRender: (originNode: ReactElement<unknown, string | JSXElementConstructor<unknown>>) =>
			fileData ? <>{originNode}</> : null,
		customRequest: uploadFileRequest,
		onChange({ file }: UploadChangeParam<UploadFile>) {
			const { name, status, response } = file;

			if (status === 'done' && response?.data) {
				setFileData(file);

				onChangeCallback(response?.data);
				setPreviewImageUrl(`${baseUrl}${response?.data}`);

				message.success(`${name} file uploaded successfully.`);
			} else if (status === 'error') {
				message.error(`${name} file upload failed.`);
			}
		},
		beforeUpload(file: RcFile) {
			const { size, type } = file;
			const isOverMaxFileSize = !!size && size / 1024 / 1024 < MAX_FILE_SIZE;

			const isAcceptedFormats = type && acceptedImageFileFormat.split(', ').includes(type);

			if (!isOverMaxFileSize || !isAcceptedFormats) {
				setErrorFileUpload(MESSAGES.imageUploadErrorText);
			} else {
				setErrorFileUpload('');
			}

			return isOverMaxFileSize && isAcceptedFormats;
		},
		onPreview() {
			setPreviewOpen(true);
		},
		onRemove() {
			setErrorFileUpload('');
			onChangeCallback('');
			setFileData(null);
			setPreviewImageUrl('');
		},
	};

	return (
		<Styled.Root>
			<Upload {...draggerProps}>
				<>
					{fileData ? null : (
						<Styled.ImagePlug>
							<AddIcon fill={COLORS.darkGray2} width='10' height='10' />
							<span>Upload</span>
						</Styled.ImagePlug>
					)}
					<Styled.Content>
						<Styled.Text className='ant-upload-text'>
							Upload a files or drag and drop it
						</Styled.Text>
						<Styled.SubText className='ant-upload-hint'> PNG, JPEG, SVG up to 15MB </Styled.SubText>
					</Styled.Content>
				</>
			</Upload>
			<Modal open={previewOpen} footer={null} centered onCancel={handleCancel}>
				<img alt='example' style={{ width: '100%' }} src={previewImageUrl} />
			</Modal>
		</Styled.Root>
	);
};

export default ImageUploader;
