import axios from 'axios';
import Cookies from 'js-cookie';

import { get, post, put } from 'api/axiosInstance';
import { FileUploadTypesEnum } from 'modules/Common/types';
import { CookiesType, RequestsEnum } from 'types';

const baseUrl = process.env.REACT_APP_API_URL;

export const getClients = () => get(RequestsEnum.Client);

export const getClientsShort = (isParentClient) => {
	const url = isParentClient
		? `${RequestsEnum.ClientParent}${RequestsEnum.Short}`
		: `${RequestsEnum.Client}${RequestsEnum.Short}`;

	return get(url);
};

export const getClientById = (clientId) => get(`${RequestsEnum.Client}/${clientId}`);

export const createClient = (data) => post(RequestsEnum.Client, data);

export const getPricesByClientId = (clientId) => get(`${RequestsEnum.PriceClient}/${clientId}`);

export const createPrice = (data) => post(RequestsEnum.Price, data);

export const updatePrice = (data) => put(RequestsEnum.Price, data);

export const getCreditsByClientId = (clientId) => get(`${RequestsEnum.CreditClient}/${clientId}`);

export const getClientsSubs = () => get(RequestsEnum.SubscriptionPlan);

export const getClientRegions = () => get(RequestsEnum.Region);

export const getClientsByRegion = async (region) => {
	return get(`${RequestsEnum.ClientRegion}/${region}${RequestsEnum.Short}`);
};

export const uploadFile = async (options, isGenericFilePath = false) => {
	const {
		onSuccess = () => null,
		onError = () => null,
		file,
		onProgress = () => null,
		fileType = FileUploadTypesEnum.Logo,
		url = null,
		generateFilename = false,
		noAuth = false,
	} = options;
	const fmData = new FormData();
	const token = !noAuth && Cookies.get(CookiesType.JWT);
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		onUploadProgress: (event) => {
			onProgress({ percent: (event.loaded / event.total) * 100 });
		},
	};

	if (token) {
		config.headers['X-Authorization'] = `Bearer ${Cookies.get(CookiesType.JWT)}`;
	}

	if (generateFilename) {
		fmData.append('file', file, 'filename.png');
	} else {
		fmData.append('file', file);
	}

	const uploadUrl = url ?? (isGenericFilePath ? `${baseUrl}/file` : `${baseUrl}/file/${fileType}`);

	try {
		const res = await axios.post(uploadUrl, fmData, config);
		onSuccess(res);
	} catch (err) {
		onError({ err });
	}
};

const backOfficeService = {
	getClients,
	getClientsShort,
	getClientById,
	getClientsSubs,
	getClientRegions,
	getClientsByRegion,
	uploadFile,
	getPricesByClientId,
	getCreditsByClientId,
	createClient,
	createPrice,
	updatePrice,
};

export default backOfficeService;
