import styled from 'styled-components/macro';

export namespace Styled {
	export const TextBlock = styled.div`
		display: flex;
		flex-direction: column;
		width: 100%;

		ul {
			margin: auto;
			margin-bottom: 1em;
			padding: revert;
			list-style: disc;
		}

		img {
			width: 100% !important;
		}

		h2 {
			font-family: 'Raleway';
			font-weight: 700;
			line-height: normal;
			margin-bottom: 16px !important;
		}

		p {
			margin-bottom: 0;
		}
	`;

	export const Items = styled.div`
		display: flex;
		flex-direction: column;
		gap: 16px;
	`;

	export const Item = styled.div`
		h4 {
			font-family: 'Raleway';
			font-size: 14px;
			font-weight: 700;
			line-height: 24px;
			margin-bottom: 8px;
		}

		p {
			line-height: 24px;
		}
	`;
}
