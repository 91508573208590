import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Popconfirm } from 'antd';
import PropTypes, { oneOfType } from 'prop-types';

import NavBackButton from 'components/SVG/NavBackButton';

import * as Styled from './PageNavTitle.styled';

const PageNavTitle = ({
	navigationLink,
	title,
	children,
	state = {},
	confirmationMessage = '',
	noSpace = false,
	styles = {},
}) => {
	const navigate = useNavigate();

	const handleNavigate = useCallback(() => {
		navigate(navigationLink, { state });
	}, [navigationLink, navigate]);

	return (
		<Styled.NavTitleWrapper noSpace={noSpace} customStyles={styles}>
			{navigationLink && (
				<>
					{confirmationMessage ? (
						<Popconfirm
							placement='topLeft'
							title={confirmationMessage}
							onConfirm={() => handleNavigate()}
							okText='Yes'
							cancelText='Cancel'
						>
							<Styled.NavTitleBackButton>
								<NavBackButton />
							</Styled.NavTitleBackButton>
						</Popconfirm>
					) : (
						<Styled.NavTitleBackButton onClick={handleNavigate}>
							<NavBackButton />
						</Styled.NavTitleBackButton>
					)}
				</>
			)}
			{title && <Styled.NavTitle>{title}</Styled.NavTitle>}
			{children && <>{children}</>}
		</Styled.NavTitleWrapper>
	);
};

PageNavTitle.propTypes = {
	navigationLink: oneOfType([PropTypes.string, PropTypes.number]),
	title: PropTypes.string,
	children: PropTypes.node,
	state: PropTypes.object,
};

export default PageNavTitle;
