import React, { FC } from 'react';
import { Form, Field, FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import { useCountdown } from 'hooks/useEffects';
import { IChangePasswordValues } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { Fields, GenericType, IUserFormValues, Routes } from 'types';
import {
	composeValidators,
	requiredValidator,
	numberValidator,
	lengthValidator,
} from 'utils/validators';

import UnregisteredInput from '../../components/UnregisteredInput';

import * as Styled from './TwoFAForm.styled';

type TwoFAFormProps = {
	twoFALoginRequested: (
		values: Partial<IUserFormValues> | Partial<IChangePasswordValues>,
		jwt2faToken: string,
		cb: (route: string) => void,
	) => void;
	resendTwoFACodeRequested: (jwt2faToken: string, callback: (newToken: string) => void) => void;
	errorMessage: string;
	loading: GenericType;
	jwt2faToken: string;
};

const TwoFAForm: FC<TwoFAFormProps> = ({
	twoFALoginRequested,
	resendTwoFACodeRequested,
	jwt2faToken,
	loading,
}) => {
	const { minutes, seconds, isActive: isActiveCountdown, setIsActive } = useCountdown(1, 0, false);

	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const onSubmit = (values: Partial<IUserFormValues>) => {
		twoFALoginRequested(values, jwt2faToken, (route) => navigate(route));
	};

	const handleResendToken = () => {
		resendTwoFACodeRequested(jwt2faToken, (newToken: string): void =>
			setSearchParams({ jwt2fa: newToken }),
		);
		setIsActive(true);
	};

	const handleGoBackToLogin = () => navigate(Routes.Login);

	const formatTimeUnit = (timeUnit: number): string =>
		timeUnit > 9 ? `${timeUnit}` : `0${timeUnit}`;

	return (
		<Styled.Form>
			<p>
				We need to confirm your identity so a code has been sent to your email Please enter your
				code below to login
			</p>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit, valid, dirtySinceLastSubmit, form }) => (
					<form onSubmit={handleSubmit}>
						<FormSpy subscription={{ values: true }}>
							{() => (
								<>
									<Field
										name='verificationCode'
										validate={composeValidators(
											requiredValidator,
											numberValidator,
											lengthValidator(6, 6),
										)}
									>
										{({ input, meta }) => (
											<UnregisteredInput
												{...input}
												type={Fields.TEXT}
												placeholder='2FA Code'
												isFullWidth
												errorMessage={meta.error && meta.dirty && meta.error}
											/>
										)}
									</Field>
								</>
							)}
						</FormSpy>

						<Button
							isFullWidth
							buttonType={ButtonTypes.primary}
							type='submit'
							disabled={!valid || !!loading?.changePasswordLoad}
							loading={!!loading?.changePasswordLoad}
						>
							Submit
						</Button>
					</form>
				)}
			/>
			<Button
				isFullWidth
				disabled={isActiveCountdown}
				buttonType={ButtonTypes.tertiary}
				onClick={handleResendToken}
			>
				{isActiveCountdown
					? `New code can be requested in ${formatTimeUnit(minutes)}:${formatTimeUnit(seconds)}`
					: 'Resend 2FA code'}
			</Button>
			<Button isFullWidth buttonType={ButtonTypes.tertiary} onClick={handleGoBackToLogin}>
				Go back to Login
			</Button>
		</Styled.Form>
	);
};

export default connect(
	(state) => ({
		loading: unregisteredDucks.unregisteredSelectors.getLoading(state),
	}),
	{
		twoFALoginRequested: unregisteredDucks.unregisteredActions.twoFALoginRequested,
		resendTwoFACodeRequested: unregisteredDucks.unregisteredActions.resendTwoFACodeRequested,
	},
)(TwoFAForm);
