import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { removeCandidateFromDatabase } from 'api/endpoints/candidates';
import Box from 'components/Box';

import { handleError } from './RemoveCandidateFromDatabasePage.helpers';
import { Styled } from './RemoveCandidateFromDatabasePage.styled';

const RemoveCandidateFromDatabasePage = () => {
	const { candidateApplicationId } = useParams();
	const [searchParams] = useSearchParams();
	const pwd = searchParams.get('pwd');
	const [message, setMessage] = useState('Deleting candidate...');

	useEffect(() => {
		const removeCandidate = async () => {
			if (candidateApplicationId && pwd) {
				try {
					await removeCandidateFromDatabase(candidateApplicationId, pwd);
					setMessage('Your data has been successfully removed.');
				} catch (error) {
					handleError(error, setMessage);
				}
			}
		};

		removeCandidate();
	}, [candidateApplicationId, pwd]);

	return (
		<Styled.Root>
			<div>
				<img src='/images/connect-logo-blue.png' alt='connect' />
			</div>
			<Box>
				<p>{message}</p>
			</Box>
		</Styled.Root>
	);
};

export default RemoveCandidateFromDatabasePage;
