import React, { createContext, useContext, FC, ReactNode } from 'react';

import { ClientBrandingDataType } from 'modules/Common/types';
import { SubscriptionPlansType } from 'types';

interface AtsContextProps {
	subscriptionPlans: SubscriptionPlansType;
	clientBrandingData: ClientBrandingDataType;
}

const AtsLayoutContext = createContext<AtsContextProps | null>(null);

export const useAtsLayoutContext = () => {
	const context = useContext(AtsLayoutContext);
	if (!context) {
		return { subscriptionPlans: {}, clientBrandingData: {} };
	}

	return context;
};

type AtsLayoutProviderProps = {
	subscriptionPlans: SubscriptionPlansType;
	clientBrandingData: ClientBrandingDataType;
	children: ReactNode;
};

export const AtsLayoutProvider: FC<AtsLayoutProviderProps> = ({
	subscriptionPlans,
	clientBrandingData,
	children,
}) => {
	return (
		<AtsLayoutContext.Provider value={{ subscriptionPlans, clientBrandingData }}>
			{children}
		</AtsLayoutContext.Provider>
	);
};
