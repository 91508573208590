import { Button } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		position: relative;

		form {
			width: 100%;
		}
	`;

	export const EditButton = styled(Button)`
		position: absolute;
		top: -60px;
		right: 0;
	`;

	export const Head = styled.section`
		display: flex;
		justify-content: space-between;
	`;

	export const MainInfo = styled.section`
		h2 {
			margin-bottom: 0;
		}

		p {
			margin: 0;
			white-space: break-spaces;
			line-height: 24px;
		}
	`;

	export const FieldWrapper = styled.div`
		width: 100%;

		h2 {
			margin-bottom: 24px;
		}
	`;

	export const BoxWrapper = styled.div`
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 16px 24px;
		margin-bottom: 32px;
		background: ${COLORS.white};
		box-shadow: ${COLORS.elevationShadow1};
		border-radius: 10px;
		gap: 12px;

		&:last-of-type {
			margin-bottom: 0;
		}
	`;

	export const ContentItem = styled.div`
		display: flex;
		flex-direction: column;
		gap: 12px;
	`;

	export const ContentItemEmail = styled.p`
		display: flex;
		align-items: center;
		margin: 0;
		gap: 6px;

		.ant-btn {
			margin-left: 12px;
		}

		a {
			display: block;
			font-size: 18px;
			text-decoration: underline;
			transition: text-decoration 0.2s ease;

			&:hover {
				text-decoration: none;
			}
		}
	`;

	export const InfoHead = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		gap: 16px;
	`;

	export const InfoDetails = styled.div`
		display: flex;
		width: 100%;
		align-items: center;
		font-weight: 400;
		font-size: 16px;
	`;

	export const InfoDetailsItem = styled.div`
		display: flex;
		align-items: center;

		span {
			display: flex;
			margin: 0 16px;
		}
	`;

	export const Label = styled.div`
		margin-left: 10px;
		font-weight: 400;
		color: ${COLORS.darkGray2};
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
		margin-top: 24px;
		gap: 24px;
	`;
	export const Data = styled.div`
		margin-left: auto;
	`;
}
