import React, { type FC } from 'react';
import { Outlet } from 'react-router-dom';

import BackofficeLayout from 'modules/BackOffice/containers/BackofficeLayout';
import { ReactParent } from 'types';

export const BackOfficeMainPage: FC<ReactParent> = ({ children }) => {
	return (
		<BackofficeLayout>
			<Outlet />
			<>{children}</>
		</BackofficeLayout>
	);
};
