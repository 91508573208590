import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;
		font-family: 'Inter';

		h2 {
			margin-bottom: 16px;
			font-family: 'Inter';
		}

		label,
		button,
		input {
			font-family: 'Inter';
		}

		.ant-tabs .ant-tabs-nav {
			text-transform: uppercase;

			&:before {
				display: none;
			}

			.ant-tabs-nav-wrap {
				justify-content: center;
			}

			.ant-tabs-nav-list {
				justify-content: space-around;
				width: 100%;
			}

			.ant-tabs-tab {
				justify-content: center;
				width: 100%;
			}

			.ant-tabs-tab + .ant-tabs-tab {
				margin: 0;
			}
		}
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
		margin-bottom: 24px;
		gap: 16px;
	`;

	export const TitleWithImage = styled.div`
		display: flex;
		gap: 10px;
		justify-content: center;
		align-items: center;
	`;
}
