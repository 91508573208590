import { type FC } from 'react';

import Pagination from 'components/Pagination';
import Spinner from 'components/Spinner';
import {
	DEFAULT_CURRENT_PAGE,
	DEFAULT_PAGE_SIZE,
	DEFAULT_PAGE_SIZE_OPTIONS,
} from 'modules/Common/constants/table';

import { S } from './Table.styled';
import { TableProps } from './Table.types';
import TableSummary from './TableSummary';

const TableComponent: FC<TableProps> = ({
	data,
	columns,
	summaryData,
	current = DEFAULT_CURRENT_PAGE,
	pageSize = DEFAULT_PAGE_SIZE,
	total = 0,
	loading = false,
	onChange,
	onTableChange,
	onPageSizeChange,
	showDefaultPagination = false,
	pagination = true,
	pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
	rowKey,
	...props
}) => {
	return (
		<S.Root>
			<S.TableContainer
				{...props}
				loading={{
					spinning: loading,
					indicator: <Spinner />,
				}}
				width={'100%'}
				columns={columns}
				dataSource={data}
				onChange={onTableChange}
				summary={() => (summaryData ? <TableSummary summaryData={summaryData} fixed /> : null)}
				pagination={showDefaultPagination && pagination}
				{...(rowKey && { rowKey })}
			/>
			{!showDefaultPagination && pagination && onChange && (
				<Pagination
					current={current}
					pageSize={pageSize}
					total={total}
					pageSizeOptions={pageSizeOptions}
					onChange={onChange}
					onShowSizeChange={onPageSizeChange}
				/>
			)}
		</S.Root>
	);
};

export default TableComponent;
