import { AtsBrandingType } from 'modules/Common/types';
import { ReactParent } from 'types';

export enum BrandingMode {
	Preview = 'preview',
	Edit = 'edit',
	View = 'view',
}

export enum BrandingPage {
	ATS = 'ats',
	CareerPage = 'careerPage',
}

type AtsBrandingPickType = Pick<
	AtsBrandingType,
	'clientName' | 'textColour' | 'highlightColour' | 'logo' | 'headerBackground' | 'backgroundImage'
>;

export type BrandingContainerProps = ReactParent &
	AtsBrandingPickType & {
		clientId?: number;
		jobs?: {
			name: string;
		}[];
		statistic?: object;
		onBgRemove?: () => void;
		onBgEdit?: () => void;
		onLogoRemove?: () => void;
		onLogoEdit?: () => void;
		mode?: BrandingMode;
	};
