import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.div``;

	export const Header = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 16px;
	`;

	export const AllInterviewSwitcher = styled.div`
		display: flex;
		gap: 16px;
	`;

	export const TitleList = styled.div`
		height: auto;
		padding: 0;
		line-height: 28px;
		font-weight: 500;
		font-size: 16px;
		white-space: nowrap;
		width: 292px;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
	`;

	export const Main = styled.div`
		display: flex;
		flex-direction: column;
		gap: 20px;

		.ant-table-cell a {
			height: auto;
		}
	`;

	export const Info = styled.div`
		line-height: 80px;
	`;

	export const ActionButtons = styled.div`
		display: flex;
		justify-content: center;
		gap: 8px;

		button .ant-btn-icon {
			vertical-align: middle;
		}
	`;
}
