export const JOB_UPGRADES_VIEW_INFO = {
	'Hand Filtered CVs': {
		displayName: 'Hand Filtered CVs',
		messageWording: 'filtering CVs ',
		creditId: 2,
	},
	'The Guardian': {
		displayName: 'The Guardian',
		messageWording: 'guardian ',
		logoUrl: '/images/jobBoards/6.png',
		logoHeight: '31px',
		creditId: 3,
	},
	Indeed: {
		displayName: 'Indeed',
		messageWording: 'indeed sponsored ',
		logoUrl: '/images/jobBoards/8.png',
		logoHeight: '39px',
		creditId: 4,
	},
};
