import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section``;

	export const HeadWrapper = styled.div`
		display: flex;
		justify-content: space-between;
		width: 100%;
		gap: 16px;
	`;

	export const Title = styled.h2`
		display: flex;
		gap: 16px;
	`;

	export const HeadActions = styled.div`
		display: flex;
		gap: 16px;

		.ant-btn {
			border: 1px solid ${COLORS.borderColor};
			background-color: ${COLORS.lightGray3};
			box-shadow: ${COLORS.elevationShadow1};

			&:disabled {
				svg {
					path {
						fill: ${COLORS.gray};
					}
				}
			}
		}
	`;

	export const CandidateInfo = styled.div`
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		gap: 0 24px;
		margin-bottom: 10px;

		a {
			display: flex;
			align-items: center;
			line-height: 24px;
			gap: 8px;
		}
	`;

	export const DetailsInfoMain = styled.div``;

	export const SelectWrapper = styled.div`
		text-align: center;
		.ant-select-selector {
			height: 40px !important;
			min-width: 200px;
			max-width: 400px;
			display: flex;
			align-items: center;
		}
		.ant-select-selection-overflow {
			justify-content: center;
		}
		.ant-select-selection-item {
			background-color: ${COLORS.white};
			font-weight: bold;
		}
	`;
}
