import { type FC, useState } from 'react';
import { connect } from 'react-redux';

import _ from 'lodash';

import { BrandingMode } from 'modules/Common/components/BrandingContainer/BrandingContainer.types';
import {
	acceptedImageFileFormat,
	DEFAULT_CAREER_BG,
	DEFAULT_LOGO,
	jobsData,
} from 'modules/Common/constants';
import { ADDRESS_FIELDS } from 'modules/Common/constants/addressFields';
import CareerBrandingInner from 'modules/Common/containers/CareerBrandingInner';
import { BenefitType, ClientAddressType, ClientChangeFormDataType } from 'modules/Common/types';
import { getSingleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import { prepareSocialNetworksData } from 'modules/Common/utils/commonHelpers';

import { backOfficeDucks } from '../../ducks';

type CareerPageContainerProps = {
	clientChangesFormData: ClientChangeFormDataType;
	saveClientChangesFormProcess: (props: Partial<ClientChangeFormDataType>) => void;
};

const CareerPageContainer: FC<CareerPageContainerProps> = ({
	clientChangesFormData,
	saveClientChangesFormProcess,
}) => {
	const { careerBranding, clientName = 'no name LTD' } = clientChangesFormData;
	const {
		backgroundImage = DEFAULT_CAREER_BG,
		whyOurCompany,
		benefits,
		socialNetworks,
		logo = DEFAULT_LOGO,
		videoLink,
	} = careerBranding;

	const changewhyOurCompany = (v: string): void => {
		saveClientChangesFormProcess({
			careerBranding: {
				...clientChangesFormData.careerBranding,
				whyOurCompany: v,
			},
		});
	};
	const changeBenefits = (v: BenefitType[]): void => {
		saveClientChangesFormProcess({
			careerBranding: {
				...clientChangesFormData.careerBranding,
				benefits: v,
			},
		});
	};
	const setLogoUrl = (v: string): void => {
		saveClientChangesFormProcess({
			logo: v,
			careerBranding: {
				...clientChangesFormData.careerBranding,
				logo: v,
			},
			atsBranding: {
				...clientChangesFormData.atsBranding,
				logo: v,
			},
		});
	};
	const setBgUrl = (v: string): void => {
		saveClientChangesFormProcess({
			careerBranding: {
				...clientChangesFormData.careerBranding,
				backgroundImage: v,
			},
		});
	};
	const changevideoLink = (v: string): void => {
		saveClientChangesFormProcess({
			careerBranding: { ...clientChangesFormData.careerBranding, videoLink: v },
		});
	};
	const changeClientAddress = (v: ClientAddressType): void => {
		saveClientChangesFormProcess({
			careerBranding: {
				...clientChangesFormData.careerBranding,
				...v,
			},
		});
	};
	const clientAddress = _.pick(clientChangesFormData.careerBranding, ADDRESS_FIELDS);
	const socialNetworksData = socialNetworks && prepareSocialNetworksData(socialNetworks);

	const onBgRemove = () => {
		setBgUrl('');
	};

	const bgUploaderProps = getSingleFileUploaderProps(
		(url, responseData) => {
			setBgUrl(responseData);
		},
		{ showUploadList: false, accept: acceptedImageFileFormat },
	);

	const logoUploaderProps = getSingleFileUploaderProps(
		(url, responseData) => {
			setLogoUrl(responseData);
		},
		{ showUploadList: false, accept: acceptedImageFileFormat },
	);

	const [colors, setColors] = useState(clientChangesFormData.careerPageColors);
	const ajustColorData = [
		{
			id: 0,
			title: 'Highlight Colour',
			initialValue: colors?.highlightColour,
			onChange: (color: string): void => setColors({ ...colors, highlightColour: color }),
		},
	];

	const applyColors = () => saveClientChangesFormProcess({ careerPageColors: colors });
	const resetColors = () => setColors(clientChangesFormData.careerPageColors);

	return (
		<CareerBrandingInner
			mode={BrandingMode.Edit}
			ajustColorData={ajustColorData}
			applyColors={applyColors}
			resetColors={resetColors}
			backgroundImage={backgroundImage}
			bgUploaderProps={bgUploaderProps}
			logoUploaderProps={logoUploaderProps}
			onBgRemove={onBgRemove}
			jobsData={jobsData}
			clientName={clientName}
			logo={logo}
			whyOurCompany={whyOurCompany}
			changewhyOurCompany={changewhyOurCompany}
			benefits={benefits}
			changeBenefits={changeBenefits}
			socialNetworksData={socialNetworksData}
			changevideoLink={changevideoLink}
			changeClientAddress={changeClientAddress}
			clientAddress={clientAddress}
			colors={colors}
			videoLink={videoLink}
			setBgUrl={setBgUrl}
		/>
	);
};

export default connect(
	(state) => ({
		clientChangesFormData: backOfficeDucks.backOfficeSelectors.getClientChangesFormState(state),
	}),
	{
		saveClientChangesFormProcess: backOfficeDucks.backOfficeActions.saveClientChangesFormProcess,
	},
)(CareerPageContainer);
