import { type FC, useState } from 'react';
import { Form, Field } from 'react-final-form';

import { Button } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { BrandingMode } from 'modules/Common/components/BrandingContainer/BrandingContainer.types';
import ControlButtons from 'modules/Common/components/BrandingContainer/ControlButtons';
import HeroHeader from 'modules/Common/components/HeroHeader';
import { CareerBrandingType } from 'modules/Common/types';

import { Styled } from './CareerPageMain.styled';

interface IFormValues {
	description: string;
}

type CareerPageMainPickType = Pick<
	CareerBrandingType,
	'clientName' | 'whyOurCompany' | 'logo' | 'backgroundImage'
>;

type CareerPageMainProps = CareerPageMainPickType & {
	mode?: string;
	clientName?: string;
	whyOurCompany?: string;
	logo?: string;
	backgroundImage?: string;
	onBgEdit?: () => void;
	onBgRemove?: () => void;
	onLogoEdit?: () => void;
	onLogoRemove?: () => void;
	changewhyOurCompany: (description: string) => void;
};

const baseUrl = `${process.env.REACT_APP_API_URL}/open/public/file/logo/`;

const CareerPageMain: FC<CareerPageMainProps> = ({
	clientName,
	backgroundImage,
	mode,
	onBgEdit,
	logo,
	onLogoEdit,
	onBgRemove,
	onLogoRemove,
	whyOurCompany,
	changewhyOurCompany,
}) => {
	const headerBackground = 'blue';
	const [descriptionEditingActive, setDescriptionEditActive] = useState(false);

	const onSubmit = (values: IFormValues) => {
		changewhyOurCompany(values.description);
		setDescriptionEditActive(false);
	};

	const logoUrl = logo?.split('/').includes('images') ? logo : logo && `${baseUrl}${logo}`;

	const backgroundImageUrl = backgroundImage?.split('/').includes('images')
		? backgroundImage
		: backgroundImage && `${baseUrl}${backgroundImage}`;

	return (
		<Styled.Root>
			<HeroHeader
				backgroundImageUrl={backgroundImageUrl}
				headerBackground={headerBackground}
				width='100%'
				height='300px'
			>
				{mode === BrandingMode.Edit ? (
					<Styled.HeaderContainerControls>
						<ControlButtons onEdit={onBgEdit} onRemove={onBgRemove} />
					</Styled.HeaderContainerControls>
				) : (
					<></>
				)}
			</HeroHeader>
			<Styled.Info>
				<Styled.Logo>
					{logoUrl && <img src={logoUrl} alt='Client Logo' />}
					{mode === BrandingMode.Edit && (
						<Styled.ButtonBox>
							<ControlButtons onEdit={onLogoEdit} onRemove={onLogoRemove} />
						</Styled.ButtonBox>
					)}
				</Styled.Logo>
				<Styled.Heading>{clientName}</Styled.Heading>
			</Styled.Info>
			<Styled.DescriptionContainer>
				<Styled.DescriptionLabel>WHY {clientName}</Styled.DescriptionLabel>
				{mode === BrandingMode.Edit && !descriptionEditingActive && (
					<Styled.DescriptionControl>
						<ControlButtons onEdit={() => setDescriptionEditActive(true)} />
					</Styled.DescriptionControl>
				)}
				{descriptionEditingActive && mode === BrandingMode.Edit ? (
					<Styled.DescriptionForm>
						<Form
							onSubmit={onSubmit}
							render={({ handleSubmit, valid }) => {
								return (
									<form onSubmit={handleSubmit}>
										<Styled.DescriptionFormArea>
											<Field initialValue={whyOurCompany} name='description'>
												{({ input }) => <TextArea {...input} placeholder='why your company' />}
											</Field>
										</Styled.DescriptionFormArea>
										<Styled.DescriptionFormSubmit>
											<Button type='primary' htmlType='submit' disabled={!valid}>
												Save Changes
											</Button>
										</Styled.DescriptionFormSubmit>
									</form>
								);
							}}
						/>
					</Styled.DescriptionForm>
				) : (
					<Styled.Description>{whyOurCompany}</Styled.Description>
				)}
			</Styled.DescriptionContainer>
		</Styled.Root>
	);
};

export default CareerPageMain;
