import React, { FC } from 'react';
import { Field, useFormState } from 'react-final-form';

import { Input, Radio, Select } from 'antd';
import _ from 'lodash';

import FieldWrapper from 'components/FieldWrapper';
import PriceBundle from 'modules/BackOffice/components/PriceBundle';
import * as Styled from 'modules/BackOffice/containers/ClientChangesFormBranding/ClientChangesFormBranding.styled';
import FormBlock from 'modules/Common/components/FormBlock';
import { TimeOptions } from 'modules/Common/constants';
import {
	ClientChangeFormDataType,
	BaseCreditType,
	CreditDefaultBundleType,
} from 'modules/Common/types';
import { Fields } from 'types';
import { getBudleInputWidth } from 'utils/helpers';
import { requiredValidator } from 'utils/validators';

import { prepareCreditsDefaultValues } from '../../CreditPrices/CreditPrices.helpers';

type CreditsProps = {
	baseCredits: BaseCreditType[];
	clientChangesFormData: ClientChangeFormDataType;
	isEdit: boolean;
	creditsDefaultPrices: CreditDefaultBundleType[];
};

const Credits: FC<CreditsProps> = ({
	baseCredits,
	clientChangesFormData,
	creditsDefaultPrices,
	isEdit,
}) => {
	const { values } = useFormState();
	const defaultPricesInitValues = prepareCreditsDefaultValues(creditsDefaultPrices);

	if (values.region && values.region !== 1) return null;

	return (
		<>
			<FormBlock title='Credits'>
				{!values.parent && (
					<>
						{baseCredits.map((credit, idx) => {
							const bundles = credit?.bundles.slice(1, -1).replace(/\s/g, '').split(',');

							const name =
								(isEdit &&
									Object.keys(clientChangesFormData)?.find((i) =>
										i.startsWith(`credit-${credit.id}`),
									)) ||
								`credit-${credit.id}`;

							return (
								<Styled.BundleRow key={credit.name}>
									<Styled.DefaultCredits>
										<Field
											name={name}
											initialValue={
												clientChangesFormData?.[name as keyof typeof clientChangesFormData] || 0
											}
										>
											{({ input }) => (
												<FieldWrapper
													name={name}
													label={credit.name}
													hint={!idx ? 'The number of active credits the client has' : ''}
												>
													<Input
														{...input}
														type={Fields.NUMBER}
														step={1}
														min={0}
														placeholder='e.g. 10'
													/>
												</FieldWrapper>
											)}
										</Field>
										<Styled.Space height={40} />
									</Styled.DefaultCredits>
									<Styled.BundlesSection>
										<Styled.FieldLabel>Price</Styled.FieldLabel>
										<Styled.BundlesWrapper>
											{bundles?.map((bundle) => {
												const bundleName =
													(!_.isEmpty(clientChangesFormData) &&
														Object.keys(clientChangesFormData)?.find((i) =>
															i.startsWith(`price-${credit.id}-${bundle}`),
														)) ||
													`price-${credit.id}-${bundle}`;

												return (
													<PriceBundle
														key={bundle}
														bundle={bundle}
														bundles={bundles}
														defaultValue={
															clientChangesFormData?.[
																bundleName as keyof typeof clientChangesFormData
															] || defaultPricesInitValues[bundleName]
														}
														inputWidth={getBudleInputWidth(bundles.length)}
														name={bundleName}
													/>
												);
											})}
										</Styled.BundlesWrapper>
										{!idx && (
											<Styled.CreditDesc>
												Price per 1 credit depending on the number of purchased credits.
											</Styled.CreditDesc>
										)}
									</Styled.BundlesSection>
								</Styled.BundleRow>
							);
						})}
					</>
				)}

				<Styled.BundleRow>
					<Styled.DefaultCredits>
						<Field
							name='chargeVatOnContracts'
							initialValue={isEdit ? clientChangesFormData?.chargeVatOnContracts : true}
						>
							{({ input }) => (
								<FieldWrapper name='chargeVatOnContracts' label='Charge VAT on Contracts?'>
									<Radio.Group
										{...input}
										value={_.isBoolean(input.value) ? input.value : true}
										options={[
											{ label: 'Yes', value: true },
											{ label: 'No', value: false },
										]}
									/>
								</FieldWrapper>
							)}
						</Field>
					</Styled.DefaultCredits>
					<Styled.BundlesSection>
						<FieldWrapper name='defaultPaymentTerms' label='Default Payment Terms'>
							<Styled.PaymentTypeField>
								<Field
									name='defaultPaymentTerms'
									initialValue={clientChangesFormData?.defaultPaymentTerms}
									validate={requiredValidator}
								>
									{({ input, meta }) => (
										<FieldWrapper
											name='defaultPaymentTerms'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
											isFixed
										>
											<Input
												{...input}
												type={Fields.NUMBER}
												placeholder='e.g. 4'
												autoComplete='off'
												min={0}
											/>
										</FieldWrapper>
									)}
								</Field>
								<Field
									name='defaultPaymentTermsUnit'
									initialValue={
										clientChangesFormData?.defaultPaymentTermsUnit || TimeOptions[0].value
									}
								>
									{({ input }) => (
										<Select {...input} placeholder='-Select period-' options={TimeOptions} />
									)}
								</Field>
							</Styled.PaymentTypeField>
						</FieldWrapper>
					</Styled.BundlesSection>
				</Styled.BundleRow>
			</FormBlock>
		</>
	);
};

export default Credits;
