import React, { memo, useEffect, useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';

import API from 'api';
import Spinner from 'components/Spinner';
import ArrowLeft from 'components/SVG/ArrowLeft';
import ArrowRight from 'components/SVG/ArrowRight';

import ErrorBoundary from '../ErrorBoundary';

import { Styled } from './PDFViewer.styled';

interface PdfReaderProps {
	pdfUrl: string;
	allPages?: boolean;
	isBorder?: boolean;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const PDFViewer: React.FC<PdfReaderProps> = ({ pdfUrl, allPages = true, isBorder = false }) => {
	const [numPages, setNumPages] = useState<number | null>(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [loading, setLoading] = useState<boolean>(false);
	const passwordRef = React.useRef<string | null>(null);
	const [passwordProtectedErrorMessage, setPasswordProtectedErrorMessage] = useState<string | null>(
		null,
	);

	const [file, setFile] = useState(null);

	useEffect(() => {
		getFile();
	}, [pdfUrl]);

	const docOptions = useMemo(() => {
		return { workerSrc: pdfjs.GlobalWorkerOptions.workerSrc } as pdfjs.PDFDateString;
	}, []);

	const getFile = async () => {
		try {
			setLoading(true);
			const fileBlob = await API.candidateApplicationService.getApplicationAttachment(pdfUrl);

			const link = URL.createObjectURL(fileBlob);

			setFile(link);
		} catch (e) {
			console.error('get file error', e);
		} finally {
			setLoading(false);
		}
	};

	const onDocumentLoadSuccess = (pdf: pdfjs.PDFDocumentProxy) => {
		setNumPages(pdf.numPages);
	};

	const onPassword = (callback: (password: string) => void, reason: number) => {
		if (passwordRef.current && reason !== pdfjs.PasswordResponses.INCORRECT_PASSWORD) {
			return callback(passwordRef.current);
		}

		const promptForPassword = (message: string) => {
			const password = prompt(message);
			if (password === null) {
				setFile(null);
				setPasswordProtectedErrorMessage(
					'This file is password protected. Please reload the page and try again.',
				);
			} else {
				passwordRef.current = password;
				callback(password);
			}
		};

		switch (reason) {
		case pdfjs.PasswordResponses.NEED_PASSWORD:
			promptForPassword('Enter the password to open this file.');
			break;
		case pdfjs.PasswordResponses.INCORRECT_PASSWORD:
			promptForPassword('Invalid password. Please try again.');
			break;
		default:
			break;
		}
	};

	function changePage(offset: number) {
		setPageNumber((prevPageNumber) => prevPageNumber + offset);
	}

	function previousPage() {
		changePage(-1);
	}

	function nextPage() {
		changePage(1);
	}

	pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

	const pageProps = {
		renderTextLayer: false,
		renderAnnotationLayer: false,
		canvasBackground: 'white',
		scale: 1.2,
		className: 'pdf-canvas',
		loading: <Spin indicator={antIcon} />,
	};

	if (loading) {
		return <Spinner />;
	}

	return (
		<Styled.Root isBorder={isBorder}>
			<ErrorBoundary errorMessage='This file is currently not ready for viewing, please check back again shortly'>
				{file ? (
					<Document
						file={file}
						options={docOptions}
						onLoadSuccess={onDocumentLoadSuccess}
						className='pdf-canvas-document'
						loading={<Spin indicator={antIcon} />}
						onPassword={onPassword}
					>
						{allPages ? (
							Array.from(new Array(numPages), (_, index) => (
								<Page key={`page_${index + 1}`} pageNumber={index + 1} {...pageProps} />
							))
						) : (
							<Page pageNumber={pageNumber} {...pageProps} />
						)}
						{!allPages && numPages && (
							<Styled.Navigation>
								<p>
									Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
								</p>
								<Button type='default' disabled={pageNumber <= 1} onClick={previousPage}>
									<ArrowLeft width='20' height='20' />
								</Button>
								<Button type='default' disabled={pageNumber >= numPages} onClick={nextPage}>
									<ArrowRight width='20' height='20' />
								</Button>
							</Styled.Navigation>
						)}
					</Document>
				) : (
					passwordProtectedErrorMessage && <p>{passwordProtectedErrorMessage}</p>
				)}
			</ErrorBoundary>
		</Styled.Root>
	);
};

export default memo(PDFViewer);
