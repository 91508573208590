import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { message } from 'antd';

import API from 'api';
import Box from 'components/Box';
import Spinner from 'components/Spinner';
import { Routes, SettingsRoutes } from 'types';

interface IPaymentUrlPromise {
	url: string;
}
const PAYMENT_LINK_NOT_FOUND = 'Payment link not found';

const getPaymentUrl = async (
	requestFunction: () => Promise<IPaymentUrlPromise>,
	loadingSetter: (newLoadingState: boolean) => void,
	setError: (error: string) => void,
) => {
	try {
		loadingSetter(true);
		const res = await requestFunction();

		if (res?.url) {
			window.location.replace(res.url);
		} else if (res?.url === null) {
			throw new Error(PAYMENT_LINK_NOT_FOUND);
		}
	} catch (e: unknown) {
		if (e instanceof Error) {
			setError(e.message);
		} else {
			const errorMessage = typeof e === 'string' ? e : 'An unknown error occurred';
			setError(errorMessage);
			message.error(errorMessage);
		}
	} finally {
		loadingSetter(false);
	}
};

const ATSManualInvoiceLinkPage = () => {
	const [loadtPaymentUrl, setLoadtPaymentUrl] = useState(false);
	const [error, setError] = useState('');
	const { uuid } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		getPaymentUrl(
			() => API.subscriptionsService.getManualPaymentLink(uuid),
			setLoadtPaymentUrl,
			setError,
		);
	}, [uuid]);

	useEffect(() => {
		if (error) {
			if (error === PAYMENT_LINK_NOT_FOUND) {
				navigate(`${SettingsRoutes.Subscription}?shouldRefresh=true`);
			} else {
				navigate(`${Routes.ATS}${Routes.Finance}`);
			}
		}
	}, [error]);

	return <Box>{loadtPaymentUrl ? <Spinner /> : ''}</Box>;
};

export default ATSManualInvoiceLinkPage;
