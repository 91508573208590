import { FC } from 'react';

type ImageProps = {
	path: string;
	setError: (value: string) => void;
};

export const Image: FC<ImageProps> = ({ path, setError }): JSX.Element | null =>
	path ? (
		<img
			src={`${process.env.REACT_APP_API_URL}/auth/logo/${path}`}
			onError={(e) => {
				setError(e.type);
			}}
			alt={path}
		/>
	) : null;
