import { Link } from 'react-router-dom';

import SelectComponent, { type DefaultOptionType } from 'antd/es/select';
import styled from 'styled-components/macro';

import Box from 'components/Box';
import ButtonComponent from 'components/Button';
import { COLORS } from 'theme';

const { Option: OptionComponent } = SelectComponent;
interface ISelectProps extends DefaultOptionType {
	styles: {
		border: string;
		background: string;
		borderColor: string;
	};
}

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;

		button.ant-btn.ant-btn-default {
			height: 28px;
			padding: 0 16px;
			line-height: 28px;
		}
	`;

	export const BoxWrap = styled(({ isBoxComponent, ...props }) => {
		const Component = isBoxComponent ? Box : 'div';

		return <Component {...props} />;
	})``;

	export const PageLink = styled(Link)`
		display: flex;
		line-height: 28px;
		min-width: 28px;
		font-weight: 500;
		color: ${COLORS.black};
		gap: 4px;
	`;

	export const ButtonLink = styled(Link)`
		display: block;
		line-height: 28px;
		height: 28px;
		max-width: 90px;
		margin: 0 auto;
		font-weight: 500;
		border: 1px solid ${COLORS.lightGray1};
		border-radius: 4px;
		color: ${COLORS.black};
		transition: background-color 0.2s ease;
		gap: 4px;

		&:hover,
		&:focus,
		&:active {
			color: ${COLORS.black};
			background-color: ${COLORS.lightGray2};
		}
	`;

	export const TooltipTitle = styled.div`
		width: calc(100% + 45px);
		position: relative;
		left: -15px;
		padding-left: 15px;
		cursor: pointer;
	`;

	export const Button = styled(ButtonComponent)`
		line-height: 28px;
		height: 28px;
		min-width: 28px;
		padding: 4px 8px;
		font-size: 14px;
		gap: 8px;
	`;

	export const Select = styled(SelectComponent)<ISelectProps>`
		&.ant-select {
			.ant-select-selector {
				width: 120px;
				height: 28px;
				line-height: 28px;
				font-size: 12px;
				${({ styles }) => styles}
				border-radius: 4px;

				.ant-select-selection-item {
					line-height: 28px;
				}
			}
		}
	`;

	export const Option = styled(OptionComponent)``;

	export const AdvancedFilterWrapper = styled.div`
		display: flex;
	`;

	export const Filter = styled.div`
		display: flex;
		align-items: center;
	`;

	export const FilterLabel = styled.div`
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		padding-right: 8px;
		padding-left: 16px;
		white-space: nowrap;
	`;
}
