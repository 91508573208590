import { memo } from 'react';

import { IconType } from '../SVG.types';

function AlertIcon({ fill = 'white', width = '24', height = '24' }: IconType) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 16 16'
			fill='none'
		>
			<path
				d='M8.00001 5.33313C8.3682 5.33313 8.66668 5.63161 8.66668 5.9998V8.66646C8.66668 9.03465 8.3682 9.33313 8.00001 9.33313C7.63182 9.33313 7.33334 9.03465 7.33334 8.66646V5.9998C7.33334 5.63161 7.63182 5.33313 8.00001 5.33313Z'
				fill={fill}
			/>
			<path
				d='M8.00001 10.6665C7.63182 10.6665 7.33334 10.9649 7.33334 11.3331C7.33334 11.7013 7.63182 11.9998 8.00001 11.9998H8.00668C8.37487 11.9998 8.67334 11.7013 8.67334 11.3331C8.67334 10.9649 8.37487 10.6665 8.00668 10.6665H8.00001Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.01883 1.52188C7.31836 1.35324 7.65629 1.26465 8.00002 1.26465C8.34376 1.26465 8.68169 1.35324 8.98122 1.52188C9.28075 1.69051 9.53175 1.9335 9.71002 2.22739L9.71194 2.23056L15.3586 11.6572L15.364 11.6664C15.5386 11.9689 15.6311 12.3117 15.632 12.661C15.633 13.0102 15.5425 13.3536 15.3696 13.657C15.1967 13.9604 14.9473 14.2132 14.6463 14.3903C14.3453 14.5674 14.0032 14.6626 13.654 14.6664L13.6467 14.6665L2.34603 14.6665C1.99683 14.6626 1.65472 14.5674 1.35374 14.3903C1.05276 14.2132 0.803392 13.9604 0.630457 13.657C0.457521 13.3536 0.367042 13.0102 0.368019 12.661C0.368997 12.3117 0.461399 11.9689 0.63603 11.6664L0.641446 11.6572L6.28811 2.23056L6.86001 2.57314L6.29002 2.22739C6.4683 1.9335 6.7193 1.69051 7.01883 1.52188ZM7.43083 2.91757L1.78848 12.337C1.7317 12.4369 1.70167 12.5498 1.70135 12.6647C1.70102 12.7811 1.73118 12.8956 1.78883 12.9967C1.84647 13.0978 1.92959 13.1821 2.02992 13.2411C2.12937 13.2997 2.24229 13.3313 2.35762 13.3331H13.6424C13.7578 13.3313 13.8707 13.2997 13.9701 13.2411C14.0705 13.1821 14.1536 13.0978 14.2112 12.9967C14.2689 12.8956 14.299 12.7811 14.2987 12.6647C14.2984 12.5498 14.2684 12.4369 14.2116 12.3371L8.57002 2.9189L8.56922 2.91757C8.50984 2.8202 8.42648 2.73968 8.32709 2.68372C8.22725 2.62751 8.1146 2.59798 8.00002 2.59798C7.88545 2.59798 7.7728 2.62751 7.67296 2.68372C7.57357 2.73968 7.4902 2.8202 7.43083 2.91757Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(AlertIcon);
