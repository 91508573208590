import styled from 'styled-components/macro';

export namespace Styled {
	export const SearchField = styled.div`
		position: relative;
		max-width: 600px;

		input {
			text-indent: 16px;
		}
	`;

	export const Icon = styled.span`
		position: absolute;
		top: 8px;
		left: 8px;
		z-index: 9;
	`;

	export const Loader = styled.span`
		position: absolute;
		top: 4px;
		right: 6px;
	`;
}
