export const getAllPrices = (values, isUpdate, client) => {
	let shouldCreateNewPrices = !isUpdate;

	const prices = Object.keys(values)?.reduce((acc, cur) => {
		if (cur?.startsWith('price-')) {
			const [, credit, amount, id] = cur.split('-');
			const price = values[cur];
			const priceObj = { credit: +credit, amount: +amount, price: +price, client: client.id };

			if (isUpdate && id) priceObj.id = +id;

			if (!id) {
				shouldCreateNewPrices = true;
			}

			return [...acc, priceObj];
		}

		return acc;
	}, []);

	return { prices, shouldCreateNewPrices };
};

export const getWallet = (values, creditsDefault) => {
	try {
		const wallet = Object.keys(values)?.reduce((acc, cur) => {
			if (cur?.startsWith('credit-')) {
				const [, credit] = cur.split('-');
				const count = values[cur];
				const creditName = creditsDefault?.find((crd) => crd.id === +credit)?.value;

				acc[creditName] = +count;
			}

			return acc;
		}, {});

		return { wallet };
	} catch (error) {
		console.error('error', error);
	}
};

export const convertAllPrices = (data) =>
	data.reduce((acc, cur) => {
		const { amount, credit, price, id } = cur;
		const key = `price-${credit}-${amount}${id ? `-${id}` : ''}`;

		acc[key] = price;

		return acc;
	}, {});

export const convertAllCredits = (data) =>
	data.reduce((acc, cur) => {
		const { count, credit, id } = cur;
		const key = `credit-${credit}${id ? `-${id}` : ''}`;

		acc[key] = count;

		return acc;
	}, {});
