import { createSelector } from 'reselect';

export const createSelectors = (root) => {
	const unregisteredRootState = createSelector([(s) => s[root]], (state) => state.data);

	const getLoading = createSelector([(s) => s[root]], (state) => state.loadingReducer);

	const getErrorMessage = createSelector(unregisteredRootState, (data) => data?.errorMessage);

	const getUser = createSelector(unregisteredRootState, (data) => data?.user);
	const getUserRole = createSelector(unregisteredRootState, (data) => data?.user?.roles[0]);
	const getUserType = createSelector(getUser, (data) => data?.userType);
	const getUserRoles = createSelector(unregisteredRootState, (data) => data?.roles);
	const getQuestionnaire = createSelector(unregisteredRootState, (data) => data?.questionnaire);
	const getQuestionnaireWellcomeState = createSelector(getQuestionnaire, (data) => data?.welcome);
	const getQuestionnaireDetailsState = createSelector(getQuestionnaire, (data) => {
		if (data?.details?.questions) {
			const sortedQD = data?.details?.questions.sort(
				(a, b) => a.orderInQuestionnaire - b.orderInQuestionnaire,
			);

			return {
				questions: sortedQD,
			};
		}

		return data.details;
	});
	const getCurrentQuestionState = createSelector(getQuestionnaire, (data) => data?.currentQuestion);

	const getClientId = createSelector(unregisteredRootState, (data) => data?.user?.clientId);

	const getCountries = createSelector(unregisteredRootState, (data) => data?.countries);

	const getCurrentEmailThread = createSelector(
		unregisteredRootState,
		(data) => data?.emails?.currentEmailThread,
	);

	return {
		unregisteredRootState,
		getLoading,
		getErrorMessage,
		getUser,
		getUserRole,
		getUserType,
		getUserRoles,
		getClientId,
		getCountries,
		getQuestionnaireWellcomeState,
		getQuestionnaireDetailsState,
		getCurrentQuestionState,
		getCurrentEmailThread,
	};
};
