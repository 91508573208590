import React, { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Routes } from 'types';
import { LocalStorageType } from 'types/storage';
import { getFromLocalStorage } from 'utils/helpers';

import UnregisteredLayout from '../../components/UnregisteredLayout';
import ChangePasswordForm from '../../containers/ChangePasswordForm';
import UnregisteredUser from '../../containers/UnregisteredUser';

import * as Styled from './ChangePasswordPage.styled';

type ChangePasswordPageProps = {
	isFinishRegistration?: boolean;
};

export const ChangePasswordPage: FC<ChangePasswordPageProps> = ({
	isFinishRegistration = false,
}) => {
	const { isTempPassword } = getFromLocalStorage(LocalStorageType.User) ?? {};
	const { pathname, search } = useLocation();
	const urlParams = new URLSearchParams(search);

	const isResetPass = pathname.includes(Routes.ResetPass);
	const tempToken = urlParams?.get('tempToken');

	if (!isTempPassword && isFinishRegistration) {
		return <Navigate to={`${Routes.Login}`} />;
	}

	return (
		<UnregisteredLayout>
			<UnregisteredUser
				pageContent={
					<Styled.FormWrapper>
						<Styled.FormTitle>
							{isResetPass ? 'Reset Password' : 'Finish registration'}
						</Styled.FormTitle>
						<ChangePasswordForm isResetPass={isResetPass} tempToken={tempToken} />
					</Styled.FormWrapper>
				}
			/>
		</UnregisteredLayout>
	);
};
