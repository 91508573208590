import React, { FC, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import {
	Chart as ChartJS,
	CategoryScale,
	PointElement,
	Title,
	Tooltip,
	Legend,
	BarElement,
} from 'chart.js';
import { darken } from 'polished';

import { ShowMoreWrapperWrapper } from 'components/ShowMoreWrapper/ShowMoreWrapper';
import Spinner from 'components/Spinner';
import CVIcon from 'components/SVG/CVIcon';
import { IDaysToHire, StatisticPeriodEnum } from 'modules/Common/types';
import { getHtmlLegendPlugin } from 'modules/Common/utils/chartHelpers';
import { STATISTICS_COLORS } from 'modules/Common/utils/StatisticsUtils';

import { options } from './TimeToHire.options';
import { Styled } from './TimeToHire.styled';

ChartJS.register(CategoryScale, PointElement, Title, Tooltip, Legend, BarElement);

type TimeToHireProps = {
	loading: boolean;
	timeToHireYear: IDaysToHire;
	timeToHireMonth: IDaysToHire;
	getTimeToHireMonth: (yearMonth?: string) => void;
	getTimeToHireYear: () => void;
	currentPeriodType: StatisticPeriodEnum;
	currentPeriod: string;
};

const TimeToHire: FC<TimeToHireProps> = ({
	loading,
	timeToHireMonth,
	timeToHireYear,
	getTimeToHireMonth,
	getTimeToHireYear,
	currentPeriod,
	currentPeriodType,
}) => {
	useEffect(() => {
		if (currentPeriodType === StatisticPeriodEnum.Year) {
			getTimeToHireYear();
		} else {
			getTimeToHireMonth(currentPeriod);
		}
	}, [currentPeriod, currentPeriodType]);

	if (loading || (!timeToHireYear && !timeToHireMonth)) {
		return (
			<Styled.Root>
				<Spinner />
			</Styled.Root>
		);
	}

	const currentTimeToHireList: IDaysToHire =
		currentPeriodType === StatisticPeriodEnum.Year ? timeToHireYear : timeToHireMonth;

	if (!currentTimeToHireList?.jobs?.length) {
		return (
			<Styled.Root>
				<Styled.Header>
					<Styled.Title>Time to Hire</Styled.Title>
				</Styled.Header>
				<Styled.NoActiveJobs>
					<Styled.CVIconContainer>
						<CVIcon width='120' height='124' />
					</Styled.CVIconContainer>
					<Styled.NoJobsText>
						Once the candidates start applying for your jobs, the number of applications will be
						displayed here
					</Styled.NoJobsText>
				</Styled.NoActiveJobs>
			</Styled.Root>
		);
	}

	const datasets = currentTimeToHireList.jobs.map((j, idx) => {
		const currentColor =
			STATISTICS_COLORS[idx] ?? darken(0.1, STATISTICS_COLORS[idx % STATISTICS_COLORS.length]);

		return {
			label: j.title,
			borderColor: currentColor,
			backgroundColor: currentColor,
			borderRadius: 4,
			data: [j.daysToHire || 0.5],
		};
	});

	const data = {
		labels: [currentPeriodType === StatisticPeriodEnum.Year ? 'year' : currentPeriod],
		datasets,
	};

	const htmlLegendPlugin = getHtmlLegendPlugin('legendBarStatisticDetails');

	return (
		<Styled.Root>
			<Styled.Header>
				<Styled.Title>Time to Hire</Styled.Title>
			</Styled.Header>
			<Styled.ChartWrapper>
				<Bar options={options} data={data} plugins={[htmlLegendPlugin]} />
			</Styled.ChartWrapper>
			<Styled.LegendWrapper>
				<ShowMoreWrapperWrapper maxHeight={100}>
					<div id='time-to-hire-container'></div>
				</ShowMoreWrapperWrapper>
			</Styled.LegendWrapper>
		</Styled.Root>
	);
};

export default TimeToHire;
