import React, { type FC, useCallback } from 'react';
import { Field, Form } from 'react-final-form';

import { DatePicker, Modal, Select } from 'antd';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import { InvoicesStatusTypesFormEnum } from 'modules/Common/types';
import { DataFormatEnum, IOption } from 'types';
import { getDateAndTime } from 'utils/helpers';
import { requiredFieldValidator } from 'utils/validators';

import { Styled } from './InvoiceStatusModal.styled';

type FormValues = {
	datePaid?: string;
	paymentMethod?: number;
};

type InvoiceStatusModalProps = {
	invoiceId: null | number;
	onCancel: () => void;
	updateInvoiceStatusRequested: (
		invoiceId: number,
		values: { status: InvoicesStatusTypesFormEnum; datePaid?: string; paymentMethod?: number },
		callback?: () => void,
	) => void;
	updateInvoiceCallback?: () => void;
	invoicePaymentMethods: IOption[];
	rootClassName?: string;
	className?: string;
	loading?: boolean;
};

const { Option } = Select;

export const InvoiceStatusModal: FC<InvoiceStatusModalProps> = ({
	invoiceId,
	onCancel,
	updateInvoiceCallback,
	invoicePaymentMethods,
	rootClassName = '',
	className = '',
	loading,
	updateInvoiceStatusRequested,
}) => {
	const dataFormat = DataFormatEnum.UK ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

	const onSubmit = useCallback(
		(values: FormValues) => {
			const updatedValues = {
				...values,
				datePaid: getDateAndTime(values.datePaid, { withSeconds: true }) as string,
				status: InvoicesStatusTypesFormEnum.PaidManually,
			};

			const callback = () => {
				onCancel();
				updateInvoiceCallback?.();
			};

			updateInvoiceStatusRequested(invoiceId as number, updatedValues, callback);
		},
		[invoiceId],
	);

	return (
		<Modal
			open={!!invoiceId}
			footer={null}
			centered
			onCancel={onCancel}
			width='auto'
			rootClassName={rootClassName}
			className={className}
		>
			<Styled.ModalHead>
				<h3>Please fill payment information</h3>
			</Styled.ModalHead>
			<Styled.ModalContent>
				<Form
					onSubmit={onSubmit}
					initialValues={{}}
					autoComplete='off'
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Styled.FieldsBlock>
								<Field name='datePaid' validate={requiredFieldValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='datePaid'
											label='Date Paid'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
											required
										>
											<DatePicker {...input} format={dataFormat} />
										</FieldWrapper>
									)}
								</Field>
							</Styled.FieldsBlock>
							<Styled.FieldsBlock>
								<Field name='paymentMethod' validate={requiredFieldValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='paymentMethod'
											label='Payment Type'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
											required
										>
											<Select
												{...input}
												value={input.value || null}
												placeholder='Select payment type'
											>
												{invoicePaymentMethods?.map((option: IOption) => (
													<Option key={option.id} value={option.id}>
														{option.value}
													</Option>
												))}
											</Select>
										</FieldWrapper>
									)}
								</Field>
							</Styled.FieldsBlock>
							<Styled.ButtonBox>
								<Button type='button' buttonType={ButtonTypes.tertiary} onClick={onCancel}>
									Cancel
								</Button>
								<Button
									type='submit'
									buttonType={ButtonTypes.primary}
									disabled={submitting || loading}
									loading={submitting || loading}
								>
									Save
								</Button>
							</Styled.ButtonBox>
						</form>
					)}
				/>
			</Styled.ModalContent>
		</Modal>
	);
};
