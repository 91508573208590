import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';

export namespace Styled {
	export const Root = styled.section`
		position: relative;
	`;

	export const ControlButtons = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		align-self: flex-end;
		margin: 4px 0;
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
	`;

	export const Button = styled(ButtonComponent)`
		position: absolute;
		top: -64px;
		right: 0;
	`;
}
