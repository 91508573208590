import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Tabs } from 'antd';

import Table from 'components/Table';
import { ClntContext } from 'modules/BackOffice/containers/ClientContextContainer/ClientContextContainer';
import { columns } from 'modules/BackOffice/containers/SupportTickets/SupportTickets.entities';
import { TabsTitleEnum } from 'modules/Common/types';
import { GenericType, Routes } from 'types';

import { Styled } from './ClientContextTicketsPage.styled';

type SupportTickets = {
	data: object[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
};

const ClientContextTicketsPage = () => {
	const { clientId } = useParams();
	const navigate = useNavigate();

	const [tab, setTab] = useState<TabsTitleEnum | string>(TabsTitleEnum.ActiveTab);
	const dispatch = useDispatch();

	const clientStore = useContext(ClntContext)[clientId];
	const loading: GenericType = useSelector(clientStore?.backOfficeSelectors?.backOfficeLoading);
	const tickets: SupportTickets = useSelector(clientStore?.backOfficeSelectors?.getBOTicketsState);
	const getTicketsRequested: (params: { active: boolean; page?: number; size?: number }) => void =
		clientStore?.backOfficeActions?.geTicketsRequested;

	const { pageIndex, pageSize, totalCount, data } = tickets || {};

	const isActiveTab = tab === TabsTitleEnum.ActiveTab;

	const columnsData = columns(isActiveTab, clientId).slice(1);

	const handleChangeTabs = (id: string) => {
		dispatch(getTicketsRequested({ active: isActiveTab, clientId }));
		setTab(id);
	};

	const handleTableChange = useCallback(
		(page: number, size: number) => {
			dispatch(getTicketsRequested({ active: isActiveTab, page, size, clientId }));
		},
		[isActiveTab],
	);

	useEffect(() => {
		dispatch(
			getTicketsRequested({ active: isActiveTab, page: pageIndex, size: pageSize, clientId }),
		);
	}, [tab, clientId]);

	return (
		<>
			<Styled.Head>
				<Styled.Title>Tickets</Styled.Title>
				<Button
					type='primary'
					onClick={() =>
						navigate(`${Routes.BOClientContext}/${clientId}${Routes.CreateSupportTicket}`)
					}
				>
					Create New
				</Button>
			</Styled.Head>
			<Tabs
				defaultActiveKey={TabsTitleEnum.ActiveTab}
				tabBarStyle={{ textTransform: 'uppercase' }}
				onChange={handleChangeTabs}
				items={[
					{
						label: 'Active',
						key: TabsTitleEnum.ActiveTab,
						active: true,
						children: (
							<Table
								pageSize={pageSize}
								current={pageIndex}
								loading={loading?.getTicketsLoad}
								total={totalCount}
								data={data}
								columns={columnsData}
								onChange={handleTableChange}
							/>
						),
					},
					{
						label: 'Resolved',
						key: TabsTitleEnum.ArchiveTab,
						children: (
							<Table
								pageSize={pageSize}
								current={pageIndex}
								loading={loading?.getTicketsLoad}
								total={totalCount}
								data={data}
								columns={columnsData}
								onChange={handleTableChange}
							/>
						),
					},
				]}
			/>
		</>
	);
};

export default ClientContextTicketsPage;
