import React, { Key } from 'react';

import { Space } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import { ColumnsDataType } from 'components/Table/Table.types';
import { IQuestionnaires } from 'modules/Common/types';
import { TStyled } from 'theme';
import { getDateFormat } from 'utils/helpers';

import { Styled } from './QuestionnaireListTable.styled';

type DataTableType = IQuestionnaires & {
	key?: Key;
};

export const columns = (onSend: (id: number) => void): ColumnsDataType => [
	{
		title: 'Title',
		dataIndex: 'title',
		width: '300px',
		key: 'title',
		render: (_: string, { title, latestSent }: Partial<DataTableType>) => {
			return (
				<Styled.Title>
					<Styled.Wrapper isHighlighted={!!latestSent}>{title}</Styled.Wrapper>
				</Styled.Title>
			);
		},
	},
	{
		title: 'Questions',
		dataIndex: 'numberOfQuestions',
		key: 'numberOfQuestions',
		align: 'center',
		render: (_: string, { numberOfQuestions, latestSent }: Partial<DataTableType>) => (
			<Styled.Wrapper isHighlighted={!!latestSent}>
				{numberOfQuestions ? numberOfQuestions : 0}
			</Styled.Wrapper>
		),
	},
	{
		title: 'Date created',
		dataIndex: 'created',
		key: 'created',
		align: 'center',
		render: (_: string, { created, latestSent }: Partial<DataTableType>) => {
			const formattedData = getDateFormat(created) ?? '-';

			return <Styled.Wrapper isHighlighted={!!latestSent}>{formattedData}</Styled.Wrapper>;
		},
	},
	{
		title: 'Actions',
		key: 'action',
		align: 'center',
		width: '176px',
		render: (_: string, { id, latestSent }: Partial<DataTableType>) => (
			<Space size='middle'>
				<TStyled.Button
					buttonType={latestSent ? ButtonTypes.secondary : ButtonTypes.default}
					onClick={() => id && onSend(id)}
				>
					Send
				</TStyled.Button>
			</Space>
		),
	},
];
