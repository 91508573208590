import styled from 'styled-components/macro';

import { COLORS } from 'theme';

type BrandingSwitchItemProps = {
	isItemActive?: boolean;
};

export namespace Styled {
	export const BrandingSwitchWrapper = styled.div`
		display: flex;
		width: 100%;
		padding-top: 18px;
	`;

	export const BrandingSwitchItem = styled.div<BrandingSwitchItemProps>`
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 12px 0;
		cursor: pointer;
		border: 1px solid #1e1e20;
		border-radius: 4px;
		transition: all 0.2s ease;

		${({ isItemActive }) => isItemActive && `color: ${COLORS.blue};`}
		${({ isItemActive }) => isItemActive && `border-color: ${COLORS.blue}; `}
		
		&:hover, &:focus, &:active {
			color: ${COLORS.blue};
			border-color: ${COLORS.blue};
		}
	`;

	export const BrandingSwitchItemTitle = styled.span`
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
	`;
}
