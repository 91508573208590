import React, { type FC } from 'react';

import dayjs from 'dayjs';

import Spinner from 'components/Spinner';
import ClipIcon from 'components/SVG/ClipIcon';
import DownloadIcon from 'components/SVG/DownloadIcon';
import { EmailThreadType } from 'modules/Common/types';
import { getFirstLettersUppercase } from 'modules/Common/utils/commonHelpers';
import { COLORS } from 'theme';
import { handleFileDownload, handleOpenThreadFileDownload } from 'utils/helpers';

import { groupMessagesByDate, isEmptyParagraph } from './EmailThreadComponent.helpers';
import { Styled } from './EmailThreadComponent.styled';

type EmailThreadComponentProps = {
	emailThread: EmailThreadType;
	pwd?: string;
};

const iconColors = {
	user: COLORS.green,
	candidate: COLORS.orange,
};

const EmailThreadComponent: FC<EmailThreadComponentProps> = ({ emailThread, pwd = '' }) => {
	if (!emailThread) return <Spinner fullWidth />;

	const { messages, candidateApplication, user, id: threadId } = emailThread;
	const groupedMessages = groupMessagesByDate(messages, candidateApplication, user);

	const handleDownload = (referenceUuid: string, originalFileName: string) => {
		if (pwd && threadId) {
			handleOpenThreadFileDownload(referenceUuid, originalFileName, {
				threadId,
				pwd,
			});
		} else {
			handleFileDownload(referenceUuid, originalFileName);
		}
	};

	return (
		<Styled.Root>
			<Styled.MessageContainer>
				{Object.entries(groupedMessages).map(([date, emails]) => (
					<Styled.DateSection key={date}>
						<Styled.DateHeader>{dayjs(date).format('MMMM D, YYYY')}</Styled.DateHeader>
						{emails.map((msg) => (
							<>
								<Styled.Message key={msg.id} sender={msg.senderType}>
									<Styled.MessageWrap>
										<Styled.MessageInfoWrap sender={msg.senderType}>
											<Styled.MessageInfo sender={msg.senderType}>
												<Styled.MessageInfoUserIcon bgColor={iconColors[msg.senderType]}>
													{getFirstLettersUppercase(msg.sender)}
												</Styled.MessageInfoUserIcon>
												<Styled.MessageInfoUser>{msg.sender}</Styled.MessageInfoUser>
												<Styled.MessageInfoTime>{msg.time}</Styled.MessageInfoTime>
											</Styled.MessageInfo>
										</Styled.MessageInfoWrap>
										{msg.content && !isEmptyParagraph(msg.content) && (
											<Styled.MessageContent
												sender={msg.senderType}
												dangerouslySetInnerHTML={{ __html: msg.content }}
											/>
										)}
										<>
											{msg.attachments?.map(({ originalFileName, referenceUuid }) => {
												return (
													<Styled.Attachment
														key={referenceUuid}
														onClick={() => handleDownload(referenceUuid, originalFileName)}
													>
														<Styled.AttachmentFileName>
															<ClipIcon fill={COLORS.darkGray2} width='22' height='22' />
															{originalFileName}
														</Styled.AttachmentFileName>
														<DownloadIcon fill={COLORS.darkGray2} width='22' height='22' />
													</Styled.Attachment>
												);
											})}
										</>
									</Styled.MessageWrap>
								</Styled.Message>
							</>
						))}
					</Styled.DateSection>
				))}
			</Styled.MessageContainer>
		</Styled.Root>
	);
};

export default EmailThreadComponent;
