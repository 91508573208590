import { RequestsEnum } from 'types';

import { get, post, put } from '../axiosInstance';

export const getSocialNetworks = (id) => get(`${RequestsEnum.SocialNetworks}/${id}`);

export const createClientSocialNetworks = (data) => post(RequestsEnum.SocialNetworks, data);

export const updateClientSocialNetworks = (data) => put(RequestsEnum.SocialNetworks, data);

export const getATSClientSocialNetwork = async () => get(RequestsEnum.SocialNetworksATS);

export const updateATSClientSocialNetwork = async (data) =>
	put(RequestsEnum.SocialNetworksATS, data);

const socialNetworksService = {
	getSocialNetworks,
	createClientSocialNetworks,
	updateClientSocialNetworks,
	getATSClientSocialNetwork,
	updateATSClientSocialNetwork,
};

export default socialNetworksService;
