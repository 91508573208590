import React, { FC } from 'react';
import { Editor } from 'react-draft-wysiwyg';

import { EditorState, RawDraftContentState, convertFromRaw } from 'draft-js';

import { Styled } from './FieldEditor.styled';
import { FieldEditorProps } from './FieldEditor.types';
import { toolbarBuilder } from './FiledEditor.constants';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const FieldEditor: FC<FieldEditorProps> = ({
	onChange,
	minRaws = 1,
	placeholder,
	defaultValue,
	toolbarValues,
	disabled = false,
}) => {
	const defaultEditorState =
		defaultValue && EditorState.createWithContent(convertFromRaw(JSON.parse(defaultValue)));

	const handleChange = (contentState: RawDraftContentState) => {
		if (contentState) {
			onChange(JSON.stringify(contentState));
		}
	};

	const toolbarOptions = toolbarValues ? Object.keys(toolbarValues).map((i) => i) : [];
	const toolbar = { ...toolbarBuilder(toolbarOptions), ...toolbarValues };

	return (
		<Styled.Root minRaws={minRaws}>
			<Editor
				defaultEditorState={defaultEditorState}
				wrapperClassName='wrapper-class'
				editorClassName='editor-class ant-input'
				toolbarClassName='toolbar-class'
				onContentStateChange={handleChange}
				handlePastedText={() => false}
				toolbar={toolbar}
				placeholder={placeholder}
				readOnly={disabled}
			/>
		</Styled.Root>
	);
};

export default FieldEditor;
