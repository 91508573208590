import { memo } from 'react';

import { IconType } from '../SVG.types';

function ForwardIcon({ width = '11', height = '11', fill = 'white' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g id='icons'>
				<path
					id='Union'
					d='M9.11213 3.63043C9.31636 3.32408 9.73028 3.2413 10.0366 3.44553L14.0242 6.10393C14.065 6.1298 14.1033 6.16035 14.1383 6.19541C14.2656 6.32238 14.3334 6.49331 14.3336 6.6669C14.3337 6.76169 14.3136 6.85728 14.2719 6.94693C14.2396 7.01692 14.1949 7.08187 14.1385 7.13818C14.1035 7.17333 14.0651 7.20395 14.0242 7.22987L10.0366 9.88827C9.73028 10.0925 9.31636 10.0097 9.11213 9.70337C8.90789 9.39701 8.99068 8.9831 9.29703 8.77887L11.465 7.33357H4.66683C3.56226 7.33357 2.66683 8.229 2.66683 9.33357V12.3336C2.66683 12.7018 2.36835 13.0002 2.00016 13.0002C1.63197 13.0002 1.3335 12.7018 1.3335 12.3336V9.33357C1.3335 7.49262 2.82588 6.00023 4.66683 6.00023H11.465L9.29703 4.55493C8.99068 4.3507 8.90789 3.93679 9.11213 3.63043Z'
					fill={fill}
				/>
			</g>
		</svg>
	);
}

export default memo(ForwardIcon);
