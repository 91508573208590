import React, { type FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CheckboxValueType } from 'antd/es/checkbox/Group';
import dayjs from 'dayjs';
import arrayMutators from 'final-form-arrays';
import createDecorator from 'final-form-calculate';
import { pick } from 'lodash';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import Spinner from 'components/Spinner';
import { atsDucks } from 'modules/ATS/ducks';
import { InterviewGuestTypeEnum, InterviewTypesFormEnum, IUser } from 'modules/Common/types';
import { convertContentToHtml } from 'modules/Common/utils/editorHelper';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { GenericType, IOption, Routes } from 'types';
import { getDate, getDateAndTime, isJSONString } from 'utils/helpers';

import { AllowedInterviewFormFields } from '../ArrangeInterview.constants';
import { Styled } from '../ArrangeInterview.styled';
import {
	ArrangeInterviewFormParamsType,
	ArrangeInterviewParamsType,
	IArrangeCandidate,
	IMonthInterviewCountData,
	IOptionArrange,
} from '../ArrangeInterview.types';
import Details from '../Details';
import Guests from '../Guests';

type ArrangeInterviewProps = {
	user: IUser;
	jobId: string;
	interviewId: string;
	interviewData: Partial<ArrangeInterviewFormParamsType>;
	candidate: IArrangeCandidate;
	interviewVideoCount: {
		[key: string]: IMonthInterviewCountData;
	};
	interviewTypes: IOption[];
	interviewUsers: IOptionArrange[];
	interviewJobsList: IArrangeCandidate[];
	getAtsUserInterview: () => void;
	getAtsEditInterviewJobs: (candidateId: number) => void;
	getAtsInterviewType: () => void;
	getAtsInterviewById: (interviewId: string) => void;
	cancelAtsInterviewById: (interviewId: string, cb: () => void) => void;
	updateAtsInterview: (values: Partial<ArrangeInterviewFormParamsType>, cb: () => void) => void;
	getVideoInterviewCount: (dates: string[]) => void;
	resetInterviewCount: () => void;
	handleOpenModal: (monthName: string) => void;
	isEditMode?: boolean;
	loading: GenericType;
};

const ArrangeInterview: FC<ArrangeInterviewProps> = ({
	user,
	jobId,
	interviewId,
	candidate,
	interviewVideoCount,
	interviewData,
	interviewTypes,
	interviewUsers,
	interviewJobsList,
	getAtsEditInterviewJobs,
	getAtsUserInterview,
	getAtsInterviewType,
	getAtsInterviewById,
	cancelAtsInterviewById,
	updateAtsInterview,
	getVideoInterviewCount,
	resetInterviewCount,
	handleOpenModal,
	isEditMode,
	loading,
}) => {
	const navigate = useNavigate();
	const [guestsOptions, setGuestsOptions] = useState<Partial<IOptionArrange>[] | []>([]);
	const [selectedOptions, setSelectedOptions] = useState<number[] | CheckboxValueType[]>([]);

	const { candidateApplicationId, startDate, guests, type } =
		interviewData as unknown as ArrangeInterviewParamsType;

	const handleAddGuests = useCallback(
		(value: Partial<IOptionArrange>) => {
			setGuestsOptions((prevState) => [...prevState, value]);
		},
		[guestsOptions, selectedOptions],
	);

	const handleChangeGuests = useCallback(
		(newValues: number[] | CheckboxValueType[]) => {
			setSelectedOptions(newValues);
		},
		[guests],
	);

	const handleCancelForm = () => {
		navigate(-1);
	};

	const handleDeleteGuest = useCallback(
		(event: FormEvent, value: Partial<IOptionArrange>) => {
			event.preventDefault();

			setGuestsOptions((prevState) => {
				return prevState.filter((option) => option.email !== value?.email);
			});
		},
		[guestsOptions, selectedOptions],
	);

	const handleCancelInterview = useCallback(() => {
		interviewId &&
			cancelAtsInterviewById(interviewId, () => navigate(`${Routes.ATS}${Routes.Interviews}`));
	}, [interviewId]);

	const onSubmit = (values: Partial<ArrangeInterviewFormParamsType>) => {
		const users = guestsOptions
			?.slice()
			.filter((i) => selectedOptions?.includes(i.id as number))
			.map((option: Partial<IOptionArrange>) => ({ id: option.id, email: option.email }));
		const newQuests = guestsOptions?.slice().filter((i) => !i.id);

		const newStartDate = getDateAndTime(values?.startDate);

		const yearMonth = dayjs(values?.startDate).format('YYYY-MM');

		const isVideoOnlineType = values?.interviewType === InterviewTypesFormEnum.VideoOnline;
		const initialTypeWasVideoOnline = type?.id === InterviewTypesFormEnum.VideoOnline;

		if (
			!initialTypeWasVideoOnline &&
			isVideoOnlineType &&
			interviewVideoCount[yearMonth]?.available === 0
		) {
			return handleOpenModal(yearMonth);
		}

		const preparedValues = pick(
			{
				id: +interviewId,
				...values,
				guests: [...users, ...newQuests],
				startDate: newStartDate,
				recordInterview: isVideoOnlineType ? values?.recordInterview : false,
				sendCandidateSmsReminder: isVideoOnlineType ? values?.sendCandidateSmsReminder : false,
				information: isJSONString(values?.information as string)
					? convertContentToHtml(values?.information)?.props?.dangerouslySetInnerHTML.__html
					: values?.information,
			},
			[...AllowedInterviewFormFields, 'id'],
		) as ArrangeInterviewFormParamsType;

		updateAtsInterview(preparedValues, () => navigate(`${Routes.ATS}${Routes.Interviews}`));
	};

	useEffect(() => {
		interviewId && getAtsInterviewById(interviewId);
	}, [interviewId]);

	useEffect(() => {
		candidateApplicationId && getAtsEditInterviewJobs(candidateApplicationId);
	}, [candidateApplicationId]);

	useEffect(() => {
		getAtsUserInterview();
		getAtsInterviewType();
	}, []);

	useEffect(() => {
		if (startDate) {
			const date = getDate(startDate);
			date?.year && date?.month && getVideoInterviewCount([`${date.year}-${date.month}`]);
		}
	}, [startDate]);

	useEffect(() => {
		if (interviewUsers?.length) {
			const otherGuests = guests?.length
				? guests
					.filter(
						(i) => i.type?.value === InterviewGuestTypeEnum.Other && !i.userId && !i.fullName,
					)
					?.map((i) => ({ email: i?.email }))
				: [];

			const filteredOption = interviewUsers.filter((i) => i.id !== user?.id);
			setGuestsOptions(() =>
				otherGuests ? [...filteredOption, ...otherGuests] : [...filteredOption],
			);

			guests?.length &&
				setSelectedOptions(() => guests?.filter((i) => i.userId).map((i) => +i.userId));
		}
	}, [interviewUsers, guests, candidateApplicationId]);

	const formDecorator = useMemo(
		() =>
			createDecorator(
				{
					field: 'startDate',
					updates: (value, _, allValues?: Partial<ArrangeInterviewFormParamsType>) => {
						const isVideoOnlineType =
							allValues?.interviewType === InterviewTypesFormEnum.VideoOnline;
						const interviewDate = getDate(value);
						if (interviewDate && isVideoOnlineType) {
							const { year, month } = interviewDate;
							year && month && getVideoInterviewCount([`${year}-${month}`]);
						} else {
							resetInterviewCount();
						}

						return {};
					},
				},
				{
					field: 'interviewType',
					updates: (value, _, allValues?: Partial<ArrangeInterviewFormParamsType>) => {
						const isVideoOnlineType = value === InterviewTypesFormEnum.VideoOnline;
						const interviewDate = allValues?.startDate && getDate(allValues.startDate);
						if (isVideoOnlineType && interviewDate) {
							const { year, month } = interviewDate;
							year && month && getVideoInterviewCount([`${year}-${month}`]);
						} else {
							resetInterviewCount();
						}

						return {};
					},
				},
			),
		[],
	) as never;

	const initialValues = useMemo(
		() => ({
			...(interviewData ? interviewData : {}),
			startDate: dayjs(startDate),
			interviewTime: dayjs(startDate),
			role: jobId,
			information: interviewData?.information || '',
		}),
		[startDate, interviewData, jobId],
	);

	if (
		loading.getAtsInterviewByIdLoad ||
		loading.getAtsUserInterviewLoad ||
		loading.getAtsEditInterviewJobsListLoad ||
		loading.getAtsInterviewTypeLoad
	) {
		return <Spinner fixed />;
	}

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				autoComplete='off'
				decorators={[formDecorator]}
				mutators={{
					...arrayMutators,
				}}
				render={({ handleSubmit, values, submitting }) => (
					<form onSubmit={handleSubmit}>
						<Details
							jobs={interviewJobsList}
							jobId={jobId}
							values={values}
							interviewTypes={interviewTypes}
							candidates={candidate ? [candidate] : []}
							handleCancelInterview={handleCancelInterview}
							isEditMode={!!isEditMode}
							curCandidate={candidate}
							defaultValues={initialValues}
							loading={loading}
						/>

						<Guests
							options={guestsOptions}
							user={user}
							selectedOptions={selectedOptions}
							curCandidate={candidate}
							guests={guests}
							onAddGuest={handleAddGuests}
							onChangeCheckbox={handleChangeGuests}
							onDeleteGuest={handleDeleteGuest}
						/>

						<Styled.ButtonBox>
							<Button
								type='button'
								buttonType={ButtonTypes.tertiary}
								onClick={() => handleCancelForm()}
							>
								Cancel
							</Button>
							<Button
								type='submit'
								buttonType={ButtonTypes.primary}
								disabled={submitting || !!loading?.updateAtsInterviewLoad}
								loading={submitting || !!loading?.updateAtsInterviewLoad}
							>
								Send Updates
							</Button>
						</Styled.ButtonBox>
					</form>
				)}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		user: unregisteredDucks.unregisteredSelectors.getUser(state),
		interviewTypes: atsDucks.atsSelectors.getInterviewTypes(state),
		interviewJobsList: atsDucks.atsSelectors.getCandidateInterviewJobsList(state),
		interviewUsers: atsDucks.atsSelectors.getInterviewUsers(state),
		candidate: atsDucks.atsSelectors.getInterviewCandidateData(state),
		interviewData: atsDucks.atsSelectors.getInterviewData(state),
		interviewVideoCount: atsDucks.atsSelectors.getInterviewVideoCount(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getAtsInterviewType: atsDucks.atsActions.getAtsInterviewTypeRequested,
		getAtsInterviewById: atsDucks.atsActions.getAtsInterviewByIdRequested,
		cancelAtsInterviewById: atsDucks.atsActions.cancelAtsInterviewByIdRequested,
		getAtsUserInterview: atsDucks.atsActions.getAtsUserInterviewRequested,
		getAtsEditInterviewJobs: atsDucks.atsActions.getAtsEditInterviewJobsListRequested,
		updateAtsInterview: atsDucks.atsActions.updateAtsInterviewRequested,
		getVideoInterviewCount: atsDucks.atsActions.getAtsVideoInterviewCountRequested,
		resetInterviewCount: atsDucks.atsActions.resetInterviewCount,
	},
)(ArrangeInterview);
