import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';

import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';

import Spinner from 'components/Spinner';
import UnImpersonateContainer from 'modules/Common/containers/UnImpersonateContainer';
import { ICurrentSubscription } from 'modules/Common/types';
import { ClientBrandingDataType } from 'modules/Common/types/branding';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { UserRolesType, CookiesType } from 'types';

import ATSLayout from '../../components/ATSLayout';
import { atsDucks } from '../../ducks';

type ATSMainPageProps = {
	clientId: number;
	clientAtsBrandingData: ClientBrandingDataType;
	currentSubscription: ICurrentSubscription;
	roles: UserRolesType;
	getATSBrandingByClientId: (clientId: number) => void;
	getCareerBrandingByClientId: (clientId: number) => void;
	getCurrentSubscriptionInfo: () => void;
};

const ATSMainPage: FC<ATSMainPageProps> = ({
	clientId,
	roles,
	currentSubscription,
	clientAtsBrandingData,
	getATSBrandingByClientId,
	getCareerBrandingByClientId,
	getCurrentSubscriptionInfo,
}) => {
	const isImpersonate = !!Cookies.get(CookiesType.boImpersonateJWT);
	const brandingDataEmpty = isEmpty(clientAtsBrandingData) || !clientAtsBrandingData.id;

	useEffect(() => {
		if (clientId && currentSubscription) {
			getATSBrandingByClientId(clientId);

			if (roles.isAtsSuperUser) {
				getCareerBrandingByClientId(clientId);
			}
		}
	}, [clientId, currentSubscription]);

	useEffect(() => {
		getCurrentSubscriptionInfo();
	}, []);

	if (!currentSubscription || brandingDataEmpty) {
		return <Spinner fullWidth />;
	}

	return (
		<>
			{isImpersonate && <UnImpersonateContainer />}
			<ATSLayout
				currentSubscription={currentSubscription}
				isImpersonate={isImpersonate}
				navigationProps={{
					accentColour: clientAtsBrandingData?.accentColour,
					highlightColourAlternate: clientAtsBrandingData?.highlightColourAlternate,
				}}
			>
				<Outlet />
			</ATSLayout>
		</>
	);
};

export default connect(
	(state) => ({
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
		clientId: unregisteredDucks.unregisteredSelectors.getClientId(state),
		clientAtsBrandingData: atsDucks.atsSelectors.getClientAtsBrandingData(state),
		currentSubscription: atsDucks.atsSelectors.getCurrentSubscriptionState(state),
	}),
	{
		getCurrentSubscriptionInfo: atsDucks.atsActions.getClientSubscriptionRequested,
		getATSBrandingByClientId: atsDucks.atsActions.getATSBrandingByClientId,
		getCareerBrandingByClientId: atsDucks.atsActions.getATSCareerBrandingByClientId,
	},
)(ATSMainPage);
