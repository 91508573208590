import React, { type FC, useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { TablePaginationConfig, SorterResult } from 'antd/lib/table/interface';
import { join } from 'lodash';

import Box from 'components/Box';
import Table from 'components/Table';
import { useMount } from 'hooks';
import SearchFilterSortWrapper from 'modules/Common/components/SearchFilterSortWrapper';
import { DEFAULT_PAGE_SIZE } from 'modules/Common/constants';
import { propsFilter } from 'utils/helpers';

import { backOfficeDucks } from '../../ducks';

import { ClientsFields } from './ClientsList.constants';
import { Styled } from './ClientsList.styled';
import { columns } from './ClientsTable.entities';

type ClientsProps = {
	clients: {
		data: [];
		pageIndex: number;
		pageSize: number;
		totalCount: number;
	};
	getClientsRequested: (params: { page: number; size: number; search?: string }) => void;
	resetClientChangesFormProcess: () => void;
	clientsLoading: boolean;
	creditsLoading: boolean;
};

const ClientsList: FC<ClientsProps> = ({
	clients,
	getClientsRequested,
	resetClientChangesFormProcess,
	clientsLoading,
}) => {
	const { data: clientsData, pageIndex, pageSize, totalCount } = clients || {};
	const [tableParams, setTableParams] = useState({});

	useMount(() => {
		resetClientChangesFormProcess();

		if (!clientsData?.length) {
			getClientsRequested({ page: 0, size: DEFAULT_PAGE_SIZE });
		}
	});

	const handleSearch = (value: string) => {
		setTableParams({ ...tableParams, page: 1, search: value });
	};

	const handleFilter = (value) => {
		setTableParams({ ...tableParams, page: 1, subscriptionFilter: value });
	};

	useEffect(() => {
		const ordersMap = {
			ascend: 'asc',
			descend: 'desc',
		};

		getClientsRequested({
			page: tableParams?.page || 0,
			size: tableParams?.size || DEFAULT_PAGE_SIZE,
			...(tableParams?.search && { search: tableParams?.search }),
			...(tableParams?.subscriptionFilter &&
				tableParams?.subscriptionFilter.length && {
				subscriptionFilter: join(tableParams?.subscriptionFilter, ','),
			}),
			...(tableParams?.sorter?.order &&
				tableParams?.sorter?.field && {
				sort: `${tableParams?.sorter?.field},${
					ordersMap[tableParams?.sorter?.order || 'ascend']
				}`,
			}),
		});
	}, [tableParams]);

	const handleTablePaginationChange = useCallback(
		(page: number, size: number) => {
			setTableParams({
				...tableParams,
				page,
				size,
			});
		},
		[tableParams],
	);

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: unknown,
		sorter: SorterResult<unknown> | SorterResult<unknown>[],
	) => {
		setTableParams({
			...tableParams,
			sorter,
		});
	};

	const filteredData = clientsData?.length && propsFilter(clientsData, ClientsFields);
	const columnsData = columns();

	return (
		<Styled.Root>
			<Box>
				<SearchFilterSortWrapper
					search={{ onSearch: handleSearch, isDebounce: false, placeholder: 'Search a client' }}
					filter={{
						mode: 'multiple',
						options: [
							{
								label: 'Pro',
								value: 'Pro',
							},
							{
								label: 'Trial',
								value: 'Trial',
							},
							{
								label: 'Standard',
								value: 'Standard',
							},
						],
						onFilter: handleFilter,
						label: 'Subscription',
						placeholder: 'Filter',
					}}
				/>
				<br />
				<Table
					data={filteredData}
					columns={columnsData}
					pageSize={pageSize}
					total={totalCount}
					current={pageIndex}
					onTableChange={handleTableChange}
					onChange={handleTablePaginationChange}
					onPageSizeChange={handleTablePaginationChange}
					loading={clientsLoading}
				/>
			</Box>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		clients: backOfficeDucks.backOfficeSelectors.getClientsPaginatedData(state),
		clientsLoading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state).getBoClientsLoad,
	}),
	{
		getClientsRequested: backOfficeDucks.backOfficeActions.getBOClientsRequested,
		resetClientChangesFormProcess: backOfficeDucks.backOfficeActions.resetClientChangesFormProcess,
	},
)(ClientsList);
