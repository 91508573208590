import styled from 'styled-components/macro';

export namespace Styled {
	export const ModalContent = styled.div`
		text-align: center;
	`;

	export const Description = styled.p`
		font-family: 'Raleway';
	`;
}
