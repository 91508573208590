import { Link as LinkComponent } from 'react-router-dom';

import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';
import { COLORS } from 'theme';

interface IStyled {
	isInitTab: boolean;
}

export namespace Styled {
	export const Root = styled.section<IStyled>`
		min-height: 100%;
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: center;

		.client-context-tabs.ant-tabs {
			& > .ant-tabs-nav {
				width: 100%;
				height: 40px;
				margin: 0;
				background-color: ${COLORS.darkBlue};

				.ant-tabs-nav-list {
					width: 100%;
					margin: 0 24px;

					.ant-tabs-tab {
						background-color: ${COLORS.lightGray2};

						.ant-tabs-tab-btn {
							font-weight: 600;
							color: ${COLORS.darkGray2};
						}

						&.ant-tabs-tab-active {
							background-color: ${COLORS.white};

							.ant-tabs-tab-btn {
								color: ${COLORS.black};
							}
						}
					}
				}

				.ant-tabs-nav-add {
					background-color: ${({ isInitTab }) => (isInitTab ? COLORS.white : COLORS.lightGray2)};
					border-bottom: none;
				}

				.ant-tabs-nav-more {
					background-color: ${COLORS.lightGray2};
					border-radius: 0 8px 0 0;
					border-bottom: none;
					box-shadow: -10px 0 15px 0 rgba(99, 111, 122, 0.16);
				}
			}
		}

		h2 {
			margin-bottom: 16px;
		}
	`;
	export const TabsHeaderBg = styled.div`
		height: 40px;
		width: 100%;
		background-color: ${COLORS.darkBlue};
		position: absolute;
		top: 0;
		left: 0;
	`;

	export const Content = styled.main`
		max-width: 1328px;
		width: 100%;
	`;

	export const ClientPageLink = styled(LinkComponent)`
		line-height: 28px;
		height: 28px;
		min-width: 28px;
		font-weight: 500;
		color: ${COLORS.black};
	`;

	export const BoxWrapper = styled.div`
		min-height: 100%;
		padding: 24px 32px;
		background: ${COLORS.white};
		border: 1px solid ${COLORS.lightGray3};
		border-top: none;
		box-shadow: ${COLORS.elevationShadow1};
	`;

	export const SearchField = styled.div`
		position: relative;
		max-width: 600px;
		margin-bottom: 24px;

		input {
			text-indent: 16px;
		}
	`;

	export const Icon = styled.span`
		position: absolute;
		top: 8px;
		left: 8px;
		z-index: 9;
	`;

	export const Button = styled(ButtonComponent)`
		line-height: 28px;
		height: 28px;
		min-width: 28px;
		padding: 4px 8px;
		gap: 8px;
	`;

	export const ButtonLink = styled(LinkComponent)``;

	export const Title = styled.h2``;
}
