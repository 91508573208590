import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Input, notification, Modal, Select, Popconfirm } from 'antd';
import arrayMutators from 'final-form-arrays';
import { pick } from 'lodash';

import Box from 'components/Box';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import FormSwitch from 'components/FormSwitch';
import Spinner from 'components/Spinner';
import CloseIcon from 'components/SVG/CloseIcon';
import { useMount } from 'hooks';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import { commonDucks } from 'modules/Common/ducks';
import {
	IQuestion,
	IQuestionnairesPaginated,
	IQuestionnaireValues,
	SendCandidateQuestionnaireType,
} from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { TStyled } from 'theme/styled';
import { GenericType, IOption, Routes, UserRolesType } from 'types';
import { requiredFieldValidator } from 'utils/validators';

import FormBlockLine from '../../components/FormBlockLine';
import QuestionnaireBlocks from '../../components/QuestionBlocks';
import { MESSAGES } from '../../constants';

import { ItemQuestionnaire } from './ATSQuestionnaire.constants';
import { Styled } from './ATSQuestionnaire.styled';
import CopyQuestionnaireListTable from './CopyQuestionnaireListTable';

type QuestionnaireProps = {
	jobId: number;
	durationData: IOption[];
	questionnaireId?: string;
	candidateIds?: number[] | string[] | string;
	currentQuestionnaire: IQuestionnaireValues;
	questionnaireUsersList: IOption[];
	getQuestionnaireById: (id: number) => void;
	getQuestionnaireUsers: (id?: number) => void;
	getQuestionnaireDurations: () => void;
	getCopyQuestionnaires: (params: { page: number; size: number }) => void;
	copyQuestionnairesData: IQuestionnairesPaginated;
	saveQuestionnaires: (
		values: {
			versionId?: number;
			questions?: IQuestion[];
			restrictedUserIds: number[] | undefined;
			id?: number;
			title?: string;
		},
		cb: (id: number) => void,
		id?: number,
	) => void;
	sendCandidateQuestionnaire: SendCandidateQuestionnaireType;
	deleteQuestionnaires: (id: number, cb: () => void) => void;
	roles: UserRolesType;
	isCopyMode: boolean;
	isEditMode: boolean;
	isSendMode: boolean;
	loading: GenericType;
};

export const ATSQuestionnaire: FC<QuestionnaireProps> = ({
	jobId,
	durationData,
	questionnaireId,
	candidateIds,
	currentQuestionnaire,
	getQuestionnaireById,
	questionnaireUsersList,
	getQuestionnaireUsers,
	getCopyQuestionnaires,
	getQuestionnaireDurations,
	saveQuestionnaires,
	sendCandidateQuestionnaire,
	copyQuestionnairesData,
	deleteQuestionnaires,
	roles,
	isCopyMode,
	isEditMode,
	isSendMode,
	loading,
}) => {
	const navigate = useNavigate();
	const [notificationApi, contextHolder] = notification.useNotification();
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [isRestricted, setIsRestricted] = useState<boolean>(false);

	const initialValues = useMemo(
		() => ({
			title: isEditMode || isCopyMode ? currentQuestionnaire?.title : '',
			questions:
				(isEditMode || isCopyMode) && currentQuestionnaire?.questions?.length
					? currentQuestionnaire?.questions?.map((i) => ({
						duration: i?.duration?.id,
						questionText: i?.questionText,
						description: i?.description,
					  }))
					: [ItemQuestionnaire],
			restrictedUserIds: isEditMode || isCopyMode ? currentQuestionnaire?.restrictedUserIds : [],
		}),
		[currentQuestionnaire],
	);

	const handleOpenModal = useCallback(() => {
		getCopyQuestionnaires({
			page: copyQuestionnairesData?.pageIndex,
			size: copyQuestionnairesData?.pageSize,
		});
		setModalOpen(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setModalOpen(false);
	}, []);

	const handleCopyJob = useCallback((id: number) => {
		id && getQuestionnaireById(id);
		setModalOpen(false);
		navigate(`${Routes.ATS}${Routes.QuestionnairesCopy}/${id}`);
	}, []);

	const handleTableChange = useCallback((page: number, size: number) => {
		getCopyQuestionnaires({ page, size: size ?? copyQuestionnairesData?.pageIndex });
	}, []);

	const handleToggleRestricted = () => {
		setIsRestricted((prevState) => !prevState);
	};

	const handleOpenNotification = () => {
		notificationApi.info({
			message: <h3>Important.</h3>,
			description: MESSAGES.questionnaireOutstandingMessage,
			placement: 'top',
			className: 'questionnaire-notification',
			closeIcon: <CloseIcon />,
			duration: 20,
			style: {
				maxWidth: '700px',
				width: '700px',
				marginLeft: 'auto',
				marginRight: 'auto',
			},
		});
	};

	const handleDeleteQuestionnaire = () => {
		questionnaireId &&
			deleteQuestionnaires(+questionnaireId, () =>
				navigate(`${Routes.ATS}${Routes.Questionnaires}`),
			);
	};

	const onSubmit = (values: IQuestionnaireValues) => {
		const filteredData = {
			...pick(values, ['title', 'questions']),
			restrictedUserIds: isRestricted ? values?.restrictedUserIds : [],
		};

		if (isEditMode) {
			saveQuestionnaires(
				filteredData,
				() => navigate(`${Routes.ATS}${Routes.Questionnaires}`),
				currentQuestionnaire?.id,
			);
		} else if (isSendMode) {
			saveQuestionnaires(filteredData, (id: number) => {
				if (candidateIds?.length) {
					sendCandidateQuestionnaire({ questionnaireId: id, candidateIds }, jobId, () =>
						navigate(-1),
					);
				}
			});
		} else {
			saveQuestionnaires(filteredData, () => navigate(`${Routes.ATS}${Routes.Questionnaires}`));
		}
	};

	useMount(() => {
		getQuestionnaireDurations();

		if (isEditMode) {
			setTimeout(() => handleOpenNotification(), 1000);
		}
	});

	useEffect(() => {
		questionnaireId && (isEditMode || isCopyMode) && getQuestionnaireById(+questionnaireId);

		if (roles?.isAtsSuperUser) {
			isEditMode && questionnaireId
				? getQuestionnaireUsers(+questionnaireId)
				: getQuestionnaireUsers();
		}
	}, [questionnaireId, isEditMode, isCopyMode]);

	useEffect(() => {
		if (isEditMode || isCopyMode) {
			setIsRestricted(!!currentQuestionnaire?.restrictedUserIds?.length);
		}
	}, [currentQuestionnaire, isEditMode, isCopyMode]);

	if (
		loading?.getQuestionnaireByIdLoad ||
		loading?.getQuestionnaireDurationLoad ||
		loading?.getQuestionnaireUsersLoad
	) {
		return <Spinner fixed />;
	}

	return (
		<>
			{!isCopyMode && !isEditMode && (
				<Styled.Button buttonType={ButtonTypes.secondary} type='button' onClick={handleOpenModal}>
					Copy Questionnaire
				</Styled.Button>
			)}
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				autoComplete='off'
				mutators={{
					...arrayMutators,
				}}
				render={({ handleSubmit, dirty, values, form }) => (
					<form onSubmit={handleSubmit}>
						<Box>
							<FormBlockLine>
								<Field name='title' validate={requiredFieldValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='title'
											label='Title'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
											isFixed
										>
											<Input {...input} placeholder='Please enter the questionnaire title' />
										</FieldWrapper>
									)}
								</Field>
								<Styled.ControlButtons>
									{roles?.isAtsSuperUser ? (
										<FormSwitch
											title='Restricted'
											name='restircted'
											value={isRestricted}
											onChange={handleToggleRestricted}
										/>
									) : (
										<div />
									)}
									{isEditMode && (
										<Popconfirm
											placement='bottomRight'
											title='Are you sure? Incomplete questionnaires will still be accessible by candidates. In order to delete it from candidate recall it.'
											onConfirm={() => handleDeleteQuestionnaire()}
											okText='Yes'
											cancelText='Cancel'
											className='questionnaire-popconfirm'
										>
											<TStyled.ButtonCancel
												type='button'
												buttonType={ButtonTypes.tertiary}
												loading={!!loading?.cancelAtsInterviewByIdLoad}
											>
												Delete Questionnaire
											</TStyled.ButtonCancel>
										</Popconfirm>
									)}
								</Styled.ControlButtons>
							</FormBlockLine>
							<>
								{isRestricted && (
									<FormBlockLine>
										<Field name='restrictedUserIds'>
											{({ input, meta }) => (
												<FieldWrapper
													name='restrictedUserIds'
													label='Restricted to'
													errorMessage={meta.submitFailed && meta.touched && meta.error}
												>
													<Select
														{...input}
														mode='multiple'
														defaultValue={[]}
														value={input.value || []}
														options={questionnaireUsersList}
														placeholder='Select Questionnaires'
														autoClearSearchValue
													/>
												</FieldWrapper>
											)}
										</Field>
										<div />
									</FormBlockLine>
								)}
							</>
							<QuestionnaireBlocks form={form} durations={durationData} values={values} />
						</Box>
						<Styled.ButtonBox>
							<ATSThemedButton
								type='submit'
								buttonType={ButtonTypes.primary}
								disabled={!dirty}
								loading={!!loading?.saveQuestionnairesLoad}
							>
								Save
							</ATSThemedButton>
						</Styled.ButtonBox>
					</form>
				)}
			/>
			{!isCopyMode && !isEditMode && (
				<Modal
					title={<h2>Copy Questionnaire</h2>}
					open={modalOpen}
					onCancel={handleCloseModal}
					closeIcon={<CloseIcon />}
					style={{ maxWidth: '820px' }}
					width={'100%'}
					footer={null}
					centered
				>
					<CopyQuestionnaireListTable
						handleCopyJob={handleCopyJob}
						listData={copyQuestionnairesData}
						loading={!!loading?.getCopyQuestionnairesLoad}
						handleTableChange={handleTableChange}
					/>
				</Modal>
			)}
			{isEditMode && contextHolder}
		</>
	);
};

export default connect(
	(state) => ({
		copyQuestionnairesData: commonDucks.commonSelectors.getCopyQuestionnaires(state),
		currentQuestionnaire: commonDucks.commonSelectors.getCurrentQuestionnaire(state),
		questionnaireUsersList: commonDucks.commonSelectors.getQuestionnaireUsersList(state),
		durationData: commonDucks.commonSelectors.getQuestionnaireDurations(state),
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		getQuestionnaireById: commonDucks.commonActions.getQuestionnaireByIdRequested,
		getQuestionnaireUsers: commonDucks.commonActions.getQuestionnaireUsersRequested,
		getCopyQuestionnaires: commonDucks.commonActions.getCopyQuestionnairesRequested,
		getQuestionnaireDurations: commonDucks.commonActions.getQuestionnaireDurationsRequested,
		saveQuestionnaires: commonDucks.commonActions.saveQuestionnairesRequested,
		sendCandidateQuestionnaire: commonDucks.commonActions.sendCandidateQuestionnaireRequested,
		deleteQuestionnaires: commonDucks.commonActions.deleteQuestionnaireByIdRequested,
	},
)(ATSQuestionnaire);
