import { memo } from 'react';

import { IconType } from '../SVG.types';

function EditIcon({ width = '11', height = '11', fill = 'white' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 11 11'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M8.2998 1.0503L1.42516 7.92495L1.15017 9.84985L3.07507 9.57486L9.94972 2.70022L8.2998 1.0503ZM8.54729 0.472829C8.41061 0.336146 8.189 0.336146 8.05232 0.472829L0.95768 7.56747C0.904131 7.62102 0.869395 7.69049 0.858684 7.76546L0.528701 10.0753C0.495703 10.3063 0.693693 10.5043 0.924681 10.4713L3.23456 10.1413C3.30953 10.1306 3.37901 10.0959 3.43255 10.0423L10.5272 2.9477C10.6639 2.81102 10.6639 2.58941 10.5272 2.45273L8.54729 0.472829Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(EditIcon);
