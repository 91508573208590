import React, { type FC, memo, useMemo } from 'react';

import Table from 'components/Table';
import { CurrencyType, GenericType } from 'types';

import { columns } from './OrderCredits.entities';
import { Styled } from './OrderCredits.styled';

type ContractDetailsProps = {
	creditsFields: GenericType[];
	currency: CurrencyType;
	totalPrice: number;
	totalPriceExVat: number;
	vatAmount: number;
	discountAmount?: number;
	clientContextId: string;
};

const Credits: FC<ContractDetailsProps> = ({
	creditsFields,
	currency,
	totalPrice,
	totalPriceExVat,
	vatAmount,
	discountAmount,
	clientContextId,
}) => {
	const TotalPricesData = useMemo(() => {
		const data = [];

		if (discountAmount) {
			data.push({
				id: 0,
				columns: [
					{
						id: 22,
						index: 0,
						content: 'Subscription discount:',
					},
					{
						id: 23,
						index: 1,
						content: `- ${currency}${discountAmount.toFixed(2)}`,
					},
				],
			});
		}

		if (vatAmount) {
			data.push(
				{
					id: 1,
					columns: [
						{
							id: 11,
							index: 0,
							content: 'Total ex VAT:',
						},
						{
							id: 12,
							index: 4,
							content: `${currency}${totalPriceExVat?.toFixed(2)}`,
						},
					],
				},
				{
					id: 2,
					columns: [
						{
							id: 21,
							index: 0,
							content: 'VAT:',
						},
						{
							id: 22,
							index: 4,
							content: `${currency}${(vatAmount ?? 0).toFixed(2)}`,
						},
					],
				},
			);
		}

		return data;
	}, [totalPrice, vatAmount, discountAmount]);

	const columnsData = columns(currency);

	return creditsFields?.length && totalPrice ? (
		<Styled.BoxWrap isBoxComponent={!clientContextId}>
			<h2>Order</h2>
			<Table
				data={creditsFields}
				columns={columnsData}
				pagination={false}
				showDefaultPagination
				{...{ summaryData: TotalPricesData }}
			/>
			<Styled.Line />
			<Styled.TotalPrice>
				<span>Total:</span>
				<span>
					{currency}
					{totalPrice?.toFixed(2)}
				</span>
			</Styled.TotalPrice>
		</Styled.BoxWrap>
	) : null;
};

export default memo(Credits);
