import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';
function ArrowUpArrowDown({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M17.5 3C18.0523 2.99999 18.5 3.4477 18.5 3.99999L18.5001 15H21C21.4045 15 21.7691 15.2437 21.9239 15.6173C22.0787 15.991 21.9931 16.4211 21.7071 16.7071L17.7071 20.7071C17.3166 21.0977 16.6834 21.0977 16.2929 20.7071L12.2929 16.7071C12.0069 16.4211 11.9214 15.991 12.0761 15.6173C12.2309 15.2437 12.5956 15 13 15H15.5001L15.5 4.00002C15.5 3.44774 15.9477 3.00002 16.5 3.00001L17.5 3Z'
				fill={fill}
			/>
			<path
				d='M3.00003 9C2.59557 9 2.23093 8.75636 2.07615 8.38268C1.92137 8.00901 2.00692 7.57889 2.29292 7.29289L5.5 4.08582V4H5.58582L6.29292 3.29289C6.68345 2.90237 7.31661 2.90237 7.70714 3.29289L8.41424 4H8.5V4.08576L11.7071 7.29289C11.9931 7.57889 12.0787 8.00901 11.9239 8.38268C11.7691 8.75636 11.4045 9 11 9H8.5L8.5 20C8.5 20.5523 8.05229 21 7.5 21H6.5C5.94772 21 5.5 20.5523 5.5 20L5.5 9H3.00003Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(ArrowUpArrowDown);
