import React, { useState, type FC } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { Button } from 'antd';

import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import ViewPostTicket from 'modules/BackOffice/containers/ViewPostTicket';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { ITicketDetails } from 'modules/Common/types';
import { GenericType, Routes } from 'types';

import { backOfficeDucks } from '../../ducks';

import { Styled } from './TicketDetailsPage.styled';

type TicketsProps = {
	ticketData: ITicketDetails;
	loading?: GenericType;
	getTicketRequested: (id: string) => void;
};

const TicketDetailsPage: FC<TicketsProps> = ({ ticketData, loading, getTicketRequested }) => {
	const { ticketId, clientId } = useParams();
	const { pathname } = useLocation();
	const [isEditMode, setIsEditMode] = useState(false);
	const isCreateMode = pathname.includes(Routes.CreateSupportTicket);

	useMount(() => {
		if (ticketId && !ticketData) {
			getTicketRequested(ticketId);
		}
	});

	const ticketIsEditable = !!loading || !!ticketData?.jobId;

	return (
		<Styled.Root>
			<Styled.Head>
				<PageNavTitle title='Tickets Details' navigationLink={-1} />
				{!isCreateMode && (
					<Button
						type='default'
						htmlType='button'
						onClick={() => setIsEditMode(!isEditMode)}
						size='large'
						disabled={ticketIsEditable}
					>
						Edit Ticket
					</Button>
				)}
			</Styled.Head>

			<>
				{isCreateMode ? (
					<ViewPostTicket
						isCreateMode={isCreateMode}
						isEditMode={false}
						clientContextId={clientId}
					/>
				) : loading || !ticketData ? (
					<Spinner />
				) : (
					<ViewPostTicket
						ticketData={ticketData}
						isEditMode={isEditMode}
						isCreateMode={isCreateMode}
						clientContextId={clientId}
					/>
				)}
			</>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		ticketData: backOfficeDucks.backOfficeSelectors.getBOTicketDetailsState(state),
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state).getTicketDetailsLoad,
	}),
	{
		getTicketRequested: backOfficeDucks.backOfficeActions.getTicketDetailsRequested,
	},
)(TicketDetailsPage);
