import React from 'react';

// import ButtonComponent from 'components/Button';
// import { ButtonTypes } from 'components/Button/Button.types';
// import DeleteIcon from 'components/SVG/DeleteIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
// import { COLORS } from 'theme';
import { TStyled } from 'theme/styled';

import { DataTableType, TableColumnsType } from './CandidatesApplicationContainer.types';

export const columns = (
	pageLink: string,
	handleDeleteAction: (id: number) => void,
): ColumnsDataType & TableColumnsType => [
	{
		title: 'Candidate',
		dataIndex: 'candidate',
		key: 'candidate',
		render: (_: string, { candidate, candidateApplicationId }: Partial<DataTableType>) => (
			<TStyled.PageLink
			// to={`${pageLink}${Routes.Candidates}/${candidateApplicationId}`}
			>
				<span>{candidate}</span>
			</TStyled.PageLink>
		),
	},
	{
		title: 'Email',
		dataIndex: 'candidateEmail',
		key: 'candidateEmail',
		align: 'center',
		render: (_: string, { candidateEmail }: Partial<DataTableType>) => {
			return <>{candidateEmail}</>;
		},
	},
	{
		title: 'Applied jobs',
		dataIndex: 'jobTitle',
		key: 'jobTitle',
		align: 'center',
		render: (_: string, { jobTitle }: Partial<DataTableType>) => {
			return <>{jobTitle}</>;
		},
	},
	// { // TODO Will be implemented in the ticket BTC-1134 https://bluetownconnect.atlassian.net/wiki/spaces/SD/pages/151453745
	// 	title: 'Action',
	// 	key: 'action',
	// 	align: 'center',
	// 	render: (_: string, { candidateApplicationId }: Partial<DataTableType>) => {
	// 		return (
	// 			<span style={{ display: 'flex', justifyContent: 'center' }}>
	// 				<ButtonComponent
	// 					buttonType={ButtonTypes.default}
	// 					icon={<DeleteIcon fill={COLORS.black} width='16' height='16' />}
	// 					onClick={() => candidateApplicationId && handleDeleteAction(candidateApplicationId)}
	// 					disabled
	// 				/>
	// 			</span>
	// 		);
	// 	},
	// },
];
