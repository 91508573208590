import React, { memo, useState } from 'react';

import { Button, Select } from 'antd';

import { ICandidatesData, IJobsApplied, JobDataType } from 'modules/Common/types';

import { statusOptions } from '../../CandidatesApplication/CandidatesApplication.constants';

import { Styled } from './AppliedJobsPanelMenu.styled';

type AppliedJobsPanelMenuProps = {
	jobVacancies: number[] | [];
	currentCandidate: Partial<ICandidatesData>;
	currentJob: IJobsApplied | null;
	jobsData: Partial<JobDataType>[] | null;
	handleChangeJob: (job: IJobsApplied) => void;
	handleSelectVacancy: (value: number[]) => void;
	handleSave: () => void;
	handleCancel: () => void;
};

const AppliedJobsPanelMenu: React.FC<AppliedJobsPanelMenuProps> = ({
	jobVacancies,
	currentCandidate,
	currentJob,
	jobsData,
	handleChangeJob,
	handleSelectVacancy,
	handleSave,
	handleCancel,
}) => {
	const [open, setOpen] = useState<boolean>(false);

	const onSave = () => {
		handleSave();
		setOpen(false);
	};

	const onCancel = () => {
		handleCancel();
		setOpen(false);
	};

	return (
		<Styled.Root>
			{!!currentCandidate?.jobsApplied?.length && (
				<Styled.MenuList>
					{currentCandidate?.jobsApplied?.map((job) => (
						<Styled.MenuListItem
							key={`${job.jobId}-${job.title}`}
							isActive={job.jobId === currentJob?.jobId}
							onClick={() => handleChangeJob(job)}
						>
							{job.title}
							{job.status && (
								<Styled.Tag>
									{/* TODO Change this on backend */}
									{statusOptions.find((status) => status.value === job.status)?.name}
								</Styled.Tag>
							)}
						</Styled.MenuListItem>
					))}
				</Styled.MenuList>
			)}
			<Styled.SelectField>
				<Styled.Label htmlFor='assign-vacancy'>Assign to vacancy:</Styled.Label>
				<Select
					open={open}
					mode='multiple'
					id='assign-vacancy'
					value={jobVacancies || null}
					onChange={handleSelectVacancy}
					onFocus={() => setOpen(true)}
					dropdownRender={(menu) => (
						<>
							{menu}
							<Styled.ButtonsWrapper style={{ padding: '0 8px 4px' }}>
								<Button type='primary' onClick={onSave} disabled={!jobVacancies?.length}>
									Save
								</Button>
								<Button type='text' onClick={onCancel}>
									Cancel
								</Button>
							</Styled.ButtonsWrapper>
						</>
					)}
					options={jobsData?.map((item) => ({ label: item.title, value: item.id }))}
					placeholder='-Select Vacancy-'
					autoClearSearchValue
				/>

				<div>
					<Styled.Tag>{statusOptions[0].name}</Styled.Tag>
				</div>
			</Styled.SelectField>
		</Styled.Root>
	);
};

export default memo(AppliedJobsPanelMenu);
