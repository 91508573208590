export const ContractFields = [
	'id',
	'contractId',
	'client',
	'btoReference',
	'relatedInvoice',
	'totalPrice',
	'startDate',
	'contractState',
	'invoices',
	'currency',
];
