import React, { useState } from 'react';

import { Input } from 'antd';

import { Fields, GenericType } from '../../types';
import EyeCloseIcon from '../SVG/EyeCloseIcon';
import EyeOpenIcon from '../SVG/EyeOpenIcon';

import { Styled } from './FieldPassword.styled';

export const FieldPassword = (input: GenericType) => {
	const [visible, setVisible] = useState(false);

	const handleToggle = () => {
		setVisible((prevState) => !prevState);
	};

	return (
		<Styled.Field>
			<Input {...input} type={visible ? input?.type : Fields.PASSWORD} placeholder='' />
			<Styled.Button onClick={handleToggle}>
				{visible ? (
					<EyeOpenIcon width={'16'} height={'16'} />
				) : (
					<EyeCloseIcon width={'16'} height={'16'} />
				)}
			</Styled.Button>
		</Styled.Field>
	);
};
