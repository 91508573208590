import styled from 'styled-components/macro';

export namespace Styled {
	export const Main = styled.section`
		h2 {
			margin-bottom: 16px;
		}
	`;

	export const Foot = styled.section`
		label {
			font-size: 16px;
		}
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
		margin-bottom: 32px;
		gap: 16px;
	`;
}
