import React, { FC, ReactElement } from 'react';

import { Styled } from './HeroHeader.styled';

type HeroHeaderProps = {
	width?: string;
	height?: string;
	minHeight?: string;
	maxHeight?: string;
	backgroundImageUrl: string;
	headerBackground?: string;
	children?: ReactElement | ReactElement[] | string;
};

export const HeroHeader: FC<HeroHeaderProps> = ({
	width = '100%',
	height = 'auto',
	minHeight = 'auto',
	maxHeight = 'initial',
	backgroundImageUrl,
	headerBackground,
	children,
}) => {
	return (
		<Styled.HeroImage
			width={width}
			height={height}
			minHeight={minHeight}
			maxHeight={maxHeight}
			backgroundImageUrl={backgroundImageUrl}
			headerBackground={headerBackground}
		>
			{children}
		</Styled.HeroImage>
	);
};
