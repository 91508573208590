import { IEmployeeValues } from 'modules/Common/types/employee';

import { ActionTypes } from './types';

export const createActions = (TYPES: ActionTypes) => {
	const getEmployeeRolesRequested = () => ({
		type: TYPES.GET_EMPLOYEES_ROLES.REQUESTED,
	});
	const getEmployeeStatusesRequested = () => ({
		type: TYPES.GET_EMPLOYEE_STATUSES.REQUESTED,
	});
	const createEmployeeRequested = (data: IEmployeeValues, callback?: () => void) => ({
		type: TYPES.CREATE_EMPLOYEE.REQUESTED,
		payload: data,
		callback,
	});
	const geEmployeesRequested = (params, isSuperUser = false) => ({
		type: TYPES.VIEW_EMPLOYEES_LIST.REQUESTED,
		payload: params,
		isSuperUser,
	});
	const archiveEmployeeRequested = (
		data: { employeeId: number; leaveDate: string },
		callback?: () => void,
	) => ({
		type: TYPES.ARCHIVE_EMPLOYEE.REQUESTED,
		payload: data,
		callback,
	});
	const unArchiveEmployeeRequested = (employeeId: number, callback?: () => void) => ({
		type: TYPES.UNARCHIVE_EMPLOYEE.REQUESTED,
		payload: employeeId,
		callback,
	});
	const changeEmployeeStatusRequested = (
		data: { employeeId: number; employeeStatus: number; startDate?: string; leaveDate?: string },
		callback?: () => void,
	) => ({
		type: TYPES.CHANGE_EMPLOYEE_STATUS.REQUESTED,
		payload: data,
		callback,
	});
	const getEmployeeByIdRequested = (employeeId: string, isSuperUser: boolean) => ({
		type: TYPES.GET_EMPLOYEE_BY_ID.REQUESTED,
		payload: employeeId,
		isSuperUser,
	});
	const resetEmployeeDetails = () => ({
		type: TYPES.RESET_EMPLOYEE_DETAILS,
	});

	return {
		getEmployeeRolesRequested,
		getEmployeeStatusesRequested,
		createEmployeeRequested,
		geEmployeesRequested,
		archiveEmployeeRequested,
		unArchiveEmployeeRequested,
		changeEmployeeStatusRequested,
		getEmployeeByIdRequested,
		resetEmployeeDetails,
	};
};
