import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.div`
		margin: 24px 0;

		h3 {
			margin-bottom: 16px;
		}
	`;

	export const ContentInfo = styled.div`
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
		font-size: 14px;
		gap: 4px;

		h4 {
			margin: 8px 0 0;
		}

		ul {
			display: block;
			list-style-type: disc;
			margin: 0;
			padding: 0;
			margin-block-start: 1em;
			margin-block-end: 1em;
			margin-inline-start: 0;
			margin-inline-end: 0;
			padding-inline-start: 40px;
		}
	`;

	export const LogoWrapper = styled.div`
		max-width: 230px;
		min-height: 52px;
		margin-bottom: 56px;

		svg,
		img {
			width: 100%;
			height: 100%;
		}
	`;

	export const ButtonWrapper = styled.div`
		display: flex;
		justify-content: center;
		width: 100%;
	`;
}
