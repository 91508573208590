import styled from 'styled-components/macro';

import { size } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		display: flex;
		flex-direction: column;
		max-width: ${size.large};
		padding: 24px;
		margin: 0 auto;
		line-height: 24px;
		font-size: 16px;
		gap: 24px;
		text-align: center;
		p {
			margin: 0;
		}
	`;
}
