import { CSSProperties } from 'react';

import styled from 'styled-components/macro';

interface IStyledProps {
	fullWidth: boolean;
	noSpaces: boolean;
	style?: CSSProperties;
}

export namespace S {
	export const Root = styled.div<IStyledProps>`
		display: flex;
		flex-direction: column;
		${({ fullWidth, noSpaces, theme: { colors, size } }) => `
			width: ${fullWidth ? '100%' : 'auto'};
    	max-width: ${fullWidth ? '100%' : size.large};
			margin-top: 0;
			margin-left: ${fullWidth ? '0' : 'auto'};
			margin-right: ${fullWidth ? '0' : 'auto'};
			margin-bottom: ${noSpaces ? '0' : '24px'};
			padding: 24px 32px;
			background: ${colors.white};
			border: 1px solid ${colors.lightGray3};
			box-shadow: ${colors.elevationShadow1};
			gap: 24px;
    `}
		border-radius: 10px;
	`;

	export const Main = styled.div`
		position: relative;
	`;
}
