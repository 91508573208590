import React, { useCallback, useState, MouseEvent, memo } from 'react';

import ArrowDown from 'components/SVG/ArrowDown';
import ArrowUp from 'components/SVG/ArrowUp';
import { INavListItem } from 'modules/Common/types';
import { COLORS } from 'theme';
import { getAcronyms } from 'utils/helpers';

import { S } from './UserMenu.styled';

type UserMenuProps = {
	user: string;
	items: INavListItem[];
	backgroundColor?: string;
	isHrModule?: boolean;
};

const UserMenu: React.FC<UserMenuProps> = ({
	user,
	items,
	isHrModule = false,
	backgroundColor,
}) => {
	const [open, setOpen] = useState(false);
	const acronyms = getAcronyms(user);

	const handleOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		setOpen(true);
	}, []);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);

	return (
		<>
			<S.UserMenu onClick={handleOpen}>
				{acronyms && <S.Acronyms>{acronyms}</S.Acronyms>}
				<S.Name>{user}</S.Name>
				<S.ArrowButton>
					{open ? (
						<ArrowUp fill={COLORS.gray} width='20' height='20' />
					) : (
						<ArrowDown fill={COLORS.gray} width='20' height='20' />
					)}
				</S.ArrowButton>
				<S.MenuList isOpen={open} backgroundColor={backgroundColor} isHrModule={isHrModule}>
					{items.map((item) => (
						<S.Item key={item.id}>
							<S.Link onClick={item.handleClick}>{item.name}</S.Link>
						</S.Item>
					))}
				</S.MenuList>
			</S.UserMenu>
			{open && <S.MenuOverlay isOpen={open} onClick={handleClose} />}
		</>
	);
};

export default memo(UserMenu);
