import { Modal as ModalAntd } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Modal = styled(ModalAntd)`
		.ant-modal-content {
			max-height: 60vh;
			overflow-y: auto;
			padding: 32px 40px;
		}
	`;

	export const ModalHead = styled.div`
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;
		font-size: 16px;
		text-align: center;
	`;

	export const ModalContent = styled.div`
		width: 440px;
		max-width: 100%;
	`;

	export const SMSHistoryItem = styled.div`
		margin-bottom: 24px;
		&:last-child {
			margin-bottom: 0;
		}
	`;

	export const SMSHistoryItemDate = styled.div`
		text-align: center;
		color: ${COLORS.black};
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		margin-bottom: 8px;
	`;

	export const SMSHistoryItemText = styled.pre`
		padding: 8px 12px;
		border-radius: 12px 12px 12px 0px;
		background: rgba(153, 167, 255, 0.3);
		color: ${COLORS.black};
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		white-space: pre-wrap;
		word-break: break-word;
		width: 100%;
		font-family: Inter;
	`;
}
