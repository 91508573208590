import { Radio } from 'antd';
import styled from 'styled-components/macro';

const { Group: RadioGroup } = Radio;

export namespace Styled {
	export const AQRadioField = styled.section`
		margin-top: 32px;

		h4 {
			margin-bottom: 16px;
		}
	`;

	export const RadioField = styled(RadioGroup)`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 16px;
	`;

	export const RadioOption = styled(Radio)`
		font-size: 16px;
	`;
}
