import { UserTypesValueEnum } from 'types';

export const createActions = (TYPES) => {
	const acceptContractByIdRequested = (id, callback, platform = UserTypesValueEnum.ATS) => ({
		type: TYPES.ACCEPT_CONTRACT_BY_ID.REQUESTED,
		payload: id,
		callback,
		platform,
	});
	const getContractsRequested = (params = {}, platform = UserTypesValueEnum.BACKOFFICE) => ({
		type: TYPES.GET_CONTRACTS.REQUESTED,
		payload: params,
		platform,
	});
	const resetContractsState = () => ({ type: TYPES.RESET_CONTRACTS_STATE });
	const getContractStatusesRequested = () => ({ type: TYPES.GET_CONTRACT_STATUSES.REQUESTED });
	const updateContractStatusRequested = (
		contractId,
		data,
		params,
		platform = UserTypesValueEnum.BACKOFFICE,
	) => ({
		type: TYPES.UPDATE_CONTRACT_STATUS.REQUESTED,
		payload: { contractId, data },
		params,
		platform,
	});
	const approvePendingContractRequested = (
		id,
		params,
		cb,
		platform = UserTypesValueEnum.BACKOFFICE,
	) => ({
		type: TYPES.APPROVE_PENDING_CONTRACT.REQUESTED,
		payload: id,
		params,
		cb,
		platform,
	});
	const getPricesByClientIdRequested = (clientId, cb) => ({
		type: TYPES.GET_PRICES_BY_CLIENT_ID.REQUESTED,
		payload: clientId,
		cb,
	});
	const getContractsByClientIdRequested = ({ clientId, params = {} }) => ({
		type: TYPES.GET_CONTRACTS_BY_CLIENT_ID.REQUESTED,
		payload: { clientId, params },
	});
	const getContractByIdRequested = (id, isBO) => ({
		type: TYPES.GET_CONTRACT_BY_ID.REQUESTED,
		payload: { id, isBO },
	});
	const deleteContractByIdRequested = (contractId, cb) => ({
		type: TYPES.DELETE_CONTRACT_BY_ID.REQUESTED,
		payload: contractId,
		cb,
	});
	const getContractVatRequested = () => ({ type: TYPES.GET_CONTRACT_VAT.REQUESTED });
	const getContractReceiptRequested = (
		id,
		isInvoice = false,
		platform = UserTypesValueEnum.ATS,
		callback,
	) => ({
		type: TYPES.GET_CONTRACT_RECEIPT.REQUESTED,
		payload: { platform, id },
		isInvoice,
		callback,
	});

	const getClientByIdRequested = (clientId) => ({
		type: TYPES.GET_CLIENT_BY_ID.REQUESTED,
		payload: clientId,
	});
	const updateClientRequested = (values) => ({
		type: TYPES.UPDATE_CLIENT.REQUESTED,
		payload: values,
	});
	const setJobApplyState = (values) => ({
		type: TYPES.SET_JOB_APPLY_STATE,
		payload: values,
	});
	const getCreditsRequested = () => ({ type: TYPES.GET_CREDITS.REQUESTED });

	const resetJobDetailsState = () => ({
		type: TYPES.RESET_JOB_STATE,
	});
	const getJobByIdRequested = (id) => ({
		type: TYPES.GET_JOB_BY_ID.REQUESTED,
		payload: id,
	});
	const getOpenJobByIdRequested = (id) => ({
		type: TYPES.GET_OPEN_JOB_BY_ID.REQUESTED,
		payload: id,
	});
	const getClientBrandingRequested = (id) => ({
		type: TYPES.GET_CLIENT_BRANDING.REQUESTED,
		payload: id,
	});
	const updateApplicationStatusRequested = (candidateIds, status, jobId, callback) => ({
		type: TYPES.UPDATE_APPLICATION_STATUS.REQUESTED,
		payload: { candidateIds, status, jobId },
		callback,
	});

	const getClientJobsRequested = () => ({
		type: TYPES.GET_CLIENT_JOBS.REQUESTED,
	});
	const getClientUsersRequested = () => ({
		type: TYPES.GET_CLIENT_USERS.REQUESTED,
	});
	const getClientCopyJobsRequested = (params) => ({
		type: TYPES.GET_CLIENT_COPY_JOBS.REQUESTED,
		payload: params,
	});
	const getOpenClientJobsRequested = (id, params) => ({
		type: TYPES.GET_OPEN_CLIENT_JOBS.REQUESTED,
		payload: id,
		params,
	});

	const getInterviewOpenRequested = (id, params) => ({
		type: TYPES.GET_INTERVIEW_OPEN.REQUESTED,
		payload: id,
		params,
	});
	const declineInterviewOpenRequested = (id, params, callback) => ({
		type: TYPES.DECLINE_INTERVIEW_OPEN.REQUESTED,
		payload: id,
		params,
		callback,
	});

	const saveFreeJobRequested = (values, platform, cb) => ({
		type: TYPES.SAVE_FREE_JOB.REQUESTED,
		payload: values,
		platform,
		cb,
	});
	const savePaidJobRequested = (values, platform, cb) => ({
		type: TYPES.SAVE_PAID_JOB.REQUESTED,
		payload: values,
		platform,
		cb,
	});
	const updateFreeJobRequested = (values, platform, cb) => ({
		type: TYPES.UPDATE_FREE_JOB.REQUESTED,
		payload: values,
		platform,
		cb,
	});
	const updatePaidJobRequested = (values, isBoost, platform, callback) => ({
		type: TYPES.UPDATE_PAID_JOB.REQUESTED,
		payload: values,
		isBoost,
		platform,
		callback,
	});

	const applyOnJobRequested = (values, cb, assessment) => ({
		type: TYPES.APPLY_ON_JOB.REQUESTED,
		payload: values,
		cb,
		assessment,
	});
	const applyOnCompanyRequested = (values, justSendQuestion, cb) => ({
		type: TYPES.APPLY_ON_COMPANY.REQUESTED,
		payload: values,
		justSendQuestion,
		cb,
	});

	const getJobTypesRequested = () => ({ type: TYPES.GET_JOB_TYPES.REQUESTED });
	const getJobAssessmentRequested = (jobId, isFreeEndpoint) => ({
		type: TYPES.GET_JOB_ASSESSMENT.REQUESTED,
		payload: { jobId, isFreeEndpoint },
	});
	const getEmploymentTypesRequested = () => ({ type: TYPES.GET_EMPLOYMENT_TYPES.REQUESTED });
	const getEducationTypesRequested = () => ({ type: TYPES.GET_EDUCATION_TYPES.REQUESTED });
	const getEducationTypesByRegionIdRequested = (regionId) => ({
		type: TYPES.GET_EDUCATION_TYPES_BY_REGION_ID.REQUESTED,
		payload: regionId,
	});
	const resetEducationTypes = () => ({ type: TYPES.RESET_EDUCATION_TYPES });
	const getAllBOQuestionnairesRequested = (params) => ({
		type: TYPES.GET_ALL_BO_QUESTIONNAIRES.REQUESTED,
		payload: params,
	});
	const getAllAtsQuestionnairesRequested = (params) => ({
		type: TYPES.GET_ALL_ATS_QUESTIONNAIRES.REQUESTED,
		payload: params,
	});
	const getQuestionnaireUsersRequested = (id) => ({
		type: TYPES.GET_QUESTIONNAIRE_USERS.REQUESTED,
		payload: id ? { videoQuestionnaireId: id } : null,
	});
	const getQuestionnaireDurationsRequested = () => ({
		type: TYPES.GET_QUESTIONNAIRE_DURATION.REQUESTED,
	});
	const getVideoQuestionnaireByCandidateIdRequested = (id) => ({
		type: TYPES.GET_VIDEO_QUESTIONNAIRE_BY_CANDIDATE_ID.REQUESTED,
		payload: id,
	});
	const getVideoQuestionnaireResultRequested = (id) => ({
		type: TYPES.GET_QUESTIONNAIRE_RESULT.REQUESTED,
		payload: id,
	});
	const getQuestionnaireByIdRequested = (params) => ({
		type: TYPES.GET_QUESTIONNAIRE_BY_ID.REQUESTED,
		payload: params,
	});
	const getCopyQuestionnairesRequested = (params) => ({
		type: TYPES.GET_COPY_QUESTIONNAIRES.REQUESTED,
		payload: params,
	});
	const getSendQuestionnairesListRequested = (id, params) => ({
		type: TYPES.GET_SEND_QUESTIONNAIRES_LIST.REQUESTED,
		payload: params,
		id,
	});
	const sendCandidateQuestionnaireRequested = (params, jobId, callback) => ({
		type: TYPES.SEND_CANDIDATE_QUESTIONNAIRE.REQUESTED,
		payload: params,
		jobId,
		callback,
	});
	const resendCandidateQuestionnaireRequested = (params, jobId, callback) => ({
		type: TYPES.RESEND_CANDIDATE_QUESTIONNAIRE.REQUESTED,
		payload: params,
		jobId,
		callback,
	});
	const recallCandidateQuestionnaireRequested = (params, jobId, callback) => ({
		type: TYPES.RECALL_CANDIDATE_QUESTIONNAIRE.REQUESTED,
		payload: params,
		jobId,
		callback,
	});

	const updateCandidateRankRequested = (props) => {
		const { rank, candidateAppId, jobId, atsReducerChanges = false, cb } = props;

		return {
			type: TYPES.UPDATE_CANDIDATE_RANK.REQUESTED,
			payload: rank,
			candidateAppId,
			jobId,
			atsReducerChanges,
			cb,
		};
	};
	const getCandidateByIdRequested = (id, jobId, clientId) => ({
		type: TYPES.GET_CANDIDATE_BY_ID.REQUESTED,
		payload: id,
		jobId,
		clientId,
	});
	const getJobIndustriesRequested = () => ({
		type: TYPES.GET_JOB_INDUSTRIES.REQUESTED,
	});
	const saveQuestionnairesRequested = (values, callback, id) => ({
		type: TYPES.SAVE_QUESTIONNAIRES.REQUESTED,
		payload: values,
		callback,
		id,
	});
	const deleteQuestionnaireByIdRequested = (id, callback) => ({
		type: TYPES.DELETE_QUESTIONNAIRE_BY_ID.REQUESTED,
		payload: id,
		callback,
	});
	const archiveUnarchiveQuestionnaireByIdRequested = (id, params, callback) => ({
		type: TYPES.ARCHIVE_UNARCHIVE_QUESTIONNAIRE_BY_ID.REQUESTED,
		payload: id,
		params,
		callback,
	});
	const getTicketsTypesRequested = () => ({
		type: TYPES.GET_TICKET_TYPES.REQUESTED,
	});
	const getTicketsStatusesRequested = () => ({
		type: TYPES.GET_TICKET_STATUSES.REQUESTED,
	});
	const getSMSCountRequested = () => ({
		type: TYPES.GET_SMS_COUNT.REQUESTED,
	});
	const getSMSOrderPriceRequested = () => ({
		type: TYPES.GET_SMS_ORDER_PRICE.REQUESTED,
	});
	const getVideoInterviewOrderPriceRequested = () => ({
		type: TYPES.GET_VIDEO_INTERVIEW_ORDER_PRICE.REQUESTED,
	});

	const getEmailAutoFilledDataRequested = (values) => ({
		type: TYPES.GET_EMAIL_AUTO_FILLED_DATA.REQUESTED,
		payload: values,
	});
	const getEmailDefaultTemplatesRequested = (isAtsRoute) => ({
		type: TYPES.GET_EMAIL_DEFAULT_TEMPLATES.REQUESTED,
		isAtsRoute,
	});
	const updateEmailDefaultTemplatesRequested = (values, isAtsRoute, callback) => ({
		type: TYPES.UPDATE_EMAIL_DEFAULT_TEMPLATES.REQUESTED,
		payload: values,
		isAtsRoute,
		callback,
	});

	const getQueryParamsRequested = () => ({
		type: TYPES.GET_QUERY_PARAMS,
	});
	const saveSearchQueryParamsRequested = (params) => ({
		type: TYPES.SAVE_SEARCH_QUERY_PARAMS,
		payload: params,
	});
	const deleteAssessmentFormRequested = (data, isBO) => ({
		type: TYPES.DELETE_ASSESSMENT_FORM.REQUESTED,
		payload: { data, isBO },
	});

	return {
		acceptContractByIdRequested,
		getContractsRequested,
		resetContractsState,
		getContractStatusesRequested,
		updateContractStatusRequested,
		approvePendingContractRequested,
		getContractsByClientIdRequested,
		getContractByIdRequested,
		deleteContractByIdRequested,
		getContractVatRequested,
		getContractReceiptRequested,
		getClientByIdRequested,
		updateClientRequested,
		getCreditsRequested,
		getJobByIdRequested,
		getOpenJobByIdRequested,
		saveFreeJobRequested,
		savePaidJobRequested,
		updateFreeJobRequested,
		updatePaidJobRequested,
		getJobTypesRequested,
		getJobAssessmentRequested,
		getEmploymentTypesRequested,
		getEducationTypesRequested,
		getEducationTypesByRegionIdRequested,
		getClientBrandingRequested,
		getClientJobsRequested,
		getClientCopyJobsRequested,
		getClientUsersRequested,
		getOpenClientJobsRequested,
		getInterviewOpenRequested,
		declineInterviewOpenRequested,
		applyOnJobRequested,
		applyOnCompanyRequested,
		setJobApplyState,
		updateApplicationStatusRequested,
		getAllBOQuestionnairesRequested,
		getAllAtsQuestionnairesRequested,
		getQuestionnaireUsersRequested,
		getQuestionnaireByIdRequested,
		getCopyQuestionnairesRequested,
		getSendQuestionnairesListRequested,
		sendCandidateQuestionnaireRequested,
		resendCandidateQuestionnaireRequested,
		recallCandidateQuestionnaireRequested,
		getVideoQuestionnaireByCandidateIdRequested,
		getQuestionnaireDurationsRequested,
		saveQuestionnairesRequested,
		deleteQuestionnaireByIdRequested,
		archiveUnarchiveQuestionnaireByIdRequested,
		updateCandidateRankRequested,
		getCandidateByIdRequested,
		getJobIndustriesRequested,
		getTicketsTypesRequested,
		getTicketsStatusesRequested,
		getVideoQuestionnaireResultRequested,
		getSMSCountRequested,
		getSMSOrderPriceRequested,
		getVideoInterviewOrderPriceRequested,
		getEmailAutoFilledDataRequested,
		getEmailDefaultTemplatesRequested,
		updateEmailDefaultTemplatesRequested,
		getPricesByClientIdRequested,
		getQueryParamsRequested,
		saveSearchQueryParamsRequested,
		resetJobDetailsState,
		resetEducationTypes,
		deleteAssessmentFormRequested,
	};
};
