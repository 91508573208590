import type { FC } from 'react';

import {
	BenefitType,
	CareerBrandingType,
	ClientAddressType,
	JobDataType,
} from 'modules/Common/types';

import { SocialNetworkDataType } from '../../types/socialNetworks';

import { Styled } from './CareerPage.styled';
import CareerPageAddress from './CareerPageAddress';
import CareerPageBenefits from './CareerPageBenefits';
import CareerPageContactForm from './CareerPageContactForm';
import CareerPageHeader from './CareerPageHeader';
import CareerPageJobs from './CareerPageJobs';
import CareerPageMain from './CareerPageMain';
import CareerPageVideo from './CareerPageVideo';

type CareerPageBrandingPickType = Pick<
	CareerBrandingType,
	'clientName' | 'whyOurCompany' | 'highlightColour' | 'logo' | 'backgroundImage' | 'videoLink'
>;

type CareerPageProps = CareerPageBrandingPickType & {
	mode: string;
	clientId?: number;
	benefitsData: BenefitType[];
	jobsData?: JobDataType[];
	socialNetworksData?: SocialNetworkDataType[];
	clientAddress?: ClientAddressType;
	changeBenefits: (benifit: BenefitType[]) => void;
	changevideoLink: (url: string) => void;
	changeClientAddress: () => void;
	onLogoEdit?: () => void;
	onLogoRemove?: () => void;
	changewhyOurCompany: (v: string) => void;
	backgroundImage?: string;
	onBgEdit?: () => void;
	onBgRemove?: () => void;
};

const CareerPage: FC<CareerPageProps> = ({
	clientId,
	clientName,
	logo,
	onLogoEdit,
	whyOurCompany,
	changewhyOurCompany,
	backgroundImage,
	onBgEdit,
	highlightColour,
	onBgRemove,
	benefitsData,
	changeBenefits,
	jobsData,
	videoLink,
	changevideoLink,
	socialNetworksData,
	clientAddress,
	changeClientAddress,
	mode,
}) => {
	return (
		<Styled.Root>
			<CareerPageHeader mode={mode} clientId={clientId} />
			<Styled.ContentWrapper>
				<Styled.ContentInner>
					<CareerPageMain
						mode={mode}
						clientName={clientName}
						whyOurCompany={whyOurCompany}
						changewhyOurCompany={changewhyOurCompany}
						backgroundImage={backgroundImage || '/images/backgroundCareer.png'}
						onBgRemove={onBgRemove}
						onBgEdit={onBgEdit}
						onLogoEdit={onLogoEdit}
						logo={logo}
					/>
					<CareerPageBenefits
						benefitsData={benefitsData}
						changeBenefits={changeBenefits}
						mode={mode}
					/>
					<CareerPageJobs
						mode={mode}
						socialNetworksData={socialNetworksData}
						clientId={clientId}
						jobsData={jobsData}
						highlightColour={highlightColour}
					/>
					<CareerPageVideo mode={mode} videoLink={videoLink} changevideoLink={changevideoLink} />
					{jobsData && (
						<CareerPageContactForm
							jobsData={jobsData.filter((j) => j.title !== 'Unassigned candidates')}
							mode={mode}
							highlightColour={highlightColour}
							clientName={clientName || ''}
						/>
					)}
					<CareerPageAddress
						mode={mode}
						clientAddress={clientAddress}
						changeClientAddress={changeClientAddress}
					/>
				</Styled.ContentInner>
			</Styled.ContentWrapper>
		</Styled.Root>
	);
};

export default CareerPage;
