export const AllowedJobFields = [
	'id',
	'title',
	'active',
	'location',
	'jobIndustry',
	'jobType',
	'salary',
	'applicationEmail',
	'applicationFile',
	'applicationForm',
	'applicationFormAttachment',
	'applicationFormUrl',
	'applicationMethod',
	'cvAndCoverLetter',
	'created',
	'endDate',
	'startDate',
	'education',
	'educationType',
	'employmentType',
	'experienceLevel',
	'jobDescription',
	'jobClass',
	'restricted',
];
