import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function ArrowLeft({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g id='State=Chevron Left'>
				<path
					id='Vector 5 (Stroke)'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M16.792 3.05646C17.2226 3.48503 17.2242 4.18151 16.7956 4.61209L9.40578 12.0364L16.7938 19.4245C17.2234 19.8541 17.2234 20.5506 16.7938 20.9801C16.3642 21.4097 15.6677 21.4097 15.2382 20.9801L7.07413 12.8161C6.64527 12.3872 6.64446 11.6921 7.07233 11.2622L15.2364 3.06008C15.6649 2.6295 16.3614 2.62788 16.792 3.05646Z'
					fill={fill}
				/>
			</g>
		</svg>
	);
}

export default memo(ArrowLeft);
