import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';

import { Routes } from 'types';

import ClientsList from '../../containers/ClientsList';

import { Styled } from './ClientsPage.styled';

export const ClientsPage = () => {
	const navigate = useNavigate();

	return (
		<>
			<Styled.Head>
				<Styled.Title>Clients</Styled.Title>
				<Button
					type='primary'
					onClick={() => navigate(`${Routes.BackOffice}${Routes.ClientCreate}`)}
				>
					Create Client
				</Button>
			</Styled.Head>
			<Styled.Content>
				<ClientsList />
			</Styled.Content>
		</>
	);
};
