import React, { Key } from 'react';
import { Link } from 'react-router-dom';

import { Button, Space, Tooltip } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import ForwardIcon from 'components/SVG/ForwardIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
import Tag from 'modules/Common/components/Tag';
import { HiddenContracts } from 'modules/Common/constants';
import {
	IInvoicesTableValues,
	InvoicesStatusColors,
	InvoicesStatusEnum,
	PaymentStatusValuesEnum,
	InvoicesTypesValuesEnum,
} from 'modules/Common/types';
import { COLORS } from 'theme';
import { CountriesType, CurrencyType, Routes } from 'types';
import { getDateFormat } from 'utils/helpers';

import { Styled } from './FinanceTables.styled';

type DataTableType = IInvoicesTableValues & {
	key?: Key;
};

export const invoiceColumns = (
	handleViewItem: (id: number) => void,
	countries: CountriesType,
): ColumnsDataType => [
	{
		title: 'Invoice',
		dataIndex: 'btoReference',
		key: 'btoReference',
		render: (_: string, { id, btoReference, status }: Partial<DataTableType>) => (
			<Styled.PageLink to={`${Routes.ATS}${Routes.InvoicesView}/${id}`} state={{ invoiceId: id }}>
				<span>{btoReference}</span>
			</Styled.PageLink>
		),
	},
	countries?.isUK
		? {
			title: 'Related contract',
			dataIndex: 'contract',
			key: 'contract',
			align: 'center',
			render: (_: string, { contract, invoiceType }: Partial<DataTableType>) =>
				contract &&
					String(contract) !== '-' &&
					!(invoiceType?.value && HiddenContracts.includes(invoiceType.value)) ? (
						<Link to={`${Routes.ATS}${Routes.ContractsView}/${contract}`}>
							<Button type='default'>View</Button>
						</Link>
					) : (
						<span>-</span>
					),
		  }
		: {},
	{
		title: 'Receipt',
		dataIndex: 'receipt',
		key: 'receipt',
		align: 'center',
		render: (_: string, { status, id }: Partial<DataTableType>) =>
			status === InvoicesStatusEnum.Paid ? (
				<Button type='default' onClick={() => id && handleViewItem(id)}>
					View
				</Button>
			) : (
				<span>-</span>
			),
	},
	{
		title: 'Value',
		dataIndex: 'value',
		key: 'value',
		align: 'center',
		render: (name: string, { invoiceType, premiumPrice, currency }) => {
			const isSubscriptionInvoice = [
				InvoicesTypesValuesEnum.PremiumSubscritionManual,
				InvoicesTypesValuesEnum.PremiumSubscrition,
			].includes(invoiceType?.value);

			const getPriceValue = (initPriceValue: string) => {
				return Number(initPriceValue)
					? `${currency === 'USD' ? CurrencyType.USDollar : CurrencyType.PoundSterling}${Number(
						initPriceValue,
					  )?.toFixed(2)}`
					: 0;
			};

			return getPriceValue(isSubscriptionInvoice ? premiumPrice : name);
		},
	},
	{
		title: 'Due Date',
		dataIndex: 'startDate',
		key: 'startDate',
		align: 'center',
		render: (_: string, { dateDue }: Partial<DataTableType>) => {
			const formattedData = getDateFormat(dateDue) ?? '-';

			return <>{formattedData}</>;
		},
	},
	{
		title: 'Payment Method',
		dataIndex: 'paymentStatus',
		key: 'paymentStatus',
		align: 'center',
		render: (_, { id, paymentStatus, invoiceType }: Partial<DataTableType>) => {
			const isFailed = paymentStatus?.value === PaymentStatusValuesEnum.Failed;
			const isPending = paymentStatus?.value === PaymentStatusValuesEnum.Pending;
			const isPreSuccess = paymentStatus?.value === PaymentStatusValuesEnum.PreSuccess;
			const isSuccess = paymentStatus?.value === PaymentStatusValuesEnum.Success;
			const isPremiumSubscriptionAutoInvoice =
				invoiceType?.value === InvoicesTypesValuesEnum.PremiumSubscrition;

			if (isPremiumSubscriptionAutoInvoice && isFailed) {
				return <span>Failed</span>;
			}
			if (isFailed) {
				return (
					<Link to={`${Routes.ATS}${Routes.InvoicesView}/${id}`}>
						<Button type='default'>Try again</Button>
					</Link>
				);
			}
			if (isPending || isPreSuccess) return <span>Pending</span>;
			if (isSuccess) return <span>Card</span>;

			return <>{paymentStatus}</>;
		},
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		align: 'center',
		render: (_, { status }: Partial<DataTableType>) => (
			<Tag color={String(InvoicesStatusColors[status as keyof typeof InvoicesStatusEnum])}>
				{InvoicesStatusEnum[status as keyof typeof InvoicesStatusEnum]}
			</Tag>
		),
	},
	{
		title: 'Action',
		key: 'action',
		align: 'center',
		width: '54px',
		render: (_, { id, invoiceType }) => {
			const forwardInvoiceDisabled =
				invoiceType?.value === InvoicesTypesValuesEnum.SmsPurchase ||
				invoiceType?.value === InvoicesTypesValuesEnum.InterviewPurchase;

			return (
				<Space size='middle'>
					<Tooltip placement='top' title='Forward Invoice'>
						<Link to={`${Routes.ATS}${Routes.InvoiceForward}/${id}`}>
							<Styled.Button
								buttonType={ButtonTypes.default}
								icon={<ForwardIcon fill={COLORS.black} />}
								disabled={forwardInvoiceDisabled}
							/>
						</Link>
					</Tooltip>
				</Space>
			);
		},
	},
];
