import { get, post } from 'api/axiosInstance';
import { RequestsEnum } from 'types';
import { getTableParams } from 'utils/helpers';

export const getAtsDashboardsEmailUnreadList = async () => {
	return get(RequestsEnum.StatisticsATSDashboardUnreadEmails);
};

export const getBOClientContextDashboardsEmailUnreadList = async (client) =>
	get(`${RequestsEnum.StatisticsBOClientContextUnreadEmails}?client=${client}`);

export const createAtsEmailThread = async (data) => post(`${RequestsEnum.ATSEmailThread}`, data);

export const getAtsEmailThreadById = async (threadId) =>
	get(`${RequestsEnum.ATSEmailThread}/${threadId}`);

export const getAtsEmailTemplates = async () => get(RequestsEnum.ATSEmailTemplate);

export const getEmailThreadOnCandidateOpenPage = async ({ threadId, pwd }) =>
	get(`${RequestsEnum.OpenEmailThread}/${threadId}?pwd=${pwd}`);

export const replyToEmailThreadOnCandidateOpenPage = async ({ threadId, pwd, data }) =>
	post(`${RequestsEnum.OpenEmailThread}/${threadId}${RequestsEnum.Reply}?pwd=${pwd}`, data);

export const replyToExistentEmailThreadInAtsPage = async ({ threadId, data }) =>
	post(`${RequestsEnum.ATSEmailThread}/${threadId}${RequestsEnum.Message}`, data);

export const getAtsInboxEmailInfo = async ({ page, size, search, isInbox }) => {
	const queryParams = getTableParams(page, { size, search });
	const url = isInbox ? RequestsEnum.ATSEmailInbox : RequestsEnum.ATSEmailSent;

	return get(`${url}${queryParams}`);
};

const emailService = {
	getAtsDashboardsEmailUnreadList,
	getBOClientContextDashboardsEmailUnreadList,
	createAtsEmailThread,
	getAtsEmailThreadById,
	getAtsEmailTemplates,
	getEmailThreadOnCandidateOpenPage,
	replyToEmailThreadOnCandidateOpenPage,
	replyToExistentEmailThreadInAtsPage,
	getAtsInboxEmailInfo,
};

export default emailService;
