import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';
import { COLORS } from 'theme';

export namespace Styled {
	export const QuestionContainer = styled.section`
		display: flex;
		flex-direction: column;
		gap: 24px;
		margin-top: 8px;
	`;

	export const QuestionItem = styled.div`
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 16px 24px 32px;
		background: ${COLORS.lightGray3};
		border: 1px solid ${COLORS.lightGray2};
		border-radius: 10px;
		gap: 16px;
	`;

	export const QuestionButtons = styled.div`
		display: flex;
		align-items: center;
		gap: 16px;
	`;

	export const QuestionItemHead = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		h3 {
			line-height: 40px;
		}
	`;

	export const QuestionRow = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		gap: 32px;
	`;

	export const QuestionContent = styled.div`
		width: 100%;
	`;

	export const ButtonBox = styled(ButtonComponent)`
		margin: 24px 0 0;
		padding: 0;
	`;

	export const Button = styled(ButtonComponent)`
		width: 40px;
		height: 40px;
		border: 1px solid ${COLORS.lightGray2};
		box-shadow: ${COLORS.elevationShadow1};
	`;

	export const TextHint = styled.section`
		margin: 24px 0;

		p {
			color: ${COLORS.darkGray2};
		}
	`;
}
