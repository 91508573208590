import React from 'react';

import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { Routes } from 'types';

import PostTicket from '../../containers/PostTicket';

import { Styled } from './ATSCreateTicketPage.styled';

const ATSCreateTicketPage = () => {
	return (
		<>
			<Styled.Head>
				<PageNavTitle
					title={'Create New Ticket'}
					navigationLink={`${Routes.ATS}${Routes.SupportCenter}`}
				/>
			</Styled.Head>
			<Styled.Content>
				<PostTicket />
			</Styled.Content>
		</>
	);
};

export default ATSCreateTicketPage;
