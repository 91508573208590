import React, { FC, useState } from 'react';
import { Field, Form } from 'react-final-form';

import { Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import backOfficeService from 'api/endpoints/backOffice';
import Box from 'components/Box';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import Spinner from 'components/Spinner';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import ImageUploader from 'modules/Common/components/ImageUploader';
import { MESSAGES } from 'modules/Common/constants';
import { ClientInfoType } from 'modules/Common/types';
import { GenericType } from 'types';
import { composeValidators, requiredFieldValidator, textValidator } from 'utils/validators';

import { SettingsPanelMenuList } from '../../ATSSettings.types';

import { Styled } from './CompanyDetailsEdit.styled';

export type CompanyDetailsProps = {
	clientId?: number;
	loading: boolean;
	clientLoading: boolean;
	fields: Partial<ClientInfoType>;
	updateClientRequested: (values: Partial<ClientInfoType>) => void;
	saveCompanyDetailsAction: (values: Partial<ClientInfoType>) => void;
	saveClientCareerBranding: (props: GenericType) => void;
	saveClientAtsBranding: (props: GenericType) => void;
};

const CompanyDetailsEdit: FC<CompanyDetailsProps> = ({
	clientId,
	fields,
	loading,
	clientLoading,
	updateClientRequested,
	saveCompanyDetailsAction,
	saveClientCareerBranding,
	saveClientAtsBranding,
}) => {
	const [errorFileUpload, setErrorFileUpload] = useState('');

	const onSubmit = async (values: Partial<ClientInfoType>) => {
		const { logo } = values;

		if (!logo) {
			setErrorFileUpload(MESSAGES.mandatoryField);
		} else {
			updateClientRequested({
				id: clientId,
				...values,
			});
			saveClientAtsBranding({ client: clientId, logo });
			saveClientCareerBranding({ client: clientId, logo });
		}
	};

	if (!fields.clientName || clientLoading) {
		return <Spinner />;
	}

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={fields}
			render={({ handleSubmit, values, valid }) => (
				<form onSubmit={handleSubmit}>
					<Box>
						<h3>{SettingsPanelMenuList.CompanyDetails}</h3>
						<Styled.UploadField>
							<Field name='logo' validate={composeValidators(requiredFieldValidator)}>
								{({ input, meta }) => (
									<FieldWrapper
										name='logo'
										label='Logo'
										hint='Logo should be on a white or transparent background.'
										errorMessage={errorFileUpload}
									>
										<ImageUploader
											imageUrl={values?.logo}
											inputName={input.name}
											// TODO Move this function into uploadFileService
											uploadFileRequest={backOfficeService.uploadFile}
											onChangeCallback={(value) => {
												saveCompanyDetailsAction({ logo: value });
												input.onChange(value);
											}}
											setErrorFileUpload={setErrorFileUpload}
										/>
									</FieldWrapper>
								)}
							</Field>
						</Styled.UploadField>

						<Styled.FieldBox>
							<Field
								name='clientName'
								validate={composeValidators(requiredFieldValidator, textValidator)}
							>
								{({ input, meta }) => (
									<FieldWrapper
										name='clientName'
										label='Company Name'
										errorMessage={meta.submitFailed && meta.touched && meta.error}
									>
										<Input
											{...input}
											onBlur={(event) => {
												saveCompanyDetailsAction({ [input.name]: event?.target?.value });
											}}
											placeholder='Enter the name'
											autoComplete='off'
										/>
									</FieldWrapper>
								)}
							</Field>
						</Styled.FieldBox>
						<Styled.FieldBox>
							<Field
								name='companyAddress'
								validate={composeValidators(requiredFieldValidator, textValidator)}
							>
								{({ input, meta }) => (
									<FieldWrapper
										name='companyAddress'
										label='Address'
										errorMessage={meta.submitFailed && meta.touched && meta.error}
									>
										<TextArea
											{...input}
											onBlur={(event) => {
												saveCompanyDetailsAction({ [input.name]: event?.target?.value });
											}}
											placeholder='Enter address here'
											autoSize={{ minRows: 6, maxRows: 6 }}
										/>
									</FieldWrapper>
								)}
							</Field>
						</Styled.FieldBox>
					</Box>

					<Styled.ButtonBox>
						<ATSThemedButton
							type='submit'
							buttonType={ButtonTypes.primary}
							disabled={!valid || (!errorFileUpload && !values?.logo && !fields?.logo) || loading}
							loading={loading}
						>
							Save
						</ATSThemedButton>
					</Styled.ButtonBox>
				</form>
			)}
		></Form>
	);
};

export default CompanyDetailsEdit;
