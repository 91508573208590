import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;
	`;

	export const ButtonWrapper = styled.div`
		display: flex;
		align-self: flex-end;
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
		gap: 16px;
	`;

	export const InfoBox = styled.div`
		margin-bottom: 24px;
		padding: 16px;
		background-color: ${COLORS.lightGray3};
		border-radius: 10px;

		h4 {
			margin-bottom: 8px;
			color: ${COLORS.darkGray2};
		}

		p {
			margin-bottom: 0;
			line-height: 24px;
			color: ${COLORS.darkGray2};
		}
	`;

	export const CheckboxWrapper = styled.div`
		display: flex;
		flex-direction: column;
	`;

	export const CheckboxOptions = styled.div`
		display: flex;
		flex-direction: column;
		label {
			font-family: 'Inter';
		}
	`;

	export const AdditionOption = styled.div`
		display: flex;
		padding-left: 24px;
		font-size: 14px;
		line-height: 1.57;

		.ant-btn {
			align-items: center;
			padding: 0;
			height: 22px;
		}
	`;
}
