import React from 'react';

import { Tooltip } from 'antd';

import ButtonComponent, { ButtonTypes } from 'components/Button';
import DownloadIcon from 'components/SVG/DownloadIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
import CandidateDatabaseJobsSelect from 'modules/ATS/components/CandidateDatabaseJobsSelect';
import { COLORS } from 'theme';
import { IOption } from 'types';
import { handleFileDownload } from 'utils/helpers';

import { Styled } from './GravityJobCandidates.styled';
import { DataTableType } from './GravityJobCandidates.types';

export const columns = (
	activeJobsList: IOption[],
	handleAssignCandidateToJobs: (
		applicationId: number,
		prevValues: number[],
		allValues: number[],
		closeDropdown: () => void,
	) => void,
): ColumnsDataType => [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		render: (_: string, { name }: Partial<DataTableType>) => <span>{name}</span>,
	},
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
		render: (_: string, { email }: Partial<DataTableType>) => <span>{email}</span>,
	},
	{
		title: 'Phone number',
		dataIndex: 'phone',
		key: 'phone',
		align: 'center',
		render: (_: string, { phone }: Partial<DataTableType>) => <span>{phone}</span>,
	},
	{
		title: 'Assign to job',
		dataIndex: 'assignToJob',
		key: 'assignToJob',
		align: 'center',
		render: (
			_: string,
			{ id: candidateApplicationId, assignedHistoryJson }: Partial<DataTableType>,
		) => {
			const prevSelected = assignedHistoryJson
				? JSON.parse(assignedHistoryJson)?.map(({ id }) => id)
				: [];

			return (
				<Styled.SelectWrapper>
					<CandidateDatabaseJobsSelect
						activeJobsList={activeJobsList}
						selectedJobs={prevSelected}
						handleSelectJobs={(allValues, closeDropdown) =>
							candidateApplicationId &&
							handleAssignCandidateToJobs(
								candidateApplicationId,
								prevSelected,
								allValues,
								closeDropdown,
							)
						}
						showSearch={true}
					/>
				</Styled.SelectWrapper>
			);
		},
	},
	{
		title: 'Actions',
		dataIndex: 'action',
		align: 'center',
		render: (_: string, { name, cvPath, coverLetterPath }: Partial<DataTableType>) => {
			return (
				<Styled.ActionButtons>
					{cvPath && (
						<Tooltip placement='top' title='Download CV'>
							<ButtonComponent
								buttonType={ButtonTypes.default}
								onClick={() => handleFileDownload(cvPath, `${name}_CV`)}
							>
								CV <DownloadIcon fill={COLORS.black} width='16' height='16' />
							</ButtonComponent>
						</Tooltip>
					)}
					{coverLetterPath && (
						<Tooltip placement='top' title='Download Cover Letter'>
							<ButtonComponent
								buttonType={ButtonTypes.default}
								onClick={() => handleFileDownload(coverLetterPath, `${name}_CL`)}
							>
								CL <DownloadIcon fill={COLORS.black} width='16' height='16' />
							</ButtonComponent>
						</Tooltip>
					)}
				</Styled.ActionButtons>
			);
		},
	},
];
