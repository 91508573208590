import styled from 'styled-components/macro';

import { COLORS } from 'theme';

interface IMenuListItemProps {
	isActive: boolean;
}

export namespace Styled {
	export const Root = styled.ul`
		width: 100%;
	`;

	export const MenuList = styled.ul`
		width: 100%;
		margin-bottom: 40px;
	`;

	export const MenuListItem = styled.li<IMenuListItemProps>`
		display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: start;
		width: 100%;
		height: auto;
		padding: 16px;
		margin: 0;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: left;
		border-radius: 4px 0 0 4px;
		cursor: pointer;
		transition: background-color 0.2s ease;
		gap: 4px;

		background: ${({ isActive }) => (isActive ? COLORS.settingsItemColor : 'none')};
		border-right: ${({ isActive }) => (isActive ? `2px solid ${COLORS.lightBlue1}` : 'none')};

		&:hover {
			background-color: ${COLORS.settingsItemColor};
			border-right: 2px solid ${COLORS.lightBlue1};
		}
	`;

	// TODO move it to Common (CandidateApplicationDetails has the same styles)
	export const Tag = styled.span`
		display: inline-block;
		margin: 0;
		padding: 4px 8px;
		font-weight: 700;
		font-size: 12px;
		line-height: 16px;
		color: #7a86ae;
		background: #f8f9fc;
		border: 1px solid #d4daed;
		border-radius: 4px;
	`;

	export const SelectField = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: start;
		width: 100%;
		padding: 0 16px;
		gap: 12px;
	`;

	export const ButtonsWrapper = styled.div`
		display: flex;
		justify-content: center;
		margin: 16px 0;
		gap: 16px;
	`;

	export const Label = styled.label`
		margin-bottom: 4px;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		text-transform: uppercase;
		color: ${COLORS.darkGray2};
	`;
}
