import React, { memo } from 'react';

import { Button, Tooltip } from 'antd';

import InfoIcon from 'components/SVG/InfoIcon';
import { EmailVariablesEnum } from 'modules/Common/types';

import { Styled } from '../EmailTemplates.styled';

const VariablesMap = {
	[EmailVariablesEnum.CANDIDATE_NAME]: (
		<li key='0'>
			<b>[Candidate Name]</b>: Full name of the candidate.
		</li>
	),
	[EmailVariablesEnum.COMPANY_NAME]: (
		<li key='1'>
			<b>[Company Name]</b>: Name of the company as displayed in Settings → Company Details.
		</li>
	),
	[EmailVariablesEnum.URL]: (
		<li key='2'>
			<b>[URL]</b>: Application Form URL for current vacancy.
		</li>
	),
	[EmailVariablesEnum.YOUR_NAME]: (
		<li key='3'>
			<b>[Your Name]</b>: Your first and last name.
		</li>
	),
	[EmailVariablesEnum.ROLE_NAME]: (
		<li key='4'>
			<b>[Role Name]</b>: Job title selected when creating an email in Messages → Create New
		</li>
	),
	[EmailVariablesEnum.JOB_TITLE]: (
		<li key='5'>
			<b>[Job Title]</b>: Vacancy title.
		</li>
	),
};

const defaultVariables = [
	EmailVariablesEnum.CANDIDATE_NAME,
	EmailVariablesEnum.COMPANY_NAME,
	EmailVariablesEnum.ROLE_NAME,
	EmailVariablesEnum.YOUR_NAME,
];

const AutoFilledInfo = ({ emailVariablesList = defaultVariables }) => {
	return (
		<Styled.InfoBlock>
			<h4>Auto-filled Data</h4>
			<Tooltip
				placement='bottomLeft'
				title={
					<div>
						To ensure seamless autofill in your sent emails, please enter the following data exactly
						as shown:
						<ul>{emailVariablesList.map((variable) => VariablesMap[variable])}</ul>
						<br />
						<div>
							<b>Important Reminder</b>: Only the data enclosed in brackets &quot;[ ]&quot; listed
							above will be auto-filled. Be sure to change other data before sending email.
						</div>
					</div>
				}
				trigger={['click']}
			>
				<Button type='ghost' size='small'>
					<InfoIcon width='20px' height='20px' />
				</Button>
			</Tooltip>
		</Styled.InfoBlock>
	);
};

export default memo(AutoFilledInfo);
