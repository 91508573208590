import Cookies from 'js-cookie';

import axiosInstance, { get } from 'api/axiosInstance';
import { CookiesType, RequestsEnum } from 'types';
import { getTableParams } from 'utils/helpers';

const baseUrl = process.env.REACT_APP_API_URL;

const getClientById = async (clientId) => get(`${RequestsEnum.Client}/${clientId}`);

export const getClientContextClientsList = ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.ClientContext}${queryParams}`);
};

export const getBOClients = ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.BOClient}${queryParams}`);
};

const getClientBranding = async (clientId) => {
	const response = await axiosInstance({
		url: `${baseUrl}/branding/career/client/${clientId}`,
		method: 'GET',
		headers: {
			'X-Authorization': `Bearer ${Cookies.get(CookiesType.JWT)}`,
		},
	});

	return response?.data;
};

const getOpenClientBranding = async (clientId) => {
	const response = await axiosInstance({
		url: `${baseUrl}/open/branding/career/client/${clientId}`,
		method: 'GET',
	});

	return response?.data;
};

const getClientJobs = async () => get(RequestsEnum.JobClient);

const getClientCopyJobs = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.ATSJobCopy}${queryParams}`);
};
const getOpenClientJobs = async (clientId) => get(`${RequestsEnum.JobOpenJobs}/${clientId}`);

const getClientUsers = async () => get(`${RequestsEnum.ClientUsers}`);

const updateClient = async (data) => {
	const response = await axiosInstance({
		url: `${baseUrl}/client`,
		method: 'PUT',
		headers: {
			'X-Authorization': `Bearer ${Cookies.get(CookiesType.JWT)}`,
		},
		data,
	});

	return response?.data;
};

const patchClient = async (data) => {
	const response = await axiosInstance({
		url: `${baseUrl}/client`,
		method: 'PATCH',
		headers: {
			'X-Authorization': `Bearer ${Cookies.get(CookiesType.JWT)}`,
		},
		data,
	});

	return response?.data;
};

export const getClientApplicationNotification = async (id) =>
	get(`${RequestsEnum.ClientApplicationNotification}/${id}`);

export const getSMSCount = async () => get(`${RequestsEnum.ATSSMSCount}`);

const clientsService = {
	getClientById,
	getClientContextClientsList,
	getBOClients,
	updateClient,
	patchClient,
	getClientBranding,
	getClientUsers,
	getClientJobs,
	getClientCopyJobs,
	getOpenClientJobs,
	getOpenClientBranding,
	getClientApplicationNotification,
	getSMSCount,
};

export default clientsService;
