import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function CVViewIcon({ fill = COLORS.darkGray2, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fill={fill}
				d='M9 6a1 1 0 000 2h6a1 1 0 100-2H9zM8 11a1 1 0 011-1h6a1 1 0 110 2H9a1 1 0 01-1-1zM9 14a1 1 0 100 2h3a1 1 0 100-2H9z'
			></path>
			<path
				fill={fill}
				fillRule='evenodd'
				d='M7 2a3 3 0 00-3 3v14a3 3 0 003 3h10a3 3 0 003-3V5a3 3 0 00-3-3H7zM6 5a1 1 0 011-1h10a1 1 0 011 1v14a1 1 0 01-1 1H7a1 1 0 01-1-1V5z'
				clipRule='evenodd'
			></path>
		</svg>
	);
}

export default memo(CVViewIcon);
