import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function UploadIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 12 12'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M4.25 2.96905H5.40469V8.25499C5.40469 8.32374 5.46094 8.37999 5.52969 8.37999H6.46719C6.53594 8.37999 6.59219 8.32374 6.59219 8.25499V2.96905H7.75C7.85469 2.96905 7.9125 2.84874 7.84844 2.76749L6.09844 0.551867C6.08674 0.536927 6.0718 0.524844 6.05475 0.516534C6.03769 0.508225 6.01897 0.503906 6 0.503906C5.98103 0.503906 5.96231 0.508225 5.94525 0.516534C5.92819 0.524844 5.91326 0.536927 5.90156 0.551867L4.15156 2.76593C4.0875 2.84874 4.14531 2.96905 4.25 2.96905ZM11.7188 7.78624H10.7812C10.7125 7.78624 10.6562 7.84249 10.6562 7.91124V10.3175H1.34375V7.91124C1.34375 7.84249 1.2875 7.78624 1.21875 7.78624H0.28125C0.2125 7.78624 0.15625 7.84249 0.15625 7.91124V11.005C0.15625 11.2816 0.379687 11.505 0.65625 11.505H11.3438C11.6203 11.505 11.8438 11.2816 11.8438 11.005V7.91124C11.8438 7.84249 11.7875 7.78624 11.7188 7.78624Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(UploadIcon);
