import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function MapMarkerIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M4.64062 9.12466C4.64062 5.0559 7.93139 1.76514 12.0002 1.76514C16.0689 1.76514 19.3597 5.0559 19.3597 9.12466C19.3597 13.5088 14.7127 19.5542 12.8097 21.8566C12.3892 22.3613 11.6217 22.3613 11.2011 21.8566C9.28764 19.5542 4.64062 13.5088 4.64062 9.12466ZM12.0002 12.0005C13.7421 12.0005 15.1542 10.5884 15.1542 8.84642C15.1542 7.10447 13.7421 5.69234 12.0002 5.69234C10.2582 5.69234 8.84607 7.10447 8.84607 8.84642C8.84607 10.5884 10.2582 12.0005 12.0002 12.0005Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(MapMarkerIcon);
