import React from 'react';

import { Svg, Path } from '@react-pdf/renderer';

const EmailSvg = () => (
	<Svg width='11' height='10' viewBox='0 0 11 10'>
		<Path
			fill='#fff'
			fillRule='evenodd'
			d='M10.084 2.507V7.5c0 .688-.562 1.25-1.25 1.25H2.167c-.688 0-1.25-.562-1.25-1.25v-5c0-.688.562-1.25 1.25-1.25h6.667a1.252 1.252 0 011.25 1.257zm-8.29-.193a.42.42 0 01.373-.23h6.667a.42.42 0 01.372.23L5.5 4.908 1.795 2.314zM1.75 3.3v4.2a.42.42 0 00.417.417h6.667A.42.42 0 009.25 7.5V3.3L5.74 5.758c-.144.1-.335.1-.479 0L1.751 3.3z'
		></Path>
	</Svg>
);

export default EmailSvg;
