import React, { type FC, FormEvent, useState, useEffect, useMemo } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import createDecorator from 'final-form-calculate';
import { pick } from 'lodash';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import { TimeOptions } from 'modules/Common/constants';
import { commonDucks } from 'modules/Common/ducks';
import { IInvoicesDetailedValues, InvoicesStatusEnum } from 'modules/Common/types';
import { CurrencyType, Routes } from 'types';
import { getCurrencySymbolByName, getDateAndTime } from 'utils/helpers';

import { backOfficeDucks } from '../../ducks';
import { getCurrencySymbol } from '../ClientChangesForm/Subscriptions/Subscriptions.helpers';

import {
	AllowedParametersInvoice,
	contractDecorator,
	defaultValues,
	prepareInvoiceValues,
} from './CreateEditInvoice.helpers';
import { Styled } from './CreateEditInvoice.styled';
import { CreateEditInvoiceProps } from './CreateEditInvoice.types';
import Credits from './Credits';
import InvoiceDetails from './InvoiceDetails';

const CreateEditInvoice: FC<CreateEditInvoiceProps> = ({
	clientContextId = '',
	isEditMode,
	invoiceId,
	creditsFields,
	clientsByRegion,
	clientsByRegionList,
	contractsByClient,
	chargeVatPercent,
	currentInvoiceFields,
	currentContractDetails,
	currentClientShortData,
	loading,
	commonLoading,
	getClientsByRegionRequested,
	getCreditsRequested,
	createInvoiceRequested,
	updateInvoiceRequested,
	getContractVatRequested,
	getContractsByClientIdRequested,
	getContractByIdRequested,
	getInvoiceByIdRequested,
	getClientByIdShortRequested,
}) => {
	const navigate = useNavigate();
	const [currency, setCurrency] = useState<string>(CurrencyType.PoundSterling);

	const initialValues = useMemo(
		() => ({
			paymentTermTimeUnit: TimeOptions[0].value,
			chargeVat: false,
			clientId: Number(clientContextId),
			...(isEditMode && currentInvoiceFields && prepareInvoiceValues(currentInvoiceFields)),
		}),
		[currentInvoiceFields, isEditMode],
	);

	const contractLoading =
		commonLoading.getContractsByClientIdLoad || loading.getBackofficeContractByIdLoad;

	const onSubmit = (values: IInvoicesDetailedValues) => {
		const normalizedValues = {
			...pick(values, AllowedParametersInvoice),
			invoiceDate: getDateAndTime(values?.invoiceDate, { withSeconds: true }),
			btoReference: values?.isManualInvoice ? values.btoReference + '_manual' : values.btoReference,
		};

		const invoicesPath = clientContextId
			? `${Routes.BOClientContext}/${clientContextId}${Routes.Invoices}`
			: `${Routes.BackOffice}${Routes.Invoices}`;

		if (isEditMode) {
			updateInvoiceRequested(normalizedValues, () => navigate(invoicesPath));
		} else {
			createInvoiceRequested(normalizedValues, () => navigate(invoicesPath));
		}
	};

	useMount(() => {
		!clientsByRegion?.length && getClientsByRegionRequested(1, { size: 1000 });
		!creditsFields?.length && getCreditsRequested();
		!chargeVatPercent && getContractVatRequested();
		isEditMode && invoiceId && getInvoiceByIdRequested(invoiceId);
	});

	useEffect(() => {
		if (isEditMode && currentInvoiceFields?.currency) {
			setCurrency(getCurrencySymbolByName(currentInvoiceFields.currency));
		}
		if (!isEditMode && currentClientShortData.region) {
			setCurrency(getCurrencySymbol(currentClientShortData.region));
		}
	}, [currentInvoiceFields, currentClientShortData]);

	const handleCancel = (event: FormEvent, reset: () => void) => {
		event.preventDefault();

		reset();
	};

	const formDecorator = useMemo(
		() =>
			createDecorator(
				{
					field: 'clientId',
					updates: (clientId) => {
						if (isEditMode) return {};

						if (clientId) {
							getContractsByClientIdRequested(clientId);

							return new Promise((resolve) =>
								getClientByIdShortRequested(clientId, (data) =>
									resolve({
										contractId: '',
										chargeVat: data?.chargeVatOnContracts || false,
										...defaultValues,
									}),
								),
							);
						}

						return {
							contractId: '',
							chargeVat: false,
							...defaultValues,
						};
					},
				},
				{
					field: 'contractId',
					updates: (contractId) => {
						if (contractId) {
							if (isEditMode) {
								getContractByIdRequested(contractId);
							} else {
								return new Promise((resolve) =>
									getContractByIdRequested(contractId, contractDecorator(resolve)),
								);
							}
						}

						return {};
					},
				},
			),
		[clientsByRegion],
	);

	if (loading.getClientsByRegionLoad || !clientsByRegion?.length) {
		return <Spinner />;
	}

	const submittingForm = !!loading?.createInvoiceLoad || !!loading?.updateInvoiceLoad;
	const submitDisabled =
		isEditMode &&
		(currentInvoiceFields?.invoiceStatus?.name === InvoicesStatusEnum.Paid ||
			currentInvoiceFields?.subscriptionPlanType !== null);

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				decorators={[formDecorator]}
				autoComplete='off'
				render={({ handleSubmit, dirty, form: { reset } }) => (
					<form onSubmit={handleSubmit}>
						<InvoiceDetails
							clientContextId={clientContextId}
							clients={clientsByRegionList}
							contracts={contractsByClient}
							currentContractDetails={currentContractDetails}
							contractsLoading={!!contractLoading}
							isEditMode={isEditMode}
						/>
						<Credits
							clientContextId={clientContextId}
							credits={creditsFields}
							vat={chargeVatPercent}
							contractsLoading={!!contractLoading}
							currency={currency}
						/>
						<Styled.ButtonBox>
							<Styled.Button
								type='button'
								buttonType={ButtonTypes.default}
								onClick={(e) => handleCancel(e, reset)}
							>
								Cancel
							</Styled.Button>
							<Button
								type='submit'
								buttonType={ButtonTypes.primary}
								disabled={!dirty || submittingForm || submitDisabled}
								loading={submittingForm}
							>
								{isEditMode ? 'Update' : 'Send'}
							</Button>
						</Styled.ButtonBox>
					</form>
				)}
			></Form>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		clientsByRegion: backOfficeDucks.backOfficeSelectors.getClientsByRegionState(state),
		currentClientShortData: backOfficeDucks.backOfficeSelectors.getCurrentClientShortData(state),
		clientsByRegionList: backOfficeDucks.backOfficeSelectors.getClientsByRegionOptions(state),
		creditsFields: commonDucks.commonSelectors.getCreditFields(state),
		chargeVatPercent: commonDucks.commonSelectors.getContractVat(state),
		contractsByClient:
			backOfficeDucks.backOfficeSelectors.getContractsByClientIdSelectFields(state),
		currentInvoiceFields: backOfficeDucks.backOfficeSelectors.getCurrentInvoice(state),
		currentContractDetails: backOfficeDucks.backOfficeSelectors.getCurrentContract(state),
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
		commonLoading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		getClientsByRegionRequested: backOfficeDucks.backOfficeActions.getClientsByRegionRequested,
		getCreditsRequested: commonDucks.commonActions.getCreditsRequested,
		createInvoiceRequested: backOfficeDucks.backOfficeActions.createInvoiceRequested,
		updateInvoiceRequested: backOfficeDucks.backOfficeActions.updateInvoiceRequested,
		getContractVatRequested: commonDucks.commonActions.getContractVatRequested,
		getContractsByClientIdRequested:
			backOfficeDucks.backOfficeActions.getBoContractsByClientIdRequested,
		getContractByIdRequested: backOfficeDucks.backOfficeActions.getBackOfficeContractByIdRequested,
		getInvoiceByIdRequested: backOfficeDucks.backOfficeActions.getInvoiceByIdRequested,
		getClientByIdShortRequested: backOfficeDucks.backOfficeActions.getClientByIdShortRequested,
	},
)(CreateEditInvoice);
