import styled from 'styled-components/macro';

export namespace Styled {
	export const ConfirmModalTitle = styled.h3`
		width: 100%;
		text-align: center;
		padding-bottom: 32px;
	`;

	export const ConfirmModalButtons = styled.section`
		width: 100%;
		display: flex;
		justify-content: center;

		button {
			margin-left: 24px;
		}
	`;
}
