import styled from 'styled-components/macro';

import Box from 'components/Box';
import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		.ant-tabs {
			&-tab {
				text-transform: uppercase;
				padding: 5px 16px;

				&.ant-tabs-tab-active {
					.ant-tabs-tab-btn {
						color: ${COLORS.blue};
					}
				}
			}
			.ant-tabs-tab + .ant-tabs-tab {
				margin: 0 0 0 24px;
			}

			.ant-tabs-ink-bar {
				background: ${COLORS.blue};
			}
		}

		.ant-table-cell {
			a {
				height: auto;
				font-weight: 400;
			}

			span {
				word-break: break-word;
			}
		}
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
	`;

	export const InfoBlock = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 24px;
		min-height: 500px;
	`;

	export const BoxWrap = styled(({ isBoxComponent, ...props }) => {
		const Component = isBoxComponent ? Box : 'div';

		const additionalStyle = isBoxComponent ? { padding: '32px 24px' } : {};

		return <Component {...props} style={{ ...props.style, ...additionalStyle }} />;
	})``;
}
