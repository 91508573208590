import React, { FC } from 'react';

import { Modal } from 'antd';

import Button, { ButtonTypes } from 'components/Button';
import CloseIcon from 'components/SVG/CloseIcon';

import { Styled } from './ThanksMessageModal.styled';

type ThanksMessageModalProps = {
	modalOpen: boolean;
	onCloseModal: () => void;
	onSubmit: () => void;
	message: string;
	title: string;
};

export const ThanksMessageModal: FC<ThanksMessageModalProps> = ({
	modalOpen,
	onCloseModal,
	onSubmit,
	message,
	title,
}) => {
	return (
		<Modal
			title={<h2 style={{ textAlign: 'center', fontFamily: 'Raleway' }}>{title}</h2>}
			open={modalOpen}
			onCancel={onCloseModal}
			closeIcon={<CloseIcon />}
			style={{ maxWidth: '820px' }}
			width={'100%'}
			footer={null}
			centered
		>
			<Styled.ModalContent>
				<Styled.Description>{message}</Styled.Description>
				<Button
					buttonType={ButtonTypes.primary}
					onClick={onSubmit}
					isFullWidth
					style={{ fontFamily: 'Inter' }}
				>
					Back to Career Page
				</Button>
			</Styled.ModalContent>
		</Modal>
	);
};
