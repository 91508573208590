import CalendarIcon from 'components/SVG/CalendarIcon';
import CandidatesIcon from 'components/SVG/CandidatesIcon';
import CreditsIcon from 'components/SVG/CreditsIcon';
import DashboardIcon from 'components/SVG/DashboardIcon';
import JobIcon from 'components/SVG/JobIcon';
import MessageIcon from 'components/SVG/MessageIcon';
import MoneyIcon from 'components/SVG/MoneyIcon';
import QuestionIcon from 'components/SVG/QuestionIcon';
import SettingsIcon from 'components/SVG/SettingsIcon';
import SupportIcon from 'components/SVG/SupportIcon';
import { AtsNavigationMenuList, AtsProfileMenuList } from 'modules/Common/types';
import { COLORS } from 'theme';
import { Routes } from 'types';

export const SuperUserMenuListData = (messages?: number) => [
	{
		id: 11,
		name: AtsNavigationMenuList.Dashboard,
		path: `${Routes.ATS}${Routes.Dashboard}`,
		icon: <DashboardIcon fill={COLORS.lightGray2} />,
	},
	{
		id: 12,
		name: AtsNavigationMenuList.Vacancies,
		path: `${Routes.ATS}${Routes.Jobs}`,
		icon: <JobIcon fill={COLORS.lightGray2} />,
	},
	{
		id: 13,
		name: AtsNavigationMenuList.Interviews,
		path: `${Routes.ATS}${Routes.Interviews}`,
		icon: <CalendarIcon fill={COLORS.lightGray2} />,
	},
	{
		id: 14,
		name: AtsNavigationMenuList.Messages,
		path: `${Routes.ATS}${Routes.Messages}`,
		icon: <MessageIcon fill={COLORS.lightGray2} />,
		messages: messages || 0,
	},
	{
		id: 15,
		name: AtsNavigationMenuList.CVDatabase,
		path: `${Routes.ATS}${Routes.Candidates}`,
		icon: <CandidatesIcon fill={COLORS.lightGray2} />,
	},
	{
		id: 16,
		name: AtsNavigationMenuList.Questionnaire,
		path: `${Routes.ATS}${Routes.Questionnaires}`,
		icon: <QuestionIcon fill={COLORS.lightGray2} />,
	},

	{
		id: 17,
		name: AtsNavigationMenuList.Credits,
		path: `${Routes.ATS}${Routes.Credits}`,
		icon: <CreditsIcon fill={COLORS.lightGray2} />,
	},
];

export const FinanceUserMenuListData = (outstandingContracts?: number) => [
	{
		id: 21,
		name: AtsNavigationMenuList.Credits,
		path: `${Routes.ATS}${Routes.Credits}`,
		icon: <CreditsIcon fill={COLORS.lightGray2} />,
	},
	{
		id: 22,
		name: AtsProfileMenuList.Finance,
		path: `${Routes.ATS}${Routes.Finance}`,
		icon: <MoneyIcon fill={COLORS.lightGray2} />,
		outstandingContracts: outstandingContracts || 0,
	},
	{
		id: 23,
		name: AtsProfileMenuList.SupportCenter,
		path: `${Routes.ATS}${Routes.SupportCenter}`,
		icon: <SupportIcon fill={COLORS.lightGray2} />,
	},
];

export const SuperUserProfileMenuListData = (outstandingContracts?: number) => [
	{
		id: 30,
		name: AtsProfileMenuList.SupportCenter,
		path: `${Routes.ATS}${Routes.SupportCenter}`,
		icon: <SupportIcon fill={COLORS.lightGray2} />,
	},
	{
		id: 31,
		name: AtsProfileMenuList.Finance,
		path: `${Routes.ATS}${Routes.Finance}`,
		icon: <MoneyIcon fill={COLORS.lightGray2} />,
		outstandingContracts: outstandingContracts || 0,
	},
	{
		id: 32,
		name: AtsProfileMenuList.Settings,
		path: `${Routes.ATS}${Routes.Settings}`,
		icon: <SettingsIcon fill={COLORS.lightGray2} />,
	},
];

export const FinanceUserProfileMenuListData = () => [
	{
		id: 40,
		name: AtsProfileMenuList.Settings,
		path: `${Routes.ATS}${Routes.Settings}`,
		icon: <SettingsIcon fill={COLORS.lightGray2} />,
	},
];
