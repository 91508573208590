import styled from 'styled-components/macro';

import Button from 'components/Button';
import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;
	`;

	export const CreditsFieldWrapper = styled.div`
		display: flex;
		gap: 16px;
		width: 100%;
		margin-bottom: 32px;
	`;

	export const MonthWrapper = styled.div`
		width: 25%;
	`;

	export const QuantityWrapper = styled.div`
		width: 25%;
	`;

	export const PricePerUnitWrapper = styled.div`
		width: 35%;
	`;

	export const UsageNote = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		margin-top: 8px;
		display: flex;
		align-items: center;
		svg {
			margin-right: 8px;
		}
	`;

	export const Price = styled.div`
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.22px;
		padding-bottom: 5px;
		margin-left: 10px;
		margin-top: 35px;
	`;

	export const Line = styled.div`
		width: 100%;
		height: 1px;
		margin: 32px 0;
		border-bottom: 1px dashed ${COLORS.lightBlue1};
	`;

	export const TotalPriceSmallSize = styled.div`
		display: flex;
		justify-content: space-between;
		margin-bottom: 32px;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
	`;

	export const TotalPrice = styled.div`
		display: flex;
		justify-content: space-between;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;
	`;

	export const FooterWrap = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 32px;
		gap: 16px;
	`;

	export const FooterNotification = styled.div`
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	`;

	export const ButtonIcon = styled(Button)`
		line-height: 28px;
		height: 28px;
		min-width: 28px;
		padding: 4px 8px;
		font-size: 14px;
		gap: 8px;
		margin-top: 33px;
	`;
}
