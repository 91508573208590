import Cookies from 'js-cookie';

import { get, patch, post } from 'api/axiosInstance';
import { AuthRequestsEnum, CookiesType, OpenRequestsEnum, RequestsEnum } from 'types';

export const changePassword = async (isBOUser, data) => {
	const token = Cookies.get(CookiesType.tempJWT);

	const config = {
		headers: { 'X-Authorization': `Bearer ${token}` },
	};

	return patch(
		isBOUser ? RequestsEnum.BOUserSetPassword : RequestsEnum.ATSUserSetPassword,
		data,
		config,
	);
};

export const twoFALogin = async (data, token) => {
	const config = {
		headers: { 'X-Authorization': `Bearer ${token}` },
	};

	return post(AuthRequestsEnum.TwoFALogin, data, config);
};

export const twoFAResendCode = async (token) => {
	const config = {
		headers: { 'X-Authorization': `Bearer ${token}` },
	};

	return post(AuthRequestsEnum.TwoFALoginResendCode, {}, config);
};

export const forgotPassword = async (data) => post(AuthRequestsEnum.ForgotPassword, data);

export const resetPassword = async (data) => post(AuthRequestsEnum.UserResetPassword, data);

export const impersonateUser = async (userId) => post(`/user/${userId}/impersonate`);

export const getLogoBySubdomain = async (params) => get(`${OpenRequestsEnum.Client}/${params}`);

export const login = async (data) => post(AuthRequestsEnum.Login, data);

export const logout = async (data) => post(AuthRequestsEnum.Logout, data);

const authService = {
	getLogoBySubdomain,
	impersonateUser,
	changePassword,
	forgotPassword,
	resetPassword,
	login,
	twoFALogin,
	twoFAResendCode,
	logout,
};

export default authService;
