import styled from 'styled-components/macro';

import { COLORS } from 'theme';

type ActivableBlockProps = {
	isActive: boolean;
};

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0 32px;
	`;
	export const Title = styled.div`
		margin: 40px 0 18px;
		color: ${COLORS.black};
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%;
		text-transform: capitalize;
	`;

	export const Wraper = styled.div`
		border-radius: 4px;
		border: 1px solid ${COLORS.lightGray2};
		background: ${COLORS.lightGray3};
		box-shadow: ${COLORS.elevationShadow};
		height: 468px;
		width: 100%;
		padding: 32px;
		display: flex;
	`;

	export const Content = styled.div`
		border-radius: 4px;
		width: 784px;
		height: 100%;
	`;

	export const VideoComponent = styled.div`
		width: 784px;
		height: 100%;
	`;

	export const DefaultContent = styled.div`
		background: ${COLORS.white};
		display: flex;
		padding: 16px 8px;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		width: 100%;
	`;
	export const DefaultContentMain = styled.div`
		width: 100%;
		height: 100%;
	`;
	export const DefaultContentTitle = styled.div`
		color: ${COLORS.black};
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		text-align: center;
	`;
	export const DefaultContentInfo = styled.div`
		color: ${COLORS.darkGray1};
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		text-align: center;
	`;
	export const DefaultContentQuestion = styled.div`
		margin-top: 100px;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.22px;
		text-align: center;
	`;
	export const DefaultContentQuestionDesc = styled.div`
		width: 100%;
		max-height: 135px;
		margin-top: 4px;
		color: ${COLORS.black};
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		text-align: center;
		overflow-y: auto;
	`;
	export const StartRecordingButton = styled.div``;

	export const Sidebar = styled.div`
		padding-left: 32px;
		overflow-y: auto;
		overflow-x: hidden;
		width: 520px;
	`;
	export const SidebarQuestions = styled.div`
		width: 100%;
	`;
	export const SidebarQuestion = styled.div`
		margin-bottom: 32px;
	`;
	export const SidebarQuestionMain = styled.div<ActivableBlockProps>`
		border-radius: 10px;
		background: ${COLORS.white};
		box-shadow: 0 2px 4px 0 rgba(99, 111, 122, 0.12);
		padding: 8px 24px;
		display: flex;
		max-width: 250px;

		${({ isActive }) => isActive && `border: 1px solid ${COLORS.blue}`}
	`;
	export const SidebarQuestionOrder = styled.span`
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	`;
	export const SidebarQuestionPeriod = styled.span`
		font-size: 16px;
		font-style: normal;
		font-family: Inter;
		font-weight: 600;
		line-height: 24px;
	`;
	export const SidebarQuestionIcon = styled.div`
		margin-right: 8px;
		padding-top: 4px;
	`;
	export const SidebarQuestionTitle = styled.div`
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		padding: 16px 0 8px;
	`;
	export const SidebarQuestionDesc = styled.div`
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
	`;

	export const QuestionsReview = styled.div``;
	export const QuestionsReviewCount = styled.div`
		padding-bottom: 70px;
	`;
	export const QuestionsReviewTitle = styled.div`
		text-align: center;
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		margin-bottom: 24px;
	`;
	export const QuestionAttempts = styled.div`
		display: flex;
	`;
	export const QuestionAttempt = styled.div`
		display: flex;
		flex-direction: column;
		margin-right: 24px;
		gap: 6px;
	`;
	export const QuestionAttemptVideo = styled.div<ActivableBlockProps>`
		display: flex;
		border-radius: 4px;
		overflow: hidden;

		${({ isActive }) => isActive && `outline: 2px solid ${COLORS.blue}`}
	`;
	export const QuestionAttemptControl = styled.div`
		display: flex;
		align-items: center;
	`;
	export const QuestionAttemptInfo = styled.div`
		padding-left: 8px;
	`;
	export const QuestionAttemptsAction = styled.div`
		padding-top: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	`;
	export const QuestionAttemptsActionRetake = styled.div`
		margin-right: 24px;
	`;

	export const QuestionUploadingWrapper = styled.div`
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	`;
	export const QuestionUploadingInner = styled.div`
		width: 100%;
	`;
	export const QuestionUploadingTitle = styled.div`
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		margin-bottom: 8px;
		text-align: center;
	`;
	export const QuestionUploadingDesc = styled.div`
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		text-align: center;
	`;
	export const UploadingProgress = styled.div`
		width: 100%;
		margin-top: 40px;
	`;

	export const CompletedWrapper = styled.div`
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	`;
	export const CompletedInner = styled.div`
		width: 100%;
	`;
	export const CompletedIcon = styled.div`
		display: flex;
		justify-content: center;
		width: 100%;
	`;
	export const CompletedTitle = styled.div`
		padding: 24px 0;
		text-align: center;
		font-family: Raleway;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	`;
	export const CompletedDesc = styled.div`
		font-family: Raleway;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-align: center;
	`;
}
