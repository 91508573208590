import React, { type FC, useCallback, useEffect, useRef, useState, createContext } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tabs } from 'antd';
import { debounce, cloneDeep } from 'lodash';

import Spinner from 'components/Spinner';
import Table from 'components/Table';
import { useMount } from 'hooks';
import { DEFAULT_PAGE_SIZE } from 'modules/Common/constants';
import { MenuList } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { Routes } from 'types';
import { getMenuPath, propsFilter } from 'utils/helpers';

import SearchField from '../../components/SearchField';
import backOfficeDucks from '../../ducks/backOfficeDucks';

import { ClientsFields } from './ClientContextContainer.constants';
import { columns } from './ClientContextContainer.entities';
import { initializeClientDucks } from './ClientContextContainer.store';
import { Styled } from './ClientContextContainer.styled';
import { TargetKey, ClientContextContainerProps } from './ClientContextContainer.types';
import ClientWrapper from './ClientWrapper/ClientWrapper';
import TableRow from './TableRow';

export const ClntContext = createContext(null);

const ClientContextContainer: FC<ClientContextContainerProps> = ({
	clients,
	clientId,
	contextClientData,
	selectedClients,
	getContextClient,
	getClientContextClientsList,
	saveClientContextSelectedList,
	children,
	loading,
	userRole,
}) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const searchValueRef = useRef<string | null>(null);
	const [showTable, setShowTable] = useState<boolean>(!clientId);
	const [contextModel, setContextModel] = useState({});

	const [tab, setTab] = useState<string>('0');
	const [clientTabs, setClientTabs] = useState<[] | null>([]);

	const { data: clientsData, pageIndex, pageSize, totalCount } = clients || {};

	const debounceSearch = debounce((value) => {
		getClientContextClientsList({ page: 0, size: DEFAULT_PAGE_SIZE, search: value });
	}, 1500);

	const handleChangeTabs = useCallback(
		(id: string) => {
			const selectedClientsData = [...selectedClients];
			const currentSelectedClientIndex = selectedClientsData?.findIndex((i) => i.id === +clientId);

			const nextSelectedClient = selectedClients?.find((i) => i.id === +id);

			if (currentSelectedClientIndex !== -1) {
				const menuItem = getMenuPath(pathname);
				const menuPath = pathname?.split('/').splice(4).join('/');

				const prevClientTabState = selectedClientsData?.splice(currentSelectedClientIndex, 1);
				const newSelectedClient = {
					id: +clientId,
					menuItem,
					clientName: prevClientTabState && prevClientTabState[0]?.clientName,
					menuPath: `/${menuPath}`,
				};

				saveClientContextSelectedList([...selectedClientsData, { ...newSelectedClient }]);
			}

			setTab(() => id);
			setShowTable(() => false);

			navigate(`${Routes.BOClientContext}/${id}${nextSelectedClient?.menuPath}`, {
				state: { clientId: id },
			});
		},
		[clientId, selectedClients, pathname],
	);

	const handleTableChange = useCallback(
		(page: number, size: number) => {
			getClientContextClientsList({
				page,
				size,
				search: searchValueRef.current?.input?.value || '',
			});
		},
		[tab],
	);

	const handleAddTab = useCallback(
		(client: { id: number; clientName: string }) => {
			const atsClientId = client?.id;

			if (atsClientId) {
				if (!contextModel[atsClientId]) {
					const ducksToInject = initializeClientDucks(
						atsClientId,
						!selectedClients.some((sc) => sc.id === atsClientId),
					);

					setContextModel((ps) => ({ ...ps, [atsClientId]: ducksToInject }));
				}

				getClientContextClientsList({ page: pageIndex, size: pageSize, search: '' });

				setClientTabs((st) => [
					...cloneDeep(st),
					{
						label: client?.clientName,
						children: (
							<ClientWrapper clientId={atsClientId} isInitTab={isInitTab} role={userRole}>
								{children}
							</ClientWrapper>
						),
						key: atsClientId,
						menuItem: MenuList.Dashboard,
						menuPath: Routes.Dashboard,
					},
				]);
				setTab(() => atsClientId);
				setShowTable(() => false);

				const route = `${Routes.BOClientContext}/${atsClientId}${Routes.Dashboard}`;

				navigate(route, { state: { clientId: atsClientId } });

				if (!selectedClients.some((sc) => sc.id === atsClientId)) {
					saveClientContextSelectedList([
						...selectedClients,
						{ id: +atsClientId, menuPath: Routes.Dashboard, clientName: client.clientName },
					]);
				}
			}
		},
		[contextModel, selectedClients, clientId],
	);

	const closeTab = (targetKey: TargetKey) => {
		const newPanes = clientTabs?.filter((item) => item.key !== targetKey);
		if (newPanes?.length && tab === targetKey) {
			const newActiveClientId = newPanes[0].key;

			setTab(newActiveClientId);

			navigate(`${Routes.BOClientContext}/${newActiveClientId}${Routes.Dashboard}`, {
				state: { clientId: newActiveClientId },
			});
		}
		setClientTabs(newPanes);

		saveClientContextSelectedList([...selectedClients]?.filter((cl) => cl?.id !== +targetKey));
	};

	const handleEditTabs = (
		targetKey: React.MouseEvent | React.KeyboardEvent | string,
		action: 'add' | 'remove',
	) => {
		if (action === 'add') {
			setTab('0');
			setShowTable(true);
			getClientContextClientsList({ page: 0, size: DEFAULT_PAGE_SIZE });

			navigate(`${Routes.BOClientContext}`);
		} else {
			closeTab(targetKey);
		}
	};

	const handleSearch = (e: Event) => {
		e?.preventDefault();

		debounceSearch(e?.target?.value);
	};

	useEffect(() => {
		if (!clientId && !clientsData?.length) {
			getClientContextClientsList({ page: 0, size: DEFAULT_PAGE_SIZE });
		}
	}, [clientsData?.length, clientId]);

	useEffect(() => {
		if (clientId && contextClientData) {
			handleAddTab(contextClientData);
		}
	}, [contextClientData]);

	useMount(() => {
		clientId && getContextClient(+clientId);
		selectedClients.forEach((cl) => handleAddTab(cl));
	});

	const filteredData = clientsData?.length && propsFilter(clientsData, ClientsFields);
	const isInitTab = tab === '0';

	if (loading?.getContextClientLoad) {
		return <Spinner fixed fullWidth />;
	}

	return (
		<ClntContext.Provider value={contextModel}>
			<Styled.Root isInitTab={isInitTab}>
				<Styled.TabsHeaderBg />
				<Styled.Content>
					<Tabs
						destroyInactiveTabPane
						defaultActiveKey={tab}
						activeKey={tab}
						items={clientTabs}
						type='editable-card'
						className='client-context-tabs'
						onChange={handleChangeTabs}
						onEdit={handleEditTabs}
					/>

					{showTable && (
						<Styled.BoxWrapper>
							<h2>Search a client</h2>
							<SearchField
								ref={searchValueRef}
								onSearch={handleSearch}
								placeholder='Search a client'
							/>
							<br />
							<Table
								data={filteredData}
								columns={columns()}
								pageSize={pageSize}
								total={totalCount}
								current={pageIndex}
								onChange={handleTableChange}
								onPageSizeChange={handleTableChange}
								loading={loading?.getClientContextClientsListLoad}
								components={{
									body: {
										row: (props) => (
											<TableRow {...props} onClick={handleAddTab} selectedList={selectedClients} />
										),
									},
								}}
							/>
						</Styled.BoxWrapper>
					)}
				</Styled.Content>
			</Styled.Root>
		</ClntContext.Provider>
	);
};

export default connect(
	(state) => ({
		clients: backOfficeDucks.backOfficeSelectors.getClientContextData(state),
		selectedClients: backOfficeDucks.backOfficeSelectors.getSelectedClientsListData(state),
		contextClientData: backOfficeDucks.backOfficeSelectors.getContextClientData(state),
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
		userRole: unregisteredDucks.unregisteredSelectors.getUserRole(state),
	}),
	{
		getContextClient: backOfficeDucks.backOfficeActions.getContextClientByIdRequested,
		getClientContextClientsList: backOfficeDucks.backOfficeActions.getClientsContextRequested,
		saveClientContextSelectedList: backOfficeDucks.backOfficeActions.saveClientContextSelectedList,
	},
)(ClientContextContainer);
