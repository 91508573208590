import { FC } from 'react';

import Box from 'components/Box';
import ClipIcon from 'components/SVG/ClipIcon';
import DownloadIcon from 'components/SVG/DownloadIcon';
import Tag from 'modules/Common/components/Tag';
import TicketNotes from 'modules/Common/components/TicketNotes';
import { ITicketDetails, TagColoursType } from 'modules/Common/types';
import { convertContentToHtml } from 'modules/Common/utils/editorHelper';
import { COLORS } from 'theme';
import { handleFileDownload } from 'utils/helpers';

import { Styled } from './AtsTicketDetails.styled';

type AtsTicketDetailsProps = {
	ticketData: ITicketDetails;
};

const AtsTicketDetails: FC<AtsTicketDetailsProps> = ({ ticketData }) => {
	const tagColor =
		ticketData.ticketStatus.name === 'New'
			? TagColoursType.Blue
			: ticketData.ticketStatus.name === 'Resolved'
				? TagColoursType.Green
				: TagColoursType.Orange;

	const descStartIndex = ticketData?.description.indexOf('Job description: ') + 17;
	const descLastIndex1 = ticketData?.description.lastIndexOf('>') + 1;
	const descLastIndex2 = ticketData?.description.lastIndexOf('}}') + 2;
	const descLastIndex = descLastIndex1 > descLastIndex2 ? descLastIndex1 : descLastIndex2;
	const descAssessmentIndexStart = ticketData?.description.indexOf('Assessment:');
	const descStringFirstPart = ticketData?.description.slice(0, descStartIndex);
	const descStringSecondtPart = ticketData?.description.slice(
		descLastIndex,
		descAssessmentIndexStart,
	);
	const richDescriptionString = ticketData?.description.slice(descStartIndex, descLastIndex);

	const descriptionHtml = richDescriptionString && convertContentToHtml(richDescriptionString);
	const { attachments, activities } = ticketData;

	return (
		<Styled.Root>
			<Box>
				<Styled.Header>
					<Styled.HeaderMain>
						<Styled.SubTitle>{`Submited by ${ticketData.user}`}</Styled.SubTitle>
					</Styled.HeaderMain>
					<Styled.HeaderAdditional>
						<Styled.StatusWrapper>
							<Tag color={tagColor}>{ticketData.ticketStatus.name}</Tag>
						</Styled.StatusWrapper>
					</Styled.HeaderAdditional>
				</Styled.Header>
				<Styled.FieldRow>
					<Styled.FieldWrapper>
						<Styled.FieldLabel>Type</Styled.FieldLabel>
						<Styled.Field>
							<span>{ticketData.ticketType.name}</span>
						</Styled.Field>
					</Styled.FieldWrapper>
					<Styled.FieldWrapper>
						<Styled.FieldLabel>Subject</Styled.FieldLabel>
						<Styled.Field>
							<span>{ticketData.subject}</span>
						</Styled.Field>
					</Styled.FieldWrapper>
				</Styled.FieldRow>
				<Styled.FieldWrapper>
					<Styled.FieldLabel>Description</Styled.FieldLabel>
					<Styled.Field isTransparent>
						{descriptionHtml ? (
							<span>
								{descStringFirstPart}
								<Styled.RichDescription>{descriptionHtml}</Styled.RichDescription>
								{descStringSecondtPart}
							</span>
						) : (
							<span>{ticketData.description}</span>
						)}
					</Styled.Field>
					{ticketData.assessmentQuestions !== null && (
						<Styled.Field isTransparent>
							<div>Assessment: {ticketData.assessmentQuestions.length ? 'YES' : 'NO'}</div>
						</Styled.Field>
					)}
				</Styled.FieldWrapper>
				<>
					{attachments?.map(({ originalFileName, referenceUuid }) => {
						return (
							<Styled.Attachment
								key={referenceUuid}
								onClick={() => handleFileDownload(referenceUuid, originalFileName)}
							>
								<Styled.AttachmentFileName>
									<ClipIcon fill={COLORS.darkGray2} width='22' height='22' />
									{originalFileName}
								</Styled.AttachmentFileName>
								<DownloadIcon fill={COLORS.darkGray2} width='22' height='22' />
							</Styled.Attachment>
						);
					})}
				</>
			</Box>
			<Box>
				{activities.length > 0 ? <TicketNotes notes={activities} /> : <h3>No activity yet!</h3>}
			</Box>
		</Styled.Root>
	);
};

export default AtsTicketDetails;
