import React from 'react';

export const BackOfficeDashboard = () => {
	return (
		<>
			<h2>Backoffice dashboard</h2>
		</>
	);
};

export default BackOfficeDashboard;
