/* eslint-disable no-duplicate-imports */
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';

import AjustColorPicker from 'modules/Common/components/AjustColorPicker';
import BrandingImgUploader from 'modules/Common/components/BrandingImgUploader';
import ATSClientBranding from 'modules/Common/containers/ATSClientBranding';
import { AppModes, SettingsRoutes } from 'types';

import ATSLayout from '../../../ATS/components/ATSLayout';

import { Styled } from './ATSBrandingInner.styled';

type AtsBrandingInnerProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};

const AtsBrandingInner: FC<AtsBrandingInnerProps> = ({
	backgroundImage,
	bgUploaderProps,
	logoUploaderProps,
	ajustColorData,
	applyColors,
	resetColors,
	navigationProps,
	colors,
	handleBgRemove,
	handleLogoRemove,
	clientName,
	jobsData,
	statisticData,
	logo,
	setBgUrl,
}) => {
	const { pathname } = useLocation();

	const isCollapsed = pathname === SettingsRoutes.Branding;

	return (
		<Styled.Root>
			{!backgroundImage && (
				<Styled.BgUploader isCollapsed={isCollapsed}>
					<BrandingImgUploader {...bgUploaderProps} setBgUrl={setBgUrl} isCropped />
				</Styled.BgUploader>
			)}

			<Styled.LogoUploader isCollapsed={isCollapsed}>
				<BrandingImgUploader {...logoUploaderProps} />
			</Styled.LogoUploader>
			<AjustColorPicker
				ajustColorData={ajustColorData}
				applyColors={applyColors}
				resetColors={resetColors}
			/>
			<ATSLayout navigationProps={navigationProps} mode={AppModes.Preview}>
				<ATSClientBranding
					textColour={colors.textColour}
					highlightColour={colors.highlightColour}
					headerBackground={colors.headerBackground}
					handleBgRemove={handleBgRemove}
					handleLogoRemove={handleLogoRemove}
					clientName={clientName}
					backgroundImage={backgroundImage}
					jobsData={jobsData}
					statisticData={statisticData}
					logo={logo}
				/>
			</ATSLayout>
		</Styled.Root>
	);
};

export default AtsBrandingInner;
