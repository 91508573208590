import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;
	`;

	export const FormHeader = styled.h3`
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
		letter-spacing: -0.264px;
		margin-bottom: 24px;
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
		margin-bottom: 32px;
		gap: 16px;
	`;

	export const AttachmentsList = styled.div``;

	export const AttachmentsTitle = styled.div`
		margin-bottom: 8px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
	`;

	export const Attachment = styled.div`
		border: 1px solid ${COLORS.gray};
		padding: 10px;
		height: auto;
		transition: all 0.2s ease 0s;
		margin-top: 8px;
		display: flex;
		align-items: center;
		&:hover {
			background-color: transparent;
			box-shadow: ${COLORS.gray} 0px 3px 5px 0px;
			cursor: pointer;
		}
	`;

	export const AttachmentPin = styled.div``;

	export const AttachmentName = styled.div`
		margin-left: 16px;
		color: ${COLORS.black};
		font-weight: bold;
	`;

	export const AttachmentDownloadIcon = styled.div`
		margin-left: auto;
	`;

	export const InvoiceDownloadWrap = styled.div``;
}
