import React, { type FC, useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import PhoneInput from 'react-phone-number-input';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { UploadOutlined } from '@ant-design/icons';
import { Input, Button as AntButton, UploadFile, Checkbox } from 'antd';

import backOfficeService from 'api/endpoints/backOffice';
import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import {
	MAX_FILE_SIZE_FOR_APPLICATIONS,
	acceptedApplicationUploadFormFileFormat,
	MESSAGES,
} from 'modules/Common/constants';
import { commonDucks } from 'modules/Common/ducks';
import { checkMaxFileSize } from 'modules/Common/utils/commonHelpers';
import { getUploader } from 'modules/Common/utils/docsUploader';
import { Routes } from 'types';
import {
	requiredFieldValidator,
	composeValidators,
	requiredValidator,
	emailValidator,
	phoneValidator,
	lengthValidator,
	textWithoutNewLinesTabsAndCarriageReturnRegExp,
} from 'utils/validators';

import ThanksMessageModal from '../../components/ThanksMessageModal';

import { Styled } from './CompanyApply.styled';

const { TextArea } = Input;

type CompanyApplyProps = {
	loading: boolean;
	clientId?: number | string;
	includeToDatabaseFieldShown?: boolean;
	requiredFields?: string[];
	justSendQuestion?: boolean;
	closeModal: () => void;
	applyOnCompanyRequested: (
		values: object,
		justSendQuestion: boolean,
		callback: () => void,
	) => void;
};

const CompanyApply: FC<CompanyApplyProps> = ({
	applyOnCompanyRequested,
	clientId,
	includeToDatabaseFieldShown = true,
	requiredFields,
	justSendQuestion = false,
	closeModal,
	loading,
}) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [formSubmited, setFormSubmited] = useState(false);
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [listOfRequiredFields, setListOfRequiredFields] = useState<string[]>([
		'fullName',
		'email',
		'phoneNumber',
		'message',
		'uploadedCV',
	]);
	const [coverLetterFileList, setCoverLetterFileList] = useState<UploadFile[]>([]);
	const [cvFileList, setCvFileList] = useState<UploadFile[]>([]);

	const handleCloseModal = () => {
		setModalOpen(false);
	};

	const handleRedirectCareerPage = () => {
		setModalOpen(false);
		if (pathname !== Routes.CareerPage) {
			navigate(Routes.CareerPage);
		}
	};

	useEffect(() => {
		setFormSubmited(false);
	}, [formSubmited]);

	useEffect(() => {
		if (requiredFields) {
			setListOfRequiredFields(requiredFields);
		}
	}, [requiredFields]);

	const onSubmit = (values: object, form: { reset: () => void }) => {
		const preparedValues = {
			...values,
			client: clientId,
		};

		const callback = () => {
			form.reset();
			closeModal();
			setFormSubmited(true);
			setModalOpen(true);
		};

		applyOnCompanyRequested(preparedValues, justSendQuestion, callback);
	};

	return (
		<Styled.ContentWrapper>
			<Styled.FormTitle>Send Us a Message</Styled.FormTitle>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit, dirty }) => (
					<form onSubmit={handleSubmit}>
						<Styled.FieldWrapper>
							<Field
								name='fullName'
								validate={
									listOfRequiredFields.includes('fullName')
										? composeValidators(requiredValidator, lengthValidator(1, 255))
										: undefined
								}
							>
								{({ input, meta }) => (
									<FieldWrapper
										isFixed
										name='fullName'
										label='Name'
										required={listOfRequiredFields.includes('fullName')}
										errorMessage={meta.submitFailed && meta.touched && meta.error}
									>
										<Input {...input} autoComplete='off' />
									</FieldWrapper>
								)}
							</Field>
						</Styled.FieldWrapper>
						<Styled.FieldWrapper>
							<Field
								name='email'
								validate={
									listOfRequiredFields.includes('email')
										? composeValidators(requiredValidator, emailValidator, lengthValidator(1, 255))
										: undefined
								}
							>
								{({ input, meta }) => (
									<FieldWrapper
										isFixed
										name='email'
										label='Contact Email'
										required={listOfRequiredFields.includes('email')}
										errorMessage={meta.submitFailed && meta.touched && meta.error}
									>
										<Input {...input} autoComplete='off' />
									</FieldWrapper>
								)}
							</Field>
						</Styled.FieldWrapper>
						<Styled.FieldWrapper>
							<Field name='city'>
								{({ input }) => (
									<FieldWrapper isFixed name='city' label='City'>
										<Input {...input} autoComplete='off' />
									</FieldWrapper>
								)}
							</Field>
						</Styled.FieldWrapper>
						<Styled.FieldWrapper>
							<Field
								name='phoneNumber'
								validate={
									listOfRequiredFields.includes('phoneNumber')
										? composeValidators(requiredValidator, phoneValidator)
										: undefined
								}
							>
								{({ input, meta }) => (
									<FieldWrapper
										isFixed
										name='phoneNumber'
										label='Phone Number'
										required={listOfRequiredFields.includes('phoneNumber')}
										errorMessage={meta.submitFailed && meta.touched && meta.error}
									>
										<Styled.PhoneFieldWrapper>
											<PhoneInput international {...input} defaultCountry={'GB'} />
										</Styled.PhoneFieldWrapper>
									</FieldWrapper>
								)}
							</Field>
						</Styled.FieldWrapper>
						<Styled.FieldWrapper>
							<Field
								name='message'
								validate={
									listOfRequiredFields.includes('message')
										? composeValidators(
											requiredValidator,
											lengthValidator(1, 3000, textWithoutNewLinesTabsAndCarriageReturnRegExp),
										  )
										: lengthValidator(0, 3000, textWithoutNewLinesTabsAndCarriageReturnRegExp)
								}
							>
								{({ input, meta }) => (
									<FieldWrapper
										isFixed
										name='message'
										label='Message'
										required={listOfRequiredFields.includes('message')}
										errorMessage={meta.submitFailed && meta.touched && meta.error}
									>
										<TextArea {...input} autoComplete='off' />
									</FieldWrapper>
								)}
							</Field>
						</Styled.FieldWrapper>

						<Styled.FieldWrapper>
							<Field
								name='uploadedCoverLetter'
								validate={
									listOfRequiredFields.includes('uploadedCoverLetter')
										? requiredFieldValidator
										: undefined
								}
							>
								{({ input }) => (
									<FieldWrapper
										isFixed
										name='uploadedCoverLetter'
										label='Upload Cover Letter'
										hint='Please select a DOC, DOCX or PDF format'
										required={listOfRequiredFields.includes('uploadedCoverLetter')}
									>
										<Styled.Upload
											listType='picture'
											maxCount={1}
											accept={acceptedApplicationUploadFormFileFormat}
											customRequest={(options) =>
												backOfficeService.uploadFile({ ...options, fileType: 'CL', noAuth: true })
											}
											fileList={coverLetterFileList}
											onChange={getUploader(input.onChange, setCoverLetterFileList, 'CV')}
											beforeUpload={(file) =>
												checkMaxFileSize(file, MAX_FILE_SIZE_FOR_APPLICATIONS)
											}
											{...(formSubmited && { fileList: [] })}
										>
											<AntButton block icon={<UploadOutlined />}>
												Upload
											</AntButton>
										</Styled.Upload>
									</FieldWrapper>
								)}
							</Field>
						</Styled.FieldWrapper>
						<Styled.FieldWrapper>
							<Field
								name='uploadedCV'
								validate={
									listOfRequiredFields.includes('uploadedCV') ? requiredFieldValidator : undefined
								}
							>
								{({ input, meta }) => (
									<FieldWrapper
										isFixed
										name='uploadedCV'
										label='Upload CV'
										hint='Please select a DOC, DOCX or PDF format'
										required={listOfRequiredFields.includes('uploadedCV')}
										errorMessage={meta.submitFailed && meta.touched && meta.error}
									>
										<Styled.Upload
											customRequest={(options) =>
												backOfficeService.uploadFile({ ...options, fileType: 'CV', noAuth: true })
											}
											listType='picture'
											maxCount={1}
											accept={acceptedApplicationUploadFormFileFormat}
											fileList={cvFileList}
											onChange={getUploader(input.onChange, setCvFileList, 'CV')}
											beforeUpload={(file) =>
												checkMaxFileSize(file, MAX_FILE_SIZE_FOR_APPLICATIONS)
											}
											{...(formSubmited && { fileList: [] })}
										>
											<AntButton block icon={<UploadOutlined />}>
												Upload
											</AntButton>
										</Styled.Upload>
									</FieldWrapper>
								)}
							</Field>
						</Styled.FieldWrapper>
						{includeToDatabaseFieldShown && (
							<Styled.FieldWrapper>
								<Field initialValue={false} name='includeToCandidateDatabase' type='checkbox'>
									{({ input, meta }) => (
										<FieldWrapper
											isFixed
											isInline
											name='includeToCandidateDatabase'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Checkbox {...input}>Agree to be included to candidate database</Checkbox>
										</FieldWrapper>
									)}
								</Field>
							</Styled.FieldWrapper>
						)}
						<Styled.SubmitWrapper>
							<Button
								type='submit'
								buttonType={ButtonTypes.primary}
								disabled={!dirty || loading}
								loading={loading}
								isFullWidth
								style={{ fontFamily: 'Inter' }}
							>
								Send Message
							</Button>
						</Styled.SubmitWrapper>
					</form>
				)}
			></Form>
			<ThanksMessageModal
				modalOpen={modalOpen}
				onCloseModal={handleCloseModal}
				onSubmit={handleRedirectCareerPage}
				title={'Thank You!'}
				message={
					justSendQuestion ? MESSAGES.successfullySentMessage : MESSAGES.successfullySentMessage
				}
			/>
		</Styled.ContentWrapper>
	);
};

export default connect(
	(state) => ({
		loading: commonDucks.commonSelectors.commonLoading(state).applyOnCompanyLoad,
	}),
	{
		applyOnCompanyRequested: commonDucks.commonActions.applyOnCompanyRequested,
	},
)(CompanyApply);
