import React, { useMemo, type FC } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isEmpty } from 'lodash';

import { useMount } from 'hooks';
import { atsDucks } from 'modules/ATS/ducks';
import { commonDucks } from 'modules/Common/ducks';
import { OrderSMSOrInterviewPriceType } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { CountriesType, GenericType, Routes, CurrencyType } from 'types';

import BuyCreditsForm from '../BuyCreditsForm/BuyCreditsForm';

import { Styled } from './BuyVideoCredits.styled';
import { FormValuesType } from './BuyVideoCredits.types';

type BuyVideoCreditsProps = {
	yearMonth?: string;
	countries: CountriesType;
	chargeVatOnContracts: boolean;
	chargeVatPercent: number;
	interviewPrice: OrderSMSOrInterviewPriceType;
	atsLoading: GenericType;
	getContractVatRequested: () => void;
	getVideoInterviewOrderPriceRequested: () => void;
	orderInterviewsRequested: (values: FormValuesType[], cb: () => void) => void;
};

const BuyVideoCredits: FC<BuyVideoCreditsProps> = ({
	yearMonth,
	countries,
	chargeVatOnContracts,
	chargeVatPercent,
	interviewPrice,
	atsLoading,
	getContractVatRequested,
	getVideoInterviewOrderPriceRequested,
	orderInterviewsRequested,
}) => {
	const navigate = useNavigate();

	useMount(() => {
		!chargeVatPercent && getContractVatRequested();
		isEmpty(interviewPrice) && getVideoInterviewOrderPriceRequested();
	});

	const initialValues = useMemo(
		() => ({
			yearMonth: yearMonth || '',
			count: '',
			pricePerOne: interviewPrice?.price || 1,
		}),
		[interviewPrice],
	);

	const onSubmit = (values: { unitForMonths: FormValuesType[] }) => {
		const valuesToSubmit = values.unitForMonths.map((value) => ({
			yearMonth: value.yearMonth,
			count: value.count,
		}));

		orderInterviewsRequested({ unitForMonths: valuesToSubmit }, (orderId: number) =>
			navigate(`${Routes.ATS}${Routes.Credits}/${orderId}`),
		);
	};
	const currencySymbol = countries.isUK ? CurrencyType.PoundSterling : CurrencyType.USDollar;

	const chargeVat = countries.isUK && chargeVatOnContracts;

	return (
		<Styled.Root>
			<BuyCreditsForm
				initialValues={initialValues}
				creditTypeLabel='Quantity of Video Interviews'
				bundleSize={interviewPrice.bundleSize}
				unitLabel='Price per Video Interview'
				creditTypeNote='Video Interview(s) will be purchased'
				addMonthButtonEnabled={true}
				chargeVat={chargeVat}
				currency={currencySymbol}
				chargeVatPercent={chargeVatPercent}
				submitButtonLoading={!!atsLoading?.createAtsTicketLoad}
				handleFormSubmit={onSubmit}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		atsLoading: atsDucks.atsSelectors.getAtsLoading(state),
		countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
		chargeVatOnContracts:
			unregisteredDucks.unregisteredSelectors.getUser(state)?.client?.chargeVatOnContracts,
		chargeVatPercent: commonDucks.commonSelectors.getContractVat(state),
		interviewPrice: commonDucks.commonSelectors.getVideoInterviewOrderPrice(state),
	}),
	{
		getContractVatRequested: commonDucks.commonActions.getContractVatRequested,
		getVideoInterviewOrderPriceRequested:
			commonDucks.commonActions.getVideoInterviewOrderPriceRequested,
		orderInterviewsRequested: atsDucks.atsActions.orderInterviewsRequested,
	},
)(BuyVideoCredits);
