import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function Icon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15 5.5C15 3.567 16.567 2 18.5 2C20.433 2 22 3.567 22 5.5C22 7.433 20.433 9 18.5 9C17.4832 9 16.5676 8.56639 15.9282 7.874L8.90339 11.1799C8.96655 11.443 9 11.7176 9 12C9 12.3666 8.94363 12.7201 8.83908 13.0522L15.6553 16.4605C16.2906 15.576 17.328 15 18.5 15C20.433 15 22 16.567 22 18.5C22 20.433 20.433 22 18.5 22C16.567 22 15 20.433 15 18.5C15 18.4565 15.0008 18.4132 15.0024 18.37L7.70273 14.7201C7.10121 15.2078 6.33473 15.5 5.5 15.5C3.567 15.5 2 13.933 2 12C2 10.067 3.567 8.5 5.5 8.5C6.42521 8.5 7.26657 8.859 7.89239 9.44529L15.0475 6.07804C15.0163 5.89001 15 5.6969 15 5.5ZM18.5 4C17.6716 4 17 4.67157 17 5.5C17 6.32843 17.6716 7 18.5 7C19.3284 7 20 6.32843 20 5.5C20 4.67157 19.3284 4 18.5 4ZM5.5 10.5C4.67157 10.5 4 11.1716 4 12C4 12.8284 4.67157 13.5 5.5 13.5C6.32843 13.5 7 12.8284 7 12C7 11.1716 6.32843 10.5 5.5 10.5ZM17 18.5C17 17.6716 17.6716 17 18.5 17C19.3284 17 20 17.6716 20 18.5C20 19.3284 19.3284 20 18.5 20C17.6716 20 17 19.3284 17 18.5Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(Icon);
