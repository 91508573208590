import { FC } from 'react';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import DeleteIcon from 'components/SVG/DeleteIcon';
import EditIcon from 'components/SVG/EditIcon';

import { S } from './ControlButtons.styled';

type ControlButtonsProps = {
	onEdit?: () => void;
	onRemove?: () => void;
};

export const ControlButtons: FC<ControlButtonsProps> = ({ onEdit, onRemove }) => {
	return (
		<S.Buttons>
			{onEdit && (
				<Button
					buttonType={ButtonTypes.primary}
					type='button'
					icon={<EditIcon />}
					onClick={onEdit}
				/>
			)}
			{onRemove && (
				<Button
					buttonType={ButtonTypes.primary}
					type='button'
					icon={<DeleteIcon width='12' height='14' />}
					onClick={onRemove}
				/>
			)}
		</S.Buttons>
	);
};
