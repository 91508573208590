import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import moment from 'moment';

import API from 'api';
import Spinner from 'components/Spinner';
import { RecordedInterviewType } from 'modules/Common/types';

import { Styled } from './ATSInterviewRecordingPage.styled';

export const ATSInterviewRecordingPage = () => {
	const { interviewId } = useParams();

	const [videoInterviewRecord, setVideoInterviewRecording] = useState<RecordedInterviewType | null>(
		null,
	);
	const [videoInterviewLinkLoading, setVideoInterviewLinkLoading] = useState<boolean>(false);

	const handleCheckInterviewVideo = useCallback(async () => {
		try {
			setVideoInterviewLinkLoading(true);

			const videoInterviewRecording = await API.interviewService.getAtsInterviewRecordingById(
				interviewId,
			);

			setVideoInterviewRecording(videoInterviewRecording);
		} catch (e) {
			console.error('error', e);
		} finally {
			setVideoInterviewLinkLoading(false);
		}
	}, [interviewId]);

	useEffect(() => {
		handleCheckInterviewVideo();
	}, [interviewId]);

	if (videoInterviewLinkLoading) {
		return <Spinner />;
	}

	if (!videoInterviewRecord) {
		return <div>sorry there is no a video interview record</div>;
	}

	const jobTitle = videoInterviewRecord?.jobTitle || '';
	const startDate =
		(videoInterviewRecord?.startDate &&
			moment(videoInterviewRecord?.startDate).format('DD MMMM, YYYY')) ||
		'';
	const candidate = videoInterviewRecord?.candidate || '';

	return (
		<Styled.Root>
			<h2>
				Video Interview {candidate}, {jobTitle}, recorded on {startDate}
			</h2>
			{videoInterviewRecord?.videos?.map((v, idx) => (
				<div key={idx}>
					<video controls width={'auto'} src={v.videoLink} />
				</div>
			))}
		</Styled.Root>
	);
};
