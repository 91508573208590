import styled from 'styled-components/macro';

import { COLORS } from 'theme';

interface ITotalSMSSecondLineProps {
	buyNow?: boolean;
}

export namespace Styled {
	export const Box = styled.div`
		display: flex;
		border-radius: 4px;
		padding: 4px 16px;
		background-color: ${COLORS.white};
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		cursor: pointer;
		align-items: center;
	`;

	export const LeftCol = styled.div`
		color: ${COLORS.darkGray3};
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;
		margin-right: 8px;
	`;

	export const RightCol = styled.div``;

	export const FirstLine = styled.div`
		color: ${COLORS.black};
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		display: flex;
	`;

	export const SecondLine = styled.div<ITotalSMSSecondLineProps>`
		color: ${({ buyNow }) => (buyNow ? COLORS.blue : COLORS.darkGray1)};
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		display: flex;
	`;

	export const SecondLineText = styled.div`
		font-size: 12px;
	`;

	export const BuyNowLink = styled.span`
		color: ${COLORS.blue};
		margin-left: 12px;
		font-size: 14px;
	`;
}
