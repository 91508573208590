import React, { FC } from 'react';
import { Line } from 'react-chartjs-2';

import moment from 'moment';
import { darken } from 'polished';

import { ShowMoreWrapperWrapper } from 'components/ShowMoreWrapper/ShowMoreWrapper';
import NavBackButton from 'components/SVG/NavBackButton';
import { JobsStatisticType, StatisticPeriodEnum } from 'modules/Common/types';
import { getHtmlLegendPlugin } from 'modules/Common/utils/chartHelpers';
import { STATISTICS_COLORS } from 'modules/Common/utils/StatisticsUtils';

import { monthOptions } from './ApplicationChart.options';
import { Styled } from './ApplicationChart.styled';

type ApplicationChartMonthProps = {
	currentPeriod: string;
	applicationsMonth: JobsStatisticType;
	setCurrentPeriodType: (period: StatisticPeriodEnum) => null;
	setCurrentPeriod: (period: string) => null;
};

const ApplicationChartMonth: FC<ApplicationChartMonthProps> = ({
	applicationsMonth,
	currentPeriod,
	setCurrentPeriod,
	setCurrentPeriodType,
}) => {
	const labels = [...new Array(31)].map((i, idx) => ({
		l: moment(`${currentPeriod}-01`).startOf('day').add(idx, 'days').format('DD.MM'),
		v: moment(`${currentPeriod}-01`).startOf('day').add(idx, 'days').format('YYYY-MM-DD'),
	}));
	const datasets = applicationsMonth.jobs.map((j, idx) => {
		const currentColor =
			STATISTICS_COLORS[idx] ?? darken(0.1, STATISTICS_COLORS[idx % STATISTICS_COLORS.length]);
		let archieved = false;

		return {
			label: j.title,
			borderColor: currentColor,
			backgroundColor: currentColor,

			data: labels.reduce((acc, l) => {
				const applies = j.applicationsData.find((ad) => ad.date === l.v);
				archieved = archieved || l.v === moment(j.archivingDate).format('YYYY-MM-DD');

				if (applies) {
					acc = [...acc, applies.count];
				} else if (!archieved) {
					acc = [...acc, acc[acc.length - 1] || 0];
				}

				return acc;
			}, []),
		};
	});

	const data = {
		labels: labels.map((label) => label.l),
		datasets,
	};

	const handleBackToYear = () => {
		setCurrentPeriod('');
		setCurrentPeriodType(StatisticPeriodEnum.Year);
	};

	const htmlLegendPlugin = getHtmlLegendPlugin('monthApplicationLegend');

	return (
		<Styled.Root>
			<Styled.Header>
				<Styled.Title>
					<Styled.NavBackToYearIcon onClick={handleBackToYear}>
						<NavBackButton />
					</Styled.NavBackToYearIcon>
					<span>{moment(`${currentPeriod}-01`).format('MMMM YYYY')}</span>
				</Styled.Title>
			</Styled.Header>
			<Styled.ChartWrapper>
				<Line options={monthOptions} data={data} plugins={[htmlLegendPlugin]} />
			</Styled.ChartWrapper>
			<Styled.LegendWrapper>
				<ShowMoreWrapperWrapper maxHeight={100}>
					<div id='application-chart-container-1'></div>
				</ShowMoreWrapperWrapper>
			</Styled.LegendWrapper>
		</Styled.Root>
	);
};

export default ApplicationChartMonth;
