import { ReactParent } from 'types';

export enum Direction {
	Column = 'column',
	Row = 'row',
}

export type ContainerProps = ReactParent & {
	fullWidth?: boolean;
	spacing?: number;
	noSpaces?: boolean;
	direction?: Direction;
	style?: object;
};
