import styled from 'styled-components/macro';

import { COLORS, device } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		display: flex;
		justify-content: space-between;
		margin-bottom: 32px;
		max-width: 1368px;
	`;

	export const Content = styled.div`
		display: flex;
		justify-content: space-between;
		@media ${device.tablet} {
			flex-direction: column;
		}
		margin: -32px -32px -24px;
		padding: 32px;
		background-color: ${COLORS.lightGray3};
	`;

	export const LeftColumn = styled.div`
		width: 62.8%;
		@media ${device.tablet} {
			width: 100%;
		}
	`;

	export const RightColumn = styled.div`
		width: 35.2%;
		@media ${device.tablet} {
			width: 100%;
		}
	`;
}
