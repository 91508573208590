import _ from 'lodash';
import { createSelector } from 'reselect';

import { statusOptions } from 'modules/ATS/containers/CandidatesApplication/CandidatesApplication.constants';
import { ContractStatusValuesEnum } from 'modules/Common/types';
import { sortJobsByCandidateStatus } from 'modules/Common/utils/commonHelpers';

export const createSelectors = (root) => {
	const commonRootState = createSelector([(s) => s[root]], (state) => state.data);
	const commonLoading = createSelector([(s) => s[root]], (state) => state.loadingReducer);

	const getContractsState = createSelector(commonRootState, (data) => data?.contractsState);
	const getContracts = createSelector(getContractsState, (data) => data?.contracts);
	const getContractStatuses = createSelector(getContractsState, (data) => data?.contractStatuses);
	const getContractStatusesShort = createSelector(getContractsState, (data) =>
		data?.contractStatuses?.filter(
			(f) =>
				f.value !== ContractStatusValuesEnum.Outstanding &&
				f.value !== ContractStatusValuesEnum.PendingForApproval,
		),
	);
	const getContractsByClientId = createSelector(getContractsState, (data) => data?.clientContracts);
	const getContractsByClientIdSelectFields = createSelector(getContractsState, (data) =>
		data?.clientContracts?.map((item) => ({ value: item.id, label: item.btoReference })),
	);
	const getContractVatState = createSelector(getContractsState, (data) => data?.chargeVat);
	const getContractByIdDetails = createSelector(getContractsState, (data) => data?.contractDetails);
	const getContractReceipt = createSelector(getContractsState, (data) => data?.receiptData);
	const getContractVat = createSelector(getContractVatState, (data) =>
		data?.valueInPercents ? data?.valueInPercents / 100 : 0,
	);
	const getContractsOutstandingCount = createSelector(
		getContractsState,
		(data) => data?.outstandingCount,
	);
	const getTicketTypes = createSelector(commonRootState, (data) => data.ticketTypes);
	const getTicketStatuses = createSelector(commonRootState, (data) => data.ticketStatuses);

	const getClientData = createSelector(commonRootState, (data) => data?.clientsState?.clientData);
	const getClientUsers = createSelector(commonRootState, (data) => data?.clientsState?.clientUsers);

	const getCredits = createSelector(commonRootState, (data) => data?.creditsState?.credits);
	const getCreditFields = createSelector(getCredits, (data) =>
		data?.map((client) => ({
			...client,
			creditPrefix: client.value + 'Count',
			creditPricePrefix: client.value + 'Price',
		})),
	);

	const getJobData = createSelector(commonRootState, (data) => data?.jobsState?.jobData);
	const getSortedCandidatesByStatus = createSelector(commonRootState, (data) => {
		return statusOptions?.reduce((acc, cur) => {
			const options = data?.jobsState?.jobData?.candidates?.filter(
				(candidate) => candidate?.candidateState?.value === cur.value,
			);

			acc.push({
				status: cur,
				options: options ?? [],
			});

			return acc;
		}, []);
	});

	const getJobTypes = createSelector(commonRootState, (data) => data?.jobsState?.jobTypes);

	const getJobApply = createSelector(commonRootState, (data) => data?.jobApply);
	const getJobAssessments = createSelector(commonRootState, (data) => data?.jobApply?.assessment);

	const getJobIndustries = createSelector(commonRootState, (data) => data?.jobIndustries);

	const getEducationTypes = createSelector(
		commonRootState,
		(data) => data?.educationState?.educationTypes,
	);

	const getEmploymentTypes = createSelector(
		commonRootState,
		(data) => data?.employmentState?.employmentTypes,
	);

	const getClientBranding = createSelector(commonRootState, (data) => data?.currentClientBranding);
	const getClientJobs = createSelector(commonRootState, (data) =>
		data?.jobsState?.currentClientJobs?.filter((j) => _.isObject(j)),
	);
	const getClientCopyJobs = createSelector(
		commonRootState,
		(data) => data?.jobsState?.currentClientCopyJobs,
	);

	const getQueryParamsState = createSelector(commonRootState, (data) => data?.queryParams);

	const getSortedJobsByCandidateStatus = createSelector(
		commonRootState,
		(data) =>
			data?.jobsState?.currentClientJobs &&
			sortJobsByCandidateStatus(data?.jobsState?.currentClientJobs),
	);

	const getClientPublickJobs = createSelector(getClientJobs, (data) =>
		data?.filter((j) => j.title !== 'Unassigned candidates'),
	);

	const getInterviewDataOpen = createSelector(
		commonRootState,
		(data) => data?.interviewState?.interviewData,
	);

	const getQuestionnairesState = createSelector(
		commonRootState,
		(data) => data?.questionnairesState,
	);
	const getBOQuestionnaires = createSelector(
		getQuestionnairesState,
		(data) => data?.questionnairesData,
	);
	const getAtsQuestionnaires = createSelector(
		getQuestionnairesState,
		(data) => data?.atsQuestionnairesData,
	);
	const getAtsQuestionnairesList = createSelector(getQuestionnairesState, (data) =>
		data?.atsQuestionnairesData?.map((item) => ({
			label: item.title,
			value: item.id,
		})),
	);
	const getQuestionnaireList = createSelector(getQuestionnairesState, (data) =>
		data?.questionnairesData?.data?.map((item) => ({
			label: item.title,
			value: item.id,
		})),
	);
	const getQuestionnaireUsersList = createSelector(getQuestionnairesState, (data) =>
		data?.questionnaireUsers?.map((item) => ({
			label: item.fullName,
			value: item.id,
			checked: item.restricted,
		})),
	);
	const getCurrentQuestionnaire = createSelector(
		getQuestionnairesState,
		(data) => data?.currentQuestionnaire,
	);
	const getCurrentQuestionnaireResult = createSelector(
		getQuestionnairesState,
		(data) => data?.questionnaireResult,
	);
	const getCopyQuestionnaires = createSelector(
		getQuestionnairesState,
		(data) => data?.copyQuestionnairesData,
	);
	const getSendQuestionnaires = createSelector(
		getQuestionnairesState,
		(data) => data?.sendQuestionnairesData,
	);
	const getCandidateVideoQuestionnaire = createSelector(
		getQuestionnairesState,
		(data) => data?.candidateVideoQuestionnaireData,
	);
	const getQuestionnaireDurations = createSelector(
		getQuestionnairesState,
		(data) => data?.durations,
	);
	const getSMSCount = createSelector(commonRootState, (data) => data?.smsCountState);
	const getSMSOrderPrice = createSelector(commonRootState, (data) => data?.orderPrices?.smsPrice);
	const getVideoInterviewOrderPrice = createSelector(
		commonRootState,
		(data) => data?.orderPrices?.videoInterviewPrice,
	);

	const getEmailTemplatesState = createSelector(
		commonRootState,
		(data) => data?.emailTemplatesState,
	);
	const getEmailAutoFilledData = createSelector(
		getEmailTemplatesState,
		(data) => data?.emailAutoFilledData,
	);
	const getEmailTemplatesData = createSelector(
		getEmailTemplatesState,
		(data) => data?.emailTemplatesData,
	);
	const getPricesByClientIdState = createSelector(
		commonRootState,
		(data) => data?.clientPrices?.prices,
	);

	return {
		getContractsState,
		getContracts,
		getContractStatuses,
		getContractStatusesShort,
		getContractsByClientId,
		getContractsByClientIdSelectFields,
		getContractByIdDetails,
		getContractReceipt,
		getContractVatState,
		getContractVat,
		getContractsOutstandingCount,
		getClientData,
		getClientUsers,
		getCredits,
		getCreditFields,
		getJobData,
		getSortedCandidatesByStatus,
		getJobTypes,
		getTicketTypes,
		getTicketStatuses,
		getEducationTypes,
		getEmploymentTypes,
		getClientBranding,
		getClientJobs,
		getClientCopyJobs,
		getSortedJobsByCandidateStatus,
		getClientPublickJobs,
		getJobApply,
		getJobAssessments,
		getJobIndustries,
		getInterviewDataOpen,
		getBOQuestionnaires,
		getAtsQuestionnaires,
		getAtsQuestionnairesList,
		getQuestionnaireList,
		getQuestionnaireUsersList,
		getCurrentQuestionnaire,
		getCopyQuestionnaires,
		getSendQuestionnaires,
		getCandidateVideoQuestionnaire,
		getQuestionnaireDurations,
		getCurrentQuestionnaireResult,
		getSMSCount,
		getSMSOrderPrice,
		getVideoInterviewOrderPrice,
		getEmailAutoFilledData,
		getEmailTemplatesState,
		getEmailTemplatesData,
		getPricesByClientIdState,
		getQueryParamsState,
		commonLoading,
	};
};
