import styled from 'styled-components/macro';

import { device } from 'theme';

export namespace Styled {
    export const VideoControlsContainer = styled.div`
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        @media ${device.tablet} {
			flex-direction: column;
		}
    `;
};
