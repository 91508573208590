import React, { type FC } from 'react';

import { IButton } from 'components/Button/Button.types';
import ThemedButton from 'modules/Common/containers/ThemedButton';

import { useAtsLayoutContext } from '../../containers/ATSLayoutContext/ATSLayoutContext';

const ATSThemedButton: FC<IButton> = ({ children, ...props }) => {
	return <ThemedButton context={useAtsLayoutContext} {...props}>{children}</ThemedButton>;
};

export default ATSThemedButton;
