import React, { Key } from 'react';

import { Space } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import { ColumnsDataType } from 'components/Table/Table.types';
import { IQuestionnaires } from 'modules/Common/types';
import { TStyled } from 'theme';
import { Routes } from 'types';
import { getDateFormat } from 'utils/helpers';

import { Styled } from './CopyQuestionnaireListTable.styled';

type DataTableType = IQuestionnaires & {
	key?: Key;
};

export const columns = (onCopy: (id: number) => void): ColumnsDataType => [
	{
		title: 'Title',
		dataIndex: 'title',
		width: '300px',
		key: 'title',
		render: (_: string, { title, id }: Partial<DataTableType>) => {
			return (
				<TStyled.PageLink to={`${Routes.ATS}${Routes.QuestionnairesEdit}/${id}`} state={{ title }}>
					<Styled.Title>{title}</Styled.Title>
				</TStyled.PageLink>
			);
		},
	},
	{
		title: 'Questions',
		dataIndex: 'numberOfQuestions',
		key: 'numberOfQuestions',
		align: 'center',
		render: (_: string, { numberOfQuestions }: Partial<DataTableType>) => (
			<span>{numberOfQuestions ? numberOfQuestions : 0}</span>
		),
	},
	{
		title: 'Date created',
		dataIndex: 'created',
		key: 'created',
		align: 'center',
		render: (_: string, { created }: Partial<DataTableType>) => {
			const formattedData = getDateFormat(created) ?? '-';

			return <>{formattedData}</>;
		},
	},
	{
		title: 'Actions',
		key: 'action',
		align: 'center',
		width: '176px',
		render: (_: string, { id }: Partial<DataTableType>) => (
			<Space size='middle'>
				<TStyled.Button buttonType={ButtonTypes.default} onClick={() => id && onCopy(id)}>
					Copy
				</TStyled.Button>
			</Space>
		),
	},
];
