import React, { FC } from 'react';
import { Field, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Input } from 'antd';

import FieldWrapper from 'components/FieldWrapper';
import FieldHtmlEditor from 'modules/Common/components/FieldEditor/FieldHtmlEditor';
import FormBlockGrid from 'modules/Common/components/FormBlockGrid';
import { COLORS } from 'theme';
import { requiredFieldValidator } from 'utils/validators';

import { Styled } from '../EmailTemplates.styled';

const TemplateFields: FC = () => {
	const { values } = useFormState();

	return (
		<FormBlockGrid columns={1}>
			<FieldArray name='templates'>
				{({ fields }) => {
					return fields?.map((item, index) => (
						<Styled.FieldsBox key={index}>
							<h2 style={{ color: COLORS.blue }}>Template #{index + 1}</h2>
							<Field
								name={`${item}.name`}
								validate={requiredFieldValidator}
								defaultValue={values.templates[index]?.name}
							>
								{({ input, meta }) => (
									<FieldWrapper
										name={`${item}.name`}
										label='Template name'
										errorMessage={meta.submitFailed && meta.touched && meta.error}
										required
									>
										<Input
											{...input}
											onChange={(value) => input.onChange(value)}
											defaultValue={input.value}
											placeholder=''
											style={{ maxWidth: '350px', marginBottom: '24px' }}
										/>
									</FieldWrapper>
								)}
							</Field>
							<Field name={`${item}.content`} validate={requiredFieldValidator}>
								{({ input, meta }) => (
									<FieldWrapper
										name={`${item}.content`}
										label='Message'
										errorMessage={meta.submitFailed && meta.touched && meta.error}
										required
									>
										<FieldHtmlEditor
											onChange={(value) => input.onChange(value)}
											defaultValue={input.value}
											placeholder='Enter your template'
											minRaws={12}
										/>
									</FieldWrapper>
								)}
							</Field>
						</Styled.FieldsBox>
					));
				}}
			</FieldArray>
		</FormBlockGrid>
	);
};

export default TemplateFields;
