import { darken } from 'polished';
import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';
import { COLORS, device } from 'theme';

type ButtonProps = {
	highlightColor?: string;
};

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		height: 100%;
		max-width: 1368px;
		padding: 0;
	`;

	export const StepTitle = styled.div`
		width: 100%;
		max-width: 1368px;
		padding: 40px 0 18px;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%;
		letter-spacing: -0.264px;
		text-transform: capitalize;

		@media ${device.tablet} {
			margin: 0 32px;
		}
	`;
	export const StepWrapper = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0 32px;
	`;

	export const StepContent = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		max-width: 1368px;
		padding: 32px 32px 40px;
		background: ${COLORS.lightGray3};
		box-shadow: ${COLORS.elevationShadow};
		color: ${COLORS.black};
		border-radius: 4px;
		border: 1px solid ${COLORS.lightGray2};

		@media ${device.tablet} {
			margin: 0 32px;
		}
	`;
	export const StepContentDesc = styled.div`
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		margin-bottom: 48px;
	`;
	export const CheckTitle = styled.div`
		text-align: center;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		margin-bottom: 24px;
	`;
	export const CheckItem = styled.div`
		display: flex;
		align-items: flex-start;
		margin-bottom: 24px;
	`;
	export const CheckItemIcon = styled.div`
		margin-right: 12px;
	`;
	export const CheckItemMain = styled.div``;
	export const CheckItemTitle = styled.div`
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
	`;
	export const CheckItemStatus = styled.div`
		color: ${COLORS.darkGray1};
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		width: 300px;
	`;
	export const StartButtonWrapper = styled.div`
		margin-top: 24px;
	`;
	export const Button = styled(ButtonComponent)<ButtonProps>`
		margin-top: 24px;
		background-color: ${({ highlightColor }) => highlightColor || COLORS.blue};
		border-color: none;
		color: ${COLORS.white};
		border: none;
		margin: 0px;
		width: auto;
		height: 40px;
		min-width: 99px;
		max-width: none;
		padding: 0px 24px;
		line-height: 40px;
		font-weight: 500;
		font-size: 16px;
		&:hover {
			background-color: ${({ highlightColor }) =>
		highlightColor ? darken(0.1, highlightColor) : COLORS.blue1};
			color: ${COLORS.white}!important;
			border: none;
		}
	`;
}
