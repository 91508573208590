import { Select as SelectComponent } from 'antd';
import { type DefaultOptionType } from 'antd/es/select';
import styled from 'styled-components/macro';

const { Option: OptionComponent } = SelectComponent;
interface ISelectProps extends DefaultOptionType {
	styles: {
		border: string;
		background: string;
		borderColor: string;
	};
}

export namespace Styled {
	export const Root = styled.section``;

	export const Head = styled.section`
		display: flex;
		justify-content: space-between;
	`;

	export const ButtonWrap = styled.div`
		display: flex;
		align-items: center;
	`;

	export const Select = styled(SelectComponent)<ISelectProps>`
		&.ant-select {
			min-width: 140px;
			text-align: center;
			.ant-select-selector {
				${({ styles }) => styles}
				font-weight: bold;
				height: 40px;
			}
			.ant-select-selection-item {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	`;

	export const PdfButtonWrapper = styled.div`
		margin-left: 20px;
	`;

	export const Option = styled(OptionComponent)``;
}
