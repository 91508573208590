import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';

import UnregisteredLayout from 'modules/Unregistered/components/UnregisteredLayout';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { Routes } from 'types';

import { Styled } from './ErrorPages.styled';

type ErrorPageProps = {
	logout: (p: () => void) => void;
};

const ErrorPage: React.FC<ErrorPageProps> = ({ logout }) => {
	const navigate = useNavigate();

	const handleLoginClick = () => {
		logout(() => navigate(Routes.Login));
	};

	return (
		<UnregisteredLayout>
			<Styled.Root id='error-page'>
				<Styled.ImageBox>
					<img src='/images/401.png' alt='Unauthorised' />
				</Styled.ImageBox>

				<Styled.BoxWrapper>
					<h3>Ooops!</h3>
					<h2>Unauthorised</h2>
					<p>Please check your credentials and try again</p>
					<Button type='link' onClick={handleLoginClick}>
						Login
					</Button>
				</Styled.BoxWrapper>
			</Styled.Root>
		</UnregisteredLayout>
	);
};

export default connect(() => ({}), {
	logout: unregisteredDucks.unregisteredActions.logOut,
})(ErrorPage);
