import { combineReducers } from 'redux';

import { takeLatest } from 'redux-saga/effects';

import createLoading from 'modules/Common/ducks/loading';

import { createActions } from './actions';
import { createActionsTypes } from './constants';
import { ATSReducer } from './reducer';
import { createSagas } from './sagas';
import { createSelectors } from './selectors';

function getATSDucks(props) {
	const { prefix, root } = props;
	const PREFIX = prefix;

	const TYPES = createActionsTypes(PREFIX);
	const ACTIONS = createActions(TYPES);
	const SELECTORS = createSelectors(root);

	const SAGAS = createSagas(TYPES, PREFIX, SELECTORS, ACTIONS, props);
	const atsReducer = ATSReducer(TYPES);

	const { reducer: loadingReducer } = createLoading({
		GET_CLIENT_SUBSCRIPTION: TYPES.GET_CLIENT_SUBSCRIPTION,
		GET_CLIENT_SOCIAL_NETWORKS: TYPES.GET_CLIENT_SOCIAL_NETWORKS,
		CHANGE_CLIENT_USER_PASSWORD: TYPES.CHANGE_CLIENT_USER_PASSWORD,
		UPDATE_CLIENT_SOCIAL_NETWORKS: TYPES.UPDATE_CLIENT_SOCIAL_NETWORKS,
		GET_CLIENT_EMAIL_SIGNATURE: TYPES.GET_CLIENT_EMAIL_SIGNATURE,
		UPDATE_CLIENT_EMAIL_SIGNATURE: TYPES.UPDATE_CLIENT_EMAIL_SIGNATURE,
		GET_CLIENT_AUTO_POP: TYPES.GET_CLIENT_AUTO_POP,
		UPDATE_CLIENT_AUTO_POP: TYPES.UPDATE_CLIENT_AUTO_POP,
		GET_CLIENT_2FA: TYPES.GET_CLIENT_2FA,
		UPDATE_CLIENT_2FA: TYPES.UPDATE_CLIENT_2FA,
		GET_CLIENT_SMS_NOTIFICATIONS: TYPES.GET_CLIENT_SMS_NOTIFICATIONS,
		UPDATE_CLIENT_SMS_NOTIFICATIONS: TYPES.UPDATE_CLIENT_SMS_NOTIFICATIONS,
		GET_USER_EMAIL_NOTIFICATIONS: TYPES.GET_USER_EMAIL_NOTIFICATIONS,
		UPDATE_USER_EMAIL_NOTIFICATIONS: TYPES.UPDATE_USER_EMAIL_NOTIFICATIONS,
		GET_SUBSCRIPTION_TYPES: TYPES.GET_SUBSCRIPTION_TYPES,
		GET_ATS_USER_PERSONAL_DATA: TYPES.GET_ATS_USER_PERSONAL_DATA,
		UPDATE_ATS_USER_PERSONAL_DATA: TYPES.UPDATE_ATS_USER_PERSONAL_DATA,
		ASSIGN_CANDIDATE_TO_VACANCY: TYPES.ASSIGN_CANDIDATE_TO_VACANCY,
		GET_UNAPPLIED_CANDIDATE_JOBS: TYPES.GET_UNAPPLIED_CANDIDATE_JOBS,
		GET_ATS_USERS: TYPES.GET_ATS_USERS,
		GET_ATS_USER_ROLES: TYPES.GET_ATS_USER_ROLES,
		CREATE_ORDER_INVOICE: TYPES.CREATE_ORDER_INVOICE,
		GET_ORDER_BY_ID: TYPES.GET_ORDER_BY_ID,
		GET_ATS_USER_BY_ID: TYPES.GET_ATS_USER_BY_ID,
		CREATE_ATS_USER: TYPES.CREATE_ATS_USER,
		DELETE_ATS_USER: TYPES.DELETE_ATS_USER,
		UPDATE_ATS_USER: TYPES.UPDATE_ATS_USER,
		GET_ATS_INVOICES: TYPES.GET_ATS_INVOICES,
		GET_ATS_INVOICE_BY_ID: TYPES.GET_ATS_INVOICE_BY_ID,
		GET_CLIENT_CREDITS: TYPES.GET_CLIENT_CREDITS,
		GET_ALL_ATS_JOBS: TYPES.GET_ALL_ATS_JOBS,
		ORDER_CREDITS: TYPES.ORDER_CREDITS,
		ARCHIVE_AND_UNARCHIVE_JOB: TYPES.ARCHIVE_AND_UNARCHIVE_JOB,
		GET_JOB_UPGRAGED: TYPES.GET_JOB_UPGRAGED,
		GET_ATS_TICKETS: TYPES.GET_ATS_TICKETS,
		GET_ATS_TICKET_DETAILS: TYPES.GET_ATS_TICKET_DETAILS,
		GET_CANDIDATE_ASSESSMENT_APPLICATION: TYPES.GET_CANDIDATE_ASSESSMENT_APPLICATION,
		UPDATE_CLIENT_APPLICATION_MESSAGES: TYPES.UPDATE_CLIENT_APPLICATION_MESSAGES,
		GET_CLIENT_APPLICATION_MESSAGES_BY_ID: TYPES.GET_CLIENT_APPLICATION_MESSAGES_BY_ID,
		GET_CLIENT_APPLICATION_NOTIFICATION: TYPES.GET_CLIENT_APPLICATION_NOTIFICATION,
		UPDATE_REJECTION_MESSAGES: TYPES.UPDATE_REJECTION_MESSAGES,
		GET_AND_SAVE_POST_JOB_CHANGES_FORM: TYPES.GET_AND_SAVE_POST_JOB_CHANGES_FORM,
		GET_ATS_ACTIVE_JOBS: TYPES.GET_ATS_ACTIVE_JOBS,
		GET_CANDIDATE_APPLICATION_BY_JOB_ID: TYPES.GET_CANDIDATE_APPLICATION_BY_JOB_ID,
		GET_ATS_INTERVIEW_TYPE: TYPES.GET_ATS_INTERVIEW_TYPE,
		GET_ATS_USER_INTERVIEW: TYPES.GET_ATS_USER_INTERVIEW,
		GET_ATS_INTERVIEW_BY_ID: TYPES.GET_ATS_INTERVIEW_BY_ID,
		SAVE_ATS_INTERVIEW: TYPES.SAVE_ATS_INTERVIEW,
		UPDATE_ATS_INTERVIEW: TYPES.UPDATE_ATS_INTERVIEW,
		GET_ATS_INTERVIEW_JOBS_LIST: TYPES.GET_ATS_INTERVIEW_JOBS_LIST,
		CANCEL_ATS_INTERVIEW_BY_ID: TYPES.CANCEL_ATS_INTERVIEW_BY_ID,
		GET_ATS_EDIT_INTERVIEW_JOBS_LIST: TYPES.GET_ATS_EDIT_INTERVIEW_JOBS_LIST,
		GET_ATS_INTERVIEW_CALENDAR_LIST: TYPES.GET_ATS_INTERVIEW_CALENDAR_LIST,
		RESEND_ATS_INTERVIEW_LINK: TYPES.RESEND_ATS_INTERVIEW_LINK,
		CREATE_ATS_TICKET: TYPES.CREATE_ATS_TICKET,
		GET_CANDIDATE_NOTES_BY_ID: TYPES.GET_CANDIDATE_NOTES_BY_ID,
		ADD_NOTE_TO_CANDIDATE: TYPES.ADD_NOTE_TO_CANDIDATE,
		GET_CANDIDATES_DATABASE: TYPES.GET_CANDIDATES_DATABASE,
		GET_CANDIDATE_DATABASE_BY_ID: TYPES.GET_CANDIDATE_DATABASE_BY_ID,
		GET_CANDIDATE_SMS_INFORMATION: TYPES.GET_CANDIDATE_SMS_INFORMATION,
		UPDATE_CANDIDATE_PHONE_NUMBER: TYPES.UPDATE_CANDIDATE_PHONE_NUMBER,
		SEND_SMS_TO_CANDIDATE: TYPES.SEND_SMS_TO_CANDIDATE,
		GET_ATS_VIDEO_INTERVIEW_COUNT: TYPES.GET_ATS_VIDEO_INTERVIEW_COUNT,
		ORDER_SMS: TYPES.ORDER_SMS,
		ORDER_INTERVIEWS: TYPES.ORDER_INTERVIEWS,
		SHOW_HIDE_CANDIDATE_DATABASE: TYPES.SHOW_HIDE_CANDIDATE_DATABASE,
		ASSIGN_CANDIDATE_TO_VACANCY_DATABASE: TYPES.ASSIGN_CANDIDATE_TO_VACANCY_DATABASE,
		FORWARD_INVOICE: TYPES.FORWARD_INVOICE,
		GET_ATS_BRANDING_BY_ID: TYPES.GET_ATS_BRANDING_BY_ID,
		GET_CANDIDATE_STATISTICS: TYPES.GET_CANDIDATE_STATISTICS,
		GET_APPLICATIONS_STATISTICS_FOR_LAST_MONTH: TYPES.GET_APPLICATIONS_STATISTICS_FOR_LAST_MONTH,
		GET_CANDIDATE_APPLICATIONS_PER_STATE_STATISTICS:
			TYPES.GET_CANDIDATE_APPLICATIONS_PER_STATE_STATISTICS,
		GET_JOBS_MONTH_STATISTIC: TYPES.GET_JOBS_MONTH_STATISTIC,
		GET_ACCEPTED_OFFERS_STATISTICS: TYPES.GET_ACCEPTED_OFFERS_STATISTICS,
		GET_DASHBOARD_UNREAD_EMAILS: TYPES.GET_DASHBOARD_UNREAD_EMAILS,
		GET_APPLICATION_SOURCES_STATISTICS: TYPES.GET_APPLICATION_SOURCES_STATISTICS,
		GET_TIME_TO_HIRE_MONTH: TYPES.GET_TIME_TO_HIRE_MONTH,
		GET_TIME_TO_HIRE_YEAR: TYPES.GET_TIME_TO_HIRE_YEAR,
		GET_APPLICATION_YEAR_STATISTICS: TYPES.GET_APPLICATION_YEAR_STATISTICS,
		GET_APPLICATION_MONTH_STATISTICS: TYPES.GET_APPLICATION_MONTH_STATISTICS,
		GET_ATS_ALL_JOBS_LIST: TYPES.GET_ATS_ALL_JOBS_LIST,
		GET_ATS_ALL_CANDIDATE_APPLICATION_BY_JOB_ID: TYPES.GET_ATS_ALL_CANDIDATE_APPLICATION_BY_JOB_ID,
		CREATE_ATS_EMAIL_THREAD: TYPES.CREATE_ATS_EMAIL_THREAD,
		GET_ATS_EMAIL_TEMPLATES: TYPES.GET_ATS_EMAIL_TEMPLATES,
		GET_ATS_EMAIL_THREAD_BY_ID: TYPES.GET_ATS_EMAIL_THREAD_BY_ID,
		POST_ATS_TO_EXISTING_EMAIL_THREAD: TYPES.POST_ATS_TO_EXISTING_EMAIL_THREAD,
		GET_GRAVITY_JOBS: TYPES.GET_GRAVITY_JOBS,
		GET_LIST_OF_GRAVITY_CANDIDATES_BY_JOB_ID: TYPES.GET_LIST_OF_GRAVITY_CANDIDATES_BY_JOB_ID,
		ASSIGN_GRAVITY_CANDIDATE_TO_VACANCY: TYPES.ASSIGN_GRAVITY_CANDIDATE_TO_VACANCY,
	});

	function* atsSaga() {
		yield takeLatest(
			TYPES.ASSIGN_CANDIDATE_TO_VACANCY.REQUESTED,
			SAGAS.assignCandidateToVacancySaga,
		);
		yield takeLatest(
			TYPES.GET_UNAPPLIED_CANDIDATE_JOBS.REQUESTED,
			SAGAS.getUnappliedCandidateJobsSaga,
		);
		yield takeLatest(TYPES.GET_CLIENT_SUBSCRIPTION.REQUESTED, SAGAS.getCurrentSubscriptionSaga);
		yield takeLatest(TYPES.GET_CLIENT_SOCIAL_NETWORKS.REQUESTED, SAGAS.getClientSocialNetworksSaga);
		yield takeLatest(
			TYPES.CHANGE_CLIENT_USER_PASSWORD.REQUESTED,
			SAGAS.changeClientUserPasswordSaga,
		);
		yield takeLatest(
			TYPES.UPDATE_CLIENT_SOCIAL_NETWORKS.REQUESTED,
			SAGAS.updateClientSocialNetworksSaga,
		);
		yield takeLatest(TYPES.GET_CLIENT_EMAIL_SIGNATURE.REQUESTED, SAGAS.getClientEmailSignatureSaga);
		yield takeLatest(
			TYPES.UPDATE_CLIENT_EMAIL_SIGNATURE.REQUESTED,
			SAGAS.updateClientEmailSignatureSaga,
		);
		yield takeLatest(TYPES.GET_CLIENT_AUTO_POP.REQUESTED, SAGAS.getClientAutoPopSaga);
		yield takeLatest(TYPES.UPDATE_CLIENT_AUTO_POP.REQUESTED, SAGAS.updateClientAutoPopSaga);
		yield takeLatest(TYPES.GET_CLIENT_2FA.REQUESTED, SAGAS.getClientTwoFASaga);
		yield takeLatest(TYPES.UPDATE_CLIENT_2FA.REQUESTED, SAGAS.updateClientTwoFASaga);
		yield takeLatest(
			TYPES.GET_CLIENT_SMS_NOTIFICATIONS.REQUESTED,
			SAGAS.getClientSMSNotificationsSaga,
		);
		yield takeLatest(
			TYPES.UPDATE_CLIENT_SMS_NOTIFICATIONS.REQUESTED,
			SAGAS.updateClientSMSNotificationsSaga,
		);
		yield takeLatest(
			TYPES.GET_USER_EMAIL_NOTIFICATIONS.REQUESTED,
			SAGAS.getUserEmailNotificationsSaga,
		);
		yield takeLatest(
			TYPES.UPDATE_USER_EMAIL_NOTIFICATIONS.REQUESTED,
			SAGAS.updateUserEmailNotificationsSaga,
		);
		yield takeLatest(TYPES.GET_ATS_USER_PERSONAL_DATA.REQUESTED, SAGAS.getATSUserPersonalDataSaga);
		yield takeLatest(
			TYPES.UPDATE_ATS_USER_PERSONAL_DATA.REQUESTED,
			SAGAS.updateATSUserPersonalDataSaga,
		);
		yield takeLatest(TYPES.GET_SUBSCRIPTION_TYPES.REQUESTED, SAGAS.getSubscriptionTypesSaga);
		yield takeLatest(TYPES.GET_ATS_USERS.REQUESTED, SAGAS.getAtsUsersSaga);
		yield takeLatest(TYPES.GET_ATS_USER_ROLES.REQUESTED, SAGAS.getAtsUserRolesSaga);
		yield takeLatest(
			TYPES.GET_CANDIDATE_ASSESSMENT_APPLICATION.REQUESTED,
			SAGAS.getCandidateAssessmentApplicationSaga,
		);
		yield takeLatest(TYPES.GET_ATS_USER_BY_ID.REQUESTED, SAGAS.getAtsUserByIdSaga);
		yield takeLatest(TYPES.CREATE_ATS_USER.REQUESTED, SAGAS.createAtsUserSaga);
		yield takeLatest(TYPES.DELETE_ATS_USER.REQUESTED, SAGAS.deleteAtsUserSaga);
		yield takeLatest(TYPES.UPDATE_ATS_USER.REQUESTED, SAGAS.updateAtsUserSaga);
		yield takeLatest(TYPES.ARCHIVE_AND_UNARCHIVE_JOB.REQUESTED, SAGAS.archiveAndUnarchiveSaga);
		yield takeLatest(
			TYPES.GET_ATS_CAREEER_BRANDING_BY_ID.REQUESTED,
			SAGAS.getATSCareerBrandingByClientIdSaga,
		);
		yield takeLatest(TYPES.GET_ORDER_BY_ID.REQUESTED, SAGAS.orderByIdSaga);
		yield takeLatest(TYPES.GET_JOB_UPGRAGED.REQUESTED, SAGAS.getJobUpgrades);
		yield takeLatest(TYPES.GET_CLIENT_CREDITS.REQUESTED, SAGAS.getCreditsSaga);
		yield takeLatest(TYPES.GET_ATS_INVOICES.REQUESTED, SAGAS.getAtsInvoicesSaga);
		yield takeLatest(TYPES.GET_ATS_INVOICE_BY_ID.REQUESTED, SAGAS.getCurrentInvoiceSaga);
		yield takeLatest(TYPES.GET_ATS_BRANDING_BY_ID.REQUESTED, SAGAS.getATSBrandingByClientIdSaga);
		yield takeLatest(TYPES.GET_ATS_TICKETS.REQUESTED, SAGAS.getAtsTicketsSaga);
		yield takeLatest(TYPES.GET_ATS_TICKET_DETAILS.REQUESTED, SAGAS.getAtsTicketDetailsSaga);
		yield takeLatest(TYPES.SAVE_ATS_BRANDING, SAGAS.updateAtsBrandingSaga);
		yield takeLatest(TYPES.SAVE_CAREER_BRANDING, SAGAS.updateAtsCareerBrandingSaga);
		yield takeLatest(TYPES.UPDATE_ATS_BRANDING.REQUESTED, SAGAS.updateAtsBrandingSaga);
		yield takeLatest(TYPES.UPDATE_ATS_CAREER_BRANDING.REQUESTED, SAGAS.updateAtsCareerBrandingSaga);
		yield takeLatest(
			TYPES.GET_CLIENT_APPLICATION_MESSAGES_BY_ID.REQUESTED,
			SAGAS.getClientATSApplicationMessagesByClientIdSaga,
		);
		yield takeLatest(
			TYPES.GET_CLIENT_APPLICATION_NOTIFICATION.REQUESTED,
			SAGAS.getClientApplicationNotificationSaga,
		);
		yield takeLatest(
			TYPES.UPDATE_CLIENT_APPLICATION_MESSAGES.REQUESTED,
			SAGAS.updateClientATSApplicationMessagesSaga,
		);
		yield takeLatest(
			TYPES.GET_AND_SAVE_POST_JOB_CHANGES_FORM.REQUESTED,
			SAGAS.copyPostJobChangesFormSaga,
		);
		yield takeLatest(
			TYPES.GET_AND_SAVE_POST_JOB_CHANGES_FORM.REQUESTED,
			SAGAS.getAndSavePostJobChangesFormSaga,
		);
		yield takeLatest(
			TYPES.UPDATE_REJECTION_MESSAGES.REQUESTED,
			SAGAS.updateRejectionMessagePerJobSaga,
		);
		yield takeLatest(TYPES.GET_ALL_ATS_JOBS.REQUESTED, SAGAS.getAllAtsJobsSaga);
		yield takeLatest(TYPES.GET_ATS_ACTIVE_JOBS.REQUESTED, SAGAS.getAtsActiveJobsSaga);
		yield takeLatest(
			TYPES.GET_CANDIDATE_APPLICATION_BY_JOB_ID.REQUESTED,
			SAGAS.getAtsCandidateApplicationByJobIdSaga,
		);
		yield takeLatest(TYPES.GET_ATS_INTERVIEW_TYPE.REQUESTED, SAGAS.getAtsInterviewTypeSaga);
		yield takeLatest(TYPES.GET_ATS_USER_INTERVIEW.REQUESTED, SAGAS.getAtsUserInterviewSaga);
		yield takeLatest(TYPES.GET_ATS_INTERVIEW_BY_ID.REQUESTED, SAGAS.getAtsInterviewByIdSaga);
		yield takeLatest(TYPES.CANCEL_ATS_INTERVIEW_BY_ID.REQUESTED, SAGAS.cancelAtsInterviewByIdSaga);
		yield takeLatest(TYPES.SAVE_ATS_INTERVIEW.REQUESTED, SAGAS.saveAtsInterviewSaga);
		yield takeLatest(TYPES.UPDATE_ATS_INTERVIEW.REQUESTED, SAGAS.updateAtsInterviewSaga);
		yield takeLatest(TYPES.ORDER_CREDITS.REQUESTED, SAGAS.orderCreditsSaga);
		yield takeLatest(TYPES.ORDER_SMS.REQUESTED, SAGAS.orderSmsSaga);
		yield takeLatest(TYPES.ORDER_INTERVIEWS.REQUESTED, SAGAS.orderInterviewsSaga);
		yield takeLatest(TYPES.CREATE_ORDER_INVOICE.REQUESTED, SAGAS.createOrderInvoiceSaga);
		yield takeLatest(
			TYPES.GET_ATS_INTERVIEW_JOBS_LIST.REQUESTED,
			SAGAS.getAtsInterviewJobsListSaga,
		);
		yield takeLatest(
			TYPES.GET_ATS_EDIT_INTERVIEW_JOBS_LIST.REQUESTED,
			SAGAS.getAtsEditInterviewJobsListSaga,
		);
		yield takeLatest(
			TYPES.GET_ATS_INTERVIEW_CALENDAR_LIST.REQUESTED,
			SAGAS.getAtsInterviewCalendarSaga,
		);
		yield takeLatest(TYPES.RESEND_ATS_INTERVIEW_LINK.REQUESTED, SAGAS.resendAtsInterviewLinkSaga);
		yield takeLatest(TYPES.CREATE_ATS_TICKET.REQUESTED, SAGAS.createATSTicketSaga);
		yield takeLatest(TYPES.GET_CANDIDATE_NOTES_BY_ID.REQUESTED, SAGAS.getCandidateNotesByIdSaga);
		yield takeLatest(TYPES.ADD_NOTE_TO_CANDIDATE.REQUESTED, SAGAS.addNoteToCandidateSaga);
		yield takeLatest(TYPES.GET_CANDIDATES_DATABASE.REQUESTED, SAGAS.getCandidatesDatabaseSaga);
		yield takeLatest(
			TYPES.GET_CANDIDATE_DATABASE_BY_ID.REQUESTED,
			SAGAS.getCandidateDatabaseByIdSaga,
		);
		yield takeLatest(
			TYPES.GET_CANDIDATE_SMS_INFORMATION.REQUESTED,
			SAGAS.getCandidateSMSInformationSaga,
		);
		yield takeLatest(
			TYPES.UPDATE_CANDIDATE_PHONE_NUMBER.REQUESTED,
			SAGAS.updateCandidatePhoneNumberSaga,
		);
		yield takeLatest(TYPES.SEND_SMS_TO_CANDIDATE.REQUESTED, SAGAS.sendSMSToCandidateSaga);
		yield takeLatest(
			TYPES.GET_ATS_VIDEO_INTERVIEW_COUNT.REQUESTED,
			SAGAS.getAtsVideoInterviewCountSaga,
		);
		yield takeLatest(
			TYPES.SHOW_HIDE_CANDIDATE_DATABASE.REQUESTED,
			SAGAS.showHideCandidateDatabaseSaga,
		);
		yield takeLatest(
			TYPES.ASSIGN_CANDIDATE_TO_VACANCY_DATABASE.REQUESTED,
			SAGAS.assignCandidateToVacancyDatabaseSaga,
		);
		yield takeLatest(TYPES.FORWARD_INVOICE.REQUESTED, SAGAS.forwardInvoiceSaga);
		yield takeLatest(TYPES.GET_CANDIDATE_STATISTICS.REQUESTED, SAGAS.getCandidateStatisticsSaga);
		yield takeLatest(
			TYPES.GET_APPLICATIONS_STATISTICS_FOR_LAST_MONTH.REQUESTED,
			SAGAS.getApplicationsStatisticsForLastMonthSaga,
		);
		yield takeLatest(
			TYPES.GET_CANDIDATE_APPLICATIONS_PER_STATE_STATISTICS.REQUESTED,
			SAGAS.getCandidateApplicationPerStateStatisticsSaga,
		);
		yield takeLatest(
			TYPES.GET_APPLICATIONS_STATISTICS_FOR_LAST_MONTH.REQUESTED,
			SAGAS.getApplicationMonthStatistic,
		);
		yield takeLatest(TYPES.GET_JOBS_MONTH_STATISTIC.REQUESTED, SAGAS.getApplicationMonthStatistic);
		yield takeLatest(
			TYPES.GET_ACCEPTED_OFFERS_STATISTICS.REQUESTED,
			SAGAS.getAcceptedOffersStatisticsSaga,
		);
		yield takeLatest(
			TYPES.GET_DASHBOARD_UNREAD_EMAILS.REQUESTED,
			SAGAS.getDashboardUnreadEmailsSaga,
		);
		yield takeLatest(
			TYPES.GET_APPLICATION_SOURCES_STATISTICS.REQUESTED,
			SAGAS.getApplicationSourcesStatisticsSaga,
		);
		yield takeLatest(TYPES.GET_TIME_TO_HIRE_MONTH.REQUESTED, SAGAS.getTimeToHireMonthSaga);
		yield takeLatest(TYPES.GET_TIME_TO_HIRE_YEAR.REQUESTED, SAGAS.getTimeToHireYearSaga);
		yield takeLatest(
			TYPES.GET_APPLICATION_YEAR_STATISTICS.REQUESTED,
			SAGAS.getApplicationYearStatisticsSaga,
		);
		yield takeLatest(
			TYPES.GET_APPLICATION_MONTH_STATISTICS.REQUESTED,
			SAGAS.getApplicationMontStatisticSaga,
		);
		yield takeLatest(TYPES.GET_ATS_ALL_JOBS_LIST.REQUESTED, SAGAS.getAtsAllJobsListSaga);
		yield takeLatest(
			TYPES.GET_ATS_ALL_CANDIDATE_APPLICATION_BY_JOB_ID.REQUESTED,
			SAGAS.getAtsAllCandidateApplicationByJobIdSaga,
		);
		yield takeLatest(TYPES.CREATE_ATS_EMAIL_THREAD.REQUESTED, SAGAS.createAtsEmailThreadSaga);
		yield takeLatest(TYPES.GET_ATS_EMAIL_TEMPLATES.REQUESTED, SAGAS.getAtsEmailTemplatesSaga);
		yield takeLatest(TYPES.GET_ATS_EMAIL_INBOX.REQUESTED, SAGAS.getAtsEmailInboxSaga);
		yield takeLatest(TYPES.GET_ATS_EMAIL_THREAD_BY_ID.REQUESTED, SAGAS.getAtsEmailThreadByIdSaga);
		yield takeLatest(
			TYPES.POST_ATS_TO_EXISTING_EMAIL_THREAD.REQUESTED,
			SAGAS.postAtsToExistingEmailThreadSaga,
		);
		yield takeLatest(TYPES.GET_GRAVITY_JOBS.REQUESTED, SAGAS.getGravityJobsSaga);
		yield takeLatest(
			TYPES.GET_LIST_OF_GRAVITY_CANDIDATES_BY_JOB_ID.REQUESTED,
			SAGAS.getListOfGravityCandidatesByJobIdSaga,
		);
		yield takeLatest(
			TYPES.ASSIGN_GRAVITY_CANDIDATE_TO_VACANCY.REQUESTED,
			SAGAS.assignGravityCandidateToVacancySaga,
		);
	}

	const reducer = combineReducers({ data: atsReducer, loadingReducer });

	return {
		reducer,
		atsSaga,
		atsActions: { ...ACTIONS },
		atsSelectors: SELECTORS,
		atsActionTypes: TYPES,
		atsSagas: SAGAS,
	};
}

export const atsDucks = getATSDucks({ prefix: 'ats', root: 'atsReducer' });
