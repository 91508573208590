import React, { type FC } from 'react';

import { IAssessmentQuestions, TypeOfQuestionEnum } from 'modules/Common/types';
import { COLORS } from 'theme';

import { determineColor } from './CandidateAssessmentForm.helpers';
import { Styled } from './CandidateAssessmentForm.styled';

type CandidateAssessmentFormProps = {
	data: IAssessmentQuestions[];
};

const CandidateAssessmentForm: FC<CandidateAssessmentFormProps> = ({ data }) => {
	return (
		<Styled.Root>
			<h2>Key Questions for the Applicant</h2>
			<Styled.Main>
				{data?.map((item) => (
					<Styled.Box key={item.id}>
						{item?.questionText && <h3>{item.questionText}</h3>}
						<Styled.Field>
							{item?.questionType === TypeOfQuestionEnum.yesNoQn && (
								<Styled.RadioField
									name={`radio-question-${item.id}`}
									value={
										item?.candidateApplicationAnswers
											? item?.candidateApplicationAnswers?.find((e) => e.checked)?.answer
											: null
									}
								>
									{item?.candidateApplicationAnswers?.map((option) => (
										<Styled.RadioOption
											key={`radio-question-${option.answer}`}
											value={option.answer}
											color={
												option.correct && option.checked
													? COLORS.green
													: option.checked
														? COLORS.red
														: 'none'
											}
											aria-readonly
										>
											{option.text}
										</Styled.RadioOption>
									))}
									{item?.assessmentAnswers?.map((option) => (
										<Styled.RadioOption
											key={`radio-question-${option.answer}`}
											value={option.answer}
											aria-readonly
										>
											{option.answer}
										</Styled.RadioOption>
									))}
								</Styled.RadioField>
							)}

							{item?.questionType === TypeOfQuestionEnum.multiChoice && (
								<Styled.CheckboxField>
									<>
										<h4>Candidate Answer(s)</h4>
										{item?.candidateApplicationAnswers?.map((option, index) => (
											<Styled.CheckboxOptionWrapper key={`checkbox-${index}`}>
												<Styled.CheckboxOption
													color={determineColor(option, item)}
													checked={option.checked}
													aria-readonly
												>
													{option.text}
												</Styled.CheckboxOption>
											</Styled.CheckboxOptionWrapper>
										))}
										{item?.assessmentAnswers?.map((option, index) => (
											<Styled.CheckboxOptionWrapper key={`checkbox-${index}`} aria-readonly>
												<Styled.CheckboxOption checked={false}>
													{option.answer}
												</Styled.CheckboxOption>
											</Styled.CheckboxOptionWrapper>
										))}
									</>
								</Styled.CheckboxField>
							)}

							{item?.questionType === TypeOfQuestionEnum.textField && (
								<Styled.TextField>
									{item?.candidateApplicationAnswers?.map((option) => (
										<Styled.TextFieldOption key={option.id}>{option.text}</Styled.TextFieldOption>
									))}
									{item?.assessmentAnswers?.map((option) => (
										<Styled.TextFieldOption key={option.id}>{option.answer}</Styled.TextFieldOption>
									))}
								</Styled.TextField>
							)}
						</Styled.Field>
					</Styled.Box>
				))}
			</Styled.Main>
		</Styled.Root>
	);
};

export default CandidateAssessmentForm;
