import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';

export namespace Styled {
	export const Root = styled.div`
		width: 100%;
	`;

	export const Fields = styled.div`
		display: flex;
		flex-direction: column;
		margin: 16px 0;
		gap: 16px;
	`;

	export const Head = styled.div`
		display: flex;
		justify-content: space-between;

		h2 {
			margin-bottom: 24px;
		}
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: center;
		gap: 8px;
	`;

	export const Button = styled(ButtonComponent)`
		line-height: 28px;
		height: 28px;
		min-width: 28px;
		padding: 4px 8px;
		font-size: 14px;
		gap: 8px;
	`;
}
