import styled from 'styled-components/macro';

import { Direction } from './Container.types';
interface IStyledProps {
	fullWidth: boolean;
	noSpaces: boolean;
	spacing: number;
	direction: Direction;
}

export namespace S {
	export const Root = styled.section<IStyledProps>`
		display: flex;
		${({ fullWidth, noSpaces, spacing, direction, theme: { size } }) => `
			flex-direction: ${direction};
			width: 100%;
    	max-width: ${fullWidth ? '100%' : size.large};
			margin-left: ${fullWidth ? '0' : 'auto'};
			margin-right: ${fullWidth ? '0' : 'auto'};
			margin-top: ${noSpaces ? '0' : '20px'};
			margin-bottom: ${noSpaces ? '0' : '20px'};
			gap: ${spacing}px};
    `}

		& > h2 {
			margin-top: 24px;
		}
	`;
}
