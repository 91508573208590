import React, { type FC, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import _ from 'lodash';

import Box from 'components/Box';
import { ButtonTypes } from 'components/Button/Button.types';
import Table from 'components/Table';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import { atsDucks } from 'modules/ATS/ducks';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from 'modules/Common/constants';
import { IUserAccessDataPaginated } from 'modules/Common/types';
import { GenericType, SettingsRoutes } from 'types';
import { propsFilter } from 'utils/helpers';

import { SettingsPanelMenuList } from '../ATSSettings.types';

import { UserFields } from './ATSUserAccess.constants';
import { userAccessColumns } from './ATSUserAccess.entities';
import { Styled } from './ATSUserAccess.styled';

export type UserAccessProps = {
	loading?: GenericType;
	getAtsUsersRequested: (params: { page: number; size: number }) => void;
	deleteAtsUserRequested: (id: number) => void;
	atsUsers: IUserAccessDataPaginated;
};

const ATSUserAccess: FC<UserAccessProps> = ({
	loading,
	getAtsUsersRequested,
	deleteAtsUserRequested,
	atsUsers,
}) => {
	const navigate = useNavigate();

	const { pageIndex, pageSize, totalCount, data } = atsUsers;

	useEffect(() => {
		getAtsUsersRequested({ page: DEFAULT_CURRENT_PAGE, size: DEFAULT_PAGE_SIZE });
	}, []);

	const handleTableChange = useCallback((page: number, size: number) => {
		getAtsUsersRequested({ page, size: size ?? pageSize });
	}, []);

	const handleAddUser = () => {
		navigate(SettingsRoutes.UserAccessCreate);
	};

	const handleDeleteUser = useCallback((id: number) => {
		deleteAtsUserRequested(id);
	}, []);

	const columns = userAccessColumns(handleDeleteUser);
	const filteredTableData = data?.length && propsFilter(data, UserFields);

	return (
		<Styled.Root>
			<Box style={{ height: '100%' }}>
				<Styled.Head>
					<h2>{SettingsPanelMenuList.UserAccess}</h2>
					<ATSThemedButton buttonType={ButtonTypes.primary} type='button' onClick={handleAddUser}>
						Add User
					</ATSThemedButton>
				</Styled.Head>
				<Styled.PageDesc>You can manage all user accounts below.</Styled.PageDesc>
				{_.isEmpty(atsUsers) ? (
					<div>loading...</div>
				) : (
					<Table
						data={filteredTableData}
						columns={columns}
						loading={loading?.getAtsUsersLoad}
						pageSize={pageSize}
						current={pageIndex}
						total={totalCount}
						onChange={handleTableChange}
					/>
				)}
			</Box>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		loading: atsDucks.atsSelectors.getAtsLoading(state),
		atsUsers: atsDucks.atsSelectors.getAtsUsersState(state),
	}),
	{
		getAtsUsersRequested: atsDucks.atsActions.getAtsUsersRequested,
		deleteAtsUserRequested: atsDucks.atsActions.deleteAtsUserRequested,
	},
)(ATSUserAccess);
