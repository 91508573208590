import styled from 'styled-components/macro';

import { COLORS, device } from 'theme';

export namespace Styled {
	export const Root = styled.main`
		background-color: ${COLORS.white};
        margin-left: 16px;
        padding: 16px;
        box-shadow: 0px 4px 12px 0px rgba(99, 111, 122, 0.16);
        width: 330px;
        max-height: 616px;
        overflow-y: auto;
        
        @media ${device.tablet} {
            margin-top: 56px;
			margin-left: 0;
            height: auto;
		}
	`;
	export const InfoSection = styled.section`
        padding-bottom: 20px;
    `;

	export const InfoSectionTitle = styled.div`
        color: ${COLORS.black};
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        padding-bottom: 16px;
        width: 100%;
    `;
    export const InfoSectionItem = styled.div`
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-bottom: 12px;
    `;
    export const InfoSectionItemText = styled.div`
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        max-width: 220px;
        text-overflow: ellipsis;
        overflow: hidden;
    `;
    export const InfoSectionItemIcons = styled.div`
        display: flex;
        width: 40px;
        justify-content: space-between;
        flex-shrink: 0;

    `;
}
