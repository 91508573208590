import React from 'react';

import { Menu } from 'antd';

import { Styled } from './ActionMenu.styled';

interface ActionMenuProps {
	items: object[];
}

const ActionMenu: React.FC<ActionMenuProps> = ({ items }) => {
	return (
		<Styled.Root>
			<Menu items={items} style={{ width: 200 }} triggerSubMenuAction={'hover'} mode='inline' />
		</Styled.Root>
	);
};

export default ActionMenu;
