import * as React from 'react';
const BlueTownProLogo = ({ fill, width = '29', height = '29', ...props }) => (
	<svg
		id='Layer_1'
		data-name='Layer 1'
		xmlns='http://www.w3.org/2000/svg'
		width={width}
		height={height}
		viewBox='0 0 246.5 282.16'
		{...props}
	>
		<defs>
			<style>{`.cls-1 { fill: ${fill};}`}</style>
		</defs>
		<path
			className='cls-1'
			d='M246.5,118.9v-44.36c0-2.86-1.52-5.5-4-6.93l-38.42-22.18c-2.47-1.43-5.53-1.43-8,0l-26.42,15.25V30.18c0-2.86-1.52-5.5-4-6.93L127.25,1.07c-2.47-1.43-5.53-1.43-8,0l-38.42,22.18c-2.48,1.43-4,4.07-4,6.93v30.5l-26.42-15.25c-2.47-1.43-5.52-1.43-8,0L4,67.61c-2.48,1.43-4,4.07-4,6.93v44.36c0,2.86,1.52,5.5,4,6.93l26.42,15.25-26.42,15.25c-2.48,1.43-4,4.07-4,6.93v44.36c0,2.86,1.52,5.5,4,6.93l38.42,22.18c1.24,.71,2.62,1.07,4,1.07s2.76-.36,4-1.07l26.42-15.25v30.5c0,2.86,1.52,5.5,4,6.93l38.42,22.18c1.24,.71,2.62,1.07,4,1.07s2.76-.36,4-1.07l38.42-22.18c2.48-1.43,4-4.07,4-6.93v-30.5l26.42,15.25c1.24,.71,2.62,1.07,4,1.07s2.76-.36,4-1.07l38.42-22.18c2.48-1.43,4-4.07,4-6.93v-44.36c0-2.86-1.52-5.5-4-6.93l-26.42-15.25,26.42-15.25c2.48-1.43,4-4.07,4-6.93Zm-123.25,57.3l-30.42-17.56v-35.12l30.42-17.56,30.42,17.56v35.12l-30.42,17.56Zm30.42-71.16l-14.42-8.32,14.42-8.32v16.65Zm-60.83,0v-16.65l14.42,8.32-14.42,8.32Zm-16,44.36l-14.42-8.32,14.42-8.32v16.65Zm16,27.71l14.42,8.32-14.42,8.32v-16.65Zm60.83,0v16.65l-14.42-8.32,14.42-8.32Zm16-44.36l14.42,8.32-14.42,8.32v-16.65ZM92.83,34.8l30.42-17.56,30.42,17.56v35.12l-30.42,17.56-30.42-17.56V34.8ZM16,79.16l30.42-17.56,30.42,17.56v35.12l-30.42,17.56-30.42-17.56v-35.12Zm30.42,141.4l-30.42-17.56v-35.12l30.42-17.56,30.42,17.56v35.12l-30.42,17.56Zm107.25,26.8l-30.42,17.56-30.42-17.56v-35.12l30.42-17.56,30.42,17.56v35.12Zm76.83-44.36l-30.42,17.56-30.42-17.56v-35.12l30.42-17.56,30.42,17.56v35.12Zm0-88.72l-30.42,17.56-30.42-17.56v-35.12l30.42-17.56,30.42,17.56v35.12Z'
		/>
	</svg>
);

export default BlueTownProLogo;
