import React, { type FC, useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { Input, message } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import ClipIcon from 'components/SVG/ClipIcon';
import DownloadIcon from 'components/SVG/DownloadIcon';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import { atsDucks } from 'modules/ATS/ducks';
import FieldEditor from 'modules/Common/components/FieldEditor';
import FormBlock from 'modules/Common/components/FormBlock';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { FinancePdf } from 'modules/Common/containers/FinancePdf';
import { commonDucks } from 'modules/Common/ducks';
import {
	IInvoicesDetailedValues,
	InvoicesTypesValuesEnum,
	ReceiptTypesEnum,
} from 'modules/Common/types';
import { convertContentToHtml } from 'modules/Common/utils/editorHelper';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { COLORS } from 'theme';
import { CountriesType, GenericType, Routes } from 'types';
import {
	composeValidators,
	emailValidator,
	lengthValidator,
	requiredFieldValidator,
} from 'utils/validators';

import { createInvoiceFile } from './ForwardInvoice.helpers';
import { Styled } from './ForwardInvoice.styled';

type ForwardInvoiceOptionProps = {
	data: {
		recipientEmail: string;
		topic: string;
		commentsHtml?: string;
		file: File;
	};
	onSuccess?: () => void;
	onError?: () => void;
};

type ForwardInvoiceProps = {
	countries: CountriesType;
	currentInvoice: IInvoicesDetailedValues;
	creditsFields: GenericType[];
	atsLoading: GenericType;
	commonLoading: GenericType;
	getCreditsRequested: () => void;
	getInvoiceByIdRequested: (invoiceId: string) => void;
	forwardInvoiceRequested: (values: ForwardInvoiceOptionProps) => void;
};

const ForwardInvoice: FC<ForwardInvoiceProps> = ({
	countries,
	currentInvoice,
	creditsFields,
	atsLoading,
	commonLoading,
	getCreditsRequested,
	getInvoiceByIdRequested,
	forwardInvoiceRequested,
}) => {
	const { invoiceId } = useParams();
	const { isUK } = countries || {};

	const [invoiceFile, setInvoiceFile] = useState<File | null>(null);
	const invoiceTypesNotToForward = [
		InvoicesTypesValuesEnum.SmsPurchase,
		InvoicesTypesValuesEnum.InterviewPurchase,
	];

	const navigate = useNavigate();

	const onSubmit = (values: { recipientEmail: string; topic: string; commentsHtml?: string }) => {
		if (!invoiceFile) {
			return;
		}
		const preparedValues = {
			...values,
			commentsHtml: values.commentsHtml
				? convertContentToHtml(values.commentsHtml)?.props?.dangerouslySetInnerHTML.__html
				: '',
			file: invoiceFile,
		};

		const options = {
			data: preparedValues,
			onSuccess: () => {
				message.success('Invoice has been forwarded');
				navigate(`${Routes.ATS}${Routes.Finance}`);
			},
			onError: () => {
				message.error('Error while forwarding invoice, please try again later');
			},
		};

		forwardInvoiceRequested(options);
	};

	useEffect(() => {
		if (currentInvoice && invoiceTypesNotToForward.includes(currentInvoice.invoiceType.value)) {
			navigate(`${Routes.ATS}${Routes.Finance}`);
		}
	}, [currentInvoice]);

	useEffect(() => {
		if (isUK) {
			getCreditsRequested();
		}

		if (invoiceId) {
			getInvoiceByIdRequested(invoiceId);
		}
	}, [invoiceId, isUK]);

	useEffect(() => {
		const attachmentPrepared =
			currentInvoice &&
			creditsFields &&
			!invoiceTypesNotToForward.includes(currentInvoice.invoiceType.value);
		if (attachmentPrepared) {
			createInvoiceFile(currentInvoice, creditsFields, setInvoiceFile);
		}
	}, [currentInvoice, creditsFields]);

	const handleCancelButton = () => {
		navigate(`${Routes.ATS}${Routes.Finance}`);
	};

	const submitButtonLoading =
		atsLoading?.getAtsInvoiceByIdLoad ||
		atsLoading?.forwardInvoiceLoa ||
		commonLoading?.getCreditsLoad;

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				initialValues={{}}
				autoComplete='off'
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<FormBlock>
							<Styled.FormHeader>Forward Invoice</Styled.FormHeader>
							<FormBlockLine>
								<Field
									name='recipientEmail'
									validate={composeValidators(requiredFieldValidator, emailValidator)}
								>
									{({ input, meta }) => (
										<FieldWrapper
											name='recipientEmail'
											label='Recipient Email Address'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Input {...input} placeholder='Enter email' autoComplete='off' />
										</FieldWrapper>
									)}
								</Field>
								<Field
									name='topic'
									validate={composeValidators(lengthValidator(1, 255), requiredFieldValidator)}
								>
									{({ input, meta }) => (
										<FieldWrapper
											name='topic'
											label='Topic'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Input {...input} placeholder='Enter message topic' autoComplete='off' />
										</FieldWrapper>
									)}
								</Field>
							</FormBlockLine>
							<FormBlockLine columns={1}>
								<Field name='commentsHtml'>
									{({ input, meta }) => {
										return (
											<FieldWrapper
												name='commentsHtml'
												label='Comments'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												hint='You can send a comment with the email'
											>
												<>
													<FieldEditor
														onChange={(value) => input.onChange(value)}
														defaultValue={input.value}
														placeholder='Enter your message'
														minRaws={20}
													/>
												</>
											</FieldWrapper>
										);
									}}
								</Field>
							</FormBlockLine>
							<Styled.InvoiceDownloadWrap>
								<Styled.AttachmentsTitle>Attachments</Styled.AttachmentsTitle>
								{currentInvoice && creditsFields && (
									<PDFDownloadLink
										document={
											<FinancePdf
												contract={currentInvoice}
												creditsFields={creditsFields}
												type={ReceiptTypesEnum.Invoice}
											/>
										}
										fileName={`${currentInvoice?.btoReference}-invoice.pdf`}
									>
										{({ loading }) => {
											const invoiceName = loading
												? 'Loading invoice ...'
												: `${currentInvoice?.btoReference}-invoice.pdf`;

											return (
												<Styled.Attachment>
													<Styled.AttachmentPin>
														<ClipIcon fill={COLORS.darkGray2} />
													</Styled.AttachmentPin>
													<Styled.AttachmentName>{invoiceName}</Styled.AttachmentName>
													<Styled.AttachmentDownloadIcon>
														<DownloadIcon fill={COLORS.darkGray2} />
													</Styled.AttachmentDownloadIcon>
												</Styled.Attachment>
											);
										}}
									</PDFDownloadLink>
								)}
							</Styled.InvoiceDownloadWrap>
						</FormBlock>
						<Styled.ButtonBox>
							<ATSThemedButton buttonType={ButtonTypes.secondary} onClick={handleCancelButton}>
								{'Cancel'}
							</ATSThemedButton>
							<ATSThemedButton
								type='submit'
								buttonType={ButtonTypes.primary}
								loading={false}
								disabled={!!submitButtonLoading}
							>
								{'Send Email'}
							</ATSThemedButton>
						</Styled.ButtonBox>
					</form>
				)}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
		currentInvoice: atsDucks.atsSelectors.getCurrentInvoice(state),
		creditsFields: commonDucks.commonSelectors.getCreditFields(state),
		atsLoading: atsDucks.atsSelectors.getAtsLoading(state),
		commonLoading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		getInvoiceByIdRequested: atsDucks.atsActions.getAtsInvoiceByIdRequested,
		getCreditsRequested: commonDucks.commonActions.getCreditsRequested,
		forwardInvoiceRequested: atsDucks.atsActions.forwardInvoiceRequested,
	},
)(ForwardInvoice);
