import { Link } from 'react-router-dom';

import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		height: 100%;
		width: 100%;
		background-color: ${COLORS.lightGray3};
		gap: 50px;
	`;

	export const Main = styled.section`
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
	`;

	export const Footer = styled.section``;

	export const InfoLink = styled(Link)`
		display: block;
		width: 100%;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		color: ${COLORS.blue};
		text-align: center;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	`;
}
