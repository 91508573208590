import styled from 'styled-components/macro';

export namespace Styled {
	export const Head = styled.section`
		display: flex;
		justify-content: space-between;
	`;

	export const Content = styled.section`
		display: block;
		min-height: 100%;

		h2 {
			margin-bottom: 16px;
		}
	`;

	export const PdfButtonWrapper = styled.div`
		padding-top: 16px;
	`;
}
