import React, { type FC } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import Box from 'components/Box';
import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import TicketDetails from 'modules/ATS/containers/AtsTicketDetails';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { ITicketDetails } from 'modules/Common/types';
import { GenericType } from 'types';

import { atsDucks } from '../../ducks';

import { Styled } from './AtsTicketDetailsPage.styled';

type AtsTicketsProps = {
	ticketData: ITicketDetails;
	loading?: GenericType;
	getTicketRequested: (id: string) => void;
};

const AtsTicketDetailsPage: FC<AtsTicketsProps> = ({ ticketData, loading, getTicketRequested }) => {
	const { ticketId } = useParams();

	useMount(() => {
		if (ticketId) {
			getTicketRequested(ticketId);
		}
	});

	return (
		<Styled.Root>
			<Styled.Head>
				<PageNavTitle title='Tickets Details' navigationLink={-1} />
			</Styled.Head>

			<>
				{loading || !ticketData ? (
					<Box>
						<Spinner />
					</Box>
				) : (
					<TicketDetails ticketData={ticketData} />
				)}
			</>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		ticketData: atsDucks.atsSelectors.getAtsTicketDetailsState(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state).getAtsTicketDetailsLoad,
	}),
	{
		getTicketRequested: atsDucks.atsActions.getAtsTicketDetailsRequested,
	},
)(AtsTicketDetailsPage);
