import React from 'react';

import JobChangeForm from 'modules/BackOffice/containers/JobChangesFormContainer';
import PageNavTitle from 'modules/Common/components/PageNavTitle';

import { Styled } from './CreateJobPage.styled';

export const CreateJobPage = () => {
	return (
		<>
			<Styled.Head>
				<PageNavTitle title='Job Details' navigationLink={-1} />
			</Styled.Head>
			<Styled.Content>
				<JobChangeForm />
			</Styled.Content>
		</>
	);
};
