import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function LogoutIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2.87868 3.87868C3.44129 3.31607 4.20435 3 5 3H12C12.7956 3 13.5587 3.31607 14.1213 3.87868C14.6839 4.44129 15 5.20435 15 6V8C15 8.55228 14.5523 9 14 9C13.4477 9 13 8.55228 13 8V6C13 5.73478 12.8946 5.48043 12.7071 5.29289C12.5196 5.10536 12.2652 5 12 5H5C4.73478 5 4.48043 5.10536 4.29289 5.29289C4.10536 5.48043 4 5.73478 4 6V18C4 18.2652 4.10536 18.5196 4.29289 18.7071C4.48043 18.8946 4.73478 19 5 19H12C12.2652 19 12.5196 18.8946 12.7071 18.7071C12.8946 18.5196 13 18.2652 13 18V16C13 15.4477 13.4477 15 14 15C14.5523 15 15 15.4477 15 16V18C15 18.7957 14.6839 19.5587 14.1213 20.1213C13.5587 20.6839 12.7957 21 12 21H5C4.20435 21 3.44129 20.6839 2.87868 20.1213C2.31607 19.5587 2 18.7956 2 18V6C2 5.20435 2.31607 4.44129 2.87868 3.87868Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289L21.7071 11.2929C22.0976 11.6834 22.0976 12.3166 21.7071 12.7071L18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071C16.9024 15.3166 16.9024 14.6834 17.2929 14.2929L18.5858 13H7C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H18.5858L17.2929 9.70711C16.9024 9.31658 16.9024 8.68342 17.2929 8.29289Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(LogoutIcon);
