import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${COLORS.white};
	padding: 40px;
	box-shadow: ${COLORS.elevationShadow1};
	border-radius: 6px;
	width: 100%;
`;

export const FormTitle = styled.h3`
	padding-bottom: 24px;
	line-height: 32px;
	font-style: normal;
	color: ${COLORS.black};
`;
