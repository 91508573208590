import { type FC, useState, useCallback } from 'react';

import { Button, Input } from 'antd';
import { EmojiStyle, EmojiClickData, Emoji } from 'emoji-picker-react';

import { BrandingMode } from 'modules/Common/components/BrandingContainer/BrandingContainer.types';
import ControlButtons from 'modules/Common/components/BrandingContainer/ControlButtons';
import { BenefitType } from 'modules/Common/types';

import { Styled } from './CareerPageBenefits.styled';
import EmojiPicker from './MemoPicker';

type CareerPageBenefitsProps = {
	mode?: string;
	benefitsData: BenefitType[];
	changeBenefits: (benifit: BenefitType[]) => void;
};

const CareerPageBenefits: FC<CareerPageBenefitsProps> = ({
	mode,
	benefitsData,
	changeBenefits,
}) => {
	const [benefitsEditingActive, setBenefitsEditActive] = useState(false);

	const [selectedEmoji, setSelectedEmoji] = useState<string>('');
	const [addBenifitTitle, setAddBenifitTitle] = useState<string>('');

	const onClick = useCallback((emojiData: EmojiClickData, event: MouseEvent) => {
		setSelectedEmoji(emojiData.unified);
	}, []);

	const onAddBenifit = useCallback(() => {
		changeBenefits([
			...benefitsData,
			{
				id: `tempId-${benefitsData.length + 1}`,
				benefitText: addBenifitTitle,
				img: selectedEmoji,
				isNew: true,
			},
		]);
		setAddBenifitTitle('');
		setSelectedEmoji('');
	}, [addBenifitTitle, benefitsData, changeBenefits, selectedEmoji]);

	const onCancelAddingBenifit = useCallback(() => {
		setBenefitsEditActive(false);
		setAddBenifitTitle('');
		setSelectedEmoji('');
	}, []);

	const onRemoveBenifit = useCallback(
		(id: number | string) => {
			const benifitToRemove = benefitsData.find((b) => b.id === id);
			if (benifitToRemove?.isNew) {
				changeBenefits(benefitsData.filter((b) => b.id !== id));
			} else {
				changeBenefits(
					benefitsData.map((b) => {
						if (b.id === id) {
							return {
								...b,
								isDeleted: true,
							};
						} else {
							return b;
						}
					}),
				);
			}
		},
		[changeBenefits, benefitsData],
	);

	const benefitsView = benefitsData?.filter((b) => !b?.isDeleted);

	return (
		<Styled.BenefitsWrapper id='benefits'>
			<Styled.BenefitsTitle>Benefits</Styled.BenefitsTitle>

			{mode === BrandingMode.Edit && !benefitsEditingActive && (
				<Styled.BenefitsControl>
					<ControlButtons onEdit={() => setBenefitsEditActive(true)} />
				</Styled.BenefitsControl>
			)}
			<Styled.BenefitsItems>
				{benefitsView?.map((benifit, idx) => (
					<Styled.BenefitsItem key={`${benifit.img}${benifit.benefitText}${idx}`}>
						{mode === BrandingMode.Edit && benefitsEditingActive && (
							<Styled.BenefitsItemRemove>
								<ControlButtons onRemove={() => onRemoveBenifit(benifit.id)} />
							</Styled.BenefitsItemRemove>
						)}
						<Styled.BenefitsItemLogo>
							<Emoji unified={benifit.img} emojiStyle={EmojiStyle.APPLE} size={32} />
						</Styled.BenefitsItemLogo>
						<Styled.BenefitsItemTitle>{benifit.benefitText}</Styled.BenefitsItemTitle>
					</Styled.BenefitsItem>
				))}
			</Styled.BenefitsItems>
			{benefitsEditingActive && mode === BrandingMode.Edit && (
				<Styled.BenefitsFormWrapper>
					<Styled.BenefitsForm>
						<Input
							placeholder='Type your benefit'
							value={addBenifitTitle}
							onChange={(e) => setAddBenifitTitle(e.target.value)}
						/>
						<Styled.BenefitsEmojiPicker>
							<Styled.SelectedEmoji>
								Selected Emoji is:
								{selectedEmoji ? (
									<Emoji unified={selectedEmoji} emojiStyle={EmojiStyle.APPLE} size={32} />
								) : null}
							</Styled.SelectedEmoji>
						</Styled.BenefitsEmojiPicker>
						<EmojiPicker onEmojiClick={onClick} autoFocusSearch={false} />
						<Styled.BenefitsFormSubmit>
							<Button onClick={onAddBenifit} type='primary'>
								Add Benefit
							</Button>
							<Button onClick={onCancelAddingBenifit}>Save and Close</Button>
						</Styled.BenefitsFormSubmit>
					</Styled.BenefitsForm>
				</Styled.BenefitsFormWrapper>
			)}
		</Styled.BenefitsWrapper>
	);
};

export default CareerPageBenefits;
