import React, { type FC, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { Spin } from 'antd';

import Box from 'components/Box';
import { ButtonTypes } from 'components/Button/Button.types';
import FormSwitch from 'components/FormSwitch';
import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import { atsDucks } from 'modules/ATS/ducks';
import FormBlockGrid from 'modules/Common/components/FormBlockGrid';
import { GenericType } from 'types';

import { Styled } from '../ATSSettings.styled';
import { SettingsPanelMenuList } from '../ATSSettings.types';

export type ATS2FAProps = {
	enable2FA: boolean;
	getClient2FA: () => void;
	updateClient2FA: (values: boolean) => void;
	loading: GenericType;
};

const ATS2FA: FC<ATS2FAProps> = ({ enable2FA, getClient2FA, updateClient2FA, loading }) => {
	const onSubmit = (values: boolean) => {
		updateClient2FA(values);
	};

	useMount(() => {
		getClient2FA();
	});

	const initialValues = useMemo(
		() => ({
			enable2FA,
		}),
		[enable2FA],
	);

	const isUpdating = !!loading?.updateClient2FaLoad;

	return (
		<Styled.Root>
			{loading.getClient2FaLoad ? (
				<Spinner fullWidth />
			) : (
				<Form
					onSubmit={onSubmit}
					initialValues={initialValues}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<Box noSpaces fullWidth style={{ flex: '1 1 auto' }}>
								<h2>{SettingsPanelMenuList.Authentication}</h2>
								<p>
									Two factor authentication requires users to confirm their login via a second
									method after using their password.
								</p>
								<FormBlockGrid columns={1}>
									<Field
										name={'enable2FA'}
										initialValue={initialValues.enable2FA}
										style={{ marginBottom: '24px' }}
									>
										{({ input }) => <FormSwitch {...input} title='Enable 2FA for all users' />}
									</Field>
								</FormBlockGrid>
							</Box>

							<Styled.ButtonWrapper>
								<ATSThemedButton buttonType={ButtonTypes.primary} type='submit' disabled={isUpdating}>
									Save
									{isUpdating ? <Spin style={{ marginLeft: '12px' }} /> : ''}
								</ATSThemedButton>
							</Styled.ButtonWrapper>
						</form>
					)}
				/>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		enable2FA: atsDucks.atsSelectors.getClient2FAState(state)?.enable2FA,
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getClient2FA: atsDucks.atsActions.getClient2FARequested,
		updateClient2FA: atsDucks.atsActions.updateClient2FARequested,
	},
)(ATS2FA);
