export const isVimeoLink = (url: string): boolean => {
	const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/(\d+)|(player\.vimeo\.com\/video\/(\d+)))(\/[a-zA-Z0-9_-]+)?(\?h=[a-zA-Z0-9%]+)?(#[a-zA-Z0-9_-]+)?$/;

	return vimeoRegex.test(url);
};

export function createVideoLink(videoLink:string):string {
	const getId = (url:string) => {
		// eslint-disable-next-line no-useless-escape
		const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
		const match = url.match(regExp);

		if (match && match[2].length === 11) {
			return match[2];
		}
	};

	const id = getId(videoLink);

	if (id) {
		return 'https://www.youtube.com/embed/' + id;
	}

	if(isVimeoLink(videoLink)) {
		return videoLink;
	}

	return '';
}
