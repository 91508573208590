import React from 'react';
import { useParams } from 'react-router-dom';

import CareerPageLayout from '../../components/CareerPageLayout';
import CareerPageJobView from '../../containers/CareerPage/CareerPageJobView';

const CareerJobPage = () => {
	const { jobId } = useParams();

	return (
		<CareerPageLayout>
			<CareerPageJobView jobId={jobId} />
		</CareerPageLayout>
	);
};

export default CareerJobPage;
