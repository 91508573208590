const formatKey = (key: string): string =>
	key
		.toLowerCase()
		.replace(/_/g, ' ')
		.replace(/^\w|\s\w/g, (m) => m.toUpperCase())
		.replace('Cv ', 'CV ');

export const convertToPercentage = (data: { [key: string]: number }) => {
	const total = Object.values(data).reduce((acc, curr) => acc + curr, 0);
	const entries = Object.entries(data);
	const resultArray = [];
	const remainingPercentage = 100;

	if (total === 0) {
		entries.forEach(([key]) => {
			resultArray.push({ name: formatKey(key), value: 0 });
		});

		return resultArray;
	}

	// First, calculate percentages with decimals
	const percentages = entries.map(([key, value]) => ({
		key,
		percentage: (value / total) * 100,
	}));

	// Then, calculate the integer part and the sum of integer parts
	const integerPercentages = percentages.map(({ key, percentage }) => ({
		key,
		intPercentage: Math.floor(percentage),
	}));
	const sumOfIntPercentages = integerPercentages.reduce(
		(acc, { intPercentage }) => acc + intPercentage,
		0,
	);

	// Calculate remaining percentage to be distributed due to rounding
	let remainingRoundingPercentage = remainingPercentage - sumOfIntPercentages;

	// Distribute the remaining percentage to the largest decimal parts
	percentages.forEach(({ key, percentage }) => {
		let intPercentage = integerPercentages.find((entry) => entry.key === key).intPercentage;
		if (remainingRoundingPercentage > 0 && percentage - intPercentage > 0) {
			intPercentage += 1;
			remainingRoundingPercentage -= 1;
		}
		resultArray.push({ name: formatKey(key), value: intPercentage });
	});

	return resultArray;
};
