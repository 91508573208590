import React, { type FC, useCallback, useState, useEffect } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { Input } from 'antd';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';

import 'react-phone-number-input/style.css';
import { CandidateInformation } from '../SendSmsTypes';

import { Styled } from './EditReceiverModal.styled';

type EditReceiverModalProps = {
	editedCandidate: CandidateInformation | null;
	loading?: boolean;
	onCancel: () => void;
	handleCandidatePhoneChange: (candidateAppId: number, phoneNumber: string) => void;
};

export const EditReceiverModal: FC<EditReceiverModalProps> = ({
	editedCandidate,
	loading,
	onCancel,
	handleCandidatePhoneChange,
}) => {
	const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
		editedCandidate?.phoneNumber || '',
	);
	const [error, setError] = useState('');

	const handleSubmit = useCallback(() => {
		if (!phoneNumber) return setError('Phone number is required');
		if (!isValidPhoneNumber(phoneNumber)) return setError('Invalid phone number');

		handleCandidatePhoneChange(editedCandidate?.id as number, phoneNumber);
	}, [phoneNumber, editedCandidate]);

	useEffect(() => {
		if (editedCandidate?.phoneNumber) {
			setPhoneNumber(editedCandidate.phoneNumber);
		}

		return () => {
			setPhoneNumber('');
			setError('');
		};
	}, [editedCandidate]);


	return (
		<Styled.Modal open={!!editedCandidate} footer={null} centered onCancel={onCancel} width='auto'>
			<Styled.ModalHead>
				<h3>Edit Receiver</h3>
			</Styled.ModalHead>
			<Styled.ModalContent>
				<Styled.FieldsBlock>
					<FieldWrapper name='candidateName' label='Full Name'>
						<Input disabled value={editedCandidate?.fullName} />
					</FieldWrapper>
				</Styled.FieldsBlock>
				<Styled.FieldsBlock>
					<FieldWrapper name='phoneNumber' label='Phone Number' errorMessage={error} required>
						<Styled.PhoneField
							international
							value={editedCandidate?.phoneNumber || phoneNumber}
							onChange={(value: string) => {
								setPhoneNumber(value);
								setError('');
							}}
							error={error}
						/>
					</FieldWrapper>
				</Styled.FieldsBlock>
				<Styled.ButtonBox>
					<Button
						onClick={handleSubmit}
						buttonType={ButtonTypes.primary}
						disabled={loading}
						loading={loading}
					>
						Save
					</Button>
				</Styled.ButtonBox>
			</Styled.ModalContent>
		</Styled.Modal>
	);
};
