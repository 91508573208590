import { Collapse as CollapseComponent } from 'antd';
import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';
import { COLORS, device } from 'theme';

interface IButtonProps {
	isActive: boolean;
}

export namespace Styled {
	export const Collapse = styled(CollapseComponent)`
		width: 100%;
		background: none;

		&.ant-collapse {
			.ant-collapse-header {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding: 0 16px;
				font-weight: 500;
				font-size: 12px;
				line-height: 36px;
				color: ${COLORS.darkGray2};
				background-color: ${COLORS.lightGray3};
				border-top: 1px solid ${COLORS.lightGray2};
				border-bottom: 1px solid ${COLORS.lightGray2};

				.ant-collapse-header-text {
					display: inline-block;
					margin: 0;
					flex: initial;
				}
			}

			.ant-collapse-item {
				border: none;
			}

			.ant-collapse-content {
				background-color: ${COLORS.white};
				margin-bottom: 40px;
				padding: 5px 0;
				max-height: 350px;
				overflow-y: auto;

				.ant-collapse-content-box {
					display: flex;
					flex-direction: column;
					padding: 0;
				}
			}

			@media ${device.tablet} {
				.ant-collapse-item {
					margin-bottom: 20px;
				}
			}
		}
	`;

	export const CollapsePanel = styled.div`
		position: relative;
		display: inline-block;
		width: 100%;
		margin: 0;
		font-weight: 500;
		font-size: 16px;
		line-height: 40px;
		color: ${COLORS.black};
	`;

	export const CollapseTitle = styled.div``;

	export const Button = styled(ButtonComponent)<IButtonProps>`
		display: block;
		width: 100%;
		border-radius: 0;
		white-space: nowrap;
		text-align: start;
		text-overflow: ellipsis;
		overflow: hidden;
		transition: background-color 0.2s ease;

		&:hover {
			background-color: ${COLORS.settingsItemColor};
		}

		${({ isActive }) => isActive && `background-color: ${COLORS.settingsItemColor}`}
	`;
}
