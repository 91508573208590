import React, { FC, useEffect, useState } from 'react';

import Spinner from 'components/Spinner';
import { CandidateApplicationSourcesType } from 'modules/Common/types';

import { convertToPercentage } from './CandidateSourcesStatistics.helpers';
import { Styled } from './CandidateSourcesStatistics.styled';
import DoughnutChart from './DoughnutChart/DoughnutChart';

type CandidateSourcesStatisticsProps = {
	candidateApplicationSources: CandidateApplicationSourcesType;
	yearMonth?: string;
	loading: boolean;
	getApplicationSources: (yearMonth?: string) => void;
};

type ChartDataType = { name: string; value: number }[];

const defaultEmptyChartList = [
	{ name: 'Bluetown', value: 0 },
	{ name: 'Careers page', value: 0 },
	{ name: 'Custom job email', value: 0 },
	{ name: 'CV Database', value: 0 },
];

const CandidateSourcesStatistics: FC<CandidateSourcesStatisticsProps> = ({
	candidateApplicationSources,
	yearMonth = '',
	loading,
	getApplicationSources,
}) => {
	const [chartData, setChartData] = useState<ChartDataType>(defaultEmptyChartList);
	useEffect(() => {
		getApplicationSources(yearMonth);
	}, [yearMonth]);

	useEffect(() => {
		if (candidateApplicationSources?.applicationsPerSource) {
			const result = convertToPercentage(candidateApplicationSources?.applicationsPerSource);
			setChartData(result);
		}
	}, [candidateApplicationSources]);

	if (loading) {
		return (
			<Styled.Root>
				<Spinner />
			</Styled.Root>
		);
	}

	return (
		<Styled.Root>
			<Styled.Head>
				<Styled.Title>Candidate Source</Styled.Title>
			</Styled.Head>
			<Styled.Content>
				<DoughnutChart data={chartData} />
			</Styled.Content>
		</Styled.Root>
	);
};

export default CandidateSourcesStatistics;
