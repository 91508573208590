import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'antd';

import { getContractsCSV } from 'api/endpoints/contracts';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { Routes, UserRolesType } from 'types';
import { downloadCsv } from 'utils/helpers';

import Contracts from '../../containers/Contracts';

import { Styled } from './ContractsPage.styled';

type ContractPageProps = {
	roles: UserRolesType;
	isClientContext: boolean;
};

const ContractsPage: FC<ContractPageProps> = ({ roles, isClientContext }) => {
	const navigate = useNavigate();
	const { clientId } = useParams();

	const handleDownloadContracts = downloadCsv(() => getContractsCSV(clientId), 'contracts');
	const newContractPath = clientId
		? `${Routes.BOClientContext}/${clientId}${Routes.ContractsCreate}`
		: `${Routes.BackOffice}${Routes.ContractsCreate}`;

	return (
		<>
			<Styled.Head>
				<Styled.Title>Contracts</Styled.Title>
				<Styled.ButtonsBox>
					{roles?.isSuperUser && (
						<Button type='default' htmlType='button' onClick={handleDownloadContracts}>
							Download CSV
						</Button>
					)}
					<Button type='primary' htmlType='button' onClick={() => navigate(newContractPath)}>
						Create Contract
					</Button>
				</Styled.ButtonsBox>
			</Styled.Head>
			<Styled.Content>
				<Contracts clientId={clientId} isClientContext={isClientContext} />
			</Styled.Content>
		</>
	);
};

export default connect(
	(state) => ({
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
	}),
	{},
)(ContractsPage);
