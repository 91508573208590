import { type FC, useState } from 'react';
import { connect } from 'react-redux';

import { acceptedImageFileFormat, DEFAULT_ATS_BG, DEFAULT_LOGO } from 'modules/Common/constants';
import { jobsData, statisticData } from 'modules/Common/constants/AtsBrandingContainer.constants';
import ATSBrandingInner from 'modules/Common/containers/ATSBrandingInner';
import { ClientChangeFormDataType } from 'modules/Common/types';
import { getSingleFileUploaderProps } from 'modules/Common/utils/brandingUploader';

import { backOfficeDucks } from '../../ducks';

type AtsBrandingProps = {
	clientChangesFormData: ClientChangeFormDataType;
	saveClientChangesFormProcess: (props: Partial<ClientChangeFormDataType>) => void;
};

const AtsBrandingContainer: FC<AtsBrandingProps> = ({
	clientChangesFormData,
	saveClientChangesFormProcess,
}) => {
	const { atsBranding, clientName = 'no name LTD' } = clientChangesFormData;
	const { logo = DEFAULT_LOGO, backgroundImage = DEFAULT_ATS_BG } = atsBranding;

	const setLogoUrl = (v: string): void => {
		saveClientChangesFormProcess({
			logo: v,
			atsBranding: {
				...clientChangesFormData.atsBranding,
				logo: v,
			},
			careerBranding: {
				...clientChangesFormData.careerBranding,
				logo: v,
			},
		});
	};
	const setBgUrl = (v: string): void => {
		saveClientChangesFormProcess({
			atsBranding: {
				...clientChangesFormData.atsBranding,
				backgroundImage: v,
			},
		});
	};
	const handleBgRemove = () => {
		setBgUrl('');
	};

	const [colors, setColors] = useState(clientChangesFormData.atsColors);

	const navigationProps = {
		accentColour: colors.accentColour,
		highlightColourAlternate: colors.highlightColourAlternate,
	};

	const ajustColorData = [
		{
			id: 0,
			title: 'Text Colour (Titles)',
			initialValue: colors.textColour,
			onChange: (color: string): void => setColors({ ...colors, textColour: color }),
		},
		{
			id: 1,
			title: 'Header Background',
			initialValue: colors.headerBackground,
			onChange: (color: string): void => setColors({ ...colors, headerBackground: color }),
		},
		{
			id: 2,
			title: 'Highlight Colour',
			initialValue: colors.highlightColour,
			hint: 'The colour of buttons, checkboxes, and toggles. \nWe recommend using dark colours for improved visibility',
			onChange: (color: string): void => setColors({ ...colors, highlightColour: color }),
		},
		{
			id: 3,
			title: 'Menu Colour',
			initialValue: colors.highlightColourAlternate,
			onChange: (color: string): void => setColors({ ...colors, highlightColourAlternate: color }),
		},
		{
			id: 4,
			title: 'Accent Colour in Menu',
			initialValue: colors.accentColour,
			onChange: (color: string): void => setColors({ ...colors, accentColour: color }),
		},
	];
	const applyColors = () => saveClientChangesFormProcess({ atsColors: colors });
	const resetColors = () => setColors(clientChangesFormData.atsColors);

	const bgUploaderProps = getSingleFileUploaderProps(
		(url, responseData) => {
			setBgUrl(responseData);
		},
		{ showUploadList: false, accept: acceptedImageFileFormat },
	);

	const logoUploaderProps = getSingleFileUploaderProps(
		(url, responseData) => {
			setLogoUrl(responseData);
		},
		{ showUploadList: false, accept: acceptedImageFileFormat },
	);

	return (
		<>
			<ATSBrandingInner
				textColour={colors.textColour}
				highlightColour={colors.highlightColour}
				headerBackground={colors.headerBackground}
				handleBgRemove={handleBgRemove}
				clientName={clientName}
				backgroundImage={backgroundImage}
				jobsData={jobsData}
				statisticData={statisticData}
				logo={logo}
				bgUploaderProps={bgUploaderProps}
				logoUploaderProps={logoUploaderProps}
				colors={colors}
				applyColors={applyColors}
				resetColors={resetColors}
				navigationProps={navigationProps}
				ajustColorData={ajustColorData}
				setBgUrl={setBgUrl}
			/>
		</>
	);
};

export default connect(
	(state) => ({
		clientChangesFormData: backOfficeDucks.backOfficeSelectors.getClientChangesFormState(state),
	}),
	{
		saveClientChangesFormProcess: backOfficeDucks.backOfficeActions.saveClientChangesFormProcess,
	},
)(AtsBrandingContainer);
