import type { FC } from 'react';

import { S } from './Container.styled';
import { ContainerProps, Direction } from './Container.types';

export const Container: FC<ContainerProps> = ({
	fullWidth = false,
	noSpaces = false,
	spacing = 0,
	direction = Direction.Column,
	children,
	...props
}) => {
	return (
		<S.Root
			{...props}
			fullWidth={fullWidth}
			noSpaces={noSpaces}
			spacing={spacing}
			direction={direction}
		>
			{children}
		</S.Root>
	);
};
