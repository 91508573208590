import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		padding: 12px 0 36px 0;
		height: 100%;

		h2 {
			margin-bottom: 0;
		}
	`;

	export const Head = styled.section``;

	export const Content = styled.section`
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-top: 70px;
		gap: 40px;
	`;

	export const ContentInner = styled.section`
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: start;
		gap: 80px;
	`;

	export const JobWrapper = styled.section`
		width: 100%;
	`;

	export const Title = styled.h1`
		font-family: 'Raleway';
		margin-bottom: 16px;
		font-weight: 700;
		color: ${({ color = COLORS.blue }) => color};
	`;

	export const Info = styled.div`
		display: flex;
		margin-bottom: 40px;
		font-weight: 500;
		font-size: 18px;
		line-height: 150%;
		gap: 26px;
	`;

	export const Description = styled.div`
		margin-bottom: 40px;
	`;

	export const Label = styled.div`
		margin-left: 10px;
		font-family: 'Raleway';
	`;

	export const InfoItem = styled.div`
		display: flex;
		align-items: center;
	`;
}
