import API from 'api';
import { Routes } from 'types';

import { OrderTypeEnum } from './ATSCreditDetailsPage';

export const getOrderDetails = (orderType: OrderTypeEnum) => {
	const defaultPaymentDetails = {
		paymentEndpoint: API.paymentsService.payForCredit, // default
		backToCreditsLink: `${Routes.ATS}${Routes.Credits}`, // default
	};

	const paymentDetailsMap = {
		CREDITS: {
			paymentEndpoint: API.paymentsService.payForCredit,
			backToCreditsLink: `${Routes.ATS}${Routes.Credits}`,
		},
		SMS: {
			paymentEndpoint: API.paymentsService.payForSMS,
			backToCreditsLink: `${Routes.ATS}${Routes.BuySMSCredits}`,
		},
		INTERVIEW: {
			paymentEndpoint: API.paymentsService.payForInterviews,
			backToCreditsLink: `${Routes.ATS}${Routes.BuyVideoCredits}`,
		},
	};

	return paymentDetailsMap[orderType] || defaultPaymentDetails;
};
