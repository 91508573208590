import styled from 'styled-components/macro';

interface IFieldsBlock {
	columns: number;
}

export namespace Styled {
	export const FieldsBlock = styled.div<IFieldsBlock>`
		display: grid;
		grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
		column-gap: 24px;
		width: 100%;
		gap: 24px;
	`;
}
