import React, { FC, useEffect } from 'react';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';

import Spinner from 'components/Spinner';
import CVIcon from 'components/SVG/CVIcon';
import { JobsStatisticType, IApplicationYear, StatisticPeriodEnum } from 'modules/Common/types';

import { Styled } from './ApplicationChart.styled';
import ApplicationChartMonth from './ApplicationChartMonth';
import ApplicationChartYear from './ApplicationChartYear';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

type ApplicationChartProps = {
	loading: boolean;
	applicatonsYear: IApplicationYear;
	applicationsMonth: JobsStatisticType;
	getMonthApplicationStatistic: (yearMonth?: string) => void;
	getYearApplicationStatistic: () => void;
	currentPeriodType: StatisticPeriodEnum;
	currentPeriod: string;
	setCurrentPeriodType: (period: StatisticPeriodEnum) => null;
	setCurrentPeriod: (period: string) => null;
};

const ApplicationChart: FC<ApplicationChartProps> = ({
	loading,
	applicationsMonth,
	applicatonsYear,
	getMonthApplicationStatistic,
	getYearApplicationStatistic,
	currentPeriodType,
	currentPeriod,
	setCurrentPeriodType,
	setCurrentPeriod,
}) => {
	useEffect(() => {
		if (currentPeriodType === StatisticPeriodEnum.Year) {
			getYearApplicationStatistic();
		} else {
			getMonthApplicationStatistic(currentPeriod);
		}
	}, [currentPeriod, currentPeriodType]);

	if (
		loading ||
		(currentPeriodType === StatisticPeriodEnum.Month && !applicationsMonth?.jobs) ||
		(currentPeriodType === StatisticPeriodEnum.Year && !applicatonsYear?.months)
	) {
		return (
			<Styled.Root>
				<Spinner />
			</Styled.Root>
		);
	}

	if (!applicationsMonth?.jobs?.length && !applicatonsYear?.months) {
		return (
			<Styled.Root>
				<Styled.Header>
					<Styled.Title>Applications</Styled.Title>
				</Styled.Header>
				<Styled.NoActiveJobs>
					<Styled.CVIconContainer>
						<CVIcon width='120' height='124' />
					</Styled.CVIconContainer>
					<Styled.NoJobsText>
						Once the candidates start applying for your jobs, the number of applications will be
						displayed here
					</Styled.NoJobsText>
				</Styled.NoActiveJobs>
			</Styled.Root>
		);
	}

	return (
		<>
			{currentPeriodType === StatisticPeriodEnum.Month ? (
				<ApplicationChartMonth
					applicationsMonth={applicationsMonth}
					currentPeriod={currentPeriod}
					setCurrentPeriod={setCurrentPeriod}
					setCurrentPeriodType={setCurrentPeriodType}
				/>
			) : (
				<ApplicationChartYear
					applicatonsYear={applicatonsYear}
					setCurrentPeriod={setCurrentPeriod}
					setCurrentPeriodType={setCurrentPeriodType}
				/>
			)}
		</>
	);
};

export default ApplicationChart;
