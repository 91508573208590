import { memo } from 'react';

import { IconType } from '../SVG.types';

function CalendarEmpty({ width = '172', height = '144' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 172 144'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M19 144C13.4772 144 9 139.523 9 134L9 31C8.99999 25.4771 13.4771 21 19 21L162 21C167.523 21 172 25.4771 172 31L172 134C172 139.523 167.523 144 162 144L19 144Z'
				fill='#F0F4FF'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12 31L12 134C12 137.866 15.134 141 19 141L162 141C165.866 141 169 137.866 169 134L169 31C169 27.134 165.866 24 162 24L19 24C15.134 24 12 27.134 12 31ZM9 134C9 139.523 13.4772 144 19 144L162 144C167.523 144 172 139.523 172 134L172 31C172 25.4771 167.523 21 162 21L19 21C13.4771 21 8.99999 25.4771 9 31L9 134Z'
				fill='#C8D6FF'
			/>
			<line
				x1='12.5'
				y1='36.5'
				x2='168.5'
				y2='36.5'
				stroke='#C8D6FF'
				strokeWidth='3'
				strokeLinecap='round'
			/>
			<mask id='path-4-inside-1_525_4060' fill='white'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M172 36H9L9 31C9 25.4772 13.4772 21 19 21L162 21C167.523 21 172 25.4772 172 31V36Z'
				/>
			</mask>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M172 36H9L9 31C9 25.4772 13.4772 21 19 21L162 21C167.523 21 172 25.4772 172 31V36Z'
				fill='#C8D6FF'
			/>
			<path
				d='M9 36H6L6 39H9V36ZM172 36V39H175V36H172ZM9 31H6H9ZM19 21V24V21ZM162 21V18V21ZM9 39H172V33H9V39ZM12 36L12 31H6L6 36H12ZM12 31C12 27.134 15.134 24 19 24V18C11.8203 18 6 23.8203 6 31H12ZM19 24L162 24V18L19 18V24ZM162 24C165.866 24 169 27.134 169 31H175C175 23.8203 169.18 18 162 18V24ZM169 31V36H175V31H169Z'
				fill='#C8D6FF'
				mask='url(#path-4-inside-1_525_4060)'
			/>
			<g clipPath='url(#clip0_525_4060)'>
				<g clipPath='url(#clip1_525_4060)'>
					<rect x='17' y='45' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='39' y='45' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='61' y='45' width='15' height='14' rx='4' fill='#E6ECFF' />
					<rect x='83' y='45' width='15' height='14' rx='4' fill='#E6ECFF' />
					<rect x='104' y='45' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='126' y='45' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='148' y='45' width='16' height='14' rx='4' fill='#E6ECFF' />
				</g>
				<g clipPath='url(#clip2_525_4060)'>
					<rect x='17' y='63' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='39' y='63' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='61' y='63' width='15' height='14' rx='4' fill='#E6ECFF' />
					<rect x='83' y='63' width='15' height='14' rx='4' fill='#E6ECFF' />
					<rect x='104' y='63' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='126' y='63' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='148' y='63' width='16' height='14' rx='4' fill='#E6ECFF' />
				</g>
				<g clipPath='url(#clip3_525_4060)'>
					<rect x='17' y='81' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='39' y='81' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='61' y='81' width='15' height='14' rx='4' fill='#E6ECFF' />
					<rect x='83' y='81' width='15' height='14' rx='4' fill='#E6ECFF' />
					<rect x='104' y='81' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='126' y='81' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='148' y='81' width='16' height='14' rx='4' fill='#E6ECFF' />
				</g>
				<g clipPath='url(#clip4_525_4060)'>
					<rect x='17' y='99' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='39' y='99' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='61' y='99' width='15' height='14' rx='4' fill='#E6ECFF' />
					<rect x='83' y='99' width='15' height='14' rx='4' fill='#E6ECFF' />
					<rect x='104' y='99' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='126' y='99' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='148' y='99' width='16' height='14' rx='4' fill='#E6ECFF' />
				</g>
				<g clipPath='url(#clip5_525_4060)'>
					<rect x='17' y='117' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='39' y='117' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='61' y='117' width='15' height='14' rx='4' fill='#E6ECFF' />
					<rect x='83' y='117' width='15' height='14' rx='4' fill='#E6ECFF' />
					<rect x='104' y='117' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='126' y='117' width='16' height='14' rx='4' fill='#E6ECFF' />
					<rect x='148' y='117' width='16' height='14' rx='4' fill='#E6ECFF' />
				</g>
			</g>
			<path
				d='M33.1 43.62H25.56L33.14 33.94C33.24 33.82 33.3 33.68 33.3 33.5V32.36C33.3 32.14 33.16 32 32.92 32H22.46C22.22 32 22.1 32.14 22.1 32.36V34.06C22.1 34.3 22.22 34.44 22.46 34.44H29.62L22.16 44.14C22.04 44.28 22 44.42 22 44.6V45.7C22 45.92 22.12 46.06 22.36 46.06H33.1C33.34 46.06 33.46 45.92 33.46 45.7V44C33.46 43.76 33.34 43.62 33.1 43.62Z'
				fill='#7D9CFC'
			/>
			<ellipse cx='98' cy='76' rx='6' ry='4' fill='#7D9CFC' />
			<path
				d='M52 61C52 61 54.5758 63 60.5 63C66.4242 63 69 61 69 61'
				stroke='#7D9CFC'
				strokeWidth='3'
				strokeLinecap='round'
			/>
			<path
				d='M114 61C114 61 116.576 63 122.5 63C128.424 63 131 61 131 61'
				stroke='#7D9CFC'
				strokeWidth='3'
				strokeLinecap='round'
			/>
			<ellipse cx='60' cy='72.5' rx='7' ry='2.5' fill='#D6E1FF' />
			<ellipse cx='123' cy='72.5' rx='7' ry='2.5' fill='#D6E1FF' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M46.4048 14.9216C45.2457 16.4965 44.5 18.6819 44.5 21H41.5C41.5 18.0714 42.4332 15.2568 43.9886 13.1434C45.5391 11.0366 47.819 9.5 50.5 9.5C55.7817 9.5 59.5 14.7747 59.5 20.5C59.5 26.2253 55.7817 31.5 50.5 31.5C49.6716 31.5 49 30.8284 49 30C49 29.1716 49.6716 28.5 50.5 28.5C53.5026 28.5 56.5 25.2681 56.5 20.5C56.5 15.7319 53.5026 12.5 50.5 12.5C49.0389 12.5 47.5687 13.3401 46.4048 14.9216Z'
				fill='#7D9CFC'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M126.405 14.9216C125.246 16.4965 124.5 18.6819 124.5 21H121.5C121.5 18.0714 122.433 15.2568 123.989 13.1434C125.539 11.0366 127.819 9.5 130.5 9.5C135.782 9.5 139.5 14.7747 139.5 20.5C139.5 26.2253 135.782 31.5 130.5 31.5C129.672 31.5 129 30.8284 129 30C129 29.1716 129.672 28.5 130.5 28.5C133.503 28.5 136.5 25.2681 136.5 20.5C136.5 15.7319 133.503 12.5 130.5 12.5C129.039 12.5 127.569 13.3401 126.405 14.9216Z'
				fill='#7D9CFC'
			/>
			<path
				d='M16.466 17.3556H5.28098L16.5253 2.89758C16.6736 2.71835 16.7627 2.50925 16.7627 2.2404V0.537694C16.7627 0.209103 16.555 0 16.199 0H0.682373C0.326352 0 0.148342 0.209103 0.148342 0.537694V3.07681C0.148342 3.43528 0.326352 3.64438 0.682373 3.64438H11.3037L0.237347 18.1323C0.0593368 18.3414 0 18.5505 0 18.8193V20.4623C0 20.7909 0.17801 21 0.534031 21H16.466C16.822 21 17 20.7909 17 20.4623V17.9232C17 17.5647 16.822 17.3556 16.466 17.3556Z'
				fill='#7D9CFC'
			/>
			<defs>
				<clipPath id='clip0_525_4060'>
					<rect width='147' height='86' fill='white' transform='translate(17 45)' />
				</clipPath>
				<clipPath id='clip1_525_4060'>
					<rect width='147' height='14' fill='white' transform='translate(17 45)' />
				</clipPath>
				<clipPath id='clip2_525_4060'>
					<rect width='147' height='14' fill='white' transform='translate(17 63)' />
				</clipPath>
				<clipPath id='clip3_525_4060'>
					<rect width='147' height='14' fill='white' transform='translate(17 81)' />
				</clipPath>
				<clipPath id='clip4_525_4060'>
					<rect width='147' height='14' fill='white' transform='translate(17 99)' />
				</clipPath>
				<clipPath id='clip5_525_4060'>
					<rect width='147' height='14' fill='white' transform='translate(17 117)' />
				</clipPath>
			</defs>
		</svg>
	);
}

export default memo(CalendarEmpty);
