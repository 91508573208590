import { Select as SelectComponent } from 'antd';
import styled from 'styled-components/macro';

import Button from 'components/Button';
import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		background: ${COLORS.white};
		padding: 24px;
		border-radius: 6px;
		margin-bottom: 24px;
	`;

	export const Head = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
	`;

	export const Column = styled.div``;

	export const FilterSection = styled.div`
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 12px;
	`;

	export const NumberOfInterviews = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px; /* 120% */
		letter-spacing: -0.22px;
	`;

	export const Select = styled(SelectComponent)`
		&.ant-select {
			position: relative;
			height: 40px;
			min-width: 110px;

			.ant-select-selector {
				position: relative;
				height: 40px;
				border: 1px solid ${COLORS.borderColor};
				background-color: ${COLORS.lightGray3};
				box-shadow: ${COLORS.elevationShadow1};

				.ant-select-selection-item {
					font-size: 14px;
					font-weight: 700;
					line-height: 38px;
					color: ${COLORS.darkGray2};
				}
			}
		}
	`;

	export const Title = styled.h4`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.22px;
		margin-bottom: 12px;
	`;

	export const SeeMoreButton = styled(Button)`
		color: ${COLORS.blue};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		padding-right: 0;
		height: auto;
		margin-top: 10px;
		margin-bottom: 12px;
	`;

	export const ButtonBox = styled.div`
		display: flex;
	`;

	export const NoActiveInterviews = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-top: 12px;
		img {
			width: 115px;
			margin-bottom: 24px;
		}
		p {
			color: ${COLORS.darkGray1};
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			margin-bottom: 4px;
		}
	`;

	export const InterviewWrapper = styled.div`
		display: flex;
		justify-content: space-between;
		border-radius: 6px;
		border: 1px solid ${COLORS.lightGray2};
		background: ${COLORS.lightGray3};
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		margin-bottom: 12px;
		padding: 12px;
	`;

	export const InterviewRightCol = styled.div`
		text-align: right;
	`;

	export const InterviewLeftCol = styled.div`
		text-align: left;
	`;

	export const InterviewCandidate = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		word-break: break-word;
	`;

	export const InterviewInfo = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
	`;
}
