import { get, post, put, patch } from 'api/axiosInstance';
import { RequestsEnum } from 'types';

export const getCredits = async () => get(RequestsEnum.Credit);

export const getCreditsDefaultPrices = async () => get(RequestsEnum.CreditsDefault);

export const updateCreditsDefaultPrices = async (values) =>
	put(RequestsEnum.CreditsDefault, values);

export const orderCredits = async (values) => post(RequestsEnum.OrderCredits, values);

export const getOrderById = async (id) => get(`${RequestsEnum.OrderCredits}/${id}`);

export const createOrderInvoice = async (id) => patch(`${RequestsEnum.OrderCredits}/${id}`);

export const orderSMS = async (values) => post(`${RequestsEnum.OrderSms}`, values);

export const orderInterviews = async (values) => post(`${RequestsEnum.OrderInterview}`, values);

const creditsService = {
	getCredits,
	orderCredits,
	getOrderById,
	createOrderInvoice,
	updateCreditsDefaultPrices,
	getCreditsDefaultPrices,
	orderSMS,
	orderInterviews,
};

export default creditsService;
