import React from 'react';

import { delay } from 'lodash';

import API from 'api';
import { ICandidateAttachments } from 'modules/Common/types';
import { getCVCLDownloadUrls, saveFile } from 'utils/helpers';

export const DownloadAttachmentsOptions = (
	candidateAttachments: ICandidateAttachments[],
	name: string,
) => {
	const { CV, CL, cvDownloadUrl, clDownloadUrl } = getCVCLDownloadUrls(candidateAttachments);
	const sanitizedName = name.replace(/\./g, ' ');
	const CVReferenceUuid = CV?.s3FileReference?.referenceUuid || CV?.referenceUuid;
	const CLReferenceUuid = CL?.s3FileReference?.referenceUuid || CL?.referenceUuid;

	const onCVDownload = async () => {
		const cv = await API.candidateApplicationService.getApplicationAttachment(cvDownloadUrl);

		saveFile(cv, `${sanitizedName}_CV`, null, 10);
	};

	const onCoverLetterDownload = async () => {
		const cv = await API.candidateApplicationService.getApplicationAttachment(clDownloadUrl);

		saveFile(cv, `${sanitizedName}_Cover_Letter`, null, 10);
	};

	const onDownloadAll = async () => {
		await onCVDownload();

		delay(async () => {
			await onCoverLetterDownload();
		}, 1000);
	};

	if (!CVReferenceUuid && !CLReferenceUuid) {
		return [];
	}

	if (CVReferenceUuid && CLReferenceUuid) {
		return [
			{
				label: <div onClick={onCVDownload}>CV</div>,
				key: 0,
			},
			{
				label: <div onClick={onCoverLetterDownload}>Cover Letter</div>,
				key: 1,
			},
			{
				label: <div onClick={onDownloadAll}>CV and Cover Letter</div>,
				key: 2,
			},
		];
	}

	if (CLReferenceUuid) {
		return [
			{
				label: <div onClick={onCoverLetterDownload}>Cover Letter</div>,
				key: 1,
			},
		];
	}

	if (CVReferenceUuid) {
		return [
			{
				label: <div onClick={onCVDownload}>CV</div>,
				key: 0,
			},
		];
	}
};
