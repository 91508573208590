import type { ReactElement, ButtonHTMLAttributes } from 'react';

export const enum ButtonTypes {
	primary = 'primary',
	secondary = 'secondary',
	tertiary = 'tertiary',
	default = 'default',
	danger = 'danger',
}

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
	icon?: ReactElement;
	onlyIcon?: boolean;
	disabled?: boolean;
	loading?: boolean;
	buttonColor?: string;
	buttonType?:
		| ButtonTypes.primary
		| ButtonTypes.secondary
		| ButtonTypes.tertiary
		| ButtonTypes.danger
		| ButtonTypes.default;
	isFullWidth?: boolean;
}
