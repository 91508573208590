import React, { FC, useMemo } from 'react';
import { Field, useFormState } from 'react-final-form';

import { Select, Input, DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import FieldWrapper from 'components/FieldWrapper';
import FormSwitch from 'components/FormSwitch';
import { useMount } from 'hooks';
import * as Styled from 'modules/BackOffice/containers/ClientChangesFormBranding/ClientChangesFormBranding.styled';
import FormBlock from 'modules/Common/components/FormBlock';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { ClientChangeFormDataType, PremiumStatusEnum } from 'modules/Common/types';
import { OptionsType } from 'types';
import { positiveIntegersValidator } from 'utils/validators';

import { getSubsMessage, getCurrencySymbol } from './Subscriptions.helpers';

type SubscriptionsProps = {
	saveClientChangesFormProcess: (values: Partial<ClientChangeFormDataType>) => void;
	subscriptionOptions: OptionsType[];
	clientChangesFormData: ClientChangeFormDataType;
	isEdit: boolean;
	isSuperUser: boolean;
};

const subsPeriods = [
	{
		id: 3,
		name: 'Yearly',
		label: 'Yearly',
		value: 2,
	},
	{
		id: 4,
		name: 'Monthly',
		label: 'Monthly',
		value: 1,
	},
];

const Subscriptions: FC<SubscriptionsProps> = ({
	isEdit,
	clientChangesFormData,
	isSuperUser,
	saveClientChangesFormProcess,
}) => {
	const { values } = useFormState();
	const dateFormat = 'YYYY/MM/DD';
	const subsDetails = clientChangesFormData.subsInfo?.subsDetails;
	const currencySymbol = useMemo(() => getCurrencySymbol(values.region), [values]);

	useMount(() => {
		saveClientChangesFormProcess({
			initialTrialEnabled: subsDetails?.trial,
		});
	});

	const initialEndTrialDate = useMemo(
		() =>
			clientChangesFormData?.subsInfo?.trialEndDate
				? dayjs(clientChangesFormData?.subsInfo?.trialEndDate, dateFormat)
				: null,
		[clientChangesFormData],
	);

	const disabledDateBOSA = (current: Dayjs | null): boolean =>
		current ? current < dayjs().startOf('day') : false;

	const disabledDatesBOnotSA = (current: Dayjs | null): boolean => {
		const today = dayjs().startOf('day');
		const sixWeeksFromToday = today.add(3, 'months');

		return current ? current < today || current > sixWeeksFromToday : false;
	};
	const isPostponedOrScheduled = [
		PremiumStatusEnum.Postponed,
		PremiumStatusEnum.Scheduled,
	].includes(subsDetails?.premiumStatus as PremiumStatusEnum);

	const showManualInvoiceBlock =
		!isEdit ||
		(!subsDetails?.premium && !isPostponedOrScheduled) ||
		subsDetails?.premiumStatus === PremiumStatusEnum.Canceled;

	return (
		<FormBlock title='Subscriptions'>
			<div>{subsDetails && getSubsMessage(subsDetails)}</div>
			<Styled.Space height={32} />
			<FormBlockLine columns={3}>
				<Styled.FieldWrapper>
					<Field
						validate={positiveIntegersValidator}
						name='annualPrice'
						initialValue={clientChangesFormData?.subsInfo?.annualPrice}
					>
						{({ input, meta }) => (
							<FieldWrapper
								errorMessage={meta.submitFailed && meta.touched && meta.error}
								name='annualPrice'
								label={currencySymbol ? `Annual Price (${currencySymbol})` : 'Annual Price'}
							>
								<Input {...input} placeholder={`e.g. ${currencySymbol} 39`} />
							</FieldWrapper>
						)}
					</Field>
				</Styled.FieldWrapper>
				<Styled.FieldWrapper>
					<Field
						validate={positiveIntegersValidator}
						name='monthlyPrice'
						initialValue={clientChangesFormData?.subsInfo?.monthlyPrice}
					>
						{({ input, meta }) => (
							<FieldWrapper
								errorMessage={meta.submitFailed && meta.touched && meta.error}
								name='monthlyPrice'
								label={currencySymbol ? `Monthly Price (${currencySymbol})` : 'Monthly Price'}
							>
								<Input {...input} placeholder={`e.g. ${currencySymbol} 559`} />
							</FieldWrapper>
						)}
					</Field>
				</Styled.FieldWrapper>
			</FormBlockLine>
			<Styled.Space height={37} />
			{showManualInvoiceBlock && (
				<FormBlockLine columns={3}>
					<Styled.FieldWrapper>
						<Field name='manualInvoice'>
							{({ input }) => (
								<FieldWrapper hint='We will send an invoice with choosed period.'>
									<FormSwitch {...input} title='Send subscription invoice' />
								</FieldWrapper>
							)}
						</Field>
					</Styled.FieldWrapper>
					{values.manualInvoice && (
						<Styled.FieldWrapper>
							<Field name='subscriptionPlanDuration' initialValue={1}>
								{({ input }) => (
									<FieldWrapper name='subscriptionPlanDuration' label='Plan Period'>
										<Select
											{...input}
											value={input.value || null}
											placeholder='Select period'
											options={subsPeriods}
										/>
									</FieldWrapper>
								)}
							</Field>
						</Styled.FieldWrapper>
					)}
				</FormBlockLine>
			)}
			{(subsDetails || !isEdit) && (
				<FormBlockLine columns={3}>
					<Styled.FieldWrapper>
						<Field
							name='freeTrial'
							initialValue={clientChangesFormData?.subsInfo?.freeTrial || false}
						>
							{({ input }) => (
								<FieldWrapper
									name='freeTrial'
									hint='This feature can be selected just once for Trial Period'
								>
									<FormSwitch
										disabled={
											(subsDetails?.premium ||
												isPostponedOrScheduled ||
												(subsDetails?.trial && !isSuperUser) ||
												(!subsDetails?.trialAvailable && !isSuperUser)) &&
											isEdit
										}
										{...input}
										defaultChecked
										title='Free Trial'
									/>
								</FieldWrapper>
							)}
						</Field>
					</Styled.FieldWrapper>
					<Styled.FieldWrapper>
						<Field name='trialEndDate' initialValue={initialEndTrialDate}>
							{({ input }) => {
								return (
									<FieldWrapper name='trialEndDate' label='Trial Period'>
										<DatePicker
											{...input}
											disabledDate={isSuperUser ? disabledDateBOSA : disabledDatesBOnotSA}
											disabled={
												!values.freeTrial ||
												subsDetails?.premium ||
												isPostponedOrScheduled ||
												(!isSuperUser && subsDetails?.trial && isEdit) ||
												(!isSuperUser && !subsDetails?.trialAvailable && isEdit)
											}
										/>
									</FieldWrapper>
								);
							}}
						</Field>
					</Styled.FieldWrapper>
				</FormBlockLine>
			)}

			<FormBlockLine columns={3}>
				<div hidden>
					<Styled.FieldLabel>Pro Features List</Styled.FieldLabel>
					<Styled.Space height={13} />
					<Field
						name='questionnairesFunctionality'
						initialValue={clientChangesFormData?.questionnairesFunctionality}
					>
						{({ input }) => (
							<FormSwitch defaultChecked {...input} title='Questionnaires Functionality' />
						)}
					</Field>
					<Styled.Space height={18} />
					<Field name='hrModule' initialValue={clientChangesFormData?.hrModule}>
						{({ input }) => <FormSwitch defaultChecked {...input} title='HR Module' />}
					</Field>
					<Styled.Space height={18} />
					<Field
						name='candidatesDatabaseEnabled'
						initialValue={clientChangesFormData?.candidatesDatabaseEnabled}
					>
						{({ input }) => <FormSwitch defaultChecked {...input} title='Candidate Database' />}
					</Field>
				</div>
			</FormBlockLine>
		</FormBlock>
	);
};

export default Subscriptions;
