import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function CanbanViewIcon({ fill = COLORS.darkGray2, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path fill={fill} d='M2 3a1 1 0 011-1h7a1 1 0 110 2H3a1 1 0 01-1-1z'></path>
			<path
				fill={fill}
				fillRule='evenodd'
				d='M2 8a2 2 0 012-2h5a2 2 0 012 2v11a2 2 0 01-2 2H4a2 2 0 01-2-2V8zm2 0h5v11H4V8zM13 8a2 2 0 012-2h5a2 2 0 012 2v4a2 2 0 01-2 2h-5a2 2 0 01-2-2V8zm2 0h5v4h-5V8z'
				clipRule='evenodd'
			></path>
			<path fill={fill} d='M14 2a1 1 0 100 2h7a1 1 0 100-2h-7z'></path>
		</svg>
	);
}

export default memo(CanbanViewIcon);
