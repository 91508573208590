import * as React from 'react';
const BlueTownProLogoTexted = ({ fill, width = '160', height = '40', ...props }) => (
	<svg
		id='Layer_1'
		data-name='Layer 1'
		xmlns='http://www.w3.org/2000/svg'
		width={width}
		height={height}
		viewBox='0 0 1067.23 282.16'
		{...props}
	>
		<defs>
			<style>{`.cls-1 { fill: ${fill};}`}</style>
		</defs>
		<g>
			<path
				className='cls-1'
				d='M355.18,159.57c-.37-.68-1.2-.96-1.91-.66l-9.67,4.16c-1.41,.6-2.09,.78-3.91,.78h-13.72c-1.11,0-1.46-.17-1.86-.57l-4.05-4.05c-.52-.52-.57-.71-.57-1.3v-32.83c0-.59,.05-.78,.57-1.3l4.05-4.05c.39-.39,.7-.57,1.86-.57h12.7c1.82,0,2.5,.17,3.88,.77l9.44,4.27c.71,.32,1.54,.05,1.92-.63l5.4-9.56c.41-.72,.15-1.63-.57-2.04l-8.55-4.84c-3.81-2.14-6-2.67-11.19-2.67h-14.95c-4.08,0-7.53,.72-11.07,4.26l-4.27,4.27c-3.56,3.56-4.82,5.9-4.82,11.97v33.05c0,6.06,1.26,8.41,4.82,11.97l4.27,4.27c3.54,3.54,6.98,4.26,11.07,4.26h15.96c5.11,0,7.32-.37,11.19-2.67l8.54-4.83c.71-.4,.97-1.31,.58-2.03l-5.17-9.44Z'
			/>
			<path
				className='cls-1'
				d='M429.57,108.75c-3.54-3.54-6.98-4.26-11.07-4.26h-21.36c-4.08,0-7.53,.72-11.07,4.26l-4.27,4.27c-3.56,3.56-4.82,5.9-4.82,11.97v33.05c0,6.06,1.26,8.4,4.82,11.97l4.27,4.27c3.54,3.54,6.98,4.26,11.07,4.26h21.36c4.08,0,7.53-.72,11.07-4.26l4.27-4.27c3.56-3.56,4.82-5.9,4.82-11.97v-33.05c0-6.06-1.26-8.41-4.82-11.97l-4.27-4.27Zm-6.5,16.46v32.6c0,.59-.05,.78-.57,1.3l-4.05,4.05c-.41,.4-.75,.57-1.86,.57h-17.54c-1.11,0-1.46-.17-1.86-.57l-4.05-4.05c-.52-.52-.57-.71-.57-1.3v-32.6c0-.59,.05-.78,.57-1.3l4.05-4.05c.39-.39,.7-.57,1.86-.57h17.54c1.16,0,1.47,.18,1.86,.57l4.05,4.05c.52,.52,.57,.71,.57,1.3Z'
			/>
			<path
				className='cls-1'
				d='M518.9,105.16h-11.69c-.83,0-1.5,.67-1.5,1.5v45.19l-32.03-44.05c-1.34-1.83-2.18-2.64-4.58-2.64h-7.2c-2,0-3.3,1.29-3.3,3.3v67.91c0,.83,.67,1.5,1.5,1.5h11.69c.83,0,1.5-.67,1.5-1.5v-44.96l31.93,43.95c1.13,1.51,2.3,2.51,4.35,2.51h7.53c2,0,3.3-1.29,3.3-3.3V106.66c0-.83-.67-1.5-1.5-1.5Z'
			/>
			<path
				className='cls-1'
				d='M602.54,105.16h-11.69c-.83,0-1.5,.67-1.5,1.5v45.19l-32.03-44.05c-1.34-1.83-2.18-2.64-4.58-2.64h-7.2c-2,0-3.3,1.29-3.3,3.3v67.91c0,.83,.67,1.5,1.5,1.5h11.69c.83,0,1.5-.67,1.5-1.5v-44.96l31.93,43.95c1.13,1.51,2.3,2.51,4.35,2.51h7.53c2,0,3.3-1.29,3.3-3.3V106.66c0-.83-.67-1.5-1.5-1.5Z'
			/>
			<path
				className='cls-1'
				d='M675.28,163.85h-33.8v-15.44h27.17c.83,0,1.5-.67,1.5-1.5v-11.02c0-.83-.67-1.5-1.5-1.5h-27.17v-15.21h33.01c.83,0,1.5-.67,1.5-1.5v-11.02c0-.83-.67-1.5-1.5-1.5h-43.62c-3.31,0-4.98,1.68-4.98,4.98v62.73c0,3.31,1.68,4.99,4.98,4.99h44.41c.83,0,1.5-.67,1.5-1.5v-11.02c0-.83-.67-1.5-1.5-1.5Z'
			/>
			<path
				className='cls-1'
				d='M746.75,159.57c-.37-.68-1.2-.96-1.91-.66l-9.67,4.16c-1.41,.6-2.09,.78-3.91,.78h-13.72c-1.11,0-1.46-.17-1.86-.57l-4.05-4.05c-.52-.52-.57-.71-.57-1.3v-32.83c0-.59,.05-.78,.57-1.3l4.05-4.05c.39-.39,.7-.57,1.86-.57h12.71c1.82,0,2.5,.17,3.88,.77l9.44,4.27c.71,.32,1.54,.05,1.92-.63l5.4-9.56c.41-.72,.15-1.63-.57-2.04l-8.55-4.84c-3.81-2.14-6-2.67-11.19-2.67h-14.95c-4.08,0-7.53,.72-11.07,4.26l-4.27,4.27c-3.56,3.56-4.82,5.9-4.82,11.97v33.05c0,6.06,1.26,8.4,4.82,11.97l4.27,4.27c3.54,3.54,6.98,4.26,11.07,4.26h15.96c5.11,0,7.32-.37,11.19-2.67l8.54-4.83c.71-.4,.97-1.31,.58-2.03l-5.17-9.44Z'
			/>
			<path
				className='cls-1'
				d='M822,105.16h-55.31c-.83,0-1.5,.67-1.5,1.5v11.02c0,.83,.67,1.5,1.5,1.5h19.86v57.19c0,.83,.67,1.5,1.5,1.5h12.59c.83,0,1.5-.67,1.5-1.5v-57.19h19.86c.83,0,1.5-.67,1.5-1.5v-11.02c0-.83-.67-1.5-1.5-1.5Z'
			/>
			<path
				className='cls-1'
				d='M901.98,109.2c-3.43-3.43-6.45-4.04-11.52-4.04h-32.27c-3.31,0-4.99,1.68-4.99,4.98v66.22c0,.83,.67,1.5,1.5,1.5h12.59c.83,0,1.5-.67,1.5-1.5v-19.86h21.66c5.07,0,8.08-.6,11.52-4.04l3.15-3.15c3.48-3.48,4.49-5.39,4.49-11.4v-14.17c0-6.01-1.01-7.93-4.49-11.4l-3.15-3.15Zm-7.84,15.23v12.82c0,.85-.11,1.17-.91,1.97l-2.36,2.36c-.74,.74-1.15,.91-2.2,.91h-19.86v-23.31h19.86c1.05,0,1.46,.17,2.2,.91l2.36,2.36c.8,.8,.91,1.12,.91,1.97Z'
			/>
			<path
				className='cls-1'
				d='M976.61,152.57c.5-.41,.98-.84,1.48-1.34l3.15-3.15c3.48-3.48,4.49-5.39,4.49-11.4v-12.93c0-6.01-1.01-7.93-4.49-11.4l-3.15-3.15c-3.43-3.43-6.45-4.04-11.52-4.04h-32.94c-3.31,0-4.99,1.68-4.99,4.98v66.22c0,.83,.67,1.5,1.5,1.5h12.59c.83,0,1.5-.67,1.5-1.5v-21.1h17.16l8.73,21.66c.23,.57,.78,.94,1.39,.94h13.38c.5,0,.97-.25,1.25-.67s.33-.95,.14-1.41l-9.67-23.21Zm-6.37-28.15v12.03c0,.44-.02,.64-.57,1.19l-3.04,3.04c-.39,.39-.7,.57-1.86,.57h-20.54v-22.07h20.42c1.22,0,1.61,.21,2.31,.91l2.36,2.36c.8,.8,.91,1.12,.91,1.97Z'
			/>
			<path
				className='cls-1'
				d='M1062.41,113.02l-4.27-4.27c-3.54-3.54-6.98-4.26-11.07-4.26h-21.36c-4.08,0-7.53,.72-11.07,4.26l-4.27,4.27c-3.56,3.56-4.82,5.9-4.82,11.97v33.05c0,6.06,1.26,8.41,4.82,11.97l4.27,4.27c3.54,3.54,6.98,4.26,11.07,4.26h21.36c4.08,0,7.53-.72,11.07-4.26l4.27-4.27c3.56-3.56,4.82-5.9,4.82-11.97v-33.05c0-6.06-1.26-8.41-4.82-11.97Zm-10.77,12.19v32.6c0,.59-.05,.78-.57,1.3l-4.05,4.05c-.41,.4-.75,.57-1.86,.57h-17.54c-1.11,0-1.46-.17-1.86-.57l-4.05-4.05c-.52-.52-.57-.71-.57-1.3v-32.6c0-.59,.05-.78,.57-1.3l4.05-4.05c.39-.39,.7-.57,1.86-.57h17.54c1.16,0,1.47,.18,1.86,.57l4.05,4.05c.52,.52,.57,.71,.57,1.3Z'
			/>
		</g>
		<path
			className='cls-1'
			d='M246.5,118.9v-44.36c0-2.86-1.52-5.5-4-6.93l-38.42-22.18c-2.47-1.43-5.53-1.43-8,0l-26.42,15.25V30.18c0-2.86-1.52-5.5-4-6.93L127.25,1.07c-2.47-1.43-5.53-1.43-8,0l-38.42,22.18c-2.48,1.43-4,4.07-4,6.93v30.5l-26.42-15.25c-2.47-1.43-5.52-1.43-8,0L4,67.61c-2.48,1.43-4,4.07-4,6.93v44.36c0,2.86,1.52,5.5,4,6.93l26.42,15.25-26.42,15.25c-2.48,1.43-4,4.07-4,6.93v44.36c0,2.86,1.52,5.5,4,6.93l38.42,22.18c1.24,.71,2.62,1.07,4,1.07s2.76-.36,4-1.07l26.42-15.25v30.5c0,2.86,1.52,5.5,4,6.93l38.42,22.18c1.24,.71,2.62,1.07,4,1.07s2.76-.36,4-1.07l38.42-22.18c2.48-1.43,4-4.07,4-6.93v-30.5l26.42,15.25c1.24,.71,2.62,1.07,4,1.07s2.76-.36,4-1.07l38.42-22.18c2.48-1.43,4-4.07,4-6.93v-44.36c0-2.86-1.52-5.5-4-6.93l-26.42-15.25,26.42-15.25c2.48-1.43,4-4.07,4-6.93Zm-123.25,57.3l-30.42-17.56v-35.12l30.42-17.56,30.42,17.56v35.12l-30.42,17.56Zm30.42-71.16l-14.42-8.32,14.42-8.32v16.65Zm-60.83,0v-16.65l14.42,8.32-14.42,8.32Zm-16,44.36l-14.42-8.32,14.42-8.32v16.65Zm16,27.71l14.42,8.32-14.42,8.32v-16.65Zm60.83,0v16.65l-14.42-8.32,14.42-8.32Zm16-44.36l14.42,8.32-14.42,8.32v-16.65ZM92.83,34.8l30.42-17.56,30.42,17.56v35.12l-30.42,17.56-30.42-17.56V34.8ZM16,79.16l30.42-17.56,30.42,17.56v35.12l-30.42,17.56-30.42-17.56v-35.12Zm30.42,141.4l-30.42-17.56v-35.12l30.42-17.56,30.42,17.56v35.12l-30.42,17.56Zm107.25,26.8l-30.42,17.56-30.42-17.56v-35.12l30.42-17.56,30.42,17.56v35.12Zm76.83-44.36l-30.42,17.56-30.42-17.56v-35.12l30.42-17.56,30.42,17.56v35.12Zm0-88.72l-30.42,17.56-30.42-17.56v-35.12l30.42-17.56,30.42,17.56v35.12Z'
		/>
	</svg>
);

export default BlueTownProLogoTexted;
