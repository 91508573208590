import { AxiosError } from 'axios';

interface ErrorResponse {
	errorMessage: string;
}

const isAxiosError = (error: unknown): error is AxiosError<ErrorResponse> => {
	return !!(error as AxiosError).isAxiosError;
};

const handleErrorMessage = (error: string, updateErrorMessage: (message: string) => void) => {
	if (error.includes('Wrong pwd parameter')) {
		updateErrorMessage('Something is wrong with the link, please try again');
	}
	if (error.includes('was already removed from candidate database')) {
		updateErrorMessage('Candidate application was already removed from candidate database');
	}
	if (error.includes('Candidate application with pwd=')) {
		updateErrorMessage(error);
	}
};

export const handleError = (error: unknown, updateErrorMessage: (message: string) => void) => {
	if (isAxiosError(error)) {
		if (error.response?.data?.errorMessage) {
			handleErrorMessage(error.response.data.errorMessage, updateErrorMessage);
		}
	} else if (typeof error === 'string') {
		updateErrorMessage(error);
	} else {
		updateErrorMessage('Something went wrong, please try again later');
	}
};
