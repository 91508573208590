import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams, Link } from 'react-router-dom';

import { Modal } from 'antd';
import _ from 'lodash';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import CountBadge from 'modules/ATS/components/CountBadge';
import { atsDucks } from 'modules/ATS/ducks';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { Routes } from 'types';

import ArrangeInterviewCreate from '../../containers/ArrangeInterview/ArrangeInterviewCreate';
import ArrangeInterviewEdit from '../../containers/ArrangeInterview/ArrangeInterviewEdit';

import { Styled } from './ATSArrangeInterviewPage.styled';

interface ICountData {
	available: number;
	total: number;
}

const ATSArrangeInterviewPage = ({
	interviewVideoCount,
}: {
	interviewVideoCount: Record<string, ICountData>;
}) => {
	const { interviewId } = useParams();
	const { state } = useLocation();
	const { pathname } = useLocation();
	const isEditMode = pathname.includes(Routes.ArrangeInterviewEdit);
	const { candidate, jobId } = state || {};
	const title =
		isEditMode && candidate ? `Interview Details: ${candidate?.fullName}` : 'Arrange Interview';
	const [modalOpen, setModalOpen] = useState<string>('');

	const renderCountBadges = (data: Record<string, ICountData>) => {
		const yearMonthsArr = Object.keys(data);

		return yearMonthsArr.map((yearMonth) => (
			<CountBadge
				key={yearMonth}
				count={data[yearMonth]}
				yearMonth={yearMonth}
				counterName='Video Interviews'
			/>
		));
	};

	const handleOpenModal = (monthName: string) => {
		setModalOpen(monthName);
	};

	const handleCloseModal = () => {
		setModalOpen('');
	};

	return (
		<>
			<Styled.Head>
				<PageNavTitle title={title} navigationLink={-1} />
				<Styled.InterviewCounter>
					{!_.isEmpty(interviewVideoCount) && renderCountBadges(interviewVideoCount)}
				</Styled.InterviewCounter>
			</Styled.Head>
			<Styled.Content>
				{isEditMode && interviewId && jobId ? (
					<ArrangeInterviewEdit
						jobId={jobId}
						interviewId={interviewId}
						isEditMode={isEditMode}
						handleOpenModal={handleOpenModal}
					/>
				) : (
					<ArrangeInterviewCreate
						jobId={jobId}
						candidateId={candidate}
						handleOpenModal={handleOpenModal}
					/>
				)}
			</Styled.Content>
			<Modal open={!!modalOpen} footer={null} centered onCancel={handleCloseModal} width={500}>
				<Styled.ConfirmModalTitle>
					The limit of allowed interviews has been reached. If you would like to purchase additional
					package please click this{' '}
					{
						<Link
							to={`${Routes.ATS}${Routes.BuyVideoCredits}`}
							state={{ prevPage: pathname, yearMonth: modalOpen }}
						>
							link
						</Link>
					}
					.
				</Styled.ConfirmModalTitle>
				<Styled.ConfirmModalButtons>
					<Button buttonType={ButtonTypes.secondary} onClick={handleCloseModal}>
						Cancel
					</Button>
				</Styled.ConfirmModalButtons>
			</Modal>
		</>
	);
};

export default connect((state) => ({
	interviewVideoCount: atsDucks.atsSelectors.getInterviewVideoCount(state),
}))(ATSArrangeInterviewPage);
