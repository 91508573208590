import _ from 'lodash';

import { CreditDefaultBundleType } from 'modules/Common/types';

export const prepareCreditsDefaultValues = (credits: CreditDefaultBundleType[]):{[key:string]: number} =>
	credits?.reduce((acc, cr) => {
		_.keys(cr.defaultBundles).forEach((creditBundle) => {
			acc[`price-${cr.id}-${creditBundle}`] = cr.defaultBundles[creditBundle];
		});

		return acc;
	}, {});

export const prepareCreditsDefaultValuesSubmit = (creditPricesFields:{[key:string]: number}):CreditDefaultBundleType[] =>
	_.values(
		_.keys(creditPricesFields).reduce((acc: object, fieldName: string) => {
			const [, credit, bundle] = fieldName.split('-');

			if (acc[credit]) {
				acc[credit].defaultBundles[bundle] = creditPricesFields[fieldName];
			} else {
				acc[credit] = {
					id: credit,
					defaultBundles: {
						[bundle]: creditPricesFields[fieldName],
					},
				};
			}

			return acc;
		}, {}),
	);
