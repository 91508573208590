import React, { type FC } from 'react';

import { ReactParent } from 'types';

import { Styled } from './Badge.styled';

type BadgeProps = ReactParent & {
	dot?: boolean;
};

const Badge: FC<BadgeProps> = ({ dot = false, children }) => {
	return <Styled.IconBadge dot={dot}>{children}</Styled.IconBadge>;
};

export default Badge;
