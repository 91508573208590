import styled from 'styled-components/macro';

import { FormBlock, FormBlockProps } from 'modules/Common/components/FormBlock/FormBlock';
import { COLORS } from 'theme';

type FormBlockPropsExtended = FormBlockProps & {
	clientContextId?: string;
};

export namespace Styled {
	export const FormBlockWrap = styled(FormBlock).attrs<FormBlockPropsExtended>(
		({ clientContextId }) => ({
			style: clientContextId
				? {
					border: 'none',
					boxShadow: 'none',
					padding: '0',
				  }
				: {},
		}),
	)<FormBlockPropsExtended>``;

	export const Price = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;
		align-self: flex-end;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;

		span {
			font-size: 16px;
			line-height: 24px;
		}
	`;

	export const TotalPrice = styled.div`
		display: flex;
		justify-content: space-between;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;
	`;

	export const TotalPriceSmallSize = styled.div`
		display: flex;
		justify-content: space-between;
		margin-bottom: 32px;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
	`;

	export const Line = styled.div`
		width: 100%;
		height: 1px;
		margin: 32px 0;
		border-bottom: 1px dashed ${COLORS.lightBlue1};
	`;
}
