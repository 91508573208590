import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';

import Box from 'components/Box';
import { MenuList } from 'modules/Common/types';
import { Routes } from 'types';

import JobsListContainer from '../../containers/JobsListContainer';

import { Styled } from './JobsListPage.styled';

export const JobsListPage = () => {
	const navigate = useNavigate();

	return (
		<>
			<Styled.Head>
				<Styled.Title>{MenuList.Vacancies}</Styled.Title>
				<Styled.ButtonsBox>
					<Button
						type='primary'
						htmlType='button'
						onClick={() => navigate(`${Routes.BackOffice}${Routes.CreateJob}`)}
					>
						Create New
					</Button>
				</Styled.ButtonsBox>
			</Styled.Head>
			<Styled.Content>
				<Box>
					<JobsListContainer pageLink={`${Routes.BackOffice}`} />
				</Box>
			</Styled.Content>
		</>
	);
};
