import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;

		h3 {
			margin-bottom: 8px;
		}

		p {
			margin-bottom: 24px;
			line-height: 24px;
		}

		textarea {
			white-space: break-spaces;
		}
	`;

	export const MessagesBox = styled.div`
		margin-bottom: 32px;

		&:last-of-type {
			margin-bottom: 0;
		}
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
	`;
}
