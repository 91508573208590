import React, { type FC, type FormEvent, memo, useEffect, useRef, useState } from 'react';

import Button from 'components/Button';
import FieldWrapper from 'components/FieldWrapper';
import DeleteIcon from 'components/SVG/DeleteIcon';
import { MESSAGES } from 'modules/Common/constants';
import { AQFieldProps, IAnswersOptions } from 'modules/Common/types';
import { COLORS } from 'theme';

import { Styled } from './AQCheckboxField.styled';

const AQCheckboxField: FC<AQFieldProps> = ({ options, onChangeCallback, readOnly }) => {
	const [showAddField, setAddField] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const inputRef = useRef(null);

	const handleCheckboxChange = (option: IAnswersOptions, index: number) => {
		if (!options) return null;

		const updatedOptions = options?.map((opt) => {
			if (opt.answer === option.answer) {
				return { ...opt, correct: !opt.correct };
			}

			return opt;
		});

		onChangeCallback && onChangeCallback(updatedOptions);
	};

	const handleChange = (event: FormEvent) => {
		event.preventDefault();

		errorMessage && setErrorMessage('');
	};

	const handleDelete = (event: FormEvent, index: number) => {
		if (!options) return;
		event.preventDefault();

		const newOptions = [...options];
		newOptions?.splice(index, 1);
		onChangeCallback && onChangeCallback(newOptions);
	};

	const handleAddNewItem = (event: FormEvent) => {
		event.preventDefault();

		setAddField(true);
	};

	const handleEnterNewItem = (event: FormEvent) => {
		event.preventDefault();
		const { value } = event.target as HTMLFormElement;

		errorMessage && setErrorMessage('');

		if (!value) {
			setErrorMessage(MESSAGES.mandatoryField);

			return;
		}

		const isValueExist = options?.find((option) => option.answer === value);
		if (isValueExist) {
			setErrorMessage(MESSAGES.incorrectValue);

			return;
		}

		const addNewItem = [...(options ?? []), { answer: value, correct: false }];

		onChangeCallback && onChangeCallback(addNewItem);
		setAddField(false);
	};

	const handleCancelAddField = (event: FormEvent) => {
		event.preventDefault();
		const { value } = event.target as HTMLFormElement;

		if (!value) {
			errorMessage && setErrorMessage('');

			setAddField(false);
		}
	};

	useEffect(() => {
		if (showAddField) {
			(inputRef?.current as HTMLInputElement | null)?.focus();
		}
	}, [showAddField]);

	return (
		<Styled.Root>
			<h4>Possible and correct answers </h4>
			<p>Please check all the correct answers for your question</p>
			<Styled.CheckboxField>
				{options?.map((option, index) => (
					<Styled.CheckboxOptionWrapper key={`checkbox-${index}`}>
						<Styled.CheckboxOption
							value={option.answer}
							checked={option.correct}
							onChange={() => handleCheckboxChange(option, index)}
							disabled={readOnly}
						>
							{option.answer}
						</Styled.CheckboxOption>
						{options?.length >= 3 && (
							<Button
								type='button'
								icon={<DeleteIcon fill={COLORS.darkGray1} />}
								onClick={(e) => handleDelete(e, index)}
								disabled={readOnly}
							/>
						)}
					</Styled.CheckboxOptionWrapper>
				))}
			</Styled.CheckboxField>

			<Styled.AddFieldWrapper>
				{showAddField ? (
					<FieldWrapper isFixed name='addNewField' errorMessage={errorMessage}>
						<Styled.FieldInput
							ref={inputRef}
							type='text'
							name='addNewField'
							onPressEnter={handleEnterNewItem}
							onBlur={handleCancelAddField}
							onChange={handleChange}
							placeholder='Enter new answer and press enter button'
							autoComplete='off'
							readOnly={readOnly}
							allowClear
						/>
					</FieldWrapper>
				) : (
					<Styled.Button type='button' onClick={handleAddNewItem} disabled={readOnly}>
						Add 1 more
					</Styled.Button>
				)}
			</Styled.AddFieldWrapper>
		</Styled.Root>
	);
};

export default memo(AQCheckboxField);
