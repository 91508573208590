import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function MuteIcon({ active, width = '80', height = '80' }: IconType) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={height}
			height={width}
			fill='none'
			viewBox='0 0 80 80'
		>
			<g filter='url(#filter0_d_188_1252)'>
				<rect
					width='56'
					height='56'
					x='12'
					y='8'
					fill='#fff'
					fillOpacity='0.5'
					rx='28'
					shapeRendering='crispEdges'
				></rect>
				<path
					fill={active ? COLORS.black : COLORS.red}
					fillRule='evenodd'
					d='M40 19.333A6.667 6.667 0 0033.333 26v8.333a6.667 6.667 0 0013.334 0V26A6.667 6.667 0 0040 19.333z'
					clipRule='evenodd'
				></path>
				<path
					fill={active ? COLORS.black : COLORS.red}
					d='M41.663 47.559c.003.035.004.071.004.108V51a1.667 1.667 0 11-3.334 0v-3.333c0-.037.002-.073.004-.108-6.66-.874-11.67-6.928-11.67-14.059a1.667 1.667 0 013.333 0c0 6.093 4.584 10.833 10 10.833s10-4.74 10-10.833a1.667 1.667 0 013.333 0c0 7.131-5.01 13.185-11.67 14.059z'
				></path>
			</g>
			<defs>
				<filter
					id='filter0_d_188_1252'
					width='80'
					height='80'
					x='0'
					y='0'
					colorInterpolationFilters='sRGB'
					filterUnits='userSpaceOnUse'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
					<feColorMatrix
						in='SourceAlpha'
						result='hardAlpha'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
					></feColorMatrix>
					<feOffset dy='4'></feOffset>
					<feGaussianBlur stdDeviation='6'></feGaussianBlur>
					<feComposite in2='hardAlpha' operator='out'></feComposite>
					<feColorMatrix values='0 0 0 0 0.387326 0 0 0 0 0.436308 0 0 0 0 0.479167 0 0 0 0.16 0'></feColorMatrix>
					<feBlend in2='BackgroundImageFix' result='effect1_dropShadow_188_1252'></feBlend>
					<feBlend in='SourceGraphic' in2='effect1_dropShadow_188_1252' result='shape'></feBlend>
				</filter>
			</defs>
		</svg>
	);
}

export default memo(MuteIcon);
