import styled from 'styled-components/macro';

export const Form = styled.div`
	width: 100%;

	form {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
`;
