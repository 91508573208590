import React, { memo, useMemo } from 'react';

import TextArea from 'antd/es/input/TextArea';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import { ActivityTypeEnum, IActivityNotes, IUser, TagColoursType } from 'modules/Common/types';
import { assignColorsToIds, getFirstLettersUppercase } from 'modules/Common/utils/commonHelpers';

import Tag from '../Tag';

import { Styled } from './TicketNotes.styled';

interface TicketNotesProps {
	notes: IActivityNotes[];
	user?: IUser;
	addNoteEnabled?: boolean;
}

const TicketNotes: React.FC<TicketNotesProps> = ({ notes, user, addNoteEnabled = false }) => {
	const [noteText, setNoteText] = React.useState('');

	const tagColor = (statusName: string) => {
		return statusName === 'New'
			? TagColoursType.Blue
			: statusName === 'Resolved'
				? TagColoursType.Green
				: TagColoursType.Orange;
	};

	const sortedNotes = useMemo(() => [...notes].reverse(), [notes]);

	const handleAddNote = () => {
		if (noteText.trim().length > 0) {
			return undefined;
		}
	};

	const senderColors = useMemo(
		() => assignColorsToIds(notes, ['user', 'id'], user?.userId || 0),
		[notes, user?.userId],
	);

	return (
		<Styled.Root>
			<Styled.NotesHeader>
				<h3>Activity</h3>
			</Styled.NotesHeader>
			{sortedNotes.map((note) => {
				const changeStatusNote = note.activityType === ActivityTypeEnum.ChangeStatus;

				return (
					<Styled.NoteItem key={note.id}>
						<Styled.NoteInfo>
							<Styled.UserIcon bgColor={senderColors[note.user.id]}>
								{getFirstLettersUppercase(note.user.fullName)}
							</Styled.UserIcon>
							<Styled.NoteName>{note.user.fullName}</Styled.NoteName>
							{changeStatusNote && <Styled.NoteStatus>changed status</Styled.NoteStatus>}
							<Styled.NoteDate>{note.date}</Styled.NoteDate>
						</Styled.NoteInfo>
						<Styled.NoteText>
							{changeStatusNote && (
								<>
									<Tag color={tagColor(note.fromStatus.name)}>{note.fromStatus.name}</Tag>
									<Styled.ArrowRight />
									<Tag color={tagColor(note.toStatus.name)}>{note.toStatus.name}</Tag>
								</>
							)}
						</Styled.NoteText>
					</Styled.NoteItem>
				);
			})}
			{addNoteEnabled && (
				<>
					<Styled.NoteForm>
						<Styled.UserIcon bgColor={senderColors[user?.userId || 0]}>
							{getFirstLettersUppercase(user?.fullName || 'user')}
						</Styled.UserIcon>
						<TextArea
							placeholder={'Enter your note'}
							value={noteText}
							autoSize={{ minRows: 4 }}
							onChange={(e) => setNoteText(e.target.value)}
							readOnly={true}
							disabled={true}
						/>
					</Styled.NoteForm>
					<Styled.ButtonsWrapper>
						<Button
							buttonType={ButtonTypes.primary}
							type='button'
							onClick={handleAddNote}
							disabled={true}
						>
					Add Note
						</Button>
					</Styled.ButtonsWrapper>
				</>
			)}
			
		</Styled.Root>
	);
};

export default memo(TicketNotes);
