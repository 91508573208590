import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function CameraSmallIcon({ active, width = '16', height = '16' }: IconType) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			fill='none'
			viewBox='0 0 16 16'
		>
			<path
				fill={active ? COLORS.black : COLORS.red}
				fillRule='evenodd'
				d='M4 4.667a2 2 0 00-2 2v2.666a2 2 0 002 2h3.333a2 2 0 002-2V6.667a2 2 0 00-2-2H4zm7.444.867a1.6 1.6 0 00-.777 1.372v2.188a1.6 1.6 0 00.777 1.372l.133.08A1.6 1.6 0 0014 9.174V6.826a1.6 1.6 0 00-2.423-1.372l-.133.08z'
				clipRule='evenodd'
			></path>
		</svg>
	);
}

export default memo(CameraSmallIcon);
