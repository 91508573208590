export const statisticData = [
	{
		id: 1,
		name: 'Active Jobs',
		amount: 4,
	},
	{
		id: 2,
		name: 'New Applications',
		amount: 15,
	},
	{
		id: 3,
		name: 'Candidates to interview',
		amount: 16,
	},
	{
		id: 4,
		name: 'Potential Candidates',
		amount: 32,
	},
	{
		id: 5,
		name: 'Candidates in Database',
		amount: 128,
	},
];

export const jobsData = {
	data: [
		{
			id: 1,
			title: 'SoftWare Engineer',
			location: 'Manchester',
			startData: '2023-05-19T11:12:17Z',
			endDate: '2023-06-19T11:12:17Z',
			expDate: '2023-06-19T11:12:17Z',
			numberOfNewCandidates: 1,
			numberOfPotentialCandidates: 0,
			numberOfInterviewedCandidates: 0,
			numberOfOfferCandidates: 4,
			numberOfHiredCandidates: 4,
		},
		{
			id: 2,
			title: 'Recoding Studio Technician',
			location: 'Liverpool',
			startData: '2023-05-19T11:12:17Z',
			endDate: '2023-06-19T11:12:17Z',
			expDate: '2023-06-19T11:12:17Z',
			numberOfNewCandidates: 5,
			numberOfPotentialCandidates: 5,
			numberOfInterviewedCandidates: 5,
			numberOfOfferCandidates: 1,
			numberOfHiredCandidates: 3,
		},
		{
			id: 3,
			title: 'Recoding Engineer',
			location: 'London',
			startData: '2023-05-19T11:12:17Z',
			endDate: '2023-06-19T11:12:17Z',
			expDate: '2023-06-19T11:12:17Z',
			numberOfNewCandidates: 6,
			numberOfPotentialCandidates: 5,
			numberOfInterviewedCandidates: 5,
			numberOfOfferCandidates: 3,
			numberOfHiredCandidates: 1,
		},
		{
			id: 4,
			title: 'Accountant',
			location: 'Remote',
			startData: '2023-05-19T11:12:17Z',
			endDate: '2023-06-19T11:12:17Z',
			expDate: '2023-06-19T11:12:17Z',
			expireData: 'Dec 28, 2022',
			numberOfNewCandidates: 22,
			numberOfPotentialCandidates: 4,
			numberOfInterviewedCandidates: 2,
			numberOfOfferCandidates: 8,
			numberOfHiredCandidates: 1,
		},
		{
			id: 5,
			title: 'Sound Designer',
			location: 'Manchester',
			startData: '2023-05-19T11:12:17Z',
			endDate: '2023-06-19T11:12:17Z',
			expDate: '2023-06-19T11:12:17Z',
			numberOfNewCandidates: 0,
			numberOfPotentialCandidates: 1,
			numberOfInterviewedCandidates: 4,
			numberOfOfferCandidates: 5,
			numberOfHiredCandidates: 2,
		},
	],
	pageIndex: 0,
	pageSize: 10,
	totalCount: 5,
};
