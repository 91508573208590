import React, { useContext, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import Spinner from 'components/Spinner';
import { ClntContext } from 'modules/BackOffice/containers/ClientContextContainer/ClientContextContainer';
import DashboardActiveJobs from 'modules/Common/components/DashboardComponents/DashboardActiveJobs';
import DashboardAllCandidates from 'modules/Common/components/DashboardComponents/DashboardAllCandidates';
import DashboardBranding from 'modules/Common/components/DashboardComponents/DashboardBranding';
import DashboardEmails from 'modules/Common/components/DashboardComponents/DashboardEmails';
import DashboardInterviews from 'modules/Common/components/DashboardComponents/DashboardInterviews';
import DashboardJobsStatistics from 'modules/Common/components/DashboardComponents/DashboardJobsStatistics';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { CountriesType, Routes } from 'types';
import { defaultFilterInterviewFunc } from 'utils/helpers';

import { Styled } from './ClientContextDashboardPage.styled';

type ClientContextDashboardPageProps = {
	countries: CountriesType;
};

const ClientContextDashboardPage = ({ countries }: ClientContextDashboardPageProps) => {
	const dispatch = useDispatch();
	const { clientId } = useParams();
	const clientStore = useContext(ClntContext)[clientId];
	const navigate = useNavigate();

	const actions = {
		getAllBackOfficeJobs: (params) =>
			dispatch(
				clientStore?.backOfficeActions?.getAllBackOfficeJobsRequested(
					{ ...params, client: clientId },
					true,
					true,
				),
			),
		getInterviewCalendarList: (params) =>
			dispatch(
				clientStore?.backOfficeActions?.getClientContextInterviewList({
					...params,
					client: clientId,
				}),
			),
		getCandidateStatisticsBORequested: (params) =>
			dispatch(clientStore?.backOfficeActions?.getCandidateStatisticsBORequested(params, clientId)),
		getEmailsRequested: () =>
			dispatch(
				clientStore?.backOfficeActions?.getClientContextDashboardUnreadEmailsRequested(clientId),
			),
		getJobsMonthStatistic: () =>
			dispatch(clientStore?.backOfficeActions?.getBOJobsMonthStatisticRequested(clientId)),
		getCurrentClientRequested: () =>
			dispatch(clientStore?.backOfficeActions?.getCurrentClientRequested(clientId)),
	};

	const state = {
		jobsData: useSelector(clientStore?.backOfficeSelectors?.getAllBackOfficeJobsData),
		interviewList: useSelector(clientStore?.backOfficeSelectors?.getInterviewsListState),
		getSubsInfo: useSelector(clientStore?.backOfficeSelectors?.getSubsInfo),
		getCandidateStatistics: useSelector(clientStore?.backOfficeSelectors?.getCandidateStatisticsBO),
		emailList: useSelector(clientStore?.backOfficeSelectors?.getClientContextDashboardUnreadEmails),
		loading: useSelector(clientStore?.backOfficeSelectors?.backOfficeLoading),
		jobMonthStatistics: useSelector(clientStore?.backOfficeSelectors?.getBOJobMonthStatisticsState),
		clientData: useSelector(clientStore?.backOfficeSelectors?.getClientChangesFormState),
	};

	const branding = { ...state.clientData?.atsBranding, clientName: state.clientData?.clientName };
	const dashboardLoading = !!state.loading?.getCurrentClientLoad;
	const activeJobsLoading = !!state.loading?.getAllBackofficeJobsLoad;
	const interviewsLoading = !!state.loading?.getClientContextInterviewListLoad;
	const allCandidatesStatisticsLoading = !!state.loading?.getCandidateStatisticsBOLoad;
	const emailListLoading = !!state.loading?.getClientContextDashboardUnreadEmailsLoad;

	const handleEditJob = (id: number) => {
		navigate(`${Routes.BOClientContext}/${clientId}${Routes.EditJob}/${id}`);
	};
	const handleSeeAllJobs = () => {
		navigate(`${Routes.BOClientContext}/${clientId}${Routes.Jobs}`);
	};
	const handlePostJob = () => {
		navigate(`${Routes.BOClientContext}/${clientId}${Routes.CreateJob}`);
	};

	useEffect(() => {
		actions.getCurrentClientRequested();
	}, []);

	if (dashboardLoading) {
		return <Spinner />;
	}

	return (
		<Styled.Root>
			<Styled.Header>
				<DashboardBranding
					clientBrandingData={branding}
					subscriptionPlans={{ isPremiumPlan: true }}
					isClientContextDashboard={true}
					loading={dashboardLoading}
				/>
			</Styled.Header>
			<Styled.Content>
				<Styled.LeftColumn>
					<DashboardJobsStatistics
						loading={state.loading?.getJobsMonthStatisticBoLoad}
						clientBrandingData={branding}
						subscriptionPlans={{ isPremiumPlan: true }}
						getJobsMonthStatistic={actions.getJobsMonthStatistic}
						jobStatistic={state.jobMonthStatistics}
					/>
					<DashboardActiveJobs
						jobsData={state.jobsData}
						clientBrandingData={branding}
						countries={countries}
						subscriptionPlans={{ isPremiumPlan: true }}
						loading={activeJobsLoading}
						getAllJobs={actions.getAllBackOfficeJobs}
						archiveAndUnarchiveJob={() => undefined}
						hideArchiveAndBoostButtons={true}
						handleEditJob={handleEditJob}
						handleSeeAllJobs={handleSeeAllJobs}
						handlePostJob={handlePostJob}
					/>
				</Styled.LeftColumn>
				<Styled.RightColumn>
					<DashboardAllCandidates
						candidateStatistics={state.getCandidateStatistics}
						loading={allCandidatesStatisticsLoading}
						getCandidateStatistics={actions.getCandidateStatisticsBORequested}
						clientId={clientId}
					/>
					<DashboardInterviews
						startDate={dayjs()}
						filterInterviewFunc={defaultFilterInterviewFunc}
						interviewList={state.interviewList}
						loading={interviewsLoading}
						getInterviewCalendarList={actions.getInterviewCalendarList}
						hideSeeMoreButton={true}
						interviewLinkDisabled={true}
					/>
					<DashboardEmails
						emailList={state.emailList}
						loading={emailListLoading}
						getEmails={actions.getEmailsRequested}
						hideTotalEmails={true}
					/>
				</Styled.RightColumn>
			</Styled.Content>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
	}),
	{},
)(ClientContextDashboardPage);
