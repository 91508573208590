import { get } from 'api/axiosInstance';
import { RequestsEnum } from 'types';

export const getPrices = async () => get(`${RequestsEnum.Price}`);

export const getPricesByClientId = async (clientId) =>
	get(`${RequestsEnum.PriceClient}/${clientId}`);

export const getSmsPrice = async () => get(`${RequestsEnum.OrderSmsPrice}`);

export const getVideoInterviewPrice = async () => get(`${RequestsEnum.OrderInterviewPrice}`);

const pricesService = {
	getPrices,
	getPricesByClientId,
	getSmsPrice,
	getVideoInterviewPrice,
};

export default pricesService;
