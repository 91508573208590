import { Link } from 'react-router-dom';

import SelectComponent, { type DefaultOptionType } from 'antd/es/select';
import styled from 'styled-components/macro';

import Box from 'components/Box';
import ButtonComponent from 'components/Button';
import { COLORS } from 'theme';

const { Option: OptionComponent } = SelectComponent;

interface ISelectProps extends DefaultOptionType {
	styles: {
		border: string;
		background: string;
		borderColor: string;
	};
}

interface ButtonProps {
	minWidth?: number;
	fontSize?: number;
}

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;

		button.ant-btn.ant-btn-default {
			height: 28px;
			padding: 0 16px;
			line-height: 28px;
		}
	`;

	export const BoxWrap = styled(({ isBoxComponent, ...props }) => {
		const Component = isBoxComponent ? Box : 'div';

		return <Component {...props} />;
	})``;

	export const Title = styled.h4`
		display: flex;
		line-height: 28px;
		min-width: 28px;
		font-weight: 500;
		color: ${COLORS.black};
		gap: 4px;
	`;

	export const PageLink = styled(Link)`
		display: flex;
		line-height: 28px;
		height: 28px;
		min-width: 28px;
		font-weight: 500;
		color: ${COLORS.black};
		gap: 4px;
	`;

	export const ButtonLink = styled(Link)`
		display: block;
		line-height: 28px;
		height: 28px;
		max-width: 90px;
		margin: 0 auto;
		font-weight: 500;
		border: 1px solid ${COLORS.lightGray1};
		border-radius: 4px;
		color: ${COLORS.black};
		transition: background-color 0.2s ease;
		gap: 4px;

		&:hover,
		&:focus,
		&:active {
			color: ${COLORS.black};
			background-color: ${COLORS.lightGray2};
		}
	`;

	export const Button = styled(ButtonComponent)<ButtonProps>`
		line-height: 28px;
		height: 28px;
		min-width: ${({ minWidth }) => `${minWidth ?? 28}px`};
		font-size: ${({ fontSize }) => `${fontSize ?? 12}px`}!important;
		padding: 4px 8px;
		gap: 8px;
	`;

	export const Select = styled(SelectComponent)<ISelectProps>`
		&.ant-select {
			.ant-select-selector {
				min-width: 120px;
				height: 28px;
				line-height: 28px;
				${({ styles }) => styles}
				border-radius: 4px;

				.ant-select-selection-item {
					line-height: 28px;
				}
			}
		}
	`;

	export const Option = styled(OptionComponent)``;
	export const Popover = styled.div``;
	export const PopoverInvoice = styled.div`
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 8px;
	`;
	export const PopoverInvoiceBTO = styled.div`
		margin-left: 8px;
	`;
}
