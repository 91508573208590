import styled from 'styled-components/macro';

export namespace S {
	export const Root = styled.div`
		display: flex;
		flex-direction: column;
		height: 100%;
		min-height: 100%;
		overflow-y: auto;
	`;

	export const Main = styled.main`
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		overflow-y: auto;
	`;
}
