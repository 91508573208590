import React, { type FC, useState, useCallback, useMemo } from 'react';

import { message, Switch, Tooltip } from 'antd';
import _ from 'lodash';

import API from 'api';
import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import CheckCircleIcon from 'components/SVG/CheckCircleIcon';
import CrossCircleIcon from 'components/SVG/CrossCircleIcon';
import InfoIcon from 'components/SVG/InfoIcon';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import { IDefaultPrice, ICurrentSubscription, PremiumStatusEnum } from 'modules/Common/types';
import { COLORS } from 'theme';
import { DataFormatEnum } from 'types';
import { getDateFormat } from 'utils/helpers';

import { Styled as StyledCommon } from '../Subscriptions.styled';

import { getSubscriptionPremiumBenifits } from './SubscriptionPremiumBenefits';
import { Styled } from './SubscriptionPremiumBlock.styled';

export type SubscriptionPremiumBlockProps = {
	defaultPrices: IDefaultPrice[];
	currentSubscription: ICurrentSubscription;
	activateTrial: () => Promise<void>;
	isActive: boolean;
	internalLoading: boolean;
	currency: string;
	region: number;
};

interface IPaymentUrlPromise {
	url: string;
}

export const getPaymentUrl = async (
	requestFunction: () => Promise<IPaymentUrlPromise>,
	loadingSetter: (newLoadingState: boolean) => void,
	setError?: (error: string) => void,
) => {
	try {
		loadingSetter(true);
		const res = await requestFunction();

		if (res?.url) {
			window.location.replace(res.url);
		}
	} catch (e: unknown) {
		if (typeof e === 'string') {
			if (setError) {
				setError(e);
			} else {
				message.error(e);
			}
		}
	} finally {
		loadingSetter(false);
	}
};

export const rescheduleSubscription = async (
	requestFunction: () => Promise<IPaymentUrlPromise>,
	loadingSetter: (newLoadingState: boolean) => void,
	requestNewSubsDetails: () => Promise<void>,
) => {
	try {
		loadingSetter(true);
		await requestFunction();
		const { nextChargeDate } = await requestNewSubsDetails();

		const currentUrl = window.location.href;
		const url = new URL(currentUrl);
		url.searchParams.set('rescheduled', nextChargeDate);
		window.location.href = url.toString();
	} catch (e: unknown) {
		if (typeof e === 'string') {
			message.error(e);
		}
	} finally {
		loadingSetter(false);
	}
};

const SubscriptionPremiumBlock: FC<SubscriptionPremiumBlockProps> = ({
	defaultPrices,
	currentSubscription,
	isActive,
	activateTrial,
	internalLoading,
	currency,
	region,
}) => {
	const SubscriptionPremiumBenifits = useMemo(
		() => getSubscriptionPremiumBenifits(region),
		[region],
	);
	const isCancelledSubscription = currentSubscription.premiumStatus === PremiumStatusEnum.Canceled;
	const isActiveSubscription = currentSubscription.premiumStatus === PremiumStatusEnum.Active;
	const isPostponedSubscription = currentSubscription.premiumStatus === PremiumStatusEnum.Postponed;
	const isScheduledSubscription = currentSubscription.premiumStatus === PremiumStatusEnum.Scheduled;
	const isActiveOrPostponed = isActiveSubscription || isPostponedSubscription;
	const isActivePostonedOrScheduled = isActiveOrPostponed || isScheduledSubscription;
	const showPeriodSwitcher =
		!isActive || isCancelledSubscription || (currentSubscription.trial && !isPostponedSubscription);

	const monthlyPlan = defaultPrices.find((i) => i.value === 'Monthly');
	const yearlyPlan = defaultPrices.find((i) => i.value === 'Annually');
	const [newPlan, setNewPlan] = useState(monthlyPlan);

	const [loadPaymentUrl, setLoadtPaymentUrl] = useState(false);
	const handleNewPlanSwitch = useCallback(() => {
		setNewPlan((currentPlan) => defaultPrices.find((dp) => currentPlan?.value !== dp.value));
	}, []);

	const handlePayPremium = useCallback(() => {
		const type = newPlan.value?.toLowerCase();

		if (isCancelledSubscription && currentSubscription.premium) {
			rescheduleSubscription(
				() => API.subscriptionsService.payClientPremiumBySchedule(type),
				setLoadtPaymentUrl,
				() => API.subscriptionsService.getCurrentSubscriptionInfo(),
			);
		} else {
			getPaymentUrl(() => API.subscriptionsService.payClientPremium(type), setLoadtPaymentUrl);
		}
	}, [newPlan, currentSubscription]);

	const updatePaymentDetails = useCallback(
		() => getPaymentUrl(() => API.subscriptionsService.updatePaymentDetails(), setLoadtPaymentUrl),
		[],
	);

	const getPersentView = useCallback(
		(a: number, b: number): string => `${_.round(((a * 12 - b) / ((a * 12 + b) / 2)) * 100, 2)} %`,
		[],
	);

	const currentPrice = (
		showPeriodSwitcher ? newPlan.price : currentSubscription.price - currentSubscription.vatPrice
	).toFixed(2);

	const currenPeriod =
		isActive &&
		!isCancelledSubscription &&
		(!currentSubscription.trial || isActiveSubscription || isPostponedSubscription)
			? currentSubscription?.premiumType?.interval?.toLowerCase()
			: newPlan?.value === 'Annually'
				? 'year'
				: 'month';

	const showGetTrialButton = currentSubscription.trialAvailable && !isActive;
	const showUpgradePlanButton =
		(currentSubscription.trial && !isActivePostonedOrScheduled) ||
		!isActive ||
		isCancelledSubscription;

	const handleGetFreeTrialClick = useCallback(() => {
		if (internalLoading) {
			return;
		}

		return activateTrial();
	}, [internalLoading]);

	return (
		<StyledCommon.SubscriptionBlock isActive={isActive}>
			<StyledCommon.SubscriptionBlockTitle>
				PRO{' '}
				{currentSubscription.chargeVat && (
					<Tooltip
						placement='topRight'
						title={'Price shown is exclusive of VAT which will be charged appropriately'}
					>
						<span>
							<InfoIcon fill={COLORS.lightGray1} width='16' height='16' />
						</span>
					</Tooltip>
				)}
			</StyledCommon.SubscriptionBlockTitle>
			<StyledCommon.SubscriptionPrice>
				{currency} {currentPrice}
			</StyledCommon.SubscriptionPrice>
			<StyledCommon.SubscriptionPriceDesc>per {currenPeriod}</StyledCommon.SubscriptionPriceDesc>

			{showPeriodSwitcher && (
				<Styled.PlanSwitch>
					<Styled.PlanSwitchPeriod>Monthly</Styled.PlanSwitchPeriod>
					<Switch onChange={handleNewPlanSwitch} />
					<Styled.PlanSwitchPeriod>Yearly</Styled.PlanSwitchPeriod>
					{newPlan.value === 'Annually' && (
						<Styled.AnnualHint>
							Save {getPersentView(monthlyPlan?.price, yearlyPlan.price)}
						</Styled.AnnualHint>
					)}
				</Styled.PlanSwitch>
			)}
			<StyledCommon.SubscriptionBenefits>
				{SubscriptionPremiumBenifits.map((b) => (
					<StyledCommon.SubscriptionBenefit key={b.name}>
						{b.value ? <CheckCircleIcon /> : <CrossCircleIcon />}
						<StyledCommon.SubscriptionBenefitValue>{b.name}</StyledCommon.SubscriptionBenefitValue>
					</StyledCommon.SubscriptionBenefit>
				))}
			</StyledCommon.SubscriptionBenefits>
			<StyledCommon.SubscriptionButtonWrapper>
				{isActive && !isCancelledSubscription && <Button isFullWidth>Current Plan</Button>}
				{isActive && isActivePostonedOrScheduled && (
					<ATSThemedButton
						buttonType={ButtonTypes.secondary}
						disabled={loadPaymentUrl}
						onClick={updatePaymentDetails}
						isFullWidth
					>
						Update Payment Details
					</ATSThemedButton>
				)}
				{showUpgradePlanButton && (
					<ATSThemedButton
						isFullWidth
						disabled={loadPaymentUrl || internalLoading}
						buttonType={ButtonTypes.primary}
						onClick={handlePayPremium}
					>
						Upgrade Plan
					</ATSThemedButton>
				)}
			</StyledCommon.SubscriptionButtonWrapper>
			<StyledCommon.SubscriptionFooterDescWrapper>
				<StyledCommon.SubscriptionFooterDesc>
					{currentSubscription.trial && !isActivePostonedOrScheduled
						? `free trial expires on ${getDateFormat(
							currentSubscription.trialEndDate,
							DataFormatEnum.Full,
						  )}`
						: isActivePostonedOrScheduled
							? `next charge date on ${getDateFormat(
								currentSubscription.nextChargeDate,
								DataFormatEnum.Full,
						  )}`
							: isCancelledSubscription && currentSubscription.premium
								? 'PRO subscription expires on ' +
						  getDateFormat(currentSubscription.premiumEndDate, DataFormatEnum.Full)
								: 'PRO users get ~60% better result'}
				</StyledCommon.SubscriptionFooterDesc>
			</StyledCommon.SubscriptionFooterDescWrapper>
			{showGetTrialButton && (
				<>
					<StyledCommon.SubscriptionFooterDescWrapper>
						<StyledCommon.SubscriptionFooterDesc>
							Need more time to think?
						</StyledCommon.SubscriptionFooterDesc>
					</StyledCommon.SubscriptionFooterDescWrapper>
					<StyledCommon.TryForFreeButton onClick={handleGetFreeTrialClick}>
						Try it for FREE for 6 weeks
					</StyledCommon.TryForFreeButton>
				</>
			)}
		</StyledCommon.SubscriptionBlock>
	);
};

export default SubscriptionPremiumBlock;
