import styled from 'styled-components/macro';

import { COLORS } from 'theme';

interface ISubscriptionBlockProps {
	isActive?: boolean;
}

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		padding: 24px;
		background-color: ${COLORS.white};

		h3 {
			margin-bottom: 32px;
		}
	`;

	export const Title = styled.span`
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
	`;

	export const ContendWrapper = styled.div`
		display: flex;
		width: 100%;
		justify-content: center;
		padding-top: 32px;
	`;

	export const Content = styled.div`
		display: grid;
		grid-template-columns: 329px 329px;
		gap: 56px;
	`;

	export const SubscriptionBlock = styled.div<ISubscriptionBlockProps>`
		display: flex;
		width: 329px;
		padding: 32px;
		flex-direction: column;
		align-items: flex-start;

		border-radius: 10px;
		border: 1px solid ${({ isActive }) => (isActive ? COLORS.lightBlue1 : COLORS.lightGray3)};
		background: ${COLORS.white};
		box-shadow: 0px 4px 12px 0px rgba(99, 111, 122, 0.16);
	`;
	export const SubscriptionBlockTitle = styled.div`
		color: ${COLORS.black};
		text-align: center;
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
		margin-bottom: 24px;
		text-align: center;
		width: 100%;
	`;
	export const SubscriptionPrice = styled.div`
		color: ${COLORS.black};
		text-align: center;
		font-family: Inter;
		font-size: 36px;
		font-style: normal;
		font-weight: 600;
		line-height: 40px;
		text-align: center;
		width: 100%;
	`;
	export const SubscriptionPriceDesc = styled.div`
		color: ${COLORS.lightGray1};
		text-align: center;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		margin-bottom: 24px;
		text-align: center;
		width: 100%;
	`;
	export const SubscriptionBenefits = styled.div``;
	export const SubscriptionBenefit = styled.div`
		display: flex;
		margin-bottom: 24px;
		justify-content: flex-start;
		align-items: center;
	`;
	export const SubscriptionBenefitIcon = styled.div``;
	export const SubscriptionBenefitValue = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		margin-left: 12px;
	`;
	export const SubscriptionButtonWrapper = styled.div`
		width: 100%;
		padding: 24px 0;
		border-top: 1px solid ${COLORS.lightGray1};
		border-bottom: 1px solid ${COLORS.lightGray1};
	`;
	export const SubscriptionFooterDescWrapper = styled.div`
		padding-top: 24px;
		width: 100%;
		display: flex;
		justify-content: center;
	`;
	export const SubscriptionFooterDesc = styled.span`
		text-align: center;
		font-size: 14px;
		color: ${COLORS.darkGray1};
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		width: 215px;
	`;
	export const TryForFreeButton = styled.div`
		color: ${COLORS.blue};
		width: 100%;
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		cursor: pointer;
	`;
	export const WipeButtonWrapper = styled.div`
		width: 100%;
		display: flex;
		justify-content: center;
		padding-top: 32px;
	`;
}
