import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import dayjs, { Dayjs } from 'dayjs';

import Spinner from 'components/Spinner';
import CalendarEmpty from 'components/SVG/CalendarEmpty';
import { useMount } from 'hooks';
import { InterviewListType } from 'modules/Common/types';
import { Routes } from 'types';

import { Styled } from './DashboardInterviews.styled';

type DashboardInterviewsProps = {
	interviewList: InterviewListType[];
	getInterviewCalendarList: ({ fromDate, toDate }: { fromDate: string; toDate: string }) => void;
	resetInterviewCalenderList?: () => void;
	hideSeeMoreButton?: boolean;
	interviewLinkDisabled?: boolean;
	loading: boolean;
	startDate: Dayjs;
	filterInterviewFunc: (currendDate: Dayjs) => (interview: InterviewListType) => boolean;
};

const NUMBER_OF_INTERVIEWS_TO_DISPLAY = 4;

const filterOptions = [
	{ value: 1, label: 'This week' },
	{ value: 2, label: 'This month' },
];

const DashboardInterviews: FC<DashboardInterviewsProps> = ({
	interviewList,
	getInterviewCalendarList,
	resetInterviewCalenderList,
	hideSeeMoreButton = false,
	interviewLinkDisabled = false,
	filterInterviewFunc,
	loading,
	startDate,
}) => {
	const navigate = useNavigate();
	const [selectedInterviewList, setSelectedInterviewList] = useState<InterviewListType[]>([]);
	const [filterValue, setFilterValue] = useState<number>(1);

	useEffect(() => {
		const filteredInterviewList = interviewList?.filter(filterInterviewFunc(startDate));
		setSelectedInterviewList(filteredInterviewList?.slice(0, NUMBER_OF_INTERVIEWS_TO_DISPLAY));
	}, [interviewList]);

	useEffect(() => {
		const datesObject = {
			fromDate: startDate.format('YYYY-MM-DD'),
			toDate: startDate.add(filterValue === 1 ? 7 : 30, 'day').format('YYYY-MM-DD'),
		};
		getInterviewCalendarList(datesObject);
	}, [filterValue]);

	useMount(() => {
		const datesObject = {
			fromDate: startDate.format('YYYY-MM-DD'),
			toDate: startDate.add(7, 'day').format('YYYY-MM-DD'),
		};
		getInterviewCalendarList(datesObject);
	});

	const handleFilterChange = useCallback((value: number) => {
		setFilterValue(value);
	}, []);

	const handleSeeMore = () => {
		resetInterviewCalenderList && resetInterviewCalenderList();
		navigate(`${Routes.ATS}${Routes.Interviews}`);
	};

	if (loading) {
		return <Spinner fixed />;
	}

	const numberOfInterviews = interviewList?.length || 0;

	return (
		<Styled.Root>
			<Styled.Head>
				<Styled.Column>
					<Styled.Title>Interviews</Styled.Title>
					<Styled.NumberOfInterviews>{numberOfInterviews}</Styled.NumberOfInterviews>
				</Styled.Column>
				<Styled.Column>
					{!hideSeeMoreButton && (
						<Styled.SeeMoreButton type='link' htmlType='button' onClick={handleSeeMore}>
							See more
						</Styled.SeeMoreButton>
					)}
					<Styled.Select
						options={filterOptions}
						value={filterValue}
						onChange={(e) => handleFilterChange(e as number)}
						placeholder='Choose period'
					></Styled.Select>
				</Styled.Column>
			</Styled.Head>
			<Styled.FilterSection></Styled.FilterSection>
			{numberOfInterviews === 0 ? (
				<Styled.NoActiveInterviews>
					<CalendarEmpty width='120px' />
					<p>You don’t have interviews this week.</p>
				</Styled.NoActiveInterviews>
			) : (
				selectedInterviewList.map((interview) => {
					const candidateName =
						interview.guests?.find((guest) => guest?.type.value === 'CANDIDATE')?.fullName ||
						'Candidate';

					return (
						<Link
							to={interviewLinkDisabled ? '#' : `${Routes.ATS}${Routes.Interviews}`}
							key={interview.id}
							style={interviewLinkDisabled ? { cursor: 'auto' } : undefined}
						>
							<Styled.InterviewWrapper>
								<Styled.InterviewLeftCol>
									<Styled.InterviewCandidate>{candidateName}</Styled.InterviewCandidate>
									<Styled.InterviewInfo>{interview.jobTitle}</Styled.InterviewInfo>
								</Styled.InterviewLeftCol>
								<Styled.InterviewRightCol>
									<Styled.InterviewInfo>
										{dayjs(interview.startDate).format('MMM DD, YYYY')}
									</Styled.InterviewInfo>
									<Styled.InterviewInfo>
										{dayjs(interview.startDate).format('HH:mm')}
									</Styled.InterviewInfo>
								</Styled.InterviewRightCol>
							</Styled.InterviewWrapper>
						</Link>
					);
				})
			)}
		</Styled.Root>
	);
};

export default DashboardInterviews;
