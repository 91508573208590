import { Radio } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

const { Group } = Radio;

export namespace Styled {
	export const RadioField = styled(Group)`
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		gap: 16px;
	`;

	export const RadioOption = styled(Radio)`
		font-size: 16px;
	`;

	export const FieldWrapper = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: left;
		align-items: flex-start;
		width: 100%;
		gap: 24px;
	`;

	export const FieldInputWrapper = styled.span`
		display: block;
		width: 100%;
		max-width: 400px;
		font-size: 16px;

		label {
			font-weight: 400;
			font-size: 16px;
		}
	`;

	// TODO have duplications. Move this to separated Styled Component
	export const Attachment = styled.div`
		border: 1px solid ${COLORS.gray};
		display: flex;
		justify-content: space-between;
		padding: 10px;
		margin-top: 8px;
		transition: all 0.2s ease;
		color: ${COLORS.black};

		&:hover {
			background-color: transparent;
			box-shadow: 0px 3px 5px 0px ${COLORS.gray};
			cursor: pointer;
		}
	`;

	export const AttachmentFileName = styled.div`
		display: flex;

		svg {
			margin-right: 8px;
		}
	`;
}
