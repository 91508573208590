import React, { ReactNode, useState } from 'react';
import { useSize } from 'react-use';

import Button from 'components/Button';

import { Styled } from './ShowMoreWrapper.styled';

interface IShowMoreWrapperProps {
	maxElHeight?: number;
	children: ReactNode | ReactNode[];
	showAllText?: string;
	hideText?: string;
}

export const ShowMoreWrapperWrapper: React.FC<IShowMoreWrapperProps> = ({
	maxElHeight = 100,
	children,
	showAllText = 'Show all',
	hideText = 'Hide',
}) => {
	const [cld, { height }] = useSize(children);
	const [isShownAll, setShownAll] = useState<boolean>(height < maxElHeight);

	return (
		<Styled.Wrapper>
			<Styled.Content isShownAll={isShownAll} maxElHeight={maxElHeight}>
				{cld}
			</Styled.Content>
			{height >= maxElHeight && (
				<Styled.ActionButtonWrapper>
					<Button onClick={() => setShownAll((sa) => !sa)}>
						{isShownAll ? hideText : showAllText}
					</Button>
				</Styled.ActionButtonWrapper>
			)}
		</Styled.Wrapper>
	);
};
