import React from 'react';

import { Svg, Path, G, Defs, ClipPath } from '@react-pdf/renderer';

const PhoneSvg = () => (
	<Svg width='10' height='10' viewBox='0 0 10 10'>
		<G>
			<Path
				fill='#fff'
				d='M7.233 9.165A6.425 6.425 0 01.816 2.75 1.917 1.917 0 013.354.936a.417.417 0 01.27.313l.572 2.5a.417.417 0 01-.109.383c-.054.058-.058.063-.57.33a4.13 4.13 0 002.029 2.037c.27-.517.275-.521.333-.575a.417.417 0 01.383-.109l2.5.571a.417.417 0 01.3.271 1.808 1.808 0 01.104.62 1.917 1.917 0 01-1.933 1.888zm-4.5-7.5A1.088 1.088 0 001.65 2.75a5.587 5.587 0 005.583 5.583A1.088 1.088 0 008.316 7.25V7.11l-1.933-.446-.12.23c-.188.362-.326.625-.676.483a4.937 4.937 0 01-2.991-3.004c-.15-.325.133-.475.491-.663l.23-.112-.446-1.934h-.138z'
			></Path>
		</G>
		<Defs>
			<ClipPath id='clip0_5682_49858'>
				<Path fill='#fff' d='M0 0H10V10H0z'></Path>
			</ClipPath>
		</Defs>
	</Svg>
);

export default PhoneSvg;
