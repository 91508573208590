import React, { type FC } from 'react';

import { Tabs } from 'antd';

import Spinner from 'components/Spinner';
import Table from 'components/Table';
import { ColumnsDataType } from 'components/Table/Table.types';
import { TableColumnsType, TabsTitleEnum } from 'modules/Common/types';

import { Styled } from './QuestionnaireListContainer.styled';

type QuestionnaireListProps = {
	handleChangeTabs: (id: TabsTitleEnum | string) => void;
	handleTableChange?: (page: number, size: number) => void;
	showDefaultPagination?: boolean;
	tableData: [];
	columns: (isActive: boolean) => ColumnsDataType & TableColumnsType;
	pageIndex?: number;
	pageSize?: number;
	totalCount?: number;
	currentTab: TabsTitleEnum | string;
	loading: boolean;
	clientId?: string;
};

export const QuestionnaireListContainer: FC<QuestionnaireListProps> = ({
	handleChangeTabs,
	handleTableChange,
	showDefaultPagination,
	tableData,
	columns,
	pageIndex,
	pageSize,
	totalCount,
	currentTab,
	loading,
	clientId,
}) => {
	const isActiveTab = currentTab === TabsTitleEnum.ActiveTab;

	const columnData = columns(isActiveTab);

	if (loading) {
		return <Spinner fixed />;
	}

	return (
		<Styled.Root>
			<Tabs
				defaultActiveKey={currentTab}
				activeKey={currentTab}
				onChange={handleChangeTabs}
				tabBarStyle={{ fontFamily: 'Inter' }}
				items={[
					{
						label: 'Active',
						key: TabsTitleEnum.ActiveTab,
						active: true,
						children: (
							<Styled.BoxWrap isBoxComponent={!clientId}>
								{tableData ? (
									<Table
										data={tableData}
										columns={columnData}
										pageSize={pageSize}
										current={pageIndex}
										total={totalCount}
										onChange={handleTableChange}
										onPageSizeChange={handleTableChange}
										showDefaultPagination={showDefaultPagination}
									/>
								) : (
									<span>There are no active questionnaires</span>
								)}
							</Styled.BoxWrap>
						),
					},
					{
						label: 'Archive',
						key: TabsTitleEnum.ArchiveTab,
						children: (
							<Styled.BoxWrap isBoxComponent={!clientId}>
								{tableData ? (
									<Table
										data={tableData}
										columns={columnData}
										pageSize={pageSize}
										current={pageIndex}
										total={totalCount}
										onChange={handleTableChange}
										onPageSizeChange={handleTableChange}
										showDefaultPagination={showDefaultPagination}
									/>
								) : (
									<span>There are no archive questionnaires</span>
								)}
							</Styled.BoxWrap>
						),
					},
				]}
			/>
		</Styled.Root>
	);
};

export default QuestionnaireListContainer;
