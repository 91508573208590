import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		width: 100%;
		margin: 0 auto;
	`;

	export const BoxWrapper = styled.div`
		min-height: 100%;
		padding: 24px 32px;
		background: ${COLORS.white};
		border: 1px solid ${COLORS.lightGray3};
		border-top: none;
		box-shadow: ${COLORS.elevationShadow1};
	`;
}
