import React from 'react';
import { useParams } from 'react-router-dom';

import JobView from 'modules/ATS/containers/JobView';
import PageNavTitle from 'modules/Common/components/PageNavTitle';

import { Styled } from './ATSJobViewPage.styled';

const ATSJobViewPage = () => {
	const { jobId } = useParams();

	return (
		<Styled.Root>
			<Styled.Head>
				<PageNavTitle title='View Job' navigationLink={-1} />
			</Styled.Head>
			<Styled.Content>{jobId && <JobView jobId={jobId} />}</Styled.Content>
		</Styled.Root>
	);
};

export default ATSJobViewPage;
