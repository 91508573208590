import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Routes } from 'types';

import PageNavTitle from '../../../Common/components/PageNavTitle';
import CreateEditContract from '../../containers/CreateEditContract';

export const PostContractPage = () => {
	const { contractId, clientId } = useParams();
	const { pathname } = useLocation();
	const isEditMode = pathname.includes(Routes.ContractsEdit);

	const navPath = clientId
		? `${Routes.BOClientContext}/${clientId}${Routes.Contracts}`
		: `${Routes.BackOffice}${Routes.Contracts}`;

	return (
		<>
			<PageNavTitle
				title={isEditMode ? 'Edit a Contract' : 'Create a New Contract'}
				navigationLink={navPath}
			/>
			<CreateEditContract
				contractId={contractId}
				isEditMode={isEditMode}
				clientContextId={clientId}
			/>
		</>
	);
};
