import { FC } from 'react';

import { Table } from 'antd';

import { TableSummaryProps } from '../Table.types';

const TableSummary: FC<TableSummaryProps> = ({ summaryData, fixed = false }) => {
	return (
		<>
			<Table.Summary fixed={fixed}>
				{summaryData?.map((item) => (
					<Table.Summary.Row key={item.id}>
						{item?.columns?.map((column, index) => (
							<Table.Summary.Cell
								align={index === 0 ? 'left' : 'center'}
								key={column?.id}
								index={column.id}
								colSpan={3}
							>
								{item?.columns?.length - 1 === index ? (
									<span style={{ fontWeight: 600 }}>{column?.content}</span>
								) : (
									column?.content
								)}
							</Table.Summary.Cell>
						))}
					</Table.Summary.Row>
				))}
			</Table.Summary>
		</>
	);
};

export default TableSummary;
