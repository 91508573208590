import { FC } from 'react';

import { JobDataType, JobParamsEnum, JobRoleInfoEnum } from 'modules/Common/types';
import { convertContentToHtml } from 'modules/Common/utils/editorHelper';
import { IOption } from 'types';

import { Styled } from '../JobViewComponents.styled';

type JobRoleInfoProps = {
	employmentTypes: IOption[];
	educationTypes: IOption[];
	jobIndustries: IOption[];
	jobData?: JobDataType;
};

export const JobRoleInfo: FC<JobRoleInfoProps> = ({
	jobData,
	jobIndustries,
	educationTypes,
	employmentTypes,
}) => {
	const JobDescription = jobData?.jobDescription && convertContentToHtml(jobData?.jobDescription);
	const jobIndustry = jobData?.jobIndustry;
	const jobIndustryName = jobIndustries?.find((industry) => industry.id === jobIndustry)?.name;
	const educationTypeName = educationTypes?.find(
		(education) => jobData?.educationType && education.id === +jobData?.educationType,
	)?.name;

	const employmentTypeName = employmentTypes?.find(
		(education) => jobData?.educationType && education.id === +jobData?.employmentType,
	)?.name;

	return (
		<Styled.TextBlock>
			<h2>{JobParamsEnum?.RoleInfo}</h2>
			<Styled.Items>
				{jobData?.experienceLevel && (
					<Styled.Item>
						<h4>{JobRoleInfoEnum?.ExperienceLevel}</h4>
						<p>{jobData?.experienceLevel}</p>
					</Styled.Item>
				)}
				{educationTypeName && (
					<Styled.Item>
						<h4>{JobRoleInfoEnum?.Education}</h4>
						<p>{educationTypeName}</p>
					</Styled.Item>
				)}
				{employmentTypeName && (
					<Styled.Item>
						<h4>{JobRoleInfoEnum?.EmploymentType}</h4>
						<p>{employmentTypeName}</p>
					</Styled.Item>
				)}
				{jobIndustryName && (
					<Styled.Item>
						<h4>{JobRoleInfoEnum?.Industry}</h4>
						<p>{jobIndustryName}</p>
					</Styled.Item>
				)}
				{!!jobData?.jobDescription && (
					<Styled.Item>
						<h4>{JobParamsEnum?.JobDescription}</h4>
						<div>{JobDescription}</div>
					</Styled.Item>
				)}
			</Styled.Items>
		</Styled.TextBlock>
	);
};
