import { Upload as UploadAnt } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		position: relative;
	`;
	export const ContentWrapper = styled.div`
		position: relative;
		padding: 12px 40px;
	`;

	export const FormTitle = styled.div`
		font-family: 'Raleway';
		font-weight: 700;
		font-size: 24px;
		line-height: 28px;
		text-align: center;
		color: ${COLORS.black};
		padding-bottom: 24px;
	`;
	export const FieldWrapper = styled.div`
		padding-bottom: 24px;
		font-family: 'Raleway';
	`;
	export const SubmitWrapper = styled.div`
		padding-top: 24px;
	`;
	export const PhoneFieldWrapper = styled.div`
		width: 100%;

		input {
			color: rgba(0, 0, 0, 0.88);
			border: 1px solid ${COLORS.neutral5};
			border-radius: 6px;
			transition: all 0.2s;
			font-size: 14px;
			padding: 4px 11px;
			line-height: 1.57;

			&:hover {
				border-color: ${COLORS.inputColor};
			}

			&:focus {
				border-color: ${COLORS.inputColor};
				box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
				border-inline-end-width: 1px;
				outline: 0;
			}
		}
	`;
	export const Upload = styled(UploadAnt)`
		width: 100%;
		.ant-upload {
			width: 100%;
		}
	`;
	export const ModalContent = styled.div`
		text-align: center;
	`;
}
