import styled from 'styled-components/macro';

import { COLORS } from 'theme';

type ActivableBlockProps = {
	isActive: boolean;
};

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	`;
	export const Main = styled.section``;
	export const VideoWrapper = styled.div``;
	export const Sidebar = styled.section`
		max-width: 452px;
		min-width: 270px;
		width: 100%;
		padding-left: 20px;
	`;

	export const SidebarQuestions = styled.div`
		width: 100%;
	`;
	export const SidebarQuestion = styled.div`
		margin-bottom: 32px;
	`;
	export const SidebarQuestionMain = styled.div<ActivableBlockProps>`
		border-radius: 10px;
		background: ${COLORS.white};
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		padding: 8px 24px;
		display: flex;
		min-width: 186px;
		cursor: pointer;
		line-height: normal;

		${({ isActive }) => isActive && `border: 1px solid ${COLORS.blue}`}
	`;
	export const SidebarQuestionOrder = styled.span`
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	`;
	export const SidebarQuestionPeriod = styled.span`
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
	`;
	export const SidebarQuestionIcon = styled.div`
		margin-right: 8px;
		padding-top: 4px;
	`;
	export const SidebarQuestionTitle = styled.div`
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		padding: 16px 0 8px;
	`;
	export const SidebarQuestionDesc = styled.div`
		max-height: 380px;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		overflow-y: auto;
	`;
}
