import React, { type FC } from 'react';
import { Field, useField } from 'react-final-form';
import { Link } from 'react-router-dom';

import { DatePicker, Input, Select } from 'antd';

import FieldWrapper from 'components/FieldWrapper';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { TimeOptions } from 'modules/Common/constants';
import { IContractValues } from 'modules/Common/types';
import { DataFormatEnum, Fields, OptionsType, Routes } from 'types';
import { filterSelectOptions } from 'utils/helpers';
import {
	composeValidators,
	numberValidator,
	positiveIntegersValidator,
	requiredFieldValidator,
} from 'utils/validators';

import { Styled } from './InvoiceDetails.styled';

type InvoiceDetailsProps = {
	clientContextId: string;
	clients: OptionsType[];
	contracts: OptionsType[];
	currentContractDetails?: IContractValues;
	contractsLoading: boolean;
	isEditMode: boolean;
	readOnly?: boolean;
};

export const InvoiceDetails: FC<InvoiceDetailsProps> = ({
	clientContextId,
	clients,
	contractsLoading,
	contracts,
	currentContractDetails,
	isEditMode,
	readOnly = false,
}) => {
	const dataFormat = DataFormatEnum.UK ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

	const { input: contractInput } = useField('contract', { subscription: { value: true } });
	const { input: contractIdInput } = useField('contractId', { subscription: { value: true } });
	const hasContractValue = contractInput.value || contractIdInput.value;

	const invoicesList = currentContractDetails?.invoices?.map((invoice, index, array) => {
		const isLast = index === array.length - 1;

		return (
			<>
				<Link
					key={invoice.id}
					to={`${Routes.BackOffice}${Routes.InvoicesView}/${invoice.id}`}
					target='_blank'
				>
					{invoice.btoReference.replace('_manual', '')}
				</Link>
				{!isLast && ', '}
			</>
		);
	});

	return (
		<Styled.FormBlockWrap title='Invoice Details' clientContextId={clientContextId}>
			<FormBlockLine>
				{isEditMode ? (
					<Field name='client' validate={requiredFieldValidator}>
						{({ input, meta }) => (
							<FieldWrapper
								name='client'
								label='Client / Company Name'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Input
									{...input}
									disabled={true}
									placeholder='-Select a client-'
									autoComplete='off'
								/>
							</FieldWrapper>
						)}
					</Field>
				) : (
					<Field name='clientId' validate={requiredFieldValidator}>
						{({ input, meta }) => (
							<FieldWrapper
								name='clientId'
								label='Client / Company Name'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Select
									{...input}
									value={input.value || null}
									placeholder='-Select a client-'
									options={clients}
									disabled={contractsLoading || isEditMode}
									filterOption={(inputValue, option) => filterSelectOptions(inputValue, option)}
									showSearch
								/>
							</FieldWrapper>
						)}
					</Field>
				)}
				{isEditMode ? (
					<Field name='contract' validate={requiredFieldValidator}>
						{({ input, meta }) => {
							return (
								<FieldWrapper
									name='contract'
									label='Contract of the client'
									errorMessage={meta.submitFailed && meta.touched && meta.error}
								>
									<Input
										{...input}
										disabled={true}
										placeholder='-Select a contract-'
										autoComplete='off'
									/>
								</FieldWrapper>
							);
						}}
					</Field>
				) : (
					<Field name='contractId' validate={requiredFieldValidator}>
						{({ input, meta }) => {
							return (
								<FieldWrapper
									name='contractId'
									label='Contract of the client'
									errorMessage={meta.submitFailed && meta.touched && meta.error}
								>
									<Select
										{...input}
										value={input.value || null}
										placeholder='-Select a contract-'
										options={contracts}
										disabled={contractsLoading}
									/>
								</FieldWrapper>
							);
						}}
					</Field>
				)}
			</FormBlockLine>
			<FormBlockLine>
				<Field name='poNumber'>
					{({ input }) => (
						<FieldWrapper
							name='poNumber'
							label='PO Number'
							hint='Enter a purchase order number if required'
						>
							<Input
								{...input}
								disabled={contractsLoading}
								placeholder='Enter the code'
								autoComplete='off'
							/>
						</FieldWrapper>
					)}
				</Field>
				<Field name='btoReference'>
					{({ input }) => (
						<FieldWrapper name='btoReference' label='BTO Reference'>
							<Input {...input} placeholder='CODEXXXXXX' autoComplete='off' />
							{hasContractValue && invoicesList && (
								<Styled.ListOfInvoices>{invoicesList}</Styled.ListOfInvoices>
							)}
						</FieldWrapper>
					)}
				</Field>
			</FormBlockLine>
			<FormBlockLine>
				<Field name='invoiceDate'>
					{({ input, meta }) => (
						<FieldWrapper name='invoiceDate' label='Invoice date'>
							<DatePicker
								{...input}
								format={dataFormat}
								disabled={readOnly}
								inputReadOnly={false}
							/>
						</FieldWrapper>
					)}
				</Field>
				<Styled.FieldsBox>
					<Styled.FieldWrapper>
						<Field
							name='paymentTerm'
							validate={composeValidators(
								requiredFieldValidator,
								numberValidator,
								positiveIntegersValidator,
							)}
						>
							{({ input, meta }) => (
								<FieldWrapper
									name='paymentTerm'
									label='Payment Terms'
									errorMessage={meta.submitFailed && meta.touched && meta.error}
								>
									<Input
										{...input}
										disabled={contractsLoading}
										type={Fields.NUMBER}
										placeholder='e.g. 30'
										min={0}
									/>
								</FieldWrapper>
							)}
						</Field>
					</Styled.FieldWrapper>
					<Field name='paymentTermTimeUnit'>
						{({ input }) => (
							<Select
								{...input}
								disabled={contractsLoading}
								placeholder='-Select period-'
								options={TimeOptions}
							/>
						)}
					</Field>
				</Styled.FieldsBox>
			</FormBlockLine>
		</Styled.FormBlockWrap>
	);
};
