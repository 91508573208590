import React from 'react';
import { connect } from 'react-redux';

import { atsDucks } from 'modules/ATS/ducks';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { IInvoicesDetailedValues } from 'modules/Common/types';
import { Routes } from 'types';

import ForwardInvoice from '../../containers/ForwardInvoice';

import { Styled } from './ATSForwardInvoicePage.styled';

const ATSForwardInvoicePage = ({ currentInvoice }: { currentInvoice: IInvoicesDetailedValues }) => {
	return (
		<>
			<Styled.Head>
				<PageNavTitle
					title={currentInvoice?.btoReference || 'Forward invoice'}
					navigationLink={`${Routes.ATS}${Routes.Finance}`}
				/>
			</Styled.Head>
			<Styled.Content>
				<ForwardInvoice />
			</Styled.Content>
		</>
	);
};

export default connect(
	(state) => ({
		currentInvoice: atsDucks.atsSelectors.getCurrentInvoice(state),
	}),
	{},
)(ATSForwardInvoicePage);
