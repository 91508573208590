import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;

		h3 {
			margin-bottom: 32px;
		}
	`;
}
