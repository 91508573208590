import React, { type FC } from 'react';

import { Select } from 'antd';

import { IOption } from 'types';

import { Styled } from './FilterFields.styled';
const { Option } = Select;

type FilterFieldProps = {
	value: number;
	options: IOption[];
	isActive: boolean;
	onChange: (value: number, isActive: boolean) => void;
};

export const FilterFields: FC<FilterFieldProps> = ({ value, options, isActive, onChange }) => {
	return (
		<Styled.FilterBox>
			<Select value={value} onChange={(newValue) => onChange(newValue, isActive)}>
				{options?.map((option: IOption) => (
					<Option key={option.id} value={option.id}>
						{option.value}
					</Option>
				))}
			</Select>
		</Styled.FilterBox>
	);
};
