import { MenuList } from 'modules/Common/types';
import { Routes } from 'types';

export const ClientsFields = [
	'id',
	'clientName',
	'subscription',
	'numberOfActiveJobs',
	'numberOfPremiumMultisiteCredits',
	'numberOfCvFilteringCredits',
	'numberOfGuardianCredits',
	'numberOfIndeedCredits',
];

export const ClientContextList = [
	{
		id: 101,
		name: MenuList.Dashboard,
		path: Routes.Dashboard,
	},
	{
		id: 102,
		name: MenuList.Vacancies,
		path: Routes.Jobs,
	},

	{
		id: 103,
		name: MenuList.Questionnaires,
		path: Routes.Questionnaires,
	},
	{
		id: 104,
		name: MenuList.Users,
		path: Routes.Users,
	},
	{
		id: 105,
		name: MenuList.Candidates,
		path: Routes.Candidates,
	},
	{
		id: 106,
		name: MenuList.Contracts,
		path: Routes.Contracts,
	},
	{
		id: 107,
		name: MenuList.Invoices,
		path: Routes.Invoices,
	},
	{
		id: 110,
		name: MenuList.SupportTickets,
		path: Routes.SupportTickets,
	},
];
