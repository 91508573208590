import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';
function IndeedIcon({ fill = COLORS.black }: IconType) {
	return (
		<svg width='4' height='12' viewBox='0 0 2 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M0.228161 10V3.45455H1.77077V10H0.228161ZM1.00373 2.52557C0.759411 2.52557 0.549183 2.4446 0.373047 2.28267C0.196911 2.1179 0.108842 1.92045 0.108842 1.69034C0.108842 1.45739 0.196911 1.25994 0.373047 1.09801C0.549183 0.933238 0.759411 0.850852 1.00373 0.850852C1.25089 0.850852 1.46112 0.933238 1.63441 1.09801C1.81055 1.25994 1.89862 1.45739 1.89862 1.69034C1.89862 1.92045 1.81055 2.1179 1.63441 2.28267C1.46112 2.4446 1.25089 2.52557 1.00373 2.52557Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(IndeedIcon);
