import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function UnarchiveIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M3 5.88277C3 5.27927 3.27252 4.70803 3.74158 4.3283L5.44358 2.95045C5.80335 2.6592 6.25301 2.50176 6.71588 2.50497L12.16 2.54273L17.2902 2.51206C17.7517 2.5093 18.2001 2.66628 18.5591 2.95638L20.257 4.32827C20.7269 4.70798 21 5.27976 21 5.88392V20.5C21 21.6046 20.1046 22.5 19 22.5H5C3.89543 22.5 3 21.6046 3 20.5V5.88277Z'
				fill={fill}
			/>
			<path d='M6.40074 3.5H17.5993L19.0118 4.71191H4.98289L6.40074 3.5Z' fill='white' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M6.71028 16.5083C6.53445 16.5083 6.44419 16.2977 6.56544 16.1704L11.876 10.5936C11.9549 10.5108 12.087 10.5108 12.1658 10.5937L17.4694 16.1705C17.5905 16.2979 17.5002 16.5083 17.3245 16.5083H14.2042C14.0937 16.5083 14.0042 16.5979 14.0042 16.7083V18.3351C14.0042 18.4456 13.9146 18.5351 13.8042 18.5351H10.1904C10.0799 18.5351 9.99037 18.4456 9.99037 18.3351V16.7083C9.99037 16.5979 9.90082 16.5083 9.79037 16.5083H6.71028Z'
				fill='white'
			/>
		</svg>
	);
}

export default memo(UnarchiveIcon);
