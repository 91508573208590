export const CandidatesStatisticData = [
	{
		id: 1,
		title: 'New Candidates',
		field: 'numberOfNewCandidates',
	},
	{
		id: 2,
		title: 'Potential',
		field: 'numberOfPotentialCandidates',
	},
	{
		id: 3,
		title: 'Interviews',
		field: 'numberOfInterviewedCandidates',
	},
	{
		id: 4,
		title: 'Offers',
		field: 'numberOfOfferCandidates',
	},
	{
		id: 5,
		title: 'Hired',
		field: 'numberOfHiredCandidates',
	},
];

export const statusActiveColors = [
	'#F5F6FF',
	'#EBEDFF',
	'#E0E5FF',
	'#D6DCFF',
	'#ccd3ff',
	'#c2caff',
];

export const statusArchiveColors = [
	'#FAFAFA',
	'#F2F1F3',
	'#EDECEE',
	'#E5E4E7',
	'#DEDDE1',
	'#dcdbdb',
];
