export const ADDRESS_FIELDS = [
	'city',
	'zipCode',
	'phoneNumber',
	'email',
	'address',
	'country',
	'longitude',
	'latitude',
];
