import React, { FC, useCallback, useState } from 'react';
import { Form, Field, FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Checkbox } from 'antd';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import { IChangePasswordValues } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { TStyled } from 'theme';
import { Fields, GenericType, IUserFormValues, UserTypesEnum } from 'types';
import { LocalStorageType } from 'types/storage';
import { getFromLocalStorage } from 'utils/helpers';
import {
	passwordValidator,
	composeValidators,
	requiredValidator,
	repeatPasswordValidator,
} from 'utils/validators';

import UnregisteredInput from '../../components/UnregisteredInput';

import * as Styled from './ChangePasswordForm.styled';

type ChangePasswordFormProps = {
	changePasswordRequested: (
		values: Partial<IUserFormValues> | Partial<IChangePasswordValues>,
		cb: (route: string) => void,
	) => void;
	resetPasswordRequested: (value: Partial<IUserFormValues>, cb: (route: string) => void) => void;
	errorMessage: string;
	isResetPass: boolean;
	tempToken?: string | null;
	loading: GenericType;
};

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({
	changePasswordRequested,
	resetPasswordRequested,
	errorMessage,
	isResetPass,
	tempToken,
	loading,
}) => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { oldPassword } = state || {};
	const { userType } = getFromLocalStorage(LocalStorageType.User) || {};
	const isBOUser = userType === UserTypesEnum.BACKOFFICE;

	const [showCredentialsError, setCredErrorVisualization] = useState(false);

	const handleSetCredError = useCallback((value: boolean) => setCredErrorVisualization(value), []);

	const onSubmit = (values: Partial<IUserFormValues>) => {
		if (isResetPass && tempToken) {
			resetPasswordRequested({ password: values.password, tempToken }, (route) => navigate(route));
		} else {
			changePasswordRequested(
				isBOUser ? { oldPassword, newPassword: values.password } : { password: values.password },
				(route) => {
					route && navigate(route, { replace: true });
				},
			);
		}

		handleSetCredError(true);
	};

	return (
		<Styled.Form>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit, valid, dirtySinceLastSubmit, form }) => {
					if (dirtySinceLastSubmit) {
						handleSetCredError(false);
					}

					return (
						<form onSubmit={handleSubmit}>
							<FormSpy subscription={{ values: true }}>
								{() => (
									<>
										<Field
											name='password'
											validate={composeValidators(requiredValidator, passwordValidator)}
										>
											{({ input, meta }) => (
												<UnregisteredInput
													{...input}
													type={Fields.PASSWORD}
													placeholder='New Password'
													isFullWidth
													errorMessage={meta.error && meta.dirty && meta.error}
												/>
											)}
										</Field>
										<Field
											name='repeatPassword'
											validate={composeValidators(requiredValidator, (value: string) =>
												repeatPasswordValidator(form.getState().values.password, value),
											)}
										>
											{({ input, meta }) => (
												<UnregisteredInput
													{...input}
													type={Fields.PASSWORD}
													placeholder='New Password Confirmation'
													isFullWidth
													errorMessage={meta.error && meta.dirty && meta.error}
												/>
											)}
										</Field>
										{!isResetPass && (
											<Field
												name='acceptedTermsAndConditions'
												validate={composeValidators(requiredValidator)}
												type='checkbox'
											>
												{({ input, meta }) => (
													<FieldWrapper
														isFixed
														isInline
														name='acceptTermsAndConditions'
														errorMessage={meta.submitFailed && meta.touched && meta.error}
													>
														<Checkbox {...input}>
															{'I accept '}
															<a
																href={'/documents/bluetownonline-terms.pdf'}
																target='_blank'
																rel='noreferrer'
															>
																terms and conditions
															</a>
															{' and '}
															<a
																href={'/documents/bluetownonline-privacy-policy.pdf'}
																target='_blank'
																rel='noreferrer'
															>
																privacy policy
															</a>
															.
														</Checkbox>
													</FieldWrapper>
												)}
											</Field>
										)}
									</>
								)}
							</FormSpy>
							{showCredentialsError && errorMessage && typeof errorMessage === 'string' && (
								<TStyled.ErrorMessage>{errorMessage}</TStyled.ErrorMessage>
							)}

							<Button
								isFullWidth
								buttonType={ButtonTypes.primary}
								type='submit'
								disabled={!valid || !!loading?.changePasswordLoad}
								loading={!!loading?.changePasswordLoad}
							>
								{isResetPass ? 'Reset Password' : 'Finish registration'}
							</Button>
						</form>
					);
				}}
			/>
		</Styled.Form>
	);
};

export default connect(
	(state) => ({
		errorMessage: unregisteredDucks.unregisteredSelectors.getErrorMessage(state),
		loading: unregisteredDucks.unregisteredSelectors.getLoading(state),
	}),
	{
		changePasswordRequested: unregisteredDucks.unregisteredActions.changePasswordRequested,
		resetPasswordRequested: unregisteredDucks.unregisteredActions.resetPasswordRequested,
	},
)(ChangePasswordForm);
