import { IAssessmentAnswers, IAssessmentQuestions } from 'modules/Common/types';
import { COLORS } from 'theme';

export const determineColor = (option: IAssessmentAnswers, item: IAssessmentQuestions) => {
	const noCorrectAnswersSelected = item.candidateApplicationAnswers?.every(
		(answer) => !answer.correct,
	);

	if (
		(!item.autoreject && noCorrectAnswersSelected && option.checked) ||
		(option.correct && option.checked)
	) {
		return COLORS.green;
	} else if (option.checked) {
		return COLORS.red;
	} else {
		return 'none';
	}
};
