import _ from 'lodash';

import { formatInvoiceBtoReference } from 'utils/helpers';
import { handlersDefaultCase } from 'utils/reduxHelpers';

import { initialState } from './reducer.initial_state';

export const BackOfficeReducer = (TYPES) => {
	return (state = initialState, action = {}) => {
		const handlers = {
			[TYPES.GET_CLIENT_CONTEXT_CLIENTS_LIST.SUCCESS]: () => ({
				...state,
				clientContextState: {
					...state?.clientContextState,
					clientContextData: action.payload,
				},
			}),
			[TYPES.GET_CONTEXT_CLIENT.SUCCESS]: () => ({
				...state,
				clientContextState: {
					...state?.clientContextState,
					contextClientData: action.payload,
				},
			}),
			[TYPES.SAVE_CLIENT_CONTEXT_SELECTED_LIST]: () => ({
				...state,
				clientContextState: {
					...state?.clientContextState,
					selectedListData: action.payload,
				},
			}),
			[TYPES.GET_CLIENTS.SUCCESS]: () => ({
				...state,
				clientsState: {
					...state?.clientsState,
					clientsData: action.payload,
				},
			}),
			[TYPES.GET_CLIENTS_SHORT.SUCCESS]: () => ({
				...state,
				clientsState: {
					...state?.clientsState,
					clientsShortData: action.payload,
				},
			}),
			[TYPES.GET_CLIENT_BY_ID_SHORT.SUCCESS]: () => ({
				...state,
				currentClientShortData: action.payload,
			}),
			[TYPES.GET_BO_CLIENTS.SUCCESS]: () => ({
				...state,
				clientsState: {
					...state?.clientsState,
					clientsPaginatedData: action.payload,
				},
			}),
			[TYPES.GET_BO_CLIENTS.FAILED]: () => ({
				...state,
				clientsState: {
					...state?.clientsState,
					clientsPaginatedData: state?.clientsState.clientsPaginatedData,
				},
			}),
			[TYPES.GET_CLIENTS_SUBS.SUCCESS]: () => ({
				...state,
				clientsSubs: action.payload,
			}),
			[TYPES.GET_CREDITS_DEFAULT_PRICES.SUCCESS]: () => ({
				...state,
				creditsDefaultPrices: action.payload,
			}),
			[TYPES.GET_SUBS_DEFAULT_PRICES.SUCCESS]: () => ({
				...state,
				subsDefaultPrice: action.payload,
			}),
			[TYPES.GET_CLIENT_REGIONS.SUCCESS]: () => ({
				...state,
				regions: action.payload,
			}),
			[TYPES.GET_CLIENTS_BY_REGION.SUCCESS]: () => ({
				...state,
				clientsByRegion: action.payload,
			}),
			[TYPES.GET_CLIENTS_BY_REGION.FAILED]: () => ({
				...state,
				clientsByRegion: initialState.clientsByRegion,
			}),
			[TYPES.GET_CURRENT_CLIENT.REQUESTED]: () => ({
				...state,
				clientChangesFormState: {
					...initialState.clientChangesFormState,
					data: initialState.clientChangesFormState.data,
				},
			}),
			[TYPES.GET_CURRENT_CLIENT.SUCCESS]: () => ({
				...state,
				clientChangesFormState: {
					...state.clientChangesFormState,
					data: {
						...state.clientChangesFormState.data,
						...action.payload,
					},
				},
			}),
			[TYPES.GET_CLIENT_SUBS_INFO.REQUESTED]: () => ({
				...state,
				clientChangesFormState: {
					...state.clientChangesFormState,
					subsInfo: initialState.clientChangesFormState.subsInfo,
				},
			}),
			[TYPES.GET_CLIENT_SUBS_INFO.SUCCESS]: () => ({
				...state,
				clientChangesFormState: {
					...state.clientChangesFormState,
					data: {
						...state.clientChangesFormState.data,
						subsInfo: action.payload,
					},
				},
			}),
			[TYPES.GET_BACKOFFICE_INVOICES.REQUESTED]: () => ({
				...state,
				invoices: {
					...initialState.invoices,
					data: initialState.invoices.data,
				},
			}),
			[TYPES.GET_BACKOFFICE_INVOICES.SUCCESS]: () => ({
				...state,
				invoices: {
					...action.payload,
					data: action.payload.data.map(formatInvoiceBtoReference),
				},
			}),
			[TYPES.DELETE_INVOICE_BY_ID.SUCCESS]: () => ({
				...state,
				invoices: {
					...state.invoices,
					data: state.invoices.data.filter((invoice) => invoice.id !== action.payload),
				},
			}),
			[TYPES.GET_TICKETS.REQUESTED]: () => ({
				...state,
				tickets: initialState.tickets,
			}),
			[TYPES.GET_TICKETS.SUCCESS]: () => ({
				...state,
				tickets: action.payload,
			}),
			[TYPES.GET_TICKET_DETAILS.REQUESTED]: () => ({
				...state,
				ticketDetails: initialState.ticketDetails,
			}),
			[TYPES.RESET_TICKET_DETAILS]: () => ({
				...state,
				ticketDetails: initialState.ticketDetails,
			}),
			[TYPES.GET_TICKET_DETAILS.SUCCESS]: () => ({
				...state,
				ticketDetails: action.payload,
			}),
			[TYPES.GET_INVOICE_BY_ID.REQUESTED]: () => ({
				...state,
				currentInvoice: initialState.currentInvoice,
			}),
			[TYPES.GET_INVOICE_BY_ID.SUCCESS]: () => ({
				...state,
				currentInvoice: formatInvoiceBtoReference(action.payload),
			}),
			[TYPES.GET_CURRENT_CLIENT.FAILED]: () => ({
				...state,
				clientChangesFormState: {
					...initialState.clientChangesFormState,
					data: initialState.clientChangesFormState.data,
				},
			}),
			[TYPES.SET_CLIENT_CHANGES_FORM]: () => ({
				...state,
				clientChangesFormState: {
					...state.clientChangesFormState,
					isSaved: true,
					data: {
						...state.clientChangesFormState.data,
						...action.payload,
						careerBranding: {
							...state.clientChangesFormState.data.careerBranding,
							...action.payload.careerBranding,
						},
					},
				},
			}),
			[TYPES.OMIT_CLIENT_CHANGES_FORM_VALUES]: () => {
				const fieldsToOmit = action.payload;
				const updatedData = _.omit(state.clientChangesFormState.data, fieldsToOmit);

				return {
					...state,
					clientChangesFormState: {
						...state.clientChangesFormState,
						isSaved: true,
						data: updatedData,
					},
				};
			},
			[TYPES.RESET_CLIENT_CHANGES_FORM]: () => ({
				...state,
				clientChangesFormState: {
					...initialState.clientChangesFormState,
					data: initialState.clientChangesFormState.data,
				},
			}),
			[TYPES.CREATE_AND_UPDATE_CLIENT.SUCCESS]: () => ({
				...state,
				clientChangesFormState: {
					...initialState.clientChangesFormState,
					data: initialState.clientChangesFormState.data,
				},
			}),
			[TYPES.GET_ALL_BACKOFFICE_JOBS.SUCCESS]: () => ({
				...state,
				jobsState: action.payload,
			}),
			[TYPES.GET_ALL_BACKOFFICE_JOBS.FAILED]: () => ({
				...state,
				jobsState: {
					...initialState.jobsState,
				},
			}),
			[TYPES.GET_CLIENT_CONTEXT_INTERVIEW_LIST.SUCCESS]: () => ({
				...state,
				interviewsList: action.payload,
			}),
			[TYPES.GET_BACKOFFICE_CANDIDATES.REQUESTED]: () => ({
				...state,
				candidatesState: {
					...initialState.candidatesState,
					candidatesData: { ...initialState.candidatesState.candidatesData },
				},
			}),
			[TYPES.GET_BACKOFFICE_CANDIDATES.SUCCESS]: () => ({
				...state,
				candidatesState: {
					candidatesData: action.payload,
				},
			}),
			[TYPES.GET_BACKOFFICE_CANDIDATES.FAILED]: () => ({
				...state,
				candidatesState: {
					...initialState.candidatesState,
					candidatesData: { ...initialState.candidatesState.candidatesData },
				},
			}),
			[TYPES.GET_BACKOFFICE_JOB_BY_ID.REQUESTED]: () => ({
				...state,
				jobState: {
					...state.jobState,
					...initialState.jobState.jobData,
				},
			}),
			[TYPES.GET_BACKOFFICE_JOB_BY_ID.SUCCESS]: () => ({
				...state,
				jobState: {
					...state.jobState,
					jobData: action.payload,
				},
			}),
			[TYPES.GET_BACKOFFICE_JOB_BY_ID.FAILED]: () => ({
				...state,
				jobState: {
					...state.jobState,
					...initialState.jobState.jobData,
				},
			}),
			[TYPES.RESET_BACKOFFICE_JOB_CHANGES_FORM]: () => ({
				...state,
				jobState: initialState.jobState,
			}),
			[TYPES.GET_BO_APPLICATION_MESSAGES_BY_CLIENT_ID.REQUESTED]: () => ({
				...state,
				applicationMessagesState: initialState.applicationMessagesState,
			}),
			[TYPES.GET_BO_APPLICATION_MESSAGES_BY_CLIENT_ID.SUCCESS]: () => ({
				...state,
				applicationMessagesState: action.payload,
			}),
			[TYPES.GET_BO_APPLICATION_MESSAGES_BY_CLIENT_ID.FAILED]: () => ({
				...state,
				applicationMessagesState: initialState.applicationMessagesState,
			}),
			[TYPES.UPDATE_BACKOFFICE_TICKET_STATUS.SUCCESS]: () => ({
				...state,
				ticketDetails: {
					assessmentQuestions: state.ticketDetails.assessmentQuestions,
					...action.payload,
				},
			}),
			[TYPES.GET_INVOICE_STATUSES.SUCCESS]: () => ({
				...state,
				invoiceStatuses: action.payload,
			}),
			[TYPES.GET_INVOICE_PAYMENT_METHODS.SUCCESS]: () => ({
				...state,
				invoicePaymentMethods: action.payload,
			}),
			[TYPES.UPDATE_INVOICE.SUCCESS]: () => ({
				...state,
				currentInvoice: action.payload,
			}),
			[TYPES.UPDATE_INVOICE_STATUS.SUCCESS]: () => ({
				...state,
				currentInvoice: action.payload,
			}),
			[TYPES.GET_BACKOFFICE_CONTRACT_BY_ID.SUCCESS]: () => ({
				...state,
				currentContractDetails: action.payload,
			}),
			[TYPES.GET_BO_USER_BY_ID.REQUESTED]: () => ({
				...state,
				userState: {
					...state.userState,
					currentUser: { ...initialState.userState.currentUser },
				},
			}),
			[TYPES.GET_BO_USER_BY_ID.SUCCESS]: () => ({
				...state,
				userState: {
					...state.userState,
					currentUser: action.payload,
				},
			}),
			[TYPES.GET_BO_USER_ROLES.REQUESTED]: () => ({
				...state,
				userState: {
					...state.userState,
					userRoles: initialState.userState.userRoles,
				},
			}),
			[TYPES.GET_BO_USER_ROLES.SUCCESS]: () => ({
				...state,
				userState: {
					...state.userState,
					userRoles: action.payload,
				},
			}),
			[TYPES.GET_BO_USER_MANAGERS.REQUESTED]: () => ({
				...state,
				userState: {
					...state.userState,
					userManagers: initialState.userState.userManagers,
				},
			}),
			[TYPES.GET_BO_USER_MANAGERS.SUCCESS]: () => ({
				...state,
				userState: {
					...state.userState,
					userManagers: action.payload,
				},
			}),
			[TYPES.GET_BO_USERS.REQUESTED]: () => ({
				...state,
				userState: {
					...state.userState,
					usersList: { ...initialState.userState.usersList },
				},
			}),
			[TYPES.GET_BO_USERS.SUCCESS]: () => ({
				...state,
				userState: {
					...state.userState,
					usersList: action.payload,
				},
			}),
			[TYPES.GET_BO_CONTRACTS_BY_CLIENT_ID.REQUESTED]: () => ({
				...state,
				clientContracts: initialState.clientContracts,
			}),
			[TYPES.GET_BO_CONTRACTS_BY_CLIENT_ID.SUCCESS]: () => ({
				...state,
				clientContracts: action.payload,
			}),
			[TYPES.GET_BO_CONTRACTS_BY_CLIENT_ID.FAILED]: () => ({
				...state,
				clientContracts: initialState.clientContracts,
			}),
			[TYPES.GET_CANDIDATE_STATISTICS_BO.SUCCESS]: () => ({
				...state,
				statistics: {
					...state.statistics,
					candidateStatistics: action.payload,
				},
			}),
			[TYPES.GET_CLIENT_CONTEXT_DASHBOARD_UNREAD_EMAILS.SUCCESS]: () => ({
				...state,
				unreadEmails: action.payload,
			}),
			[TYPES.GET_JOBS_MONTH_STATISTIC_BO.REQUESTED]: () => ({
				...state,
				statistics: {
					...state.statistics,
					jobsMonthStatistic: initialState.statistics.jobsMonthStatistic,
				},
			}),
			[TYPES.GET_JOBS_MONTH_STATISTIC_BO.SUCCESS]: () => ({
				...state,
				statistics: {
					...state.statistics,
					jobsMonthStatistic: action.payload,
				},
			}),
			[TYPES.GET_JOBS_MONTH_STATISTIC_BO.FAILED]: () => ({
				...state,
				statistics: {
					...state.statistics,
					jobsMonthStatistic: initialState.statistics.jobsMonthStatistic,
				},
			}),
		};

		return handlersDefaultCase(handlers, action, state);
	};
};
