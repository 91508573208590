import styled from 'styled-components';

export namespace Styled {
	export const FormBlockContent = styled.div<{ columns: number }>`
		display: flex;
		gap: 32px;
		margin-bottom: 32px;

		& > div {
			width: ${({ columns }) => `${100 / columns}%`};
			height: 100%;
			padding: 0;
		}

		&:last-of-type {
			margin-bottom: 16px;
		}
	`;
}
