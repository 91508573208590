import { combineReducers } from 'redux';

import { takeLatest } from 'redux-saga/effects';

import createLoading from 'modules/Common/ducks/loading';

import { createActions } from './actions';
import { createActionsTypes } from './constants';
import { CommonReducer } from './reducer';
import { createSagas } from './sagas';
import { createSelectors } from './selectors';

function getCommonDucks(props) {
	const { prefix, root } = props;
	const PREFIX = prefix;

	const TYPES = createActionsTypes(PREFIX);
	const ACTIONS = createActions(TYPES);
	const SELECTORS = createSelectors(root);

	const SAGAS = createSagas(TYPES, PREFIX, SELECTORS, ACTIONS, props);
	const commonReducer = CommonReducer(TYPES);

	const { reducer: loadingReducer } = createLoading({
		GET_CONTRACT_BY_ID: TYPES.GET_CONTRACT_BY_ID,
		GET_CONTRACT_RECEIPT: TYPES.GET_CONTRACT_RECEIPT,
		GET_CONTRACTS: TYPES.GET_CONTRACTS,
		GET_CONTRACT_STATUSES: TYPES.GET_CONTRACT_STATUSES,
		GET_CONTRACT_VAT: TYPES.GET_CONTRACT_VAT,
		DELETE_CONTRACT_BY_ID: TYPES.DELETE_CONTRACT_BY_ID,
		GET_CLIENT_JOBS: TYPES.GET_CLIENT_JOBS,
		GET_CLIENT_USERS: TYPES.GET_CLIENT_USERS,
		GET_CLIENT_COPY_JOBS: TYPES.GET_CLIENT_COPY_JOBS,
		GET_OPEN_CLIENT_JOBS: TYPES.GET_OPEN_CLIENT_JOBS,
		GET_CREDITS: TYPES.GET_CREDITS,
		APPLY_ON_COMPANY: TYPES.APPLY_ON_COMPANY,
		APPLY_ON_JOB: TYPES.APPLY_ON_JOB,
		UPDATE_CLIENT: TYPES.UPDATE_CLIENT,
		UPDATE_FREE_JOB: TYPES.UPDATE_FREE_JOB,
		UPDATE_PAID_JOB: TYPES.UPDATE_PAID_JOB,
		SAVE_PAID_JOB: TYPES.SAVE_PAID_JOB,
		SAVE_FREE_JOB: TYPES.SAVE_FREE_JOB,
		GET_JOB_BY_ID: TYPES.GET_JOB_BY_ID,
		GET_JOB_TYPES: TYPES.GET_EMPLOYMENT_TYPES,
		GET_EMPLOYMENT_TYPES: TYPES.GET_EMPLOYMENT_TYPES,
		GET_EDUCATION_TYPES: TYPES.GET_EDUCATION_TYPES,
		GET_EDUCATION_TYPES_BY_REGION_ID: TYPES.GET_EDUCATION_TYPES_BY_REGION_ID,
		GET_INTERVIEW_OPEN: TYPES.GET_INTERVIEW_OPEN,
		DECLINE_INTERVIEW_OPEN: TYPES.DECLINE_INTERVIEW_OPEN,
		GET_CANDIDATE_BY_ID: TYPES.GET_CANDIDATE_BY_ID,
		UPDATE_APPLICATION_STATUS: TYPES.UPDATE_APPLICATION_STATUS,
		GET_ALL_BO_QUESTIONNAIRES: TYPES.GET_ALL_BO_QUESTIONNAIRES,
		GET_ALL_ATS_QUESTIONNAIRES: TYPES.GET_ALL_ATS_QUESTIONNAIRES,
		GET_QUESTIONNAIRE_USERS: TYPES.GET_QUESTIONNAIRE_USERS,
		GET_QUESTIONNAIRE_BY_ID: TYPES.GET_QUESTIONNAIRE_BY_ID,
		GET_COPY_QUESTIONNAIRES: TYPES.GET_COPY_QUESTIONNAIRES,
		GET_SEND_QUESTIONNAIRES_LIST: TYPES.GET_SEND_QUESTIONNAIRES_LIST,
		SEND_CANDIDATE_QUESTIONNAIRE: TYPES.SEND_CANDIDATE_QUESTIONNAIRE,
		RESEND_CANDIDATE_QUESTIONNAIRE: TYPES.RESEND_CANDIDATE_QUESTIONNAIRE,
		RECALL_CANDIDATE_QUESTIONNAIRE: TYPES.RECALL_CANDIDATE_QUESTIONNAIRE,
		GET_VIDEO_QUESTIONNAIRE_BY_CANDIDATE_ID: TYPES.GET_VIDEO_QUESTIONNAIRE_BY_CANDIDATE_ID,
		GET_QUESTIONNAIRE_DURATION: TYPES.GET_QUESTIONNAIRE_DURATION,
		GET_CLIENT_BY_ID: TYPES.GET_CLIENT_BY_ID,
		SAVE_QUESTIONNAIRES: TYPES.SAVE_QUESTIONNAIRES,
		GET_QUESTIONNAIRE_RESULT: TYPES.GET_QUESTIONNAIRE_RESULT,
		DELETE_QUESTIONNAIRE_BY_ID: TYPES.DELETE_QUESTIONNAIRE_BY_ID,
		ARCHIVE_UNARCHIVE_QUESTIONNAIRE_BY_ID: TYPES.ARCHIVE_UNARCHIVE_QUESTIONNAIRE_BY_ID,
		UPDATE_CANDIDATE_RANK: TYPES.UPDATE_CANDIDATE_RANK,
		GET_JOB_INDUSTRIES: TYPES.GET_JOB_INDUSTRIES,
		GET_TICKET_TYPES: TYPES.GET_TICKET_TYPES,
		GET_TICKET_STATUSES: TYPES.GET_TICKET_STATUSES,
		GET_CONTRACTS_BY_CLIENT_ID: TYPES.GET_CONTRACTS_BY_CLIENT_ID,
		APPROVE_PENDING_CONTRACT: TYPES.APPROVE_PENDING_CONTRACT,
		GET_SMS_COUNT: TYPES.GET_SMS_COUNT,
		GET_SMS_ORDER_PRICE: TYPES.GET_SMS_ORDER_PRICE,
		GET_VIDEO_INTERVIEW_ORDER_PRICE: TYPES.GET_VIDEO_INTERVIEW_ORDER_PRICE,
		GET_EMAIL_AUTO_FILLED_DATA: TYPES.GET_EMAIL_AUTO_FILLED_DATA,
		GET_EMAIL_DEFAULT_TEMPLATES: TYPES.GET_EMAIL_DEFAULT_TEMPLATES,
		UPDATE_EMAIL_DEFAULT_TEMPLATES: TYPES.UPDATE_EMAIL_DEFAULT_TEMPLATES,
		GET_PRICES_BY_CLIENT_ID: TYPES.GET_PRICES_BY_CLIENT_ID,
	});

	function* commonSaga() {
		yield takeLatest(TYPES.GET_CONTRACTS.REQUESTED, SAGAS.getContractsSaga);
		yield takeLatest(TYPES.GET_CONTRACT_STATUSES.REQUESTED, SAGAS.getContractStatusesSaga);
		yield takeLatest(TYPES.UPDATE_CONTRACT_STATUS.REQUESTED, SAGAS.updateContractStatusSaga);
		yield takeLatest(TYPES.APPROVE_PENDING_CONTRACT.REQUESTED, SAGAS.approvePendingContractSaga);
		yield takeLatest(TYPES.ACCEPT_CONTRACT_BY_ID.REQUESTED, SAGAS.acceptContractByClientIdSaga);
		yield takeLatest(TYPES.GET_CONTRACTS_BY_CLIENT_ID.REQUESTED, SAGAS.getContractsByClientIdSaga);
		yield takeLatest(TYPES.DELETE_CONTRACT_BY_ID.REQUESTED, SAGAS.deleteContractByIdSaga);
		yield takeLatest(TYPES.GET_CONTRACT_RECEIPT.REQUESTED, SAGAS.getContractReceiptSaga);
		yield takeLatest(TYPES.GET_CONTRACT_BY_ID.REQUESTED, SAGAS.getContractByIdSaga);
		yield takeLatest(TYPES.GET_CONTRACT_VAT.REQUESTED, SAGAS.getContractVatSaga);
		yield takeLatest(TYPES.GET_CLIENT_BY_ID.REQUESTED, SAGAS.getClientByIdSaga);
		yield takeLatest(TYPES.UPDATE_CLIENT.REQUESTED, SAGAS.updateClientSaga);
		yield takeLatest(TYPES.GET_CREDITS.REQUESTED, SAGAS.getCreditsSaga);
		yield takeLatest(TYPES.GET_JOB_BY_ID.REQUESTED, SAGAS.getJobByIdSaga);
		yield takeLatest(TYPES.GET_CANDIDATE_BY_ID.REQUESTED, SAGAS.getCandidateByIdSaga);
		yield takeLatest(TYPES.UPDATE_APPLICATION_STATUS.REQUESTED, SAGAS.updateApplicationStatusSaga);
		yield takeLatest(TYPES.GET_OPEN_JOB_BY_ID.REQUESTED, SAGAS.getOpenJobByIdSaga);
		yield takeLatest(TYPES.GET_JOB_TYPES.REQUESTED, SAGAS.getJobTypesSaga);
		yield takeLatest(TYPES.GET_JOB_ASSESSMENT.REQUESTED, SAGAS.getJobAssessmentSaga);
		yield takeLatest(TYPES.APPLY_ON_JOB.REQUESTED, SAGAS.applyOnJobSaga);
		yield takeLatest(TYPES.APPLY_ON_COMPANY.REQUESTED, SAGAS.applyOnCompanySaga);
		yield takeLatest(TYPES.SAVE_FREE_JOB.REQUESTED, SAGAS.saveFreeJobSaga);
		yield takeLatest(TYPES.SAVE_PAID_JOB.REQUESTED, SAGAS.savePaidJobSaga);
		yield takeLatest(TYPES.UPDATE_FREE_JOB.REQUESTED, SAGAS.updateFreeJobSaga);
		yield takeLatest(TYPES.UPDATE_PAID_JOB.REQUESTED, SAGAS.updatePaidJobSaga);
		yield takeLatest(TYPES.GET_PRICES_BY_CLIENT_ID.REQUESTED, SAGAS.getPricesByClientIdSaga);
		yield takeLatest(TYPES.GET_EMPLOYMENT_TYPES.REQUESTED, SAGAS.getEmploymentTypesSaga);
		yield takeLatest(TYPES.GET_EDUCATION_TYPES.REQUESTED, SAGAS.getEducationTypesSaga);
		yield takeLatest(
			TYPES.GET_EDUCATION_TYPES_BY_REGION_ID.REQUESTED,
			SAGAS.getEducationTypesByRegionIdSaga,
		);
		yield takeLatest(TYPES.GET_CLIENT_BRANDING.REQUESTED, SAGAS.getClientBrandingSaga);
		yield takeLatest(TYPES.GET_CLIENT_JOBS.REQUESTED, SAGAS.getClientJobsSaga);
		yield takeLatest(TYPES.GET_CLIENT_USERS.REQUESTED, SAGAS.getClientUsersSaga);
		yield takeLatest(TYPES.GET_CLIENT_COPY_JOBS.REQUESTED, SAGAS.getClientCopyJobsSaga);
		yield takeLatest(TYPES.GET_OPEN_CLIENT_JOBS.REQUESTED, SAGAS.getOpenClientJobsSaga);
		yield takeLatest(TYPES.GET_INTERVIEW_OPEN.REQUESTED, SAGAS.getInterviewOpenSaga);
		yield takeLatest(TYPES.DECLINE_INTERVIEW_OPEN.REQUESTED, SAGAS.declineInterviewOpenSaga);
		yield takeLatest(TYPES.GET_ALL_BO_QUESTIONNAIRES.REQUESTED, SAGAS.getAllBOQuestionnairesSaga);
		yield takeLatest(TYPES.GET_ALL_ATS_QUESTIONNAIRES.REQUESTED, SAGAS.getAllAtsQuestionnairesSaga);
		yield takeLatest(TYPES.GET_QUESTIONNAIRE_USERS.REQUESTED, SAGAS.getQuestionnaireUsersSaga);
		yield takeLatest(TYPES.GET_QUESTIONNAIRE_BY_ID.REQUESTED, SAGAS.getQuestionnaireByIdSaga);
		yield takeLatest(
			TYPES.GET_QUESTIONNAIRE_RESULT.REQUESTED,
			SAGAS.getVideoQuestionnaireResultSaga,
		);
		yield takeLatest(TYPES.GET_COPY_QUESTIONNAIRES.REQUESTED, SAGAS.getCopyQuestionnairesSaga);
		yield takeLatest(TYPES.GET_SEND_QUESTIONNAIRES_LIST.REQUESTED, SAGAS.getSendQuestionnairesSaga);
		yield takeLatest(
			TYPES.GET_VIDEO_QUESTIONNAIRE_BY_CANDIDATE_ID.REQUESTED,
			SAGAS.getVideoQuestionnaireByCandidateIdSaga,
		);
		yield takeLatest(
			TYPES.GET_QUESTIONNAIRE_DURATION.REQUESTED,
			SAGAS.getQuestionnaireDurationsSaga,
		);
		yield takeLatest(TYPES.SAVE_QUESTIONNAIRES.REQUESTED, SAGAS.saveQuestionnaireSaga);
		yield takeLatest(
			TYPES.SEND_CANDIDATE_QUESTIONNAIRE.REQUESTED,
			SAGAS.sendCandidateQuestionnaireSaga,
		);
		yield takeLatest(
			TYPES.RESEND_CANDIDATE_QUESTIONNAIRE.REQUESTED,
			SAGAS.resendCandidateQuestionnaireSaga,
		);
		yield takeLatest(
			TYPES.RECALL_CANDIDATE_QUESTIONNAIRE.REQUESTED,
			SAGAS.recallCandidateQuestionnaireSaga,
		);
		yield takeLatest(TYPES.DELETE_QUESTIONNAIRE_BY_ID.REQUESTED, SAGAS.deleteQuestionnaireByIdSaga);
		yield takeLatest(
			TYPES.ARCHIVE_UNARCHIVE_QUESTIONNAIRE_BY_ID.REQUESTED,
			SAGAS.archiveUnarchiveQuestionnaireByIdSaga,
		);
		yield takeLatest(TYPES.UPDATE_CANDIDATE_RANK.REQUESTED, SAGAS.updateCandidateRankSaga);
		yield takeLatest(TYPES.GET_JOB_INDUSTRIES.REQUESTED, SAGAS.getJobIndustriesSaga);
		yield takeLatest(TYPES.GET_TICKET_TYPES.REQUESTED, SAGAS.getTicketTypesSaga);
		yield takeLatest(TYPES.GET_TICKET_STATUSES.REQUESTED, SAGAS.getTicketStatusesSaga);
		yield takeLatest(TYPES.GET_SMS_COUNT.REQUESTED, SAGAS.getSMSCountSaga);
		yield takeLatest(TYPES.GET_SMS_ORDER_PRICE.REQUESTED, SAGAS.getSMSOrderPriceSaga);
		yield takeLatest(
			TYPES.GET_VIDEO_INTERVIEW_ORDER_PRICE.REQUESTED,
			SAGAS.getVideoInterviewOrderPriceSaga,
		);
		yield takeLatest(TYPES.GET_EMAIL_AUTO_FILLED_DATA.REQUESTED, SAGAS.getEmailAutoFilledDataSaga);
		yield takeLatest(TYPES.GET_EMAIL_DEFAULT_TEMPLATES.REQUESTED, SAGAS.getEmailTemplatesSaga);
		yield takeLatest(
			TYPES.UPDATE_EMAIL_DEFAULT_TEMPLATES.REQUESTED,
			SAGAS.updateEmailTemplatesSaga,
		);
		yield takeLatest(TYPES.DELETE_ASSESSMENT_FORM.REQUESTED, SAGAS.deleteAssessmentFormSaga);
	}

	const reducer = combineReducers({ data: commonReducer, loadingReducer });

	return {
		reducer,
		commonSaga,
		commonActions: { ...ACTIONS },
		commonSelectors: SELECTORS,
		commonActionTypes: TYPES,
		commonSagas: SAGAS,
	};
}

export const commonDucks = getCommonDucks({ prefix: 'common', root: 'commonReducer' });
