import React from 'react';

import { TStyled } from 'theme';
import { Routes } from 'types';

import UnregisteredLayout from '../../components/UnregisteredLayout';
import LoginForm from '../../containers/LoginForm';
import UnregisteredUser from '../../containers/UnregisteredUser';

const LoginPageContent = () => (
	<TStyled.UnregisteredFormWrapper>
		<TStyled.UnregisteredFormTitle>Login</TStyled.UnregisteredFormTitle>
		<LoginForm />
		<TStyled.UnregisteredFormLink to={Routes.ForgotPass}>
			Forgot Password?
		</TStyled.UnregisteredFormLink>
	</TStyled.UnregisteredFormWrapper>
);

export const LoginPage = () => (
	<UnregisteredLayout>
		<UnregisteredUser pageContent={<LoginPageContent />} />
	</UnregisteredLayout>
);
