import React, { type FC } from 'react';

import { Input } from 'antd';

import ClipIcon from 'components/SVG/ClipIcon';
import DownloadIcon from 'components/SVG/DownloadIcon';
import { AppMethodsFreeData, AppMethodsPaidData } from 'modules/Common/constants';
import { FieldTypesEnum, IFileProperties, JobClassesEnum } from 'modules/Common/types';
import { COLORS } from 'theme';
import { handleFileDownload } from 'utils/helpers';

import { Styled } from './ApplicationMethodView.styled';

type ApplicationMethodViewProps = {
	applicationMethod: string;
	applicationInputValue?: string;
	applicationAttachment?: IFileProperties;
	jobType: JobClassesEnum;
};

const ApplicationMethodView: FC<ApplicationMethodViewProps> = ({
	applicationMethod,
	applicationInputValue = '',
	applicationAttachment,
	jobType,
}) => {
	const fields =
		jobType && jobType === JobClassesEnum.PaidJob ? AppMethodsPaidData : AppMethodsFreeData;
	const currentAppMethod = fields?.find((i) => i.value === applicationMethod);

	return (
		<>
			<h2>Application Method</h2>
			<Styled.RadioField value={currentAppMethod?.value}>
				<Styled.RadioOption value={currentAppMethod?.value} checked>
					{currentAppMethod?.label}
				</Styled.RadioOption>
				<Styled.FieldWrapper>
					{currentAppMethod?.fields?.map((field) => {
						if (field?.type === FieldTypesEnum.File) {
							return (
								<Styled.FieldInputWrapper key={field.id}>
									<Styled.Attachment
										key={applicationAttachment?.referenceUuid}
										onClick={() =>
											handleFileDownload(
												applicationAttachment?.referenceUuid,
												applicationAttachment?.originalFileName,
											)
										}
									>
										<Styled.AttachmentFileName>
											<ClipIcon fill={COLORS.darkGray2} width='22' height='22' />
											<span>{applicationAttachment?.originalFileName}</span>
										</Styled.AttachmentFileName>
										<DownloadIcon fill={COLORS.darkGray2} width='22' height='22' />
									</Styled.Attachment>
								</Styled.FieldInputWrapper>
							);
						} else {
							return (
								<Styled.FieldInputWrapper key={field.id}>
									<Input {...field} value={applicationInputValue} readOnly />
								</Styled.FieldInputWrapper>
							);
						}
					})}
				</Styled.FieldWrapper>
			</Styled.RadioField>
		</>
	);
};

export default ApplicationMethodView;
