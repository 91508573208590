import React, { useRef } from 'react';

import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';
import ClientChangesFormBranding from 'modules/BackOffice/containers/ClientChangesFormBranding';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { Routes } from 'types';

import { Root } from './CreateClientBranding.styled';

const CreateClientPage = () => {
	const anchorRef = useRef(null);
	useScrollToTopOnMount(anchorRef);

	return (
		<Root ref={anchorRef}>
			<PageNavTitle
				title='Client ClientBranding'
				navigationLink={`${Routes.BackOffice}${Routes.ClientCreate}`}
			/>
			<ClientChangesFormBranding />
		</Root>
	);
};

export default CreateClientPage;
