import React, { type FC, useEffect, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { Spin } from 'antd';
import { omit } from 'lodash';

import Box from 'components/Box';
import { ButtonTypes } from 'components/Button';
import FieldWrapper from 'components/FieldWrapper';
import FormSwitch from 'components/FormSwitch';
import Spinner from 'components/Spinner';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import { atsDucks } from 'modules/ATS/ducks';
import FormBlockGrid from 'modules/Common/components/FormBlockGrid';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { GenericType } from 'types';

import { Styled } from '../ATSSettings.styled';

interface INotificationsData {
	interviewReminder: boolean;
	sendSMSQuestionnaireReminder: boolean;
}

export type SocialNetworkProps = {
	clientId: string;
	clientSMSNotifications: INotificationsData;
	getClientSMSNotifications: (clientId: number) => void;
	updateClientSMSNotifications: (values: INotificationsData) => void;
	loading: GenericType;
};

const ATSSMSNotifications: FC<SocialNetworkProps> = ({
	clientId,
	clientSMSNotifications,
	getClientSMSNotifications,
	updateClientSMSNotifications,
	loading,
}) => {
	const onSubmit = (values: INotificationsData) => {
		updateClientSMSNotifications(values);
	};

	useEffect(() => {
		clientId && getClientSMSNotifications(+clientId);
	}, []);

	const initialValues = useMemo(
		() => ({
			...omit(clientSMSNotifications, ['client']),
		}),
		[clientSMSNotifications],
	);

	const isUpdating = !!loading?.updateClientSmsNotificationsLoad;

	return (
		<Styled.Root>
			{loading.getClientSmsNotificationsLoad ? (
				<Spinner fullWidth />
			) : (
				<Form
					onSubmit={onSubmit}
					initialValues={initialValues}
					render={({ handleSubmit, dirty }) => (
						<form onSubmit={handleSubmit}>
							<>
								<Box noSpaces fullWidth style={{ flex: '1 1 auto' }}>
									<h2>SMS Reminders</h2>
									<FormBlockGrid columns={1}>
										<FieldWrapper name='interviewReminder'>
											<Styled.FieldHint>
												Set the default state for candidate reminders below.
											</Styled.FieldHint>
											<Field name={'interviewReminder'}>
												{({ input }) => (
													<FormSwitch {...input} title='Send SMS interview reminder' />
												)}
											</Field>
										</FieldWrapper>
										<FieldWrapper
											name='sendSMSQuestionnaireReminder'
											hint='Enable the toggle below to send candidates reminders for incomplete questionnaires'
										>
											<Field name={'sendSMSQuestionnaireReminder'}>
												{({ input }) => (
													<FormSwitch {...input} title='Send SMS questionnaire reminder' />
												)}
											</Field>
										</FieldWrapper>
									</FormBlockGrid>
								</Box>
								<Styled.ButtonWrapper>
									<ATSThemedButton
										buttonType={ButtonTypes.primary}
										type='submit'
										disabled={!dirty || isUpdating}
									>
										Save
										{isUpdating ? <Spin style={{ marginLeft: '12px' }} /> : ''}
									</ATSThemedButton>
								</Styled.ButtonWrapper>
							</>
						</form>
					)}
				/>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		clientId: unregisteredDucks.unregisteredSelectors.getClientId(state),
		clientSMSNotifications: atsDucks.atsSelectors.getClientSMSNotifications(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getClientSMSNotifications: atsDucks.atsActions.getSMSNotificationsRequested,
		updateClientSMSNotifications: atsDucks.atsActions.updateSMSNotificationsRequested,
	},
)(ATSSMSNotifications);
