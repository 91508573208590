import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function ListViewIcon({ fill = COLORS.darkGray2, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fill={fill}
				fillRule='evenodd'
				d='M3 5a1 1 0 011 1v.01a1 1 0 01-2 0V6a1 1 0 011-1zm3 1a1 1 0 011-1h14a1 1 0 110 1.999H7a1 1 0 01-1-1zm-3 4.996a1 1 0 011 .999v.01a1 1 0 01-2 0v-.01a1 1 0 011-1zm3 .999a1 1 0 011-1h14a1 1 0 110 2H7a1 1 0 01-1-1zm-3 4.996a1 1 0 011 1V18a1 1 0 01-2 0v-.01a1 1 0 011-1zm3 1a1 1 0 011-1h14a1 1 0 110 1.999H7a1 1 0 01-1-1z'
				clipRule='evenodd'
			></path>
		</svg>
	);
}

export default memo(ListViewIcon);
