import styled from 'styled-components';

interface IFormSwitchTitle {
	isDisabled?: boolean;
}

export namespace Styled {
	export const FormSwitchWrapper = styled.div`
		display: flex;
	`;

	export const FormSwitchTitle = styled.h4<IFormSwitchTitle>`
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: ${({ theme: { colors }, isDisabled }) => (isDisabled ? colors.gray : colors.black)};
		padding-left: 12px;
	`;

	export const Hint = styled.div<IFormSwitchTitle>`
		margin-left: 16px;
		font-size: 14px;
		line-height: 24px;
		color: ${({ theme: { colors }, isDisabled }) => (isDisabled ? colors.gray : colors.darkGray2)};
	`;
}
