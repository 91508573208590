import styled from 'styled-components/macro';

import { COLORS } from 'theme';

type BrandingSwitchItemProps = {
	isItemActive?: boolean;
};

export namespace Styled {
	export const BrandingSwitchWrapper = styled.div`
		display: flex;
		height: 40px;
		outline: 1px solid ${COLORS.borderColor};
		border-radius: 4px;
		overflow: hidden;
	`;

	export const BrandingSwitchItem = styled.div<BrandingSwitchItemProps>`
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 12px 0;
		cursor: pointer;
		border-radius: 4px;

		${({ isItemActive }) => `
			border-color: ${isItemActive ? COLORS.blue : 'none'};
			border: 1px solid ${isItemActive ? COLORS.lightGray2 : 'none'};
			background: ${isItemActive ? COLORS.white : COLORS.lightGray3};
			box-shadow: ${isItemActive ? COLORS.elevationShadow1 : 'none'};
		`}

		&:hover, &:focus, &:active {
			background-color: ${COLORS.white};
			color: ${COLORS.blue};
			border-color: ${COLORS.lightGray2};

			svg {
				path {
					fill: ${COLORS.blue};
				}
			}
		}
	`;

	export const BrandingSwitchItemIcon = styled.div<BrandingSwitchItemProps>`
		${({ isItemActive }) => `
			path {
				fill: ${isItemActive ? COLORS.blue : COLORS.black};
			}
		`}

		svg {
			vertical-align: middle;
		}
	`;
}
