import { Button as ButtonComponent } from 'antd';
import styled from 'styled-components/macro';

import Box from 'components/Box';
import { COLORS } from 'theme';

type CandidatesSectionTitleBlockProps = {
	color: string;
};

export namespace Styled {
	export const Root = styled(Box)``;
	export const ActionPanel = styled.div`
		display: flex;
		justify-content: space-between;
	`;
	export const KanbanRoot = styled.div`
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		column-gap: 24px;
		width: 100%;
		margin-top: 24px;
		padding: 0;
		min-height: 400px;
	`;

	export const CandidatesSection = styled.div``;
	export const CandidatesSectionTitleBlock = styled.div<CandidatesSectionTitleBlockProps>`
		${({ color }) => `background-color: ${color};`}
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px;
		border-radius: 8px;
		color: ${COLORS.black};
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
	`;
	export const CandidatesSectionTitle = styled.div``;
	export const CandidatesSectionCounter = styled.div``;
	export const CandidatesList = styled.div`
		max-height: 500px;
		overflow-y: auto;
	`;
	export const CandidateItem = styled.div`
		margin-top: 16px;
		cursor: pointer;
		display: flex;
		padding: 16px;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 12px;
		align-self: stretch;
		border: 1px solid ${COLORS.lightGray2};
		background: ${COLORS.white};
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		border-radius: 8px;
	`;
	export const CandidateItemHeader = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	`;
	export const CandidateItemName = styled((props) => <ButtonComponent {...props} />)`
		display: flex;
		align-items: center;
		height: auto;
		padding: 0;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		color: ${COLORS.black};
		white-space: normal;
		text-align: left;

		transition: color 0.2s ease;

		&:hover {
			color: ${COLORS.blue};
		}
	`;
	export const CandidateItemBadge = styled.div`
		display: flex;
		padding: 4px 8px;
		justify-content: center;
		align-items: center;
		border-radius: 2px;
		overflow: hidden;
		border: 1px solid rgba(153, 167, 255, 0.4);
		background: rgba(153, 167, 255, 0.1);
		margin-left: 16px;
		color: ${COLORS.lightBlue1};
		text-align: center;
		font-size: 10px;
		font-style: normal;
		font-weight: 700;
		line-height: 12px;
		height: 20px;
		min-width: 40px;
	`;
}
