import type { CSSProperties, FC } from 'react';

import { ReactParent } from 'types';

import { S } from './Box.styled';

type ContainerProps = ReactParent & {
	fullWidth?: boolean;
	noSpaces?: boolean;
	style?: CSSProperties;
};

export const Box: FC<ContainerProps> = ({
	fullWidth = true,
	noSpaces = false,
	children,
	style,
}) => {
	return (
		<S.Root fullWidth={fullWidth} noSpaces={noSpaces} style={style}>
			<S.Main>{children}</S.Main>
		</S.Root>
	);
};
