import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function MuteSmallIcon({ active, width = '16', height = '16' }: IconType) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			fill='none'
			viewBox='0 0 16 16'
		>
			<path
				fill={active ? COLORS.red : COLORS.black}
				fillRule='evenodd'
				d='M8 1.333A2.667 2.667 0 005.333 4v3.333a2.667 2.667 0 005.333 0V4A2.667 2.667 0 008 1.333z'
				clipRule='evenodd'
			></path>
			<path
				fill={active ? COLORS.red : COLORS.black}
				d='M8.665 12.623l.002.044V14a.667.667 0 11-1.334 0v-1.333c0-.015 0-.03.002-.044C4.67 12.273 2.667 9.853 2.667 7A.667.667 0 014 7c0 2.437 1.833 4.333 4 4.333 2.166 0 4-1.896 4-4.333a.667.667 0 011.333 0c0 2.852-2.004 5.274-4.668 5.623z'
			></path>
		</svg>
	);
}

export default memo(MuteSmallIcon);
