import { handlersDefaultCase } from 'utils/reduxHelpers';

import { initialState } from './reducer.initial_state';
import { ActionTypes, GenericAction } from './types';

export const HRModuleReducer = (TYPES: ActionTypes) => {
	return (state = initialState, action: GenericAction<unknown> = { type: '' }) => {
		const handlers = {
			[TYPES.GET_EMPLOYEES_ROLES.SUCCESS]: () => ({
				...state,
				employeeRoles: action.payload,
			}),
			[TYPES.GET_EMPLOYEE_STATUSES.SUCCESS]: () => ({
				...state,
				employeeStatuses: action.payload,
			}),
			[TYPES.GET_EMPLOYEES_ROLES.FAILED]: () => ({
				...state,
				employeeRoles: initialState.employeeRoles,
			}),
			[TYPES.VIEW_EMPLOYEES_LIST.SUCCESS]: () => ({
				...state,
				employeesList: action.payload,
			}),
			[TYPES.VIEW_EMPLOYEES_LIST.FAILED]: () => ({
				...state,
				employeesList: initialState.employeesList,
			}),
			[TYPES.GET_EMPLOYEE_BY_ID.SUCCESS]: () => ({
				...state,
				employeeDetails: action.payload,
			}),
			[TYPES.GET_EMPLOYEE_BY_ID.FAILED]: () => ({
				...state,
				employeeDetails: initialState.employeeDetails,
			}),
			[TYPES.RESET_EMPLOYEE_DETAILS as unknown as string]: () => ({
				...state,
				employeeDetails: initialState.employeeDetails,
			}),
		};

		return handlersDefaultCase(handlers, action, state);
	};
};
