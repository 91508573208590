import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function InfoIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_1903_23181)'>
				<path
					d='M11.5999 9.02921C12.3363 9.02921 12.9333 8.43226 12.9333 7.69588C12.9333 6.9595 12.3363 6.36255 11.5999 6.36255C10.8636 6.36255 10.2666 6.9595 10.2666 7.69588C10.2666 8.43226 10.8636 9.02921 11.5999 9.02921Z'
					fill={fill}
				/>
				<path
					d='M12.7099 11.3488V15.7435C12.7099 16.0377 12.593 16.3199 12.385 16.5279C12.1769 16.736 11.8948 16.8528 11.6005 16.8528C11.3063 16.8528 11.0242 16.736 10.8161 16.5279C10.6081 16.3199 10.4912 16.0377 10.4912 15.7435V11.3488C10.4912 11.0546 10.6081 10.7725 10.8161 10.5644C11.0242 10.3564 11.3063 10.2395 11.6005 10.2395C11.8948 10.2395 12.1769 10.3564 12.385 10.5644C12.593 10.7725 12.7099 11.0546 12.7099 11.3488Z'
					fill={fill}
				/>
				<path
					d='M11.6 2C6.29824 2 2 6.29824 2 11.6C2 16.9018 6.29824 21.2 11.6 21.2C16.9018 21.2 21.2 16.9018 21.2 11.6C21.2 6.29824 16.9018 2 11.6 2ZM17.1812 17.1812C16.4506 17.9245 15.58 18.5156 14.6196 18.9206C13.6593 19.3256 12.6283 19.5364 11.5861 19.5408C10.5438 19.5453 9.51106 19.3433 8.54732 18.9465C7.58357 18.5497 6.70795 17.966 5.97098 17.229C5.23402 16.4921 4.65029 15.6164 4.2535 14.6527C3.85671 13.6889 3.65471 12.6562 3.65915 11.6139C3.6636 10.5717 3.8744 9.5407 4.27939 8.58037C4.68438 7.62005 5.27555 6.74943 6.01877 6.01877C6.74943 5.27555 7.62005 4.68438 8.58037 4.27939C9.5407 3.8744 10.5717 3.6636 11.6139 3.65915C12.6562 3.65471 13.6889 3.85671 14.6527 4.2535C15.6164 4.65029 16.4921 5.23402 17.229 5.97098C17.966 6.70795 18.5497 7.58357 18.9465 8.54732C19.3433 9.51106 19.5453 10.5438 19.5408 11.5861C19.5364 12.6283 19.3256 13.6593 18.9206 14.6196C18.5156 15.58 17.9245 16.4506 17.1812 17.1812Z'
					fill={fill}
				/>
				<path
					d='M11.6 2C6.29824 2 2 6.29824 2 11.6C2 16.9018 6.29824 21.2 11.6 21.2C16.9018 21.2 21.2 16.9018 21.2 11.6C21.2 6.29824 16.9018 2 11.6 2ZM17.1812 17.1812C16.4506 17.9245 15.58 18.5156 14.6196 18.9206C13.6593 19.3256 12.6283 19.5364 11.5861 19.5408C10.5438 19.5453 9.51106 19.3433 8.54732 18.9465C7.58357 18.5497 6.70795 17.966 5.97098 17.229C5.23402 16.4921 4.65029 15.6164 4.2535 14.6527C3.85671 13.6889 3.65471 12.6562 3.65915 11.6139C3.6636 10.5717 3.8744 9.5407 4.27939 8.58037C4.68438 7.62005 5.27555 6.74943 6.01877 6.01877C6.74943 5.27555 7.62005 4.68438 8.58037 4.27939C9.5407 3.8744 10.5717 3.6636 11.6139 3.65915C12.6562 3.65471 13.6889 3.85671 14.6527 4.2535C15.6164 4.65029 16.4921 5.23402 17.229 5.97098C17.966 6.70795 18.5497 7.58357 18.9465 8.54732C19.3433 9.51106 19.5453 10.5438 19.5408 11.5861C19.5364 12.6283 19.3256 13.6593 18.9206 14.6196C18.5156 15.58 17.9245 16.4506 17.1812 17.1812Z'
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id='clip0_1903_23181'>
					<rect width='20' height='20' fill='white' transform='translate(2 2)' />
				</clipPath>
			</defs>
		</svg>
	);
}

export default memo(InfoIcon);
