import { COLORS } from 'theme';


export const monthOptions = {
	responsive: true,
	maintainAspectRatio: false,
	elements: {
		line: {
			tension: 0.2,
			borderWidth: 2,
		},
		point: {
			borderWidth: 0,
			radius: (ctx) => {
				const datasetLength = ctx.chart.data.datasets[ctx.datasetIndex].data.length;
				const isLastDot = datasetLength - 1 === ctx.index;
				const isJobClosed = datasetLength < 31;

				return isLastDot && isJobClosed ? 7 : 1;
			},
		},
	},
	plugins: {
		monthApplicationLegend: {
			containerID: 'application-chart-container-1',
		},
		legend: {
			display: false,
		},
		tooltip: {
			callbacks: {
				label: function (context) {
					const thereIsNewApplication =
						context.dataIndex && context.dataset.data[context.dataIndex - 1] < context.raw;
					const mainMessage = `total application per month: ${context.raw}`;

					return thereIsNewApplication
						? `${mainMessage}, new applications: ${
							context.raw - context.dataset.data[context.dataIndex - 1]
						  }`
						: mainMessage;
				},
			},
		},
		title: {
			display: false,
		},
	},
	scales: {
		x: {
			grid: {
				display: false,
			},
			ticks: {
				color: COLORS.darkGray1,
				padding: 0,
				backdropPadding: 0,
				callback: function (val, index) {
					return index % 3 === 0 ? this.getLabelForValue(val) : '';
				},
			},
		},
		y: {
			position: 'right',
			ticks: {
				color: COLORS.darkGray1,
			},
		},
	},
};


export const yearOptions = onclickHandler => ({
	responsive: true,
	maintainAspectRatio: false,
	// barThickness: 4,
	categoryPercentage: 0.8,
	barPercentage: 0.6,

	onClick: (e) => {
		onclickHandler(e);
	},

	elements: {
		line: {
			tension: 0.2,
			borderWidth: 2,
		},
		point: {
			borderWidth: 0,
		},
	},
	plugins: {
		yearApplicationLegend: {
			containerID: 'application-chart-container-2',
		},
		legend: {
			display: false,
		},
		tooltip: {
			callbacks: {
				label: function (context) {
					const mainMessage = `${context?.dataset?.label}: ${context.raw}`;

					return mainMessage;
				},
			},
		},
		title: {
			display: false,
		},
	},
	scales: {
		x: {
			grid: {
				display: false,
			},
			ticks: {
				color: COLORS.darkGray1,
				padding: 0,
				backdropPadding: 0,
			},
		},
		y: {
			position: 'right',
			ticks: {
				color: COLORS.darkGray1,
			},
		},
	}
});

