import { PostJobTypeIdsEnum, PostJobTypeValuesEnum } from 'modules/Common/types';

export const JobClasses = [
	{
		id: PostJobTypeIdsEnum.FreeJob,
		value: PostJobTypeIdsEnum.FreeJob,
		name: PostJobTypeValuesEnum.FreeJob,
	},
	{
		id: PostJobTypeIdsEnum.PaidJob,
		value: PostJobTypeIdsEnum.PaidJob,
		name: PostJobTypeValuesEnum.PaidJob,
	},
	{
		id: PostJobTypeIdsEnum.PendingBoost,
		value: PostJobTypeIdsEnum.PendingBoost,
		name: PostJobTypeValuesEnum.PendingBoost,
	},
];

export const JobUpgradesData = [
	{
		id: 1,
		name: 'Hand Filtered CVs',
		value: 'handFilteredCVsUpgrade',
	},
	{
		id: 2,
		name: 'The Guardian',
		value: 'guardianJobsUpgrade',
	},
	{
		id: 3,
		name: 'Indeed',
		value: 'indeedUpgrade',
	},
];

export const UpgradesFields = ['handFilteredCVsUpgrade', 'guardianJobsUpgrade', 'indeedUpgrade'];

export const getApplicationFormUrlThankYouMessage = () =>`
	Dear [Candidate Name] \n
	Thank you for applying for the [Job Title] position at [Company Name]. We appreciate your interest in joining our team. In order to proceed with your application, please follow the below link and complete the application form. [URL] \n
	Should you have any questions, please feel free to reach out to us.
`;

export const getApplicationFormAttachmentThankYouMessage = () => `
	Dear [Candidate Name] \n
	Thank you for applying for the [Job Title] position at [Company Name], we appreciate your interest in joining our team. In order to move forward with your application, please fill out the attached form and return it to us at *email address* \n
	Should you have any questions, please feel free to reach out to us.
`;

export const AllowedJobFields = [
	'id',
	'title',
	'jobType',
	'location',
	'salary',
	'experienceLevel',
	'educationType',
	'employmentType',
	'jobIndustry',
	'jobDescription',
	'applicationMethod',
	'cvAndCoverLetter',
	'cvAndCoverLetterEmail',
	'applicationEmail',
	'applicationForm',
	'applicationFormUrl',
	'jobUpgrades',
	'assessmentQuestions',
];
