import { IOption } from 'types';

export interface IQuestionnaires {
	id: number;
	versionId?: number;
	title: string;
	created: string;
	numberOfQuestions: number;
	client?: string;
	latestSent?: boolean;
}

export interface IQuestionnairesPaginated {
	data: IQuestionnaires[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
}

export interface IQuestion {
	id?: number;
	questionText: string;
	duration: number | IOption;
	description: string;
}

export type MediaPermissionType = {
	value: boolean;
	error: {
		message: string;
	};
};

export type WellcomeInfoType = {
	backgroundImage: string;
	client: string;
	logo: string;
	expiredOrCanceled?: boolean;
};

export enum VideoMimeTypesEnum {
	VideoWebm = 'video/webm',
	VideoMp4 = 'video/mp4',
}

export enum VideoExtensionsEnum {
	Mp4 = 'mp4',
	Webm = 'webm',
}

export type QuestionnariesRowDetailsType = {
	questions: {
		id: number;
		duration: {
			id: number;
			name: string;
			value: number;
		};
		orderInQuestionnaire: number;
		wasAnswered: boolean;
	}[];
};

export type CurrentQuestionType = {
	id: number;
	duration: {
		id: number;
		name: string;
		value: number;
	};
	description: string;
	orderInQuestionnaire: number;
	questionText: string;
	wasAnswered: boolean;
};

export type SendCandidateQuestionnaireType = (
	params: { candidateIds?: number[] | string[] | string | number; questionnaireId?: number },
	jobId: number,
	callback?: (ids?: number[]) => void,
) => void;

export interface IQuestionnaireValues {
	id?: number;
	title: string;
	versionId: number;
	questions: IQuestion[];
	restrictedUserIds: number[];
}
