
import { all, fork } from 'redux-saga/effects';

import { store } from 'store';

import { getBackofficeDucks } from '../../ducks/backOfficeDucks';


function* getSagaToInject(saga) {
	yield all([fork(saga)]);
}

export const initializeClientDucks = (atsClientId: number, shouldBeInjected:boolean) => {
	const ducksToInject = getBackofficeDucks({
		prefix: `backoffice-${atsClientId}`,
		root: `backofficeReducer-${atsClientId}`,
	});

	if(shouldBeInjected) {
		const sagaToInject = () => getSagaToInject(ducksToInject.backOfficeSaga);
		store.injectReducer(`backofficeReducer-${atsClientId}`, ducksToInject.reducer);
		store.runSaga(sagaToInject);
	}

	return ducksToInject;
};
