import styled from 'styled-components/macro';

export namespace Styled {
	export const Head = styled.section`
		display: flex;
		justify-content: space-between;
	`;

	export const Title = styled.h2``;

	export const Content = styled.section`
		position: relative;

		.ant-tabs .ant-tabs-nav {
			text-transform: uppercase;

			&:before {
				display: none;
			}
		}
	`;

	export const TableBox = styled.div``;

	export const ATSClientCredits = styled.div`
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 32px;
	`;

	export const ATSCreateCredits = styled.div`
		padding-left: 24px;

		button {
			max-width: 100%;
			width: 140px;
			height: 48px;
		}
	`;

	export const ButtonBox = styled.div`
		position: absolute;
		right: 0;
		top: 4px;
		z-index: 1;
	`;
}
