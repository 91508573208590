import styled from 'styled-components/macro';

export namespace Styled {
	export const Form = styled.div`
		width: 100%;

		form {
			display: flex;
			flex-direction: column;
			gap: 24px;
		}
	`;

	export const MessageInfo = styled.div`
		p {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	`;
}
