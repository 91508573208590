import styled from 'styled-components/macro';

import { COLORS } from 'theme';

type JUCreditProps = {
	active: boolean;
};

type JULogoImageProps = {
	logoUrl?: string;
	logoHeight?: string;
};

export namespace Styled {
	export const JU = styled.div``;
	export const TextHint = styled.p`
		font-size: 16px;
	`;
	export const JUContent = styled.div`
		padding-top: 16px;
		display: grid;
		row-gap: 16px;
	`;
	export const JUCredit = styled.div<JUCreditProps>`
		cursor: pointer;
		padding: 16px;
		border-radius: 6px;
		border: 1px solid ${COLORS.lightGray2};
		box-shadow: 0px 4px 12px 0px rgba(99, 111, 122, 0.16);
		display: flex;
		justify-content: space-between;

		${({ active }) => active && `border-color: ${COLORS.blue};`};
	`;
	export const JUCreditTitle = styled.div`
		color: ${COLORS.black};
		font-size: 24px;
		font-weight: 600;
		line-height: 32px;
		padding-bottom: 8px;
	`;
	export const JUCreditDesc = styled.div`
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	`;
	export const JUCreditCount = styled.span`
		border-radius: 2px;
		font-weight: 600;
		background: rgba(153, 167, 255, 0.2);
		color: ${COLORS.blue};
		padding: 0 8px;
	`;
	export const JUCreditLink = styled.span`
		text-decoration-line: underline;
		color: ${COLORS.blue};
	`;
	export const JUCreditLogoWrapper = styled.div`
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
		height: 68px;
		width: 177px;
		background: ${COLORS.lightGray3};
	`;
	export const JUCreditLogo = styled.div<JULogoImageProps>`
		${({ logoUrl }) => `background: url(${logoUrl}) no-repeat;`};
		${({ logoHeight }) => `height: ${logoHeight};`};
		width: 145px;
	`;
}
