import styled from 'styled-components/macro';

type FieldStyleProps = {
	minRaws: number;
};

const LINE_HEIGHT = 22;

export namespace Styled {
	export const Root = styled.div<FieldStyleProps>`
		.wrapper-class {
		}

		.editor-class {
			margin: 0;
			padding: 4px 11px;
			font-size: 14px;
			color: ${({ theme: { colors } }) => colors.black};
			background-image: none;
			background-color: ${({ theme: { colors } }) => colors.white};
			border: 1px solid ${({ theme: { colors } }) => colors.neutral5};
			border-radius: 6px;
			transition: all 0.2s ease;

			&:hover,
			&:active,
			&:focus-within,
			&:focus {
				border-color: ${({ theme: { colors } }) => colors.inputColor};
				box-shadow: ${({ theme: { colors } }) => colors.elevationAntInputShadow};
				border-inline-end-width: 1px;
				outline: 0;
			}

			.public-DraftEditor-content {
				min-height: ${({ minRaws }) => `${LINE_HEIGHT * minRaws}px`};
			}

			.public-DraftStyleDefault-block {
				margin: 0;
				line-height: ${LINE_HEIGHT}px;
			}

			.public-DraftEditorPlaceholder-root {
				color: ${({ theme: { colors } }) => colors.gray};
			}

			.public-DraftStyleDefault-orderedListItem:before {
				line-height: 1.5;
			}
		}

		.toolbar-class {
			display: flex;
			margin: 8px 4px;
			padding: 6px 2px 0;
			font-size: 14px;
			border: none;

			.editor-item {
				min-width: 36px;
				min-height: 36px;
				line-height: 22px;

				.rdw-option-wrapper,
				.rdw-image-wrapper {
					padding: 0;
					min-width: 36px;
					min-height: 36px;
					line-height: 36px;
					border: 1px solid ${({ theme: { colors } }) => colors.lightGray2};
					transition: border 0.2s ease;
					gap: 8px;
				}
			}

			.rdw-link-modal {
				height: auto;
			}

			a {
				color: ${({ theme: { colors } }) => colors.black};
			}
		}

		.rdw-image-alignment {
			display: inline !important;
		}

		.rdw-image-center {
			// to set alignment left by default, can be deleted after fix the issue https://github.com/jpuri/react-draft-wysiwyg/issues/1233
			display: block !important;
		}
	`;
}
