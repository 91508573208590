import styled from 'styled-components/macro';

import COLORS from 'common/colors';

interface PriceProps {
	inputWidth: string;
}

export namespace Styled {
	export const BundleTotal = styled.div`
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: ${COLORS.darkGray2};
		text-align: end;
	`;

	export const PriceBundleWrapper = styled.div<PriceProps>`
		padding-bottom: 8px;
		width: ${({ inputWidth }) => (inputWidth === '100%' ? '100%' : 'inherit')};
		margin-right: ${({ inputWidth }) => (inputWidth === '95px' ? '40px' : '16px')};
	`;

	export const PriceBundle = styled.div<PriceProps>`
		display: flex;
		align-items: center;
		width: 100%;

		input {
			width: ${({ inputWidth }) => inputWidth};
		}
	`;

	export const PriceBundleCounter = styled.div`
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		padding-right: 8px;
		color: ${COLORS.darkGray2};
	`;

	export const FieldError = styled.div`
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: ${COLORS.red};
		margin-top: 8px;
	`;
}
