import React, { FC, ReactNode } from 'react';
import { useDrag } from 'react-dnd';

type KanbanItemProps = {
	id: number;
	children: ReactNode | ReactNode[];
};

const KanbanItem: FC<KanbanItemProps> = ({ id, children }) => {
	const [{ opacity }, dragRef] = useDrag({
		type: 'card',
		item: { id },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
			opacity: monitor.isDragging() ? 0.5 : 1,
		}),
	});

	return (
		<div ref={dragRef} style={{ opacity }}>
			{children}
		</div>
	);
};

export default KanbanItem;
