import { Key } from 'react';

import { ColumnsType } from 'antd/es/table';

import { ICandidateAttachments, CandidateStatusType } from 'modules/Common/types';

export enum CandidatesStatusEnum {
	NewCandidate = 'NewCandidate',
	Potential = 'Potential',
	Interviews = 'Interviews',
	Offers = 'Offers',
	Hired = 'Hired',
	Rejected = 'Rejected',
}

export enum CandidatesStatusValuesEnum {
	NewCandidate = 'NEW',
	Potential = 'POTENTIAL',
	Interviews = 'INTERVIEWS',
	Offers = 'OFFERS',
	Hired = 'HIRED',
	Rejected = 'REJECTED',
}

export enum QuestionnaireStatusEnum {
	Send = 'SEND',
	Sent = 'SENT',
	Started = 'STARTED',
	Review = 'REVIEW',
	Expired = 'EXPIRED',
	Reviewed = 'REVIEWED',
	Failed = 'FAILED_TO_SEND',
	Finished = 'FINISHED',
}

export type VideoQuestionnaireInfoType = {
	videoQuestionnaireStatus: QuestionnaireStatusEnum;
	restricted: boolean;
};

export type DataTableType = {
	key?: Key;
	id: number;
	name: string;
	rank: number;
	questionnaireType: string;
	candidateState: {
		value: string;
		name?: string;
		updateCandidateStatus: CandidateStatusType;
	};
	candidateAttachments?: ICandidateAttachments[];
	job: number;
	candidate: number;
	videoQuestionnaireInfo: VideoQuestionnaireInfoType | null;
	isNew: boolean;
};

export type TableColumnsType = ColumnsType<DataTableType>;

export const enum CandidateTabsTitlesEnum {
	AllTab = '1',
	NewCandidatesTab = '2',
	Potential = '3',
	Interviews = '4',
	Offered = '5',
	Hired = '6',
	Rejected = '7',
	CandidatesDatabase = '8',
}
