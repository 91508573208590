import type { CSSProperties, FC, ReactNode } from 'react';

import Box from 'components/Box';

import { Styled } from './FormBlock.styled';

export type FormBlockProps = {
	children: ReactNode | ReactNode[];
	title?: string;
	style?: CSSProperties;
};

export const FormBlock: FC<FormBlockProps> = ({ children, title, style }) => (
	<Box style={style}>
		<>{title && <Styled.FormBlockTitle>{title}</Styled.FormBlockTitle>}</>
		<>{children}</>
	</Box>
);
