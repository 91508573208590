import { type FC, useEffect, useState } from 'react';

import { Document, Image, Page, Text, View, Link } from '@react-pdf/renderer';

import { LOGO_CONNECT_BIG } from 'modules/Common/constants';
import {
	IContractValues,
	IInvoicesDetailedValues,
	InvoicesTypesValuesEnum,
	ReceiptTypesEnum,
} from 'modules/Common/types';
import { getContractTableData } from 'modules/Common/utils/contracts';
import { getSortedUnitForMonths } from 'modules/Common/utils/invoices';
import { CurrencyType, DataFormatEnum, GenericType } from 'types';
import { getCounter, getCurrencySymbolByName, getDateFormat } from 'utils/helpers';

import { ITableFilteredData } from '../ContractView/ContractView.types';

import EarthSvg from './EarthSvg';
import EmailSvg from './EmailSvg';
import { styles } from './FinancePdf.styles';
import PhoneSvg from './PhoneSvg';
import { tableDataTemplate } from './tableDataTemplate';

interface FinancePDFProps {
	type?: string;
	contract: IContractValues & IInvoicesDetailedValues;
	creditsFields: GenericType[];
}

const contractDetailsItem = {
	[ReceiptTypesEnum.Contract]: 'Contract',
	[ReceiptTypesEnum.Invoice]: 'Invoice',
};

const FinancePDF: FC<FinancePDFProps> = ({
	contract,
	creditsFields,
	type = ReceiptTypesEnum.Contract,
}) => {
	const [data, setContractData] = useState<ITableFilteredData[] | []>([]);
	const isInvoice = type === ReceiptTypesEnum.Invoice;
	const isSubscriptionInvoice = [
		InvoicesTypesValuesEnum.PremiumSubscritionManual,
		InvoicesTypesValuesEnum.PremiumSubscrition,
	].includes(contract?.invoiceType?.value);
	const currency = getCurrencySymbolByName(contract?.currency) || CurrencyType.PoundSterling;
	const showVAT = currency === CurrencyType.PoundSterling;

	useEffect(() => {
		if (creditsFields && contract) {
			const isSmsORInterviewInvoice = [
				InvoicesTypesValuesEnum.SmsPurchase,
				InvoicesTypesValuesEnum.InterviewPurchase,
			].includes(contract?.invoiceType?.value);
			let unitFields = [] as ITableFilteredData[];

			if (isSmsORInterviewInvoice) {
				unitFields = getSortedUnitForMonths(contract, false) || [];
			}
			const contactsTable = isSubscriptionInvoice
				? [
					{
						key: 0,
						amount: 1,
						name: `${
							contract?.subscriptionPlanType?.name
								? contract?.subscriptionPlanType?.name === 'MONTHLY'
									? 'Monthly'
									: 'Annual'
								: ''
						} Subscription`,
						unitPrice: contract?.totalExVat,
						totalPrice: contract?.totalExVat,
					},
				  ]
				: [...getContractTableData(creditsFields, contract), ...unitFields];

			if (contactsTable?.length) {
				setContractData(contactsTable);
			}
		}
	}, [creditsFields, contract]);

	const isPONumberShown =
		contract?.invoiceType?.value !== InvoicesTypesValuesEnum.CreditsPurchase &&
		!isSubscriptionInvoice &&
		!!contract?.poNumber &&
		contract?.poNumber !== 'n/a';
	const isCreditsPurchaseInvoice =
		contract?.invoiceType?.value === InvoicesTypesValuesEnum.CreditsPurchase;

	const bluetownDetails = contract?.bluetownDetails || contract?.blueTownDetails;
	const paymentTermDate = getCounter(contract?.paymentTerm, contract?.paymentTermTimeUnit);
	const regularTotalPrice = contract?.totalPrice ? Number(contract?.totalPrice) : 0;
	const subsInvoiceTotalPrice = contract?.premiumPrice;
	const totalPrice = isSubscriptionInvoice ? subsInvoiceTotalPrice : regularTotalPrice;
	const showPayWithinLabel =
		paymentTermDate &&
		!isSubscriptionInvoice &&
		!isCreditsPurchaseInvoice &&
		contract?.invoiceStatus?.name !== 'Paid';
	const documentEndDate = isInvoice ? contract?.creditsExpDay : contract.endDate;
	const showCreditsWillBeValid =
		contract.startDate &&
		documentEndDate &&
		(contract?.invoiceType?.value === InvoicesTypesValuesEnum.CreditsPurchase ||
			contract?.invoiceType?.value === InvoicesTypesValuesEnum.ContractAccept ||
			contract?.invoiceType?.value === InvoicesTypesValuesEnum.Backoffice ||
			!!contract?.contractState);

	const sectionStyles = isInvoice
		? { ...styles.section, marginTop: 10, marginBottom: 6 }
		: styles.section;
	const infoItemStyles = isInvoice ? { ...styles.infoItem, marginBottom: 10 } : styles.infoItem;

	return (
		<Document>
			<Page size='A4' style={styles.page}>
				<View>
					<View style={sectionStyles}>
						<View style={styles.headerWrapper}>
							<View style={styles.headerLogo}>
								<Image src={LOGO_CONNECT_BIG} style={{ width: '129px', height: '30px' }} />
							</View>
							<View style={styles.headerTitle}>
								<Text>{type}</Text>
							</View>
						</View>
					</View>
					<View style={sectionStyles}>
						<View style={styles.contractDetails}>
							<View style={styles.contractDetailsColumn}>
								<View style={styles.contractDetailsItem}>
									<Text>{type && `${contractDetailsItem[type as ReceiptTypesEnum]} To`}</Text>
								</View>
								<View style={{ ...styles.contractDetailsItem, ...styles.contractDetailsItemHigh }}>
									<Text>{contract.clientName || contract.name}</Text>
								</View>
								<View style={styles.contractDetailsItem}>
									<Text>
										{contract.clientOfficeAddress ||
											contract.clientOffice ||
											contract.clientAddress}
									</Text>
								</View>
								{isPONumberShown && (
									<View style={{ ...styles.poNumber, ...styles.contractDetailsItem }}>
										<Text>PO Number: </Text>
										<Text>{contract.poNumber}</Text>
									</View>
								)}
							</View>
							<View style={styles.contractDetailsColumn}>
								<View style={styles.contractDetailsItem}></View>
								<View>
									<View style={styles.contractDetailsItem}>
										<Text>{type && `${contractDetailsItem[type as ReceiptTypesEnum]} No`}</Text>
									</View>
									<View
										style={{ ...styles.contractDetailsItem, ...styles.contractDetailsItemHigh }}
									>
										<Text>{contract.btoReference}</Text>
									</View>
								</View>
								<View>
									<View style={styles.contractDetailsItem}>
										<Text>Date</Text>
									</View>
									<View
										style={{ ...styles.contractDetailsItem, ...styles.contractDetailsItemHigh }}
									>
										<Text>
											{getDateFormat(
												contract.startDate || contract.dateCreation || contract.dateOfPayment,
												DataFormatEnum.Full,
											)}
										</Text>
									</View>
								</View>
								{showPayWithinLabel && <Text>{`Please Pay within ${paymentTermDate}`}</Text>}
							</View>
						</View>
					</View>
					{!!data.length && (
						<View style={sectionStyles}>
							<View style={styles.contractCalculation}>
								<View style={styles.contractCalculationTable}>
									<View style={styles.contractCalculationTableHeader}>
										{tableDataTemplate.map((dt, idx) => (
											<View
												key={dt.id}
												style={{
													...styles.contractCalculationTableHeaderItem,
													width: dt.width,
													textAlign: idx ? 'center' : 'left',
												}}
											>
												<Text>{dt.label}</Text>
											</View>
										))}
									</View>
									{data?.map((contractData, idx) => (
										<View key={idx} style={styles.contractCalculationTableRow}>
											<View
												style={{
													...styles.contractCalculationTableCell,
													width: tableDataTemplate[0].width,
													textAlign: 'left',
												}}
											>
												<Text>{contractData?.name}</Text>
											</View>
											<View
												style={{
													...styles.contractCalculationTableCell,
													width: tableDataTemplate[1].width,
												}}
											>
												<Text>{contractData?.amount}</Text>
											</View>
											<View
												style={{
													...styles.contractCalculationTableCell,
													width: tableDataTemplate[2].width,
												}}
											>
												<Text>{`${currency}${(Number(contractData?.unitPrice) ?? 0).toFixed(
													2,
												)}`}</Text>
											</View>
											<View
												style={{
													...styles.contractCalculationTableCell,
													width: tableDataTemplate[3].width,
													fontWeight: 'bold',
												}}
											>
												<Text>
													{`${currency}${(Number(contractData?.totalPrice) ?? 0).toFixed(2)}`}
												</Text>
											</View>
										</View>
									))}

									{contract?.discountAmount && (
										<View
											style={{ ...styles.contractCalculationTableRow, backgroundColor: '#F8F8F8' }}
										>
											<View style={styles.contractCalculationTotalCell}>
												<Text>Subscription discount: </Text>
											</View>
											<View
												style={{
													...styles.contractCalculationTotalCell,
													width: tableDataTemplate[3].width,
												}}
											>
												<Text>{`- ${currency}${Number(contract?.discountAmount).toFixed(2)}`}</Text>
											</View>
										</View>
									)}
									{contract?.totalExVat && (
										<View
											style={{ ...styles.contractCalculationTableRow, backgroundColor: '#F8F8F8' }}
										>
											<View style={styles.contractCalculationTotalCell}>
												<Text>Total Ex VAT:</Text>
											</View>
											<View
												style={{
													...styles.contractCalculationTotalCell,
													width: tableDataTemplate[3].width,
												}}
											>
												<Text>{`${currency}${Number(contract?.totalExVat).toFixed(2)}`}</Text>
											</View>
										</View>
									)}
									{showVAT && (
										<View
											style={{ ...styles.contractCalculationTableRow, backgroundColor: '#F8F8F8' }}
										>
											<View style={styles.contractCalculationTotalCell}>
												<Text>VAT (20%):</Text>
											</View>
											<View
												style={{
													...styles.contractCalculationTotalCell,
													width: tableDataTemplate[3].width,
												}}
											>
												<Text>{`${currency}${Number(contract?.vatPrice).toFixed(2)}`}</Text>
											</View>
										</View>
									)}
									<View style={styles.contractTotalTableWrapper}>
										<View style={styles.contractTotalTableInner}>
											<Text>Total:</Text>
											<Text>{`${currency}${totalPrice?.toFixed(2)}`}</Text>
										</View>
									</View>
								</View>
							</View>
						</View>
					)}
				</View>

				<View>
					<View style={styles.infoSection}>
						{showCreditsWillBeValid && (
							<View style={infoItemStyles}>
								<Text>{`Credits will be valid from ${getDateFormat(
									contract.startDate,
									DataFormatEnum.Full,
								)} through ${getDateFormat(documentEndDate, DataFormatEnum.Full)}`}</Text>
							</View>
						)}
						{contract.accepted && contract.acceptedDate && contract.acceptorFirstName && (
							<View style={infoItemStyles}>
								<Text>
									{`Contract accepted by ${contract.acceptorFirstName} ${
										contract.acceptorLastName
									} on behalf of ${contract.clientName} on ${getDateFormat(
										contract.acceptedDate,
										DataFormatEnum.FullHoursMinutes,
									)}`}
								</Text>
							</View>
						)}
						{bluetownDetails && (
							<View style={infoItemStyles}>
								<Text>{bluetownDetails}</Text>
							</View>
						)}
					</View>
					{isInvoice && !isSubscriptionInvoice && (
						<View style={styles.invoiceSection}>
							<View style={styles.invoiceBlock}>
								<View style={styles.invoiceBlockHeader}>
									<Text>Bank Transfer</Text>
								</View>
								<View style={styles.invoiceBlockContent}>
									<View style={styles.invoiceBlockContentItem}>
										<Text>Please include the invoice number as a reference for the payment.</Text>
									</View>
									<View style={styles.invoiceBlockContentItem}>
										<Text>Bank: Barclays Bank PLC</Text>
									</View>
									<View style={styles.invoiceBlockContentItem}>
										<Text>Invoice Number: {contract.btoReference}</Text>
									</View>
									<View style={styles.invoiceBlockContentItem}>
										<Text>Account Name: Bluetownonline limited</Text>
									</View>
									<View style={styles.invoiceBlockContentItem}>
										<Text>Account Number: 13021718</Text>
									</View>
									<View style={styles.invoiceBlockContentItem}>
										<Text>Sort Code: 204895</Text>
									</View>
								</View>
							</View>
							<View style={styles.invoiceBlock}>
								<View style={styles.invoiceBlockHeader}>
									<Text>Credit / Debit Card</Text>
								</View>
								<View style={styles.invoiceBlockContent}>
									<View style={styles.invoiceBlockContentItem}>
										<Text>Please follow the below link to pay the invoice.</Text>
									</View>
									<View style={styles.invoiceBlockContentItem}>
										<Link src={contract?.invoiceDetailsUrl || ''}>Pay Online Now</Link>
									</View>
									<View style={styles.invoiceBlockContentItem}>
										<Text>For help contact: support@bluetownonline.co.uk</Text>
									</View>
									<View style={styles.invoiceBlockContentItem}>
										<Text>Phone: 0207 183 6293</Text>
									</View>
								</View>
							</View>
						</View>
					)}
					<View style={styles.footer}>
						<View style={styles.footerItem}>
							<View style={styles.footerItemImg}>
								<PhoneSvg />
							</View>
							<Text>0207 183 6293</Text>
						</View>
						<View style={styles.footerItem}>
							<View style={styles.footerItemImg}>
								<EmailSvg />
							</View>
							<Text>support@bluetownonline.co.uk</Text>
						</View>
						<View style={styles.footerItem}>
							<View style={styles.footerItemImg}>
								<EarthSvg />
							</View>
							<Text>www.bluetownonline.com</Text>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
};

export default FinancePDF;
