import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Main = styled.section`
		h2 {
			margin-bottom: 16px;
		}
	`;

	export const Receivers = styled.div``;

	export const ReceiversHeader = styled.div`
		color: ${COLORS.black};
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		margin-bottom: 8px;
	`;

	export const ReceiversList = styled.div`
		display: flex;
		flex-wrap: wrap;
	`;

	export const ValidationError = styled.div`
		color: ${COLORS.red};
		margin-bottom: 10px;
		font-size: 14px;
	`;

	export const ReceiverBox = styled.div`
		border: 1px solid ${COLORS.lightGray1};
		border-radius: 8px;
		margin-right: 16px;
		margin-bottom: 16px;
		padding: 8px 32px 8px 12px;
		position: relative;
		min-width: 150px;
		&.error {
			border: 1px solid ${COLORS.red};
		}
	`;

	export const ReceiverFirstLine = styled.div`
		display: flex;
	`;

	export const ReceiverSecondLine = styled.div`
		display: flex;
		align-items: center;
	`;

	export const ReceiverName = styled.div`
		font-weight: bold;
		margin-right: 4px;
		font-size: 16px;
	`;

	export const ReceiverHistoryBadge = styled.div`
		display: flex;
		align-items: center;
		background-color: ${COLORS.blue};
		color: ${COLORS.white};
		border-radius: 5px 5px 5px 0px;
		padding: 0 5px;
		position: relative;
		top: -2px;
		font-size: 12px;
		height: 16px;
		font-weight: 500;
		cursor: pointer;
	`;
	export const ReceiverRemoveIcon = styled.div`
		position: absolute;
		right: 6px;
		top: 6px;
		cursor: pointer;
	`;

	export const ReceiverEditIcon = styled.div`
		cursor: pointer;
	`;

	export const ReceiverPhone = styled.div`
		font-size: 16px;
		line-height: 24px;
		margin-right: 8px;
	`;

	export const SmsRemainingChars = styled.p`
		color: ${COLORS.darkGray2};
		margin-top: 8px;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
		margin-bottom: 32px;
		gap: 16px;
	`;

	export const Field = styled.div`
		padding-bottom: 30px;
	`;
}
