import { message, UploadProps } from 'antd';
import type { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';

import backOfficeService from 'api/endpoints/backOffice';

import { FileUploadTypesEnum } from '../types';

const baseUrl = process.env.REACT_APP_API_URL;

interface IProps {
	accept?: string;
	showUploadList?: boolean;
	fileList?: UploadFile[];
	multiple?: boolean;
	fileType?: FileUploadTypesEnum;
	url?: string;
	customRequest?: (options: {
		onSuccess: () => void;
		onError: () => void;
		file: FileList;
		onProgress: () => void;
		fileType?: FileUploadTypesEnum;
	}) => void;
	onRemove?: (uploadFile: UploadFile) => void;
	onDownload?: (uploadFile: UploadFile) => void;
	maxCount?: number | undefined;
}

const createUploaderProps = (
	callback: (url: string, responseData: string, fileList: UploadFile[]) => void,
	isMultiple: boolean,
	maxCount: number | undefined,
	props: IProps = {},
	setFileList?: (fileList: UploadFile[]) => void,
): UploadProps => ({
	name: 'file',
	multiple: isMultiple,
	listType: 'picture',
	maxCount: maxCount,
	customRequest: (options) =>
		backOfficeService.uploadFile({
			...options,
			fileType: props.fileType || FileUploadTypesEnum.Logo,
			url: props.url || null,
		}),
	onChange(payload: UploadChangeParam<UploadFile>) {
		const { status } = payload.file;

		if (setFileList) {
			setFileList(payload.fileList);
		}

		if (status === 'done') {
			const { response } = payload.file;
			callback(baseUrl || '', response?.data, payload.fileList);
			message.success(`${payload.file.name} file uploaded successfully.`);
		} else if (status === 'error') {
			message.error(`${payload.file.name} file upload failed.`);
			console.error('problem with uploading', payload.file, payload.fileList);
		}
	},
	...props,
});

export const getSingleFileUploaderProps = (
	callback: (url: string, responseData: string, fileList: UploadFile[]) => void,
	props: IProps = {},
	setFileList?: (fileList: UploadFile[]) => void,
) => createUploaderProps(callback, false, 1, props, setFileList);

export const getMultipleFileUploaderProps = (
	callback: (url: string, responseData: string, fileList: UploadFile[]) => void,
	props: IProps = {},
	setFileList?: (fileList: UploadFile[]) => void,
) => createUploaderProps(callback, true, 5, props, setFileList);
