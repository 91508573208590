import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';
function ArrowVectorUp({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g id='State=Arrow Up'>
				<path
					id='Vector 4 (Stroke)'
					d='M19.2992 12.4086C18.9191 12.8093 18.2862 12.8261 17.8855 12.4461L12.852 7.67288V18.6543C12.852 19.2066 12.4043 19.6543 11.852 19.6543C11.2998 19.6543 10.852 19.2066 10.852 18.6543V7.74627L6.12794 12.4306C5.73576 12.8194 5.1026 12.8167 4.71373 12.4246C4.32487 12.0324 4.32755 11.3992 4.71972 11.0104L11.1479 4.63634C11.5316 4.25591 12.1481 4.24903 12.5401 4.62082L19.2617 10.9948C19.6624 11.3749 19.6792 12.0078 19.2992 12.4086Z'
					fill={fill}
				/>
			</g>
		</svg>
	);
}

export default memo(ArrowVectorUp);
