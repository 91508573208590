import type { FC, ReactNode } from 'react';

import { S } from './CreditTag.styled';

type CreditTagType = {
	amount: number;
	icon: ReactNode;
};

export const CreditTag: FC<CreditTagType> = ({ amount, icon }) => {
	return (
		<S.Root>
			<S.Icon>{icon}</S.Icon>
			<S.Amount>{amount}</S.Amount>
		</S.Root>
	);
};
