import React, { Key } from 'react';
import { Link } from 'react-router-dom';

import { Button, Popconfirm, Space, Tooltip } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import DeleteIcon from 'components/SVG/DeleteIcon';
import EditIcon from 'components/SVG/EditIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
import Tag from 'modules/Common/components/Tag';
import { HiddenContracts } from 'modules/Common/constants';
import {
	InvoicesStatusEnum,
	InvoicesStatusColors,
	IInvoicesTableValues,
	IInvoicesState,
	InvoiceTypeValuesEnum,
	InvoicesStatusStylesData,
} from 'modules/Common/types';
import { COLORS } from 'theme';
import { IOption, Routes } from 'types';
import { getCurrencySymbolByName, getDateFormat } from 'utils/helpers';

import { Styled } from './Invoices.styled';

type DataTableType = IInvoicesTableValues & {
	key?: Key;
};

export const columns = (
	invoiceStatuses: IInvoicesState[],
	clientContextId: string,
	invoiceSorterTooltipTitle: string,
	handleViewItem: (id: number) => void,
	handleDeleteItem: (id: number) => void,
	handleEditItem: (id: number) => void,
	handleChangeStatus: (invoiceId: number, statusId: number) => void,
): ColumnsDataType => [
	{
		title: 'Client',
		dataIndex: 'clientName',
		sorter: !clientContextId,
		key: 'clientName',
		render: (_: string, { clientName }: Partial<DataTableType>) => (
			<Styled.PageLink
				to={`${Routes.BackOffice}${Routes.Contracts}`}
				onClick={(e) => e.preventDefault()}
			>
				{clientName}
			</Styled.PageLink>
		),
	},
	{
		title: (
			<Tooltip title={invoiceSorterTooltipTitle}>
				<Styled.TooltipTitle>Invoice</Styled.TooltipTitle>
			</Tooltip>
		),
		dataIndex: 'btoReference',
		key: 'btoReference',
		sorter: true,
		showSorterTooltip: false,
		sortDirections: ['descend', 'ascend'],
		render: (_: string, { id, btoReference }: Partial<DataTableType>) => {
			const invoicePath = clientContextId
				? `${Routes.BOClientContext}/${clientContextId}${Routes.InvoicesView}/${id}`
				: `${Routes.BackOffice}${Routes.InvoicesView}/${id}`;

			return (
				<Styled.PageLink to={invoicePath} state={{ invoiceId: id }}>
					<span>{btoReference}</span>
				</Styled.PageLink>
			);
		},
	},
	{
		title: 'Related contract',
		dataIndex: 'contract',
		key: 'contract',
		align: 'center',
		render: (_: string, { contract, invoiceType }: Partial<DataTableType>) =>
			contract &&
			String(contract) !== '-' &&
			!(invoiceType?.value && HiddenContracts.includes(invoiceType.value)) ? (
					<Link
						to={
							clientContextId
								? `${Routes.BOClientContext}/${clientContextId}${Routes.ContractsView}/${contract}`
								: `${Routes.BackOffice}${Routes.ContractsView}/${contract}`
						}
					>
						<Button type='default'>View</Button>
					</Link>
				) : (
					<span>-</span>
				),
	},
	{
		title: 'Receipt',
		dataIndex: 'receipt',
		key: 'receipt',
		align: 'center',
		render: (_: string, { status, id }: Partial<DataTableType>) => {
			const isShown = status === InvoicesStatusEnum.Paid;

			return isShown ? (
				<Button type='default' onClick={() => id && handleViewItem(id)}>
					View
				</Button>
			) : (
				<span>-</span>
			);
		},
	},
	{
		title: 'Value',
		dataIndex: 'value',
		key: 'value',
		align: 'center',
		render: (_: string, { currency, value }: Partial<DataTableType>) =>
			`${getCurrencySymbolByName(currency)}${Number(value)?.toFixed(2)}` ?? 0,
	},
	{
		title: 'Due Date',
		dataIndex: 'startDate',
		key: 'startDate',
		sorter: true,
		align: 'center',
		render: (_: string, { dateDue }: Partial<DataTableType>) => {
			const formattedData = getDateFormat(dateDue) ?? '-';

			return <>{formattedData}</>;
		},
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		sorter: true,
		defaultSortOrder: 'ascend',
		align: 'center',
		render: (_, { status, id: invoiceId, invoiceType }: Partial<DataTableType>) => {
			const isPaid = status === InvoicesStatusEnum.Paid;
			const isSubscriptionBased =
				invoiceType?.value === InvoiceTypeValuesEnum.PremiumSubscritionManual ||
				invoiceType?.value === InvoiceTypeValuesEnum.PremiumSubscrition;
			const showTag = isPaid || isSubscriptionBased;
			const selectedStatusId = invoiceStatuses?.find((item) => {
				return status === InvoicesStatusEnum.PaidManually
					? item.name === InvoicesStatusEnum.Paid
					: item.name === status;
			})?.id;
			const styles = status && InvoicesStatusStylesData[status];

			return (
				<>
					{showTag ? (
						<Tag
							color={String(InvoicesStatusColors[status as InvoicesStatusEnum])}
							style={{ width: '120px' }}
						>
							{InvoicesStatusEnum[status as InvoicesStatusEnum] === 'PaidManually'
								? 'Paid'
								: InvoicesStatusEnum[status as InvoicesStatusEnum]}
						</Tag>
					) : (
						<Styled.Select
							value={selectedStatusId || null}
							styles={styles}
							onChange={(statusId: number) => invoiceId && handleChangeStatus(invoiceId, statusId)}
							placeholder='Select a status'
							label=''
						>
							{invoiceStatuses?.map((option: IOption) => (
								<Styled.Option key={option.id} value={option.id}>
									{option.name}
								</Styled.Option>
							))}
						</Styled.Select>
					)}
				</>
			);
		},
	},
	{
		title: 'Action',
		key: 'action',
		align: 'center',
		width: '54px',
		render: (_: string, { id, status, subscriptionPlanType }: Partial<DataTableType>) => {
			const isDisabled = status === InvoicesStatusEnum.Paid || subscriptionPlanType !== '-';

			return (
				<Space size='middle'>
					<Styled.Button
						buttonType={ButtonTypes.default}
						onClick={() => id && handleEditItem(id)}
						icon={<EditIcon fill={COLORS.black} />}
						disabled={isDisabled}
					/>
					<Popconfirm
						placement='bottomRight'
						title='Are you sure?'
						onConfirm={() => id && handleDeleteItem(id)}
						okText='Yes'
						cancelText='Cancel'
					>
						<Styled.Button
							buttonType={ButtonTypes.default}
							icon={<DeleteIcon fill={COLORS.black} width='14' height='14' />}
						/>
					</Popconfirm>
				</Space>
			);
		},
	},
];
