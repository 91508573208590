import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function QuestionIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8 1C8.55228 1 9 1.44772 9 2V3H15V2C15 1.44772 15.4477 1 16 1C16.5523 1 17 1.44772 17 2V3H18C19.6569 3 21 4.34315 21 6V20C21 21.6569 19.6569 23 18 23H6C4.34315 23 3 21.6569 3 20V6C3 4.34315 4.34315 3 6 3H7V2C7 1.44772 7.44772 1 8 1ZM7 5H6C5.44771 5 5 5.44772 5 6V20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20V6C19 5.44772 18.5523 5 18 5H17C17 5.55228 16.5523 6 16 6C15.4477 6 15 5.55228 15 5H9C9 5.55228 8.55228 6 8 6C7.44772 6 7 5.55228 7 5ZM8.7002 10.7386C8.70036 7.22151 15.2808 6.9946 15.2808 10.6252C15.2808 11.7723 14.6704 12.2769 14.0309 12.8056C13.4686 13.2706 12.8836 13.7542 12.6713 14.7097C12.4444 15.7309 11.0829 15.6174 11.0829 14.4828C11.0829 13.2795 11.7011 12.7827 12.3315 12.2762C12.7115 11.9708 13.096 11.6619 13.352 11.1924C13.8058 10.2848 13.0117 9.37707 12.104 9.37707C11.0351 9.37707 10.7676 10.0392 10.5291 10.6295C10.3373 11.1044 10.1643 11.5328 9.60788 11.5328C9.15407 11.5328 8.7002 11.1924 8.7002 10.7386ZM11.877 18C10.5155 18 10.629 15.9577 11.877 15.9577C13.2385 15.9577 13.2385 18 11.877 18Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(QuestionIcon);
