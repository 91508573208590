import { post } from 'api/axiosInstance';
import { RequestsEnum } from 'types';

export const payForInvoice = async (data) => post(`${RequestsEnum.PayForInvoice}`, data);

export const payForSubsInvoice = async (id) => post(`${RequestsEnum.PayForInvoice}/${id}`);

export const payForCredit = async (data) => post(`${RequestsEnum.PayForCredit}`, data);

export const payForSMS = async (data) => post(`${RequestsEnum.PaySms}`, data);

export const payForInterviews = async (data) => post(`${RequestsEnum.PayInterview}`, data);

const paymentsService = {
	payForInvoice,
	payForCredit,
	payForSMS,
	payForInterviews,
	payForSubsInvoice,
};

export default paymentsService;
