import React, { type FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Tabs } from 'antd';

import Spinner from 'components/Spinner';
import { commonDucks } from 'modules/Common/ducks';
import {
	JobDataType,
	TabsTitleEnum,
	CandidateStatusType,
	QueryParamsSearchType,
} from 'modules/Common/types';
import { GenericType } from 'types';

import CandidatesDatabase from '../CandidatesDatabase';

import { Styled } from './CandidatesApplication.styled';
import { CandidatesStatusEnum, CandidateTabsTitlesEnum } from './CandidatesApplication.types';
import CandidatesApplicationTableListContainer from './CandidatesApplicationTableListContainer';

type ATSJobsListPageProps = {
	clientId: number;
	sortedJobs: {
		[CandidatesStatusEnum.NewCandidate]: JobDataType[];
		[CandidatesStatusEnum.Potential]: JobDataType[];
		[CandidatesStatusEnum.Interviews]: JobDataType[];
		[CandidatesStatusEnum.Offers]: JobDataType[];
		[CandidatesStatusEnum.Hired]: JobDataType[];
		[CandidatesStatusEnum.Rejected]: JobDataType[];
	};
	clientJobs: JobDataType[];
	getClientJobsRequested: () => void;
	saveSearchQueryParams: (params: QueryParamsSearchType | null) => void;
	updateCandidateStatus: CandidateStatusType;
	loading: GenericType;
};

const CandidatesApplication: FC<ATSJobsListPageProps> = ({
	sortedJobs,
	clientJobs,
	getClientJobsRequested,
	saveSearchQueryParams,
	updateCandidateStatus,
	loading,
}) => {
	const location = useLocation();
	const { activeTab } = location.state || {};
	const [tab, setTab] = useState<TabsTitleEnum | string>(
		activeTab || CandidateTabsTitlesEnum.AllTab,
	);

	const candidateLength = clientJobs?.reduce((acc, cur) => (acc += cur?.candidates?.length), 0);

	const handleChangeTabs = (id: TabsTitleEnum | string) => {
		setTab(id);
		saveSearchQueryParams(null);
	};

	useEffect(() => {
		getClientJobsRequested();
	}, []);

	if (loading?.getClientJobsLoad) {
		return <Spinner fixed />;
	}

	const getCandidatesCount = (jobs: JobDataType[]) =>
		jobs?.length
			? jobs.reduce((acc: number, item: JobDataType): number => acc + item.candidates.length, 0)
			: 0;

	return (
		<>
			<Styled.Content>
				<Tabs
					activeKey={tab}
					onChange={handleChangeTabs}
					defaultActiveKey={tab}
					destroyInactiveTabPane
					items={[
						{
							label: <>{`All (${candidateLength})`}</>,
							key: CandidateTabsTitlesEnum.AllTab,
							active: true,
							children: !!candidateLength && (
								<CandidatesApplicationTableListContainer
									jobsData={clientJobs}
									updateCandidateStatus={updateCandidateStatus}
									isCollapsable
								/>
							),
						},
						{
							label: <>CV Database</>,
							key: CandidateTabsTitlesEnum.CandidatesDatabase,
							children: <CandidatesDatabase />,
						},
						{
							label: <>{`New Candidates (${getCandidatesCount(sortedJobs?.NewCandidate)})`}</>,
							key: CandidateTabsTitlesEnum.NewCandidatesTab,
							children: sortedJobs?.NewCandidate?.length && (
								<CandidatesApplicationTableListContainer
									jobsData={sortedJobs?.NewCandidate}
									updateCandidateStatus={updateCandidateStatus}
									isCollapsable
								/>
							),
						},
						{
							label: <>{`Potential (${getCandidatesCount(sortedJobs?.Potential)})`}</>,
							key: CandidateTabsTitlesEnum.Potential,
							children: !!sortedJobs?.Potential?.length && (
								<CandidatesApplicationTableListContainer
									jobsData={sortedJobs?.Potential}
									updateCandidateStatus={updateCandidateStatus}
									isCollapsable
								/>
							),
						},
						{
							label: <>{`Interviews (${getCandidatesCount(sortedJobs?.Interviews)})`}</>,
							key: CandidateTabsTitlesEnum.Interviews,
							children: !!sortedJobs?.Interviews?.length && (
								<CandidatesApplicationTableListContainer
									jobsData={sortedJobs?.Interviews}
									updateCandidateStatus={updateCandidateStatus}
									isCollapsable
								/>
							),
						},
						{
							label: <>{`Offered (${getCandidatesCount(sortedJobs?.Offers)})`}</>,
							key: CandidateTabsTitlesEnum.Offered,
							children: !!sortedJobs?.Offers?.length && (
								<CandidatesApplicationTableListContainer
									jobsData={sortedJobs?.Offers}
									updateCandidateStatus={updateCandidateStatus}
									isCollapsable
								/>
							),
						},
						{
							label: <>{`Hired (${getCandidatesCount(sortedJobs?.Hired)})`}</>,
							key: CandidateTabsTitlesEnum.Hired,
							children: !!sortedJobs?.Hired?.length && (
								<CandidatesApplicationTableListContainer
									jobsData={sortedJobs.Hired}
									updateCandidateStatus={updateCandidateStatus}
									isCollapsable
								/>
							),
						},
						{
							label: <>{`Rejected (${getCandidatesCount(sortedJobs?.Rejected)})`}</>,
							key: CandidateTabsTitlesEnum.Rejected,
							children: !!sortedJobs?.Rejected?.length && (
								<CandidatesApplicationTableListContainer
									jobsData={sortedJobs.Rejected}
									updateCandidateStatus={updateCandidateStatus}
									isCollapsable
								/>
							),
						},
					]}
				/>
			</Styled.Content>
		</>
	);
};

export default connect(
	(state) => ({
		clientJobs: commonDucks.commonSelectors.getClientJobs(state),
		sortedJobs: commonDucks.commonSelectors.getSortedJobsByCandidateStatus(state),
		candidatesByStatus: commonDucks.commonSelectors.getSortedCandidatesByStatus(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		getClientJobsRequested: commonDucks.commonActions.getClientJobsRequested,
		updateCandidateStatus: commonDucks.commonActions.updateApplicationStatusRequested,
		saveSearchQueryParams: commonDucks.commonActions.saveSearchQueryParamsRequested,
	},
)(CandidatesApplication);
