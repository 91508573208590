import styled from 'styled-components/macro';

import { device } from 'theme';

export namespace Styled {
	export const Chart = styled.div`
		width: 50%;
		margin-right: 20px;
		@media ${device.large} {
			width: 80%;
			margin-top: 20px;
			margin-right: 0;
		}
	`;

	export const ChartContainer = styled.div`
		width: 200px;
		margin: 0 auto;
		padding: 20px 25px 20px 0;
	`;

	export const Legend = styled.ul`
		margin-top: 24px;
		li {
			margin-bottom: 12px;
			display: flex;
		}
		.legendBox {
			display: inline-block;
			width: 16px;
			height: 16px;
			margin-right: 12px;
			border-radius: 2px;
		}
		.legendText {
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			margin-right: 16px;
		}
		.legendValue {
			font-weight: bold;
			margin-left: auto;
		}
	`;
}
