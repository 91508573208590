import React, { type FC } from 'react';

import Button from 'components/Button';
import { IButton } from 'components/Button/Button.types';

interface IThemedButtonProps extends IButton {
	context: () => {
		subscriptionPlans: {
			isPremiumPlan: boolean;
		};
		clientBrandingData: {
			highlightColour: string;
		};
	};
}

const ThemedButton: FC<IThemedButtonProps> = ({ context, children, ...props }) => {
	const { subscriptionPlans, clientBrandingData } = context();

	const buttonProps = {
		...(subscriptionPlans?.isPremiumPlan &&
			clientBrandingData?.highlightColour && {
			buttonColor: clientBrandingData.highlightColour,
		}),
		...props,
	};

	return <Button {...buttonProps}>{children}</Button>;
};

export default ThemedButton;
