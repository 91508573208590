import _ from 'lodash';

import { ATS_DEFAULT_COLORS } from 'common/colors';
import { formatInvoiceBtoReference } from 'utils/helpers';
import { handlersDefaultCase } from 'utils/reduxHelpers';
import { phoneNumberIsValid } from 'utils/validators';

import { initialState } from './reducer.initial_state';

export const ATSReducer = (TYPES) => {
	return (state = initialState, action = {}) => {
		const handlers = {
			[TYPES.GET_ATS_USERS.SUCCESS]: () => ({
				...state,
				atsUsers: action.payload,
			}),
			[TYPES.GET_ATS_USERS.FAILED]: () => ({
				...state,
				atsUsers: initialState.atsUsers,
			}),
			[TYPES.GET_ATS_USER_ROLES.SUCCESS]: () => ({
				...state,
				atsUserRoles: action.payload,
			}),
			[TYPES.GET_ATS_USER_ROLES.FAILED]: () => ({
				...state,
				atsUserRoles: initialState.atsUserRoles,
			}),
			[TYPES.GET_ATS_USER_BY_ID.SUCCESS]: () => {
				const role = action.payload.role;
				const userAccessForm = { ...action.payload, role: (role && role?.id) ?? null };

				return {
					...state,
					settingsState: {
						...state.settingsState,
						userAccessForm,
					},
				};
			},
			[TYPES.GET_ATS_USER_BY_ID.FAILED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					userAccessForm: initialState.settingsState.atsUserRoles,
				},
			}),
			[TYPES.GET_CLIENT_APPLICATION_MESSAGES_BY_ID.REQUESTED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					defaultMessagesData: initialState.settingsState.defaultMessagesData,
				},
			}),
			[TYPES.GET_CLIENT_APPLICATION_MESSAGES_BY_ID.SUCCESS]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					defaultMessagesData: action.payload,
				},
			}),
			[TYPES.GET_CLIENT_APPLICATION_MESSAGES_BY_ID.FAILED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					defaultMessagesData: initialState.settingsState.defaultMessagesData,
				},
			}),
			[TYPES.UPDATE_CLIENT_APPLICATION_MESSAGES.SUCCESS]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					defaultMessagesData: action.payload,
				},
			}),
			[TYPES.UPDATE_CLIENT_APPLICATION_MESSAGES.FAILED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					defaultMessagesData: initialState.settingsState.defaultMessagesData,
				},
			}),
			[TYPES.GET_CLIENT_APPLICATION_NOTIFICATION.REQUESTED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					clientApplicationNotification: initialState.settingsState.clientApplicationNotification,
				},
			}),
			[TYPES.GET_CLIENT_APPLICATION_NOTIFICATION.SUCCESS]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					clientApplicationNotification: action.payload,
				},
			}),
			[TYPES.GET_CLIENT_APPLICATION_NOTIFICATION.FAILED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					clientApplicationNotification: initialState.settingsState.clientApplicationNotification,
				},
			}),
			[TYPES.GET_CLIENT_SOCIAL_NETWORKS.REQUESTED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					socialNetworks: initialState.settingsState.socialNetworks,
				},
			}),
			[TYPES.GET_CLIENT_SOCIAL_NETWORKS.SUCCESS]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					socialNetworks: action.payload,
				},
			}),
			[TYPES.GET_CLIENT_EMAIL_SIGNATURE.REQUESTED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					emailSignatureData: initialState.settingsState.emailSignatureData,
				},
			}),
			[TYPES.GET_CLIENT_EMAIL_SIGNATURE.SUCCESS]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					emailSignatureData: action.payload,
				},
			}),
			[TYPES.GET_CLIENT_EMAIL_SIGNATURE.FAILED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					emailSignatureData: initialState.settingsState.emailSignatureData,
				},
			}),
			[TYPES.GET_CLIENT_AUTO_POP.REQUESTED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					autoPopData: initialState.settingsState.autoPopData,
				},
			}),
			[TYPES.GET_CLIENT_AUTO_POP.SUCCESS]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					autoPopData: action.payload,
				},
			}),
			[TYPES.GET_CLIENT_2FA.REQUESTED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					twoFA: initialState.settingsState.twoFA,
				},
			}),
			[TYPES.GET_CLIENT_2FA.SUCCESS]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					twoFA: action.payload,
				},
			}),
			[TYPES.GET_CLIENT_2FA.FAILED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					twoFA: initialState.settingsState.twoFA,
				},
			}),
			[TYPES.GET_CLIENT_SMS_NOTIFICATIONS.REQUESTED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					smsNotificationsData: initialState.settingsState.smsNotificationsData,
				},
			}),
			[TYPES.GET_CLIENT_SMS_NOTIFICATIONS.SUCCESS]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					smsNotificationsData: action.payload,
				},
			}),
			[TYPES.GET_CLIENT_SMS_NOTIFICATIONS.FAILED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					smsNotificationsData: initialState.settingsState.smsNotificationsData,
				},
			}),
			[TYPES.GET_USER_EMAIL_NOTIFICATIONS.REQUESTED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					emailNotificationsData: initialState.settingsState.emailNotificationsData,
				},
			}),
			[TYPES.GET_USER_EMAIL_NOTIFICATIONS.SUCCESS]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					emailNotificationsData: action.payload,
				},
			}),
			[TYPES.GET_USER_EMAIL_NOTIFICATIONS.FAILED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					emailNotificationsData: initialState.settingsState.emailNotificationsData,
				},
			}),
			[TYPES.GET_ATS_USER_PERSONAL_DATA.REQUESTED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					personalData: initialState.settingsState.personalData,
				},
			}),
			[TYPES.GET_ATS_USER_PERSONAL_DATA.SUCCESS]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					personalData: action.payload,
				},
			}),
			[TYPES.GET_ATS_USER_PERSONAL_DATA.FAILED]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					personalData: initialState.settingsState.personalData,
				},
			}),
			[TYPES.UPDATE_USER_EMAIL_NOTIFICATIONS.SUCCESS]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					emailNotificationsData: action.payload,
				},
			}),
			[TYPES.GET_ATS_CAREEER_BRANDING_BY_ID.REQUESTED]: () => ({
				...state,
				clientBrandingState: {
					...state.clientBrandingState,
					clientCareerBrandingData: initialState.clientBrandingState.clientCareerBrandingData,
				},
			}),
			[TYPES.GET_ATS_CAREEER_BRANDING_BY_ID.SUCCESS]: () => ({
				...state,
				clientBrandingState: {
					...state.clientBrandingState,
					clientCareerBrandingData: {
						...action.payload,
						removedBenefits:
							initialState.clientBrandingState.clientCareerBrandingData.removedBenefits,
					},
				},
			}),
			[TYPES.GET_ATS_BRANDING_BY_ID.REQUESTED]: () => ({
				...state,
				clientBrandingState: {
					...state.clientBrandingState,
					clientAtsBrandingData: initialState.clientBrandingState.clientAtsBrandingData,
				},
			}),
			[TYPES.GET_ATS_BRANDING_BY_ID.SUCCESS]: () => ({
				...state,
				clientBrandingState: {
					...state.clientBrandingState,
					clientAtsBrandingData: action.payload,
				},
			}),
			[TYPES.GET_ATS_BRANDING_BY_ID.FAILED]: () => ({
				...state,
				clientBrandingState: {
					...state.clientBrandingState,
					clientAtsBrandingData: initialState.clientBrandingState.clientAtsBrandingData,
				},
			}),
			[TYPES.SAVE_ATS_BRANDING]: () => ({
				...state,
				clientBrandingState: {
					...state.clientBrandingState,
					clientAtsBrandingData: {
						...state.clientBrandingState.clientAtsBrandingData,
						...action.payload,
					},
				},
			}),
			[TYPES.SAVE_CAREER_BRANDING]: () => ({
				...state,
				clientBrandingState: {
					...state.clientBrandingState,
					clientCareerBrandingData: {
						...state.clientBrandingState.clientCareerBrandingData,
						...action.payload,
					},
				},
			}),
			[TYPES.SETTINGS_SAVE_COMPANY_DETAILS]: () => ({
				...state,
				settingsState: {
					...state.settingsState,
					companyDetailsData: {
						...state.settingsState.companyDetailsData,
						...action.payload,
					},
				},
			}),
			[TYPES.GET_ATS_INVOICES.REQUESTED]: () => ({
				...state,
				invoices: {
					...initialState.invoices,
					data: initialState.invoices.data,
				},
			}),
			[TYPES.GET_ATS_INVOICES.SUCCESS]: () => ({
				...state,
				invoices: {
					...action.payload,
					data: action.payload.data.map(formatInvoiceBtoReference),
				},
			}),
			[TYPES.GET_ATS_INVOICE_BY_ID.REQUESTED]: () => ({
				...state,
				currentInvoice: initialState.currentInvoice,
			}),
			[TYPES.GET_ATS_INVOICE_BY_ID.SUCCESS]: () => ({
				...state,
				currentInvoice: formatInvoiceBtoReference(action.payload),
			}),
			[TYPES.GET_CLIENT_CREDITS.REQUESTED]: () => ({
				...state,
				credits: initialState.credits,
			}),
			[TYPES.GET_CLIENT_CREDITS.SUCCESS]: () => ({
				...state,
				credits: action.payload,
			}),
			[TYPES.GET_AND_SAVE_POST_JOB_CHANGES_FORM.REQUESTED]: () => ({
				...state,
				postJobChangesFormState: {
					...initialState.postJobChangesFormState,
				},
			}),
			[TYPES.GET_AND_SAVE_POST_JOB_CHANGES_FORM.SUCCESS]: () => ({
				...state,
				postJobChangesFormState: {
					...state.postJobChangesFormState,
					data: {
						...action.payload,
						restrictedUserIds: action.payload.restrictedUsers?.map((user) => user.id) ?? [],
					},
				},
			}),
			[TYPES.GET_AND_SAVE_POST_JOB_CHANGES_FORM.FAILED]: () => ({
				...state,
				postJobChangesFormState: {
					...initialState.postJobChangesFormState,
				},
			}),
			[TYPES.RESET_POST_JOB_CHANGES_FORM]: () => ({
				...state,
				postJobChangesFormState: {
					...initialState.postJobChangesFormState,
				},
			}),
			[TYPES.GET_ALL_ATS_JOBS.REQUESTED]: () => ({
				...state,
				jobsState: { ...state.jobsState, jobsData: initialState.jobsState.jobsData },
			}),
			[TYPES.GET_ALL_ATS_JOBS.SUCCESS]: () => ({
				...state,
				jobsState: { ...state.jobsState, jobsData: action.payload },
			}),
			[TYPES.GET_ALL_ATS_JOBS.FAILED]: () => ({
				...state,
				jobsState: { ...state.jobsState, jobsData: initialState.jobsState.jobsData },
			}),
			[TYPES.GET_ATS_ACTIVE_JOBS.REQUESTED]: () => ({
				...state,
				jobsState: { ...state.jobsState, activeJobs: initialState.jobsState.activeJobs },
			}),
			[TYPES.GET_ATS_ACTIVE_JOBS.SUCCESS]: () => ({
				...state,
				jobsState: { ...state.jobsState, activeJobs: action.payload },
			}),
			[TYPES.GET_ATS_ACTIVE_JOBS.FAILED]: () => ({
				...state,
				jobsState: { ...state.jobsState, activeJobs: initialState.jobsState.activeJobs },
			}),
			[TYPES.RESET_ACTIVE_JOBS_LIST]: () => ({
				...state,
				jobsState: { ...state.jobsState, activeJobs: initialState.jobsState.activeJobs },
			}),
			[TYPES.GET_ATS_ALL_JOBS_LIST.SUCCESS]: () => ({
				...state,
				jobsState: { ...state.jobsState, allJobsList: action.payload },
			}),
			[TYPES.GET_ATS_INTERVIEW_TYPE.REQUESTED]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewTypes: initialState.interviewState.interviewTypes,
				},
			}),
			[TYPES.GET_ATS_INTERVIEW_TYPE.SUCCESS]: () => ({
				...state,
				interviewState: { ...state.interviewState, interviewTypes: action.payload },
			}),
			[TYPES.GET_ATS_INTERVIEW_TYPE.FAILED]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewTypes: initialState.interviewState.interviewTypes,
				},
			}),
			[TYPES.GET_CANDIDATE_ASSESSMENT_APPLICATION.REQUESTED]: () => ({
				...state,
				candidatesState: {
					...state.candidatesState,
					interviewTypes: initialState.candidatesState.candidateAssessmentApplication,
				},
			}),
			[TYPES.GET_CANDIDATE_ASSESSMENT_APPLICATION.SUCCESS]: () => ({
				...state,
				candidatesState: {
					...state.candidatesState,
					candidateAssessmentApplication: action.payload?.sort((a, b) => a?.question - b?.question),
				},
			}),
			[TYPES.GET_CANDIDATE_ASSESSMENT_APPLICATION.FAILED]: () => ({
				...state,
				candidatesState: {
					...state.candidatesState,
					candidateAssessmentApplication:
						initialState.candidatesState.candidateAssessmentApplication,
				},
			}),
			[TYPES.GET_CANDIDATE_NOTES_BY_ID.SUCCESS]: () => ({
				...state,
				candidatesState: {
					...state.candidatesState,
					candidateNotes: action.payload?.map((note) => ({
						...note,
						created: new Date(note.created).toLocaleDateString('en-US', {
							year: 'numeric',
							month: 'short',
							day: 'numeric',
							timeZone: 'UTC',
						}),
					})),
				},
			}),
			[TYPES.ADD_NOTE_TO_CANDIDATE.SUCCESS]: () => ({
				...state,
				candidatesState: {
					...state.candidatesState,
					candidateNotes: [
						{
							...action.payload,
							created: new Date(action.payload.created).toLocaleDateString('en-US', {
								year: 'numeric',
								month: 'short',
								day: 'numeric',
								timeZone: 'UTC',
							}),
						},
						...state.candidatesState.candidateNotes,
					],
				},
			}),
			[TYPES.GET_ATS_USER_INTERVIEW.REQUESTED]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewUsers: initialState.interviewState.interviewUsers,
				},
			}),
			[TYPES.GET_ATS_USER_INTERVIEW.SUCCESS]: () => ({
				...state,
				interviewState: { ...state.interviewState, interviewUsers: action.payload },
			}),
			[TYPES.GET_ATS_USER_INTERVIEW.FAILED]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewUsers: initialState.interviewState.interviewUsers,
				},
			}),
			[TYPES.GET_ATS_INTERVIEW_JOBS_LIST.REQUESTED]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewJobsList: initialState.interviewState.interviewJobsList,
				},
			}),
			[TYPES.GET_ATS_INTERVIEW_JOBS_LIST.SUCCESS]: () => ({
				...state,
				interviewState: { ...state.interviewState, interviewJobsList: action.payload },
			}),
			[TYPES.GET_ATS_INTERVIEW_JOBS_LIST.FAILED]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewJobsList: initialState.interviewState.interviewJobsList,
				},
			}),
			[TYPES.RESET_INTERVIEW_JOBS_LIST]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewJobsList: initialState.interviewState.interviewJobsList,
				},
			}),
			[TYPES.UPDATE_CANDIDATE_RANK]: () => {
				const { candidateData, candidateAppId, jobId } = action.payload;

				const newInterviewState = {
					...state.interviewState,
					interviewJobsList: state.interviewState.interviewJobsList.map((job) => {
						if (job.id !== jobId) {
							return job;
						}
						const newInterviews = job.interviews.map((interview) => {
							if (interview.candidateId !== candidateAppId) {
								return interview;
							}

							return _.set({ ...interview }, 'rank', candidateData.rank);
						});

						return _.set({ ...job }, 'interviews', newInterviews);
					}),
				};

				return _.set({ ...state }, 'interviewState', newInterviewState);
			},
			[TYPES.GET_ATS_EDIT_INTERVIEW_JOBS_LIST.REQUESTED]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewEditJobsList: initialState.interviewState.interviewEditJobsList,
				},
			}),
			[TYPES.GET_ATS_EDIT_INTERVIEW_JOBS_LIST.SUCCESS]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewEditJobsList: action.payload?.map((i) => ({ id: i.jobId, title: i.jobTitle })),
				},
			}),
			[TYPES.GET_ATS_EDIT_INTERVIEW_JOBS_LIST.FAILED]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewEditJobsList: initialState.interviewState.interviewEditJobsList,
				},
			}),
			[TYPES.SAVE_ATS_INTERVIEW.SUCCESS]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewCalendarList: initialState.interviewState.interviewCalendarList,
					interviewJobsList: initialState.interviewState.interviewJobsList,
					interviewData: initialState.interviewState.interviewData,
				},
			}),
			[TYPES.UPDATE_ATS_INTERVIEW.SUCCESS]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewCalendarList: initialState.interviewState.interviewCalendarList,
					interviewJobsList: initialState.interviewState.interviewJobsList,
					interviewData: initialState.interviewState.interviewData,
				},
			}),
			[TYPES.CANCEL_ATS_INTERVIEW_BY_ID.SUCCESS]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewCalendarList: initialState.interviewState.interviewCalendarList,
					interviewJobsList: initialState.interviewState.interviewJobsList,
					interviewData: initialState.interviewState.interviewData,
				},
			}),
			[TYPES.GET_ATS_INTERVIEW_CALENDAR_LIST.REQUESTED]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewCalendarList: initialState.interviewState.interviewCalendarList,
				},
			}),
			[TYPES.GET_ATS_INTERVIEW_CALENDAR_LIST.SUCCESS]: () => ({
				...state,
				interviewState: { ...state.interviewState, interviewCalendarList: action.payload },
			}),
			[TYPES.GET_ATS_INTERVIEW_CALENDAR_LIST.FAILED]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewCalendarList: initialState.interviewState.interviewCalendarList,
				},
			}),
			[TYPES.RESET_ATS_INTERVIEW_CALENDAR_LIST]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewCalendarList: initialState.interviewState.interviewCalendarList,
				},
			}),
			[TYPES.GET_ATS_INTERVIEW_BY_ID.REQUESTED]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewData: initialState.interviewState.interviewData,
				},
			}),
			[TYPES.GET_ATS_INTERVIEW_BY_ID.SUCCESS]: () => ({
				...state,
				interviewState: { ...state.interviewState, interviewData: action.payload },
			}),
			[TYPES.GET_ATS_INTERVIEW_BY_ID.FAILED]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewData: initialState.interviewState.interviewData,
				},
			}),
			[TYPES.GET_CANDIDATE_APPLICATION_BY_JOB_ID.REQUESTED]: () => ({
				...state,
				candidatesState: {
					candidateApplicationData: initialState.candidatesState.candidateApplicationData,
				},
			}),
			[TYPES.GET_CANDIDATE_APPLICATION_BY_JOB_ID.SUCCESS]: () => ({
				...state,
				candidatesState: {
					...state.candidatesState,
					candidateApplicationData: action.payload,
				},
			}),
			[TYPES.GET_CANDIDATE_APPLICATION_BY_JOB_ID.FAILED]: () => ({
				...state,
				candidatesState: {
					...state.candidatesState,
					candidateApplicationData: initialState.candidatesState.candidateApplicationData,
				},
			}),
			[TYPES.GET_ATS_ALL_CANDIDATE_APPLICATION_BY_JOB_ID.SUCCESS]: () => ({
				...state,
				candidatesState: {
					...state.candidatesState,
					allCandidateApplicationsByJob: action.payload,
				},
			}),
			[TYPES.GET_JOB_UPGRAGED.REQUESTED]: () => ({
				...state,
				jobUpgrades: initialState.jobUpgrades,
			}),
			[TYPES.GET_JOB_UPGRAGED.SUCCESS]: () => ({
				...state,
				jobUpgrades: action.payload,
			}),
			[TYPES.GET_JOB_UPGRAGED.FAILED]: () => ({
				...state,
				jobUpgrades: initialState.jobUpgrades,
			}),
			[TYPES.GET_ORDER_BY_ID.REQUESTED]: () => ({
				...state,
				currentOrder: initialState.currentOrder,
			}),
			[TYPES.GET_ORDER_BY_ID.SUCCESS]: () => ({
				...state,
				currentOrder: action.payload,
			}),
			[TYPES.GET_ORDER_BY_ID.FAILED]: () => ({
				...state,
				currentOrder: initialState.currentOrder,
			}),
			[TYPES.RESET_ATS_CURRENT_ORDER]: () => ({
				...state,
				currentOrder: initialState.currentOrder,
			}),
			[TYPES.GET_UNAPPLIED_CANDIDATE_JOBS.SUCCESS]: () => ({
				...state,
				unappliedCandidateJobsState: action.payload,
			}),
			[TYPES.GET_UNAPPLIED_CANDIDATE_JOBS.FAILED]: () => ({
				...state,
				unappliedCandidateJobsState: [...initialState.unappliedCandidateJobsState],
			}),
			[TYPES.GET_ATS_TICKETS.REQUESTED]: () => ({
				...state,
				tickets: initialState.tickets,
			}),
			[TYPES.GET_ATS_TICKETS.SUCCESS]: () => {
				const { data, ...rest } = action.payload;
				const tickets = {
					data: data?.map((ticket) => ({ key: ticket.id, ...ticket })),
					...rest,
				};

				return {
					...state,
					tickets,
				};
			},
			[TYPES.GET_CLIENT_SUBSCRIPTION.SUCCESS]: () => {
				const isPremiumPlan = action.payload?.premium || action.payload?.trial;

				const clientAtsBrandingData = isPremiumPlan
					? {
						...ATS_DEFAULT_COLORS,
						...state.clientBrandingState?.clientAtsBrandingData,
					  }
					: {
						...state.clientBrandingState?.clientAtsBrandingData,
						...ATS_DEFAULT_COLORS,
					  };

				return {
					...state,
					subscription: {
						...state.subscription,
						currentSubscription: action.payload,
						subscriptionPlans: {
							isPremiumPlan: action.payload.premium || action.payload.trial,
						},
					},
					clientBrandingState: {
						...state.clientBrandingState,
						clientAtsBrandingData,
					},
				};
			},
			[TYPES.GET_SUBSCRIPTION_TYPES.REQUESTED]: () => ({
				...state,
				subscription: {
					...state.subscription,
					subscriptionTypes: initialState.subscription.subscriptionTypes,
				},
			}),
			[TYPES.GET_SUBSCRIPTION_TYPES.SUCCESS]: () => ({
				...state,
				subscription: {
					...state.subscription,
					subscriptionTypes: action.payload,
				},
			}),
			[TYPES.GET_ATS_TICKET_DETAILS.REQUESTED]: () => ({
				...state,
				ticketDetails: initialState.ticketDetails,
			}),
			[TYPES.RESET_ATS_TICKET_DETAILS]: () => ({
				...state,
				ticketDetails: initialState.ticketDetails,
			}),
			[TYPES.GET_ATS_TICKET_DETAILS.SUCCESS]: () => {
				const ticketDetails = {
					...action.payload,
					activities: action.payload.activities?.map((activity) => ({
						...activity,
						date: new Date(activity.date).toLocaleDateString('en-US', {
							year: 'numeric',
							month: 'short',
							day: 'numeric',
							timeZone: 'UTC',
						}),
					})),
				};

				return {
					...state,
					ticketDetails,
				};
			},
			[TYPES.GET_CANDIDATES_DATABASE.SUCCESS]: () => {
				const { data, ...rest } = action.payload;

				const candidatesDatabase = {
					data: data?.map((candidate) => ({ key: candidate.id, ...candidate })),
					...rest,
				};

				return {
					...state,
					candidatesDatabase,
				};
			},
			[TYPES.RESET_CANDIDATES_DATABASE]: () => ({
				...state,
				candidatesDatabase: initialState.candidatesDatabase,
			}),
			[TYPES.GET_CANDIDATE_DATABASE_BY_ID.SUCCESS]: () => ({
				...state,
				candidateDatabaseDetails: action.payload,
			}),
			[TYPES.SHOW_HIDE_CANDIDATE_DATABASE.SUCCESS]: () => ({
				...state,
				candidateDatabaseDetails: action.payload,
			}),
			[TYPES.GET_CANDIDATE_SMS_INFORMATION.SUCCESS]: () => {
				const candidatesSMSInformation = action.payload?.map((candidate) => ({
					...candidate,
					smsHistory: candidate.smsHistory?.map((sms) => ({
						...sms,
						sentDate: new Date(sms.sentDate).toLocaleDateString('en-US', {
							year: 'numeric',
							month: 'short',
							day: 'numeric',
							timeZone: 'UTC',
						}),
					})),
					isInvalidPhoneNumber: !phoneNumberIsValid(candidate.phoneNumber),
				}));

				return {
					...state,
					candidatesSMSInformation,
				};
			},
			[TYPES.RESET_CANDIDATES_SMS_INFORMATION]: () => ({
				...state,
				candidatesSMSInformation: initialState.candidatesSMSInformation,
			}),
			[TYPES.GET_ATS_VIDEO_INTERVIEW_COUNT.SUCCESS]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewVideoCount: action.payload,
				},
			}),
			[TYPES.GET_ATS_VIDEO_INTERVIEW_COUNT.FAILED]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewVideoCount: initialState.interviewState.interviewVideoCount,
				},
			}),
			[TYPES.RESET_INTERVIEW_COUNT]: () => ({
				...state,
				interviewState: {
					...state.interviewState,
					interviewVideoCount: initialState.interviewState.interviewVideoCount,
				},
			}),
			[TYPES.GET_CANDIDATE_STATISTICS.SUCCESS]: () => ({
				...state,
				statistics: {
					...state.statistics,
					candidateStatistics: action.payload,
				},
			}),
			[TYPES.GET_APPLICATIONS_STATISTICS_FOR_LAST_MONTH.SUCCESS]: () => ({
				...state,
				statistics: {
					...state.statistics,
					applicationsStatistics: action.payload,
				},
			}),
			[TYPES.GET_CANDIDATE_APPLICATIONS_PER_STATE_STATISTICS.SUCCESS]: () => ({
				...state,
				statistics: {
					...state.statistics,
					candidateApplicationsPerState: action.payload,
				},
			}),
			[TYPES.GET_JOBS_MONTH_STATISTIC.REQUESTED]: () => ({
				...state,
				statistics: {
					...state.statistics,
					jobsMonthStatistic: initialState.statistics.jobsMonthStatistic,
				},
			}),
			[TYPES.GET_JOBS_MONTH_STATISTIC.SUCCESS]: () => ({
				...state,
				statistics: {
					...state.statistics,
					jobsMonthStatistic: action.payload,
				},
			}),
			[TYPES.GET_JOBS_MONTH_STATISTIC.FAILED]: () => ({
				...state,
				statistics: {
					...state.statistics,
					jobsMonthStatistic: initialState.statistics.jobsMonthStatistic,
				},
			}),
			[TYPES.GET_TIME_TO_HIRE_MONTH.REQUESTED]: () => ({
				...state,
				statistics: {
					...state.statistics,
					timeToHireMonth: initialState.statistics.timeToHireMonth,
				},
			}),
			[TYPES.GET_TIME_TO_HIRE_MONTH.SUCCESS]: () => ({
				...state,
				statistics: {
					...state.statistics,
					timeToHireMonth: action.payload,
				},
			}),
			[TYPES.GET_TIME_TO_HIRE_MONTH.FAILED]: () => ({
				...state,
				statistics: {
					...state.statistics,
					timeToHireMonth: initialState.statistics.timeToHireMonth,
				},
			}),
			[TYPES.GET_TIME_TO_HIRE_YEAR.REQUESTED]: () => ({
				...state,
				statistics: {
					...state.statistics,
					timeToHireYear: initialState.statistics.timeToHireYear,
				},
			}),
			[TYPES.GET_TIME_TO_HIRE_YEAR.SUCCESS]: () => ({
				...state,
				statistics: {
					...state.statistics,
					timeToHireYear: action.payload,
				},
			}),
			[TYPES.GET_TIME_TO_HIRE_YEAR.FAILED]: () => ({
				...state,
				statistics: {
					...state.statistics,
					timeToHireYear: initialState.statistics.timeToHireYear,
				},
			}),
			[TYPES.GET_APPLICATION_YEAR_STATISTICS.REQUESTED]: () => ({
				...state,
				statistics: {
					...state.statistics,
					applicationsYear: initialState.statistics.applicationsYear,
				},
			}),
			[TYPES.GET_APPLICATION_YEAR_STATISTICS.SUCCESS]: () => ({
				...state,
				statistics: {
					...state.statistics,
					applicationsYear: action.payload,
				},
			}),
			[TYPES.GET_APPLICATION_YEAR_STATISTICS.FAILED]: () => ({
				...state,
				statistics: {
					...state.statistics,
					applicationsYear: initialState.statistics.applicationsYear,
				},
			}),
			[TYPES.GET_APPLICATION_MONTH_STATISTICS.REQUESTED]: () => ({
				...state,
				statistics: {
					...state.statistics,
					applicationsMonth: initialState.statistics.applicationsMonth,
				},
			}),
			[TYPES.GET_APPLICATION_MONTH_STATISTICS.SUCCESS]: () => ({
				...state,
				statistics: {
					...state.statistics,
					applicationsMonth: action.payload,
				},
			}),
			[TYPES.GET_APPLICATION_MONTH_STATISTICS.FAILED]: () => ({
				...state,
				statistics: {
					...state.statistics,
					applicationsMonth: initialState.statistics.applicationsMonth,
				},
			}),
			[TYPES.GET_ACCEPTED_OFFERS_STATISTICS.SUCCESS]: () => ({
				...state,
				statistics: {
					...state.statistics,
					acceptedOffersStatistics: action.payload,
				},
			}),
			[TYPES.GET_APPLICATION_SOURCES_STATISTICS.SUCCESS]: () => ({
				...state,
				statistics: {
					...state.statistics,
					applicationSourcesStatistics: action.payload,
				},
			}),
			[TYPES.GET_DASHBOARD_UNREAD_EMAILS.SUCCESS]: () => ({
				...state,
				emailsState: {
					...state.emailsState,
					dashboardUnreadEmails: action.payload,
				},
			}),
			[TYPES.GET_ATS_EMAIL_TEMPLATES.SUCCESS]: () => ({
				...state,
				emailsState: {
					...state.emailsState,
					emailTemplates: action.payload,
				},
			}),
			[TYPES.GET_ATS_EMAIL_INBOX.SUCCESS]: () => ({
				...state,
				emailsState: {
					...state.emailsState,
					inboxThreads: action.payload,
				},
			}),
			[TYPES.GET_ATS_EMAIL_THREAD_BY_ID.SUCCESS]: () => ({
				...state,
				emailsState: {
					...state.emailsState,
					currentEmailThread: action.payload,
				},
			}),
			[TYPES.UPDATE_ATS_EMAIL_UNREAD_THREAD_COUNT]: () => {
				const { candidateApplicationId, threadId } = action.payload;

				const updatedData = _.cloneDeep(state.emailsState.inboxThreads);
				if (updatedData?.unreadMessagesCount) {
					updatedData.unreadMessagesCount--;
				}

				const candidate = _.find(updatedData.data, { candidateApplicationId });

				if (candidate) {
					candidate.threads = _.map(candidate.threads, (thread) =>
						thread.id === threadId ? { ...thread, unreadMessagesCount: 0 } : thread,
					);
				}

				return {
					...state,
					emailsState: {
						...state.emailsState,
						inboxThreads: updatedData,
					},
				};
			},
			[TYPES.RESET_ATS_CURRENT_EMAIL_THREAD]: () => ({
				...state,
				emailsState: {
					...state.emailsState,
					currentEmailThread: initialState.emailsState.currentEmailThread,
				},
			}),
			[TYPES.POST_ATS_TO_EXISTING_EMAIL_THREAD.SUCCESS]: () => ({
				...state,
				emailsState: {
					...state.emailsState,
					currentEmailThread: action.payload,
				},
			}),
			[TYPES.GET_GRAVITY_JOBS.SUCCESS]: () => ({
				...state,
				jobsState: { ...state.jobsState, gravityJobs: action.payload },
			}),
			[TYPES.GET_LIST_OF_GRAVITY_CANDIDATES_BY_JOB_ID.SUCCESS]: () => ({
				...state,
				jobsState: { ...state.jobsState, currentGravityJobCandidates: action.payload },
			}),
		};

		return handlersDefaultCase(handlers, action, state);
	};
};
