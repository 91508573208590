import { RefObject } from 'react';

import { useMount } from './useEffects';

export const useScrollToTopOnMount = (ref: RefObject<HTMLDivElement>) => {
	useMount(() => {
		if (ref?.current) {
			ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
		} else {
			window.scrollTo(0, 0);
		}
	});
};
