import { combineReducers } from 'redux';

import { takeLatest } from 'redux-saga/effects';

import createLoading from 'modules/Common/ducks/loading';

import { createActions } from './actions';
import { createActionsTypes } from './constants';
import { unregisteredReducer } from './reducer';
import { createSagas } from './sagas';
import { createSelectors } from './selectors';

function getUnregisteredDucks(props) {
	const { prefix, root } = props;
	const PREFIX = prefix;

	const TYPES = createActionsTypes(PREFIX);
	const ACTIONS = createActions(TYPES);
	const SELECTORS = createSelectors(root);

	const SAGAS = createSagas(TYPES, PREFIX, SELECTORS, ACTIONS, props);
	const backOfficeReducer = unregisteredReducer(TYPES);

	const { reducer: loadingReducer } = createLoading({
		CHECK_CLIENT_INFO: TYPES.CHECK_CLIENT_INFO,
		GET_QUESTIONNAIRE_WELCOME: TYPES.GET_QUESTIONNAIRE_WELCOME,
		GET_QUESTIONNAIRE_DETAILS: TYPES.GET_QUESTIONNAIRE_DETAILS,
		GET_CURRENT_QUESTION: TYPES.GET_CURRENT_QUESTION,
		GET_EMAIL_THREAD: TYPES.GET_EMAIL_THREAD,
		REPLY_TO_EMAIL_THREAD: TYPES.REPLY_TO_EMAIL_THREAD,
		CHANGE_PASSWORD: TYPES.CHANGE_PASSWORD,
		FORGOT_PASSWORD: TYPES.FORGOT_PASSWORD,
		RESEND_TWOFA_CODE: TYPES.RESEND_TWOFA_CODE,
		TWOFA_LOGIN: TYPES.TWOFA_LOGIN,
		RESET_PASSWORD: TYPES.RESET_PASSWORD,
		GET_USER: TYPES.GET_USER,
		LOGIN: TYPES.LOGIN,
		IMPERSONATE: TYPES.IMPERSONATE,
		UN_IMPERSONATE: TYPES.UN_IMPERSONATE,
		LOGOUT: TYPES.LOGOUT,
	});

	function* unregisteredSaga() {
		yield takeLatest(TYPES.CHECK_CLIENT_INFO.REQUESTED, SAGAS.checkClientInfo);
		yield takeLatest(TYPES.CHANGE_PASSWORD.REQUESTED, SAGAS.changePasswordSaga);
		yield takeLatest(TYPES.FORGOT_PASSWORD.REQUESTED, SAGAS.forgotPasswordSaga);
		yield takeLatest(TYPES.RESET_PASSWORD.REQUESTED, SAGAS.resetPasswordSaga);
		yield takeLatest(TYPES.GET_USER.REQUESTED, SAGAS.getUserById);
		yield takeLatest(TYPES.LOGIN.REQUESTED, SAGAS.login);
		yield takeLatest(TYPES.TWOFA_LOGIN.REQUESTED, SAGAS.twoFALoginSaga);
		yield takeLatest(TYPES.RESEND_TWOFA_CODE.REQUESTED, SAGAS.resendTwoFACodeSaga);
		yield takeLatest(TYPES.IMPERSONATE.REQUESTED, SAGAS.impersonateSaga);
		yield takeLatest(TYPES.UN_IMPERSONATE.REQUESTED, SAGAS.unImpersonateSaga);
		yield takeLatest(TYPES.LOGOUT.REQUESTED, SAGAS.logOut);
		yield takeLatest(TYPES.GET_QUESTIONNAIRE_WELCOME.REQUESTED, SAGAS.getQuestionnaireWelcomeSaga);
		yield takeLatest(TYPES.GET_QUESTIONNAIRE_DETAILS.REQUESTED, SAGAS.getQuestionnaireDetailsSaga);
		yield takeLatest(TYPES.GET_CURRENT_QUESTION.REQUESTED, SAGAS.getCurrentQuestionSaga);
		yield takeLatest(TYPES.GET_EMAIL_THREAD.REQUESTED, SAGAS.getEmailThreadSaga);
		yield takeLatest(TYPES.REPLY_TO_EMAIL_THREAD.REQUESTED, SAGAS.replyToEmailThreadSaga);
	}

	const reducer = combineReducers({ data: backOfficeReducer, loadingReducer });

	return {
		reducer,
		unregisteredSaga,
		unregisteredActions: { ...ACTIONS },
		unregisteredSelectors: SELECTORS,
		unregisteredActionTypes: TYPES,
		backOfficesSagas: SAGAS,
	};
}

const unregisteredDucks = getUnregisteredDucks({ prefix: 'auth', root: 'unregisteredReducer' });

export default unregisteredDucks;
