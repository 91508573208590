import { memo } from 'react';

import { IconType } from '../SVG.types';

function YoutubeIcon({ width = '22', height = '22' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_4582_10541)'>
				<path
					fill='#39393B'
					d='M24 11.927c0-.06 0-.126-.003-.204-.002-.217-.008-.46-.013-.72a40.333 40.333 0 00-.118-2.194c-.08-.967-.198-1.774-.359-2.379a3.737 3.737 0 00-2.633-2.638c-.758-.204-2.242-.33-4.331-.407-.994-.038-2.057-.062-3.12-.075-.373-.006-.719-.008-1.03-.011h-.787c-.31.003-.656.005-1.028.01-1.064.014-2.127.038-3.12.076-2.09.08-3.577.206-4.332.407A3.733 3.733 0 00.493 6.43C.329 7.035.214 7.842.133 8.81a40.332 40.332 0 00-.117 2.193c-.008.26-.01.504-.013.72 0 .079-.003.146-.003.205v.15c0 .058 0 .125.003.203.002.217.008.46.013.72.021.748.059 1.493.118 2.194.08.967.198 1.774.359 2.379a3.732 3.732 0 002.633 2.638c.755.204 2.242.33 4.331.408.994.037 2.057.061 3.12.075.373.005.719.008 1.03.01h.787c.31-.003.656-.005 1.028-.01 1.064-.014 2.127-.038 3.12-.075 2.09-.08 3.577-.207 4.332-.408a3.728 3.728 0 002.633-2.638c.163-.605.279-1.412.36-2.379.058-.701.095-1.446.117-2.193.008-.26.01-.504.013-.72 0-.079.003-.146.003-.205v-.15zm-1.929.139c0 .056 0 .118-.002.19-.003.209-.008.44-.014.688a36.306 36.306 0 01-.112 2.087c-.073.862-.174 1.57-.3 2.044a1.802 1.802 0 01-1.27 1.272c-.562.15-1.98.27-3.905.343a121.718 121.718 0 01-4.085.083h-.766l-1.013-.008a122.03 122.03 0 01-3.072-.075c-1.926-.075-3.345-.193-3.905-.343a1.807 1.807 0 01-1.27-1.272c-.126-.474-.228-1.181-.3-2.044a38.799 38.799 0 01-.112-2.087c-.008-.249-.011-.482-.014-.688l-.002-.19v-.129c0-.056 0-.118.002-.19.003-.209.008-.44.014-.688.018-.713.056-1.425.112-2.087.072-.862.174-1.57.3-2.044a1.802 1.802 0 011.27-1.272c.562-.15 1.98-.27 3.905-.343a121.72 121.72 0 014.085-.083h.766l1.013.008c1.047.014 2.094.038 3.072.075 1.926.075 3.345.193 3.905.343a1.807 1.807 0 011.27 1.272c.126.474.227 1.182.3 2.044.056.662.09 1.374.112 2.087.008.249.011.482.014.688l.002.19v.129zM9.616 15.59l6.214-3.616-6.214-3.563v7.179z'
				></path>
			</g>
			<defs>
				<clipPath id='clip0_4582_10541'>
					<path fill='#fff' d='M0 0H24V24H0z'></path>
				</clipPath>
			</defs>
		</svg>
	);
}

export default memo(YoutubeIcon);
