import type { FC } from 'react';

import dayjs from 'dayjs';

import BrandingContainer from 'modules/Common/components/BrandingContainer';
import { BrandingMode } from 'modules/Common/components/BrandingContainer/BrandingContainer.types';
import DashboardActiveJobs from 'modules/Common/components/DashboardComponents/DashboardActiveJobs';
import DashboardAllCandidates from 'modules/Common/components/DashboardComponents/DashboardAllCandidates';
import DashboardEmails from 'modules/Common/components/DashboardComponents/DashboardEmails';
import DashboardInterviews from 'modules/Common/components/DashboardComponents/DashboardInterviews';
import DashboardJobsStatistics from 'modules/Common/components/DashboardComponents/DashboardJobsStatistics';
import { AtsBrandingType, JobDataType } from 'modules/Common/types';

import {
	jobMonthStatistic,
	candidateStatistics,
	interviewList,
	emailList,
} from './ATSClientBranding.mocks';
import { Styled } from './ATSClientBranding.styled';

type AtsBrandingPickType = Pick<
	AtsBrandingType,
	'clientName' | 'textColour' | 'highlightColour' | 'logo' | 'headerBackground' | 'backgroundImage'
>;

type ATSClientBrandingProps = AtsBrandingPickType & {
	clientId?: number;
	jobsData: {
		data: JobDataType[];
		pageIndex: number;
		pageSize: number;
		totalCount: number;
	};
	handleBgRemove?: () => void;
	handleBgEdit?: () => void;
	handleLogoRemove?: () => void;
	handleLogoEdit?: () => void;
};

const ATSClientBranding: FC<ATSClientBrandingProps> = ({
	jobsData,
	clientName,
	headerBackground,
	textColour,
	highlightColour,
	backgroundImage,
	logo,
	handleBgRemove,
	handleLogoRemove,
	handleBgEdit,
	handleLogoEdit,
}) => {
	return (
		<Styled.Root>
			<BrandingContainer
				clientName={clientName}
				backgroundImage={backgroundImage || '/images/background.png'}
				logo={logo}
				mode={BrandingMode.Edit}
				onBgRemove={handleBgRemove}
				headerBackground={headerBackground}
				textColour={textColour}
				highlightColour={highlightColour}
				onLogoRemove={handleLogoRemove}
				onBgEdit={handleBgEdit}
				onLogoEdit={handleLogoEdit}
			>
				<Styled.Content>
					<Styled.LeftColumn>
						<DashboardJobsStatistics
							loading={false}
							getJobsMonthStatistic={() => null}
							clientBrandingData={{
								highlightColour,
								textColour,
							}}
							subscriptionPlans={{ isPremiumPlan: true }}
							jobStatistic={jobMonthStatistic}
							startDate='2024-04-25'
						/>
						<DashboardActiveJobs
							jobsData={jobsData}
							clientBrandingData={{
								highlightColour,
								textColour,
							}}
							countries={{ isUK: true, isUS: false }}
							subscriptionPlans={{ isPremiumPlan: true }}
							loading={false}
							getAllJobs={() => null}
							archiveAndUnarchiveJob={() => null}
							hideArchiveAndBoostButtons={true}
							handleEditJob={() => null}
							handleSeeAllJobs={() => null}
							handlePostJob={() => null}
						/>
					</Styled.LeftColumn>
					<Styled.RightColumn>
						<DashboardAllCandidates
							candidateStatistics={candidateStatistics}
							loading={false}
							getCandidateStatistics={() => null}
							clientId={'1'}
						/>
						<DashboardInterviews
							startDate={dayjs()}
							filterInterviewFunc={() => () => true}
							interviewList={interviewList}
							loading={false}
							getInterviewCalendarList={() => null}
							hideSeeMoreButton={true}
							interviewLinkDisabled={true}
						/>
						<DashboardEmails emailList={emailList} loading={false} getEmails={() => null} />
					</Styled.RightColumn>
				</Styled.Content>
			</BrandingContainer>
		</Styled.Root>
	);
};

export default ATSClientBranding;
