import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		padding: 0 32px;

		.ant-tabs .ant-tabs-nav {
			.ant-tabs-tab-btn {
				position: relative;
				padding: 0 10px;
			}

			&:before {
				display: none;
			}
		}
	`;

	export const Head = styled.div`
		display: flex;
		justify-content: space-between;
		margin-top: 24px;
		margin-bottom: 24px;

		h2 {
			margin: 0;
		}
	`;
}
