import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import UserAccessForm from 'modules/BackOffice/containers/UserAccessFormContainer';
import { BackOfficeSettingsRoutes } from 'types';

export const UserAccessFormPage = () => {
	const location = useLocation();
	const { userId } = useParams();

	const isEditMode =
		location?.pathname?.includes(BackOfficeSettingsRoutes.UserAccessEdit) && !!userId;

	return (
		<UserAccessForm isEditMode={isEditMode} navigationLink={BackOfficeSettingsRoutes.UserAccess} />
	);
};

export default UserAccessFormPage;
