import React from 'react';

import PostMessage from 'modules/ATS/containers/PostMessage';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { Routes } from 'types';

import { Styled } from './ATSCreateMessagePage.styled';

const ATSCreateMessagePage = () => {
	return (
		<>
			<Styled.Head>
				<PageNavTitle title={'New Message'} navigationLink={`${Routes.ATS}${Routes.Messages}`} />
			</Styled.Head>
			<Styled.Content>
				<PostMessage />
			</Styled.Content>
		</>
	);
};

export default ATSCreateMessagePage;
