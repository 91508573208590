import React, { type FC, memo } from 'react';
import { Field, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Button, Input, Popconfirm, Select } from 'antd';
import { FormApi } from 'final-form';

import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import FormSwitch from 'components/FormSwitch';
import ArrowDown from 'components/SVG/ArrowDown';
import ArrowUp from 'components/SVG/ArrowUp';
import DeleteIcon from 'components/SVG/DeleteIcon';
import FormBlock from 'modules/Common/components/FormBlock';
import {
	InitItem,
	InitYesNoQns,
	IPostFreeJobValues,
	TypeOfQuestionEnum,
	typeOfQuestionsData,
} from 'modules/Common/types';
import { COLORS } from 'theme';
import { IOption } from 'types';
import {
	composeValidators,
	requiredFieldValidator,
	requiredValidateAssessmentAnswers,
	textMaxLengthValidator,
} from 'utils/validators';

import AQBuilderCheckboxField from './AQCheckboxField';
import AQBuilderRadioField from './AQRadioField';
import { Styled } from './AssessmentQuestions.styled';

const { Option } = Select;

type AssessmentQuestionsProps = {
	form: FormApi<IPostFreeJobValues, Partial<IPostFreeJobValues>>;
	onDeleteForm?: () => void;
	readOnly: boolean;
};

const AssessmentQuestions: FC<AssessmentQuestionsProps> = ({ form, onDeleteForm, readOnly }) => {
	const { values } = useFormState();
	const {
		mutators: { push },
		change,
	} = form;

	const handleDeleteAssessmentForm = () => {
		change('assessmentQuestions', []);
		onDeleteForm && onDeleteForm();
	};

	return (
		<FormBlock>
			<Styled.AQHead>
				<h2>Key Questions for the Applicant</h2>
				{!!values.assessmentQuestions?.length && (
					<Popconfirm
						placement='top'
						title='Are you sure?'
						onConfirm={handleDeleteAssessmentForm}
						okText='Yes'
						cancelText='Cancel'
					>
						<>
							<Button type='ghost' htmlType='button' disabled={readOnly}>
								Delete Assessment Form
							</Button>
						</>
					</Popconfirm>
				)}
			</Styled.AQHead>
			<Styled.TextHint>
				Key questions must be answered with a specific answer to apply.
			</Styled.TextHint>
			<Styled.TextHint fontSize={'14px'}>
				Assessment forms cannot be completed with URL Method
			</Styled.TextHint>
			<Styled.TextHint fontSize={'14px'} fontWeight='bold'>
				Note: assessments will be shown on careers page that is available only for premium
				subscription
			</Styled.TextHint>
			<Styled.AQContainer>
				<FieldArray name='assessmentQuestions'>
					{({ fields }) =>
						fields?.map((item, index) => {
							const questionType = values.assessmentQuestions[index]?.questionType;
							const id = index + 1;

							return (
								<Styled.AQItem key={id}>
									<Styled.AQItemHead>
										<Styled.AQTitle>{`Question ${id}`}</Styled.AQTitle>
										<Styled.AQButtons>
											<Field
												name={`${item}.autoreject`}
												initialValue={values.assessmentQuestions[index]?.autoreject || false}
											>
												{({ input }) => (
													<FormSwitch
														{...input}
														title='Auto-reject'
														value={
															questionType === TypeOfQuestionEnum.textField
																? false
																: values.assessmentQuestions[index].autoreject
														}
														disabled={questionType === TypeOfQuestionEnum.textField || readOnly}
														defaultChecked
													/>
												)}
											</Field>

											<Styled.Button
												buttonType={ButtonTypes.default}
												type='button'
												icon={<ArrowUp />}
												onClick={() => fields.swap(index, index - 1)}
												disabled={index <= 0 || readOnly}
											/>
											<Styled.Button
												buttonType={ButtonTypes.default}
												type='button'
												icon={<ArrowDown />}
												onClick={() => fields.swap(index, index + 1)}
												disabled={index >= values?.assessmentQuestions?.length - 1 || readOnly}
											/>
											<Popconfirm
												placement='bottomRight'
												title='Are you sure you want to remove this question?'
												onConfirm={() => {
													if (fields.length === 1) return handleDeleteAssessmentForm();

													return fields.remove(index);
												}}
												okText='Delete'
												cancelText='Cancel'
												disabled={readOnly}
											>
												<>
													<Styled.DeleteButton
														buttonType={ButtonTypes.default}
														type='button'
														icon={<DeleteIcon fill={COLORS.red} />}
													/>
												</>
											</Popconfirm>
										</Styled.AQButtons>
									</Styled.AQItemHead>
									<Styled.AQRow>
										<Field
											name={`${item}.questionText`}
											validate={composeValidators(requiredFieldValidator, textMaxLengthValidator)}
										>
											{({ input, meta }) => (
												<FieldWrapper
													name={`${item}.questionText`}
													label='Question'
													fullWidth
													errorMessage={meta.submitFailed && meta.touched && meta.error}
												>
													<Input
														{...input}
														placeholder='Enter question...'
														autoComplete='off'
														disabled={readOnly}
													/>
												</FieldWrapper>
											)}
										</Field>
										<Field name={`${item}.questionType`} validate={requiredFieldValidator}>
											{({ input, meta }) => (
												<FieldWrapper
													name={`${item}.questionType`}
													label='Type of Question'
													fullWidth
													errorMessage={meta.submitFailed && meta.touched && meta.error}
												>
													<Select
														{...input}
														value={input.value || null}
														onChange={(value) => {
															fields.update(index, {
																...values?.assessmentQuestions[index],
																autoreject: value !== TypeOfQuestionEnum.textField,
																assessmentAnswers:
																	value === TypeOfQuestionEnum.yesNoQn ? InitYesNoQns : [],
															});

															input.onChange(value);
														}}
														placeholder='Select a job type'
														disabled={readOnly}
													>
														{typeOfQuestionsData?.map((option: IOption) => (
															<Option key={option.id} value={option.id}>
																{option.name}
															</Option>
														))}
													</Select>
												</FieldWrapper>
											)}
										</Field>
									</Styled.AQRow>
									<Styled.AQContent>
										<Field
											name={`${item}.assessmentAnswers`}
											initialValue={values?.assessmentQuestions[index]?.assessmentAnswers}
											validate={(value, currentValues) =>
												questionType === TypeOfQuestionEnum.textField
													? undefined
													: requiredValidateAssessmentAnswers(
														value,
														currentValues?.assessmentQuestions[index]?.autoreject,
													  )
											}
										>
											{({ input: { onChange }, meta }) => (
												<FieldWrapper
													isFixed
													name={`${item}.assessmentAnswers`}
													errorMessage={meta.submitFailed && meta.touched && meta.error}
												>
													<>
														{questionType === TypeOfQuestionEnum.yesNoQn && (
															<AQBuilderRadioField
																options={values.assessmentQuestions[index]?.assessmentAnswers}
																onChangeCallback={onChange}
																readOnly={readOnly}
															/>
														)}

														{questionType === TypeOfQuestionEnum.multiChoice && (
															<AQBuilderCheckboxField
																options={values.assessmentQuestions[index]?.assessmentAnswers}
																onChangeCallback={onChange}
																readOnly={readOnly}
															/>
														)}
													</>
												</FieldWrapper>
											)}
										</Field>
									</Styled.AQContent>
								</Styled.AQItem>
							);
						})
					}
				</FieldArray>
			</Styled.AQContainer>
			<Styled.AddQnButton
				type='button'
				onClick={() => push('assessmentQuestions', InitItem)}
				disabled={readOnly}
			>
				Add Question
			</Styled.AddQnButton>
		</FormBlock>
	);
};

export default memo(AssessmentQuestions);
