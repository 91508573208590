import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import VideoRecorder from 'react-video-recorder';

import { Radio, Progress } from 'antd';
import fixWebmDuration from 'fix-webm-duration';
import { delay } from 'lodash';

import { uploadFile } from 'api/endpoints/backOffice';
import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import Spinner from 'components/Spinner';
import CheckCircleIcon from 'components/SVG/CheckCircleIcon';
import {
	QuestionnariesRowDetailsType,
	CurrentQuestionType,
	VideoExtensionsEnum,
	VideoMimeTypesEnum,
} from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { RequestsEnum } from 'types';

import { Styled } from './QuetionnaireCompleting.styled';

type QuestionnaireCompletingProps = {
	handleStartQuestionnaire?: () => void;
	questionnaireDetails: QuestionnariesRowDetailsType;
	getCurrentQuestion: () => void;
	getQuesionnaireDetails: (pwd: string) => void;
	currentQuestion: CurrentQuestionType;
	pwd: string;
	questionnaireCompleted: boolean;
};

const QuestionnaireCompleting: FC<QuestionnaireCompletingProps> = ({
	questionnaireDetails,
	currentQuestion,
	getCurrentQuestion,
	getQuesionnaireDetails,
	questionnaireCompleted,
	pwd,
}) => {
	const [currentMinimazedQuestion, setCurrentMinimazedQuestion] = useState(null);
	const [takesLeft, setTakes] = useState(3);
	const [attempts, setAttempts] = useState([]);
	const [choosedAttempt, setChoosedAttempt] = useState(0);
	const [recording, setRecording] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [questionCompleted, setQuestionsCompleted] = useState(0);
	const [uploadingPersent, setUploadingPercent] = useState(0);

	useEffect(() => {
		for (let i = 0; i < questionnaireDetails.questions.length; i++) {
			if (!questionnaireDetails.questions[i].wasAnswered) {
				setCurrentMinimazedQuestion(questionnaireDetails.questions[i]);
				setQuestionsCompleted(i);

				break;
			}
		}
	}, [questionnaireDetails]);

	useEffect(() => {
		if (currentMinimazedQuestion?.id) {
			getCurrentQuestion(currentMinimazedQuestion.id, pwd);
		}
	}, [currentMinimazedQuestion]);

	const onStartRecording = () => {
		setRecording(true);
	};

	const onSubmitAttempt = async () => {
		const baseUrl = process.env.REACT_APP_API_URL;
		const url = `${baseUrl}${RequestsEnum.OpenQuestionnaire}/questions/${currentQuestion.id}?pwd=${pwd}`;

		setUploading(true);
		const choosedBlob = attempts[choosedAttempt]?.videoBlob;

		const isVideoWebm = choosedBlob.type?.includes(VideoMimeTypesEnum.VideoWebm);
		const type = isVideoWebm ? VideoMimeTypesEnum.VideoWebm : VideoMimeTypesEnum.VideoMp4;
		const extension = isVideoWebm ? VideoExtensionsEnum.Webm : VideoExtensionsEnum.Mp4;

		const file = new File([choosedBlob], `fileName.${extension}`, {
			type,
		});

		await uploadFile({
			file,
			url,
			onProgress: ({ percent }) => setUploadingPercent(percent.toFixed(2)),
		});

		setAttempts([]);
		setChoosedAttempt(0);
		setTakes(3);
		setRecording(false);
		setUploadingPercent(0);
		await delay(() => getQuesionnaireDetails(pwd), 1000);
		await delay(() => setUploading(false), 1000);
	};

	if ((!currentMinimazedQuestion || !currentQuestion) && !questionnaireCompleted) {
		return <Spinner fullWidth />;
	}

	const handleComplete = (blob: Blob) => {
		setAttempts((prevAttempts) => [
			...prevAttempts,
			{
				videoBlob: blob,
				url: window.URL.createObjectURL(blob),
			},
		]);
		setTakes((takes) => takes - 1);
		setRecording(false);
		if (!attempts.length) {
			setChoosedAttempt(0);
		}
	};

	return (
		<Styled.Root>
			<Styled.Title>Video Questionnaires</Styled.Title>
			<Styled.Wraper>
				<Styled.Content>
					{questionnaireCompleted ? (
						<Styled.CompletedWrapper>
							<Styled.CompletedInner>
								<Styled.CompletedIcon>
									<CheckCircleIcon width='40' height='40' />
								</Styled.CompletedIcon>
								<Styled.CompletedTitle>Thank You</Styled.CompletedTitle>
								<Styled.CompletedDesc>
									Your questionnaire has been submited successfully and we will be in touch shortly.
								</Styled.CompletedDesc>
							</Styled.CompletedInner>
						</Styled.CompletedWrapper>
					) : recording ? (
						<Styled.VideoComponent>
							<VideoRecorder
								onRecordingComplete={(videoBlob, startedAt, thumbnailBlob, duration) => {
									// if (videoBlob.type.includes('webm')) {
									fixWebmDuration(videoBlob, duration).then((fixedBlob) => {
										handleComplete(fixedBlob);
									});
									// } else {
									// 	handleComplete(videoBlob);
									// }
								}}
								countdownTime={0}
								constraints={{
									audio: true,
									video: {
										width: { ideal: 640 },
										height: { ideal: 480 },
									},
								}}
								timeLimit={currentQuestion.duration.value * 1000}
							/>
						</Styled.VideoComponent>
					) : uploading ? (
						<Styled.QuestionUploadingWrapper>
							<Styled.QuestionUploadingInner>
								<Styled.QuestionUploadingTitle>Uploading Your Videos</Styled.QuestionUploadingTitle>
								<Styled.QuestionUploadingDesc>
									Please wait for completion message or your might lose your work.
								</Styled.QuestionUploadingDesc>
								<Styled.UploadingProgress>
									<Progress percent={uploadingPersent} />
								</Styled.UploadingProgress>
							</Styled.QuestionUploadingInner>
						</Styled.QuestionUploadingWrapper>
					) : takesLeft === 3 ? (
						<Styled.DefaultContent>
							<div style={{ width: '100%' }}>
								<Styled.DefaultContentTitle>
									Question {questionCompleted + 1}/{questionnaireDetails?.questions?.length}
								</Styled.DefaultContentTitle>
								<Styled.DefaultContentInfo>
									{currentQuestion.duration.name} • 3 takes
								</Styled.DefaultContentInfo>
								<Styled.DefaultContentQuestion>
									{currentQuestion.questionText}
								</Styled.DefaultContentQuestion>
								<Styled.DefaultContentQuestionDesc>
									{currentQuestion.description}
								</Styled.DefaultContentQuestionDesc>
							</div>
							<Styled.StartRecordingButton>
								<Button buttonType={ButtonTypes.primary} onClick={onStartRecording}>
									Start Recording
								</Button>
							</Styled.StartRecordingButton>
						</Styled.DefaultContent>
					) : (
						<Styled.QuestionsReview>
							<Styled.QuestionsReviewCount>
								Question {questionCompleted + 1}/{questionnaireDetails?.questions?.length}
							</Styled.QuestionsReviewCount>
							<Styled.QuestionsReviewTitle>
								Retake or submit your answer
							</Styled.QuestionsReviewTitle>
							<Styled.QuestionAttempts>
								{attempts.map((attempt, idx) => (
									<Styled.QuestionAttempt key={idx}>
										<Styled.QuestionAttemptVideo isActive={choosedAttempt === idx}>
											<video controls width={'235px'} src={attempt.url} />
										</Styled.QuestionAttemptVideo>
										<Styled.QuestionAttemptControl>
											<Radio
												checked={choosedAttempt === idx}
												onChange={() => setChoosedAttempt(idx)}
											/>
											<Styled.QuestionAttemptInfo>
												Take {idx + 1}/{attempts.length}
											</Styled.QuestionAttemptInfo>
										</Styled.QuestionAttemptControl>
									</Styled.QuestionAttempt>
								))}
							</Styled.QuestionAttempts>
							<Styled.QuestionAttemptsAction>
								{attempts.length < 3 && (
									<Styled.QuestionAttemptsActionRetake>
										<Button buttonType={ButtonTypes.secondary} onClick={() => setRecording(true)}>
											Retake
										</Button>
									</Styled.QuestionAttemptsActionRetake>
								)}
								<Button onClick={onSubmitAttempt} buttonType={ButtonTypes.primary}>
									Submit
								</Button>
							</Styled.QuestionAttemptsAction>
						</Styled.QuestionsReview>
					)}
				</Styled.Content>
				<Styled.Sidebar>
					<Styled.SidebarQuestion>
						{questionnaireDetails.questions.map((q) => (
							<Styled.SidebarQuestion key={q.id}>
								<Styled.SidebarQuestionMain isActive={currentMinimazedQuestion?.id === q.id}>
									{q.wasAnswered && (
										<Styled.SidebarQuestionIcon>
											<CheckCircleIcon />
										</Styled.SidebarQuestionIcon>
									)}
									<Styled.SidebarQuestionOrder>
										Question {q.orderInQuestionnaire + 1}. {q.duration.name}
									</Styled.SidebarQuestionOrder>
								</Styled.SidebarQuestionMain>
								{currentMinimazedQuestion?.id === q.id && (
									<>
										<Styled.SidebarQuestionTitle>
											{currentQuestion.questionText}
										</Styled.SidebarQuestionTitle>
										<Styled.SidebarQuestionDesc>
											{currentQuestion.description}
										</Styled.SidebarQuestionDesc>
									</>
								)}
							</Styled.SidebarQuestion>
						))}
					</Styled.SidebarQuestion>
				</Styled.Sidebar>
			</Styled.Wraper>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		loading: unregisteredDucks.unregisteredSelectors.getLoading(state),
		currentQuestion: unregisteredDucks.unregisteredSelectors.getCurrentQuestionState(state),
	}),
	{
		getCurrentQuestion: unregisteredDucks.unregisteredActions.getCurrentQuestion,
	},
)(QuestionnaireCompleting);
