export const DEFAULT_CURRENT_PAGE = 1;

export const DEFAULT_PAGE_SIZE = 12;

export const DEFAULT_ATS_JOBS_PAGE_SIZE = 6;

export const DEFAULT_PAGE_SIZE_OPTIONS = ['10', '12', '24', '36'];

export const DEFAULT_ATS_JOBS_PAGE_SIZE_OPTIONS = ['6', '12', '24'];

export const UNASSIGNED_JOB_TITLE = 'Unassigned candidates';
