import { type FC, type ReactNode } from 'react';

import ShowMoreWrapperItemRelated from 'components/ShowMoreWrapperItemRelated';
import { prefixLink } from 'utils/helpers';

import CareerPageJob from './CareerPageJob';
import { Styled } from './CareerPageJobs.styled';
import CompanyApplyBlock from './CompanyApplyBlock';

type CareerPageJobsProps = {
	mode?: string;
	clientId?: number;
	jobsData?: {
		jobId: number;
		title: string;
		candidatesStatistic?: {
			id: number;
			title: string;
			amount: number;
		}[];
		location: string;
		expireData?: string;
		salary?: string;
	}[];
	socialNetworksData?: {
		id: number;
		icon: ReactNode;
		url: string;
	}[];
	highlightColour: string;
};

const CareerPageJobs: FC<CareerPageJobsProps> = ({
	jobsData,
	socialNetworksData,
	highlightColour,
	mode,
	clientId,
}) => {
	const viewJobs = jobsData?.filter((j) => j.title !== 'Unassigned candidates');

	return (
		<Styled.JobsBlockWrapper id='jobs'>
			<Styled.JobsContacts>
				<CompanyApplyBlock clientId={clientId} highlightColour={highlightColour} />

				{!!socialNetworksData?.length && (
					<Styled.JobsContactsNetworks>
						<Styled.JobsContactsNetworksTitle>FOLLOW US</Styled.JobsContactsNetworksTitle>
						<Styled.JobsContactsNetworksItems>
							{socialNetworksData?.map((sn) => (
								<a href={prefixLink(sn.url)} target='_blank' rel='noreferrer' key={sn.id}>
									<Styled.JobsContactsNetworksItem>{sn.icon}</Styled.JobsContactsNetworksItem>
								</a>
							))}
						</Styled.JobsContactsNetworksItems>
					</Styled.JobsContactsNetworks>
				)}
			</Styled.JobsContacts>
			{!!viewJobs?.length && (
				<Styled.JobsMain>
					<Styled.JobsTitle>Jobs:</Styled.JobsTitle>
					<ShowMoreWrapperItemRelated
						maxVisibleItems={5}
						style={{ display: 'grid', rowGap: '16px' }}
					>
						{viewJobs?.map((ji, idx) => (
							<CareerPageJob
								clientId={clientId}
								mode={mode}
								key={ji.jobId || idx}
								job={ji}
								highlightColour={highlightColour}
							/>
						))}
					</ShowMoreWrapperItemRelated>
				</Styled.JobsMain>
			)}
		</Styled.JobsBlockWrapper>
	);
};

export default CareerPageJobs;
