import styled from 'styled-components/macro';

export namespace Styled {
	export const MessageWrapper = styled.div`
		margin-bottom: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
	`;

    export const Message = styled.div`
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        padding-left: 8px;
    `;
}
