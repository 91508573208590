import styled from 'styled-components/macro';

export namespace Styled {
	export const Content = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 8px;
	`;
}
