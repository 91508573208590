import React from 'react';

import { TStyled } from 'theme';

import ForgotPasswordForm from './ForgotPasswordForm';

export const ForgotPassword = () => (
	<TStyled.UnregisteredFormWrapper>
		<TStyled.UnregisteredFormTitle>Forgot Password</TStyled.UnregisteredFormTitle>

		<ForgotPasswordForm />
	</TStyled.UnregisteredFormWrapper>
);
