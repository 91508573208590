import DraggerAntd from 'antd/es/upload/Dragger';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;

		.myClass {
			margin-top: 56px;
		}
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
		margin-bottom: 32px;
		gap: 16px;
	`;

	export const FormBlockContent = styled.div`
		display: flex;
		gap: 32px;
		margin-bottom: 32px;

		& > div {
			height: 100%;
			padding: 0;
		}

		& > div:first-child {
			width: 27%;
			min-width: 280px;
		}

		& > div:last-child {
			width: 100%;
		}

		&:last-of-type {
			margin-bottom: 16px;
		}
	`;

	export const Dragger = styled(DraggerAntd)`
		&.ant-upload-wrapper {
			.ant-upload-list {
				.ant-upload-list-item {
					border: 1px solid ${COLORS.gray};
					padding: 10px;
					height: auto;
					transition: all 0.2s ease;
					&:hover {
						background-color: transparent;
						box-shadow: 0px 3px 5px 0px ${COLORS.gray};
						cursor: pointer;
					}
					.ant-upload-list-item-actions {
						display: flex;
					}
					.ant-upload-icon {
						svg {
							font-size: 24px;
						}
					}
					.ant-upload-list-item-actions {
						.ant-btn {
							margin: 0 5px;
						}
					}
					.ant-upload-list-item-action {
						opacity: 1;
						color: ${COLORS.black};

						svg {
							font-size: 24px;
						}
					}
					.ant-upload-list-item-name {
						color: ${COLORS.black};
						font-size: 16px;
					}
				}
			}
		}
	`;

	export const ValidationError = styled.div`
		color: ${COLORS.red};
		margin-bottom: 10px;
		font-size: 14px;
	`;
}
