import React, { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
	Calendar,
	DateFormat,
	DateLocalizer,
	momentLocalizer,
	type View,
	Views,
} from 'react-big-calendar';
import { connect } from 'react-redux';

import { Input, message, Popover } from 'antd';
import moment from 'moment';
import 'moment-timezone';

import Box from 'components/Box';
import Container from 'components/Container';
import { useMount } from 'hooks';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { paletteColors } from 'theme/colors';
import { RequestsEnum } from 'types';

import { atsDucks } from '../../ducks';

import InterviewCalendarCustomEvent from './InterviewCalendarCustomEvent';
import { InterviewCalendarCustomToolbar } from './InterviewCalendarCustomToolbar/InterviewCalendarCustomToolbar';
import { CalendarColors } from './InterviewCandidateCalendar.constants';
import { generateDateParams } from './InterviewCandidateCalendar.helper';
import {
	CalendarEventType,
	ColorMappingType,
	DateType,
	InterviewCandidateCalendarProps,
} from './InterviewCandidateCalendar.types';
import { Styled } from './InterviewCandidatesCalendar.styled';

import 'react-big-calendar/lib/css/react-big-calendar.css';

const defaultTZ = moment.tz.guess();

const getStartEndDate = (date: Date, view: View) => {
	const rangeDate = {
		[Views.MONTH]: {
			start: moment(date).startOf('month').toDate(),
			end: moment(date).endOf('month').toDate(),
		},
		[Views.WEEK]: [moment(date).startOf('week').toDate(), moment(date).endOf('week').toDate()],
		[Views.DAY]: [moment(date).toDate()],
	} as never;

	return rangeDate && rangeDate[view];
};

const baseURL = process.env.REACT_APP_API_URL;

const InterviewCandidateCalendar: FC<InterviewCandidateCalendarProps> = ({
	interviewList,
	getInterviewCalendarList,
	getVideoInterviewCount,
	resendAtsInterviewLink,
	user,
}) => {
	const [events, setEvents] = useState<CalendarEventType[] | []>([]);
	const [isInfoActive, setInfoActive] = useState<boolean>(false);
	const [calendarLink, setCalendarLink] = useState<string>('');

	const {
		defaultDate,
		defaultView,
		scrollToTime,
		views,
		formats,
		getNow,
		localizerDate,
		validateTypeView,
	} = useMemo(() => {
		moment.tz.setDefault(defaultTZ);

		return {
			defaultDate: moment().toDate(),
			defaultView: Views.WEEK,
			getNow: () => moment().toDate(),
			localizerDate: momentLocalizer(moment),
			formats: {
				dayFormat: (date: Date, culture: string, localizer: DateLocalizer): DateFormat =>
					localizer.format(date, 'ddd DD/MM', culture),
				dayHeaderFormat: (date: Date, culture: string, localizer: DateLocalizer): DateFormat =>
					localizer.format(date, 'dddd MMMM Do', culture),
				dayRangeHeaderFormat: (
					{ start, end }: DateType,
					culture: string,
					localizer: DateLocalizer,
				): DateFormat =>
					localizer.format(start, 'MMM ', culture) +
					localizer.format(start, 'D', culture) +
					' - ' +
					localizer.format(end, 'D', culture) +
					localizer.format(start, ', YYYY', culture),
				timeGutterFormat: (date: Date, culture: string, localizer: DateLocalizer): DateFormat =>
					localizer.format(date, 'HH:mm', culture),
			},
			scrollToTime: moment().toDate(),
			views: [Views.MONTH, Views.WEEK, Views.DAY],
			validateTypeView: (date: Date[] | DateType, view: View) => {
				let fromDate;
				let toDate;

				if (Array.isArray(date) && view === Views.DAY) {
					toDate = moment(date[0]?.toString()).add(1, 'days');
					fromDate = moment(date[0]?.toString()).subtract(1, 'days');
				} else if (Array.isArray(date) && view === Views.WEEK) {
					toDate = date?.[date?.length - 1];
					fromDate = date?.[0];
				} else if ('start' in date && view === Views.MONTH) {
					fromDate = date?.start;
					toDate = date?.end;
				} else {
					toDate = new Date();
					fromDate = new Date();
				}

				return {
					fromDate: moment(fromDate.toString())?.format('YYYY-MM-DD'),
					toDate: moment(toDate.toString())?.format('YYYY-MM-DD'),
				};
			},
		};
	}, []);

	const [currentDate, setDate] = useState<Date>(defaultDate);

	const handleInterviewResendLink = useCallback((id: number, candidate: string) => {
		resendAtsInterviewLink(id, candidate);
	}, []);

	const components = useMemo(
		() => ({
			event: (props: React.JSX.IntrinsicAttributes & { event: CalendarEventType }) => (
				<InterviewCalendarCustomEvent {...props} handleResendLink={handleInterviewResendLink} />
			),
			toolbar: InterviewCalendarCustomToolbar,
		}),
		[interviewList],
	);

	const handleNavigate = useCallback(
		(date: Date, view: View) => {
			const currentRange = getStartEndDate(date, view);
			const { fromDate, toDate } = currentRange && validateTypeView(currentRange, view);
			setDate(date);

			if (fromDate && toDate) {
				getInterviewCalendarList({ fromDate, toDate });
				getVideoInterviewCount(generateDateParams({ fromDate, toDate }));
			}
		},
		[currentDate, setDate, getInterviewCalendarList, getVideoInterviewCount],
	);

	const handleCalendarChange = useCallback((date: Date[] | DateType, view: View) => {
		const { fromDate, toDate } = validateTypeView(date, view);

		if (fromDate && toDate) {
			getInterviewCalendarList({ fromDate, toDate });
			getVideoInterviewCount(generateDateParams({ fromDate, toDate }));
		}
	}, []);

	const eventPropGetter = useCallback(
		(event: CalendarEventType) => ({
			style: {
				backgroundColor: event?.colors?.bgColor || paletteColors.lightBlue,
				color: event?.colors?.color || paletteColors.blue,
				border: 'none',
			},
		}),
		[],
	);

	const handleInfoActive = () => {
		setInfoActive((prevState) => !prevState);
	};

	const handleCopyLink = () => {
		if (calendarLink) {
			navigator.clipboard.writeText(calendarLink);
			message.success('Calendar link copied');
		}
	};

	useEffect(() => {
		const colorMapping = {} as ColorMappingType;
		// @ts-ignore
		const uniqueJobIds = [...new Set(interviewList?.map((item) => item.jobId))];
		uniqueJobIds?.forEach((jobId, index) => {
			colorMapping[jobId] = CalendarColors[index % CalendarColors.length];
		});

		const filteredEvents = interviewList?.map((i) => ({
			id: i.id,
			candidateId: i.candidateApplicationId,
			jobTitle: i.jobTitle,
			jobId: i.jobId,
			guests: i.guests,
			start: moment(i.startDate).toDate(),
			end: moment(i.endDate).toDate(),
			desc: i.information,
			colors: (i.jobId && colorMapping[i.jobId]) || null,
			type: i.type,
		}));

		setEvents(filteredEvents);
	}, [interviewList, currentDate]);

	const interviewListIsIrrelevant = 
		useMemo(() => {
        	if (!interviewList?.length) return true;
        	const firstStartDate = new Date(interviewList[0].startDate);
			const startOfTheWeek = moment(defaultDate).startOf('week').toDate();
			const endOfTheWeek = moment(defaultDate).endOf('week').toDate();
         
			return !(firstStartDate >= startOfTheWeek && firstStartDate <= endOfTheWeek);
    	}, [interviewList, defaultDate]);

	useMount(() => {
		const datesObject = {
			fromDate: moment(defaultDate).startOf('week').format('YYYY-MM-DD'),
			toDate: moment(defaultDate).endOf('week').format('YYYY-MM-DD'),
		};
		if (interviewListIsIrrelevant) {
			getInterviewCalendarList(datesObject);
		}
		getVideoInterviewCount(generateDateParams(datesObject)); // retrieve video count because it was erased on unmount from the store
	});

	useEffect(() => {
		const link =
			user?.calendarUuid && `${baseURL}${RequestsEnum.CalendarLinkOpen}/${user?.calendarUuid}`;
		link && setCalendarLink(link);
	}, [user?.calendarUuid]);

	return (
		<Styled.Root>
			<Container noSpaces fullWidth>
				<Box>
					<Calendar
						date={currentDate}
						defaultDate={defaultDate}
						defaultView={defaultView}
						localizer={localizerDate}
						components={components}
						events={events}
						eventPropGetter={eventPropGetter}
						getNow={getNow}
						onNavigate={(date, view) => handleNavigate(date, view)}
						onRangeChange={(range, view) => view && handleCalendarChange(range, view)}
						scrollToTime={scrollToTime}
						formats={formats}
						views={views}
						startAccessor='start'
						endAccessor='end'
						style={{ height: 700, minHeight: '100%', maxHeight: 'calc(100vh - 284px)' }}
						step={30}
						popup
						showMultiDayTimes
					/>
				</Box>
				<Styled.LinkPanel>
					<Styled.InputWrapper>
						<label>
							<span>Subscribe to Calendar (ical/ics)</span>
							<Popover
								content={
									<p>
										If you share this link the recipient will be able to view <br /> all entries on
										your calendar, including restricted jobs
									</p>
								}
								trigger='click'
								placement='top'
								open={isInfoActive}
								onOpenChange={handleInfoActive}
								mouseLeaveDelay={200}
							>
								<Styled.ButtonInfo type='default'>i</Styled.ButtonInfo>
							</Popover>
						</label>
						<Input value={calendarLink} onClick={handleCopyLink} autoComplete='off' readOnly />
					</Styled.InputWrapper>
				</Styled.LinkPanel>
			</Container>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		interviewList: atsDucks.atsSelectors.getInterviewCalendarList(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
		user: unregisteredDucks.unregisteredSelectors.getUser(state),
	}),
	{
		getInterviewCalendarList: atsDucks.atsActions.getAtsInterviewCalendarListRequested,
		getVideoInterviewCount: atsDucks.atsActions.getAtsVideoInterviewCountRequested,
		resendAtsInterviewLink: atsDucks.atsActions.resendAtsInterviewLinkRequested,
	},
)(InterviewCandidateCalendar);
