import { Select as SelectComponent } from 'antd';
import styled from 'styled-components/macro';

import { COLORS, device } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		margin-bottom: 24px;
		background-color: ${COLORS.white};
		padding: 24px;
		border-radius: 6px;
	`;

	export const Head = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
	`;

	export const Title = styled.h3`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.22px;
	`;

	export const Content = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media ${device.large} {
			flex-direction: column;
		}
	`;

	export const Select = styled(SelectComponent)`
		&.ant-select {
			position: relative;
			height: 40px;
			min-width: 110px;

			.ant-select-selector {
				position: relative;
				height: 40px;
				border: 1px solid ${COLORS.borderColor};
				background-color: ${COLORS.lightGray3};
				box-shadow: ${COLORS.elevationShadow1};

				.ant-select-selection-item {
					font-size: 14px;
					font-weight: 700;
					line-height: 38px;
					color: ${COLORS.darkGray2};
				}
			}
		}
	`;
}
