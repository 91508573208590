import Cookies from 'js-cookie';

import { patch, get, post } from 'api/axiosInstance';
import { RequestsEnum, CookiesType } from 'types';
import { getTableParams } from 'utils/helpers';

export const getUnappliedCandidateJobs = async (candidateAppId) =>
	get(`${RequestsEnum.AtsJobUnappliedCandidate}/${candidateAppId}`);

export const getApplicationAttachment = async (url) => {
	const token = Cookies.get(CookiesType.JWT);

	const options = {
		headers: {
			'X-Authorization': `Bearer ${token}`,
		},
	};

	try {
		const file = await fetch(url, options).then((res) => res?.blob());

		return file;
	} catch (e) {
		console.error('error with application attachments downloading', e);
	}
};

export const assignCandidateToVacancy = async (data) => post(RequestsEnum.AtsCandidateAssign, data);

export const assignCandidateToVacancyDatabase = async (data) =>
	post(RequestsEnum.AtsCandidateDatabaseAssign, data);

export const assignGravityCandidateToVacancy = async (data) => {
	return patch(`${RequestsEnum.AtsCandidateGravity}`, data);
};

export const updateCandidatesRank = async ({ candidateAppId, rank }) =>
	patch(`${RequestsEnum.CandidateApplication}/${candidateAppId}${RequestsEnum.Rank}`, {
		value: rank,
	});

export const getClientContextCandidates = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.BackofficeClientContextCandidate}${queryParams}`);
};

export const getCandidateById = async (id) => get(`${RequestsEnum.CandidateApplication}/${id}`);

export const getCandidateNotesById = async (id) =>
	get(`${RequestsEnum.CandidateApplication}/${id}/note`);

export const addNoteToCandidate = async ({ candidateAppId, text }) =>
	post(`${RequestsEnum.CandidateApplication}/${candidateAppId}${RequestsEnum.Note}`, {
		text,
	});

export const getCandidateApplicationInterviewById = async (id) =>
	get(`${RequestsEnum.AtsCandidateApplicationInterview}/${id}`);

export const getAvailableStatuses = async () => get('/candidate/state');

export const updateApplicationStatus = async (applicationId, state) =>
	patch(`/candidate/${applicationId}/state`, { state }, { 'X-Hide-Alert-Error': true });

export const getCandidatesSMSInformation = async (candidatesId) => {
	const paramsList = candidatesId.map((id) => `candidateId=${id}`).join('&');

	return get(`${RequestsEnum.ATSCandidateApplicationSMS}?${paramsList}`);
};

export const updateCandidatePhoneNumber = async ({ candidateAppId, phoneNumber }) =>
	patch(`${RequestsEnum.ATSCandidateApplication}/${candidateAppId}${RequestsEnum.Phone}`, {
		phoneNumber,
	});

export const sendSMSToCandidate = async (data) =>
	post(RequestsEnum.ATSCandidateApplicationSMS, data);

export const getAtsCandidatesDatabase = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.AtsCandidateDatabase}${queryParams}`);
};

export const getAtsCandidateDatabaseById = async (candidateApplicationId) => {
	return get(`${RequestsEnum.AtsCandidateDatabase}/${candidateApplicationId}`);
};

export const removeCandidateFromDatabase = async (candidateApplicationId, uuid) => {
	return post(`${RequestsEnum.OpenCandidateDatabaseRemove}/${candidateApplicationId}?pwd=${uuid}`);
};

export const showHideCandidateInDatabase = async ({ candidateApplicationId, data }) =>
	post(
		`${RequestsEnum.AtsCandidateDatabase}/${candidateApplicationId}${RequestsEnum.ShowAction}`,
		data,
	);

export const getAtsAllCandidateApplicationsByJobId = async (id) =>
	get(`${RequestsEnum.ATSCandidateApplicationJob}/${id}`);

const candidateApplicationService = {
	getApplicationAttachment,
	assignCandidateToVacancy,
	getUnappliedCandidateJobs,
	getCandidateApplicationInterviewById,
	getClientContextCandidates,
	getCandidateById,
	getCandidateNotesById,
	getAvailableStatuses,
	updateApplicationStatus,
	updateCandidatesRank,
	addNoteToCandidate,
	getCandidatesSMSInformation,
	updateCandidatePhoneNumber,
	sendSMSToCandidate,
	getAtsCandidatesDatabase,
	getAtsCandidateDatabaseById,
	showHideCandidateInDatabase,
	assignCandidateToVacancyDatabase,
	getAtsAllCandidateApplicationsByJobId,
	assignGravityCandidateToVacancy,
};

export default candidateApplicationService;
