import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Routes } from 'types';

import PageNavTitle from '../../../Common/components/PageNavTitle';
import CreateEditInvoice from '../../containers/CreateEditInvoice';

export const PostInvoicePage = () => {
	const { invoiceId, clientId } = useParams();
	const { pathname } = useLocation();
	const isEditMode = pathname.includes(Routes.InvoiceEdit);

	const backToInvoicesPath = clientId
		? `${Routes.BOClientContext}/${clientId}${Routes.Invoices}`
		: `${Routes.BackOffice}${Routes.Invoices}`;

	return (
		<>
			<PageNavTitle
				title={isEditMode ? 'Edit an Invoice' : 'Create a New Invoice'}
				navigationLink={backToInvoicesPath}
			/>
			<CreateEditInvoice invoiceId={invoiceId} isEditMode={isEditMode} clientContextId={clientId} />
		</>
	);
};
