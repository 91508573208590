import { useEffect, useState } from 'react';

import { MediaPermissionType } from 'modules/Common/types';


export const useMediaPermission = (constraint: string): MediaPermissionType => {
	const [permissionAvailable, setPermission] = useState<MediaPermissionType>({
		value: false, error: {
			message: 'Waiting for permission...'
		}
	});

	const getPermission = (item: string): void => {
		try {
			navigator.mediaDevices
				.getUserMedia({ [item]: true })
				.then(() =>
					setPermission({ value: true, error: {
						message: ''
					} })
				)
				.catch((error) => 
					setPermission({ value: false, error })
				);
		} catch (error) {
			setPermission({ value: false, error: {
				message: 'err'
			} });
		}
	};

	useEffect(() => {
		getPermission(constraint);
	}, []);

	return permissionAvailable;
};

export const useAudioPermission = () => useMediaPermission('audio');

export const useVideoPermission = () => useMediaPermission('video');