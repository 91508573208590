import { all, fork } from 'redux-saga/effects';

import ats from '../modules/ATS';
import backOffice from '../modules/BackOffice';
import Common from '../modules/Common';
import hrModule from '../modules/HR';
import unregistered from '../modules/Unregistered';

export default function* rootSaga() {
	yield all([
		fork(unregistered.unregisteredSaga),
		fork(backOffice.backOfficeSaga),
		fork(Common.commonSaga),
		fork(ats.atsSaga),
		fork(hrModule.hrSaga),
	]);
}
