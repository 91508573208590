import React, { FC, ReactNode, useRef } from 'react';
import { useDrop } from 'react-dnd';

import { CandidateStatusType } from 'modules/Common/types';

type KanbanColumnProps = {
	updateCandidateStatus: CandidateStatusType;
	children: ReactNode | ReactNode[];
	status: string;
};

const KanbanColumn: FC<KanbanColumnProps> = ({ status, updateCandidateStatus, children }) => {
	const ref = useRef(null);
	const [, drop] = useDrop({
		accept: 'card',
		drop(item: { id: string }) {
			updateCandidateStatus([+item.id], status);
		},
	});
	drop(ref);

	return <div ref={ref}> {children}</div>;
};

export default KanbanColumn;
