import styled from 'styled-components/macro';

export namespace Styled {
	export const Head = styled.section`
		display: flex;
		justify-content: space-between;
	`;

	export const Content = styled.section`
		position: relative;
	`;
}
