import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ButtonTypes } from 'components/Button/Button.types';
import Overlay from 'components/Overlay';
import Spinner from 'components/Spinner';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import QuestionnaireList from 'modules/ATS/containers/ATSQuestionnaireList';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { GenericType, Routes, SubscriptionPlansType } from 'types';

import { atsDucks } from '../../ducks';

import { Styled } from './ATSQuestionnaireListPage.styled';

type ATSQuestionnaireListPageProps = {
	subscriptionPlans: SubscriptionPlansType;
	loading: GenericType;
};

const ATSQuestionnaireListPage: FC<ATSQuestionnaireListPageProps> = ({
	subscriptionPlans,
	loading,
}) => {
	const navigate = useNavigate();
	const { isPremiumPlan } = subscriptionPlans || {};

	if (loading?.getClientSubscriptionLoad) {
		return <Spinner fixed />;
	}

	return (
		<>
			<Styled.Head>
				<PageNavTitle title='Video Questionnaire Setup' />
				{isPremiumPlan && (
					<ATSThemedButton
						buttonType={ButtonTypes.primary}
						onClick={() => navigate(`${Routes.ATS}${Routes.QuestionnairesCreate}`)}
					>
						Create New
					</ATSThemedButton>
				)}
			</Styled.Head>
			<Styled.Content>
				<QuestionnaireList />
			</Styled.Content>
			{!isPremiumPlan && (
				<Overlay
					title='This feature requires a pro membership, upgrade or try free here'
					buttonTitle='Upgrade Plan'
				/>
			)}
		</>
	);
};

export default connect(
	(state) => ({
		subscriptionPlans: atsDucks.atsSelectors.getSubscriptionPlans(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{},
)(ATSQuestionnaireListPage);
