import styled from 'styled-components/macro';

interface IStyles {
	color: string;
	bgColor: string;
}

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;

		h2 {
			margin-bottom: 16px;
		}
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
		margin-bottom: 32px;
		gap: 16px;
	`;

	export const FormHead = styled.div`
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		margin-bottom: 24px;
	`;

	export const AdditionalRow = styled.div`
		margin-top: 24px;
	`;

	export const SelectWrapper = styled.div<IStyles>`
		position: absolute;
		top: 0;
		right: 0;

		.ant-select {
			position: relative;

			.ant-select-selector {
				position: relative;

				${({ color, bgColor }) => `
					border: 1px solid ${color};
					background-color: ${bgColor};
				`}
			}
		}
	`;
}
