import React, { type FC, useState } from 'react';

import TableComponent from 'components/Table';
import { IUser } from 'modules/Common/types';
import { propsFilter } from 'utils/helpers';

import { DEFAULT_TABLE_ITEMS } from '../../CandidatesApplication/CandidatesApplication.constants';
import { Styled } from '../../CandidatesApplication/CandidatesApplication.styled';
import { columns } from '../InterveiwCandidatesList.entities';
import { INTERVIEW_CANDIDATE_FIELDS } from '../InterviewCandidatesList.constants';
import { InterviewsList } from '../InterviewCandidatesList.types';

type CandidatesApplicationTableProps = {
	data: InterviewsList[];
	jobId: number;
	user: IUser;
	resendInterviewLink: (id: number, candidate: string) => void;
	handleCandidateRank: (
		id: string | number,
		rank: number,
		jobId?: string | number,
		cb?: () => void,
	) => void;
};

const InterviewCandidatesListTable: FC<CandidatesApplicationTableProps> = ({
	data,
	jobId,
	user,
	resendInterviewLink,
	handleCandidateRank,
}) => {
	const [displaySize, setDisplaySize] = useState(DEFAULT_TABLE_ITEMS);

	const filteredData = propsFilter(data, INTERVIEW_CANDIDATE_FIELDS);
	const columnsData = columns(jobId, user, resendInterviewLink, handleCandidateRank);

	const handleShowButton = () => {
		setDisplaySize((prevState) => prevState + DEFAULT_TABLE_ITEMS);
	};

	return (
		<Styled.Root>
			<TableComponent
				data={filteredData.slice(0, displaySize)}
				columns={columnsData}
				pagination={false}
			/>
			{data?.length > displaySize && (
				<Styled.ShowButton onClick={handleShowButton} style={{ marginTop: 16 }}>
					{`View ${data.length - displaySize} more`}
				</Styled.ShowButton>
			)}
		</Styled.Root>
	);
};

export default InterviewCandidatesListTable;
