export const toolbarBuilder = (options: string[] = []) => ({
	options: ['inline', 'list', 'textAlign', 'link', ...options],
	inline: {
		inDropdown: false,
		className: 'editor-item',
		component: undefined,
		dropdownClassName: undefined,
		options: ['bold', 'italic', 'underline', 'strikethrough'],
	},
	list: {
		inDropdown: false,
		className: 'editor-item',
		component: undefined,
		dropdownClassName: undefined,
		options: ['ordered', 'unordered', 'indent', 'outdent'],
	},
	textAlign: {
		inDropdown: false,
		className: 'editor-item',
		component: undefined,
		dropdownClassName: undefined,
		options: ['left', 'center', 'right', 'justify'],
	},
	link: {
		inDropdown: false,
		className: 'editor-item',
		component: undefined,
		popupClassName: undefined,
		dropdownClassName: undefined,
		showOpenOptionOnHover: true,
		defaultTargetOption: '_self',
		options: ['link', 'unlink'],
		linkCallback: undefined,
	},
	image: {
		alignmentEnabled: false,
	},
});
