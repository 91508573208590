import { get } from 'api/axiosInstance';
import { RequestsEnum } from 'types';

export const getATSCandidateStatistics = async (timeFrameInDays) => {
	const params = `?timeframeInDays=${timeFrameInDays || 30}`;

	return get(`${RequestsEnum.StatisticsAtsCandidates}${params}`);
};

export const getATSCandidateStatisticsMonth = async (month) => {
	const params = `?date=${month}`;

	return get(`${RequestsEnum.StatisticsAtsCandidatesMonth}${params}`);
};

export const getBOCandidateStatistics = async ({ timeFrameInDays, client }) => {
	const params = `?timeframeInDays=${timeFrameInDays || 30}&client=${client}`;

	return get(`${RequestsEnum.StatisticsBOClientContextCandidates}${params}`);
};

export const getATSLastMonthApplications = async () => {
	return get(`${RequestsEnum.StatisticsApplicationsLastMonth}`);
};

export const getATSYearApplications = async () => {
	return get(`${RequestsEnum.StatisticsApplicationsYear}`);
};

export const getATSMonthApplications = async (month) => {
	return get(`${RequestsEnum.StatisticsApplicationsMonth}?date=${month}`);
};

export const getTimeToHireYear = async () => {
	return get(`${RequestsEnum.StatisticsTimeToHireYear}`);
};

export const getTimeToHireMonth = async (month) => {
	return get(`${RequestsEnum.StatisticsTimeToHireMonth}?date=${month}`);
};

export const getBOCCMonthStatistic = async (client) => {
	return get(`${RequestsEnum.StatisticsBOClientContext}/applications/month?client=${client}`);
};

export const getATSApplicationsStatisticsForLastMonth = async () =>
	get(RequestsEnum.StatisticsAtsDashboardApplicationsMonth);

export const getATSCandidatesTimePerStage = async (yearMonth) => {
	const endpoint = yearMonth
		? `${RequestsEnum.StatisticsAtsCandidatesTimePerStateMonth}?date=${yearMonth}`
		: RequestsEnum.StatisticsAtsCandidatesTimePerStateYear;

	return get(endpoint);
};

export const getATSStatisticsForAcceptedOffers = async (yearMonth) => {
	const endpoint = yearMonth
		? `${RequestsEnum.StatisticsAtsAcceptedOffersMonth}?date=${yearMonth}`
		: RequestsEnum.StatisticsAtsAcceptedOffersYear;

	return get(endpoint);
};

export const getATSStatisticsApplicationSources = async (yearMonth) => {
	const endpoint = yearMonth
		? `${RequestsEnum.StatisticsAtsSourceMonth}?date=${yearMonth}`
		: RequestsEnum.StatisticsAtsSourceYear;

	return get(endpoint);
};

const statisticsService = {
	getATSCandidateStatistics,
	getATSLastMonthApplications,
	getBOCandidateStatistics,
	getATSApplicationsStatisticsForLastMonth,
	getATSCandidatesTimePerStage,
	getATSStatisticsForAcceptedOffers,
	getBOCCMonthStatistic,
	getATSYearApplications,
	getATSMonthApplications,
	getTimeToHireYear,
	getTimeToHireMonth,
	getATSStatisticsApplicationSources,
	getATSCandidateStatisticsMonth,
};

export default statisticsService;
