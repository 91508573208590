import React, { type FC } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import ContractView from 'modules/Common/containers/ContractView';
import { FinancePdf } from 'modules/Common/containers/FinancePdf';
import { commonDucks } from 'modules/Common/ducks';
import { IContractValues } from 'modules/Common/types';
import { Routes, GenericType } from 'types';

import { Styled } from './ContractViewPage.styled';

interface ContractViewPageProps {
	contract: IContractValues;
	creditsFields: GenericType[];
}

const ContractViewPage: FC<ContractViewPageProps> = ({ contract, creditsFields }) => {
	const { contractId, clientId } = useParams();
	const { state } = useLocation();

	const contractTitle = state?.contractName;
	const navPath = clientId
		? `${Routes.BOClientContext}/${clientId}${Routes.Contracts}`
		: `${Routes.BackOffice}${Routes.Contracts}`;

	return (
		<>
			<Styled.Head>
				<PageNavTitle title={contractTitle ?? 'Contract details'} navigationLink={navPath} />
				{contract && creditsFields && (
					<Styled.PdfButtonWrapper>
						<PDFDownloadLink
							document={<FinancePdf contract={contract} creditsFields={creditsFields} />}
							fileName={`${contract?.clientCode ?? contract?.btoReference}-contract.pdf`}
						>
							{({ loading }) => {
								const buttonText = loading ? 'Loading document...' : 'Download PDF';

								return <Button buttonType={ButtonTypes.secondary}>{buttonText}</Button>;
							}}
						</PDFDownloadLink>
					</Styled.PdfButtonWrapper>
				)}
			</Styled.Head>
			<Styled.Content>
				<ContractView contractId={contractId} clientContextId={clientId} />
			</Styled.Content>
		</>
	);
};

export default connect(
	(state) => ({
		contract: commonDucks.commonSelectors.getContractByIdDetails(state),
		creditsFields: commonDucks.commonSelectors.getCreditFields(state),
	}),
	{},
)(ContractViewPage);
