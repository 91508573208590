import React, { type FC, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { backOfficeDucks } from 'modules/BackOffice/ducks';
import ChangePasswordContainer from 'modules/Common/containers/ChangePasswordContainer';
import { IChangePasswordValues } from 'modules/Common/types';
import { GenericType } from 'types';

import { Styled } from './ChangePassword.styled';

type ChangePasswordProps = {
	changeUserPassword: (values: Partial<IChangePasswordValues>, cb: () => void) => void;
	loading: GenericType;
};

const ChangePassword: FC<ChangePasswordProps> = ({ changeUserPassword, loading }) => {
	const navigate = useNavigate();

	const handleSaveUserPassword = useCallback((values: IChangePasswordValues) => {
		changeUserPassword(values, () => navigate(0));
	}, []);

	return (
		<Styled.Root>
			<ChangePasswordContainer
				changeUserPassword={handleSaveUserPassword}
				loading={!!loading?.changeUserPasswordLoad}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
	}),
	{
		changeUserPassword: backOfficeDucks.backOfficeActions.changeUserPasswordRequested,
	},
)(ChangePassword);
