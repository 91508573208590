import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function ArrowDown({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g id='State=Chevron Down'>
				<path
					id='icon'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M3.03717 7.17993C3.46574 6.74935 4.16222 6.74773 4.5928 7.1763L12.0172 14.5661L19.4052 7.17812C19.8348 6.74854 20.5313 6.74854 20.9608 7.17812C21.3904 7.6077 21.3904 8.30418 20.9608 8.73376L12.7968 16.8978C12.3679 17.3267 11.6728 17.3275 11.243 16.8996L3.04079 8.73556C2.61022 8.30699 2.60859 7.61051 3.03717 7.17993Z'
					fill={fill}
				/>
			</g>
		</svg>
	);
}

export default memo(ArrowDown);
