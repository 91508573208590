import { Rate } from 'antd';
import styled from 'styled-components/macro';

export namespace Styled {
	export const RankingStars = styled(Rate)`
		flex: 0 0 auto;
		min-width: 170px;

		&.ant-rate {
			display: flex;

			li.ant-rate-star {
				margin-inline-end: 14px;
			}
		}
	`;
}
