const colors = {
	black: '#1E1E20',
	darkGray4: '#2A2A2C',
	darkGray3: '#39393B',
	darkGray2: '#5C5C60',
	darkGray1: '#9D9DA1',
	gray: '#C4C4C8',
	ligtGray1: '#DEDDE1',
	ligtGray2: '#EBEBED',
	ligtGray3: '#F8F8F8',
	white: '#FFFFFF',
	darkBlue1: '#001699',
	blue: '#314EFF',
	lightBlue1: '#99A7FF',
	green: '#52C41A',
	red: '#FF4D4F',
	orange: '#FAAD14',
	mintGreen: '#3EEBD0',
	lightBlue2: '#7A8DFF',
	darkBlue2: '#212B3C',
};

export const ATS_DEFAULT_COLORS = {
	textColour: '#1E1E20',
	headerBackground: '#540AD1',
	highlightColour: '#314EFF',
	highlightColourAlternate: '#262834',
	accentColour: '#99A7FF',
};

export default colors;
