import React, { type FC, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import TextArea from 'antd/es/input/TextArea';

import Box from 'components/Box';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import FormSwitch from 'components/FormSwitch';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import { atsDucks } from 'modules/ATS/ducks';
import AutoFilledInfo from 'modules/Common/containers/EmailTemplates/AutoFilledInfo/AutoFilledInfo';
import { commonDucks } from 'modules/Common/ducks';
import { ApplicationMessagesType, EmailVariablesEnum } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { UserRolesType } from 'types';
import { getBooleanOrDefault } from 'utils/helpers';
import { composeValidators, requiredFieldValidator, textValidator } from 'utils/validators';

import { SettingsPanelMenuList } from '../ATSSettings.types';

import { Styled } from './DefaultMessages.styled';

export type CompanyDetailsProps = {
	roles: UserRolesType;
	client: number;
	loading: boolean;
	defaultMessages: ApplicationMessagesType;
	getClientATSApplicationMessagesByClientId: (id: number) => void;
	updateClientATSApplicationMessages: (values: ApplicationMessagesType) => void;
};

const DefaultMessages: FC<CompanyDetailsProps> = ({
	roles,
	client,
	loading,
	defaultMessages,
	getClientATSApplicationMessagesByClientId,
	updateClientATSApplicationMessages,
}) => {
	const isEditMode = roles?.isAtsSuperUser;

	const initialValues = {
		thankyouMessage: defaultMessages?.thankyouMessage || '',
		rejectionMessage: defaultMessages?.rejectionMessage || '',
		sendThankyouLetter: getBooleanOrDefault(defaultMessages?.sendThankyouLetter, true),
		sendRejectionLetter: getBooleanOrDefault(defaultMessages?.sendRejectionLetter, true),
	};

	const onSubmit = async (values: ApplicationMessagesType) => {
		if (values) {
			updateClientATSApplicationMessages({
				client,
				...values,
			});
		}
	};

	useEffect(() => {
		client &&
			(!defaultMessages?.thankyouMessage || !defaultMessages?.rejectionMessage) &&
			getClientATSApplicationMessagesByClientId(client);
	}, [client]);

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				render={({ handleSubmit, valid }) => (
					<form onSubmit={handleSubmit}>
						<Box noSpaces={!isEditMode}>
							<h2>{SettingsPanelMenuList.DefaultMessages}</h2>
							<Styled.MessagesBox>
								<h3>New Application</h3>
								<p>
									A standard thank you message will be sent when applicants have applied for a role.
								</p>
								<Field name={'sendThankyouLetter'} initialValue={initialValues.sendThankyouLetter}>
									{({ input }) => (
										<FormSwitch {...input} title='Send message' disabled={!isEditMode} />
									)}
								</Field>
								<div style={{ marginBottom: '24px' }} />

								<AutoFilledInfo
									emailVariablesList={[
										EmailVariablesEnum.CANDIDATE_NAME,
										EmailVariablesEnum.COMPANY_NAME,
									]}
								/>
								<Field
									name='thankyouMessage'
									validate={composeValidators(requiredFieldValidator, textValidator)}
								>
									{({ input, meta }) => (
										<FieldWrapper
											name='thankyouMessage'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<TextArea
												{...input}
												placeholder='Enter message here'
												autoSize={{ minRows: 10 }}
												readOnly={!isEditMode}
											/>
										</FieldWrapper>
									)}
								</Field>
							</Styled.MessagesBox>
							<Styled.MessagesBox>
								<h3>Rejections</h3>
								<p>
									A standard message will be sent when applicants have been rejected, you can edit
									the company’s default outgoing message below. Alternatively, you can add a
									different default message to each job under the job’s detail page.
								</p>
								<Field
									name={'sendRejectionLetter'}
									initialValue={initialValues.sendRejectionLetter}
									style={{ marginBottom: '24px' }}
								>
									{({ input }) => (
										<FormSwitch {...input} title='Send message' disabled={!isEditMode} />
									)}
								</Field>
								<div style={{ marginBottom: '24px' }} />

								<AutoFilledInfo
									emailVariablesList={[
										EmailVariablesEnum.CANDIDATE_NAME,
										EmailVariablesEnum.COMPANY_NAME,
									]}
								/>
								<Field
									name='rejectionMessage'
									validate={composeValidators(requiredFieldValidator, textValidator)}
								>
									{({ input, meta }) => (
										<FieldWrapper
											name='rejectionMessage'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<TextArea
												{...input}
												placeholder='Enter message here'
												autoSize={{ minRows: 10 }}
												readOnly={!isEditMode}
											/>
										</FieldWrapper>
									)}
								</Field>
							</Styled.MessagesBox>
						</Box>

						{isEditMode && (
							<Styled.ButtonBox>
								<ATSThemedButton
									type='submit'
									buttonType={ButtonTypes.primary}
									disabled={!valid || loading}
									loading={loading}
								>
									Save
								</ATSThemedButton>
							</Styled.ButtonBox>
						)}
					</form>
				)}
			></Form>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		client: commonDucks.commonSelectors.getClientData(state)?.id,
		defaultMessages: atsDucks.atsSelectors.getSettingsDefaultMessages(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state).updateClientApplicationMessagesLoad,
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
	}),
	{
		getClientATSApplicationMessagesByClientId:
			atsDucks.atsActions.getClientATSApplicationMessagesByClientId,
		updateClientATSApplicationMessages: atsDucks.atsActions.updateClientATSApplicationMessages,
	},
)(DefaultMessages);
