import styled from 'styled-components/macro';

interface ISearchProps {
	width?: string;
}

export namespace Styled {
	export const Root = styled.div`
		display: flex;
	`;

	export const Search = styled.div<ISearchProps>`
		flex: 1 0 ${({ width }) => width ?? '400px'};
		max-width: ${({ width }) => width ?? '400px'};
	`;
	export const Filter = styled.div`
		display: flex;
		align-items: center;
	`;
	export const FilterLabel = styled.div`
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		padding-right: 8px;
		padding-left: 16px;
		white-space: nowrap;
	`;
}
