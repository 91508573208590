import { type FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import { message } from 'antd';
import { has } from 'lodash';

import { ATS_DEFAULT_COLORS } from 'common/colors';
import Container from 'components/Container';
import { Direction } from 'components/Container/Container.types';
import BlueTownSmallLogo from 'components/SVG/BlueTownSmallLogo';
import { usePollingEffect } from 'hooks/useEffects';
import Navigation from 'modules/ATS/components/Navigation';
import { AtsLayoutProvider } from 'modules/ATS/containers/ATSLayoutContext/ATSLayoutContext';
import { atsDucks } from 'modules/ATS/ducks';
import { WrapperFooter } from 'modules/Common/components/Footer';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from 'modules/Common/constants';
import { commonDucks } from 'modules/Common/ducks';
import {
	AtsNavigationMenuList,
	ClientBrandingDataType,
	IUser,
	InboxEmailThreadsType,
	SentEmailThreadsType,
	ICurrentSubscription,
} from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { COLORS } from 'theme';
import {
	ReactParent,
	Routes,
	SettingsRoutes,
	CountriesType,
	UserRolesType,
	UserTypesEnum,
	AtsRouteCountryMap,
	AppModes,
	SubscriptionPlansType,
} from 'types';
import { checkAllowedRoute } from 'utils/helpers';

import {
	FinanceUserMenuListData,
	FinanceUserProfileMenuListData,
	SuperUserMenuListData,
	SuperUserProfileMenuListData,
} from '../Navigation/Navigation.entities';

import { Styled } from './ATSLayout.styled';

const HiddenUSItems = [AtsNavigationMenuList.Credits];

type ATSLayoutProps = ReactParent & {
	userInfo: IUser;
	roles: UserRolesType;
	countries: CountriesType;
	currentSubscription: ICurrentSubscription;
	navigationProps?: {
		accentColour?: string;
		highlightColourAlternate?: string;
	};
	outstandingContracts?: number;
	emailThreads?: InboxEmailThreadsType | SentEmailThreadsType;
	logout: (cb: () => void) => void;
	clientId: string;
	getContractsByClientIdRequested: (props: {
		clientId: string;
		params?: { activeOnly: boolean };
	}) => void;
	getAtsEmailInboxRequested: ({
		page,
		size,
		isInbox,
		search,
	}: {
		page: number;
		size: number;
		isInbox: boolean;
		search?: string;
	}) => void;
	mode: AppModes;
	subscriptionPlans: SubscriptionPlansType;
	clientBrandingData: ClientBrandingDataType;
	isImpersonate: boolean;
};

const ATSLayout: FC<ATSLayoutProps> = ({
	children,
	navigationProps,
	userInfo,
	roles,
	countries,
	outstandingContracts,
	emailThreads,
	logout,
	clientId,
	currentSubscription,
	getContractsByClientIdRequested,
	getAtsEmailInboxRequested,
	mode,
	subscriptionPlans,
	clientBrandingData,
	isImpersonate,
}) => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const params = useParams();

	const { client, userType } = userInfo;
	const { isAtsSuperUser, isAtsStandardUser, isAtsFinanceUser } = roles;
	const { isUS, isUK } = countries || {};
	const [inboxUnreadMessagesCount, setInboxUnreadMessagesCount] = useState<number>(0);

	const isExtended = pathname !== SettingsRoutes.Branding;
	const isAtsBrandingPage = pathname === SettingsRoutes.Branding;

	const { accentColour, highlightColourAlternate } = navigationProps || {};

	const isAtsAllowedRoutesByCountry =
		pathname.includes(Routes.ATS) &&
		checkAllowedRoute(pathname, params, AtsRouteCountryMap, countries);

	const menuList =
		isAtsSuperUser || mode === AppModes.Preview || isAtsStandardUser
			? SuperUserMenuListData(inboxUnreadMessagesCount)
			: FinanceUserMenuListData(outstandingContracts);

	const profileMenuList =
		isAtsSuperUser || isAtsStandardUser
			? SuperUserProfileMenuListData(outstandingContracts)
			: FinanceUserProfileMenuListData();
	const logoLinkUrl = isAtsFinanceUser
		? `${Routes.ATS}${Routes.Credits}`
		: `${Routes.ATS}${Routes.Dashboard}`;

	const filteredMenuListItems =
		menuList?.filter((item) => {
			if (isUS) return !HiddenUSItems.includes(item.name);

			return true;
		}) || [];

	const checkOutstandingContracts = useCallback(() => {
		if (clientId && isUK) {
			getContractsByClientIdRequested({ clientId });
		}
	}, [clientId]);

	const handleLogout = useCallback(() => {
		// TODO implement ability redirect to exact subdomain
		const url =
			client?.subdomain && +userType === UserTypesEnum.ATS
				? `${Routes.Login}?subdomain=${client?.subdomain}`
				: Routes.Login;

		logout(() => navigate(url));
	}, [client?.subdomain, logout, navigate]);

	useEffect(() => {
		isUK && checkOutstandingContracts();
	}, [clientId, isUK]);

	usePollingEffect(
		() =>
			!(roles.isAtsFinanceUser || +userType === UserTypesEnum.BACKOFFICE) &&
			getAtsEmailInboxRequested({
				page: DEFAULT_CURRENT_PAGE,
				size: DEFAULT_PAGE_SIZE,
				isInbox: true,
			}),
		[roles.isAtsFinanceUser, userType],
		{ interval: 20000, stopPollingPaths: [`${Routes.ATS}${Routes.Messages}`] },
	);

	useEffect(() => {
		if (has(emailThreads, 'unreadMessagesCount')) {
			setInboxUnreadMessagesCount((emailThreads as InboxEmailThreadsType).unreadMessagesCount);
		}
	}, [emailThreads]);

	if (!pathname.includes(Routes.BackOffice) && !isAtsAllowedRoutesByCountry) {
		return (
			<Navigate
				to={Routes.Forbidden}
				state={
					roles.isAtsFinanceUser
						? `${Routes.ATS}${Routes.Credits}`
						: `${Routes.ATS}${Routes.Dashboard}`
				}
				replace
			/>
		);
	}

	const previewProps = {
		logoLinkUrl: '',
		menuList: filteredMenuListItems,
		profileMenuList: profileMenuList,
		onLogout: () => null,
		accentColour,
		highlightColourAlternate,
		isExtended: false,
		onMenuItemClick: (e) => {
			e.preventDefault();
		},
		isPreview: true,
	};
	const isPremium =
		mode === AppModes.Preview ? true : currentSubscription.premium || currentSubscription.trial;

	const handleHrModuleClick = () => {
		if (!isPremium) {
			message.warning('This feature requires a pro membership, upgrade or try free here');
		} else {
			window
				.open(`${window.location.origin}${Routes.HRModule}${Routes.Dashboard}`, '_blank')
				?.focus();
		}
	};

	return (
		<AtsLayoutProvider
			subscriptionPlans={subscriptionPlans}
			clientBrandingData={clientBrandingData}
		>
			<Styled.Root isImpersonating={isImpersonate}>
				<Container noSpaces direction={Direction.Row} fullWidth>
					<Navigation
						isPremium={isPremium}
						menuList={filteredMenuListItems}
						logoLinkUrl={logoLinkUrl}
						profileMenuList={profileMenuList}
						onHrModuleClick={handleHrModuleClick}
						onLogout={handleLogout}
						isImpersonating={isImpersonate}
						accentColour={isPremium ? accentColour : ATS_DEFAULT_COLORS.accentColour}
						highlightColourAlternate={
							isPremium ? highlightColourAlternate : ATS_DEFAULT_COLORS.highlightColourAlternate
						}
						isExtended={isExtended && !(mode === AppModes.Preview)}
						onMenuItemClick={checkOutstandingContracts}
						{...(mode === AppModes.Preview && previewProps)}
					/>
					<Styled.Main>
						<Container fullWidth={isAtsBrandingPage}>{children}</Container>
						<WrapperFooter styles={{ justifyContent: 'center', gap: '10px' }}>
							<span>Powered by Connect </span>
							<BlueTownSmallLogo fill={COLORS.darkGray2} />
						</WrapperFooter>
					</Styled.Main>
				</Container>
			</Styled.Root>
		</AtsLayoutProvider>
	);
};

export default connect(
	(state) => ({
		userInfo: unregisteredDucks.unregisteredSelectors.getUser(state),
		clientId: unregisteredDucks.unregisteredSelectors.getClientId(state),
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
		countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
		outstandingContracts: commonDucks.commonSelectors.getContractsOutstandingCount(state),
		emailThreads: atsDucks.atsSelectors.getEmailInbox(state),
		subscriptionPlans: atsDucks.atsSelectors.getSubscriptionPlans(state),
		clientBrandingData: atsDucks.atsSelectors.getClientAtsBrandingData(state),
	}),
	{
		logout: unregisteredDucks.unregisteredActions.logOut,
		getContractsByClientIdRequested: commonDucks.commonActions.getContractsByClientIdRequested,
		getAtsEmailInboxRequested: atsDucks.atsActions.getAtsEmailInboxRequested,
	},
)(ATSLayout);
