import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function MessageIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M7 11C7.82843 11 8.5 10.3284 8.5 9.5C8.5 8.67157 7.82843 8 7 8C6.17157 8 5.5 8.67157 5.5 9.5C5.5 10.3284 6.17157 11 7 11Z'
				fill={fill}
			/>
			<path
				d='M13.5 9.5C13.5 10.3284 12.8284 11 12 11C11.1716 11 10.5 10.3284 10.5 9.5C10.5 8.67157 11.1716 8 12 8C12.8284 8 13.5 8.67157 13.5 9.5Z'
				fill={fill}
			/>
			<path
				d='M17 11C17.8284 11 18.5 10.3284 18.5 9.5C18.5 8.67157 17.8284 8 17 8C16.1716 8 15.5 8.67157 15.5 9.5C15.5 10.3284 16.1716 11 17 11Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.4389 17L6.90514 21.082C6.27704 21.6475 5.27417 21.2272 5.23699 20.3828L5.08807 17H4C2.34315 17 1 15.6569 1 14V5C1 3.34315 2.34315 2 4 2H20C21.6569 2 23 3.34315 23 5V14C23 15.6569 21.6569 17 20 17H11.4389ZM7.14185 18.1777L7.00196 15H4C3.44772 15 3 14.5523 3 14V5C3 4.44772 3.44772 4 4 4H20C20.5523 4 21 4.44772 21 5V14C21 14.5523 20.5523 15 20 15H10.6712L7.14185 18.1777Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(MessageIcon);
