import React, { memo } from 'react';

import { highlightSearch } from './PanelMenu.helpers';
import { Styled } from './PanelMenu.styled';

type PanelMenuProps = {
	candidateList: {
		candidate: string;
		candidateApplicationId: number;
		unreadMessages: boolean;
	}[];
	currentCandidateThread: { candidate: string; candidateApplicationId: number } | undefined;
	paginationData: { pageSize: number; current: number; total: number };
	searchTerm: string;
	handleSelectCandidate: (value: number) => void;
	handlePagination: (page: number, size: number) => void;
};

const PanelMenu: React.FC<PanelMenuProps> = ({
	candidateList,
	currentCandidateThread,
	paginationData,
	searchTerm,
	handleSelectCandidate,
	handlePagination,
}) => {
	return (
		<Styled.Root>
			{!!candidateList?.length && (
				<Styled.MenuList>
					{candidateList?.map((item) => (
						<Styled.MenuListItem
							key={item.candidateApplicationId}
							isActive={
								currentCandidateThread?.candidateApplicationId === item?.candidateApplicationId
							}
							onClick={() => handleSelectCandidate(item.candidateApplicationId)}
						>
							<Styled.MenuListItemSpan>
								<Styled.MenuCandidate>
									{highlightSearch(item.candidate, searchTerm)}
								</Styled.MenuCandidate>
								{item.unreadMessages && <Styled.Badge />}
							</Styled.MenuListItemSpan>
						</Styled.MenuListItem>
					))}
					<Styled.Pagination
						onChange={handlePagination}
						{...paginationData}
						size='small'
						showLessItems={true}
					/>
				</Styled.MenuList>
			)}
		</Styled.Root>
	);
};

export default memo(PanelMenu);
