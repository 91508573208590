import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function DownloadIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6.12126 10.8217C6.0135 10.6913 6.10627 10.4943 6.27544 10.4943H9.32065C9.43111 10.4943 9.52065 10.4047 9.52065 10.2943V3.20024C9.52065 3.08979 9.6102 3.00024 9.72065 3.00024H14.2598C14.3703 3.00024 14.4598 3.08979 14.4598 3.20024V10.2943C14.4598 10.4047 14.5494 10.4943 14.6598 10.4943H17.7268C17.8957 10.4943 17.9886 10.6907 17.8814 10.8212L12.1735 17.7723C12.0936 17.8695 11.9449 17.8698 11.8647 17.7727L6.12126 10.8217Z'
				fill={fill}
			/>
			<path
				d='M5.31855 19.1544C4.87673 19.1544 4.51855 19.5126 4.51855 19.9544V20.2002C4.51855 20.6421 4.87673 21.0002 5.31855 21.0002H18.682C19.1239 21.0002 19.482 20.6421 19.482 20.2002V19.9544C19.482 19.5126 19.1239 19.1544 18.682 19.1544H5.31855Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(DownloadIcon);
