import React, { type FC, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { message } from 'antd';

import API from 'api';
import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import Spinner from 'components/Spinner';
import { atsDucks } from 'modules/ATS/ducks';
import { MESSAGES } from 'modules/Common/constants';
import { commonDucks } from 'modules/Common/ducks';
import {
	ClientInfoType,
	IDefaultPrice,
	ICurrentSubscription,
	SubscriptionPayPremiumStatusesEnum,
} from 'modules/Common/types';
import { GenericType, CurrencyType, DataFormatEnum } from 'types';
import { getDateFormat } from 'utils/helpers';

import { Styled } from './Subscriptions.styled';
import SubscriptionPremiumBlock from './SubscriptionsPremiumBlock';
import SubscriptionStandartBlock from './SubscriptionsStandartBlock';

export type SubscriptionProps = {
	defaultPrices: IDefaultPrice[];
	currentSubscription: ICurrentSubscription;
	getSubscriptionTypesRequested: () => void;
	getCurrentSubscriptionInfo: () => void;
	loading: GenericType;
	commonLoading: GenericType;
	clientInfo: Partial<ClientInfoType>;
};

const Subscriptions: FC<SubscriptionProps> = ({
	defaultPrices,
	currentSubscription,
	getSubscriptionTypesRequested,
	getCurrentSubscriptionInfo,
	loading,
	commonLoading,
	clientInfo,
}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const paymentState = searchParams.get('state');
	const paymentReference = searchParams.get('ref');
	const rescheduledState = searchParams.get('rescheduled');
	const shouldRefresh = searchParams.get('shouldRefresh');
	const [internalLoading, setLoadInternal] = useState(false);
	const isDevEnv = process.env.REACT_APP_ENV === 'dev';

	useEffect(() => {
		if (paymentReference && paymentState === SubscriptionPayPremiumStatusesEnum.Success) {
			message.success(MESSAGES.successfullyPaySubscription);

			confirmSubscription();
		}

		if (paymentState === SubscriptionPayPremiumStatusesEnum.CardUpdated) {
			message.success(MESSAGES.successfullyUpdatedCard);

			setSearchParams({});
		}

		if (rescheduledState) {
			message.success(
				`Your Subscription has been enabled and will be charged on ${getDateFormat(
					rescheduledState,
					DataFormatEnum.Full,
				)}`,
			);

			setSearchParams({});
		}

		if (paymentState === SubscriptionPayPremiumStatusesEnum.Fail) {
			setSearchParams({});
		}
	}, [paymentReference, paymentState, rescheduledState]);

	const confirmSubscription = async () => {
		try {
			await API.subscriptionsService.confirmClientPremium(paymentReference);
			getCurrentSubscriptionInfo();
			setSearchParams({});
		} catch (e) {
			console.error(e);
		}
	};

	const activateTrial = async () => {
		try {
			setLoadInternal(true);
			await API.subscriptionsService.getClientPremiumTrial();
			getCurrentSubscriptionInfo();
		} catch (e) {
			console.error(e);
		} finally {
			setLoadInternal(() => false);
		}
	};

	useEffect(() => {
		if (shouldRefresh) {
			message.success(
				'Your Subscription has been enabled and will be charged according to the selected plan',
			);
			getCurrentSubscriptionInfo();
			setSearchParams({});
		}
	}, [shouldRefresh]);

	useEffect(() => {
		getSubscriptionTypesRequested();
	}, []);

	const handleWipe = useCallback(async () => {
		try {
			setLoadInternal(true);
			await API.subscriptionsService.wipePremium();
			await API.subscriptionsService.wipeTrial();

			getCurrentSubscriptionInfo();
		} catch (e) {
			console.error(e);
		} finally {
			setLoadInternal(() => false);
		}
	}, []);

	if (
		!defaultPrices.length ||
		!currentSubscription ||
		!clientInfo ||
		loading.getSubscriptionTypesLoad ||
		commonLoading.getClientByIdLoad ||
		loading.getClientSubscriptionLoad
	) {
		return <Spinner />;
	}

	const currency = +clientInfo?.region === 1 ? CurrencyType.PoundSterling : CurrencyType.USDollar;

	const clientHasPremiumOrTrial = currentSubscription.premium || currentSubscription.trial;

	return (
		<Styled.Root>
			<Styled.Title>Subscription</Styled.Title>
			<Styled.ContendWrapper>
				<Styled.Content>
					<SubscriptionStandartBlock
						internalLoading={internalLoading}
						getCurrentSubscriptionInfo={getCurrentSubscriptionInfo}
						isActive={!clientHasPremiumOrTrial}
						isTrial={currentSubscription.trial}
						currency={currency}
						region={clientInfo?.region}
						currentSubscription={currentSubscription}
					/>
					<SubscriptionPremiumBlock
						internalLoading={internalLoading}
						isActive={clientHasPremiumOrTrial}
						currentSubscription={currentSubscription}
						defaultPrices={defaultPrices}
						activateTrial={activateTrial}
						currency={currency}
						region={clientInfo?.region}
					/>
				</Styled.Content>
			</Styled.ContendWrapper>
			{isDevEnv && (
				<Styled.WipeButtonWrapper>
					<Button
						disabled={internalLoading}
						buttonType={ButtonTypes.secondary}
						onClick={handleWipe}
					>
						Wipe Subscription
					</Button>
				</Styled.WipeButtonWrapper>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		defaultPrices: atsDucks.atsSelectors.getSubscriptionTypesState(state),
		currentSubscription: atsDucks.atsSelectors.getCurrentSubscriptionState(state),
		loading: atsDucks.atsSelectors.getAtsLoading,
		clientInfo: commonDucks.commonSelectors.getClientData(state),
		commonLoading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		getSubscriptionTypesRequested: atsDucks.atsActions.getSubscriptionTypesRequested,
		getCurrentSubscriptionInfo: atsDucks.atsActions.getClientSubscriptionRequested,
	},
)(Subscriptions);
