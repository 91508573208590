import { type FC, useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';

import { isEmpty, pick } from 'lodash';

import Spinner from 'components/Spinner';
import { atsDucks } from 'modules/ATS/ducks';
import { acceptedImageFileFormat, DEFAULT_ATS_BG, DEFAULT_LOGO } from 'modules/Common/constants';
import { jobsData, statisticData } from 'modules/Common/constants/AtsBrandingContainer.constants';
import ATSBrandingInner from 'modules/Common/containers/ATSBrandingInner';
import { commonDucks } from 'modules/Common/ducks';
import { AtsBrandingType, BrandingColorsType, ClientInfoType } from 'modules/Common/types';
import { getSingleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import { GenericType } from 'types';

type AtsBrandingProps = {
	clientAtsBranding: AtsBrandingType;
	clientInfo: Partial<ClientInfoType>;
	updateClientRequested: (values: Partial<ClientInfoType>) => void;
	saveClientCareerBranding: (props: GenericType) => void;
	saveClientAtsBranding: (props: GenericType) => void;
};

const AtsClientBrandingContainer: FC<AtsBrandingProps> = ({
	saveClientAtsBranding,
	clientAtsBranding,
	updateClientRequested,
	saveClientCareerBranding,
	clientInfo,
}) => {
	const {
		backgroundImage = DEFAULT_ATS_BG,
		logo = DEFAULT_LOGO,
		clientName = 'no name LTD',
	} = clientAtsBranding;

	const atsColors = useMemo(
		() =>
			pick(clientAtsBranding, [
				'textColour',
				'accentColour',
				'headerBackground',
				'highlightColour',
				'highlightColourAlternate',
			]),
		[clientAtsBranding],
	);

	const [colors, setColors] = useState<BrandingColorsType>(atsColors);

	useEffect(() => {
		setColors(atsColors);
	}, [atsColors]);

	const setLogoUrl = (v: string): void => {
		saveClientAtsBranding({
			logo: v,
		});
		saveClientCareerBranding({ logo: v });
		updateClientRequested({
			logo: v,
			clientName: clientInfo.clientName,
			companyAddress: clientInfo.companyAddress,
			id: clientInfo.id,
		});
	};
	const setBgUrl = (v: string): void => {
		saveClientAtsBranding({
			backgroundImage: v,
		});
	};

	const handleBgRemove = () => {
		setBgUrl('');
	};

	const navigationProps = {
		accentColour: colors.accentColour,
		highlightColourAlternate: colors.highlightColourAlternate,
	};

	const ajustColorData = [
		{
			id: 0,
			title: 'Text Colour (Titles)',
			initialValue: colors.textColour,
			onChange: (color: string): void => setColors({ ...colors, textColour: color }),
		},
		{
			id: 1,
			title: 'Header Background',
			initialValue: colors.headerBackground,
			onChange: (color: string): void => setColors({ ...colors, headerBackground: color }),
		},
		{
			id: 2,
			title: 'Highlight Colour',
			initialValue: colors.highlightColour,
			hint: 'The colour of buttons, checkboxes, and toggles. \nWe recommend using dark colours for improved visibility',
			onChange: (color: string): void => setColors({ ...colors, highlightColour: color }),
		},
		{
			id: 3,
			title: 'Menu Colour',
			initialValue: colors.highlightColourAlternate,
			onChange: (color: string): void => setColors({ ...colors, highlightColourAlternate: color }),
		},
		{
			id: 4,
			title: 'Accent Colour in Menu',
			initialValue: colors.accentColour,
			onChange: (color: string): void => setColors({ ...colors, accentColour: color }),
		},
	];
	const applyColors = () => {
		saveClientAtsBranding(colors);
	};
	const resetColors = () => setColors(atsColors);

	const bgUploaderProps = getSingleFileUploaderProps(
		(url, responseData) => {
			setBgUrl(responseData);
		},
		{ showUploadList: false, accept: acceptedImageFileFormat },
	);

	const logoUploaderProps = getSingleFileUploaderProps(
		(url, responseData) => {
			setLogoUrl(responseData);
		},
		{ showUploadList: false, accept: acceptedImageFileFormat },
	);

	if (isEmpty(clientAtsBranding)) {
		return <Spinner />;
	}

	return (
		<ATSBrandingInner
			textColour={colors.textColour}
			highlightColour={colors.highlightColour}
			headerBackground={colors.headerBackground}
			handleBgRemove={handleBgRemove}
			clientName={clientName}
			backgroundImage={backgroundImage}
			jobsData={jobsData}
			statisticData={statisticData}
			logo={logo}
			bgUploaderProps={bgUploaderProps}
			logoUploaderProps={logoUploaderProps}
			colors={colors}
			applyColors={applyColors}
			resetColors={resetColors}
			navigationProps={navigationProps}
			ajustColorData={ajustColorData}
			setBgUrl={setBgUrl}
		/>
	);
};

export default connect(
	(state) => ({
		clientAtsBranding: atsDucks.atsSelectors.getClientAtsPremiumBrandingData(state),
		clientInfo: commonDucks.commonSelectors.getClientData(state),
	}),
	{
		saveClientAtsBranding: atsDucks.atsActions.saveClientAtsBranding,
		saveClientCareerBranding: atsDucks.atsActions.saveClientCareerBranding,
		updateClientRequested: commonDucks.commonActions.updateClientRequested,
	},
)(AtsClientBrandingContainer);
