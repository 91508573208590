import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { Routes } from 'types';

import PostJob from '../../containers/PostJob';

import { Styled } from './ATSPostJobPage.styled';

const ATSPostJobPage = () => {
	const { jobId } = useParams();
	const { pathname } = useLocation();
	const isEditMode = pathname.includes(Routes.PostJobEdit);
	const isCopyMode = pathname.includes(Routes.PostJobCopy);
	const isBoostMode = pathname.includes(Routes.PostJobBoost);

	return (
		<>
			<Styled.Head>
				<PageNavTitle
					title={isEditMode ? 'Edit a Job' : `${isBoostMode ? 'Boost' : 'Post'} a Job`}
					navigationLink={`${Routes.ATS}${Routes.Jobs}`}
				/>
			</Styled.Head>
			<Styled.Content>
				<PostJob
					jobId={jobId}
					isEditMode={isEditMode}
					isCopyMode={isCopyMode}
					isBoostMode={isBoostMode}
				/>
			</Styled.Content>
		</>
	);
};

export default ATSPostJobPage;
