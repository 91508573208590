import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function ProfileIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7ZM15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4 16.5147C4 15.1259 5.12586 14 6.51467 14H17.4853C18.8741 14 20 15.1259 20 16.5147V16.9527C20 18.7857 18.863 20.4264 17.1467 21.07C13.8284 22.3144 10.1716 22.3144 6.85332 21.07C5.13703 20.4264 4 18.7857 4 16.9527V16.5147ZM6 16.5147C6 16.2304 6.23042 16 6.51467 16H17.4853C17.7696 16 18 16.2304 18 16.5147V16.9527C18 17.952 17.3801 18.8465 16.4444 19.1973C13.5789 20.2719 10.4211 20.2719 7.55557 19.1973C6.61988 18.8465 6 17.952 6 16.9527V16.5147Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(ProfileIcon);
