import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import Spinner from 'components/Spinner';
import { commonDucks } from 'modules/Common/ducks';
import { CreditType, BaseCreditType } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { GenericType } from 'types';

import { atsDucks } from '../../ducks';

import { Styled } from './ATSClientCredits.styled';

type ATSClientCreditsProps = {
	clientCredits: CreditType[];
	loading: GenericType;
	getCurrentClientCredits: (clientId: number) => void;
	getBaseCredits: () => void;
	baseCredits: BaseCreditType[];
	clientId: number;
};

const ATSClientCredits: FC<ATSClientCreditsProps> = ({
	clientCredits,
	clientId,
	getCurrentClientCredits,
	baseCredits,
	getBaseCredits,
	loading,
}) => {
	useEffect(() => {
		getBaseCredits();
	}, []);

	useEffect(() => {
		getCurrentClientCredits(clientId);
	}, [clientId]);

	return (
		<Styled.Root>
			{loading?.getAllAtsJobsLoad || !baseCredits.length ? (
				<Spinner height='56' />
			) : (
				<Styled.CreditsWrapper>
					{clientCredits.map((c) => (
						<Styled.ClientCreditItem key={c.id}>
							<Styled.ClientCreditCount>{c.count}</Styled.ClientCreditCount>
							<Styled.ClientCreditName>
								{baseCredits.find((bc) => bc.id === c.credit)?.name}
							</Styled.ClientCreditName>
						</Styled.ClientCreditItem>
					))}
				</Styled.CreditsWrapper>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		loading: atsDucks.atsSelectors.getAtsLoading(state),
		clientCredits: atsDucks.atsSelectors.getCreditsState(state),
		baseCredits: commonDucks.commonSelectors.getCredits(state),
		clientAtsBrandingData: atsDucks.atsSelectors.getClientAtsBrandingData(state),
		clientId: unregisteredDucks.unregisteredSelectors.getClientId(state),
	}),
	{
		getCurrentClientCredits: atsDucks.atsActions.getCurrentClientCredits,
		getBaseCredits: commonDucks.commonActions.getCreditsRequested,
	},
)(ATSClientCredits);
