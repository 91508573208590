import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Spinner from 'components/Spinner';
import CheckCircleIcon from 'components/SVG/CheckCircleIcon';
import { commonDucks } from 'modules/Common/ducks';
import { IQuestionnaireValues } from 'modules/Common/types';
import { GenericType } from 'types';

import { Styled } from './QuestionnaireReview.styled';

type QuestionnaireReviewProps = {
	candidateId: number | undefined;
	currentQuestionnaire: IQuestionnaireValues;
	getVideoQuestionnaireResult: (id: number) => void;
	loading: GenericType;
};

const QuestionnaireReview: FC<QuestionnaireReviewProps> = ({
	candidateId,
	getVideoQuestionnaireResult,
	currentQuestionnaire,
	loading,
}) => {
	const [currentQuestion, setCurrentQuestion] = useState(0);

	useEffect(() => {
		candidateId && getVideoQuestionnaireResult(+candidateId);
	}, [candidateId]);

	if (loading?.getVideoQuestionnaireResultLoad || !currentQuestionnaire) {
		return <Spinner fixed />;
	}

	const { questions } = currentQuestionnaire;

	return (
		<Styled.Root>
			<Styled.Main>
				<Styled.VideoWrapper>
					<video controls width={'635px'} src={questions[currentQuestion].videoLink} />
				</Styled.VideoWrapper>
			</Styled.Main>
			<Styled.Sidebar>
				<Styled.SidebarQuestions>
					{questions.map((q, idx) => (
						<Styled.SidebarQuestion key={q.id}>
							<Styled.SidebarQuestionMain
								isActive={currentQuestion === idx}
								onClick={() => setCurrentQuestion(idx)}
							>
								<Styled.SidebarQuestionIcon>
									<CheckCircleIcon />
								</Styled.SidebarQuestionIcon>
								<Styled.SidebarQuestionOrder>
									Question {q.orderInQuestionnaire + 1}. {q.duration.name}
								</Styled.SidebarQuestionOrder>
							</Styled.SidebarQuestionMain>
							{currentQuestion === idx && (
								<>
									<Styled.SidebarQuestionTitle>{q.questionText}</Styled.SidebarQuestionTitle>
									<Styled.SidebarQuestionDesc>{q.description}</Styled.SidebarQuestionDesc>
								</>
							)}
						</Styled.SidebarQuestion>
					))}
				</Styled.SidebarQuestions>
			</Styled.Sidebar>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		currentQuestionnaire: commonDucks.commonSelectors.getCurrentQuestionnaireResult(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		getVideoQuestionnaireResult: commonDucks.commonActions.getVideoQuestionnaireResultRequested,
	},
)(QuestionnaireReview);
