import styled from 'styled-components/macro';

import { device } from 'theme';

export const NavTitleWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: ${({ noSpace }) => (noSpace ? 0 : '24px 0')};
	${({ customStyles }) => customStyles && { ...customStyles }}

	@media ${device.desktop} {
		margin: ${({ noSpace }) => (noSpace ? 0 : '24px 16px')};
	}
`;

export const NavTitleBackButton = styled.div`
	padding-right: 28px;
	cursor: pointer;
`;

export const NavTitle = styled.h3`
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	color: #1e1e20;
`;
