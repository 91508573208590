import React from 'react';

import JobDetailsView from 'modules/ATS/containers/JobDetailsView';
import PageNavTitle from 'modules/Common/components/PageNavTitle';

import { Styled } from './ATSJobDetailsPage.styled';

const ATSJobDetailsPage = () => {
	return (
		<Styled.Root>
			<Styled.Head>
				<PageNavTitle title='Job Details' navigationLink={-1} />
			</Styled.Head>
			<Styled.Content>
				<JobDetailsView />
			</Styled.Content>
		</Styled.Root>
	);
};

export default ATSJobDetailsPage;
