import React from 'react';

import QuestionnaireList from 'modules/BackOffice/containers/QuestionnaireList';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { MenuList } from 'modules/Common/types';

import { Styled } from './QuestionnaireListPage.styled';

const QuestionnaireListPage = () => {
	return (
		<>
			<Styled.Head>
				<PageNavTitle title={MenuList.Questionnaires} />
			</Styled.Head>
			<Styled.Content>
				<QuestionnaireList />
			</Styled.Content>
		</>
	);
};

export default QuestionnaireListPage;
