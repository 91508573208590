import React, { type FC, memo, useCallback, useState, useEffect } from 'react';
import { Field, useFormState } from 'react-final-form';

import { Input } from 'antd';

import FieldWrapper from 'components/FieldWrapper';
import FormBlock from 'modules/Common/components/FormBlock';
import { CreditsType, PriceType } from 'modules/Common/types';
import { CurrencyType, Fields } from 'types';
import { numberValidator } from 'utils/validators';

import { Styled } from './CreditsFields.styled';

type ContractDetailsProps = {
	credits: CreditsType[];
	clientPrices: PriceType[];
	vat: number;
	chargeVatOnContracts: boolean;
	isPremium: boolean;
	currency: CurrencyType;
};

const Credits: FC<ContractDetailsProps> = ({
	credits,
	vat,
	clientPrices,
	chargeVatOnContracts,
	isPremium,
	currency,
}) => {
	const { values } = useFormState();
	const [total, setTotal] = useState<number>(0);
	const [discount, setDiscount] = useState<number>(0);
	const [creditsTotal, setCreditsTotal] = useState({});
	const DISCOUNT_AMOUNT = 0.15;

	const getBudleInputWidth = useCallback((bundleWidth: number): string => {
		if (bundleWidth === 1) {
			return '400px';
		}
		if (bundleWidth === 6) {
			return '90px';
		}
		if (bundleWidth > 6) {
			return '95px';
		}

		return '100px';
	}, []);

	const getBudleInputFlex = useCallback((bundleWidth: number): string => {
		if (bundleWidth === 1) {
			return '100%';
		}
		if (bundleWidth === 6) {
			return '16%';
		}
		if (bundleWidth > 1) {
			return `${Math.floor(100 / bundleWidth)}%`;
		}

		return 'auto';
	}, []);

	const getCreditTotal = useCallback((amount: number, currentCreditPrices: PriceType[]) => {
		if (!amount) {
			return 0;
		} else if (currentCreditPrices.length === 1) {
			return amount * currentCreditPrices[0].price;
		} else {
			const currentPriceObj = currentCreditPrices.find((ccp) => ccp.amount <= amount);

			return currentPriceObj?.price && currentPriceObj?.price * amount;
		}
	}, []);

	useEffect(() => {
		const newCreditsTotal =
			credits.length &&
			credits.reduce((acc, credit) => {
				const currentCreditPrices = clientPrices
					.filter((cp) => cp.credit === credit.id)
					.sort((a, b) => b.amount - a.amount);
				const orderedAmount = +values[credit.value];

				const creditTotal = getCreditTotal(orderedAmount, currentCreditPrices);

				return { ...acc, [credit.name]: creditTotal };
			}, {});
		const creditsTotalValues = Object.values(newCreditsTotal);

		const newGlobalCreditsTotal =
			creditsTotalValues.length && creditsTotalValues.reduce((acc, item) => acc + item);

		setCreditsTotal(newCreditsTotal);
		setTotal(isPremium ? newGlobalCreditsTotal * (1 - DISCOUNT_AMOUNT) : newGlobalCreditsTotal);

		if (isPremium) {
			setDiscount(newGlobalCreditsTotal * DISCOUNT_AMOUNT);
		}
	}, [values]);

	return credits?.length ? (
		<FormBlock title='Buy Credits'>
			<Styled.BlockDesc>
				The prices below have been reduced to your exclusive preferential rate.
			</Styled.BlockDesc>
			<>
				{credits?.map((credit, index) => {
					const bundles = credit?.bundles.slice(1, -1).replace(/\s/g, '').split(',');

					return (
						<Styled.FiedlsBlockWrapper key={credit.id}>
							<Styled.AmountFieldWrapper>
								<Field name={credit.value} validate={numberValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name={credit.value}
											label={credit?.name}
											errorMessage={meta.dirty && meta.error}
											isFixed
										>
											<Styled.InputWrapper>
												<Input
													{...input}
													type={Fields.NUMBER}
													placeholder='e.g. 10'
													minLength={0}
													min={0}
												/>
											</Styled.InputWrapper>
										</FieldWrapper>
									)}
								</Field>
							</Styled.AmountFieldWrapper>

							<Styled.PricesWrapper>
								<FieldWrapper name={credit.creditPricePrefix} label='Price per unit' fullWidth>
									<Styled.Prices>
										{bundles?.map((bundle) => {
											const currentPrice = clientPrices.find(
												(cp) => cp.credit === credit.id && +bundle === +cp.amount,
											);

											return (
												<Styled.PriceUnit
													key={`${credit.id}-${bundle}`}
													inputWidth={getBudleInputFlex(bundles.length)}
												>
													<Styled.PriceLabel>{bundle}:</Styled.PriceLabel>
													<Styled.PriceCountWrapper inputWidth={getBudleInputWidth(bundles.length)}>
														<Styled.PriceCount>{currentPrice?.price}</Styled.PriceCount>
														<Styled.CreditFieldsInfo>
															Total: {currency}
															{currentPrice && +bundle * currentPrice?.price}
														</Styled.CreditFieldsInfo>
													</Styled.PriceCountWrapper>
												</Styled.PriceUnit>
											);
										})}
									</Styled.Prices>
									{index === 0 ? (
										<Styled.PriceDesc>
											Price per 1 credit depending on the number of purchased credits.
										</Styled.PriceDesc>
									) : (
										<></>
									)}
								</FieldWrapper>
							</Styled.PricesWrapper>

							<Styled.Price>
								{index === 0 && <span>Total:</span>}
								{currency}
								{creditsTotal?.[credit.name]?.toFixed(2)}
							</Styled.Price>
						</Styled.FiedlsBlockWrapper>
					);
				})}
			</>
			<Styled.Line />
			{isPremium && (
				<>
					<Styled.TotalPriceSmallSize>
						<span>Subscription discount:</span>
						<span>
							- {currency}
							{discount?.toFixed(2)}
						</span>
					</Styled.TotalPriceSmallSize>
				</>
			)}
			{chargeVatOnContracts && (
				<>
					<Styled.TotalPriceSmallSize>
						<span>Total Ex VAT:</span>
						<span>
							{currency}
							{total?.toFixed(2)}
						</span>
					</Styled.TotalPriceSmallSize>
					<Styled.TotalPriceSmallSize>
						<span>VAT:</span>
						<span>
							{currency}
							{(total * vat)?.toFixed(2)}
						</span>
					</Styled.TotalPriceSmallSize>
					<Styled.Line />
				</>
			)}
			<Styled.TotalPrice>
				<span>Total:</span>
				<span>
					{currency}
					{chargeVatOnContracts ? (total * vat + total)?.toFixed(2) : total.toFixed(2)}
				</span>
			</Styled.TotalPrice>
		</FormBlock>
	) : null;
};

export default memo(Credits);
