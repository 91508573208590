import _ from 'lodash';
import { createSelector } from 'reselect';

import { InvoicesStatusValuesEnum, ContractStatusValuesEnum } from 'modules/Common/types';

export const createSelectors = (root) => {
	const backOfficeRootState = createSelector([(s) => s[root]], (state) => state.data);
	const backOfficeLoading = createSelector([(s) => s[root]], (state) => state.loadingReducer);
	const getClientsState = createSelector(backOfficeRootState, (data) => data?.clientsState);
	const getClientsData = createSelector(getClientsState, (data) => data?.clientsData);
	const getClientsShortData = createSelector(getClientsState, (data) => data?.clientsShortData);
	const getCurrentClientShortData = createSelector(
		backOfficeRootState,
		(data) => data?.currentClientShortData,
	);
	const getClientsPaginatedData = createSelector(
		getClientsState,
		(data) => data?.clientsPaginatedData,
	);
	const getClientContextState = createSelector(
		backOfficeRootState,
		(data) => data?.clientContextState,
	);
	const getClientContextData = createSelector(
		getClientContextState,
		(data) => data?.clientContextData,
	);
	const getContextClientData = createSelector(
		getClientContextState,
		(data) => data?.contextClientData,
	);
	const getSelectedClientsListData = createSelector(
		getClientContextState,
		(data) => data?.selectedListData,
	);
	const getClientsSubsState = createSelector(backOfficeRootState, (data) => data.clientsSubs);
	const getClientsRegionsState = createSelector(backOfficeRootState, (data) => data.regions);
	const getClientsOptions = createSelector(getClientsData, (data) =>
		data?.map((item) => ({ id: item.id, value: item.clientName, region: item.region })),
	);
	const getCreditsDefaultPricesState = createSelector(
		backOfficeRootState,
		(data) => data.creditsDefaultPrices,
	);
	const getSubsDefaultPricesState = createSelector(
		backOfficeRootState,
		(data) => data.subsDefaultPrice,
	);
	const getBOTicketsState = createSelector(backOfficeRootState, (rootData) => {
		if (!rootData.tickets) {
			return null;
		}

		const { data, ...rest } = rootData.tickets;

		return {
			data: data.map((ticket) => ({ key: ticket.id, ...ticket })),
			...rest,
		};
	});
	const getBOTicketDetailsState = createSelector(
		backOfficeRootState,
		(rootData) =>
			rootData.ticketDetails && {
				...rootData.ticketDetails,
				activities: rootData.ticketDetails.activities?.map((activity) => ({
					...activity,
					date: new Date(activity.date).toLocaleDateString('en-US', {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
						timeZone: 'UTC',
					}),
				})),
			},
	);
	const getBoClientContractsState = createSelector(
		backOfficeRootState,
		(data) => data.clientContracts,
	);
	const getContractsByClientIdSelectFields = createSelector(getBoClientContractsState, (data) =>
		data
			?.filter((c) => c.contractState.value === ContractStatusValuesEnum.Accepted)
			.map((item) => ({ value: item.id, label: item.btoReference })),
	);
	const getInvoicesState = createSelector(backOfficeRootState, (data) => data.invoices);
	const getCurrentInvoice = createSelector(backOfficeRootState, (data) => data.currentInvoice);
	const getCurrentContract = createSelector(
		backOfficeRootState,
		(data) => data.currentContractDetails,
	);
	const getClientChangesFormState = createSelector(
		backOfficeRootState,
		(data) => data?.clientChangesFormState?.data,
	);
	const getSubsInfo = createSelector(
		getClientChangesFormState,
		(data) => data?.subsInfo?.subsDetails,
	);
	const isSavedState = createSelector(
		backOfficeRootState,
		(data) => data?.clientChangesFormState?.isSaved,
	);
	const getClientsByRegionState = createSelector(
		backOfficeRootState,
		(data) => data?.clientsByRegion,
	);

	const getClientsRegionsOptions = createSelector(getClientsRegionsState, (data) =>
		data?.map((region) => ({ value: region.id, label: region.name })),
	);
	const getClientsRegionsPublicOptions = createSelector(getClientsRegionsState, (data) =>
		data?.map((region) => ({ value: region.name, label: region.name })),
	);
	const getClientsListOptions = createSelector(getClientsData, (data) =>
		_.orderBy(data, [(client) => client?.clientName?.toLowerCase()], ['asc'])
			.filter((cl) => !cl.parent)
			.map((c) => ({
				value: c.id,
				label: c.clientName,
			})),
	);
	const getSubStateOptions = createSelector(getClientsSubsState, (data) =>
		data?.map((s) => ({
			value: s.id,
			label: s.name,
		})),
	);
	const getClientsByRegionOptions = createSelector(getClientsByRegionState, (data) =>
		data?.map((client) => ({ value: client.id, label: client.name })),
	);

	const getAllBackOfficeJobsData = createSelector(backOfficeRootState, (data) => data?.jobsState);
	const getCandidatesState = createSelector(backOfficeRootState, (data) => data?.candidatesState);
	const getCandidatesData = createSelector(getCandidatesState, (data) => data?.candidatesData);
	const getBackOfficeJob = createSelector(backOfficeRootState, (data) => data?.jobState?.jobData);
	const getBoApplicationMessagesState = createSelector(
		backOfficeRootState,
		(data) => data?.applicationMessagesState,
	);
	const getDefaultThankYouMessage = createSelector(
		getBoApplicationMessagesState,
		(data) => data?.thankyouMessage,
	);

	const getInvoiceStatusesForBO = createSelector(backOfficeRootState, (data) =>
		data?.invoiceStatuses
			?.filter((status) => status.value !== InvoicesStatusValuesEnum.Paid)
			.map((status) => {
				if (status.value === InvoicesStatusValuesEnum.PaidManually) {
					return {
						...status,
						name: 'Paid',
					};
				}

				return status;
			}),
	);

	const getInvoicePaymentMethodsForBO = createSelector(backOfficeRootState, (data) =>
		data?.invoicePaymentMethods?.filter((method) => method.value !== 'SUBSCRIPTION'),
	);

	const getBackOfficeUserState = createSelector(backOfficeRootState, (data) => data?.userState);
	const getBackOfficeUserRoles = createSelector(getBackOfficeUserState, (data) => data?.userRoles);
	const getBackOfficeUsersList = createSelector(getBackOfficeUserState, (data) => data?.usersList);
	const getBackOfficeCurrentUser = createSelector(getBackOfficeUserState, (data) => ({
		...data?.currentUser,
		role: data?.currentUser?.roles?.length ? data?.currentUser?.roles[0] : data?.currentUser?.role,
	}));
	const getUserManagers = createSelector(getBackOfficeUserState, (data) => data?.userManagers);
	const getInterviewsListState = createSelector(
		backOfficeRootState,
		(data) => data?.interviewsList,
	);

	const getStatisticState = createSelector(backOfficeRootState, (data) => data?.statistics);

	const getCandidateStatisticsBO = createSelector(
		getStatisticState,
		(data) => data?.candidateStatistics,
	);

	const getBOJobMonthStatisticsState = createSelector(
		getStatisticState,
		(data) => data?.jobsMonthStatistic,
	);
	const getClientContextDashboardUnreadEmails = createSelector(
		backOfficeRootState,
		(data) => data?.unreadEmails,
	);

	return {
		backOfficeRootState,
		getClientsSubsState,
		getClientsRegionsState,
		getClientsOptions,
		getCreditsDefaultPricesState,
		getSubsDefaultPricesState,
		getClientsState,
		getClientsData,
		getClientsShortData,
		getCurrentClientShortData,
		getClientsPaginatedData,
		getClientContextData,
		getContextClientData,
		getSelectedClientsListData,
		getClientsListOptions,
		getClientsRegionsOptions,
		getClientsRegionsPublicOptions,
		getClientsByRegionState,
		getClientsByRegionOptions,
		getSubStateOptions,
		getSubsInfo,
		getClientChangesFormState,
		backOfficeLoading,
		getCurrentInvoice,
		getInvoicesState,
		isSavedState,
		getBOTicketsState,
		getBOTicketDetailsState,
		getAllBackOfficeJobsData,
		getCandidatesState,
		getCandidatesData,
		getBoApplicationMessagesState,
		getDefaultThankYouMessage,
		getInvoiceStatusesForBO,
		getInvoicePaymentMethodsForBO,
		getCurrentContract,
		getBackOfficeUserState,
		getBackOfficeCurrentUser,
		getBackOfficeUsersList,
		getBackOfficeUserRoles,
		getUserManagers,
		getBackOfficeJob,
		getBoClientContractsState,
		getContractsByClientIdSelectFields,
		getInterviewsListState,
		getCandidateStatisticsBO,
		getClientContextDashboardUnreadEmails,
		getBOJobMonthStatisticsState,
	};
};
