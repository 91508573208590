import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.section``;

	export const Head = styled.section`
		display: flex;
		justify-content: space-between;
	`;

	export const HeadRight = styled.div`
		display: flex;
		align-items: center;
		gap: 24px;
	`;

	export const PdfButtonWrapper = styled.div``;
}
