import React, { FC, useState, useEffect } from 'react';

import { UpOutlined } from '@ant-design/icons';
import ZoomVideo from '@zoom/videosdk';
import { Dropdown } from 'antd';

import { useMount } from 'hooks';
import { GenericType } from 'types';

import { getAntdDropdownMenu } from '../../VideoInterview.helpers';

import { Styled } from './InterviewProcessDevices.styled';

const { Button: DropdownButton } = Dropdown;

interface MediaDevice {
	label: string;
	deviceId: string;
}

let allDevices;

const mountDevices: () => Promise<{
	mics: MediaDevice[];
	speakers: MediaDevice[];
	cameras: MediaDevice[];
}> = async () => {
	allDevices = await ZoomVideo.getDevices();
	const cameraDevices: Array<MediaDeviceInfo> = allDevices.filter((device) => {
		return device.kind === 'videoinput';
	});
	const micDevices: Array<MediaDeviceInfo> = allDevices.filter((device) => {
		return device.kind === 'audioinput';
	});
	const speakerDevices: Array<MediaDeviceInfo> = allDevices.filter((device) => {
		return device.kind === 'audiooutput';
	});

	return {
		mics: micDevices.map((item) => {
			return { label: item.label, deviceId: item.deviceId };
		}),
		speakers: speakerDevices.map((item) => {
			return { label: item.label, deviceId: item.deviceId };
		}),
		cameras: cameraDevices.map((item) => {
			return { label: item.label, deviceId: item.deviceId };
		}),
	};
};

type InterviewProcessDevicesProps = {
	stream: MediaStream;
	zoomClient: GenericType;
};

const InterviewProcessDevices: FC<InterviewProcessDevicesProps> = ({ stream, zoomClient }) => {
	const [micList, setMicList] = useState<MediaDevice[]>([]);
	const [speakerList, setSpeakerList] = useState<MediaDevice[]>([]);
	const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
	const [selectedMicName, setSelectedMicName] = useState<string>('choose a microphone');
	const [selectedCameraName, setSelectedCameraName] = useState<string>('choose a camera');
	const [selectedSpeakerName, setSelectedSpeakerName] = useState<string>('choose a sound output');

	useEffect(() => {
		const deviceChangeHandler = () => {
			const microphoneList = stream?.getMicList();
			setMicList(microphoneList?.map((d) => ({ label: d.label, key: d.deviceId })));
			const speakersList = stream?.getSpeakerList();
			setSpeakerList(speakersList?.map((d) => ({ label: d.label, key: d.deviceId })));
		};

		zoomClient.on('device-change', deviceChangeHandler);

		return () => {
			zoomClient.off('device-change', deviceChangeHandler);
		};
	}, [zoomClient, stream]);

	useMount(() => {
		mountDevices().then((devices) => {
			setMicList(devices.mics?.map((d) => ({ label: d.label, key: d.deviceId })));
			setCameraList(devices.cameras?.map((d) => ({ label: d.label, key: d.deviceId })));
			setSpeakerList(devices.speakers?.map((d) => ({ label: d.label, key: d.deviceId })));
		});
	});

	const onMicrophoneMenuClick = ({ key }) => {
		stream?.switchMicrophone(key);
		const selectedMic = micList.find((mic) => mic?.key === key);
		setSelectedMicName(selectedMic?.label || 'choose a microphone');
	};

	const onCameraMenuClick = ({ key }) => {
		stream?.switchCamera(key);
		const selectedCamera = cameraList.find((cam) => cam?.key === key);
		setSelectedCameraName(selectedCamera?.label || 'choose a camera');
	};

	const onSpeakerMenuClick = ({ key }) => {
		stream?.switchSpeaker(key);
		const selectedSpeaker = speakerList.find((sp) => sp?.key === key);
		setSelectedSpeakerName(selectedSpeaker?.label || 'choose a sound output');
	};

	return (
		<Styled.VideoControlsContainer>
			<DropdownButton
				className='vc-dropdown-button'
				size='large'
				menu={getAntdDropdownMenu(micList, onMicrophoneMenuClick)}
				trigger={['click']}
				type='ghost'
				icon={<UpOutlined />}
				placement='topRight'
			>
				{selectedMicName}
			</DropdownButton>
			{!!speakerList.length && (
				<DropdownButton
					className='vc-dropdown-button'
					size='large'
					menu={getAntdDropdownMenu(speakerList, onSpeakerMenuClick)}
					trigger={['click']}
					type='ghost'
					icon={<UpOutlined />}
					placement='topRight'
				>
					{selectedSpeakerName}
				</DropdownButton>
			)}
			<DropdownButton
				className='vc-dropdown-button'
				size='large'
				menu={getAntdDropdownMenu(cameraList, onCameraMenuClick)}
				trigger={['click']}
				type='ghost'
				icon={<UpOutlined />}
				placement='topRight'
			>
				{selectedCameraName}
			</DropdownButton>
		</Styled.VideoControlsContainer>
	);
};

export default InterviewProcessDevices;
