import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Popover } from 'antd';
import moment from 'moment/moment';

import { InterviewGuestType, InterviewGuestTypeEnum, IUser } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { DataFormatEnum, Routes } from 'types';
import { getDateFormat } from 'utils/helpers';

import InterviewCalendarPopupEvent from '../InterviewCalendarPopupEvent/InterviewCalendarPopupEvent';
import { CalendarEventType } from '../InterviewCandidateCalendar.types';
import { Styled } from '../InterviewCandidatesCalendar.styled';

type InterviewCalendarPopupEventTypeProps = {
	event: CalendarEventType;
	user: IUser;
	handleResendLink: (id: number, candidate: string) => void;
};

const InterviewCalendarCustomEvent: FC<InterviewCalendarPopupEventTypeProps> = ({
	event,
	user,
	handleResendLink,
}) => {
	const { candidateId, jobTitle, jobId, guests, start, id, type } = event || {};
	const currentCandidate = guests?.find(
		(guest: InterviewGuestType) => guest?.type?.value === InterviewGuestTypeEnum.Candidate,
	);

	const title = `${jobTitle} - ${currentCandidate?.fullName}`;
	const dateString = `${moment(start).utc().format('YYYY-MM-DDTHH:mm:00')}Z`;
	const dateFormat = getDateFormat(dateString, DataFormatEnum.FullHoursMinutes);

	const popoverTitle = (
		<Styled.PopoverHead>
			<h4>{currentCandidate?.fullName}</h4>

			<Link
				to={`${Routes.ATS}${Routes.AppliedJobsCandidate}/${candidateId}?jobId=${jobId}`}
				target='_blank'
			>
				View Candidate
			</Link>
		</Styled.PopoverHead>
	);

	return (
		<Styled.EventWrapper>
			<Popover
				placement='rightTop'
				title={popoverTitle}
				content={
					<InterviewCalendarPopupEvent
						date={dateFormat}
						type={type}
						candidate={currentCandidate}
						currentUserEmail={user.email}
						interviewId={id}
						jobId={jobId}
						guests={guests}
						handleResendLink={handleResendLink}
					/>
				}
				trigger='click'
			>
				<span>{title}</span>
			</Popover>
		</Styled.EventWrapper>
	);
};

export default connect((state) => ({
	user: unregisteredDucks.unregisteredSelectors.getUser(state),
}))(InterviewCalendarCustomEvent);
