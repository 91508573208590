import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function ArrowRight({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g id='State=Chevron Right'>
				<path
					id='Vector 5 (Stroke)'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M7.07414 3.05826C7.50372 2.62869 8.2002 2.62869 8.62978 3.05827L16.7938 11.2223C17.2227 11.6512 17.2235 12.3463 16.7956 12.7762L8.63158 20.9783C8.20301 21.4089 7.50653 21.4105 7.07595 20.9819C6.64537 20.5534 6.64375 19.8569 7.07233 19.4263L14.4622 12.002L7.07414 4.6139C6.64456 4.18432 6.64456 3.48784 7.07414 3.05826Z'
					fill={fill}
				/>
			</g>
		</svg>
	);
}

export default memo(ArrowRight);
