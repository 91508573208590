import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;

		h3 {
			margin-bottom: 8px;
		}

		p {
			margin-bottom: 24px;
			line-height: 24px;
		}

		textarea {
			white-space: break-spaces;
		}
	`;

	export const Head = styled.div`
		display: flex;
		justify-content: space-between;
	`;

	export const PageDesc = styled.div`
		margin-bottom: 24px;
	`;

	export const ButtonsWrapper = styled.div`
		display: flex;
	`;

	export const DeleteButtonWrapper = styled.div`
		margin-left: 8px;
	`;

	export const Button = styled(ButtonComponent)`
		line-height: 28px;
		height: 28px;
		min-width: 28px;
		padding: 4px 8px;
		font-size: 14px;
		gap: 8px;
	`;
}
