import React from 'react';
import { useLocation } from 'react-router-dom';

import BuySMSCredits from 'modules/ATS/containers/BuySMSCredits';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { Routes } from 'types';

import { Styled } from './ATSBuySMSCreditsPage.styled';

const ATSBuySMSCreditsPage = () => {
	const { state } = useLocation();
	const { prevPage, selectedCandidateIds } = state || {};

	return (
		<>
			<Styled.Head>
				<PageNavTitle
					title={'Buy SMS Credits'}
					navigationLink={prevPage ?? `${Routes.ATS}${Routes.Candidates}`}
					state={{ selectedCandidateIds }}
				/>
			</Styled.Head>
			<Styled.Content>
				<BuySMSCredits />
			</Styled.Content>
		</>
	);
};

export default ATSBuySMSCreditsPage;
