import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function ArrowPrevious({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 9 9'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g id='State=Chevron Left'>
				<path
					id='Vector 5 (Stroke)'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M4.70712 2.33075L4.74847 2.28448C4.92765 2.10743 5.20959 2.09381 5.40458 2.24362L5.4514 2.28448L6.94255 3.75793L6.96313 3.77952L6.99055 3.81284L7.02617 3.86748L7.05273 3.92284L7.07032 3.97458L7.0847 4.04722L7.08814 4.10523L7.08675 4.14218L7.07808 4.20381L7.06341 4.25854L7.04158 4.31309L7.01557 4.36097L6.97894 4.41242L6.94255 4.45252L5.4514 5.92597C5.25729 6.11778 4.94258 6.11778 4.74847 5.92597C4.56929 5.74892 4.55551 5.47033 4.70712 5.27765L4.74847 5.23138L5.39071 4.59638H1.62059C1.34607 4.59638 1.12354 4.37648 1.12354 4.10523C1.12354 3.83397 1.34607 3.61408 1.62059 3.61408H5.39071L4.74847 2.97907C4.56929 2.80202 4.55551 2.52342 4.70712 2.33075L4.74847 2.28448L4.70712 2.33075Z'
					fill={fill}
				/>
			</g>
		</svg>
	);
}

export default memo(ArrowPrevious);
