import React, { FC } from 'react';

import { RadioChangeEvent } from 'antd';

import { AQFieldProps, IAnswersOptions } from 'modules/Common/types';

import { Styled } from './AQRadioField.styled';

const AQRadioField: FC<AQFieldProps> = ({ options, onChangeCallback, readOnly }) => {
	const defaultValue = options?.find((option) => option.correct)?.answer;

	const handleChange = (event: RadioChangeEvent) => {
		if (!options) return null;

		const { value } = event.target;
		const updatedOptions = options?.map((option: IAnswersOptions) => ({
			...option,
			correct: option.answer === value,
		}));

		onChangeCallback && onChangeCallback(updatedOptions);
	};

	return (
		<Styled.AQRadioField>
			<h4>Correct Answer</h4>
			<Styled.RadioField onChange={handleChange} defaultValue={defaultValue} disabled={readOnly}>
				{options?.map((option) => (
					<Styled.RadioOption key={`radio-question-${option.answer}`} value={option.answer}>
						{option.answer}
					</Styled.RadioOption>
				))}
			</Styled.RadioField>
		</Styled.AQRadioField>
	);
};

export default AQRadioField;
