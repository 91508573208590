import React, { type FC, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { atsDucks } from 'modules/ATS/ducks';
import ChangePasswordContainer from 'modules/Common/containers/ChangePasswordContainer';
import { IChangePasswordValues } from 'modules/Common/types';
import { GenericType } from 'types';

import { Styled } from './ATSChangePassword.styled';

export type SocialNetworkProps = {
	changeClientUserPassword: (values: Partial<IChangePasswordValues>, cb: () => void) => void;
	loading: GenericType;
};

const ATSChangePassword: FC<SocialNetworkProps> = ({ changeClientUserPassword, loading }) => {
	const navigate = useNavigate();

	const handleSaveUserPassword = useCallback((values: IChangePasswordValues) => {
		changeClientUserPassword(values, () => navigate(0));
	}, []);

	return (
		<Styled.Root>
			<ChangePasswordContainer
				changeUserPassword={handleSaveUserPassword}
				loading={!!loading?.changeClientUserPasswordLoad}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		changeClientUserPassword: atsDucks.atsActions.changeClientUserPasswordRequested,
	},
)(ATSChangePassword);
