import React, { type FC } from 'react';

import FormBlock from 'modules/Common/components/FormBlock';

import { Styled } from './JobUpgrades.styled';

type JobUpgradesProps = {
	credits: {
		displayName: string;
		messageWording: string;
		logoUrl?: string;
		logoHeight?: string;
		creditId: number;
		count: number;
		id: number;
	}[];
	jobUpgrades: number[];
	setJobUpgrades: (jobUpg: number[]) => void;
};

const JobUpgrades: FC<JobUpgradesProps> = ({ credits, setJobUpgrades, jobUpgrades }) => {
	const onUpgradesClick = (id: number) => {
		if (jobUpgrades.includes(id)) {
			setJobUpgrades(jobUpgrades.filter((ju) => ju !== id));
		} else {
			setJobUpgrades([...jobUpgrades, id]);
		}
	};

	return (
		<FormBlock title='Job Upgrades'>
			<Styled.TextHint>Please choose type of upgrade</Styled.TextHint>
			<Styled.JUContent>
				{credits.map((c) => (
					<Styled.JUCredit
						key={c.id}
						active={jobUpgrades.includes(c.id)}
						onClick={() => onUpgradesClick(c.id)}
					>
						<div>
							<Styled.JUCreditTitle>{c.displayName}</Styled.JUCreditTitle>
							<Styled.JUCreditDesc>
								You currently have <Styled.JUCreditCount>{c.count}</Styled.JUCreditCount>{' '}
								{c.messageWording} credit{c.count !== 1 && 's'}{' '}
								{c.count
									? 'so if you select this option one of our team will be in touch.'
									: 'You can buy credit right now'}
							</Styled.JUCreditDesc>
						</div>
						{c.logoUrl && (
							<Styled.JUCreditLogoWrapper>
								<Styled.JUCreditLogo logoUrl={c.logoUrl} logoHeight={c.logoHeight} />
							</Styled.JUCreditLogoWrapper>
						)}
					</Styled.JUCredit>
				))}
			</Styled.JUContent>
		</FormBlock>
	);
};

export default JobUpgrades;
