import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';

import VersionProvider from 'components/VersionProvider';
import { GlobalStyles, theme } from 'theme';

import App from './App';
import 'antd/dist/reset.css';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<>
		<VersionProvider />
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<GlobalStyles />
				<RouterProvider router={App} />
			</ThemeProvider>
		</Provider>
	</>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
