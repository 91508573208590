import styled from 'styled-components/macro';

import Box from 'components/Box';
import { COLORS } from 'theme';

export namespace Styled {
	export const Price = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;
		align-self: flex-end;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;

		span {
			font-size: 16px;
			line-height: 24px;
		}
	`;

	export const TotalPrice = styled.div`
		display: flex;
		justify-content: space-between;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;

		span:first-of-type {
			flex: 7;
		}

		span:last-of-type {
			flex: 1 1 auto;
			text-align: center;
		}
	`;

	export const TableTotalPrice = styled.span`
		font-weight: 600;
		font-size: 16px;
		line-height: 32px;
	`;

	export const Line = styled.div`
		width: 100%;
		height: 1px;
		margin: 32px 0;
		border-bottom: 1px dashed ${COLORS.lightBlue1};
	`;

	export const BoxWrap = styled(({ isBoxComponent, ...props }) => {
		const Component = isBoxComponent ? Box : 'div';

		const additionalStyle = !isBoxComponent ? { margin: '20px 0' } : {};

		return <Component {...props} style={{ ...props.style, ...additionalStyle }} />;
	})``;
}
