import socialNetworksService from 'api/endpoints/social-networks';

import assessmentService from './endpoints/assesments';
import authService from './endpoints/auth';
import backOfficeService from './endpoints/backOffice';
import brandingService from './endpoints/branding';
import candidateApplicationService from './endpoints/candidates';
import clientsService from './endpoints/clients';
import contractsService from './endpoints/contracts';
import creditsService from './endpoints/credits';
import educationsService from './endpoints/educations';
import emailService from './endpoints/email';
import employeeService from './endpoints/employee';
import employmentsService from './endpoints/employments';
import interviewService from './endpoints/interview';
import invoicesService from './endpoints/invoices';
import jobsService from './endpoints/jobs';
import paymentsService from './endpoints/payments';
import pricesService from './endpoints/prices';
import questionnairesService from './endpoints/questionnaires';
import settingsService from './endpoints/settings';
import statisticsService from './endpoints/statistics';
import subscriptionsService from './endpoints/subscription';
import ticketsService from './endpoints/tickets';
import usersService from './endpoints/users';
import versionService from './endpoints/versions';

const services = {
	authService,
	assessmentService,
	backOfficeService,
	brandingService,
	candidateApplicationService,
	contractsService,
	invoicesService,
	interviewService,
	clientsService,
	creditsService,
	educationsService,
	employmentsService,
	jobsService,
	pricesService,
	settingsService,
	usersService,
	versionService,
	ticketsService,
	paymentsService,
	questionnairesService,
	subscriptionsService,
	socialNetworksService,
	statisticsService,
	emailService,
	employeeService,
};

export default services;
