import React, { ChangeEvent, FC, useRef } from 'react';

import { Select, DatePicker } from 'antd';
import { debounce } from 'lodash';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SearchField from 'modules/BackOffice/components/SearchField';
import { OptionsType } from 'types';

import { Styled } from './SearchFilterSortWrapper.styled';

const { RangePicker } = DatePicker;

type SearchFilterSortWrapperProps = {
	search?: {
		onSearch: (value: string) => void;
		defaultValue?: string;
		isDebounce?: boolean;
		placeholder?: string;
		loading?: boolean;
		width?: string;
	};
	filter?: {
		mode: 'multiple' | 'single';
		placeholder: string;
		options: OptionsType[];
		onFilter: (id: number) => void;
		label: string;
	};
	dateFilter?: {
		onDateFilter: () => void;
		placeholder: string;
		label: string;
	};
};

const SearchFilterSortWrapper: FC<SearchFilterSortWrapperProps> = ({
	search,
	filter,
	dateFilter,
}) => {
	const searchValueRef = useRef<string | null>(null);

	const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
		event?.preventDefault();

		const { value } = event?.target || {};

		search?.isDebounce ? debounce(() => search?.onSearch(value)) : search?.onSearch(value);
	};

	return (
		<Styled.Root>
			{search && (
				<Styled.Search width={search.width}>
					<SearchField
						ref={searchValueRef}
						onSearch={handleSearch}
						placeholder={search?.placeholder}
						defaulValue={search?.defaultValue}
						isLoading={search?.loading}
					/>
				</Styled.Search>
			)}
			{filter && (
				<Styled.Filter>
					<Styled.FilterLabel>{filter?.label} :</Styled.FilterLabel>
					<Select
						allowClear
						placeholder='Filter'
						style={{ width: '300px' }}
						mode={filter?.mode}
						options={filter?.options}
						onChange={filter?.onFilter}
					/>
				</Styled.Filter>
			)}
			{dateFilter && (
				<Styled.Filter>
					<Styled.FilterLabel>{dateFilter?.label} :</Styled.FilterLabel>
					<RangePicker allowClear onChange={dateFilter?.onDateFilter} />
				</Styled.Filter>
			)}
		</Styled.Root>
	);
};

export default SearchFilterSortWrapper;
