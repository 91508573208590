import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function FilterIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g id='State=Filter'>
				<path
					id='Vector'
					d='M21.2456 3.00023H2.7541C2.56348 3.00023 2.38006 3.0748 2.24049 3.20907C2.10107 3.34333 2.01568 3.52732 2.002 3.72381C1.98814 3.9205 2.04671 4.11536 2.16596 4.26903L9.54173 13.7757V20.2196C9.54173 20.4132 9.61118 20.5997 9.73673 20.7432C9.86212 20.8867 10.0347 20.9767 10.2207 20.9961C10.4068 21.0154 10.5932 20.9624 10.7436 20.8475L14.1517 18.2464C14.3445 18.0994 14.4583 17.8663 14.4583 17.6184V13.7755L21.834 4.2688C21.9533 4.11512 22.0119 3.92026 21.998 3.72359C21.9841 3.52708 21.8989 3.3431 21.7593 3.20884C21.6198 3.07457 21.4363 3 21.2457 3L21.2456 3.00023Z'
					fill={fill}
				/>
			</g>
		</svg>
	);
}

export default memo(FilterIcon);
