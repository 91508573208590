import { connect } from 'react-redux';

import { backOfficeDucks } from '../../ducks';

import { UserAccessFormContainer } from './UserAccessFormContainer';

export default connect(
	(state) => ({
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
		userRoles: backOfficeDucks.backOfficeSelectors.getBackOfficeUserRoles(state),
		currentUser: backOfficeDucks.backOfficeSelectors.getBackOfficeCurrentUser(state),
		userManagers: backOfficeDucks.backOfficeSelectors.getUserManagers(state),
	}),
	{
		deActivateUser: backOfficeDucks.backOfficeActions.activationUserRequested,
		getUserRoles: backOfficeDucks.backOfficeActions.getBackOfficeUserRolesRequested,
		getUserManagers: backOfficeDucks.backOfficeActions.getBackOfficeUserManagersRequested,
		getUserById: backOfficeDucks.backOfficeActions.getBackOfficeUserByIdRequested,
		updateUser: backOfficeDucks.backOfficeActions.updateBackOfficeUserRequested,
		createUser: backOfficeDucks.backOfficeActions.createBackOfficeUserRequested,
	},
)(UserAccessFormContainer);
