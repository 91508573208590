import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';

import Container from 'components/Container';
import { Direction } from 'components/Container/Container.types';
import { commonDucks } from 'modules/Common/ducks';
import { AtsProfileMenuList } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { UserRoleNamesEnum, UserRolesType, UserRoleType } from 'types';

import SettingsPanelMenu from '../../components/SettingsPanelMenu';

import {
	SettingPanelList,
	SettingPaneShortList,
	SettingPanelFinanceList,
} from './ATSSettingsPage.entities';
import { Styled } from './ATSSettingsPage.styled';

type ATSSettingsPageProps = {
	clientId: number;
	roles: UserRolesType;
	userRole: UserRoleType;
	getClientById: (clientId: number) => void;
};

const ATSSettingsPage: FC<ATSSettingsPageProps> = ({ clientId, userRole, getClientById }) => {
	useEffect(() => {
		clientId && getClientById(clientId);
	}, [clientId]);

	const menuItems = {
		[UserRoleNamesEnum.JAT_SUPER_USER]: SettingPanelList,
		[UserRoleNamesEnum.JAT_USER]: SettingPaneShortList,
		[UserRoleNamesEnum.JAT_FINANCE_USER]: SettingPanelFinanceList,
	};

	const items = menuItems[userRole?.name as keyof UserRoleNamesEnum];

	return (
		<Styled.Root>
			<h2>{AtsProfileMenuList.Settings}</h2>
			<Container direction={Direction.Row} noSpaces fullWidth>
				<>{items?.length && <SettingsPanelMenu items={items} />}</>
				<Outlet />
			</Container>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		clientId: unregisteredDucks.unregisteredSelectors.getUser(state)?.clientId,
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
		userRole: unregisteredDucks.unregisteredSelectors.getUser(state)?.roles[0],
	}),
	{
		getClientById: commonDucks.commonActions.getClientByIdRequested,
	},
)(ATSSettingsPage);
