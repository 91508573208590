import { get } from 'api/axiosInstance';
import { RequestsEnum } from 'types';

export const getEducationTypes = async () => get(RequestsEnum.EducationType);

export const getEducationTypesByClientId = async (regionId) =>
	get(`${RequestsEnum.EducationTypeRegion}/${regionId}`);

const educationsService = {
	getEducationTypes,
	getEducationTypesByClientId,
};

export default educationsService;
