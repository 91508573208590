import React, { type FC, useEffect } from 'react';
import { connect } from 'react-redux';

import _ from 'lodash';

import Spinner from 'components/Spinner';
import { useSubdomain } from 'hooks/useSubdomain';
import { BrandingMode } from 'modules/Common/components/BrandingContainer/BrandingContainer.types';
import { ADDRESS_FIELDS } from 'modules/Common/constants/addressFields';
import { SocialNetworksAllowedFields } from 'modules/Common/constants/clientChangesForm.constants';
import CareerBrandingInner from 'modules/Common/containers/CareerBrandingInner';
import { commonDucks } from 'modules/Common/ducks';
import { prepareSocialNetworksData } from 'modules/Common/utils/commonHelpers';
import { unregisteredDucks } from 'modules/Unregistered/ducks';

type TempPropType = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
	checkClientInfoRequested: (subdomain: string) => void;
};

const CareerMainPage: FC<TempPropType> = (props) => {
	const { client, getClientInfo, clientId, checkClientInfoRequested, getClientJobs, jobsData } =
		props;
	const subdomain = useSubdomain(process.env?.REACT_APP_ENV === 'prod');

	useEffect(() => {
		if (subdomain) {
			checkClientInfoRequested(subdomain);
		}
	}, [subdomain]);

	useEffect(() => {
		if (clientId) {
			getClientInfo(clientId);
			getClientJobs(clientId);
		}
	}, [clientId, getClientInfo, getClientJobs]);

	if (!clientId || _.isEmpty(client)) {
		return <Spinner fullWidth />;
	}

	const {
		whyOurCompany,
		benefits,
		logo,
		backgroundImage,
		highlightColour,
		videoLink,
		clientName = 'no name LTD',
	} = client;
	const socialNetworks = client.socialNetworks
		? prepareSocialNetworksData(_.pick(client.socialNetworks, SocialNetworksAllowedFields))
		: {};

	const clientAddress = _.pick(client, ADDRESS_FIELDS);

	return (
		<CareerBrandingInner
			backgroundImage={backgroundImage}
			jobsData={jobsData}
			mode={BrandingMode.View}
			clientName={clientName}
			logo={logo}
			clientId={clientId}
			whyOurCompany={whyOurCompany}
			benefits={benefits}
			videoLink={videoLink}
			socialNetworksData={socialNetworks}
			colors={{
				highlightColour,
			}}
			clientAddress={clientAddress}
		/>
	);
};

export default connect(
	(state) => ({
		client: commonDucks.commonSelectors.getClientBranding(state),
		clientId: unregisteredDucks.unregisteredSelectors.getClientId(state),
		jobsData: commonDucks.commonSelectors.getClientJobs(state),
	}),
	{
		getClientInfo: commonDucks.commonActions.getClientBrandingRequested,
		getClientJobs: commonDucks.commonActions.getOpenClientJobsRequested,
		checkClientInfoRequested: unregisteredDucks.unregisteredActions.checkClientInfo,
	},
)(CareerMainPage);
