export enum SettingsPanelMenuList {
	CompanyDetails = 'Company Details',
	Subscription = 'Subscription',
	Branding = 'Branding',
	SocialNetworks = 'Social Networks',
	EmailTemplates = 'Email Templates',
	EmailNotifications = 'Email Notifications',
	SMSReminders = 'SMS Reminders',
	EmailSignature = 'Email Signature',
	DefaultMessages = 'Default Messages',
	Authentication = '2F Authentication',
	AutoPop = 'Auto-Pop',
	ChangePassword = 'Change Password',
	UserAccess = 'User Access',
	PersonalData = 'Personal Data',
}
