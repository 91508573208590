import styled from 'styled-components/macro';

import { COLORS, device } from 'theme';

type HeaderProps = {
	headerBackground?: string;
	backgroundImageUrl?: string;
};

type ClientNameProps = {
	textColor?: string;
};

type ContentHeaderProps = {
	highlightColour?: string;
};

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	`;

	export const ExpiredLinkWrapper = styled.div`
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100vh;
	`;

	export const ExpiredLinkInner = styled.div`
		text-align: center;
		border-radius: 4px;
		font-size: 24px;
		border: 1px solid ${COLORS.lightGray2};
		background: ${COLORS.lightGray2};
		box-shadow: 0px 4px 12px 0px rgba(99, 111, 122, 0.16);
		padding: 24px;
	`;

	export const Header = styled.div<HeaderProps>`
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		min-height: 264px;
		${({ backgroundImageUrl }) =>
		backgroundImageUrl && `background: url(${backgroundImageUrl}); background-size: 100% 264px;`};
		${({ headerBackground }) => headerBackground && `background-color: ${headerBackground};`};

		@media ${device.tablet} {
			min-height: 200px;
			${({ backgroundImageUrl }) =>
			backgroundImageUrl &&
				`background: url(${backgroundImageUrl}); background-size: 100% 200px;`};
			${({ headerBackground }) => headerBackground && `background-color: ${headerBackground};`};
		}
	`;

	export const Info = styled.div`
		position: relative;
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 20px;
	`;

	export const Logo = styled.div`
		position: absolute;
		top: 0;
		width: 100%;
		max-width: 240px;
		height: 120px;
		max-height: 120px;
		margin-left: 32px;
		padding: 8px;
		border-radius: 4px;
		background-color: ${COLORS.white};
		transform: translateY(-50%);
		box-shadow: ${COLORS.elevationShadow};
		z-index: 3;

		img {
			width: 100%;
			padding: 4px;
			object-fit: contain;
		}
	`;

	export const ImageWrapper = styled.div`
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		gap: 4px;
		img {
			height: 100%;
		}
	`;

	export const ClientName = styled.div<ClientNameProps>`
		margin-top: 32px;
		margin-left: 300px;
		font-family: Raleway;
		font-size: 20px;
		font-style: normal;
		font-weight: 800;
		line-height: 30px;
		letter-spacing: 0.6px;
		color: ${({ textColor }) => textColor};
	`;

	export const Content = styled.div`
		width: 100%;
		max-width: 100%;
	`;

	export const ContentHeader = styled.div<ContentHeaderProps>`
		background-color: ${COLORS.white};
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.22px;
		padding: 16px;
		border-top: 10px 10px 0 0;
		border-bottom: 1px solid ${COLORS.lightGray1};
		border-radius: 10px 10px 0 0;
	`;
}
