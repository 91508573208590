import React, { type FC, FormEvent, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import createDecorator from 'final-form-calculate';
import { isEmpty } from 'lodash';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import { TimeOptions } from 'modules/Common/constants';
import { commonDucks } from 'modules/Common/ducks';
import {
	ClientInfoType,
	ContractStatusValuesEnum,
	IContractValues,
	PriceType,
} from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { Routes } from 'types';

import { backOfficeDucks } from '../../ducks';

import ContractDetails from './ContractDatails';
import {
	clientDecorator,
	dynamicFormValidation,
	prepareInitialValues,
} from './CreateEditContract.helpers';
import { Styled } from './CreateEditContract.styled';
import { CreateEditContractProps } from './CreateEditContract.types';
import Credits from './Credits';

const CreateEditContract: FC<CreateEditContractProps> = ({
	clientContextId = '',
	contractId,
	isEditMode,
	creditsFields,
	clientsByRegion,
	clientsByRegionList,
	chargeVat,
	currentContractDetails,
	roles,
	userId,
	loading,
	commonLoading,
	getClientsByRegionRequested,
	getCreditsRequested,
	createContractRequested,
	updateContractRequested,
	getPricesByClientIdRequested,
	getContractVatRequested,
	getBackOfficeContractByIdRequested,
	getClientByIdShortRequested,
}) => {
	const navigate = useNavigate();
	const [sendCopyToRecipientEmailsField, setSendCopyToRecipientEmailsField] = useState(true);

	const initialValues = useMemo(
		() => ({
			durationDaysTimeUnit: TimeOptions[0].value,
			btoReference: '',
			generateInvoiceOnAcceptance: true,
			...(clientContextId && { client: Number(clientContextId) }),
			...(isEditMode && prepareInitialValues(currentContractDetails, clientsByRegion)),
			getCopy: true, // is set to true by default in any case according to requirements
		}),
		[currentContractDetails, clientsByRegion],
	);

	const { isServiceUser } = roles || {};

	const isFormDisabled =
		isEditMode &&
		!(
			currentContractDetails?.contractState.value === ContractStatusValuesEnum.Outstanding ||
			currentContractDetails?.contractState.value === ContractStatusValuesEnum.PendingForApproval
		);

	const onSubmit = (values: IContractValues) => {
		const path = clientContextId
			? `${Routes.BOClientContext}/${clientContextId}${Routes.Contracts}`
			: `${Routes.BackOffice}${Routes.Contracts}`;

		const normalizedValues = sendCopyToRecipientEmailsField
			? values
			: { ...values, recipientEmails: null };

		if (isEditMode) {
			updateContractRequested(normalizedValues, () => navigate(path));
		} else {
			createContractRequested(normalizedValues, () => navigate(path));
		}
	};

	useMount(() => {
		!clientsByRegion?.length && getClientsByRegionRequested(1, { size: 1000 });
		!creditsFields?.length && getCreditsRequested();
		contractId && getBackOfficeContractByIdRequested(contractId);
		getContractVatRequested();
	});

	const handleCancel = (event: FormEvent, reset: () => void) => {
		event.preventDefault();

		reset();
	};

	const formDecorator = useMemo(
		() =>
			createDecorator({
				field: 'client',
				updates: (id, _, __, prevValues = {}) => {
					if (!isEditMode || (isEditMode && !isEmpty(prevValues))) {
						const pricesByClientPromise = new Promise<PriceType[]>((resolve, reject) => {
							getPricesByClientIdRequested(id, (val: PriceType[]) => {
								resolve(val);
							});
						});

						const clientByIdPromise = new Promise<ClientInfoType>((resolve, reject) => {
							getClientByIdShortRequested(id, (val: ClientInfoType) => {
								resolve(val);
							});
						});

						return Promise.all([pricesByClientPromise, clientByIdPromise]).then(
							(values: [PriceType[], ClientInfoType]) => {
								return clientDecorator(...values, creditsFields);
							},
						);
					}

					return {};
				},
			}),
		[clientsByRegion, creditsFields],
	);

	if (
		loading.getClientsByRegionLoad ||
		commonLoading.getContractVatLoad ||
		!clientsByRegion?.length ||
		loading.getBackofficeContractByIdLoad
	) {
		return <Spinner />;
	}

	const isEditableServiceUser =
		(isServiceUser && isEditMode && userId === currentContractDetails?.creator) ||
		(isServiceUser && !isEditMode) ||
		!isServiceUser;

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				decorators={[formDecorator]}
				autoComplete='off'
				validate={(values) => dynamicFormValidation(values, !sendCopyToRecipientEmailsField)}
				render={({ handleSubmit, dirty, form: { reset } }) => (
					<form onSubmit={handleSubmit}>
						<ContractDetails
							clients={clientsByRegionList}
							priceLoading={loading.getPricesByClientIdLoad}
							clientContextId={clientContextId}
							isEditMode={isEditMode}
							sendCopyToRecipientEmailsField={sendCopyToRecipientEmailsField}
							lastTimeContractSent={currentContractDetails?.sentContractEmails?.lastSentTime}
							handleSendCopyToRecipientEmailsFieldChange={setSendCopyToRecipientEmailsField}
						/>
						<Credits
							credits={creditsFields}
							vat={chargeVat}
							priceLoading={loading.getPricesByClientIdLoad}
							clientContextId={clientContextId}
						/>
						<Styled.ButtonBox>
							<Styled.Button
								type='button'
								buttonType={ButtonTypes.default}
								onClick={(e) => handleCancel(e, reset)}
							>
								Cancel
							</Styled.Button>
							<Button
								type='submit'
								buttonType={ButtonTypes.primary}
								disabled={
									!dirty ||
									!!loading?.createContractLoad ||
									!!loading?.updateContractLoad ||
									isFormDisabled ||
									!isEditableServiceUser
								}
								loading={!!loading?.createContractLoad || !!loading?.updateContractLoad}
							>
								{isEditMode ? 'Update' : 'Send'}
							</Button>
						</Styled.ButtonBox>
					</form>
				)}
			></Form>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		clientPrices: commonDucks.commonSelectors.getPricesByClientIdState(state),
		clientsByRegion: backOfficeDucks.backOfficeSelectors.getClientsByRegionState(state),
		clientsByRegionList: backOfficeDucks.backOfficeSelectors.getClientsByRegionOptions(state),
		creditsFields: commonDucks.commonSelectors.getCreditFields(state),
		commonLoading: commonDucks.commonSelectors.commonLoading(state),
		chargeVat: commonDucks.commonSelectors.getContractVat(state),
		currentContractDetails: backOfficeDucks.backOfficeSelectors.getCurrentContract(state),
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
		userId: unregisteredDucks.unregisteredSelectors.getUser(state)?.id,
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
	}),
	{
		getClientsByRegionRequested: backOfficeDucks.backOfficeActions.getClientsByRegionRequested,
		getCreditsRequested: commonDucks.commonActions.getCreditsRequested,
		createContractRequested: backOfficeDucks.backOfficeActions.createContractRequested,
		updateContractRequested: backOfficeDucks.backOfficeActions.updateContractRequested,
		getPricesByClientIdRequested: commonDucks.commonActions.getPricesByClientIdRequested,
		getContractVatRequested: commonDucks.commonActions.getContractVatRequested,
		getBackOfficeContractByIdRequested:
			backOfficeDucks.backOfficeActions.getBackOfficeContractByIdRequested,
		getClientByIdShortRequested: backOfficeDucks.backOfficeActions.getClientByIdShortRequested,
	},
)(CreateEditContract);
