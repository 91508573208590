import styled from 'styled-components/macro';

export namespace Styled {
	export const InfoBlock = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 24px;
		width: 100%;
		min-height: 500px;
	`;

	export const Overlay = styled.div`
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.7);
	`;
}
