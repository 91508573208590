import React from 'react';

import ATSStatistic from 'modules/ATS/containers/ATSStatistic';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { Routes } from 'types';

import { Styled } from './ATSStatisticsPage.styled';

const ATSStatisticsPage = () => {
	return (
		<>
			<Styled.Head>
				<PageNavTitle title={'Statistics'} navigationLink={`${Routes.ATS}${Routes.Dashboard}`} />
			</Styled.Head>
			<Styled.Content>
				<ATSStatistic />
			</Styled.Content>
		</>
	);
};

export default ATSStatisticsPage;
