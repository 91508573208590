import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		position: relative;
		width: 100%;

		.ant-upload-wrapper {
			position: relative;
			width: 100%;
			background: ${COLORS.blueOverlay};
			border: 1px dashed ${COLORS.lightBlue1};
			border-radius: 2px;

			.ant-upload {
				&.ant-upload-select {
					width: 100%;
					min-height: 112px;
					margin: 0;
					background: none;
					border: none;
					border-radius: 0;
				}
			}

			.ant-upload-list {
				.ant-upload-list-item-container {
					position: absolute;
					top: 0;
					left: 0;
					animation-duration: 0s;

					.ant-upload-list-item {
						width: 78px;
						height: 78px;
						margin: 16px;
						padding: 0;
						border-radius: 0;

						&:before {
							width: 100%;
							height: 100%;
						}
					}
				}

				.ant-upload-list-item-actions {
					display: block;
					width: 100%;
				}
			}
		}
		.ant-click-animating-node {
			display: none;
		}
	`;

	export const Content = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		text-align: left;
		margin-left: 110px;
		gap: 6px;

		p {
			margin: 0;
		}
	`;

	export const Text = styled.p`
		font-weight: 500;
	`;

	export const ImagePlug = styled.p`
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 78px;
		height: 78px;
		margin: 16px;
		background: ${COLORS.neutral2};
		border: 1px dashed ${COLORS.neutral5};
		border-radius: 2px;
		gap: 8px;

		span {
			color: ${COLORS.darkGray2};
		}
	`;

	export const SubText = styled.p`
		font-weight: 500;
		font-size: 14px;
		color: ${COLORS.darkGray2};
	`;
}
