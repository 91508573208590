import { Link as LinkComponent } from 'react-router-dom';

import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		gap: 50px;
	`;

	export const ImageBox = styled.div`
		min-height: 150px;
	`;

	export const BoxWrapper = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 476px;
		padding: 40px;
		text-align: center;
		border-radius: 6px;
		background-color: ${COLORS.white};
		box-shadow: ${COLORS.elevationShadow1};

		h3 {
			color: ${COLORS.blue};
		}

		h2 {
			margin-top: 4px;
			margin-bottom: 24px;
		}

		a:hover {
			text-decoration: underline;
		}
	`;

	export const Link = styled(LinkComponent)`
		text-decoration: underline;
	`;
}
