import { ToolbarProps, Navigate, type View } from 'react-big-calendar';

import { Button } from 'antd';

import ArrowNext from 'components/SVG/ArrowNext';
import ArrowPrevious from 'components/SVG/ArrowPrevious';
import { COLORS } from 'theme';

import { Styled } from './InterviewCalendarCustomToolbar.styled';

export const InterviewCalendarCustomToolbar: React.FC<ToolbarProps> = (props) => {
	const { label, view, views, onNavigate, onView } = props;
	const viewsList = views as View[];

	return (
		<Styled.Toolbar>
			{/* <Styled.TodayWrapper></Styled.TodayWrapper> */}
			<Styled.DateRangeWrapper>
				<Button
					className='rbc-btn-group__nav-btn'
					icon={<ArrowPrevious width='7' height='9' fill={COLORS.blue2} />}
					onClick={() => onNavigate(Navigate.PREVIOUS)}
					size='small'
				></Button>
				<Styled.DateRangeLabel className={view}>{label}</Styled.DateRangeLabel>
				<Button
					className='rbc-btn-group__nav-btn'
					icon={<ArrowNext width='9' height='9' fill={COLORS.blue2} />}
					onClick={() => onNavigate(Navigate.NEXT)}
					size='small'
				></Button>
			</Styled.DateRangeWrapper>

			<Styled.ViewsWrapper>
				{viewsList && viewsList.length
					? viewsList.map((name) => (
						<Styled.ViewButton
							key={name}
							className={`${view === name ? 'rbc-active' : ''}`}
							onClick={() => onView(name)}
						>
							{name}
						</Styled.ViewButton>
					  ))
					: null}
			</Styled.ViewsWrapper>
		</Styled.Toolbar>
	);
};
