import { memo } from 'react';

import { IconType } from '../SVG.types';

function JobArrow({ width = '40', height = '40' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path fill="#262A34" d="M0 0H40V40H0z"></path>
			<path
				fill="#fff"
				d="M0 0H1440V3726H0z"
				transform="translate(-1312 -1441)"
			></path>
			<g clipPath="url(#clip0_1229_16404)">
				<rect
					width="1440"
					height="632"
					x="-1312"
					y="-144"
					fill="#fff"
					rx="10"
				></rect>
				<g filter="url(#filter0_d_1229_16404)">
					<path
						fill="#fff"
						d="M-890-20c0-6.627 5.373-12 12-12H60c6.627 0 12 5.373 12 12v80c0 6.627-5.373 12-12 12h-938c-6.627 0-12-5.373-12-12v-80z"
					></path>
					<circle
						cx="20"
						cy="20"
						r="19.5"
						fill="#F8F8F8"
						stroke="#F8F8F8"
					></circle>
					<path
						fill="#99A7FF"
						fillRule="evenodd"
						d="M15.074 11.058a1.1 1.1 0 011.556 0l8.164 8.164a1.1 1.1 0 01.002 1.554l-8.164 8.202a1.1 1.1 0 01-1.56-1.552l7.39-7.424-7.388-7.388a1.1 1.1 0 010-1.556z"
						clipRule="evenodd"
					></path>
				</g>
			</g>
			<defs>
				<filter
					id="filter0_d_1229_16404"
					width="970"
					height="112"
					x="-894"
					y="-34"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dy="2"></feOffset>
					<feGaussianBlur stdDeviation="2"></feGaussianBlur>
					<feComposite in2="hardAlpha" operator="out"></feComposite>
					<feColorMatrix values="0 0 0 0 0.299271 0 0 0 0 0.384767 0 0 0 0 0.550729 0 0 0 0.12 0"></feColorMatrix>
					<feBlend
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1229_16404"
					></feBlend>
					<feBlend
						in="SourceGraphic"
						in2="effect1_dropShadow_1229_16404"
						result="shape"
					></feBlend>
				</filter>
				<clipPath id="clip0_1229_16404">
					<path
						fill="#fff"
						d="M0 0H1440V3292H0z"
						transform="translate(-1312 -1059)"
					></path>
				</clipPath>
			</defs>

		</svg>
	);
}

export default memo(JobArrow);
