import React, { type FC, useEffect, useState } from 'react';
import { Field } from 'react-final-form';

import { Input } from 'antd';
import { round } from 'lodash';

import { Fields } from 'types';
import { requiredValidator } from 'utils/validators';

import { Styled } from './PriceBundle.styled';

type PriceBundleProps = {
	name: string;
	defaultValue: number | string;
	inputWidth: string;
	bundle: number | string;
	bundles: string[];
};

const PriceBundle: FC<PriceBundleProps> = ({
	bundle,
	bundles,
	name,
	defaultValue = 0,
	inputWidth,
}) => {
	const [total, setTotal] = useState(0);

	useEffect(() => {
		defaultValue && setTotal(!Number.isNaN(defaultValue) ? round(+defaultValue * +bundle, 2) : 0);
	}, [defaultValue]);

	return (
		<Styled.PriceBundleWrapper inputWidth={inputWidth}>
			<Field name={name} initialValue={defaultValue} validate={requiredValidator}>
				{({ input, meta }) => {
					return (
						<div>
							<Styled.PriceBundle inputWidth={inputWidth}>
								{bundles.length > 1 && (
									<Styled.PriceBundleCounter>{bundle}:</Styled.PriceBundleCounter>
								)}
								<Input
									{...input}
									type={Fields.NUMBER}
									min={0}
									step={0.01}
									onChange={(e) => {
										const value = +e.target.value;
										const multiplicator = +bundle;

										setTotal(!Number.isNaN(value) ? round(value * multiplicator, 2) : 0);

										input.onChange(e);
									}}
								/>
							</Styled.PriceBundle>
							{meta.submitFailed && meta.touched && meta.error ? (
								<Styled.FieldError>{meta.error}</Styled.FieldError>
							) : (
								<Styled.BundleTotal>
									{bundles?.length > 1 ? `Total: £${total}` : ''}
								</Styled.BundleTotal>
							)}
						</div>
					);
				}}
			</Field>
		</Styled.PriceBundleWrapper>
	);
};

export default PriceBundle;
