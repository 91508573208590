import React, { FC } from 'react';

import { ReactParent } from 'types';

import { Styled } from './UnregisteredLayout.styled';

export const UnregisteredLayout: FC<ReactParent> = ({ children }) => (
	<Styled.Root>
		<Styled.Main>{children}</Styled.Main>
		<Styled.Footer>
			<a href='/documents/bluetownonline-terms.pdf' target='_blank' rel='noreferrer'>
				Terms and Conditions
			</a>
		</Styled.Footer>
	</Styled.Root>
);
