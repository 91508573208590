import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { SettingsRoutes } from '../../types';
import Button from '../Button';
import { ButtonTypes } from '../Button/Button.types';

import { Styled } from './Overlay.styled';

type OverlayProps = {
	buttonTitle: string;
	title: string;
};

export const Overlay: FC<OverlayProps> = ({ buttonTitle, title }) => {
	const navigate = useNavigate();

	return (
		<Styled.Overlay>
			<Styled.InfoBlock>
				<p>{title}</p>
				<Button
					buttonType={ButtonTypes.primary}
					onClick={() => navigate(SettingsRoutes.Subscription)}
				>
					{buttonTitle}
				</Button>
			</Styled.InfoBlock>
		</Styled.Overlay>
	);
};
