import { MESSAGES, TimeOptions } from 'modules/Common/constants';
import {
	CreditPrices,
	PriceType,
	ClientInfoType,
	CreditsType,
	IContractValues,
} from 'modules/Common/types';
import { multipleEmailValidator } from 'utils/validators';

export const clientDecorator = (
	clientDefaultPrices: PriceType[],
	currentClient: ClientInfoType,
	cf: CreditsType[],
) => {
	const prices = cf?.reduce((acc, cur) => {
		const price = clientDefaultPrices.find(
			(item: PriceType) => cur.id === item.credit && item.amount === 1,
		)?.price;

		if (price) {
			acc[cur.creditPricePrefix] = price;
		}

		return acc;
	}, {} as CreditPrices);

	return {
		...prices,
		clientOfficeAddress: currentClient?.companyAddress || null,
		paymentTerm: currentClient?.defaultPaymentTerms ? +currentClient?.defaultPaymentTerms : null,
		paymentTermTimeUnit: currentClient?.defaultPaymentTermsUnit || TimeOptions[0].value,
		chargeVat: currentClient?.chargeVatOnContracts || false,
		recipientEmails: currentClient?.email || null,
	};
};

export const dynamicFormValidation = (values: IContractValues, disableValidation: boolean) => {
	const errors = {};

	if (multipleEmailValidator(values.recipientEmails, disableValidation)) {
		errors.recipientEmails = MESSAGES.validateEmailMessage;
	}

	return errors;
};

export const prepareInitialValues = (
	values: IContractValues,
	clientsByRegion: Partial<ClientInfoType>[],
) => {
	const recipientEmails =
		values?.sentContractEmails?.recipientEmails ||
		clientsByRegion?.find((client) => client?.id === Number(values?.client))?.email ||
		'';

	return { ...values, recipientEmails };
};
