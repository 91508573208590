import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.h2``;
	export const ButtonsContainer = styled.section`
		position: relative;
		display: flex;
		justify-content: flex-end;
		padding: 24px 0;
		width: 100%;

		button {
			margin-left: 16px;
		}
	`;

	export const PopoverTitle = styled.div`
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		color: #1e1e20;
		padding: 0 12px;
	`;
}
