import styled from 'styled-components/macro';

import { STATISTICS_CANDIDATE_HIRING_STAGE_COLORS } from 'modules/Common/utils/StatisticsUtils';
import { COLORS } from 'theme';

type StatisticItemProps = {
	statusId: number;
};

export namespace Styled {
	export const Root = styled.section`
		margin-bottom: 24px;
		background-color: ${COLORS.white};
		padding: 24px;
		border-radius: 6px;
		position: relative;
	`;

	export const Head = styled.div``;

	export const Title = styled.h3`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.22px;
		margin-bottom: 16px;
	`;

	export const Content = styled.div``;

	export const FunnelStatistic = styled.div`
		display: flex;
		border-radius: 4px;
		overflow: hidden;
		gap: 20px;
	`;

	export const FunnelStatisticItemWrap = styled.div<StatisticItemProps>`
		display: flex;
		flex: 1;
		flex-direction: column;

		&:first-child {
			> div {
				border-radius: 0 0 0 5px;
			}
		}
		&:last-child {
			> div {
				border-radius: 0 0 5px 0;
			}
		}
		h4 {
			text-align: center;
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			letter-spacing: -0.176px;
			text-transform: capitalize;
			margin-top: 16px;
			${({ statusId }) => `
				color: ${STATISTICS_CANDIDATE_HIRING_STAGE_COLORS[statusId - 1]};
			`}
		}
	`;

	export const FunnelStatisticItem = styled.div<StatisticItemProps>`
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		justify-content: center;
		height: 68px;
		padding: 12px;
		text-align: center;
		${({ statusId }) => `
			background-color: ${STATISTICS_CANDIDATE_HIRING_STAGE_COLORS[statusId - 1]};
		`}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: -20px;
			width: 0;
			height: 0;
			border-left: 16px solid transparent;
			${({ statusId }) => `
				border-top: 34px solid ${STATISTICS_CANDIDATE_HIRING_STAGE_COLORS[statusId]};
				border-bottom: 34px solid ${STATISTICS_CANDIDATE_HIRING_STAGE_COLORS[statusId]};
		`}
			z-index: 3;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: -16px;
			width: 0;
			height: 0;
			border-top: 34px solid transparent;
			border-left: 16px solid
				${({ statusId }) => STATISTICS_CANDIDATE_HIRING_STAGE_COLORS[statusId - 1]};
			border-bottom: 34px solid transparent;
			z-index: 3;
		}

		&:first-child {
			color: black;
		}
		&:last-child {
			border-radius: 0 0 5px 0;
		}
	`;
}
