import styled from 'styled-components/macro';

export const PageWrapper = styled.div``;

export const PageContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-width: 476px;
	max-width: 476px;
`;

export const LogoWrapper = styled.div`
	max-width: 230px;
	min-height: 52px;
	margin-bottom: 56px;

	svg,
	img {
		width: 100%;
		height: 100%;
	}
`;
