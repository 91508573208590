import React, { type FC, FormEvent, useState } from 'react';
import { Field } from 'react-final-form';

import { Button, Checkbox, Input } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';

import FieldWrapper from 'components/FieldWrapper';
import CloseIcon from 'components/SVG/CloseIcon';
import FormBlock from 'modules/Common/components/FormBlock';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import {
	InterviewGuestType as InterviewGuestOptionsType,
	MESSAGES,
} from 'modules/Common/constants';
import { IUser, InterviewGuestType } from 'modules/Common/types';
import { COLORS } from 'theme';
import { emailValidator } from 'utils/validators';

import { Styled } from '../ArrangeInterview.styled';
import { IArrangeCandidate, IOptionArrange } from '../ArrangeInterview.types';

type MainInfoProps = {
	user: Partial<IUser>;
	curCandidate?: IArrangeCandidate | null;
	guests?: InterviewGuestType[] | undefined;
	selectedOptions: number[] | CheckboxValueType[];
	options: Partial<IOptionArrange>[];
	onAddGuest: (value: Partial<IOptionArrange>) => void;
	onChangeCheckbox: (values: number[] | CheckboxValueType[]) => void;
	onDeleteGuest: (event: FormEvent<Element>, value: Partial<IOptionArrange>) => void;
};

const Guests: FC<MainInfoProps> = ({
	user,
	curCandidate,
	guests,
	selectedOptions = [],
	options,
	onAddGuest,
	onChangeCheckbox,
	onDeleteGuest,
}) => {
	const [errorMessage, setErrorMessage] = useState('');

	const interviewer = guests
		? guests.find((guest) => guest.type?.value === InterviewGuestOptionsType[1].value) ?? user
		: user;

	const filteredOptions = options?.filter((option) => option.id !== interviewer?.userId);

	const handleEnterNewItem = (event: FormEvent, callback: () => void) => {
		event.preventDefault();

		const { value } = event.target as HTMLFormElement;

		errorMessage && setErrorMessage('');

		const validateMessage = emailValidator(value);
		if (!value) return setErrorMessage(MESSAGES.mandatoryField);
		if (validateMessage) return setErrorMessage(validateMessage);

		const isValueExist = options?.find((option) => option.email === value);
		if (isValueExist) return setErrorMessage(MESSAGES.incorrectEmailValue);

		onAddGuest({ email: value });

		callback();
	};

	return (
		<FormBlock>
			<FormBlockLine>
				<h2 style={{ width: '100%', margin: 0 }}>Guests</h2>
			</FormBlockLine>
			<FormBlockLine>
				<Styled.CheckboxWrapper>
					{curCandidate?.id && (
						<Checkbox checked disabled>
							{curCandidate.fullName}
							{` (${curCandidate.email})`}
						</Checkbox>
					)}
					{interviewer?.id && (
						<Checkbox checked disabled>
							{interviewer.fullName}
							{` (${interviewer.email})`}
						</Checkbox>
					)}
					<FieldWrapper name='guests'>
						<Checkbox.Group onChange={onChangeCheckbox} value={selectedOptions}>
							<Styled.CheckboxOptions>
								{filteredOptions?.map((item) =>
									item?.id ? (
										<Checkbox key={item.id} value={item.id}>
											{item.fullName}
										</Checkbox>
									) : (
										<Styled.AdditionOption key={item?.email}>
											<span>{item?.email}</span>
											<Button
												type='ghost'
												onClick={(e) => onDeleteGuest(e, item)}
												icon={<CloseIcon width={'14'} height={'14'} fill={COLORS.black} />}
												size='small'
											/>
										</Styled.AdditionOption>
									),
								)}
							</Styled.CheckboxOptions>
						</Checkbox.Group>
					</FieldWrapper>
				</Styled.CheckboxWrapper>
			</FormBlockLine>
			<FormBlockLine>
				<Field name='guestEmail'>
					{({ input, meta }) => (
						<FieldWrapper
							name='guestEmail'
							label='Additional guest'
							errorMessage={(meta.submitFailed && meta.touched && meta.error) || errorMessage}
						>
							<Input
								{...input}
								placeholder='Please enter guest’s email'
								onPressEnter={(value) => {
									handleEnterNewItem(value, () => {
										input.onChange('');
									});
								}}
								onChange={(value) => {
									value && errorMessage && setErrorMessage(() => '');

									return input.onChange(value);
								}}
								autoComplete='off'
								allowClear
							/>
						</FieldWrapper>
					)}
				</Field>
			</FormBlockLine>
		</FormBlock>
	);
};

export default Guests;
