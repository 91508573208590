import React, { type FC, useState, useMemo, useEffect } from 'react';
import { Field, Form } from 'react-final-form';

import { InboxOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Input, Modal, Select, UploadFile, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { isEmpty } from 'lodash';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import FormSwitch from 'components/FormSwitch';
import DownloadIcon from 'components/SVG/DownloadIcon';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { MESSAGES, acceptedTicketUploadFormFileFormat } from 'modules/Common/constants';
import {
	FileUploadTypesEnum,
	IColorProps,
	IPostTicketBackOfficeValues,
	ITicketDetails,
	IUser,
} from 'modules/Common/types';
import { getMultipleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import { getColorProps } from 'modules/Common/utils/commonHelpers';
import { COLORS } from 'theme';
import { IOption } from 'types';
import { filterSelectOptions, handleFileDownload } from 'utils/helpers';
import {
	composeValidators,
	lengthValidator,
	requiredFieldValidator,
	textValidator,
} from 'utils/validators';

import { Styled } from './TicketForm.styled';

type TicketFormProps = {
	isEditMode: boolean;
	clientContextId?: string;
	onSubmitHandler: (values: IPostTicketBackOfficeValues) => void;
	ticketData?: ITicketDetails;
	user: IUser;
	ticketStatuses: IOption[];
	clientsList: IOption[];
	ticketTypes: IOption[];
	submitButtonLoading: boolean;
};

interface IStyles {
	styles: IColorProps;
}

const { Option } = Select;

const parseInitialValues = (ticketData: ITicketDetails) => {
	return {
		...ticketData,
		ticketStatus: ticketData.ticketStatus.id,
		ticketType: ticketData.ticketType.id,
		client: ticketData.client.id,
		attachments: ticketData.attachments.map(({ referenceUuid }) => referenceUuid),
	};
};

const TicketForm: FC<TicketFormProps> = ({
	isEditMode,
	clientContextId = '',
	onSubmitHandler,
	ticketData,
	user,
	ticketStatuses,
	clientsList,
	ticketTypes,
	submitButtonLoading,
}) => {
	const initialValues = useMemo(
		() => ({
			visibleToClient: true,
			ticketStatus: 1,
			client: Number(clientContextId),
			...(!isEmpty(ticketData) && parseInitialValues(ticketData)),
		}),
		[ticketData, clientContextId],
	);
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [selectedClientName, setSelectedClientName] = useState<string>('client');
	const [enteredSubject, setEnteredSubject] = useState<string>('subject');

	useEffect(() => {
		setEnteredSubject(ticketData?.subject || 'subject');
		setSelectedClientName(ticketData?.client?.name || 'client');
		if (ticketData?.attachments && ticketData?.attachments.length) {
			const initialFileList = ticketData.attachments.map(({ originalFileName, referenceUuid }) => ({
				uid: referenceUuid,
				name: originalFileName,
				response: {
					data: referenceUuid,
				},
				status: 'done' as UploadFileStatus,
			}));

			setFileList(initialFileList);
		}
	}, [ticketData]);

	useEffect(() => {
		if (clientContextId && clientsList?.length) {
			const clientName = clientsList.find(
				(client) => Number(client.value) === Number(clientContextId),
			)?.label;
			clientName && setSelectedClientName(clientName);
		}
	}, [clientContextId, clientsList]);

	const ticketStatusesWithColor = ticketStatuses?.map((item) => {
		return {
			...item,
			styles: getColorProps(item.name || ''),
		};
	});
	const getColorsForSelect = (id: number) => {
		return ticketStatusesWithColor?.find((item) => item.id === id)?.styles;
	};

	const handleClientChange = (clientId: string) => {
		const selectedClient = clientsList.find((client) => client.id === clientId);
		setSelectedClientName(selectedClient?.name || '');
	};

	const handleSubjectChange = (subject: string) => {
		setEnteredSubject(subject);
	};

	const handleDownload = (file: UploadFile) => {
		const { name } = file;
		const uid = file?.response?.data;
		handleFileDownload(uid, name);
	};

	const draggerProps = ({
		onChange,
		value = [],
	}: {
		onChange: (fileIds: string[]) => void;
		value: string[];
	}) =>
		getMultipleFileUploaderProps(
			(url, responseData) => {
				onChange([...value, responseData]);
			},
			{
				fileList,
				accept: acceptedTicketUploadFormFileFormat,
				onRemove: (file: UploadFile) => {
					Modal.confirm({
						title: 'Are you sure?',
						icon: <ExclamationCircleOutlined />,
						okText: 'Yes',
						cancelText: 'No',
						mask: false,
						style: { top: '50%' },
						onOk: () => {
							onChange(value.filter((item) => item !== file?.response?.data));
							setFileList(fileList.filter((item) => item.uid !== file.uid));
							message.success(MESSAGES.successfullyRemovedAttachment);
						},
						onCancel() {
							return false;
						},
					});

					return false;
				},
				onDownload: handleDownload,
				fileType: FileUploadTypesEnum.Ticket,
				maxCount: undefined,
			},
			setFileList,
		);

	return (
		<Styled.Root>
			<Styled.BoxWrap isBoxComponent={!clientContextId}>
				<Form
					onSubmit={onSubmitHandler}
					initialValues={initialValues}
					autoComplete='off'
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<Styled.Header>
								<Styled.FormHeader>
									<Styled.Title>{`${selectedClientName}, ${enteredSubject}`}</Styled.Title>
									<Styled.SubTitle>{`Submited by ${user.fullName}`}</Styled.SubTitle>
								</Styled.FormHeader>
								<Styled.FormHeaderAdditional>
									<Field name='visibleToClient'>
										{({ input }) => <FormSwitch {...input} title='Visible to Client' />}
									</Field>
									<Styled.StatusWrapper>
										<Field name='ticketStatus' validate={requiredFieldValidator}>
											{({ input }) => (
												<Styled.Select
													{...input}
													value={input.value || null}
													placeholder='Choose ticket status'
													{...getColorsForSelect(input.value)}
												>
													{ticketStatusesWithColor?.map((option: IOption & IStyles) => (
														<Option
															key={option.id}
															value={option.id}
															style={{ color: option.styles.color }}
														>
															{option.name}
														</Option>
													))}
												</Styled.Select>
											)}
										</Field>
									</Styled.StatusWrapper>
								</Styled.FormHeaderAdditional>
							</Styled.Header>
							<FormBlockLine columns={3}>
								<Field name='client' validate={requiredFieldValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='client'
											label='Client'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Select
												{...input}
												value={input.value || null}
												onChange={(id) => {
													handleClientChange && handleClientChange(id);

													return input.onChange(id);
												}}
												options={clientsList}
												optionFilterProp='label'
												filterOption={(inputValue, option) =>
													filterSelectOptions(inputValue, option, 'name')
												}
												fieldNames={{ label: 'name', value: 'id' }}
												disabled={initialValues.createdByClient || !clientsList.length}
												placeholder='Select a client'
												showSearch={true}
											/>
										</FieldWrapper>
									)}
								</Field>
								<Field name='ticketType' validate={requiredFieldValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='ticketType'
											label='Ticket Type'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Select
												{...input}
												value={input.value || null}
												placeholder='Choose ticket type'
												disabled={!ticketTypes.length}
											>
												{ticketTypes?.map((option: IOption) => (
													<Option key={option.id} value={option.id}>
														{option.name}
													</Option>
												))}
											</Select>
										</FieldWrapper>
									)}
								</Field>
								<Field
									name='subject'
									validate={composeValidators(lengthValidator(1, 255), requiredFieldValidator)}
								>
									{({ input, meta }) => (
										<FieldWrapper
											name='subject'
											label='Subject'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Input
												{...input}
												placeholder='Please enter the subject of your support ticket'
												autoComplete='off'
												onChange={(e) => {
													input.onChange(e.target.value);
													handleSubjectChange(e.target.value);
												}}
											/>
										</FieldWrapper>
									)}
								</Field>
							</FormBlockLine>
							<FormBlockLine columns={1}>
								<Field
									name='description'
									validate={composeValidators(requiredFieldValidator, textValidator)}
								>
									{({ input, meta }) => (
										<FieldWrapper
											name='description'
											label='Description'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<TextArea
												{...input}
												placeholder='Enter ticket description'
												autoSize={{ minRows: 8, maxRows: 8 }}
											/>
										</FieldWrapper>
									)}
								</Field>
							</FormBlockLine>
							<FormBlockLine columns={1}>
								<Field name='attachments' initialValue={initialValues.attachments}>
									{({ input, meta }) => (
										<FieldWrapper
											isFixed
											name='attachments'
											label='Attachments'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Styled.Dragger
												{...draggerProps(input)}
												listType='text'
												onPreview={handleDownload}
												showUploadList={{
													showDownloadIcon: true,
													downloadIcon: <DownloadIcon fill={COLORS.darkGray2} />,
												}}
											>
												<p className='ant-upload-drag-icon'>
													<InboxOutlined />
												</p>
												<p className='ant-upload-text'>Upload a files or drag and drop it</p>
												<p className='ant-upload-hint'>
													{' '}
													PDF, DOC, DOCX, XLS, XLSX, CSV, JPEG, PNG up to 15MB{' '}
												</p>
											</Styled.Dragger>
										</FieldWrapper>
									)}
								</Field>
							</FormBlockLine>
							<Styled.ButtonBox>
								<Button
									type='submit'
									buttonType={ButtonTypes.primary}
									loading={false}
									disabled={!!submitButtonLoading}
								>
									{isEditMode ? 'Update ticket' : 'Send to Bluetown'}
								</Button>
							</Styled.ButtonBox>
						</form>
					)}
				/>
			</Styled.BoxWrap>
		</Styled.Root>
	);
};

export default TicketForm;
