import { ICurrentSubscription, PremiumStatusEnum } from 'modules/Common/types';
import { DataFormatEnum, CurrencyType } from 'types';
import { getDateFormat } from 'utils/helpers';

export const getSubsMessage = (subsInfo: ICurrentSubscription) => {
	if (!subsInfo.premium && !subsInfo.trial && !subsInfo.trialAvailable) {
		return 'The client has no active pro subscription or trial. Trial period for the client could be activated only by BO super admin.';
	}
	if (!subsInfo.premium && !subsInfo.trial && subsInfo.trialAvailable) {
		return 'The client has no active pro subscription or trial. Trial period could be activated by the client or BO admin.';
	}
	if (subsInfo.premium && subsInfo?.premiumStatus === PremiumStatusEnum.Canceled) {
		return `The client has cancelled their pro subscription, end date ${getDateFormat(
			subsInfo.premiumEndDate,
			DataFormatEnum.Full,
		)}`;
	}
	if (subsInfo.premium) {
		return `The client has an active pro subscription, start date is ${getDateFormat(
			subsInfo.premiumStartDate,
			DataFormatEnum.Full,
		)}, next charge date ${getDateFormat(
			subsInfo.nextChargeDate,
			DataFormatEnum.Full,
		)} subscription type is ${subsInfo.premiumType.name}`;
	}
	if (subsInfo.trial) {
		const main = `The client has an active trial until ${getDateFormat(
			subsInfo.trialEndDate,
			DataFormatEnum.Full,
		)}`;

		if (PremiumStatusEnum.Postponed === subsInfo.premiumStatus) {
			return `${main} and subscribed on pro subscription, start date is ${getDateFormat(
				subsInfo.trialEndDate,
				DataFormatEnum.Full,
			)}, next charge date ${getDateFormat(
				subsInfo.nextChargeDate,
				DataFormatEnum.Full,
			)} subscription type is ${subsInfo.premiumType.name}`;
		}

		if (subsInfo.premiumStatus === PremiumStatusEnum.Canceled) {
			return `${main} and has cancelled their pro renewal, start date is ${getDateFormat(
				subsInfo.premiumStartDate,
				DataFormatEnum.Full,
			)}, end date is ${getDateFormat(subsInfo.premiumEndDate, DataFormatEnum.Full)}`;
		}

		return main;
	}

	return '';
};

export const getCurrencySymbol = (region: number): string => {
	const isUK = region === 1;
	const isUSA = region === 2;

	if (isUK) {
		return CurrencyType.PoundSterling;
	}

	if (isUSA) {
		return CurrencyType.USDollar;
	}

	return '';
};
