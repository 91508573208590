import React, { type FC } from 'react';

import Box from 'components/Box';
import { DEFAULT_LOGO } from 'modules/Common/constants';
import { ClientInfoType } from 'modules/Common/types';

import { SettingsPanelMenuList } from '../../ATSSettings.types';

import { Styled } from './CompanyDetailsPreview.styled';

type CompanyDetailsPreviewProps = {
	fields: Partial<ClientInfoType>;
};

const baseUrl = `${process.env.REACT_APP_API_URL}/open/public/file/logo/`;

const CompanyDetailsPreview: FC<CompanyDetailsPreviewProps> = ({ fields }) => {
	const { logo = DEFAULT_LOGO, clientName = '-', companyAddress = '-' } = fields;

	const url = logo && !logo?.startsWith('/images') ? `${baseUrl}${logo}` : logo;

	return (
		<Box style={{ height: '100%' }}>
			<h3>{SettingsPanelMenuList.CompanyDetails}</h3>
			<Styled.FieldBox>
				<Styled.Title>Logo</Styled.Title>
				<Styled.LogoPreview>
					<img src={url} alt={clientName} />
				</Styled.LogoPreview>
			</Styled.FieldBox>
			<Styled.FieldBox>
				<Styled.Title>Company Name</Styled.Title>
				<Styled.Text>{clientName}</Styled.Text>
			</Styled.FieldBox>
			<Styled.FieldBox>
				<Styled.Title>Address</Styled.Title>
				<Styled.TextArea>{companyAddress}</Styled.TextArea>
			</Styled.FieldBox>
		</Box>
	);
};

export default CompanyDetailsPreview;
