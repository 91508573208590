import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'antd';
import Cookies from 'js-cookie';

import EditIcon from 'components/SVG/EditIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
import Ranking from 'modules/Common/components/Ranking';
import Tag from 'modules/Common/components/Tag';
import {
	DEFAULT_ALLOW_EDIT_BUTTON,
	DEFAULT_SHOWING_TIME_RESEND_BUTTON,
	DEFAULT_TIME_RESEND_BUTTON,
} from 'modules/Common/constants';
import { IUser } from 'modules/Common/types';
import {
	InterviewStatusColors,
	InterviewStatusEnum,
	InterviewTypesFormEnum,
} from 'modules/Common/types/interview';
import { COLORS } from 'theme';
import { TStyled } from 'theme/styled';
import { DataFormatEnum, DataFormatType, Routes } from 'types';
import { getDateFormat, getDate, getAmountTimeLeft, getInterviewResendTimes } from 'utils/helpers';

import { Styled } from './InterviewCandidatesList.styled';
import { DataTableType, TableColumnsType } from './InterviewCandidatesList.types';

export const columns = (
	jobId: number,
	user: IUser,
	handleResendLink: (id: number, candidate: string) => void,
	handleCandidateRank: (
		id: string | number,
		rank: number,
		jobId?: string | number,
		cb?: () => void,
	) => void,
): ColumnsDataType & TableColumnsType => [
	{
		title: 'Candidate Name',
		dataIndex: 'name',
		key: 'name',
		render: (_: string, { candidate, candidateId }: Partial<DataTableType>) =>
			jobId && (
				<TStyled.PageLink
					to={`${Routes.ATS}${Routes.AppliedJobsCandidate}/${candidateId}`}
					state={{ jobId }}
				>
					<Styled.TitleList>{candidate}</Styled.TitleList>
				</TStyled.PageLink>
			),
	},
	{
		title: 'Ranking',
		dataIndex: 'ranking',
		key: 'ranking',
		align: 'center',
		render: (_: string, { candidateId, rank }: Partial<DataTableType>) => {
			return (
				<Ranking
					value={rank ?? 0}
					onChange={(val, cb) => candidateId && handleCandidateRank(candidateId, val, jobId, cb)}
				/>
			);
		},
	},
	{
		title: 'Data',
		dataIndex: 'startDate',
		key: 'startDate',
		align: 'center',
		render: (_: string, { startDate }: Partial<DataTableType>) => {
			const formattedData = getDateFormat(startDate, DataFormatEnum.Full) ?? '-';

			return <>{formattedData}</>;
		},
	},
	{
		title: 'Time',
		dataIndex: 'time',
		key: 'time',
		align: 'center',
		render: (_: string, { startDate }: Partial<DataTableType>) => {
			const data: Partial<DataFormatType> | null = getDate(startDate);

			return (
				data && (
					<>
						{data.hours}:{data.minutes}
					</>
				)
			);
		},
	},
	{
		title: 'Type',
		dataIndex: 'type',
		key: 'type',
		align: 'center',
		render: (_: string, { type }: Partial<DataTableType>) => {
			return <>{type?.name}</>;
		},
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		align: 'center',
		render: (_, { status }: Partial<DataTableType>) => {
			const color = status
				? InterviewStatusColors[status?.name as keyof typeof InterviewStatusEnum]
				: '';

			return (
				color && (
					<Tag color={color}>
						{InterviewStatusEnum[status?.name as keyof typeof InterviewStatusEnum]}
					</Tag>
				)
			);
		},
	},
	{
		title: 'Action',
		key: 'action',
		align: 'center',
		render: (
			_: string,
			{ id, candidate, startDate, type, recordingAvailable, guests }: Partial<DataTableType>,
		) => {
			const token = Cookies.get('jwt');
			const candidateData = { fullName: candidate };

			const diff = getAmountTimeLeft(startDate);

			const allowEditButton = diff >= DEFAULT_ALLOW_EDIT_BUTTON;

			const isVideoInterview = type?.id === InterviewTypesFormEnum.VideoOnline;
			const currentUserIsParticipant = guests?.some(
				(g) => g.email?.toLocaleLowerCase() === user.email?.toLocaleLowerCase(),
			);

			const showResendButton =
				Math.abs(diff) <= DEFAULT_SHOWING_TIME_RESEND_BUTTON && isVideoInterview;
			const diffResendTime = getInterviewResendTimes(id);
			const allowResendButton =
				diffResendTime !== null ? Math.abs(diffResendTime) >= DEFAULT_TIME_RESEND_BUTTON : true;

			return (
				<Styled.ActionButtons>
					{showResendButton && (
						<Button
							type='default'
							onClick={() => id && candidate && handleResendLink(id, candidate)}
							disabled={!allowResendButton}
						>
							Resend
						</Button>
					)}
					{recordingAvailable && (
						<Link to={`${Routes.ATS}${Routes.InterviewRecording}/${id}`}>
							<Button type='primary'>View Recording</Button>
						</Link>
					)}
					<Link
						to={`${Routes.ATS}${Routes.ArrangeInterviewEdit}/${id}`}
						state={{ candidate: candidateData, jobId }}
					>
						<Button
							type='default'
							icon={<EditIcon fill={COLORS.black} width='14' height='14' />}
							disabled={!allowEditButton}
						/>
					</Link>
					{isVideoInterview && currentUserIsParticipant && (
						<Link to={`${Routes.Interview}/${id}?jwt=${token}`}>
							<Button type='default'>Join</Button>
						</Link>
					)}
				</Styled.ActionButtons>
			);
		},
	},
];
