import { memo } from 'react';

import { IconType } from '../SVG.types';

function UsdIcon({ width = '16', height = '16' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fill='#1E1E20'
				fillRule='evenodd'
				d='M8.198 7.321l.176.072c.106.042.323.112.438.152a4.3 4.3 0 01.746.35l-.025-.016c.458.279.871.842.871 1.542a2.03 2.03 0 01-.75 1.575c-.276.224-.602.38-.955.465v.233a.7.7 0 01-1.398 0v-.253a2.695 2.695 0 01-1.606-1.19.722.722 0 01.24-.96.722.722 0 01.96.24c.166.276.575.611 1.105.611.344 0 .61-.106.77-.237a.64.64 0 00.178-.207.597.597 0 00.058-.277c-.001-.17-.14-.311-.408-.444a4.06 4.06 0 00-.425-.174l-.127-.045c-.106-.038-.212-.075-.307-.113l.125.048a9.173 9.173 0 00-.44-.163c-.3-.106-.638-.224-.957-.418a1.818 1.818 0 01-.87-1.533c0-.634.294-1.216.75-1.584.276-.224.602-.375.954-.457v-.232a.7.7 0 01.568-.687v-.012H8a.7.7 0 01.7.699v.252a2.675 2.675 0 011.606 1.181c.19.318.077.77-.24.961a.722.722 0 01-.961-.24A1.33 1.33 0 008 5.857c-.346 0-.61.1-.77.229a.632.632 0 00-.177.21.623.623 0 00-.059.283c.002.165.139.303.41.436.128.064.275.12.424.173l.123.044c.108.038.215.075.31.114l-.063-.025z'
				clipRule='evenodd'
			></path>
			<path
				fill='#1E1E20'
				fillRule='evenodd'
				d='M8 1.333A6.675 6.675 0 001.333 8 6.675 6.675 0 008 14.667 6.675 6.675 0 0014.667 8 6.675 6.675 0 008 1.333zm0 1.399A5.26 5.26 0 0113.268 8 5.26 5.26 0 018 13.268 5.26 5.26 0 012.732 8 5.26 5.26 0 018 2.732z'
				clipRule='evenodd'
			></path>
		</svg>
	);
}

export default memo(UsdIcon);
