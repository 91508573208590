import React, { useState } from 'react';
import { connect } from 'react-redux';

import { atsDucks } from 'modules/ATS/ducks';
import AcceptedOffersStatistics from 'modules/Common/components/Statistics/AcceptedOffersStatistics';
import ApplicantFunnel from 'modules/Common/components/Statistics/ApplicantFunnel';
import ApplicationChart from 'modules/Common/components/Statistics/ApplicationsChart';
import CandidatePerStateStatistics from 'modules/Common/components/Statistics/CandidatePerStateStatistics';
import CandidateSourcesStatistics from 'modules/Common/components/Statistics/CandidateSourcesStatistics';
import TimeToHireChart from 'modules/Common/components/Statistics/TimeToHireChart';
import {
	CandidateApplicationSourcesType,
	CandidateStatisticsType,
	IDaysToHire,
	IApplicationYear,
	JobsStatisticType,
	StatisticPeriodEnum,
} from 'modules/Common/types';
import { CandidateStatisticsPerStateType } from 'modules/Common/types/statistics';
import { GenericType } from 'types';

import { Styled } from './ATSStatistic.styled';

type DashboardProps = {
	candidateApplicationPerState: CandidateStatisticsPerStateType[];
	acceptedOffersStatistics: { acceptedOffersPercent: number } | null;
	candidateStatistics: CandidateStatisticsType[];
	candidateApplicationSources: CandidateApplicationSourcesType;
	loading: GenericType;
	getCandidateApplicationPerState: (yearMonth?: string) => void;
	getAcceptedOffersStatistics: (yearMonth?: string) => void;
	getCandidateStatistics: (params: {
		periodType: string;
		timeFrameInDays?: number;
		month?: string;
	}) => void;
	getApplicationSources: (yearMonth?: string) => void;
	getMonthApplicationStatistic: (yearMonth?: string) => void;
	getYearApplicationStatistic: () => void;
	getTimeToHireMonth: (yearMonth?: string) => void;
	getTimeToHireYear: () => void;
	timeToHireMonth: IDaysToHire;
	timeToHireYear: IDaysToHire;
	applicationsMonth: JobsStatisticType;
	applicatonsYear: IApplicationYear;
};

const ATSStatistic = ({
	candidateApplicationPerState,
	acceptedOffersStatistics,
	candidateStatistics,
	candidateApplicationSources,
	loading,
	getCandidateApplicationPerState,
	getAcceptedOffersStatistics,
	getCandidateStatistics,
	getApplicationSources,
	getMonthApplicationStatistic,
	getYearApplicationStatistic,
	applicatonsYear,
	applicationsMonth,
	getTimeToHireMonth,
	getTimeToHireYear,
	timeToHireMonth,
	timeToHireYear,
}: DashboardProps) => {
	const [currentPeriodType, setCurrentPeriodType] = useState<StatisticPeriodEnum>(
		StatisticPeriodEnum.Year,
	);
	const [currentPeriod, setCurrentPeriod] = useState<string>('');

	return (
		<Styled.Root>
			<Styled.Content>
				<Styled.LeftColumn>
					<ApplicationChart
						currentPeriod={currentPeriod}
						currentPeriodType={currentPeriodType}
						applicationsMonth={applicationsMonth}
						applicatonsYear={applicatonsYear}
						setCurrentPeriodType={setCurrentPeriodType}
						setCurrentPeriod={setCurrentPeriod}
						getMonthApplicationStatistic={getMonthApplicationStatistic}
						getYearApplicationStatistic={getYearApplicationStatistic}
						loading={!!loading.getApplicationYearLoad || !!loading.getApplicationMonthLoad}
					/>
					<ApplicantFunnel
						candidateStatistics={candidateStatistics}
						yearMonth={currentPeriod}
						loading={!!loading?.getCandidateStatisticsLoad}
						getCandidateStatistics={getCandidateStatistics}
					/>
					<TimeToHireChart
						timeToHireMonth={timeToHireMonth}
						timeToHireYear={timeToHireYear}
						getTimeToHireMonth={getTimeToHireMonth}
						getTimeToHireYear={getTimeToHireYear}
						currentPeriod={currentPeriod}
						currentPeriodType={currentPeriodType}
						loading={!!loading.getTimeToHireMonthLoad || !!loading.getTimeToHireYearLoad}
					/>
				</Styled.LeftColumn>
				<Styled.RightColumn>
					<AcceptedOffersStatistics
						acceptedOffersStatistics={acceptedOffersStatistics}
						loading={!!loading?.getAcceptedOffersStatisticsLoad}
						getAcceptedOffersStatistics={getAcceptedOffersStatistics}
						yearMonth={currentPeriod}
					/>
					<CandidateSourcesStatistics
						candidateApplicationSources={candidateApplicationSources}
						loading={!!loading?.getApplicationSourcesStatisticsLoad}
						getApplicationSources={getApplicationSources}
						yearMonth={currentPeriod}
					/>
					<CandidatePerStateStatistics
						candidateApplicationPerState={candidateApplicationPerState}
						loading={!!loading?.getCandidateApplicationsPerStateStatisticsLoad}
						getCandidateApplicationPerState={getCandidateApplicationPerState}
						yearMonth={currentPeriod}
					/>
				</Styled.RightColumn>
			</Styled.Content>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		candidateApplicationPerState:
			atsDucks.atsSelectors.getCandidateApplicationPerStateStatistics(state),
		acceptedOffersStatistics: atsDucks.atsSelectors.getAcceptedOffersStatistics(state),
		candidateStatistics: atsDucks.atsSelectors.getCandidateStatistics(state),
		timeToHireMonth: atsDucks.atsSelectors.getTimeToHireMonthState(state),
		timeToHireYear: atsDucks.atsSelectors.getTimeToHireYearState(state),
		applicatonsYear: atsDucks.atsSelectors.getApplicatonsYearState(state),
		applicationsMonth: atsDucks.atsSelectors.getApplicationsMonthState(state),
		candidateApplicationSources: atsDucks.atsSelectors.getApplicationSourcesStatistics(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getCandidateApplicationPerState:
			atsDucks.atsActions.getCandidateApplicationPerStateStatisticsRequested,
		getAcceptedOffersStatistics: atsDucks.atsActions.getAcceptedOffersStatisticsRequested,
		getCandidateStatistics: atsDucks.atsActions.getCandidateStatisticsRequested,
		getApplicationSources: atsDucks.atsActions.getApplicationSourcesStatisticsRequested,
		getMonthApplicationStatistic: atsDucks.atsActions.getMonthApplicationStatisticRequested,
		getYearApplicationStatistic: atsDucks.atsActions.getYearApplicationStatisticRequested,
		getTimeToHireMonth: atsDucks.atsActions.getTimeToHireMonthRequested,
		getTimeToHireYear: atsDucks.atsActions.getTimeToHireYearRequested,
	},
)(ATSStatistic);
