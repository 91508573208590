import { type FC } from 'react';

import { ExpandIconPosition } from 'antd/es/collapse/Collapse';

import ArrowDown from 'components/SVG/ArrowDown';
import ArrowUp from 'components/SVG/ArrowUp';
import { GenericType } from 'types';

import { Styled } from './CollapseList.styled';

type CollapseListProps = {
	items: GenericType[];
	defaultActiveKey?: number[];
	expandIconPosition?: ExpandIconPosition;
};

export const CollapseList: FC<CollapseListProps> = ({
	items,
	defaultActiveKey,
	expandIconPosition = 'end',
}) => {
	return (
		<>
			<Styled.Collapse
				bordered={false}
				items={items}
				defaultActiveKey={defaultActiveKey}
				expandIcon={({ isActive }) => (isActive ? <ArrowUp /> : <ArrowDown />)}
				expandIconPosition={expandIconPosition}
			/>
		</>
	);
};
