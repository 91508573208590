import { pdf } from '@react-pdf/renderer';

import { FinancePdf } from 'modules/Common/containers/FinancePdf';
import { IInvoicesDetailedValues, ReceiptTypesEnum } from 'modules/Common/types';
import { GenericType } from 'types';

const generatePdfBlob = async (
	currentInvoice: IInvoicesDetailedValues,
	creditsFields: GenericType[],
) => {
	const blob = await pdf(
		<FinancePdf
			contract={currentInvoice}
			creditsFields={creditsFields}
			type={ReceiptTypesEnum.Invoice}
		/>,
	).toBlob();

	return new File([blob], `${currentInvoice?.btoReference}-invoice.pdf`, {
		type: 'application/pdf',
	});
};

export const createInvoiceFile = async (
	currentInvoice: IInvoicesDetailedValues,
	creditsFields: GenericType[],
	setFileBlob: (file: File) => void,
) => {
	const blob = await generatePdfBlob(currentInvoice, creditsFields);
	setFileBlob(blob);
};
