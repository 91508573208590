import React from 'react';

import { Modal } from 'antd';

import Button, { ButtonTypes } from 'components/Button';

import { Styled } from './EmployeeToHRModal.styled';

type ModalProps = {
	modalOpen: boolean;
	handleApprove: () => void;
	handleCloseModal: () => void;
};

const EmployeeToHRModal = ({ modalOpen, handleApprove, handleCloseModal }: ModalProps) => {
	return (
		<Modal open={!!modalOpen} footer={null} centered onCancel={handleCloseModal} width={500}>
			<Styled.ConfirmModalTitle>
				Do you want to add this employee to HR module?
			</Styled.ConfirmModalTitle>
			<Styled.ConfirmModalButtons>
				<Button buttonType={ButtonTypes.primary} onClick={handleApprove}>
					Yes
				</Button>
				<Button buttonType={ButtonTypes.secondary} onClick={handleCloseModal}>
					No
				</Button>
			</Styled.ConfirmModalButtons>
		</Modal>
	);
};

export default EmployeeToHRModal;
