import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function EyeCloseIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M19.4811 6.52931C20.6723 7.67694 21.6995 9.15998 22.5626 10.9784C22.656 11.1761 22.7044 11.392 22.7044 11.6107C22.7044 11.8293 22.656 12.0452 22.5626 12.2429C20.2398 17.1586 16.7188 19.6164 11.9997 19.6164C10.2973 19.6164 8.7504 19.2951 7.35903 18.6526L8.71267 17.299C9.71445 17.6653 10.8101 17.8485 11.9997 17.8485C15.9626 17.8485 18.8624 15.84 20.9052 11.6119C20.1379 10.0257 19.2506 8.75214 18.2318 7.77859L19.4811 6.52931Z'
				fill={fill}
				fillOpacity='0.85'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M19.955 2.05761L21.0017 3.10556C21.02 3.1238 21.0345 3.14546 21.0444 3.16931C21.0543 3.19316 21.0593 3.21872 21.0593 3.24453C21.0593 3.27034 21.0543 3.2959 21.0444 3.31975C21.0345 3.3436 21.02 3.36526 21.0017 3.3835L3.5159 20.8686C3.47907 20.9054 3.42913 20.9261 3.37705 20.9261C3.32498 20.9261 3.27504 20.9054 3.2382 20.8686L2.19001 19.8204C2.1532 19.7836 2.13252 19.7336 2.13252 19.6816C2.13252 19.6295 2.1532 19.5795 2.19001 19.5427L4.78827 16.9452C3.48186 15.7664 2.36467 14.1998 1.43671 12.2454C1.34334 12.0477 1.29492 11.8318 1.29492 11.6131C1.29492 11.3945 1.34334 11.1786 1.43671 10.9809C3.75947 6.06524 7.28046 3.60743 11.9997 3.60743C13.8471 3.60743 15.5111 3.98556 16.9916 4.74181L19.6771 2.05761C19.6953 2.03935 19.717 2.02486 19.7408 2.01497C19.7647 2.00509 19.7902 2 19.816 2C19.8419 2 19.8674 2.00509 19.8913 2.01497C19.9151 2.02486 19.9368 2.03935 19.955 2.05761ZM11.9997 5.37529C8.03916 5.37529 5.13939 7.38377 3.09407 11.6119C3.92889 13.3375 4.90613 14.6931 6.03976 15.6937L8.08802 13.6454C7.65186 12.828 7.48999 11.8919 7.62628 10.9755C7.76258 10.059 8.18982 9.21062 8.84499 8.55545C9.50016 7.90028 10.3486 7.47304 11.265 7.33674C12.1815 7.20045 13.1175 7.36232 13.935 7.79848L15.6611 6.07237C14.5564 5.60511 13.3388 5.37529 11.9997 5.37529ZM9.45077 10.3663C9.25442 10.7522 9.15187 11.1789 9.15144 11.6119C9.15113 11.8969 9.19511 12.1802 9.28182 12.4516L12.7412 8.99228C12.3287 8.86062 11.891 8.82812 11.4636 8.89741C11.0362 8.9667 10.6312 9.13582 10.2815 9.39105C9.93173 9.64627 9.64712 9.9804 9.45077 10.3663Z'
				fill={fill}
				fillOpacity='0.85'
			/>
			<path
				d='M11.6504 14.3544C11.7334 14.362 11.8172 14.366 11.9021 14.366C12.2633 14.3662 12.621 14.2952 12.9547 14.157C13.2885 14.0189 13.5917 13.8164 13.8471 13.561C14.1025 13.3056 14.305 13.0023 14.4432 12.6686C14.5813 12.3349 14.6523 11.9772 14.6521 11.616C14.6521 11.531 14.6482 11.4473 14.6406 11.3643L15.9375 10.0674C16.2362 10.8474 16.3026 11.6973 16.1286 12.5143C15.9546 13.3312 15.5477 14.0803 14.9571 14.671C14.3665 15.2616 13.6174 15.6685 12.8004 15.8425C11.9834 16.0165 11.1336 15.9501 10.3535 15.6514L11.6504 14.3544Z'
				fill={fill}
				fillOpacity='0.85'
			/>
		</svg>
	);
}

export default memo(EyeCloseIcon);
