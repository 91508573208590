import React from 'react';

function NavBackButton({ className }: { className?: string }) {
	return (
		<svg
			className={className}
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			fill='none'
			viewBox='0 0 16 16'
		>
			<path
				fill='#1E1E20'
				fillRule='evenodd'
				d='M8.407.7a1 1 0 01.038 1.413L3.67 7.147h10.982a1 1 0 110 2H3.745l4.684 4.724a1 1 0 01-1.42 1.408L.635 8.85a1 1 0 01-.016-1.393L6.993.737A1 1 0 018.407.699z'
				clipRule='evenodd'
			></path>
		</svg>
	);
}

export default React.memo(NavBackButton);
