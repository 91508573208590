import axios from 'axios';
import Cookies from 'js-cookie';

import { CookiesType, RequestsEnum } from 'types';
import { getTableParams } from 'utils/helpers';

import { get, del, post, put, patch } from '../axiosInstance';

const baseUrl = process.env.REACT_APP_API_URL;

export const getAtsInvoices = async ({ page, ...rest }) => {
	const queryParams = getTableParams(page, rest);

	return get(`${RequestsEnum.AtsInvoice}${queryParams}`);
};

export const getBackofficeInvoices = async ({ page, ...rest }) => {
	const queryParams = getTableParams(page, rest);

	const endpoint = rest.client
		? RequestsEnum.BackofficeClientContextInvoice
		: RequestsEnum.BackofficeInvoice;

	return get(`${endpoint}${queryParams}`);
};

export const getBOInvoiceById = async (invoiceId) =>
	get(`${RequestsEnum.InvoiceBackoffice}/${invoiceId}`);

export const getAtsInvoiceById = async (invoiceId) =>
	get(`${RequestsEnum.InvoiceAts}/${invoiceId}`);

export const getInvoicesCSV = async (clientId) => {
	const endpoint = clientId
		? `${RequestsEnum.BackofficeClientContextInvoicesCSV}/${clientId}`
		: RequestsEnum.InvoiceCSV;

	return get(endpoint);
};

export const getInvoiceReceipt = async ({ platform, id }) =>
	get(`${RequestsEnum.Invoice}/${platform}/${id}/receipt`);

export const deleteInvoiceById = async (invoiceId) =>
	del(`${RequestsEnum.BackofficeInvoice}/${invoiceId}`);

export const createInvoice = async (data) => post(RequestsEnum.BackofficeInvoice, data);

export const updateInvoice = async (data) =>
	put(`${RequestsEnum.BackofficeInvoice}/${data.id}`, data);

export const getInvoiceStatuses = async () => get(RequestsEnum.InvoiceStatuses);

export const getInvoicePaymentMethods = async () => get(RequestsEnum.InvoicePaymentMethods);

export const updateInvoiceStatus = async ({ invoiceId, values }) =>
	patch(`${RequestsEnum.BackofficeInvoice}/${invoiceId}/status`, values);

export const forwardInvoice = async (options) => {
	const { onSuccess = () => null, onError = () => null, data } = options;
	const fmData = new FormData();
	const token = Cookies.get(CookiesType.JWT);
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	};

	if (token) {
		config.headers['X-Authorization'] = `Bearer ${Cookies.get(CookiesType.JWT)}`;
	}
	for (const [key, value] of Object.entries(data)) {
		fmData.append(key, value);
	}

	const url = `${baseUrl}${RequestsEnum.AtsInvoiceForward}`;
	try {
		const res = await axios.post(url, fmData, config);
		onSuccess(res);
	} catch (err) {
		onError({ err });
	}
};

const contractsService = {
	getAtsInvoices,
	getBackofficeInvoices,
	getBOInvoiceById,
	getAtsInvoiceById,
	deleteInvoiceById,
	getInvoicesCSV,
	getInvoiceReceipt,
	createInvoice,
	updateInvoice,
	getInvoiceStatuses,
	getInvoicePaymentMethods,
	updateInvoiceStatus,
	forwardInvoice,
};

export default contractsService;
