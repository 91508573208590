import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';
import ClientChangesFormBranding from 'modules/BackOffice/containers/ClientChangesFormBranding';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { AppModes, Routes } from 'types';

import { Styled } from './EditClientBranding.styled';

const EditClientPage = () => {
	const anchorRef = useRef<HTMLDivElement>(null);
	const { state } = useLocation();
	const clientName = state?.clientName;
	const clientId = state?.clientId;

	useScrollToTopOnMount(anchorRef);

	return (
		<Styled.Root ref={anchorRef}>
			<PageNavTitle
				title={clientName ? `${clientName} Branding` : 'Client Branding'}
				navigationLink={`${Routes.BackOffice}${Routes.EditClient}/${clientId}`}
				state={{ clientId, clientName }}
			/>

			<ClientChangesFormBranding mode={AppModes.Edit} />
		</Styled.Root>
	);
};

export default EditClientPage;
