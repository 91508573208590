import React, { FC } from 'react';

import Container from 'components/Container';
import { Direction } from 'components/Container/Container.types';
import { COLORS } from 'theme';
import { ReactParent } from 'types';

import { Styled } from './Footer.styled';

type FooterProps = ReactParent & {
	bgColor?: string;
	styles?: object;
};

export const WrapperFooter: FC<FooterProps> = ({ bgColor = 'none', styles, children }) => {
	return (
		<Styled.Footer bgColor={bgColor}>
			<Container direction={Direction.Row} style={styles}>
				{children}
			</Container>
		</Styled.Footer>
	);
};

export const Footer: FC<FooterProps> = () => (
	<WrapperFooter styles={{ justifyContent: 'flex-end', gap: '24px' }} bgColor={COLORS.lightGray2}>
		<a href='/documents/bluetownonline-terms.pdf' target='_blank' rel='noreferrer'>
			Terms and Conditions
		</a>
		<span>© CONNECT 2024</span>
	</WrapperFooter>
);
