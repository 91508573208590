import { type FC, memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Popconfirm, Tooltip } from 'antd';

import Button from 'components/Button';
import Pagination from 'components/Pagination';
import ArchiveIcon from 'components/SVG/ArchiveIcon';
import BoostIcon from 'components/SVG/BoostIcon';
import DotIcon from 'components/SVG/DotIcon';
import EditIcon from 'components/SVG/EditIcon';
import MapMarkerIcon from 'components/SVG/MapMarkerIcon';
import UnarchiveIcon from 'components/SVG/UarchiveIcon';
import {
	DEFAULT_ATS_JOBS_PAGE_SIZE_OPTIONS,
	UNASSIGNED_JOB_TITLE,
} from 'modules/Common/constants/table';
import { JobClassesEnum, JobsDataType } from 'modules/Common/types';
import { COLORS } from 'theme';
import { CountriesType, DataFormatEnum, Routes } from 'types';
import { getDateFormat } from 'utils/helpers';

import { CandidatesStatisticData } from './JobsListStatistic.constants';
import { Styled } from './JobsListStatistic.styled';

interface ICandidateStatus {
	id: number;
	title: string;
	field: string;
}

type JobsListProps = {
	jobsData?: JobsDataType;
	textColour?: string;
	onChangeActiveJob: (jobId: number, setActive: boolean) => void;
	onChangePage: (page: number, size: number) => void;
	countries: CountriesType;
	isActive?: boolean;
	isPremium: boolean;
	highlightColour?: string;
	showPagination?: boolean;
	dashboardStyles?: boolean;
	hideArchiveAndBoostButtons?: boolean;
	handleEditJob?: (jobId: number) => void;
};

const JobsListStatistic: FC<JobsListProps> = ({
	jobsData,
	textColour,
	onChangeActiveJob,
	onChangePage,
	countries,
	isPremium,
	highlightColour,
	isActive = true,
	showPagination = true,
	dashboardStyles = false,
	hideArchiveAndBoostButtons = false,
	handleEditJob,
}) => {
	const navigate = useNavigate();
	const titleColor = isActive ? textColour : COLORS.black;

	const handleBoostJob = (id: number) => {
		navigate(`${Routes.ATS}${Routes.PostJobBoost}/${id}`);
	};

	return (
		<Styled.Root>
			{jobsData?.data?.length ? (
				<>
					{jobsData?.data?.map((item) => {
						const isUnassignedJob = item.title === UNASSIGNED_JOB_TITLE;

						return (
							<Styled.JobsItem key={item.id} dashboardStyles={dashboardStyles}>
								<Styled.JobsItemTitle textColour={titleColor}>
									{hideArchiveAndBoostButtons ? (
										<h2>{item.title}</h2>
									) : (
										<Link to={`${Routes.ATS}${Routes.Jobs}/${item.id}`}>
											<h2>{item.title}</h2>
										</Link>
									)}
									{!isUnassignedJob && !hideArchiveAndBoostButtons && (
										<Styled.JobsIcons>
											{isActive ? (
												<Popconfirm
													placement='top'
													title='Are you sure?'
													onConfirm={() => onChangeActiveJob(item.id, false)}
													okText='Yes'
													cancelText='Cancel'
												>
													<Tooltip placement='top' title='Archive'>
														<Button icon={<ArchiveIcon />} />
													</Tooltip>
												</Popconfirm>
											) : (
												<Popconfirm
													placement='top'
													title='Are you sure?'
													onConfirm={() => onChangeActiveJob(item.id, true)}
													okText='Yes'
													cancelText='Cancel'
												>
													<Tooltip placement='top' title='Un-archive'>
														<Button icon={<UnarchiveIcon />} />
													</Tooltip>
												</Popconfirm>
											)}
											{isActive && countries?.isUK && item?.jobClass === JobClassesEnum.FreeJob && (
												<Tooltip placement='top' title={'Boost Job'}>
													<Button icon={<BoostIcon />} onClick={() => handleBoostJob(item.id)} />
												</Tooltip>
											)}
										</Styled.JobsIcons>
									)}
									{hideArchiveAndBoostButtons && handleEditJob && (
										<Button
											icon={<EditIcon fill='#000000' />}
											onClick={() => handleEditJob(item.id)}
										/>
									)}
								</Styled.JobsItemTitle>
								<Styled.JobsStatistic>
									{CandidatesStatisticData?.map((status: ICandidateStatus) => {
										const curJob = jobsData?.data?.find((job) => job.id === item.id);

										return (
											<Styled.JobsStatisticItem
												key={status.id}
												statusId={status.id}
												isActive={isActive}
												isPremium={isPremium}
												highlightColour={highlightColour}
											>
												{/* @ts-ignore */}
												<h3>{curJob ? curJob[status.field] : 0}</h3>
												<span>{status.title}</span>
											</Styled.JobsStatisticItem>
										);
									})}
								</Styled.JobsStatistic>
								{!isUnassignedJob && !dashboardStyles && (
									<Styled.JobsFooter>
										<Styled.JobsFooterLeft>
											<span>
												<MapMarkerIcon width='17' height='17' />
												{item.location}
											</span>
											{item.startDate && (
												<span>
													<DotIcon />
													{getDateFormat(item.startDate, DataFormatEnum.Full)}
												</span>
											)}
										</Styled.JobsFooterLeft>
										{item.endDate && (
											<span>Expires on {getDateFormat(item.endDate, DataFormatEnum.Full)}</span>
										)}
									</Styled.JobsFooter>
								)}
							</Styled.JobsItem>
						);
					})}
					{showPagination && (
						<Pagination
							current={jobsData?.pageIndex}
							pageSize={jobsData?.pageSize}
							total={jobsData?.totalCount}
							pageSizeOptions={DEFAULT_ATS_JOBS_PAGE_SIZE_OPTIONS}
							onChange={onChangePage}
						/>
					)}
				</>
			) : (
				<>There are no {isActive ? 'active' : 'archive'} jobs</>
			)}
		</Styled.Root>
	);
};

export default memo(JobsListStatistic);
