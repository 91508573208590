import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function EyeOpenIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M22.5626 11.3669C20.2349 6.46626 16.7164 4 11.9997 4C7.28046 4 3.76439 6.46626 1.43671 11.3693C1.34334 11.5669 1.29492 11.7827 1.29492 12.0012C1.29492 12.2197 1.34334 12.4355 1.43671 12.6331C3.76439 17.5337 7.28291 20 11.9997 20C16.7189 20 20.2349 17.5337 22.5626 12.6307C22.7517 12.2331 22.7517 11.7718 22.5626 11.3669ZM11.9997 18.2331C8.03916 18.2331 5.13939 16.2258 3.09407 12C5.13939 7.77423 8.03916 5.76687 11.9997 5.76687C15.9601 5.76687 18.8599 7.77423 20.9052 12C18.8624 16.2258 15.9626 18.2331 11.9997 18.2331ZM11.9014 7.68098C9.51483 7.68098 7.58001 9.61472 7.58001 12C7.58001 14.3853 9.51483 16.319 11.9014 16.319C14.288 16.319 16.2229 14.3853 16.2229 12C16.2229 9.61472 14.288 7.68098 11.9014 7.68098ZM11.9014 14.7485C10.3816 14.7485 9.15144 13.519 9.15144 12C9.15144 10.481 10.3816 9.25153 11.9014 9.25153C13.4213 9.25153 14.6514 10.481 14.6514 12C14.6514 13.519 13.4213 14.7485 11.9014 14.7485Z'
				fill={fill}
				fillOpacity='0.85'
			/>
		</svg>
	);
}

export default memo(EyeOpenIcon);
