import React, { type FC, useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'antd';
import Cookies from 'js-cookie';

import API from 'api';
import CheckCircleIcon from 'components/SVG/CheckCircleIcon';
import CrossCircleIcon from 'components/SVG/CrossCircleIcon';
import {
	DEFAULT_ALLOW_EDIT_BUTTON,
	DEFAULT_INTERVIEW_DURATION,
	DEFAULT_SHOWING_TIME_RESEND_BUTTON,
	DEFAULT_TIME_RESEND_BUTTON,
} from 'modules/Common/constants';
import { InterviewGuestType, InterviewTypesFormEnum } from 'modules/Common/types';
import { IOption, Routes } from 'types';
import { getAmountTimeLeft, getInterviewResendTimes } from 'utils/helpers';

import { IOptionArrange } from '../../ArrangeInterview/ArrangeInterview.types';
import { Styled } from '../InterviewCandidatesCalendar.styled';

type InterviewCalendarPopupEventProps = {
	date: Date | string | null;
	type: IOption;
	candidate: IOptionArrange;
	jobId: number | string;
	interviewId: number;
	currentUserEmail: string;
	guests: Partial<InterviewGuestType>[];
	handleResendLink: (id: number, candidate: string) => void;
};

const InterviewCalendarPopupEvent: FC<InterviewCalendarPopupEventProps> = ({
	date,
	type,
	candidate,
	interviewId,
	jobId,
	guests,
	currentUserEmail,
	handleResendLink,
}) => {
	const diff = getAmountTimeLeft(date);
	const allowEditButton = diff >= DEFAULT_ALLOW_EDIT_BUTTON;
	const allowViewRecordingButton = diff <= -DEFAULT_INTERVIEW_DURATION;

	const token = Cookies.get('jwt');
	const [videoInterviewRecorded, setVideoInterviewRecorder] = useState<boolean>(false);
	const [videoInterviewLinkLoading, setVideoInterviewLinkLoading] = useState<boolean>(false);

	const handleCheckInterviewVideo = useCallback(async () => {
		try {
			setVideoInterviewLinkLoading(true);

			const videoInterviewRecording = await API.interviewService.getAtsInterviewRecordingById(
				interviewId,
			);

			setVideoInterviewRecorder(videoInterviewRecording?.videos?.length);
		} catch (e) {
			console.error('error', e);
		} finally {
			setVideoInterviewLinkLoading(false);
		}
	}, [interviewId]);

	useEffect(() => {
		const showViewRecordingButton = type?.id === InterviewTypesFormEnum.VideoOnline;

		if (showViewRecordingButton) {
			handleCheckInterviewVideo();
		}
	}, [interviewId]);

	const isVideoInterview = type?.id === InterviewTypesFormEnum.VideoOnline;

	const showResendButton = Math.abs(diff) <= DEFAULT_SHOWING_TIME_RESEND_BUTTON && isVideoInterview;

	const currentUserIsParticipant = guests.some(
		(g) => g.email?.toLocaleLowerCase() === currentUserEmail?.toLocaleLowerCase(),
	);

	const showJoinInterviewButton =
		isVideoInterview &&
		currentUserIsParticipant &&
		!videoInterviewLinkLoading &&
		!videoInterviewRecorded &&
		!allowViewRecordingButton;

	const diffResendTime = getInterviewResendTimes(interviewId);
	const allowResendButton =
		diffResendTime !== null ? Math.abs(diffResendTime) >= DEFAULT_TIME_RESEND_BUTTON : true;

	return (
		<Styled.PopoverContent>
			{date && <Styled.PopoverDate>{<>Interview Confirmed {date}</>}</Styled.PopoverDate>}
			<Styled.PopoverList>
				{guests?.map((i) => {
					const interviewStatus = i.isInterviewAccepted ? <CheckCircleIcon /> : <CrossCircleIcon />;

					return (
						<li key={i.id}>
							{interviewStatus}
							{i.fullName ? i.fullName : i.email}
						</li>
					);
				})}
			</Styled.PopoverList>

			<Styled.ButtonBox>
				{showResendButton && (
					<Button
						type='default'
						onClick={() =>
							interviewId &&
							candidate?.fullName &&
							handleResendLink(interviewId, candidate?.fullName)
						}
						disabled={!allowResendButton}
					>
						Resend invite
					</Button>
				)}
				<Link
					to={`${Routes.ATS}${Routes.ArrangeInterviewEdit}/${interviewId}`}
					state={{ candidate, jobId }}
				>
					<Button type='default' disabled={!allowEditButton}>
						Edit Interview
					</Button>
				</Link>
				{showJoinInterviewButton && (
					<Link to={`${Routes.Interview}/${interviewId}?jwt=${token}`}>
						<Button type='primary'>Join</Button>
					</Link>
				)}
				{videoInterviewRecorded && !videoInterviewLinkLoading && allowViewRecordingButton && (
					<Link to={`${Routes.ATS}${Routes.InterviewRecording}/${interviewId}`}>
						<Button type='primary'>View Interview Recording</Button>
					</Link>
				)}
			</Styled.ButtonBox>
		</Styled.PopoverContent>
	);
};

export default InterviewCalendarPopupEvent;
