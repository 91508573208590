import { createSelector } from 'reselect';

export const createSelectors = (root: string) => {
	const hrModuleRootState = createSelector([(state) => state[root]], (state) => state.data);
	const getHrModuleLoading = createSelector(
		[(state) => state[root]],
		(state) => state.loadingReducer,
	);

	const getEmployeeRoles = createSelector(hrModuleRootState, (data) => data.employeeRoles);

	const getEmployeeStatuses = createSelector(hrModuleRootState, (data) => data.employeeStatuses);

	const getEmployeesList = createSelector(hrModuleRootState, (data) => data.employeesList);

	const getEmployeeDetails = createSelector(hrModuleRootState, (data) => data.employeeDetails);

	return {
		getHrModuleLoading,
		getEmployeeRoles,
		getEmployeeStatuses,
		getEmployeesList,
		getEmployeeDetails,
	};
};
