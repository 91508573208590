import { ReactNode } from 'react';

export enum AtsNavigationMenuList {
	Dashboard = 'Dashboard',
	Clients = 'Clients',
	Vacancies = 'Vacancies',
	Interviews = 'Interviews',
	Messages = 'Messages',
	CVDatabase = 'CV Database',
	HrModule = 'HR Module',
	Checkout = 'Checkout',
	Questionnaire = 'Questionnaire',
	Credits = 'Credits',
}

export enum AtsProfileMenuList {
	SupportCenter = 'Support center',
	Finance = 'Finance',
	UserProfile = 'User Profile',
	Settings = 'Settings',
	Logout = 'Logout',
}

export interface INavListItem {
	id: number;
	name: string;
	path?: string;
	icon?: ReactNode;
	messages?: number;
	outstandingContracts?: number;
}

export enum MenuList {
	ClientContext = 'Client context',
	Dashboard = 'Dashboard',
	Clients = 'Clients',
	SupportTickets = 'Support tickets',
	Support = 'Support',
	Vacancies = 'Vacancies',
	Questionnaires = 'Questionnaires',
	Contracts = 'Contracts',
	Invoices = 'Invoices',
	Users = 'Users',
	Candidates = 'Candidates',
}
