import React, { FC, useEffect, useState } from 'react';

import Spinner from 'components/Spinner';
import { CandidateStatisticsPerStateType } from 'modules/Common/types/statistics';

import { Styled } from './CandidatePerStateStatistics.styled';
import DoughnutChart from './DoughnutChart/DoughnutChart';

type CandidatePerStateStatisticsProps = {
	candidateApplicationPerState: CandidateStatisticsPerStateType[];
	yearMonth?: string;
	loading: boolean;
	getCandidateApplicationPerState: (yearMonth?: string) => void;
};

type ChartDataType = { name: string; value: number }[];

const defaultEmptyChartList = [
	{ name: 'New candidates', value: 0 },
	{ name: 'Potential', value: 0 },
	{ name: 'Interviews', value: 0 },
	{ name: 'Offers', value: 0 },
	{ name: 'Hired', value: 0 },
];

const CandidatePerStateStatistics: FC<CandidatePerStateStatisticsProps> = ({
	candidateApplicationPerState,
	yearMonth = '',
	loading,
	getCandidateApplicationPerState,
}) => {
	const [chartData, setChartData] = useState<ChartDataType>(defaultEmptyChartList);
	useEffect(() => {
		getCandidateApplicationPerState(yearMonth);
	}, [yearMonth]);

	const sortFunction = (a: CandidateStatisticsPerStateType, b: CandidateStatisticsPerStateType) =>
		a.state.id - b.state.id;

	useEffect(() => {
		if (candidateApplicationPerState?.length) {
			const sortedStatistics = [...candidateApplicationPerState]
				.sort(sortFunction)
				.filter((item) => item.state.name !== 'Rejected');
			const transformedData = sortedStatistics.map((item) => ({
				name: item.state.name,
				value: item.timeInSeconds,
			}));

			setChartData(transformedData.length ? transformedData : defaultEmptyChartList);
		}
	}, [candidateApplicationPerState]);

	if (loading) {
		return (
			<Styled.Root>
				<Spinner />
			</Styled.Root>
		);
	}

	return (
		<Styled.Root>
			<Styled.Head>
				<Styled.Title>Average number of days at each stage</Styled.Title>
			</Styled.Head>
			<Styled.Content>
				<DoughnutChart data={chartData} />
			</Styled.Content>
		</Styled.Root>
	);
};

export default CandidatePerStateStatistics;
