import { FC } from 'react';

import { GenericType, ReactParent } from 'types';

import { Styled } from './Tag.styled';

type TagProps = ReactParent & {
	color: string;
	style?: GenericType;
};

const Tag: FC<TagProps> = ({ color, children, ...props }) => {
	return (
		<Styled.Tag color={color} {...props}>
			{children}
		</Styled.Tag>
	);
};

export default Tag;
