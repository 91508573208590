import React, { type FC, Fragment, memo, useState } from 'react';
import { Field } from 'react-final-form';

import { type RadioChangeEvent } from 'antd';

import backOfficeService from 'api/endpoints/backOffice';
import FieldWrapper from 'components/FieldWrapper';
import ButtonFileUploader from 'modules/Common/components/ButtonFileUploader';
import FieldInput from 'modules/Common/components/FieldInput';
import FormBlock from 'modules/Common/components/FormBlock';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { acceptedApplicationUploadFormFileFormat } from 'modules/Common/constants';
import {
	ApplicationFormFieldsEnum,
	AppMethodsEnum,
	FieldTypesEnum,
	FileUploadTypesEnum,
	IFileProperties,
	IPostJobValues,
	RadioFieldOption,
} from 'modules/Common/types';
import { getSingleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import { requiredFieldValidator } from 'utils/validators';

import { Styled } from './ApplicationMethod.styled';

type ApplicationMethodProps = {
	appMethodsData: RadioFieldOption[];
	defaultValue: AppMethodsEnum | string;
	defaultUploadFile?: IFileProperties | null;
	setValues?: (fieldName: keyof IPostJobValues, fieldValue: string | null) => void;
	isPaidJob?: boolean;
	readOnly?: boolean;
};

const ApplicationMethod: FC<ApplicationMethodProps> = ({
	appMethodsData,
	defaultUploadFile,
	defaultValue,
	setValues,
	isPaidJob,
	readOnly = false,
}) => {
	const [applicationMethod, setApplicationMethod] = useState<AppMethodsEnum | string>(defaultValue);
	const handleApplicationMethodChange = (e: RadioChangeEvent) => {
		setApplicationMethod(e.target.value);
	};

	const defaultFile = defaultUploadFile?.referenceUuid && {
		uid: defaultUploadFile?.referenceUuid,
		name: defaultUploadFile?.originalFileName,
		status: 'done',
		thumbUrl: '/images/file.png',
	};

	const buttonUploaderProps = getSingleFileUploaderProps(
		(url, applicationFormAttachment) => {
			setValues &&
				setValues(ApplicationFormFieldsEnum.ApplicationFormAttachment, applicationFormAttachment);
		},
		{
			accept: acceptedApplicationUploadFormFileFormat,
			...(defaultUploadFile && { defaultFileList: [defaultFile] }),
			customRequest: (options) => {
				return backOfficeService.uploadFile({
					...options,
					fileType: FileUploadTypesEnum.JobApplicationFormAttachment,
				});
			},
			onRemove: () =>
				setValues && setValues(ApplicationFormFieldsEnum.ApplicationFormAttachment, null),
		},
	);

	return (
		<FormBlock title='Application Method'>
			<FormBlockLine columns={1}>
				<Field name='applicationMethod' type='radio' validate={requiredFieldValidator}>
					{({ input, meta }) => (
						<FieldWrapper
							name='applicationMethod'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
						>
							<Styled.RadioGroup
								// {...input}
								onChange={(e) => {
									handleApplicationMethodChange(e);

									return input.onChange(e);
								}}
								value={applicationMethod}
								disabled={readOnly}
							>
								{appMethodsData?.map((option: RadioFieldOption) => {
									const isDisabled = applicationMethod !== option.value || readOnly;

									return (
										<Fragment key={option.value}>
											<Styled.RadioOption value={option.value}>{option.label}</Styled.RadioOption>
											{!!option?.fields?.length && (
												<Styled.RadioFields>
													{option?.fields?.map((field) => {
														return (
															<Fragment key={field.id}>
																{field.type === FieldTypesEnum.File && (
																	<Styled.FieldWrapper>
																		<ButtonFileUploader
																			uploadProps={buttonUploaderProps}
																			disabled={isDisabled}
																			placeholder={field.placeholder}
																			label={field?.label}
																		/>
																	</Styled.FieldWrapper>
																)}

																{field.type === FieldTypesEnum.Email && (
																	<Styled.FieldWrapper>
																		<FieldInput {...field} disabled={isDisabled} />
																	</Styled.FieldWrapper>
																)}
															</Fragment>
														);
													})}
												</Styled.RadioFields>
											)}
										</Fragment>
									);
								})}
							</Styled.RadioGroup>
						</FieldWrapper>
					)}
				</Field>
			</FormBlockLine>
		</FormBlock>
	);
};

export default memo(ApplicationMethod);
