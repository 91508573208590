import { memo } from 'react';

import { IconType } from '../SVG.types';

function FacebookIcon({ width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fill='#39393B'
				d='M17.525 9H14V7c0-1.032.084-1.682 1.563-1.682h1.868v-3.18A26.065 26.065 0 0014.693 2C11.98 2 10 3.657 10 6.7V9H7v4h3v9h4v-9.003h3.066L17.525 9z'
			></path>
		</svg>
	);
}

export default memo(FacebookIcon);
