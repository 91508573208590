import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function ArrowPrevious({ fill = COLORS.black, width = '7', height = '5' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 7 5'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g id='State=Chevron Left'>
				<path
					id='Vector 5 (Stroke)'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M3.15055 0.33075L3.1092 0.28448C2.93002 0.107428 2.64808 0.0938084 2.45309 0.243622L2.40626 0.28448L0.915114 1.75793L0.894537 1.77952L0.867119 1.81284L0.831499 1.86748L0.804934 1.92284L0.787348 1.97458L0.772964 2.04722L0.769531 2.10523L0.770917 2.14218L0.779592 2.20381L0.794254 2.25854L0.816092 2.31309L0.842098 2.36097L0.878728 2.41242L0.915114 2.45252L2.40626 3.92597C2.60037 4.11778 2.91509 4.11778 3.1092 3.92597C3.28838 3.74892 3.30216 3.47033 3.15055 3.27765L3.1092 3.23138L2.46696 2.59638H6.23708C6.5116 2.59638 6.73413 2.37648 6.73413 2.10523C6.73413 1.83397 6.5116 1.61408 6.23708 1.61408H2.46696L3.1092 0.979071C3.28838 0.802019 3.30216 0.523424 3.15055 0.33075L3.1092 0.28448L3.15055 0.33075Z'
					fill={fill}
				/>
			</g>
		</svg>
	);
}

export default memo(ArrowPrevious);
