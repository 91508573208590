import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		width: 100%;
		margin: 0;
		padding: 0;
		position: relative;
	`;
	export const Inner = styled.section`
		height: 600px;

		@media screen and (max-width: 800px) {
			height: 300px;
		}
	`;
	export const VideoUploader = styled.div`
		height: 527px;
		max-width: 1264px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: ${COLORS.lightGray2};
		border: 1px solid ${COLORS.lightGray3};
	`;
	export const VideoUloaderInner = styled.div`
		height: 192px;
		width: 680px;
		border-radius: 12px;
		padding: 24px 32px 40px;
		background: rgba(255, 255, 255, 0.4);
		border: 1px solid rgba(255, 255, 255, 0.3);
	`;
	export const VideoUloaderTitle = styled.div`
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: ${COLORS.black};
		padding-bottom: 8px;
	`;
	export const VideoUloaderField = styled.div`
		width: 100%;
		padding-bottom: 16px;

		input {
			width: 100%;
		}
	`;
	export const VideoUloaderControl = styled.div`
		z-index: 3;
		position: absolute;
		top: 20px;
		right: 40px;
	`;
	export const VideoErrorMessage = styled.div`
		font-size: 12px;
		color: ${COLORS.red};
		padding: 0 0 8px;
	`;
	export const VideoUloaderButton = styled.div`
		button {
			width: 100%;
		}
	`;
}
