import { createAsyncConstantsMap, createSyncConstantsMap } from 'utils/reduxHelpers';

export const createActionsTypes = (PREFIX: string) => {
	const asyncActions = createAsyncConstantsMap(PREFIX, [
		'GET_EMPLOYEES_ROLES',
		'GET_EMPLOYEE_STATUSES',
		'CREATE_EMPLOYEE',
		'EDIT_EMPLOYEE',
		'VIEW_EMPLOYEES_LIST',
		'ARCHIVE_EMPLOYEE',
		'UNARCHIVE_EMPLOYEE',
		'CHANGE_EMPLOYEE_STATUS',
		'GET_EMPLOYEE_BY_ID',
	]);

	const syncActions = createSyncConstantsMap(PREFIX, ['RESET_EMPLOYEE_DETAILS']);

	return {
		...asyncActions,
		...syncActions,
	};
};
