import React from 'react';
import { Field } from 'react-final-form';

import { Button, Space, Radio } from 'antd';
import _ from 'lodash';

import * as Styled from 'modules/BackOffice/containers/ClientChangesFormBranding/ClientChangesFormBranding.styled';
import FormBlock from 'modules/Common/components/FormBlock';
import FormBlockLine from 'modules/Common/components/FormBlockLine';

const CreditCheck = ({ clientChangesFormData }) => (
	<FormBlock title='Credit Check'>
		<FormBlockLine>
			<Styled.FieldWrapper>
				<Styled.ClientBaseDescription>
					Please ensure a credit check has been conducted for this client.
				</Styled.ClientBaseDescription>
				<Styled.ClientBaseDescription>
					Use your best judgement but keep an eye out for companies trading less than 2 years, those
					with micro accounts, Gazettes, directors who have a number of dissolved companies etc.
				</Styled.ClientBaseDescription>
				<Styled.Space height={24} />
				<Space wrap>
					<a
						target='_blank'
						href='https://find-and-update.company-information.service.gov.uk/'
						rel='noreferrer'
					>
						<Button type='primary' ghost>
							Companies House
						</Button>
					</a>
					<a target='_blank' href='https://companycheck.co.uk/' rel='noreferrer'>
						<Button type='primary' ghost>
							Company Check
						</Button>
					</a>
				</Space>
			</Styled.FieldWrapper>
			<div>
				<Styled.FieldWrapper>
					<Styled.FieldLabel>Has this client been credit checked?</Styled.FieldLabel>
					<Field name='creditChecked' initialValue={clientChangesFormData?.creditChecked}>
						{({ input }) => (
							<Radio.Group
								{...input}
								value={_.isBoolean(input.value) ? input.value : false}
								options={[
									{ label: 'Yes', value: true },
									{ label: 'No', value: false },
								]}
							/>
						)}
					</Field>
				</Styled.FieldWrapper>
				<Styled.Space height={24} />
				<Styled.FieldWrapper>
					<Styled.FieldLabel>Does this client require upfront payment?</Styled.FieldLabel>
					<Field
						name='requireUpfrontPayment'
						initialValue={clientChangesFormData?.requireUpfrontPayment}
					>
						{({ input }) => (
							<Radio.Group
								{...input}
								value={_.isBoolean(input.value) ? input.value : false}
								options={[
									{ label: 'Yes', value: true },
									{ label: 'No', value: false },
								]}
							/>
						)}
					</Field>
				</Styled.FieldWrapper>
			</div>
		</FormBlockLine>
	</FormBlock>
);

export default CreditCheck;
