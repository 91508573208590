import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSearchParams, useParams, useLocation } from 'react-router-dom';

import Spinner from 'components/Spinner';
import { useAudioPermission, useVideoPermission } from 'hooks/useMediaPermission';
import useNetwork from 'hooks/useNetwork';
import HeroHeader from 'modules/Common/components/HeroHeader';
import QuestionnaireCheck from 'modules/Common/components/QuestionnareSystemCheck';
import { DEFAULT_CAREER_BG } from 'modules/Common/constants';
import {
	MediaPermissionType,
	WellcomeInfoType,
	QuestionnariesRowDetailsType,
} from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { GenericType, Routes } from 'types';

import { Styled } from './Questionnaire.styled';
import QuestionnaireWelcome from './QuestionnaireWelcome';
import QuestionnaireCompleting from './QuestionnarireCompeting';

type QuestionnaireProps = {
	loading: GenericType;
	getWelcomeInfo: (qstId: string, pwd: string) => void;
	getQuesionnaireDetails: (pwd: string) => void;
	welcomeInfo?: WellcomeInfoType;
	questionnaireDetails?: QuestionnariesRowDetailsType;
};

const baseURL = `${process.env.REACT_APP_API_URL}/open/public/file/logo/`;

const Questionnaire: FC<QuestionnaireProps> = ({
	loading,
	getQuesionnaireDetails,
	questionnaireDetails,
	getWelcomeInfo,
	welcomeInfo,
}) => {
	const [searchParams] = useSearchParams();
	const pwd = searchParams.get('pwd');
	const { questionnaireId } = useParams();
	const { pathname } = useLocation();
	const isVideoQuestionnairePage = pathname.includes(Routes.VideoQuestionnaire);

	const [currentStep, setStep] = useState(0);
	const online: boolean = useNetwork();
	const isVideoAvailable: MediaPermissionType = useVideoPermission();
	const isAudioAvailable: MediaPermissionType = useAudioPermission();
	const notProvidedAllPermissions = !isAudioAvailable.value || !isVideoAvailable.value || !online;
	const [questionnaireCompleted, setQuestionnaireCompleted] = useState(false);

	const nextStep = () => {
		setStep((step) => step + 1);
	};

	useEffect(() => {
		if (pwd && questionnaireId) {
			getWelcomeInfo(questionnaireId, pwd);
			getQuesionnaireDetails(pwd);
		}
	}, [pwd, questionnaireId]);

	useEffect(() => {
		const isAllQuestionAnswered = questionnaireDetails?.questions.every((q) => q.wasAnswered);

		if (isAllQuestionAnswered) {
			setQuestionnaireCompleted(true);
			setStep(2);
		}
	}, [questionnaireDetails]);

	if (!welcomeInfo || loading.getQuestionnareWelcomeLoad) {
		return <Spinner fullWidth />;
	}

	const { backgroundImage = DEFAULT_CAREER_BG, expiredOrCanceled } = welcomeInfo;
	const backgroundImageURL =
		backgroundImage && !backgroundImage?.startsWith('/images')
			? `${baseURL}${backgroundImage}`
			: backgroundImage;

	if (expiredOrCanceled) {
		return (
			<Styled.ExpiredLinkWrapper>
				<Styled.ExpiredLinkInner>
					The questionnaire has been recalled. Please contact company for more details
				</Styled.ExpiredLinkInner>
			</Styled.ExpiredLinkWrapper>
		);
	}

	return (
		<Styled.Root>
			<HeroHeader
				backgroundImageUrl={backgroundImageURL}
				minHeight='200px'
				maxHeight={currentStep === 0 ? '342px' : '200px'}
				height='100%'
			/>
			{!loading.getQuestionnareDetailsLoad && questionnaireDetails ? (
				<Styled.Content>
					{(notProvidedAllPermissions && !isVideoQuestionnairePage) || currentStep === 1 ? (
						<QuestionnaireCheck
							description={
								'After you click Start Recording, you will be given the question and 30 seconds to prepare your answer. Once ready, click Start Now to record.'
							}
							title='Video Questionnaires'
							online={online}
							isAudioAvailable={isAudioAvailable}
							isVideoAvailable={isVideoAvailable}
							handleStart={() => setStep(2)}
							buttonText={'Start Video Questionnaire'}
						/>
					) : currentStep === 0 ? (
						<QuestionnaireWelcome welcomeInfo={welcomeInfo} handleStartQuestionnaire={nextStep} />
					) : currentStep === 2 ? (
						<QuestionnaireCompleting
							getQuesionnaireDetails={getQuesionnaireDetails}
							questionnaireDetails={questionnaireDetails}
							pwd={pwd || ''}
							questionnaireCompleted={questionnaireCompleted}
						/>
					) : null}
				</Styled.Content>
			) : (
				<Spinner fullWidth />
			)}
			<Styled.Footer>
				<Styled.FooterInner>
					<Styled.FooterContent>
						<Styled.FooterItem>
							<a href='/documents/bluetownonline-terms.pdf' target='_blank' rel='noreferrer'>
								Terms and Conditions
							</a>
						</Styled.FooterItem>
						<Styled.FooterItem>©Bluetown 2024</Styled.FooterItem>
					</Styled.FooterContent>
				</Styled.FooterInner>
			</Styled.Footer>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		welcomeInfo: unregisteredDucks.unregisteredSelectors.getQuestionnaireWellcomeState(state),
		loading: unregisteredDucks.unregisteredSelectors.getLoading(state),
		questionnaireDetails:
			unregisteredDucks.unregisteredSelectors.getQuestionnaireDetailsState(state),
	}),
	{
		getWelcomeInfo: unregisteredDucks.unregisteredActions.getQuestionnaireWelcome,
		getQuesionnaireDetails: unregisteredDucks.unregisteredActions.getQuestionnaireDetails,
	},
)(Questionnaire);
