import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useEffectOnce = (effect: () => void) => {
	// eslint-disable-next-line  react-hooks/exhaustive-deps
	useEffect(effect, []);
};

export const useMount = (fn: () => void) => {
	useEffectOnce(() => {
		fn();
	});
};

export const useUnmount = (fn: () => void) => {
	useEffectOnce(() => fn);
};


export const useCountdown = (min:number, sec:number, setActive:boolean) => {
	const [minutes, setMinutes] = useState<number>(min);
	const [seconds, setSeconds] = useState<number>(sec);
	const [isActive, setIsActive] = useState<boolean>(setActive);
 
	useEffect(() => {
		let interval = null;
		if (isActive && (minutes > 0 || seconds > 0)) {
			interval = setInterval(() => {
				if (seconds === 0) {
					setMinutes(minutes - 1);
					setSeconds(59);
				} else {
					setSeconds(seconds - 1);
				}
			}, 1000);
		} else {
			setIsActive(false);
			setMinutes(min);
			setSeconds(sec);
			clearInterval(interval);
		}
 
		return () => clearInterval(interval);
	}, [isActive, minutes, seconds]);

	return { minutes, seconds, isActive, setIsActive };
};

interface UsePollingEffectOptions {
	interval?: number;
	onCleanUp?: () => void;
	stopPollingPaths?: string[];
}

export const usePollingEffect = (
	asyncCallback: () => void,
	dependencies: unknown[] = [],
	{ interval = 10000, onCleanUp, stopPollingPaths = [] }: UsePollingEffectOptions = {},
) => {
	const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
	const location = useLocation();

	useEffect(() => {
		let stopped = false;

		const pollingCallback = async () => {
			try {
				await asyncCallback();
			} finally {
				if (!stopped) {
					timeoutIdRef.current = setTimeout(pollingCallback, interval);
				}
			}
		};

		const startPolling = () => {
			stopped = false;
			pollingCallback();
		};

		const stopPolling = () => {
			stopped = true;
			if (timeoutIdRef.current) {
				clearTimeout(timeoutIdRef.current);
			}
		};

		const shouldStopPolling = stopPollingPaths.some((path) => location.pathname.includes(path));
		if (shouldStopPolling) {
			stopPolling();
		} else {
			startPolling();
		}

		return () => {
			stopPolling();
			if (onCleanUp) {
				onCleanUp();
			}
		};
	}, [...dependencies, interval, location.pathname]);
};
