import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		background: ${COLORS.white};
		padding: 24px;
		border-radius: 6px;
		margin-bottom: 24px;
		position: relative;
		min-height: 508px;

		#legend-container {
			display: flex;
			overflow: hidden;
			padding-top: 22px;
		}
	`;

	export const Header = styled.div`
		padding-bottom: 12px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	`;

	export const Title = styled.div`
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
	`;

	export const ViewAllLink = styled.div`
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
	`;

	export const ChartWrapper = styled.div`
		height: 292px;
	`;

	export const LegendWrapper = styled.div`
		margin-left: -16px;
		padding-top: 40px;
	`;

	export const NoActiveJobs = styled.div`
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		min-height: 300px;
	`;
	export const CVIconContainer = styled.div`
		position: absolute;
		bottom: 0;
		left: 0;
	`;
	export const NoJobsText = styled.div`
		max-width: 400px;
		text-align: center;
		line-height: 24px;
	`;
}
