import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		position: relative;
		width: 100%;
		min-height: 100%;
		font-weight: 400;

		h2 {
			margin-bottom: 24px;
		}

		h4 {
			font-weight: 500;
		}

		p {
			margin-bottom: 24px;
		}

		form {
			display: flex;
			flex-direction: column;
			height: 100%;
		}
	`;

	export const ButtonWrapper = styled.div`
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		padding-top: 36px;
	`;

	export const FieldHint = styled.section`
		margin-bottom: 12px;
		color: ${COLORS.darkGray2};
		font-weight: 400;
		font-size: 14px;
	`;
}
