import styled from 'styled-components/macro';

import { COLORS } from 'theme';

interface ITotalSMSSecondLineProps {
	buyNow?: boolean;
}

export namespace Styled {
	export const Head = styled.section`
		display: flex;
		justify-content: space-between;
		align-items: center;
	`;

	export const AvailableSMSLimitsBox = styled.div`
		display: flex;
		border-radius: 4px;
		padding: 8px 16px;
		background-color: ${COLORS.white};
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		cursor: pointer;
	`;

	export const LeftSMS = styled.div`
		color: ${COLORS.darkGray3};
		font-size: 40px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;
		margin-right: 8px;
	`;

	export const TotalSMS = styled.div``;

	export const TotalSMSFirstLine = styled.div`
		color: ${COLORS.black};
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	`;

	export const TotalSMSSecondLine = styled.div<ITotalSMSSecondLineProps>`
		color: ${({ buyNow }) => (buyNow ? COLORS.blue : COLORS.darkGray1)};
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	`;

	export const Content = styled.section`
		h2 {
			margin-bottom: 16px;
		}
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
		margin-bottom: 32px;
		gap: 16px;
	`;
}
