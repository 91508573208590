import { createAsyncConstantsMap } from 'utils/reduxHelpers';

export const createActionsTypes = (PREFIX) => {
	const asyncActions = createAsyncConstantsMap(PREFIX, [
		'CHECK_CLIENT_INFO',
		'GET_QUESTIONNAIRE_WELCOME',
		'GET_QUESTIONNAIRE_DETAILS',
		'GET_CURRENT_QUESTION',
		'GET_EMAIL_THREAD',
		'REPLY_TO_EMAIL_THREAD',
		'CHANGE_PASSWORD',
		'FORGOT_PASSWORD',
		'RESET_PASSWORD',
		'GET_USER',
		'LOGIN',
		'TWOFA_LOGIN',
		'RESEND_TWOFA_CODE',
		'IMPERSONATE',
		'UN_IMPERSONATE',
		'LOGOUT',
	]);

	return { ...asyncActions };
};
