import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Checkbox } from 'antd';
import Cookies from 'js-cookie';

import { ButtonTypes } from 'components/Button/Button.types';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import ContractView from 'modules/Common/containers/ContractView';
import { commonDucks } from 'modules/Common/ducks';
import { ContractStatusValuesEnum, IContractValues } from 'modules/Common/types';
import { GenericType, Routes, CookiesType, UserTypesValueEnum } from 'types';

import { Styled } from './ATSAcceptContractView.styled';

type ATSAcceptContractViewProps = {
	acceptContractById: (contractId: string, cb: () => void, platform: UserTypesValueEnum) => void;
	contractId?: string;
	contract: IContractValues;
	loading?: GenericType;
};

const ATSAcceptContractView: FC<ATSAcceptContractViewProps> = ({
	acceptContractById,
	contractId,
	contract,
	loading,
}) => {
	const navigate = useNavigate();
	const [checked, setChecked] = useState<boolean>(false);
	const isImpersonate = !!Cookies.get(CookiesType.boImpersonateJWT);

	const handleCheck = () => {
		setChecked((checkedState) => !checkedState);
	};

	const handleAcceptContract = () => {
		contractId &&
			acceptContractById(
				contractId,
				() => navigate(`${Routes.ATS}${Routes.Finance}`),
				UserTypesValueEnum.ATS,
			);
	};

	const isLoading =
		loading?.getContractByIdLoad || loading?.getContractVatLoad || loading?.getCreditsLoad;

	const isOutstanding = contract?.contractState?.value === ContractStatusValuesEnum.Outstanding;

	return (
		<>
			<Styled.Main>
				<ContractView contractId={contractId} />
			</Styled.Main>
			{isOutstanding && !isLoading && !isImpersonate && (
				<Styled.Foot>
					<p>
						Accepting a contract will automatically increase your credits and generate an invoice.
						Please note: on acceptance this is a legally binding agreement.
					</p>
					<p>
						<Checkbox checked={checked} onChange={handleCheck}>
							{'I accept '}
							<a target='_blank' href={'/documents/contracts-terms.pdf'} rel='noreferrer'>
								terms and conditions
							</a>
							{' and '}
							<a
								href={'/documents/bluetownonline-privacy-policy.pdf'}
								target='_blank'
								rel='noreferrer'
							>
								privacy policy
							</a>
							.
						</Checkbox>
					</p>
					<Styled.ButtonBox>
						<ATSThemedButton
							type='button'
							buttonType={ButtonTypes.primary}
							onClick={handleAcceptContract}
							disabled={!checked}
						>
							Accept Contract
						</ATSThemedButton>
					</Styled.ButtonBox>
				</Styled.Foot>
			)}
		</>
	);
};

export default connect(
	(state) => ({
		contract: commonDucks.commonSelectors.getContractByIdDetails(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		acceptContractById: commonDucks.commonActions.acceptContractByIdRequested,
	},
)(ATSAcceptContractView);
