import { MESSAGES } from 'modules/Common/constants';
import { AppMethodsEnum, IPostJobValues } from 'modules/Common/types';
import { composeValidators, emailValidator, requiredFieldValidator } from 'utils/validators';

export const dynamicFormValidation = (values: IPostJobValues, isPaidJob: boolean) => {
	const errors = {};

	if (
		values?.applicationMethod === AppMethodsEnum.cvAndCoverLetterEmail &&
		composeValidators(requiredFieldValidator, emailValidator)(values?.applicationEmail)
	) {
		errors.applicationEmail = MESSAGES.validateEmailMessage;
	}

	if (
		values?.applicationMethod === AppMethodsEnum.applicationForm &&
		!isPaidJob &&
		requiredFieldValidator(values?.applicationFormUrl)
	) {
		errors.applicationFormUrl = MESSAGES.mandatoryField;
	}

	return errors;
};
