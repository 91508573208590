import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function CameraIcon({ active, width = '80', height = '80' }: IconType) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='80' height='80' fill='none' viewBox='0 0 80 80'>
			<g filter='url(#filter0_d_188_1255)'>
				<rect
					width='56'
					height='56'
					x='12'
					y='8'
					fill='#fff'
					fillOpacity='0.5'
					rx='28'
					shapeRendering='crispEdges'
				></rect>
				<path
					fill={active ? COLORS.black : COLORS.red}
					fillRule='evenodd'
					d='M47.444 30.534a1.6 1.6 0 00-.777 1.372v8.188a1.6 1.6 0 00.776 1.372l5.134 3.08A1.6 1.6 0 0055 43.174V28.826a1.6 1.6 0 00-2.423-1.372l-5.133 3.08zM27 27.667a2 2 0 00-2 2v12.666a2 2 0 002 2h14.333a2 2 0 002-2V29.667a2 2 0 00-2-2H27z'
					clipRule='evenodd'
				></path>
			</g>
			<defs>
				<filter
					id='filter0_d_188_1255'
					width='80'
					height='80'
					x='0'
					y='0'
					colorInterpolationFilters='sRGB'
					filterUnits='userSpaceOnUse'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
					<feColorMatrix
						in='SourceAlpha'
						result='hardAlpha'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
					></feColorMatrix>
					<feOffset dy='4'></feOffset>
					<feGaussianBlur stdDeviation='6'></feGaussianBlur>
					<feComposite in2='hardAlpha' operator='out'></feComposite>
					<feColorMatrix values='0 0 0 0 0.387326 0 0 0 0 0.436308 0 0 0 0 0.479167 0 0 0 0.16 0'></feColorMatrix>
					<feBlend in2='BackgroundImageFix' result='effect1_dropShadow_188_1255'></feBlend>
					<feBlend in='SourceGraphic' in2='effect1_dropShadow_188_1255' result='shape'></feBlend>
				</filter>
			</defs>
		</svg>
	);
}

export default memo(CameraIcon);
