import React from 'react';

import { ColumnsDataType } from 'components/Table/Table.types';
import Tag from 'modules/Common/components/Tag';
import { CreditIcons, SubscriptionTypeColours } from 'modules/Common/constants';
import { TableColumnsType, SubscriptionTypesEnum, ClientTableType } from 'modules/Common/types';
import { GenericType } from 'types';

import CreditTag from '../../components/CreditTag';

export const columns = (): ColumnsDataType & TableColumnsType => [
	{
		title: 'Client Name',
		dataIndex: 'clientName',
		key: 'clientName',
		render: (name) => <span>{name}</span>,
	},
	{
		title: 'Active Jobs',
		dataIndex: 'numberOfActiveJobs',
		key: 'numberOfActiveJobs',
		align: 'center',
		render: (_, { numberOfActiveJobs }) => numberOfActiveJobs ?? 0,
	},
	{
		title: 'Subscription',
		dataIndex: 'subscription',
		key: 'subscription',
		align: 'center',
		render: (_, { subscription }: GenericType) => {
			const color: SubscriptionTypesEnum = subscription && SubscriptionTypeColours[subscription];

			return (
				<Tag color={color}>
					<>{subscription ?? '-'}</>
				</Tag>
			);
		},
	},
	{
		title: 'Credits',
		key: 'clientCredit',
		dataIndex: 'clientCredit',
		align: 'center',
		render: (
			_,
			{
				numberOfPremiumMultisiteCredits,
				numberOfCvFilteringCredits,
				numberOfGuardianCredits,
				numberOfIndeedCredits,
			}: Partial<ClientTableType>,
		) => (
			<>
				<CreditTag amount={numberOfPremiumMultisiteCredits ?? 0} icon={CreditIcons[1]} />
				<CreditTag amount={numberOfCvFilteringCredits ?? 0} icon={CreditIcons[2]} />
				<CreditTag amount={numberOfGuardianCredits ?? 0} icon={CreditIcons[3]} />
				<CreditTag amount={numberOfIndeedCredits ?? 0} icon={CreditIcons[4]} />
			</>
		),
	},
];
