import styled from 'styled-components/macro';

import { COLORS, device } from 'theme';

interface ISelfContainerProps {
	isSelfVideoReady: boolean;
	participantIsSpeaking: boolean;
}

interface MembersIconProps {
	active: boolean;
	onClick: () => void;
}

interface IVideoContainerProps {
	participantsAmount: number;
}

export namespace Styled {
	export const Root = styled.main`
		width: 100%;
		max-width: 1368px;
		margin: 0 auto;
		padding: 56px;
		background-color: ${COLORS.white};
	`;
	export const Content = styled.section`
		display: flex;

		@media ${device.tablet} {
			flex-direction: column;
		}
	`;
	export const VideoContainer = styled.div<IVideoContainerProps>`
		${({ participantsAmount }) => {
		if (participantsAmount === 0) {
			return 'height: 625px;';
		}
		if (participantsAmount === 1) {
			return `
                display: grid;
                grid-template-columns: repeat(2, 2fr);
                column-gap: 16px;
                grid-template-areas:"A B";
            `;
		}
		if (participantsAmount === 2) {
			return `
                display: grid;
                grid-auto-flow: column;
                grid-template-areas: 
                "A B"
                "A C";
                column-gap: 16px;
                row-gap: 16px;
                grid-template-columns: repeat(2, 2fr);

                video {
                    height: 100%;
                }
            `;
		}

		return `
            display: grid;
            grid-auto-flow: column;
            grid-template-areas: 
            "A B E"
            "C D E";
            column-gap: 16px;
            row-gap: 16px;
            grid-template-columns: repeat(2, 2fr);
        `;
	}};

		width: 100%;

		@media ${device.tablet} {
			display: block;

			> div {
				margin-bottom: 8px;
			}
		}
	`;
	export const RecordingLabel = styled.div`
		position: absolute;
		z-index: 5;
		top: 24px;
		left: 24px;
		border-radius: 4px;
		background: rgba(255, 255, 255, 0.5);
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
		text-align: center;
		padding: 4px 16px;
	`;
	export const Recording = styled.div`
		display: flex;
		align-items: center;
	`;
	export const RecordingDot = styled.div`
		width: 12px;
		height: 12px;
		background-color: ${COLORS.red};
		border-radius: 50%;
		overflow: hidden;
		margin-right: 8px;
	`;
	export const SelfVideoContainer = styled.div<ISelfContainerProps>`
		grid-area: A;
		position: relative;
		overflow: hidden;
		${({ isSelfVideoReady }) => (isSelfVideoReady ? 'height: auto;' : 'height: 1px;')}
		border: ${({ participantIsSpeaking }) =>
		participantIsSpeaking ? '3px solid ' + COLORS.green : 'none'};
		max-height: 100%;
		background-color: black;
		display: flex;
		align-items: center;
	`;
	export const SelfVideoMute = styled.div`
		cursor: pointer;
	`;
	export const SelfVideoCamera = styled.div`
		cursor: pointer;
	`;
	export const Video = styled.video`
		width: 100%;
		height: 100%;
		background-color: ${COLORS.black};
	`;
	export const Canvas = styled.canvas`
		width: 100%;
		height: auto;
		aspect-ratio: 16/9;
		background-color: ${COLORS.black};
	`;
	export const PseudoCanvas = styled.div`
		width: 100%;
		height: 1000px;
		background-color: ${COLORS.black};
		position: absolute;
		bottom: 0;
		z-index: 3;
	`;
	export const VideoPlayerContainer = styled.div`
		width: 100%;
		height: 1000px;
	`;
	export const VideoPlayer = styled.video`
		width: 100%;
		height: auto;
		aspect-ratio: 16/9;
	`;
	export const ButtonsControlWrapper = styled.div`
		display: flex;
		position: absolute;
		bottom: 0;
		z-index: 3;
		left: calc(50% - 80px);
	`;

	export const VideoInterviewHeader = styled.div`
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	`;
	export const VideoInterviewTitle = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
	`;
	export const VideoInterviewHeaderButtons = styled.div`
		display: grid;
		grid-template-columns: 40px 125px 95px;
		column-gap: 16px;
	`;
	export const MembersIcon = styled.div<MembersIconProps>`
		width: 40px;
		height: 40px;
		padding: 8px;
		border-radius: 4px;
		border: 1px solid ${COLORS.gray};
		background: ${COLORS.lightGray3};
		cursor: pointer;

		${({ active }) =>
		active &&
			`
            border: 1px solid ${COLORS.darkGray3};
            background: ${COLORS.darkGray3};
        `}
	`;
	export const VideoControlsContainer = styled.div`
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;

		@media ${device.tablet} {
			flex-direction: column;
		}
	`;
	export const VideoInterviewDescription = styled.div`
		padding: 16px 0;
		font-family: Inter;
		color: ${COLORS.black};
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	`;
}
