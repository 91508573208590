import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams, useParams } from 'react-router-dom';

import Spinner from 'components/Spinner';
import BlueTownSmallLogo from 'components/SVG/BlueTownSmallLogo';
import { useMount } from 'hooks';
import EmailThreadComponent from 'modules/Common/components/EmailThreadComponent';
import HeroHeader from 'modules/Common/components/HeroHeader';
import ReplyEmailForm from 'modules/Common/containers/ReplyEmailForm';
import { EmailThreadType } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { GenericType } from 'types';

import { Styled } from './ReplyToEmail.styled';

type QuestionnaireProps = {
	emailThread: EmailThreadType;
	loading: GenericType;
	getEmailThread: (threadId: string, pwd: string, cb: () => void) => void;
	replyToEmailThread: (
		threadId: string,
		pwd: string,
		data: { content: string; attachments: string[] },
		cb: () => void,
	) => void;
};

const baseUrl = `${process.env.REACT_APP_API_URL}/open/public/file/logo/`;

const ReplyToEmail: FC<QuestionnaireProps> = ({
	emailThread,
	loading,
	getEmailThread,
	replyToEmailThread,
}) => {
	const { emailThreadId } = useParams();
	const [searchParams] = useSearchParams();
	const pwd = searchParams.get('pwd');
	const [wrongIdThreadOrPwd, setWrongIdThreadOrPwd] = useState(false);
	const { logo, backgroundImage, highlightColour, client } = emailThread || {};

	useMount(() => {
		if (emailThreadId && pwd) {
			getEmailThread(emailThreadId, pwd, () => setWrongIdThreadOrPwd(true));
		} else {
			setWrongIdThreadOrPwd(true);
		}
	});

	const handleSubmitReply = (
		values: { content: string; attachments: string[] },
		cb: () => void,
	) => {
		if (emailThreadId && pwd) {
			replyToEmailThread(emailThreadId, pwd, values, cb);
		}
	};

	if (loading?.getEmailThreadLoad) {
		return <Spinner fullWidth />;
	}

	if (wrongIdThreadOrPwd) {
		return (
			<Styled.ExpiredLinkWrapper>
				<Styled.ExpiredLinkInner>
					Something is wrong with the link. Please contact company for more details
				</Styled.ExpiredLinkInner>
			</Styled.ExpiredLinkWrapper>
		);
	}

	const submitButtonLoading = !!loading?.replyToEmailThreadLoad;

	const logoUrl = logo?.split('/').includes('images') ? logo : logo && `${baseUrl}${logo}`;

	const backgroundImageUrl = backgroundImage?.split('/').includes('images')
		? backgroundImage
		: backgroundImage && `${baseUrl}${backgroundImage}`;

	return (
		<Styled.Root>
			<HeroHeader backgroundImageUrl={backgroundImageUrl || ''} minHeight='264px' width='100%' />
			<Styled.Info>
				<Styled.Logo>
					<Styled.ImageWrapper>
						{logoUrl ? (
							<img src={logoUrl} alt='Client Logo' />
						) : (
							<BlueTownSmallLogo width='128px' height='128px' fill='blue' />
						)}
					</Styled.ImageWrapper>
				</Styled.Logo>
				<Styled.ClientName textColor={highlightColour || ''}>{client}</Styled.ClientName>
			</Styled.Info>
			<Styled.Content>
				<Styled.ContentHeader highlightColour={highlightColour || ''}>
					{emailThread?.topic || ''}
				</Styled.ContentHeader>
				<EmailThreadComponent emailThread={emailThread} pwd={pwd || ''} />
				<ReplyEmailForm
					submitButtonLoading={submitButtonLoading}
					handleSubmitReply={handleSubmitReply}
				/>
			</Styled.Content>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		emailThread: unregisteredDucks.unregisteredSelectors.getCurrentEmailThread(state),
		loading: unregisteredDucks.unregisteredSelectors.getLoading(state),
	}),
	{
		getEmailThread: unregisteredDucks.unregisteredActions.getEmailThreadRequested,
		replyToEmailThread: unregisteredDucks.unregisteredActions.replyToEmailThreadRequested,
	},
)(ReplyToEmail);
