import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import ATSClientCredits from 'modules/ATS/containers/ATSClientCredits';
import { atsDucks } from 'modules/ATS/ducks';
import { ClientBrandingDataType, AtsNavigationMenuList } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { COLORS } from 'theme';
import { CountriesType, Routes, SubscriptionPlansType } from 'types';

import ATSJobsList from '../../containers/ATSJobsList';

import { Styled } from './ATSJobsListPage.styled';

type ATSJobsListPageProps = {
	countries: CountriesType;
	subscriptionPlans: SubscriptionPlansType;
	clientBrandingData: ClientBrandingDataType;
};

const ATSJobsListPage: FC<ATSJobsListPageProps> = ({
	countries,
	subscriptionPlans,
	clientBrandingData,
}) => {
	const navigate = useNavigate();
	const { isPremiumPlan } = subscriptionPlans;

	const { isUK } = countries || {};

	return (
		<>
			<Styled.ATSClientCredits>
				{isUK && (
					<>
						<ATSClientCredits />
						<Styled.ATSCreateCredits>
							<Button
								buttonColor={isPremiumPlan ? clientBrandingData?.highlightColour : COLORS.blue}
								buttonType={ButtonTypes.secondary}
								onClick={() => navigate(`${Routes.ATS}${Routes.Credits}`)}
							>
								Buy Credits
							</Button>
						</Styled.ATSCreateCredits>
					</>
				)}
			</Styled.ATSClientCredits>
			<Styled.Head>
				<Styled.Title>{AtsNavigationMenuList.Vacancies}</Styled.Title>
			</Styled.Head>
			<Styled.Content>
				<Styled.ButtonBox>
					<Button
						buttonColor={isPremiumPlan ? clientBrandingData?.highlightColour : COLORS.blue}
						buttonType={ButtonTypes.primary}
						onClick={() => navigate(`${Routes.ATS}${Routes.PostJob}`)}
					>
						Post a job
					</Button>
				</Styled.ButtonBox>
				<Styled.TableBox>
					<ATSJobsList />
				</Styled.TableBox>
			</Styled.Content>
		</>
	);
};

export default connect((state) => ({
	countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
	subscriptionPlans: atsDucks.atsSelectors.getSubscriptionPlans(state),
	clientBrandingData: atsDucks.atsSelectors.getClientAtsBrandingData(state),
}))(ATSJobsListPage);
