import { ContractStatusEnum } from 'modules/Common/types';

export const ContractFields = [
	'id',
	'contractId',
	'clientName',
	'client',
	'btoReference',
	'relatedInvoice',
	'totalPrice',
	'startDate',
	'contractState',
	'invoices',
	'accepted',
	'creator',
	'currency',
];

export const StatusStylesData = {
	[ContractStatusEnum.FULFILLED]: {
		color: '#d46b08',
		background: '#fff7e6',
		borderColor: '#ffd591',
	},
	[ContractStatusEnum.OUTSTANDING]: {
		color: '#0958d9',
		background: '#e6f4ff',
		borderColor: '#91caff',
	},
	[ContractStatusEnum.ACCEPTED]: {
		color: '#389e0d',
		background: '#f6ffed',
		borderColor: '#b7eb8f',
	},
	[ContractStatusEnum.PENDING_APPROVAL]: {
		color: '#5c5c60',
		background: '#f5f5f5',
		borderColor: '#d9d9d9',
	},
};
