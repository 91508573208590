import { Component } from 'react';

import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';

import { Styled } from './ErrorBoundary.styled';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.error(error, errorInfo);
	}

	render() {
		const { errorMessage } = this.props;
		const defaultErrorMessage = 'Something went wrong.';

		if (this.state.hasError) {
			return (
				<Styled.MessageWrapper>
					<ExclamationCircleOutlined />
					<Styled.Message>{errorMessage || defaultErrorMessage}</Styled.Message>
				</Styled.MessageWrapper>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
