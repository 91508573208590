import styled from 'styled-components/macro';

import COLORS from 'common/colors';

interface SpaceProps {
	height: number;
}

export const FieldWrapper = styled.div`
	padding-right: 32px;
	height: 100%;
	.ant-select {
		width: 100%;
	}
`;

export const FieldLabel = styled.div`
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: ${COLORS.black};
	padding-bottom: 8px;
`;

export const FieldHint = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: ${COLORS.darkGray2};
	margin-top: 8px;
`;

export const FieldError = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: ${COLORS.red};
	margin-top: 8px;
`;

export const ClientBaseDescription = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: ${COLORS.black};
	max-width: 484px;
	padding-bottom: 8px;
`;

export const Space = styled.div<SpaceProps>`
	height: ${({ height }) => `${height}px`};
`;

export const SubmitContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding-top: 24px;
	padding-bottom: 24px;
	gap: 4px;
`;

export const CreditCheckFooter = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding-top: 24px;
`;

export const BundleRow = styled.div`
	display: flex;
	align-items: flex-start;
	margin-top: 32px;
`;

export const BundlesWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const DefaultCredits = styled.div`
	width: 30%;
`;

export const BundlesSection = styled.div`
	width: 70%;
	padding-left: 16px;
`;

export const PaymentTypeField = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 16px;

	.ant-input {
		width: 100%;
		max-width: 160px;
	}

	.ant-select {
		width: 100%;
		max-width: 160px;
	}
`;

export const CreditDesc = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: ${COLORS.darkGray2};
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	padding-right: 36px;
	height: auto !important;
`;
