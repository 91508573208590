import React, { type FC } from 'react';
import { useParams } from 'react-router-dom';

import CandidateDatabaseApplicationDetails from 'modules/ATS/containers/CandidateDatabaseApplicationDetails';
import { CandidateTabsTitlesEnum } from 'modules/ATS/containers/CandidatesApplication/CandidatesApplication.types';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { Routes } from 'types';

import { Styled } from './ATSCandidateDatabasePage.styled';

const ATSCandidateDatabasePage: FC = () => {
	const { applicationId } = useParams();

	if (!applicationId) {
		return null;
	}

	return (
		<>
			<Styled.Head>
				<PageNavTitle
					title={'Back to candidates database'}
					navigationLink={`${Routes.ATS}${Routes.Candidates}`}
					state={{ activeTab: CandidateTabsTitlesEnum.CandidatesDatabase }}
				/>
			</Styled.Head>
			<Styled.Content>
				<CandidateDatabaseApplicationDetails applicationId={Number(applicationId)} />
			</Styled.Content>
		</>
	);
};

export default ATSCandidateDatabasePage;
