import React, { type FC, useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import PhoneInput from 'react-phone-number-input';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { UploadOutlined } from '@ant-design/icons';
import { Input, Select, Button as AntButton, Checkbox } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';

import backOfficeService from 'api/endpoints/backOffice';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import JobApplyBgIcon from 'components/SVG/JobApplyBG';
import {
	MAX_FILE_SIZE_FOR_APPLICATIONS,
	acceptedApplicationUploadFormFileFormat,
	MESSAGES,
} from 'modules/Common/constants';
import { commonDucks } from 'modules/Common/ducks';
import { AppMethodsEnum, JobDataType } from 'modules/Common/types';
import { checkMaxFileSize } from 'modules/Common/utils/commonHelpers';
import { getUploader } from 'modules/Common/utils/docsUploader';
import { Routes } from 'types';
import { prefixLink } from 'utils/helpers';
import {
	requiredFieldValidator,
	composeValidators,
	requiredValidator,
	emailValidator,
	phoneValidator,
	lengthValidator,
	textWithoutNewLinesTabsAndCarriageReturnRegExp,
} from 'utils/validators';

import ThanksMessageModal from '../../components/ThanksMessageModal';

import { Styled } from './JobApply.styled';

const { Option } = Select;
const { TextArea } = Input;

type JobApplyProps = {
	mode?: string;
	applyOnJobLoading: boolean;
	clientName: string;
	highlightColour?: string;
	jobApplyState: {
		assessment: object[];
		job?: number;
		fullName?: string;
		email?: string;
		phoneNumber?: string;
		uploadedCoverLetter?: string;
		uploadedCV?: string;
		coverLetter?: string;
		includeToCandidateDatabase?: boolean;
	};
	jobsData?: JobDataType[];
	getJobAssessmentRequested: (id: number) => void;
	applyOnJobRequested: (values: object, callback: () => void) => void;
	setJobApplyState: (values: object) => void;
};

const JobApply: FC<JobApplyProps> = ({
	jobsData,
	jobApplyState,
	applyOnJobLoading,
	getJobAssessmentRequested,
	applyOnJobRequested,
	setJobApplyState,
	highlightColour,
	clientName,
}) => {
	const [uploadedCoverLetter, setCoverLetterLink] = useState('');
	const [uploadedCV, setCVLink] = useState('');
	const [coverLetterFileList, setCoverLetterFileList] = useState<UploadFile[]>([]);
	const [cvFileList, setCvFileList] = useState<UploadFile[]>([]);
	const [cvValidationError, setCvValidationError] = useState(false);
	const [coverLetterValidationError, setCoverLetterValidationError] = useState(false);
	const { jobId: jobIdParams } = useParams();
	const navigate = useNavigate();
	const { pathname, search } = useLocation();
	const isAssessmentComplete = new URLSearchParams(search)?.get('isAssessmentComplete');

	const hasCurrentJobAssessment = !!jobApplyState.assessment?.length;
	const [currentJob, setCurrentJob] = useState<JobDataType | undefined>(undefined);
	const [modalOpen, setModalOpen] = useState<boolean>(false);

	const isUrlApplyJob =
		currentJob?.applicationFormUrl &&
		!currentJob?.applicationFormAttachment &&
		currentJob?.applicationMethod === AppMethodsEnum.applicationForm;

	const onSubmit = (values: object, form: { reset: () => void }) => {
		if (isUrlApplyJob) {
			window.open(prefixLink(currentJob.applicationFormUrl), '_blank')?.focus();
		}

		const hasCoverLetterError = !coverLetterFileList.length && !values?.coverLetter;
		const hasCvError = !cvFileList.length;
		setCoverLetterValidationError(hasCoverLetterError);
		setCvValidationError(hasCvError);
		if (hasCoverLetterError || hasCvError) {
			return;
		}

		const preparedValues = {
			...values,
			uploadedCoverLetter,
			uploadedCV,
		};

		const callback = () => {
			form.reset();
			setModalOpen(() => true);
			setCVLink(() => '');
			setCoverLetterLink(() => '');
			setCoverLetterFileList(() => []);
			setCvFileList(() => []);
		};

		if (hasCurrentJobAssessment) {
			setJobApplyState(preparedValues);
			if (currentJob) {
				navigate(`${Routes.CareerJobPage}/${currentJob.jobId}/assessment`);
			}
		} else {
			applyOnJobRequested(preparedValues, callback);
		}
	};

	const handleCloseModal = () => {
		setModalOpen(false);
		if (isAssessmentComplete) {
			navigate(pathname);
		}
	};

	const handleRedirectCareerPage = () => {
		setModalOpen(false);
		if (pathname !== Routes.CareerPage) {
			navigate(Routes.CareerPage);
		}
	};

	useEffect(() => {
		setCvValidationError(false);
	}, [cvFileList]);

	useEffect(() => {
		if (isAssessmentComplete === 'true') {
			setModalOpen(true);
		}
	}, []);

	useEffect(() => {
		setCoverLetterValidationError(false);
	}, [coverLetterFileList]);

	const handleJobChange = (jobId: number) => {
		if (jobId !== currentJob?.jobId) {
			const cj = jobsData?.find((j) => j.jobId === jobId);

			setCurrentJob(cj);
			getJobAssessmentRequested(jobId);
		}
	};

	useEffect(() => {
		if (jobsData?.length === 1) {
			handleJobChange(jobsData[0].jobId);
		}
	}, []);

	const CvUploader = getUploader(setCVLink, setCvFileList, 'CV');
	const ClUploader = getUploader(setCoverLetterLink, setCoverLetterFileList, 'CL');

	const isFormFieldsHidden =
		!!currentJob?.applicationFormUrl &&
		!currentJob?.applicationFormAttachment &&
		currentJob?.applicationMethod === AppMethodsEnum.applicationForm;

	return (
		<Styled.ContentWrapper>
			<Styled.BgWrapper>
				<JobApplyBgIcon />
			</Styled.BgWrapper>
			<Styled.ContentInner>
				<Styled.FormTitle>Be a part of {clientName} family today!</Styled.FormTitle>
				<Form
					onSubmit={onSubmit}
					render={({ handleSubmit, dirty, values, pristine, form, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Styled.FieldWrapper hidden={jobsData?.length === 1 && !!jobIdParams}>
								<Field
									initialValue={jobsData?.length === 1 && jobsData[0].jobId}
									name='job'
									validate={requiredFieldValidator}
								>
									{({ input, meta }) => (
										<FieldWrapper
											isFixed
											isInline
											name='job'
											label='Job'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Select
												{...input}
												value={input.value || null}
												placeholder='Select a job'
												disabled={jobsData?.length === 1 && !!jobIdParams}
												onChange={(v) => {
													handleJobChange(v);

													return input.onChange(v);
												}}
											>
												{jobsData?.map((option) => (
													<Option key={option.jobId} value={option.jobId}>
														{option.title}
													</Option>
												))}
											</Select>
										</FieldWrapper>
									)}
								</Field>
							</Styled.FieldWrapper>
							{currentJob && !isFormFieldsHidden && (
								<>
									<Styled.FieldWrapper>
										<Field
											initialValue={jobApplyState.fullName}
											name='fullName'
											validate={composeValidators(requiredValidator, lengthValidator(1, 255))}
										>
											{({ input, meta }) => (
												<FieldWrapper
													isFixed
													isInline
													name='fullName'
													label='Name'
													required
													errorMessage={meta.submitFailed && meta.touched && meta.error}
												>
													<Input {...input} autoComplete='off' />
												</FieldWrapper>
											)}
										</Field>
									</Styled.FieldWrapper>
									<Styled.FieldWrapper>
										<Field
											name='email'
											initialValue={jobApplyState.email}
											validate={composeValidators(
												requiredValidator,
												emailValidator,
												lengthValidator(1, 255),
											)}
										>
											{({ input, meta }) => (
												<FieldWrapper
													isFixed
													isInline
													name='email'
													label='Contact Email'
													required
													errorMessage={meta.submitFailed && meta.touched && meta.error}
												>
													<Input {...input} autoComplete='off' />
												</FieldWrapper>
											)}
										</Field>
									</Styled.FieldWrapper>
									<Styled.FieldWrapper>
										<Field
											initialValue={jobApplyState.phoneNumber}
											name='phoneNumber'
											validate={composeValidators(requiredValidator, phoneValidator)}
										>
											{({ input, meta }) => (
												<FieldWrapper
													isFixed
													isInline
													name='phoneNumber'
													label='Phone Number'
													required
													errorMessage={meta.submitFailed && meta.touched && meta.error}
												>
													<Styled.PhoneFieldWrapper>
														<PhoneInput international {...input} defaultCountry={'GB'} />
													</Styled.PhoneFieldWrapper>
												</FieldWrapper>
											)}
										</Field>
									</Styled.FieldWrapper>
									<Styled.FieldWrapper>
										<Field
											initialValue={jobApplyState.coverLetter}
											name='coverLetter'
											validate={lengthValidator(
												0,
												3000,
												textWithoutNewLinesTabsAndCarriageReturnRegExp,
											)}
										>
											{({ input, meta }) => (
												<FieldWrapper
													isFixed
													isInline
													name='coverLetter'
													label='Cover Letter'
													errorMessage={meta.submitFailed && meta.touched && meta.error}
													required
												>
													<TextArea
														{...input}
														autoComplete='off'
														onChange={(e) => {
															input.onChange(e);
															setCoverLetterValidationError(false);
														}}
													/>
												</FieldWrapper>
											)}
										</Field>
									</Styled.FieldWrapper>
									<Styled.FieldWrapper>
										<FieldWrapper
											isFixed
											isInline
											name='uploadedCoverLetter'
											label='&nbsp;'
											hint='Please select a DOC, DOCX or PDF format'
										>
											<Styled.Upload
												listType='picture'
												accept={acceptedApplicationUploadFormFileFormat}
												maxCount={1}
												customRequest={(options) =>
													backOfficeService.uploadFile({ ...options, fileType: 'CL', noAuth: true })
												}
												fileList={coverLetterFileList}
												onChange={ClUploader}
												beforeUpload={(file) =>
													checkMaxFileSize(file, MAX_FILE_SIZE_FOR_APPLICATIONS)
												}
											>
												<AntButton block icon={<UploadOutlined />}>
													Upload
												</AntButton>
											</Styled.Upload>
										</FieldWrapper>
										{coverLetterValidationError && (
											<Styled.FieldError>
												Cover Letter is mandatory. Please enter either a text or upload a file
											</Styled.FieldError>
										)}
									</Styled.FieldWrapper>
									<Styled.FieldWrapper>
										<FieldWrapper
											isFixed
											isInline
											required
											name='uploadedCV'
											label='Upload CV'
											hint='Please select a DOC, DOCX or PDF format'
										>
											<Styled.Upload
												customRequest={(options) =>
													backOfficeService.uploadFile({ ...options, fileType: 'CV', noAuth: true })
												}
												accept={acceptedApplicationUploadFormFileFormat}
												listType='picture'
												maxCount={1}
												fileList={cvFileList}
												onChange={CvUploader}
												beforeUpload={(file) =>
													checkMaxFileSize(file, MAX_FILE_SIZE_FOR_APPLICATIONS)
												}
											>
												<AntButton block icon={<UploadOutlined />}>
													Upload
												</AntButton>
											</Styled.Upload>
										</FieldWrapper>
										{cvValidationError && (
											<Styled.FieldError>
												This field is mandatory. Please upload the CV
											</Styled.FieldError>
										)}
									</Styled.FieldWrapper>
									<Styled.FieldCheckboxWrapper>
										<Field initialValue={false} name='includeToCandidateDatabase' type='checkbox'>
											{({ input, meta }) => (
												<FieldWrapper
													isFixed
													isInline
													name='includeToCandidateDatabase'
													errorMessage={meta.submitFailed && meta.touched && meta.error}
												>
													<Checkbox {...input}>Agree to be included to candidate database</Checkbox>
												</FieldWrapper>
											)}
										</Field>
									</Styled.FieldCheckboxWrapper>
								</>
							)}
							{currentJob && (
								<Styled.SubmitWrapper
									isCentered={isFormFieldsHidden && jobsData?.length === 1 && !!jobIdParams}
								>
									<Styled.JobsApplyButton
										highlightColour={highlightColour}
										type='submit'
										buttonType={ButtonTypes.primary}
										isFullWidth
										disabled={applyOnJobLoading}
										loading={applyOnJobLoading}
										style={{ fontFamily: 'Inter' }}
									>
										{hasCurrentJobAssessment && !isUrlApplyJob ? 'Next' : 'Apply for a Job'}
									</Styled.JobsApplyButton>
								</Styled.SubmitWrapper>
							)}
						</form>
					)}
				></Form>
			</Styled.ContentInner>
			<ThanksMessageModal
				modalOpen={modalOpen}
				onCloseModal={handleCloseModal}
				onSubmit={handleRedirectCareerPage}
				message={MESSAGES.successfullySentApplication}
				title={'Application sent!'}
			/>
		</Styled.ContentWrapper>
	);
};

export default connect(
	(state) => ({
		jobApplyState: commonDucks.commonSelectors.getJobApply(state),
		applyOnJobLoading: commonDucks.commonSelectors.commonLoading(state).applyOnJobLoad,
	}),
	{
		getJobAssessmentRequested: commonDucks.commonActions.getJobAssessmentRequested,
		applyOnJobRequested: commonDucks.commonActions.applyOnJobRequested,
		setJobApplyState: commonDucks.commonActions.setJobApplyState,
	},
)(JobApply);
