import React from 'react';
import { Link } from 'react-router-dom';

import BlueTownProLogoTexted from 'components/SVG/ BlueTownProLogoTexted';
import BlueTownProLogo from 'components/SVG/BluetownProLogo';
import BlueTownSmallLogo from 'components/SVG/BlueTownSmallLogo';
import BluetownTextLogoIcon from 'components/SVG/BluetownTextLogoIcon';
import CandidatesIcon from 'components/SVG/CandidatesIcon';
import LogoutIcon from 'components/SVG/LogoutIcon';
import { INavListItem } from 'modules/Common/types';
import { COLORS } from 'theme';

import { Styled } from './Navigation.styled';
import NavigationMenu from './NavigationMenu';

type NavigationProps = {
	menuList: INavListItem[];
	logoLinkUrl: string;
	profileMenuList: INavListItem[];
	onLogout: () => void;
	highlightColourAlternate?: string;
	accentColour?: string;
	isExtended: boolean;
	isPremium: boolean;
	isImpersonating?: boolean;
	onMenuItemClick: () => void;
	onHrModuleClick: () => void;
};

export const Navigation: React.FC<NavigationProps> = ({
	menuList,
	logoLinkUrl,
	profileMenuList,
	onLogout,
	isPremium,
	highlightColourAlternate = COLORS.darkBlue,
	accentColour = COLORS.lightBlue1,
	isExtended = false,
	onMenuItemClick,
	isImpersonating = false,
	onHrModuleClick,
}) => (
	<Styled.Navigation
		bgColor={highlightColourAlternate}
		isExtended={isExtended}
		isImpersonating={isImpersonating}
	>
		<Styled.NavigationContainer isImpersonating={isImpersonating}>
			<Styled.Box>
				<Link to={logoLinkUrl}>
					<Styled.MobileLogo isExtended={isExtended}>
						{isPremium ? (
							<BlueTownProLogo fill={accentColour} />
						) : (
							<BlueTownSmallLogo fill={accentColour} />
						)}
					</Styled.MobileLogo>
				</Link>
				<Link to={logoLinkUrl}>
					<Styled.DesktopLogo isExtended={isExtended}>
						{isPremium ? (
							<BlueTownProLogoTexted fill={accentColour} />
						) : (
							<BluetownTextLogoIcon fill={accentColour} />
						)}
					</Styled.DesktopLogo>
				</Link>
				<NavigationMenu
					isExtended={isExtended}
					itemColor={accentColour}
					items={menuList}
					onMenuItemClick={onMenuItemClick}
				/>
				{/* <Styled.LogoutButton isExtended={isExtended} onClick={onHrModuleClick}>
					<CandidatesIcon fill={COLORS.lightGray2} />
					<span>HR Module</span>
				</Styled.LogoutButton> */}
			</Styled.Box>
			<Styled.Box>
				<NavigationMenu
					isExtended={isExtended}
					itemColor={accentColour}
					items={profileMenuList}
					onMenuItemClick={onMenuItemClick}
				/>
				<Styled.LogoutButton isExtended={isExtended} onClick={onLogout}>
					<LogoutIcon fill={COLORS.lightGray2} />
					<span>Logout</span>
				</Styled.LogoutButton>
			</Styled.Box>
		</Styled.NavigationContainer>
	</Styled.Navigation>
);
