import styled from 'styled-components/macro';

import { FormBlock, FormBlockProps } from 'modules/Common/components/FormBlock/FormBlock';

type FormBlockPropsExtended = FormBlockProps & {
	clientContextId?: string;
};

export namespace Styled {
	export const RadioFieldsBox = styled.div`
		display: flex;
		gap: 65px;
	`;

	export const FieldsBox = styled.div`
		display: flex;
		align-items: flex-end;
		gap: 10px;

		input,
		.ant-select {
			max-width: 160px;
			width: 100%;
		}
	`;

	export const ListOfInvoices = styled.div`
		display: flex;
		flex-wrap: wrap;
		margin-top: 8px;
		font-size: 14px;
		a:not(:first-child) {
			margin-left: 5px;
		}
	`;

	export const FieldWrapper = styled.div`
		span {
			white-space: nowrap;
		}
	`;

	export const FormBlockWrap = styled(FormBlock).attrs<FormBlockPropsExtended>(
		({ clientContextId }) => ({
			style: clientContextId
				? {
					border: 'none',
					boxShadow: 'none',
					padding: '0',
				  }
				: {},
		}),
	)<FormBlockPropsExtended>``;
}
