import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import UnregisteredLayout from 'modules/Unregistered/components/UnregisteredLayout';

import { Styled } from './ErrorPages.styled';

export default function ForbiddenPage() {
	const { state } = useLocation();

	return (
		<UnregisteredLayout>
			<Styled.Root id='error-page'>
				<Styled.ImageBox>
					<img src='/images/403.png' alt='Forbidden' />
				</Styled.ImageBox>

				<Styled.BoxWrapper>
					<h3>Ooops!</h3>
					<h2>Forbidden</h2>
					<p>Access restricted. Please contact support for assistance</p>
					{state && <Link to={state}>Go back</Link>}
				</Styled.BoxWrapper>
			</Styled.Root>
		</UnregisteredLayout>
	);
}
