import styled from 'styled-components/macro';

interface PickedProps {
	pickedColor: string;
}
interface IStylesProps {
	isOnTop: boolean;
}

export namespace Styled {
	export const ColorPickerContent = styled.div`
		min-width: 514px;
		max-height: calc(100vh - 215px);
		margin: 0 12px;
		overflow: hidden auto;
	`;

	export const ColorPickerItem = styled.section`
		display: flex;
		justify-content: space-between;
		margin-bottom: 24px;
	`;

	export const ColorPickerPreview = styled.div<PickedProps>`
		width: 154px;
		height: 72px;
		background: ${({ pickedColor }) => pickedColor};
		border-radius: 4px;
		margin-right: 25px;
		display: block;
	`;

	export const ColorPickerMain = styled.div`
		position: relative;
		width: 335px;
	`;

	export const ColorPickerWrapper = styled.div`
		position: relative;
	`;

	export const ColorPickerItemHead = styled.div`
		display: flex;
		justify-content: space-between;
	`;

	export const ColorPickerItemTitle = styled.div`
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #1e1e20;
		padding-bottom: 8px;
	`;

	export const ColorPickerInput = styled.input`
		width: 335px;
		height: 40px;
		background: #ffffff;
		border: 1px solid #c4c4c8;
		border-radius: 4px;
		padding: 8px 12px;
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		cursor: pointer;
	`;

	export const ColorPicker = styled.div<IStylesProps>`
		position: fixed;
		margin-top: ${({ isOnTop }) => (isOnTop ? '-350px' : '0')};
		z-index: 99;
	`;

	export const ColorPickerCover = styled.div`
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	`;

	export const ButtonWrapper = styled.div`
		padding: 0 7px;
		margin-right: -7px;
	`;
}
