import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		max-width: 1368px;
		margin: 0;
		padding: 0;
	`;

	export const ClientInfo = styled.div`
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
		margin-top: -60px;
	`;

	export const LogoWrapper = styled.div`
		display: flex;
		height: 120px;
		max-width: 240px;
		width: 100%;
		margin-left: 32px;
		padding: 8px;
		background-color: ${COLORS.white};
		border-radius: 4px;
		box-shadow: ${COLORS.elevationShadow};
		z-index: 3;

		img {
			width: 100%;
			object-fit: contain;
		}
	`;

	export const ClientName = styled.div`
		color: ${COLORS.black};
		font-size: 32px;
		font-style: normal;
		font-weight: 800;
		line-height: 40px;
		letter-spacing: 0.96px;
		margin-left: 32px;
	`;

	export const Main = styled.div`
		margin-top: 40px;
		padding-top: 32px;
	`;

	export const Title = styled.div`
		color: ${COLORS.blue};
		text-align: center;
		font-family: Raleway;
		font-size: 36px;
		font-style: normal;
		font-weight: 700;
		line-height: 40px;
		padding-bottom: 32px;
	`;

	export const Desc = styled.div`
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		margin-bottom: 32px;
	`;

	export const ButtonWrapper = styled.div`
		width: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 88px
	`;
}
