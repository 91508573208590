import { Link } from 'react-router-dom';

import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';
import { COLORS, size } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;

		button.ant-btn.ant-btn-default {
			height: 28px;
			padding: 0 16px;
			line-height: 28px;
		}
	`;

	export const PageLink = styled(Link)`
		display: flex;
		line-height: 28px;
		min-width: 28px;
		font-weight: 500;
		color: ${COLORS.black};
		gap: 4px;
	`;

	export const Button = styled(ButtonComponent)`
		line-height: 28px;
		height: 28px;
		min-width: 28px;
		padding: 4px 8px;
		font-size: 14px;
		gap: 8px;
	`;

	export const BadgeWrapper = styled.div`
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
	`;

	export const ModalHead = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;
	`;

	export const ModalContent = styled.div`
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 60vh;

		canvas {
			width: auto !important;
			height: 100% !important;
			max-height: calc(100vh - 140px) !important;

			@media (min-height: ${size.large}) {
				max-height: 1010px !important;
			}
		}
	`;
	export const Popover = styled.div``;
	export const PopoverInvoice = styled.div`
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 8px;
	`;
	export const PopoverInvoiceBTO = styled.div`
		margin-left: 8px;
	`;
}
