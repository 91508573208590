export const ClientsFields = [
	'id',
	'clientName',
	'subscription',
	'numberOfActiveJobs',
	'numberOfPremiumMultisiteCredits',
	'numberOfCvFilteringCredits',
	'numberOfGuardianCredits',
	'numberOfIndeedCredits',
];
