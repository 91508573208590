import { Link } from 'react-router-dom';

import { Select as SelectComponent } from 'antd';
import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';
import { COLORS, device } from 'theme';

const { Option } = SelectComponent;

export namespace Styled {
	export const Root = styled.section`
		position: relative;
		display: flex;
		gap: 24px;
	`;

	export const Head = styled.section`
		display: flex;
		justify-content: space-between;
	`;

	export const DownloadButtonWrapper = styled.div`
		padding: 16px 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.ant-btn-link {
			color: ${COLORS.blue};
		}
	`;

	export const Title = styled.h2``;

	export const PanelMenu = styled.section`
		display: flex;
		align-items: start;
		justify-content: space-between;
		flex-direction: column;
		max-width: 246px;
		min-width: 200px;
		width: 100%;
		margin-right: 24px;
		padding: 16px 0;
		background-color: ${COLORS.white};
		box-shadow: ${COLORS.elevationShadow1};
		border-radius: 10px;

		@media ${device.mobile} {
			gap: 10px;
		}
	`;

	export const DetailsInfo = styled.div`
		display: flex;
		align-items: start;
		flex-direction: column;
		width: 100%;
		padding: 32px 24px;
		background-color: ${COLORS.white};
		box-shadow: ${COLORS.elevationShadow1};
		border-radius: 10px;
	`;

	export const DetailsInfoHead = styled.div`
		display: flex;
		flex-direction: column;
		width: 100%;

		h2 {
			margin-bottom: 0;
		}
	`;

	export const HeadWrapper = styled.div`
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 12px;
		gap: 16px;
	`;

	export const HeadTitle = styled.div`
		display: flex;
		gap: 16px;
	`;

	export const HeadActions = styled.div`
		display: flex;
		gap: 16px;

		.ant-btn {
			border: 1px solid ${COLORS.borderColor};
			background-color: ${COLORS.lightGray3};
			box-shadow: ${COLORS.elevationShadow1};

			&:disabled {
				svg {
					path {
						fill: ${COLORS.gray};
					}
				}
			}
		}
	`;

	export const DownloadPopup = styled.div`
		position: relative;
	`;

	export const CandidateInfo = styled.div`
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		gap: 0 24px;

		a {
			display: flex;
			align-items: center;
			line-height: 24px;
			gap: 8px;
		}
	`;

	export const CandidateInfoDate = styled.div`
		display: flex;
		align-items: center;
		line-height: 24px;
		gap: 8px;
	`;

	export const AdditionalInfo = styled.div`
		display: flex;
		align-items: start;
		gap: 0 16px;
	`;

	export const AdditionalInfoListHeading = styled.div`
		display: flex;
		white-space: nowrap;
		line-height: 22px;
	`;

	export const AdditionalInfoList = styled.div`
		flex: 1 1 auto;
		display: flex;
		flex-wrap: wrap;
		gap: 8px 16px;
	`;

	export const AdditionalInfoListTitle = styled.div``;

	export const AdditionalInfoListTitleLink = styled(Link)`
		color: ${COLORS.black};

		&:hover {
			text-decoration: underline;
		}
	`;

	export const ShowButton = styled(ButtonComponent)`
		display: inline-block;
		height: auto;
		padding: 0;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: ${COLORS.blue};
		transition: color 0.2s ease;
		white-space: nowrap;

		&:hover {
			color: ${COLORS.lightBlue2};
		}
	`;

	export const Tag = styled.span`
		display: inline-block;
		margin: 0 4px;
		padding: 4px 8px;
		font-weight: 700;
		font-size: 12px;
		line-height: 16px;
		color: #7a86ae;
		background: #f8f9fc;
		border: 1px solid #d4daed;
		border-radius: 4px;
	`;

	export const DetailsInfoMain = styled.section`
		width: 100%;

		.ant-tabs-nav {
			margin-bottom: 24px;
		}

		.ant-tabs-tab {
			padding: 12px;
			text-transform: uppercase;

			&.ant-tabs-tab-active,
			&:hover {
				.ant-tabs-tab-btn {
					color: ${COLORS.blue};
				}
			}

			&-btn {
				font-weight: 500;
				color: ${COLORS.darkGray2};
			}
		}
	`;

	export const InfoText = styled.section`
		padding: 12px;
		margin-bottom: 24px;
		white-space: break-spaces;
	`;

	export const HintText = styled.section`
		margin-bottom: 24px;
		white-space: break-spaces;
	`;

	export const Select = styled(SelectComponent)`
		&.ant-select {
			position: relative;
			height: 40px;
			min-width: 110px;

			.ant-select-selector {
				position: relative;
				height: 40px;
				border: 1px solid ${COLORS.borderColor};
				background-color: ${COLORS.lightGray3};
				box-shadow: ${COLORS.elevationShadow1};

				.ant-select-selection-item {
					font-size: 14px;
					font-weight: 700;
					line-height: 38px;
					color: ${COLORS.darkGray2};
				}
			}
		}
	`;

	export const SelectOption = styled(Option)``;
}
