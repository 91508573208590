import { FC } from 'react';

import { Upload, UploadProps } from 'antd';

import UploadIcon from 'components/SVG/UploadIcon';

import { Styled } from './ButtonFileUploader.styled';

type ButtonFileUploaderProps = {
	uploadProps: UploadProps;
	label?: string;
	placeholder: string;
	disabled: boolean;
};

export const ButtonFileUploader: FC<ButtonFileUploaderProps> = ({
	uploadProps,
	placeholder,
	label,
	disabled,
}) => (
	<Styled.Root>
		<Styled.Label>{label}</Styled.Label>
		<Styled.UploadWrapper>
			<Upload {...uploadProps} disabled={disabled}>
				<Styled.Button
					htmlType='button'
					type='default'
					icon={<UploadIcon width='14' height='14' />}
					disabled={disabled}
				>
					{placeholder}
				</Styled.Button>
			</Upload>
		</Styled.UploadWrapper>
	</Styled.Root>
);
