import React, { type FC } from 'react';

import Table from 'components/Table';
import { IQuestionnairesPaginated } from 'modules/Common/types';
import { propsFilter } from 'utils/helpers';

import { TableFields } from './QuestionnaireListTable.constants';
import { columns } from './QuestionnaireListTable.entities';

type QuestionnaireListTableProps = {
	listData: IQuestionnairesPaginated;
	handleSendQuestionnaire: (id: number) => void;
	handleTableChange: (page: number, size: number) => void;
	handleTablePageSizeChange: (page: number, size: number) => void;
	loading: boolean;
};

const QuestionnaireListTable: FC<QuestionnaireListTableProps> = ({
	listData,
	handleSendQuestionnaire,
	handleTableChange,
	handleTablePageSizeChange,
	loading,
}) => {
	const { pageIndex, pageSize, totalCount } = listData;
	const filteredData = propsFilter(listData?.data, TableFields);

	return (
		<Table
			data={filteredData}
			columns={columns(handleSendQuestionnaire)}
			loading={loading}
			pageSize={pageSize}
			current={pageIndex}
			total={totalCount}
			onPageSizeChange={handleTablePageSizeChange}
			onChange={handleTableChange}
		/>
	);
};

export default QuestionnaireListTable;
