import { get } from 'api/axiosInstance';
import { RequestsEnum } from 'types';

export const getEmploymentTypes = async () => get(`${RequestsEnum.EmploymentType}`);

const employmentsService = {
	getEmploymentTypes,
};

export default employmentsService;
