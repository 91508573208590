export const size = {
	mobile: '425px',
	tablet: '768px',
	desktop: '1024px',
	large: '1328px',
	laptop: '1662px'
} as const;

export const device = {
	mobile: `(max-width: ${size.mobile})`,
	tablet: `(max-width: ${size.tablet})`,
	desktop: `(max-width: ${size.desktop})`,
	large: `(max-width: ${size.large})`,
	laptop: `(max-width: ${size.laptop})`,
} as const;
