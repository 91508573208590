import styled from 'styled-components/macro';

import { COLORS } from 'theme';

interface IStyled {
	isFullWidth: boolean;
}

export namespace S {
	export const Root = styled.nav`
		width: 100%;
		max-width: ${({ theme: { size } }) => size.large};
		margin: 0 auto;
		overflow: hidden;
		white-space: nowrap;
	`;

	export const MainMenu = styled.ul<IStyled>`
		display: flex;
		align-items: center;
		${({ isFullWidth }) =>
		isFullWidth &&
			`
      justify-content: space-between;
    `}
		height: 72px;
		margin-bottom: 24px;
		color: ${COLORS.darkGray3};
		gap: 32px;
		list-style: none;
	`;

	export const MenuItem = styled.li`
		a {
			display: block;
			line-height: 24px;
			font-size: 1rem;
			font-weight: 400;
			color: ${({ theme }) => theme.colors.black};
			text-decoration: none;
			transition: color ease 0.2s;

			&.active {
				color: ${({ theme }) => theme.colors.lightBlue1};
			}

			&:hover,
			&:focus {
				color: ${({ theme }) => theme.colors.lightBlue1};
			}
		}
	`;
}
