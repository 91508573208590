import React from 'react';

import { Svg, Path, G, Defs, ClipPath } from '@react-pdf/renderer';

const EarthSvg = () => (
	<Svg width='10' height='10' viewBox='0 0 10 10'>
		<G fill='#fff' fillRule='evenodd'>
			<Path d='M5 1.665a3.333 3.333 0 100 6.667 3.333 3.333 0 000-6.667zM.833 5a4.167 4.167 0 118.333 0 4.167 4.167 0 01-8.333 0z'></Path>
			<Path d='M1.083 3.749c0-.23.187-.417.417-.417h7a.417.417 0 110 .833h-7a.417.417 0 01-.417-.416zM1.083 6.249c0-.23.187-.417.417-.417h7a.417.417 0 110 .833h-7a.417.417 0 01-.417-.416z'></Path>
			<Path d='M5.012.895a.417.417 0 01.133.574 6.667 6.667 0 000 7.06.417.417 0 01-.707.44 7.5 7.5 0 010-7.94.417.417 0 01.574-.134z'></Path>
			<Path d='M4.987.895a.417.417 0 01.574.133 7.5 7.5 0 010 7.941.417.417 0 11-.706-.44 6.667 6.667 0 000-7.06.417.417 0 01.132-.574z'></Path>
		</G>
		<Defs>
			<ClipPath id='clip0_5682_49864'>
				<Path fill='#fff' d='M0 0H10V10H0z'></Path>
			</ClipPath>
		</Defs>
	</Svg>
);

export default EarthSvg;
