import styled from 'styled-components/macro';

import { COLORS } from 'theme';

interface JobTitleProps {
	color: string;
}

export namespace Styled {
	export const JobItem = styled.div`
		padding: 24px 32px;
		box-shadow: 0px 2px 4px rgba(76, 98, 140, 0.12);
		border-radius: 12px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	`;
	export const JobItemMain = styled.div``;
	export const JobItemTitle = styled.div<JobTitleProps>`
		font-family: 'Raleway';
		margin-bottom: 8px;
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		cursor: pointer;
		color: ${({ color }) => color};
	`;
	export const JobItemAdditional = styled.div`
		display: flex;
	`;
	export const JobItemAdditionalLabel = styled.div`
		font-family: 'Raleway';
		margin-left: 10px;
	`;
	export const JobItemAdditionalLocation = styled.div`
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		color: ${COLORS.black};
		display: flex;
		align-items: center;
	`;
	export const JobItemAdditionalSalary = styled.div`
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		color: ${COLORS.black};
		margin-left: 25px;
		display: flex;
		align-items: center;
	`;
	export const JobItemNav = styled.div`
		cursor: pointer;
	`;
}
