export const AllowedTicketFields = [
	'id',
	'client',
	'visibleToClient',
	'ticketType',
	'subject',
	'ticketStatus',
	'description',
	'attachments',
];
