import React, { type FC, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { Input, Spin } from 'antd';

import Box from 'components/Box';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import { atsDucks } from 'modules/ATS/ducks';
import FormBlockGrid from 'modules/Common/components/FormBlockGrid';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { GenericType } from 'types';
import { trimSpacesFormValues } from 'utils/helpers';
import { composeValidators, emailValidator, requiredFieldValidator } from 'utils/validators';

import { SettingsPanelMenuList } from '../ATSSettings.types';

import { Styled } from './PersonalData.styled';

type PersonalDataValues = {
	firstName: string;
	lastName: string;
	email: string;
};

export type PersonalDataProps = {
	userId: number;
	personalData: PersonalDataValues;
	getATSUserPersonalData: (userId: number) => void;
	updateATSUserPersonalData: (values: Partial<PersonalDataValues>) => void;
	loading: GenericType;
};

const PersonalData: FC<PersonalDataProps> = ({
	userId,
	personalData,
	getATSUserPersonalData,
	updateATSUserPersonalData,
	loading,
}) => {
	const onSubmit = (values: PersonalDataValues) => {
		const preparedValues = trimSpacesFormValues(values);
		updateATSUserPersonalData(preparedValues);
	};

	useMount(() => {
		getATSUserPersonalData(userId);
	});

	const initialValues = useMemo(
		() => ({
			firstName: personalData?.firstName ?? '',
			lastName: personalData?.lastName ?? '',
			email: personalData?.email ?? '',
		}),
		[personalData],
	);

	const isUpdating = !!loading?.updateAtsUserPersonalDataLoad;

	return (
		<Styled.Root>
			{loading.getAtsUserPersonalDataLoad ? (
				<Spinner fullWidth />
			) : (
				<>
					<Form
						onSubmit={onSubmit}
						initialValues={initialValues}
						render={({ handleSubmit, dirty }) => (
							<form onSubmit={handleSubmit}>
								<Box noSpaces fullWidth style={{ flex: '1 1 auto' }}>
									<h2>{SettingsPanelMenuList.PersonalData}</h2>
									<FormBlockGrid columns={2}>
										<Field name='firstName' validate={requiredFieldValidator}>
											{({ input, meta }) => (
												<FieldWrapper
													name='firstName'
													label='First Name'
													errorMessage={meta.submitFailed && meta.touched && meta.error}
													required
												>
													<Input {...input} placeholder='Enter first name...' autoComplete='off' />
												</FieldWrapper>
											)}
										</Field>
										<Field name='lastName' validate={requiredFieldValidator}>
											{({ input, meta }) => (
												<FieldWrapper
													name='lastName'
													label='Last Name'
													errorMessage={meta.submitFailed && meta.touched && meta.error}
													required
												>
													<Input {...input} placeholder='Enter last name...' autoComplete='off' />
												</FieldWrapper>
											)}
										</Field>
										<Field
											name='email'
											validate={composeValidators(requiredFieldValidator, emailValidator)}
										>
											{({ input, meta }) => (
												<FieldWrapper
													name='email'
													label='Email'
													errorMessage={meta.submitFailed && meta.touched && meta.error}
													required
												>
													<Input {...input} placeholder='example@gmail.com' autoComplete='off' />
												</FieldWrapper>
											)}
										</Field>
									</FormBlockGrid>
								</Box>

								<Styled.ButtonWrapper>
									<ATSThemedButton
										buttonType={ButtonTypes.primary}
										type='submit'
										disabled={!dirty || isUpdating}
									>
										Save
										{isUpdating ? <Spin style={{ marginLeft: '12px' }} /> : ''}
									</ATSThemedButton>
								</Styled.ButtonWrapper>
							</form>
						)}
					/>
				</>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		userId: unregisteredDucks.unregisteredSelectors.getUser(state)?.id,
		personalData: atsDucks.atsSelectors.getUserPersonalData(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getATSUserPersonalData: atsDucks.atsActions.getATSUserPersonalDataRequested,
		updateATSUserPersonalData: atsDucks.atsActions.updateATSUserPersonalDataRequested,
	},
)(PersonalData);
