import React from 'react';
import { useLocation } from 'react-router-dom';

import BuyVideoCredits from 'modules/ATS/containers/BuyVideoCredits';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { Routes } from 'types';

import { Styled } from './ATSBuyVideoCreditsPage.styled';

const ATSBuyVideoCreditsPage = () => {
	const { state } = useLocation();
	const { prevPage, yearMonth } = state || {};

	return (
		<>
			<Styled.Head>
				<PageNavTitle
					title={'Buy Video Interviews'}
					navigationLink={prevPage ?? `${Routes.ATS}${Routes.Interviews}`}
				/>
			</Styled.Head>
			<Styled.Content>
				<BuyVideoCredits yearMonth={yearMonth} />
			</Styled.Content>
		</>
	);
};

export default ATSBuyVideoCreditsPage;
