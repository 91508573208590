import FacebookIcon from 'components/SVG/FacebookIcon';
import InstagramIcon from 'components/SVG/InstagramIcon';
import LinkedinIcon from 'components/SVG/LinkedinIcon';
import TwitterIcon from 'components/SVG/TwitterIcon';
import YoutubeIcon from 'components/SVG/YoutubeIcon';

export const jobsData = [
	{
		jobId: 1,
		title: 'SoftWare Engineer',
		location: 'Manchester',
		salary: 'Up to £30,000',
	},
	{
		jobId: 2,
		title: 'Recoding Studio Technician',
		location: 'Liverpool',
		salary: 'Up to £32,000',
	},
	{
		jobId: 3,
		title: 'Recoding Engineer',
		location: 'London',
		salary: 'Up to £40,000',
	},
	{
		jobId: 4,
		title: 'Accountant',
		location: 'Remote',
		salary: 'Up to £26,000',
	},
];

export const socialNetworksIconsMap = {
	linkedIn: <LinkedinIcon />,
	instagram: <InstagramIcon />,
	facebook: <FacebookIcon />,
	twitter: <TwitterIcon />,
	youtube: <YoutubeIcon />,
};
