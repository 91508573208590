import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function VideoPlayIcon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g id='State=Play'>
				<path
					id='Polygon 1'
					d='M20.9 10.7876C21.8333 11.3264 21.8333 12.6736 20.9 13.2124L8.6 20.3138C7.66667 20.8527 6.5 20.1791 6.5 19.1014L6.5 4.89859C6.5 3.82087 7.66667 3.1473 8.6 3.68616L20.9 10.7876Z'
					fill={fill}
				/>
			</g>
		</svg>
	);
}

export default memo(VideoPlayIcon);
