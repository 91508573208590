import { type FC } from 'react';

import HeroHeader from '../HeroHeader';

import { S } from './BrandingContainer.styled';
import { BrandingContainerProps, BrandingMode } from './BrandingContainer.types';
import ControlButtons from './ControlButtons';

const baseUrl = `${process.env.REACT_APP_API_URL}/open/public/file/logo/`;

export const BrandingContainer: FC<BrandingContainerProps> = ({
	clientName,
	logo,
	textColour,
	headerBackground,
	backgroundImage,
	mode,
	onBgRemove,
	onBgEdit,
	onLogoRemove,
	onLogoEdit,
	children,
}) => {
	const logoUrl = logo?.split('/').includes('images') ? logo : logo && `${baseUrl}${logo}`;

	const backgroundImageUrl = backgroundImage?.split('/').includes('images')
		? backgroundImage
		: backgroundImage && `${baseUrl}${backgroundImage}`;

	return (
		<S.Root>
			<HeroHeader
				backgroundImageUrl={backgroundImageUrl}
				headerBackground={headerBackground}
				minHeight='300px'
			>
				{mode === BrandingMode.Edit ? (
					<ControlButtons onEdit={onBgEdit} onRemove={onBgRemove} />
				) : (
					<></>
				)}
			</HeroHeader>
			<S.Info>
				<S.Logo>
					<S.ImageWrapper>{logoUrl && <img src={logoUrl} alt='Client Logo' />}</S.ImageWrapper>

					{mode === BrandingMode.Edit && (
						<S.ButtonBox>
							<ControlButtons onEdit={onLogoEdit} onRemove={onLogoRemove} />
						</S.ButtonBox>
					)}
				</S.Logo>
				<S.Heading textColour={textColour}>{clientName}</S.Heading>
			</S.Info>
			<S.MainContainer>{children}</S.MainContainer>
		</S.Root>
	);
};
