import { Link as LinkComponent } from 'react-router-dom';

import styled from 'styled-components/macro';

interface LinkProps {
	isDisabled?: boolean;
}

export namespace Styled {
	export const Link = styled(LinkComponent)<LinkProps>`
		color: ${({ isDisabled }) => (isDisabled ? 'gray' : '##1677ff')};
		cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
		&:hover {
			color: ${({ isDisabled }) => (isDisabled ? 'gray' : '##69b1ff')};
		}
	`;
}
