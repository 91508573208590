import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.section``;
	export const Head = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 24px;
		margin-bottom: 24px;

		h2 {
			margin: 0;
		}
	`;

	export const Title = styled.h2``;
}
