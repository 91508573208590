import { FormEvent } from 'react';

import { ReactParent } from './common';

type InputValueType = string | number;

export const enum Fields {
	NUMBER = 'number',
	SELECT = 'select',
	TEXT = 'text',
	PASSWORD = 'password',
}

export const enum SizeTypes {
	large = 'large',
	medium = 'medium',
	small = 'small',
}

export const FieldSizes = {
	[SizeTypes.small]: '140px',
	[SizeTypes.medium]: '210px',
	[SizeTypes.large]: '396px',
};

export interface IInputStyled {
	isFullWidth?: boolean;
	sizeField?: SizeTypes.large | SizeTypes.medium | SizeTypes.small;
	isError: boolean;
}

export type IInputProps = ReactParent & {
	name: string;
	type?: string;
	size?: SizeTypes.large | SizeTypes.medium | SizeTypes.small;
	value?: InputValueType;
	onChange?: (event: FormEvent) => void;
	placeholder?: string;
	errorMessage?: string | undefined;
	isFullWidth?: boolean;
};

export type IFieldProps = ReactParent & {
	name?: string;
	label?: string;
	hint?: string;
	isFixed?: boolean;
	isInline?: boolean;
	required?: boolean;
	fullWidth?: boolean;
	showToggle?: boolean;
	errorMessage?: string | undefined;
};

export interface IUserFormValues {
	email: string;
	password: string;
	tempToken?: string;
}
