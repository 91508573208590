import { ContentState, EditorState, RawDraftContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export const convertContentToHtml = (value: string | undefined) => {
	try {
		if (!value) {
			return null;
		}

		let htmlText;

		if (isHTMLString(value)) {
			htmlText = value;
		} else {
			const convertedToJson = typeof value === 'string' ? JSON.parse(value) : value;

			htmlText = value && draftToHtml(convertedToJson);
		}

		const createMarkup = (html: string | undefined): { __html: string } => {
			return { __html: html || '' };
		};

		return <div dangerouslySetInnerHTML={createMarkup(htmlText)} />;
	} catch (e) {
		console.error('convertContentToHtml error: ', e);
	}
};

export const convertContentFromHtml = (htmlString: string): EditorState | null => {
	try {
		const contentBlock = htmlToDraft(htmlString);

		if (!contentBlock || !contentBlock.contentBlocks) {
			console.error('Failed to convert HTML to Draft.js content');

			return null;
		}

		const entityMap = contentBlock.entityMap || {};

		const contentState = ContentState?.createFromBlockArray(contentBlock.contentBlocks, entityMap);

		return contentState && EditorState.createWithContent(contentState);
	} catch (error) {
		console.error('Error converting HTML to Draft.js content:', error);

		return null;
	}
};

export const isDraftJsString = (value: unknown): value is RawDraftContentState => {
	if (typeof value !== 'string') {
		return false;
	}

	try {
		const parsedValue = JSON.parse(value);

		return (
			typeof parsedValue === 'object' &&
			parsedValue !== null &&
			Array.isArray(parsedValue.blocks) &&
			typeof parsedValue.entityMap === 'object'
		);
	} catch (e) {
		return false;
	}
};

export const isHTMLString = (value: unknown): boolean => {
	if (typeof value !== 'string') {
		return false;
	}

	const htmlTagPattern = /<\/?[a-z][\s\S]*>/i;

	return htmlTagPattern.test(value);
};

export const htmlStringToDraftJsString = (htmlString: string): string => {
	const contentBlock = htmlToDraft(htmlString);

	if (!contentBlock || !contentBlock.contentBlocks) {
		throw new Error('Failed to convert HTML to Draft.js content');
	}

	const contentState = ContentState.createFromBlockArray(
		contentBlock.contentBlocks,
		contentBlock.entityMap,
	);
	const rawContentState = convertToRaw(contentState);

	return JSON.stringify(rawContentState);
};
