import React from 'react';

export const highlightSearch = (candidateName: string, searchTerm: string) => {
	if (!searchTerm) {
		return candidateName;
	}
	const index = candidateName.toLowerCase().indexOf(searchTerm.toLowerCase());
	if (index === -1) {
		return candidateName;
	}
	const before = candidateName.slice(0, index);
	const match = candidateName.slice(index, index + searchTerm.length);
	const after = candidateName.slice(index + searchTerm.length);

	return (
		<>
			{before}
			<span className='highlight'>{match}</span>
			{after}
		</>
	);
};
