import React, { type FC, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import { message } from 'antd';
import { isEmpty } from 'lodash';

import Spinner from 'components/Spinner';
import { atsDucks } from 'modules/ATS/ducks';
import { MESSAGES } from 'modules/Common/constants';
import SocialNetworkContainer from 'modules/Common/containers/SocialNetworkContainer';
import { SocialNetworksType } from 'modules/Common/types/socialNetworks';
import { GenericType } from 'types';
import { trimSpacesFormValues } from 'utils/helpers';

import { Styled } from './ATSSocialNetwork.styled';

export type SocialNetworkProps = {
	socialNetworks: SocialNetworksType;
	getClientSocialNetworks: () => void;
	updateClientSocialNetworks: (values: Partial<SocialNetworksType>, cb: () => void) => void;
	loading: GenericType;
};

const ATSSocialNetwork: FC<SocialNetworkProps> = ({
	socialNetworks,
	getClientSocialNetworks,
	updateClientSocialNetworks,
	loading,
}) => {
	const handleSave = useCallback((values: Partial<SocialNetworksType>) => {
		const preparedValues = trimSpacesFormValues(values);
		updateClientSocialNetworks(preparedValues, () => {
			message.success(MESSAGES.successfullySavedSocialNetworks);
		});
	}, []);

	useEffect(() => {
		socialNetworks && isEmpty(socialNetworks) && getClientSocialNetworks();
	}, []);

	return (
		<Styled.Root>
			{loading.getClientSocialNetworksLoad ? (
				<Spinner fullWidth />
			) : (
				<SocialNetworkContainer
					defaultValues={socialNetworks}
					handleSave={handleSave}
					loading={!!loading.updateClientSocialNetworksLoad}
				/>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		socialNetworks: atsDucks.atsSelectors.getClientSocialNetworks(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getClientSocialNetworks: atsDucks.atsActions.getClientSocialNetworksRequested,
		updateClientSocialNetworks: atsDucks.atsActions.updateClientSocialNetworksRequested,
	},
)(ATSSocialNetwork);
