import styled from 'styled-components/macro';

export namespace Styled {
	export const UploadField = styled.div`
		margin-bottom: 32px;
	`;

	export const FieldBox = styled.div`
		max-width: 615px;
		margin-bottom: 32px;
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
	`;
}
