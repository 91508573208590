import { useState, useEffect } from 'react';


const useNetwork = () => {
	const [online, setOnline] = useState(navigator.onLine);

	useEffect(() => {
		const handleOnline = () => {
			setOnline(true);
		};

		const handleOffline = () => {
			setOnline(false);
		};

		window.addEventListener('online', handleOnline);
		window.addEventListener('offline', handleOffline);

		return () => {
			window.removeEventListener('online', handleOnline);
			window.removeEventListener('offline', handleOffline);
		};
	}, []);

	return online;
};

export default useNetwork;