import React, { useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';

import { Tooltip } from 'antd';
import validateColor from 'validate-color';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import InfoIcon from 'components/SVG/InfoIcon';

import { Styled } from './AtsClientColorPicker.styled';

export const ColorPickerItem = ({ pickedColor, title, hint, onChange }) => {
	const [displayColorPicker, setDisplayColorPicker] = useState(false);
	const [color, setColor] = useState(pickedColor || '#FFF');
	const [colorPickerOutside, setColorPickerOutside] = useState(false);
	const colorPickerRef = useRef(null);

	const handleDisplayColorPicker = () => {
		const colorPickerPosition = colorPickerRef?.current?.getBoundingClientRect();
		const documentHeight = document.documentElement.clientHeight;
		const isOffside = colorPickerPosition.bottom + 305 > documentHeight;

		if (isOffside) {
			setColorPickerOutside(true);
		} else {
			setColorPickerOutside(false);
		}

		setDisplayColorPicker(true);
	};

	useEffect(() => {
		if (pickedColor && validateColor(pickedColor)) {
			setColor(pickedColor);
		}
	}, [pickedColor]);

	return (
		<Styled.ColorPickerItem>
			<Styled.ColorPickerPreview pickedColor={color} />
			<Styled.ColorPickerMain>
				<Styled.ColorPickerItemHead>
					<Styled.ColorPickerItemTitle>{title}</Styled.ColorPickerItemTitle>
					{hint && (
						<Tooltip placement='topRight' title={hint}>
							<Styled.ButtonWrapper>
								<Button type={ButtonTypes.default} icon={<InfoIcon />} />
							</Styled.ButtonWrapper>
						</Tooltip>
					)}
				</Styled.ColorPickerItemHead>
				<Styled.ColorPickerInput
					onChange={(e) => {
						const newColor = e.target?.value;
						if (newColor && validateColor(newColor)) {
							onChange(newColor);
						}
						setColor(newColor);
					}}
					onClick={handleDisplayColorPicker}
					value={color}
				/>
				<Styled.ColorPickerWrapper ref={colorPickerRef}>
					{displayColorPicker && (
						<Styled.ColorPicker isOnTop={colorPickerOutside}>
							<Styled.ColorPickerCover onClick={() => setDisplayColorPicker(false)} />
							{validateColor(color) && (
								<SketchPicker
									color={color}
									onChange={(v) => {
										setColor(v.hex);
										onChange(v.hex);
									}}
								/>
							)}
						</Styled.ColorPicker>
					)}
				</Styled.ColorPickerWrapper>
			</Styled.ColorPickerMain>
		</Styled.ColorPickerItem>
	);
};
