import React, { type FC, useEffect, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Input, Select, Spin } from 'antd';
import arrayMutators from 'final-form-arrays';
import { omit } from 'lodash';

import Box from 'components/Box';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import FormSwitch from 'components/FormSwitch';
import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import { atsDucks } from 'modules/ATS/ducks';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { commonDucks } from 'modules/Common/ducks';
import { IUserBaseFields } from 'modules/Common/types';
import { TStyled } from 'theme';
import {
	GenericType,
	IOption,
	OptionsType,
	SettingsRoutes,
	SubscriptionPlansType,
	UserAccessFormType,
	UserRolesEnum,
} from 'types';
import { trimSpacesFormValues } from 'utils/helpers';
import {
	composeValidators,
	emailValidator,
	passwordValidator,
	requiredFieldValidator,
} from 'utils/validators';

import { Styled } from './ATSUserAccessForm.styled';

const { Option } = Select;

export type UserAccessFormProps = {
	atsUserRoles: IOption[];
	userAccessForm: UserAccessFormType;
	subscriptionPlans: SubscriptionPlansType;
	questionnairesList: OptionsType[];
	activeJobsList: IOption[];
	getAtsUserByIdRequested: (id: string, cb?: () => void) => void;
	getAtsUserRolesRequested: () => void;
	getAtsActiveJobsRequested: () => void;
	getQuestionnaires: (p: { active: boolean }) => void;
	createAtsUserRequested: (values: Partial<IUserBaseFields>, cb: () => void) => void;
	updateAtsUserRequested: (values: Partial<IUserBaseFields>, cb: () => void) => void;
	loadingAts: GenericType;
};

const ATSUserAccessForm: FC<UserAccessFormProps> = ({
	atsUserRoles,
	userAccessForm,
	subscriptionPlans,
	questionnairesList,
	activeJobsList,
	getAtsUserByIdRequested,
	getAtsUserRolesRequested,
	getQuestionnaires,
	getAtsActiveJobsRequested,
	createAtsUserRequested,
	updateAtsUserRequested,
	loadingAts,
}) => {
	const location = useLocation();
	const { userId } = useParams();
	const navigate = useNavigate();

	const isEditMode = location?.pathname?.includes(SettingsRoutes.UserAccessEdit) && !!userId;

	const restrictedQuestionnaireIds = questionnairesList
		.filter((cur) => userAccessForm?.restrictedQuestionnaireIds?.includes(cur?.value as never))
		.map((cur) => cur?.value as unknown);

	const restrictedJobIds = activeJobsList
		.filter((cur) => userAccessForm?.restrictedJobIds?.includes(cur?.id as never))
		.map((cur) => cur?.id as unknown);

	const initialValues = useMemo(
		() => ({
			firstName: isEditMode ? userAccessForm?.firstName : '',
			lastName: isEditMode ? userAccessForm?.lastName : '',
			email: isEditMode ? userAccessForm?.email : '',
			role: isEditMode ? userAccessForm?.role : '',
			restrictedQuestionnaireIds:
				isEditMode && !!userAccessForm?.restrictedQuestionnaireIds?.length
					? restrictedQuestionnaireIds
					: [],
			restrictedJobIds:
				isEditMode && !!userAccessForm?.restrictedJobIds?.length ? restrictedJobIds : [],
			notificationNewJob: isEditMode ? userAccessForm?.notificationNewJob : false,
			notificationNewApplication: isEditMode ? userAccessForm?.notificationNewApplication : false,
			notificationNewMessage: isEditMode ? userAccessForm?.notificationNewMessage : false,
			notificationCandidateReassigned: isEditMode
				? userAccessForm?.notificationCandidateReassigned
				: false,
			notificationCandidateRejected: isEditMode
				? userAccessForm?.notificationCandidateRejected
				: false,
			notificationSupportTicket: isEditMode ? userAccessForm?.notificationSupportTicket : false,
		}),
		[userAccessForm, isEditMode, restrictedQuestionnaireIds, restrictedJobIds],
	);

	const onSubmit = (values: IUserBaseFields) => {
		const preparedValues = trimSpacesFormValues(
			values?.role === UserRolesEnum.JAT_USER
				? {
					...values,
					restrictedQuestionnaireIds: values?.restrictedQuestionnaireIds || [],
					restrictedJobIds: values?.restrictedJobIds || [],
				  }
				: omit(values, ['restrictedQuestionnaireIds', 'restrictedJobIds']),
		);

		if (isEditMode) {
			updateAtsUserRequested({ ...preparedValues, id: +userId }, () =>
				navigate(SettingsRoutes.UserAccess),
			);
		} else {
			createAtsUserRequested(preparedValues, () => navigate(SettingsRoutes.UserAccess));
		}
	};

	useEffect(() => {
		if (isEditMode) {
			getAtsUserByIdRequested(userId);
		}
		!atsUserRoles?.length && getAtsUserRolesRequested();
	}, [isEditMode, userId]);

	useMount(() => {
		subscriptionPlans?.isPremiumPlan && !questionnairesList.length &&
			getQuestionnaires({ active: true });
		!activeJobsList?.length && getAtsActiveJobsRequested();
	});

	const isLoading = loadingAts?.createAtsUserLoad || loadingAts?.updateAtsUserLoad;

	return (
		<Styled.Root>
			{loadingAts?.getAtsUserRolesLoad || loadingAts?.getAtsUserByIdLoad ? (
				<Box style={{ justifyContent: 'center', height: '100%', width: '100%' }}>
					<Spinner />
				</Box>
			) : (
				<Box style={{ height: '100%' }}>
					<Styled.Head>
						<PageNavTitle
							title={
								isEditMode ? `${userAccessForm.firstName} ${userAccessForm.lastName}` : 'New User'
							}
							navigationLink={SettingsRoutes.UserAccess}
							noSpace
						/>
					</Styled.Head>
					<Styled.Content>
						<Form
							onSubmit={onSubmit}
							initialValues={initialValues}
							autoComplete='off'
							mutators={{
								...arrayMutators,
							}}
							render={({ handleSubmit, dirty, values }) => {
								const isRestrictedJobsFieldShown = values?.role === UserRolesEnum.JAT_USER;
								const isQuestionnairesFieldShown = subscriptionPlans?.isPremiumPlan;

								return (
									<form onSubmit={handleSubmit}>
										<>
											<FormBlockLine>
												<Field name='firstName' validate={requiredFieldValidator}>
													{({ input, meta }) => (
														<FieldWrapper
															name='firstName'
															label='First Name'
															errorMessage={meta.submitFailed && meta.touched && meta.error}
															required
														>
															<Input {...input} placeholder='Enter first name...' />
														</FieldWrapper>
													)}
												</Field>
												<Field name='lastName' validate={requiredFieldValidator}>
													{({ input, meta }) => (
														<FieldWrapper
															name='lastName'
															label='Last Name'
															errorMessage={meta.submitFailed && meta.touched && meta.error}
															required
														>
															<Input {...input} placeholder='Enter last name...' />
														</FieldWrapper>
													)}
												</Field>
											</FormBlockLine>
											<FormBlockLine>
												<Field
													name='email'
													validate={composeValidators(requiredFieldValidator, emailValidator)}
												>
													{({ input, meta }) => (
														<FieldWrapper
															name='email'
															label='Email'
															errorMessage={meta.submitFailed && meta.touched && meta.error}
															required
														>
															<Input {...input} placeholder='example@gmail.com' />
														</FieldWrapper>
													)}
												</Field>
												<Field name='role' validate={requiredFieldValidator}>
													{({ input, meta }) => (
														<FieldWrapper
															name='role'
															label='Access'
															errorMessage={meta.submitFailed && meta.touched && meta.error}
															required
														>
															<Select
																{...input}
																value={input.value || null}
																placeholder='Select a role type'
															>
																{atsUserRoles?.map((option: IOption) => (
																	<Option key={option.id} value={option.id}>
																		{option.value}
																	</Option>
																))}
															</Select>
														</FieldWrapper>
													)}
												</Field>
											</FormBlockLine>
											<Styled.FormBlockLine columns={2}>
												<>
													{isRestrictedJobsFieldShown && (
														<Field name='restrictedJobIds'>
															{({ input, meta }) => (
																<FieldWrapper
																	name='restrictedJobIds'
																	label='Job Access'
																	errorMessage={meta.submitFailed && meta.touched && meta.error}
																>
																	<Styled.SelectAntd
																		{...input}
																		mode='multiple'
																		value={input.value || null}
																		options={activeJobsList}
																		placeholder='Select Jobs'
																		fieldNames={{ label: 'title', value: 'id' }}
																		autoClearSearchValue
																	/>
																	<Styled.FieldDescription>
																		Standard users access will be restricted to the jobs in this
																		list. If the list is left empty, user will have access to all
																		jobs
																	</Styled.FieldDescription>
																</FieldWrapper>
															)}
														</Field>
													)}
													{isRestrictedJobsFieldShown && isQuestionnairesFieldShown && (
														<Field name='restrictedQuestionnaireIds'>
															{({ input, meta }) => (
																<FieldWrapper
																	name='restrictedQuestionnaireIds'
																	label='Restricted video questionnaires'
																	errorMessage={meta.submitFailed && meta.touched && meta.error}
																>
																	<Styled.SelectAntd
																		{...input}
																		mode='multiple'
																		value={input.value || null}
																		options={questionnairesList}
																		placeholder='Select Questionnaires'
																		autoClearSearchValue
																	/>
																</FieldWrapper>
															)}
														</Field>
													)}
													{isEditMode ? (
														<Field name='password' validate={passwordValidator}>
															{({ input, meta }) => (
																<FieldWrapper
																	name='password'
																	label='Password'
																	errorMessage={meta.submitFailed && meta.touched && meta.error}
																>
																	<Input.Password {...input} placeholder='' />
																</FieldWrapper>
															)}
														</Field>
													) : (
														<div />
													)}
												</>
											</Styled.FormBlockLine>
											<Styled.Notifications>
												<Styled.NotificationsHeader>Notifications</Styled.NotificationsHeader>
												<Styled.NotificationsList>
													<Field name='notificationNewJob'>
														{({ input }) => <FormSwitch {...input} title='New jobs are listed' />}
													</Field>
													<Field name='notificationNewApplication'>
														{({ input }) => (
															<FormSwitch {...input} title='New applications are received' />
														)}
													</Field>
													<Field name='notificationNewMessage'>
														{({ input }) => (
															<FormSwitch
																{...input}
																title='An email is received from a candidate'
															/>
														)}
													</Field>
													<Field name='notificationCandidateReassigned'>
														{({ input }) => (
															<FormSwitch {...input} title='A candidate is re-assigned' />
														)}
													</Field>
													<Field name='notificationCandidateRejected'>
														{({ input }) => (
															<FormSwitch {...input} title='A candidate is rejected' />
														)}
													</Field>
													<Field name='notificationSupportTicket'>
														{({ input }) => (
															<FormSwitch {...input} title='A support ticket is updated' />
														)}
													</Field>
												</Styled.NotificationsList>
											</Styled.Notifications>
										</>
										<TStyled.ButtonBox>
											<ATSThemedButton
												buttonType={ButtonTypes.primary}
												type='submit'
												disabled={!dirty}
											>
												{isLoading ? <Spin /> : 'Save'}
											</ATSThemedButton>
										</TStyled.ButtonBox>
									</form>
								);
							}}
						/>
					</Styled.Content>
				</Box>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		atsUserRoles: atsDucks.atsSelectors.getAtsUserRolesState(state),
		userAccessForm: atsDucks.atsSelectors.getSettingsUserAccessForm(state),
		subscriptionPlans: atsDucks.atsSelectors.getSubscriptionPlans(state),
		questionnairesList: commonDucks.commonSelectors.getAtsQuestionnairesList(state),
		loadingAts: atsDucks.atsSelectors.getAtsLoading(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
		activeJobsList: atsDucks.atsSelectors.getActiveJobsData(state),
	}),
	{
		getAtsUserRolesRequested: atsDucks.atsActions.getAtsUserRolesRequested,
		getAtsUserByIdRequested: atsDucks.atsActions.getAtsUserByIdRequested,
		createAtsUserRequested: atsDucks.atsActions.createAtsUserRequested,
		updateAtsUserRequested: atsDucks.atsActions.updateAtsUserRequested,
		getQuestionnaires: commonDucks.commonActions.getAllAtsQuestionnairesRequested,
		getAtsActiveJobsRequested: atsDucks.atsActions.getAtsActiveJobsRequested,
	},
)(ATSUserAccessForm);
