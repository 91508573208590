import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function Icon({ fill = COLORS.black, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M23 6.01766V18C23 19.6523 21.6523 21 20 21H4C2.34772 21 1 19.6523 1 18V6C1 4.34772 2.34772 3 4 3H20C21.6443 3 22.9869 4.3347 22.9999 5.97604C23.0002 5.98992 23.0003 6.0038 23 6.01766ZM3.1066 5.5539C3.27199 5.2269 3.61206 5 4 5H20C20.388 5 20.7281 5.22694 20.8934 5.55399L12.0001 11.7793L3.1066 5.5539ZM3 7.92059V18C3 18.5477 3.45228 19 4 19H20C20.5477 19 21 18.5477 21 18V7.92071L12.5735 13.8192C12.2292 14.0603 11.7709 14.0603 11.4266 13.8192L3 7.92059Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(Icon);
