import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		min-height: 100%;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		background-color: ${COLORS.white};
		overflow-x: scroll;
		padding-bottom: 60px;
	`;

	export const ExpiredLinkWrapper = styled.div`
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100vh;
	`;

	export const ExpiredLinkInner = styled.div`
		text-align: center;
		border-radius: 4px;
		font-size: 24px;
		border: 1px solid ${COLORS.lightGray2};
		background: ${COLORS.lightGray2};
		box-shadow: ${COLORS.elevationShadow};
		padding: 24px;
	`;

	export const Content = styled.div`
		max-width: 1368px;
		width: 100%;
		height: 100%;
	`;

	export const BackgroundImage = styled.img`
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		width: 100vw;
		max-height: 300px;
		object-fit: cover;
	`;

	export const Footer = styled.div`
		position: fixed;
		bottom: 0;
		background-color: #000524;
		display: flex;
		justify-content: center;
		width: 100%;
	`;
	export const FooterInner = styled.div`
		max-width: 1368px;
		width: 100%;
		padding: 16px 0;
		display: flex;
		justify-content: flex-end;
	`;
	export const FooterContent = styled.div`
		display: flex;
	`;
	export const FooterItem = styled.div`
		color: ${COLORS.white};
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		margin-right: 24px;

		a {
			color: ${COLORS.white};
		}
	`;
}
