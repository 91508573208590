import styled from 'styled-components/macro';

import { FOOTER_HEIGHT } from '../../modules/Common/constants';

interface IStyledProps {
	fullWidth: boolean;
	fixed?: boolean;
	position?: string;
}

export namespace Styled {
	export const Root = styled.section<IStyledProps>`
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		min-height: 100%;

		${({ fullWidth, fixed, position, theme: { size } }) => `
			width: 100%;
			max-width: ${fullWidth ? '100%' : size.large};
			position: ${fixed ? position : 'relative'};
		`}
		z-index: 99;

		svg {
			transform: ${({ fixed, position }) =>
		fixed && position === 'fixed' ? `translateY(calc(-50% - ${FOOTER_HEIGHT}px))` : 'none'};
		}
	`;

	export const Overlay = styled.div`
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.7);
		z-index: 99;
	`;
}
