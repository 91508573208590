import React from 'react';

import ATSOrderCredits from 'modules/ATS/containers/ATSOrderCredits';
import { AtsNavigationMenuList } from 'modules/Common/types';

import { Styled } from './ATSCreditsPage.styled';

export const ATSCreditsPage = () => {
	return (
		<Styled.Root>
			<h2>{AtsNavigationMenuList.Credits}</h2>
			<ATSOrderCredits />
		</Styled.Root>
	);
};
