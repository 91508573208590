import dayjs from 'dayjs';

import { TimeOptions } from 'modules/Common/constants';
import { IContractValues, IInvoicesDetailedValues } from 'modules/Common/types';

export const AllowedParametersInvoice = [
	'id',
	'clientId',
	'btoReference',
	'name',
	'poNumber',
	'chargeVat',
	'contractId',
	'invoiceDate',
	'paymentTerm',
	'paymentTermTimeUnit',
	'premiumMultisiteCount',
	'premiumMultisitePrice',
	'cvFilteringCount',
	'cvFilteringPrice',
	'guardianCount',
	'guardianPrice',
	'indeedCount',
	'indeedPrice',
];

export const defaultValues = {
	btoReference: '',
	poNumber: '',
	paymentTerm: null,
	paymentTermTimeUnit: TimeOptions[0].value,
	invoiceDate: '',
	premiumMultisiteCount: 0,
	premiumMultisitePrice: 0,
	cvFilteringCount: 0,
	cvFilteringPrice: 0,
	guardianCount: 0,
	guardianPrice: 0,
	indeedCount: 0,
	indeedPrice: 0,
};

export const prepareInvoiceValues = (values: IInvoicesDetailedValues) => {
	const updatedValues = {
		...values,
		invoiceId: values.id,
		client: values.clientName,
		contract: values?.contractObj?.btoReference,
		contractId: values?.contractObj?.contractId,
		invoiceDate: dayjs(values.dateDue),
	};

	return updatedValues;
};

export const contractDecorator =
	(resolve: (values: unknown) => void) => (contractDetails: IContractValues) => {
		const resolvedValues = Object.keys(defaultValues).reduce((acc, key) => {
			if (key === 'invoiceDate' && contractDetails.startDate) {
				acc[key] = dayjs(contractDetails.startDate);
			} else if (key in contractDetails) {
				acc[key] = contractDetails[key] || defaultValues[key];
			} else {
				acc[key] = defaultValues[key];
			}

			return acc;
		}, {});

		return resolve?.(resolvedValues);
	};
