import React, { type FC } from 'react';
import { Form, Field } from 'react-final-form';

import { Button, Input, Select } from 'antd';

import FieldWrapper from 'components/FieldWrapper';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { ClientAddressType } from 'modules/Common/types';
import { IOption } from 'types';
import { requiredValidator, emailValidator, validateOptionalField } from 'utils/validators';

import { Styled } from './CareerPageAddressForm.styled';

type CareerPageAddressFormProps = {
	clientRegions: IOption[];
	clientAddress?: ClientAddressType;
	changeClientAddress: (values: ClientAddressType) => void;
};

const CareerPageAddressForm: FC<CareerPageAddressFormProps> = ({
	clientRegions,
	changeClientAddress,
	clientAddress,
}) => {
	return (
		<Styled.FormWrapper>
			<Styled.FormInner>
				<Form
					onSubmit={changeClientAddress}
					initialValues={clientAddress}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<FormBlockLine>
								<Field name='country' validate={requiredValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='country'
											label='Country'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Select
												{...input}
												value={input.value || null}
												options={clientRegions}
												placeholder='Select country'
											/>
										</FieldWrapper>
									)}
								</Field>
								<Field name='city' validate={requiredValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='city'
											label='City'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Input {...input} placeholder='e.g. London' />
										</FieldWrapper>
									)}
								</Field>
							</FormBlockLine>
							<FormBlockLine>
								<Field name='address' validate={requiredValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='address'
											label='Address'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Input {...input} placeholder='e.g. Lukin Street, 4' />
										</FieldWrapper>
									)}
								</Field>
								<Field name='zipCode' validate={requiredValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='zipCode'
											label='Zip code'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Input {...input} placeholder='e.g. E1 0AA' />
										</FieldWrapper>
									)}
								</Field>
							</FormBlockLine>
							<FormBlockLine>
								<Field name='email' validate={validateOptionalField(emailValidator)}>
									{({ input, meta }) => (
										<FieldWrapper
											name='email'
											label='Email'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Input {...input} placeholder='e.g. example@mail.com' />
										</FieldWrapper>
									)}
								</Field>
								<Field name='phoneNumber'>
									{({ input, meta }) => (
										<FieldWrapper
											name='phoneNumber'
											label='Phone number'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Input {...input} placeholder='e.g. +1(404) 3233 233' />
										</FieldWrapper>
									)}
								</Field>
							</FormBlockLine>
							<Button type='primary' htmlType='submit' block>
								Save Changes
							</Button>
						</form>
					)}
				></Form>
			</Styled.FormInner>
		</Styled.FormWrapper>
	);
};

export default CareerPageAddressForm;
