import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		padding: 32px 0;
	`;
	export const PageTitle = styled.h2`
		margin-bottom: 20px;
	`;
}
