import { Tag as TagAnt } from 'antd';
import styled from 'styled-components/macro';

export namespace Styled {
	export const Tag = styled(TagAnt)`
		margin: 0;
		line-height: 28px;
		width: 110px;
		height: 28px;
		text-align: center;
	`;
}
