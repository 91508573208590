import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace S {
	export const Root = styled.section`
		display: inline-block;
		text-align: center;
		height: 24px;
		margin: 0 5px;
		padding: 0;
		font-weight: 600;
		font-size: 12px;
		background: ${COLORS.lightBlue3};
		border: 1px solid ${COLORS.lightBlue4};
		border-radius: 4px;
		vertical-align: middle;
	`;

	export const Icon = styled.div`
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 24px;
		height: 22px;
		border-right: 1px solid ${COLORS.lightBlue4};
	`;

	export const Amount = styled.div`
		display: inline-block;
		min-width: 24px;
		padding: 0 4px;
		line-height: 22px;
		vertical-align: top;
	`;
}
