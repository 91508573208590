import React, { type FC } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { pick } from 'lodash';

import { useMount, useUnmount } from 'hooks';
import { AllowedTicketFields } from 'modules/BackOffice/constants/postTicketChangesForm';
import { backOfficeDucks } from 'modules/BackOffice/ducks';
import { commonDucks } from 'modules/Common/ducks';
import { IPostTicketBackOfficeValues, ITicketDetails, IUser } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { GenericType, IOption, OptionsType, Routes } from 'types';

import TicketDetails from './TicketDetails';
import TicketForm from './TicketForm';

type ViewPostTicketProps = {
	isCreateMode: boolean;
	isEditMode: boolean;
	clientContextId?: string;
	ticketData?: ITicketDetails;
	user: IUser;
	clientsList: OptionsType[];
	ticketTypes: IOption[];
	ticketStatuses: IOption[];
	getClientsShortListRequested: () => void;
	getTicketsTypesRequested: () => void;
	getTicketsStatusesRequested: () => void;
	createBackOfficeTicketRequested: (
		values: IPostTicketBackOfficeValues,
		callback: () => void,
	) => void;
	updateBackOfficeTicketRequested: (
		values: IPostTicketBackOfficeValues,
		callback: () => void,
	) => void;
	updateBackOfficeTicketStatusRequested: (props: {
		ticketId: number;
		ticketStatus: number;
	}) => void;
	resetPostTicketDetailsProcess: () => void;
	backOfficeLoading: GenericType;
};

const ViewPostTicket: FC<ViewPostTicketProps> = ({
	ticketData,
	isCreateMode,
	isEditMode,
	clientContextId,
	user,
	clientsList,
	ticketTypes,
	ticketStatuses,
	getClientsShortListRequested,
	getTicketsTypesRequested,
	getTicketsStatusesRequested,
	createBackOfficeTicketRequested,
	updateBackOfficeTicketRequested,
	updateBackOfficeTicketStatusRequested,
	resetPostTicketDetailsProcess,
	backOfficeLoading,
}) => {
	const navigate = useNavigate();

	const onSubmit = (values: IPostTicketBackOfficeValues) => {
		const navigationLink = clientContextId
			? `${Routes.BOClientContext}/${clientContextId}${Routes.SupportTickets}`
			: `${Routes.BackOffice}${Routes.SupportTickets}`;
		if (isEditMode) {
			updateBackOfficeTicketRequested({ ...pick(values, AllowedTicketFields) }, () =>
				navigate(navigationLink),
			);
		} else {
			createBackOfficeTicketRequested({ ...values }, () => navigate(navigationLink));
		}
	};

	useMount(() => {
		!clientsList?.length && getClientsShortListRequested();
		!ticketTypes?.length && getTicketsTypesRequested();
		!ticketStatuses?.length && getTicketsStatusesRequested();
	});

	const submitButtonLoading = backOfficeLoading?.createBackofficeTicketLoad;

	useUnmount(() => {
		resetPostTicketDetailsProcess();
	});

	const handleUpdateTicketStatus = (ticketStatus: number) => {
		ticketData?.id &&
			updateBackOfficeTicketStatusRequested({ ticketId: ticketData.id, ticketStatus });
	};

	return (
		<>
			{isCreateMode || isEditMode ? (
				<TicketForm
					isEditMode={isEditMode}
					clientContextId={clientContextId}
					onSubmitHandler={onSubmit}
					ticketData={ticketData}
					user={user}
					ticketStatuses={ticketStatuses}
					clientsList={clientsList}
					ticketTypes={ticketTypes}
					submitButtonLoading={submitButtonLoading as boolean}
				/>
			) : (
				<TicketDetails
					ticketData={ticketData}
					clientContextId={clientContextId}
					ticketStatuses={ticketStatuses}
					handleUpdateTicketStatus={handleUpdateTicketStatus}
					user={user}
				/>
			)}
		</>
	);
};

export default connect(
	(state) => ({
		ticketTypes: commonDucks.commonSelectors.getTicketTypes(state),
		ticketStatuses: commonDucks.commonSelectors.getTicketStatuses(state),
		backOfficeLoading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
		clientsList: backOfficeDucks.backOfficeSelectors.getClientsShortData(state),
		user: unregisteredDucks.unregisteredSelectors.getUser(state),
	}),
	{
		getClientsShortListRequested: backOfficeDucks.backOfficeActions.getClientsShortRequested,
		getTicketsTypesRequested: commonDucks.commonActions.getTicketsTypesRequested,
		getTicketsStatusesRequested: commonDucks.commonActions.getTicketsStatusesRequested,
		createBackOfficeTicketRequested:
			backOfficeDucks.backOfficeActions.createBackOfficeTicketRequested,
		updateBackOfficeTicketRequested:
			backOfficeDucks.backOfficeActions.updateBackOfficeTicketRequested,
		updateBackOfficeTicketStatusRequested:
			backOfficeDucks.backOfficeActions.updateBackOfficeTicketStatusRequested,
		resetPostTicketDetailsProcess: backOfficeDucks.backOfficeActions.resetPostTicketDetailsProcess,
	},
)(ViewPostTicket);
