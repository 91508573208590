import React, { type FC, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';

import { Button, Input } from 'antd';
import _ from 'lodash';

import FieldWrapper from 'components/FieldWrapper';
import Spinner from 'components/Spinner';
import PriceBundle from 'modules/BackOffice/components/PriceBundle';
import { backOfficeDucks } from 'modules/BackOffice/ducks';
import FormBlock from 'modules/Common/components/FormBlock';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { commonDucks } from 'modules/Common/ducks';
import { BaseCreditType, ISubsPrices, CreditDefaultBundleType } from 'modules/Common/types';
import { GenericType } from 'types';
import { getBudleInputWidth } from 'utils/helpers';
import { requiredValidator } from 'utils/validators';

import {
	prepareCreditsDefaultValues,
	prepareCreditsDefaultValuesSubmit,
} from './CreditPrices.helpers';
import { Styled } from './CreditPrices.styled';

type CareerPageAddressFormProps = {
	baseCredits: BaseCreditType[];
	getCreditsRequested: () => void;
	getCreditsDefaultPrices: () => void;
	updateCreditsDefaultPrices: () => void;
	getSubsDefaultPrices: () => void;
	updateSubsDefaultPrices: (prices: ISubsPrices) => void;
	creditsDefaultPrices: CreditDefaultBundleType[];
	subsDefaultPrices: ISubsPrices;
	loading: GenericType;
	commonLoading: GenericType;
};

const SUB_PRICES_FIELDS = ['ukMonthlyPrice', 'ukAnnualPrice', 'usaMonthlyPrice', 'usaAnnualPrice'];

const CareerPageAddressForm: FC<CareerPageAddressFormProps> = ({
	baseCredits,
	loading,
	commonLoading,
	getCreditsRequested,
	getCreditsDefaultPrices,
	updateCreditsDefaultPrices,
	getSubsDefaultPrices,
	updateSubsDefaultPrices,
	creditsDefaultPrices,
	subsDefaultPrices,
}) => {
	const onsubmit = (v) => {
		const subPrices = _.pick(v, SUB_PRICES_FIELDS);
		const creditPricesFields = _.pick(
			v,
			_.keys(v).filter((fieldName: string): boolean => fieldName.startsWith('price')),
		);

		const creditPrices = prepareCreditsDefaultValuesSubmit(creditPricesFields);

		updateSubsDefaultPrices(subPrices);
		updateCreditsDefaultPrices(creditPrices);
	};

	useEffect(() => {
		getCreditsRequested();
		getCreditsDefaultPrices();
		getSubsDefaultPrices();
	}, []);

	if (
		(commonLoading.getCreditsLoad ||
			loading.getSubsDefaultPricesLoad ||
			loading.getCreditsDefaultPricesLoad) &&
		!(creditsDefaultPrices.length || !_.isEmpty(subsDefaultPrices))
	) {
		return <Spinner />;
	}

	const formInitialValues = {
		...subsDefaultPrices,
		...prepareCreditsDefaultValues(creditsDefaultPrices),
	};

	return (
		<Styled.Root>
			<Form
				onSubmit={onsubmit}
				initialValues={formInitialValues}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<FormBlock title='Subscription'>
							<Styled.CountryPricing>
								<Styled.CountryPricingLabel>UK:</Styled.CountryPricingLabel>
								<Styled.CountryPricingContent>
									<FormBlockLine>
										<Field name='ukAnnualPrice' validate={requiredValidator}>
											{({ input, meta }) => (
												<FieldWrapper
													name='ukAnnualPrice'
													label='Annual Price'
													required
													errorMessage={meta.submitFailed && meta.touched && meta.error}
												>
													<Input {...input} placeholder='100' />
												</FieldWrapper>
											)}
										</Field>
										<Field name='ukMonthlyPrice' validate={requiredValidator}>
											{({ input, meta }) => (
												<FieldWrapper
													name='ukMonthlyPrice'
													label='Monthly Price'
													required
													errorMessage={meta.submitFailed && meta.touched && meta.error}
												>
													<Input {...input} placeholder='10' />
												</FieldWrapper>
											)}
										</Field>
									</FormBlockLine>
								</Styled.CountryPricingContent>
							</Styled.CountryPricing>
							<Styled.CountryPricing>
								<Styled.CountryPricingLabel>USA:</Styled.CountryPricingLabel>
								<Styled.CountryPricingContent>
									<FormBlockLine>
										<Field name='usaAnnualPrice' validate={requiredValidator}>
											{({ input, meta }) => (
												<FieldWrapper
													name='usaAnnualPrice'
													label='Annual Price'
													required
													errorMessage={meta.submitFailed && meta.touched && meta.error}
												>
													<Input {...input} placeholder='100' />
												</FieldWrapper>
											)}
										</Field>
										<Field name='usaMonthlyPrice' validate={requiredValidator}>
											{({ input, meta }) => (
												<FieldWrapper
													name='usaMonthlyPrice'
													label='Monthly Price'
													required
													errorMessage={meta.submitFailed && meta.touched && meta.error}
												>
													<Input {...input} placeholder='10' />
												</FieldWrapper>
											)}
										</Field>
									</FormBlockLine>
								</Styled.CountryPricingContent>
							</Styled.CountryPricing>
						</FormBlock>

						<FormBlock title='Credit Default Prices'>
							{baseCredits.map((credit) => {
								const bundles = credit?.bundles.slice(1, -1).replace(/\s/g, '').split(',');

								return (
									<Styled.Bundles key={credit.id}>
										<Styled.BundlesLabel>{credit.name}</Styled.BundlesLabel>
										<Styled.BundlesWrapper key={credit.id}>
											{bundles?.map((bundle: string) => {
												const bundleName = `price-${credit.id}-${bundle}`;

												return (
													<PriceBundle
														key={bundle}
														bundle={bundle}
														bundles={bundles}
														defaultValue={formInitialValues[bundleName]}
														inputWidth={getBudleInputWidth(bundles.length)}
														name={bundleName}
													/>
												);
											})}
										</Styled.BundlesWrapper>
									</Styled.Bundles>
								);
							})}
						</FormBlock>
						<Styled.SubmitButtonWrapper>
							<Button type='primary' htmlType='submit'>
								Save Changes
							</Button>
						</Styled.SubmitButtonWrapper>
					</form>
				)}
			></Form>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		baseCredits: commonDucks.commonSelectors.getCredits(state),
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
		creditsDefaultPrices: backOfficeDucks.backOfficeSelectors.getCreditsDefaultPricesState(state),
		subsDefaultPrices: backOfficeDucks.backOfficeSelectors.getSubsDefaultPricesState(state),
		commonLoading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		getCreditsRequested: commonDucks.commonActions.getCreditsRequested,
		getCreditsDefaultPrices: backOfficeDucks.backOfficeActions.getCreditsDefaultPricesRequested,
		updateCreditsDefaultPrices:
			backOfficeDucks.backOfficeActions.updateCreditsDefaultPricesRequested,
		getSubsDefaultPrices: backOfficeDucks.backOfficeActions.getSubsDefaultPricesRequested,
		updateSubsDefaultPrices: backOfficeDucks.backOfficeActions.updateSubsDefaultPricesRequested,
	},
)(CareerPageAddressForm);
