import React, { memo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { INavListItem } from 'modules/Common/types';
import { Routes } from 'types';

import { S } from './SubContextMenu.styled';

type MainMenuType = {
	clientId: string;
	isFullWidth: boolean;
	items: INavListItem[];
};

const SubContextMenu: React.FC<MainMenuType> = ({ clientId, items, isFullWidth = false }) => {
	const { state } = useLocation();

	const currentClientId = clientId ?? state?.clientId;

	return (
		<S.Root>
			<S.MainMenu isFullWidth={isFullWidth}>
				{items?.map((item: INavListItem) => (
					<S.MenuItem key={`${currentClientId}-${item.id}`}>
						<NavLink
							to={`${Routes.BOClientContext}/${currentClientId}${item.path}`}
							state={{ clientId: currentClientId }}
						>
							{item.name}
						</NavLink>
					</S.MenuItem>
				))}
			</S.MainMenu>
		</S.Root>
	);
};

export default memo(SubContextMenu);
