import { useCallback, type FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import Cookies from 'js-cookie';

import { Routes, CookiesType } from 'types';

import { Styled } from './CountBadge.styled';

type CountBadgeProps = {
	count: {
		available: number;
		total: number;
	};
	yearMonth: string;
	counterName: string;
};

export const CountBadge: FC<CountBadgeProps> = ({ count, yearMonth, counterName }) => {
	const monthDate = dayjs(yearMonth, 'YYYY-MM');
	const month = monthDate.format('MMMM');
	const { available } = count;
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const isImpersonate = !!Cookies.get(CookiesType.boImpersonateJWT);

	const handleClick = useCallback(() => {
		const currentDate = dayjs();
		const dateToPass = monthDate.isBefore(currentDate) ? currentDate : monthDate;
		navigate(`${Routes.ATS}${Routes.BuyVideoCredits}`, {
			state: { prevPage: pathname, yearMonth: dateToPass.format('YYYY-MM') },
		});
	}, [monthDate, pathname, navigate]);

	return (
		<>
			<Styled.Box onClick={isImpersonate ? () => null : handleClick}>
				<Styled.LeftCol>{available}</Styled.LeftCol>
				<Styled.RightCol>
					<Styled.FirstLine>{counterName}</Styled.FirstLine>
					<Styled.SecondLine>
						<Styled.SecondLineText>Remaining in {month}</Styled.SecondLineText>
					</Styled.SecondLine>
				</Styled.RightCol>
				{available === 0 ? <Styled.BuyNowLink>Buy Now</Styled.BuyNowLink> : null}
			</Styled.Box>
		</>
	);
};
