import React, { FC, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';

import Box from 'components/Box';
import BrandingPageSwitcher from 'components/BrandingPageSwitcher';
import { atsDucks } from 'modules/ATS/ducks';
import { SettingsRoutes, SubscriptionPlansType } from 'types';

import AtsClientBrandingContainer from './AtsClientBrandingContainer';
import { Styled } from './ClientBranding.styled';
import CareerPageContainer from './ClientCareerPageContainer';

const SWITCHER_ITEMS = [
	{
		id: '1',
		value: 'ATS',
	},
	{
		id: '2',
		value: 'Career Page',
	},
];

type ClientBrandingProps = {
	subscriptionPlans: SubscriptionPlansType;
};

const ClientBranding: FC<ClientBrandingProps> = ({ subscriptionPlans }) => {
	const navigate = useNavigate();
	const [activeItem, onSwitch] = useState(SWITCHER_ITEMS[0].id);

	const handleSwitchTab = useCallback(
		(id: string) => {
			onSwitch(id);
		},
		[activeItem],
	);

	return (
		<Styled.Root>
			<Box noSpaces>
				<h2>Branding Page</h2>
				<>
					{!subscriptionPlans?.isPremiumPlan && (
						<div>
							<span style={{ marginRight: '24px' }}>
								This feature requires a pro membership, upgrade or try free here
							</span>
							<Button type='primary' onClick={() => navigate(SettingsRoutes.Subscription)}>
								Upgrade Plan
							</Button>
						</div>
					)}
					<BrandingPageSwitcher
						onSwitch={handleSwitchTab}
						activeItem={activeItem}
						items={SWITCHER_ITEMS}
					/>
					{activeItem === SWITCHER_ITEMS[0].id && <AtsClientBrandingContainer />}
					{activeItem === SWITCHER_ITEMS[1].id && <CareerPageContainer />}
				</>
			</Box>
		</Styled.Root>
	);
};

export default connect((state) => ({
	subscriptionPlans: atsDucks.atsSelectors.getSubscriptionPlans(state),
}))(ClientBranding);
