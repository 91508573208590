import styled from 'styled-components/macro';

export namespace Styled {
	export const FormWrapper = styled.div`
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		z-index: 5;
	`;
	export const FormInner = styled.div`
		padding: 24px 32px 40px;
		background: rgba(255, 255, 255, 0.4);
		border: 1px solid rgba(255, 255, 255, 0.3);
		backdrop-filter: blur(5px);
		max-width: 680px;
		width: 100%;
	`;
}
