import styled from 'styled-components/macro';

import Box from 'components/Box';
import { COLORS, device } from 'theme';

export namespace Styled {
	export const BoxWrap = styled(({ isBoxComponent, ...props }) => {
		const Component = isBoxComponent ? Box : 'div';

		return <Component {...props} />;
	})``;
	export const FieldRow = styled.div`
		display: flex;
		gap: 32px;

		&:last-child > div {
			margin-bottom: 0;
		}
	`;

	export const Field = styled.div`
		display: flex;
		flex-direction: column;
		width: 50%;
		margin-bottom: 32px;

		span {
			display: block;
			width: 100%;
			min-height: 40px;
			margin-top: 8px;
			padding: 8px 12px;
			flex: 1;
			font-weight: 500;
			line-height: 22px;
			background: ${COLORS.lightGray3};
			border: 1px solid ${COLORS.lightGray1};
			border-radius: 4px;
			color: ${COLORS.darkGray2};
			white-space: break-spaces;
		}

		@media ${device.tablet} {
			width: 100%;
		}
	`;
}
