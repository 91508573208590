import styled from 'styled-components/macro';

export namespace Styled {
	export const FilterBox = styled.div`
		position: absolute;
		right: 0;
		display: flex;
		justify-content: flex-end;
		z-index: 999;

		.ant-select {
			min-width: 150px;
		}
	`;
}
