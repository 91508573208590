import React, { type FC, memo } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Input, Popconfirm, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { FormApi } from 'final-form';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import ArrowDown from 'components/SVG/ArrowDown';
import ArrowUp from 'components/SVG/ArrowUp';
import DeleteIcon from 'components/SVG/DeleteIcon';
import { IQuestionnaireValues } from 'modules/Common/types';
import { COLORS } from 'theme';
import { IOption } from 'types';
import { requiredFieldValidator } from 'utils/validators';

import { Styled } from './QuestionBlocks.styled';

const { Option } = Select;

const DEFAULT_QUESTIONS = 5;
const DEFAULT_SHOWN_QUESTIONS = 12;

const InitItem = {
	questionText: '',
	duration: 2,
};

type QuestionBlocksProps = {
	form: FormApi<IQuestionnaireValues, Partial<IQuestionnaireValues>>;
	durations: IOption[];
	values: IQuestionnaireValues;
	isPreviewMode?: boolean;
};

const QuestionBlocks: FC<QuestionBlocksProps> = ({
	form,
	durations,
	values,
	isPreviewMode = false,
}) => {
	const {
		mutators: { push },
	} = form;

	const handleAddQuestion = (e: { preventDefault: () => void }) => {
		e.preventDefault();

		push('questions', InitItem);
	};

	const isMaxQuestions = values?.questions?.length >= DEFAULT_SHOWN_QUESTIONS;

	return (
		<>
			<Styled.QuestionContainer>
				<FieldArray name='questions'>
					{({ fields }) =>
						fields?.map((item, index) => {
							const id = index + 1;

							return (
								<Styled.QuestionItem key={id}>
									<Styled.QuestionItemHead>
										<h3>{`Question ${id}`}</h3>
										{!isPreviewMode && (
											<Styled.QuestionButtons>
												<Styled.Button
													buttonType={ButtonTypes.default}
													type='button'
													icon={<ArrowUp />}
													onClick={() => fields.swap(index, index - 1)}
													disabled={index <= 0}
												/>
												<Styled.Button
													buttonType={ButtonTypes.default}
													type='button'
													icon={<ArrowDown />}
													onClick={() => fields.swap(index, index + 1)}
													disabled={index >= values?.questions?.length - 1}
												/>
												<Popconfirm
													placement='bottomRight'
													title='Are you sure you want to remove this question?'
													onConfirm={() => fields.remove(index)}
													okText='Delete'
													cancelText='Cancel'
												>
													<>
														<Styled.Button
															buttonType={ButtonTypes.default}
															type='button'
															icon={<DeleteIcon fill={COLORS.black} />}
															disabled={values?.questions?.length <= 1}
														/>
													</>
												</Popconfirm>
											</Styled.QuestionButtons>
										)}
									</Styled.QuestionItemHead>
									<Styled.QuestionRow>
										<Field name={`${item}.questionText`} validate={requiredFieldValidator}>
											{({ input, meta }) => (
												<FieldWrapper
													name={`${item}.questionText`}
													label='Question'
													errorMessage={meta.submitFailed && meta.touched && meta.error}
													fullWidth
													required
													isFixed
												>
													<Input
														{...input}
														placeholder={!isPreviewMode ? 'Please enter the question' : ''}
														readOnly={isPreviewMode}
													/>
												</FieldWrapper>
											)}
										</Field>
										<Field name={`${item}.duration`} validate={requiredFieldValidator}>
											{({ input, meta }) => (
												<FieldWrapper
													name={`${item}.duration`}
													label='Duration'
													errorMessage={meta.submitFailed && meta.touched && meta.error}
													fullWidth
													required
												>
													{isPreviewMode ? (
														<Input {...input} placeholder='' readOnly={isPreviewMode} />
													) : (
														<Select
															{...input}
															value={input.value || null}
															onChange={(value) => {
																fields.update(index, {
																	...values?.questions[index],
																});

																input.onChange(value);
															}}
															placeholder=''
														>
															{durations?.map((option: IOption) => (
																<Option key={option.id} value={option.id}>
																	{option.name}
																</Option>
															))}
														</Select>
													)}
												</FieldWrapper>
											)}
										</Field>
									</Styled.QuestionRow>
									<Styled.QuestionContent>
										<Field
											name={`${item}.description`}
											initialValue={values.questions[index]?.description}
										>
											{({ input }) => (
												<FieldWrapper name='description' label='Description'>
													<TextArea
														{...input}
														placeholder={
															!isPreviewMode
																? 'Please enter any additional relevant information'
																: ''
														}
														autoSize={{ minRows: 4 }}
														readOnly={isPreviewMode}
													/>
												</FieldWrapper>
											)}
										</Field>
									</Styled.QuestionContent>
								</Styled.QuestionItem>
							);
						})
					}
				</FieldArray>
			</Styled.QuestionContainer>

			{!isPreviewMode && (
				<>
					<Styled.TextHint>
						{values?.questions?.length >= DEFAULT_QUESTIONS && (
							<p>
								We recommend using no more than {DEFAULT_QUESTIONS} questions to keep candidates
								engaged
							</p>
						)}
						{isMaxQuestions && (
							<p>
								Maximum question limit reached. Please remove a question or consider combining
								similar questions
							</p>
						)}
					</Styled.TextHint>
					<Styled.ButtonBox>
						<Button
							buttonType={ButtonTypes.default}
							onClick={handleAddQuestion}
							disabled={isMaxQuestions}
						>
							Add Question
						</Button>
					</Styled.ButtonBox>
				</>
			)}
		</>
	);
};

export default memo(QuestionBlocks);
