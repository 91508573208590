import React, { FC, useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { message } from 'antd';

import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import { MESSAGES } from 'modules/Common/constants';
import SocialNetworkContainer from 'modules/Common/containers/SocialNetworkContainer';
import { ClientInfoType } from 'modules/Common/types';
import { AppModes, GenericType } from 'types';
import { trimSpacesFormValues } from 'utils/helpers';

import { backOfficeDucks } from '../../ducks';
import Branding from '../Branding';

type ClientChangesFormBrandingProps = {
	clientChangesFormData: Partial<ClientInfoType>;
	saveClientChangesFormProcess: (props: GenericType) => void;
	getClientById: (clientId: string) => void;
	loading: GenericType;
	mode?: AppModes;
};

const ClientChangesFormBranding: FC<ClientChangesFormBrandingProps> = ({
	clientChangesFormData,
	saveClientChangesFormProcess,
	getClientById,
	loading,
	mode,
}) => {
	const isEdit = mode === AppModes.Edit;
	const { clientId } = useParams();
	const { careerBranding } = clientChangesFormData;

	const handleSubmit = useCallback(
		(v: Partial<ClientInfoType>) => {
			saveClientChangesFormProcess({
				careerBranding: { ...careerBranding, socialNetworks: trimSpacesFormValues(v) },
			});
			message.success(MESSAGES.successfullySavedSocialNetworks);
		},
		[careerBranding],
	);

	useMount(() => {
		if (!clientChangesFormData?.id && clientId && isEdit) {
			getClientById(clientId);
		}
	});

	if (loading?.getCurrentClientLoad) {
		return <Spinner fixed />;
	}

	return (
		<>
			<SocialNetworkContainer
				defaultValues={careerBranding?.socialNetworks}
				handleSave={handleSubmit}
			/>
			<br />
			<br />
			<Branding />
		</>
	);
};

export default connect(
	(state) => ({
		clientChangesFormData: backOfficeDucks.backOfficeSelectors.getClientChangesFormState(state),
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
	}),
	{
		getClientById: backOfficeDucks.backOfficeActions.getCurrentClientRequested,
		saveClientChangesFormProcess: backOfficeDucks.backOfficeActions.saveClientChangesFormProcess,
	},
)(ClientChangesFormBranding);
