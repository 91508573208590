import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function ClockIcon({ fill = COLORS.darkGray1, width = '16', height = '16' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path fillRule="evenodd" clipRule="evenodd" d="M7.9996 4.66541C8.36778 4.66541 8.66626 4.96389 8.66626 5.33208V7.7226L9.80433 8.86067C10.0647 9.12102 10.0647 9.54313 9.80433 9.80348C9.54398 10.0638 9.12187 10.0638 8.86152 9.80348L7.52819 8.47015C7.40317 8.34512 7.33293 8.17555 7.33293 7.99874V5.33208C7.33293 4.96389 7.63141 4.66541 7.9996 4.66541Z" fill={fill}/>
			<path fillRule="evenodd" clipRule="evenodd" d="M11.5088 3.92173C10.5214 3.06012 9.25146 2.59215 7.94113 2.60712C6.6308 2.62209 5.37184 3.11894 4.40445 4.00287C3.43706 4.88681 2.82896 6.09597 2.69616 7.39964C2.65885 7.76593 2.33166 8.03262 1.96537 7.99531C1.59908 7.958 1.33238 7.63081 1.36969 7.26452C1.53569 5.63493 2.29582 4.12349 3.50505 3.01857C4.71429 1.91364 6.28799 1.29258 7.9259 1.27387C9.56381 1.25516 11.1513 1.84012 12.3854 2.91714C13.6196 3.99415 14.4141 5.48784 14.6172 7.11321C14.8204 8.73858 14.4181 10.3819 13.487 11.7295C12.5559 13.0772 11.1613 14.0349 9.56916 14.42C7.97704 14.805 6.29889 14.5904 4.85488 13.8172C4.00641 13.3628 3.27368 12.7343 2.6996 11.981V13.3321C2.6996 13.7003 2.40112 13.9987 2.03293 13.9987C1.66474 13.9987 1.36626 13.7003 1.36626 13.3321V9.99874C1.36626 9.63055 1.66474 9.33208 2.03293 9.33208H2.35071C2.36106 9.33183 2.3714 9.33184 2.3817 9.33208H5.36626C5.73445 9.33208 6.03293 9.63055 6.03293 9.99874C6.03293 10.3669 5.73445 10.6654 5.36626 10.6654H3.41758C3.91059 11.4947 4.62289 12.1805 5.48431 12.6417C6.63952 13.2604 7.98204 13.432 9.25574 13.124C10.5294 12.816 11.6451 12.0498 12.39 10.9716C13.1349 9.89351 13.4567 8.57888 13.2942 7.27859C13.1317 5.97829 12.4961 4.78334 11.5088 3.92173Z" fill={fill}/>
		</svg>
	);
}

export default memo(ClockIcon);