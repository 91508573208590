import React, { type FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'antd/es/button';

import Box from 'components/Box';
import SupportTickets from 'modules/BackOffice/containers/SupportTickets';
import { Routes } from 'types';

import { Styled } from './Tickets.styled';

const Tickets: FC = () => {
	const navigate = useNavigate();

	return (
		<Styled.Root>
			<Styled.Head>
				<Styled.Title>Tickets</Styled.Title>
				<Button
					type='primary'
					onClick={() => navigate(`${Routes.BackOffice}${Routes.CreateSupportTicket}`)}
				>
					Create New
				</Button>
			</Styled.Head>

			<Box>
				<SupportTickets />
			</Box>
		</Styled.Root>
	);
};

export default Tickets;
