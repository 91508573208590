import React, { type FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { atsDucks } from 'modules/ATS/ducks';
import { commonDucks } from 'modules/Common/ducks';
import { ClientInfoType } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { UserRolesType, GenericType } from 'types';

import { Styled } from './CompanyDetails.styled';
import CompanyDetailsEdit from './CompanyDetailsEdit';
import CompanyDetailsPreview from './CompanyDetailsPreview';

export type CompanyDetailsProps = {
	roles: UserRolesType;
	clientId: number;
	clientInfo: Partial<ClientInfoType>;
	companyDetails: Partial<ClientInfoType>;
	loading: { [key: string]: boolean };
	getClientById: (clientId: number) => void;
	updateClientRequested: (values: Partial<ClientInfoType>) => void;
	saveCompanyDetailsAction: (values: Partial<ClientInfoType>) => void;
	saveClientCareerBranding: (props: GenericType) => void;
	saveClientAtsBranding: (props: GenericType) => void;
};

const CompanyDetails: FC<CompanyDetailsProps> = ({
	roles,
	loading,
	clientId,
	clientInfo,
	getClientById,
	companyDetails,
	updateClientRequested,
	saveCompanyDetailsAction,
	saveClientCareerBranding,
	saveClientAtsBranding,
}) => {
	const initialValues = {
		logo: companyDetails?.logo || clientInfo?.logo || '',
		clientName: companyDetails?.clientName || clientInfo?.clientName || '',
		companyAddress: companyDetails?.companyAddress || clientInfo?.companyAddress || '',
	};

	useEffect(() => {
		getClientById(clientId);
	}, []);

	return (
		<Styled.Root>
			{roles?.isAtsSuperUser ? (
				<CompanyDetailsEdit
					loading={loading.updateClientLoad}
					clientLoading={loading.getClientByIdLoad}
					clientId={clientId}
					fields={initialValues}
					updateClientRequested={updateClientRequested}
					saveCompanyDetailsAction={saveCompanyDetailsAction}
					saveClientCareerBranding={saveClientCareerBranding}
					saveClientAtsBranding={saveClientAtsBranding}
				/>
			) : (
				<CompanyDetailsPreview fields={initialValues} />
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
		clientInfo: commonDucks.commonSelectors.getClientData(state),
		companyDetails: atsDucks.atsSelectors.getSettingsCompanyDetails(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
		clientId: unregisteredDucks.unregisteredSelectors.getUser(state)?.clientId,
	}),
	{
		updateClientRequested: commonDucks.commonActions.updateClientRequested,
		saveCompanyDetailsAction: atsDucks.atsActions.saveSettingsCompanyDetailsAction,
		saveClientCareerBranding: atsDucks.atsActions.saveClientCareerBranding,
		saveClientAtsBranding: atsDucks.atsActions.saveClientAtsBranding,
		getClientById: commonDucks.commonActions.getClientByIdRequested,
	},
)(CompanyDetails);
